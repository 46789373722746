import { Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { type MaxRisk } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { SparklineIcon } from '../ReportIcons/SparklineIcon';

type Props = {
    maxRisk?: MaxRisk;
};

export const ReportMaxRisk: FC<Props> = ({ maxRisk }) => (
    <Stack
px={24} py={24} gap={16}
w="100%">
        <Text fz={36} fw={400}>
            {'Максимальная просадка'}
        </Text>
        <Group justify="space-between" wrap="nowrap">
            <Group gap={6}>
                <SparklineIcon />
                <Text style={{ whiteSpace: 'nowrap' }}>
                    {maxRisk?.dateFromMaxRisk ? new Date(maxRisk.dateFromMaxRisk).toLocaleDateString('ru') : '-'}
                    {'–'}
                    {maxRisk?.dateTillMaxRisk ? new Date(maxRisk.dateTillMaxRisk).toLocaleDateString('ru') : '-'}
                </Text>
            </Group>
            <Text fz={15} fw={600} c="#E42821">
                {maxRisk?.maxRisk?.toLocaleString('ru', { maximumFractionDigits: 2 })}
                {'%'}
            </Text>
        </Group>
    </Stack>
);
