import { type PortfolioPerformanceResponse } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getPerformance: ApiFactory<
    ApiEndpoint<PortfolioPerformanceResponse, void, { id: string; efficiencyType: string }>
> = (client) => async ({ signal, params: { id, ...params } }) => {
    const { data } = await client.get<PortfolioPerformanceResponse>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/efficiency/${id}`,
        { signal, params },
    );

    return data;
};
