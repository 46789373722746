/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable react/prop-types */
import { readLocalStorageValue } from '@mantine/hooks';
import { Document } from '@react-pdf/renderer';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import { type FC, Fragment, type ReactNode, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { type PortfolioByIdResponseData, PortfolioFilterKeys } from '../../../entities/portfolio';
import { type NewReportItem, ReportItemType } from '../../../entities/report';
import { type InferParams } from '../../../shared/router/types';
import { useCashFlowQuery } from '../CashFlow/useCashFlowQuery';
import { useGetGeoDiversificationQuery } from '../Diversification/useGetGeoDiversificationQuery';
import { useGetIntraPortfolioCorrelationQuery } from '../Diversification/useGetIntraPortfolioCorrelationQuery';
import { useGetLeastCorrelatingToolsQuery } from '../Diversification/useGetLeastCorrelatingToolsQuery';
import { useGetMostCorrelatingToolsQuery } from '../Diversification/useGetMostCorrelatingToolsQuery';
import { useGetSectoralDiversificationQuery } from '../Diversification/useGetSectoralDiversificationQuery';
import { useGetPortfolioDynamicsQuery } from '../Dynamics/useGetPortfolioDynamicsQuery';
import { useGetKeyStatisticDataQuery } from '../GeneralInfo/KeyStatisticsTable/useGetKeyStatisticDataQuery';
import { useGetExpectationsQuery } from '../GeneralInfo/Profit/useGetExpectationsQuery';
import { useGetMaxRiskQuery } from '../GeneralInfo/Profit/useGetMaxRiskQuery';
import { useGetDiversificationQuery } from '../GeneralInfo/Scales/useGetDiversificationQuery';
import { useGetInvestmentProfileQuery } from '../GeneralInfo/Scales/useGetInvestmentProfileQuery';
import { useGetPortfolioInstrumentsQuery } from '../GeneralInfo/ToolsTable/useGetPortfolioInstrumentsQuery';
import { useGetCoreMetricsQuery } from '../GeneralInfo/useGetCoreMetricsQuery';
import { useGetPortfolioCostQuery } from '../GeneralInfo/useGetPortfolioCostQuery';
import { useGetHistoricalReturnsQuery } from '../HistoricReturns/useGetHistoricalReturnsQuery';
import { useGetInstrumentsLiquidityQuery } from '../Liquidity/useGetInstrumentsLiquidityQuery';
import { useGetLiquidityQuery } from '../Liquidity/useGetLiquidityQuery';
import { useGetPortfolioPerformanceQuery } from '../Performance/useGetPortfolioPerformanceQuery';
import { type ReportFormFinalValues, type ReportFormTitleValues } from './ReportForm.schema';
import { useGetPortfolioRiskMetricsQuery } from '../RiskMetrics/useGetPortfolioRiskMetricsQuery';
import { useGetAssetsBehaviorQuery } from '../StressTests/AssetBehavior/useGetAssetsBehaviorQuery';
import { useGetRiskProfileQuery } from '../StressTests/useGetRiskProfileQuery';
import { useGetScenarioIndicatorQuery } from '../StressTests/useGetScenarioIndicatorQuery';
import { useGetScenariosQuery } from '../StressTests/useGetScenariosQuery';
import { useGetSimulationResultQuery } from '../StressTests/useGetSimulationResultQuery';
import { useGetTopFiveDividendsQuery } from '../TypologyAssets/Tables/useGetTopFiveDividendsQuery';
import { useGetTopFiveReturnOnValueQuery } from '../TypologyAssets/Tables/useGetTopFiveReturnOnValueQuery';
import { useGetTopFiveTotalReturnQuery } from '../TypologyAssets/Tables/useGetTopFiveTotalReturnQuery';
import { useGetTypologyAssetsBondsQuery } from '../TypologyAssets/useGetTypologyAssetsBondsQuery';
import { useGetTypologyAssetsFundsQuery } from '../TypologyAssets/useGetTypologyAssetsFundsQuery';
import { useGetTypologyAssetsStocksQuery } from '../TypologyAssets/useGetTypologyAssetsStocksQuery';
import { ComponentScreener } from './ComponentScreener';
import { PdfDocumentDownloader } from './PdfDocumentDownloader';
import { PdfModal } from './PdfModal';
import { PdfPage } from './PdfPage';
import { ReportAssetAllocation } from './ReportComponentsForScreen/ReportAssetAllocation';
import { ReportCashFlowAccumulated } from './ReportComponentsForScreen/ReportCashFlowAccumulated';
import { ReportCashFlowAverage } from './ReportComponentsForScreen/ReportCashFlowAverage';
import { ReportCorrelation } from './ReportComponentsForScreen/ReportCorrelation';
import { ReportDiversification } from './ReportComponentsForScreen/ReportDiversification';
import { ReportDynamics } from './ReportComponentsForScreen/ReportDynamics';
import { ReportGeneralInfoFirstPage } from './ReportComponentsForScreen/ReportGeneralInfoFirstPage';
import { ReportGeneralInfoSecondPage } from './ReportComponentsForScreen/ReportGeneralInfoSecondPage';
import { ReportLiquidity } from './ReportComponentsForScreen/ReportLiquidity';
import { ReportLosses } from './ReportComponentsForScreen/ReportLosses';
import { ReportPerformance } from './ReportComponentsForScreen/ReportPerformance';
import { ReportPerformanceRisk } from './ReportComponentsForScreen/ReportPerformanceRisk';
import { ReportPortfolioAndBenchmarkReturns } from './ReportComponentsForScreen/ReportPortfolioAndBenchmarkReturns';
import { ReportPortfolioReturns } from './ReportComponentsForScreen/ReportPortfolioReturns';
import { ReportRiskMetrics } from './ReportComponentsForScreen/ReportRiskMetrics';
import { ReportStressTestsGeneralInfo } from './ReportComponentsForScreen/ReportStressTestsGeneralInfo';
import { ReportStressTestsScenarios } from './ReportComponentsForScreen/ReportStressTestsScenarios';
import { ReportTypologyAssets } from './ReportComponentsForScreen/ReportTypologyAssets';
import { ReportFullDocument } from './ReportFullDocument';
import { ReportItemEditCommentModal } from './ReportItemEditCommentModal';
import { type LoadedStatus } from './ReportLayout';

type Props = {
    finalValues: ReportFormFinalValues;
    fullReportModalClose: () => void;
    fullReportModalOpened: boolean;
    inReportItems: NewReportItem[];
    isFullReportDownloading: boolean;
    onFullReportDownload: () => void;
    onComponentsDataLoadedStatusChange: (statuses: Record<ReportItemType, LoadedStatus>) => void;
    selectedReportItem?: NewReportItem;
    reportSettings: string[];
    titleValues: ReportFormTitleValues;
    showSelectedReportItem: boolean;
    editSelectedReportItem: boolean;
    onShowSelectedReportItemChange: (value: boolean) => void;
    onEditSelectedReportItemChange: (value: boolean) => void;
    onCommentsChange: (id: ReportItemType, comment: string) => void;
    comments: Record<ReportItemType, string>;
};

export const PdfShaper: FC<Props> = memo(
    ({
        finalValues,
        fullReportModalClose,
        fullReportModalOpened,
        inReportItems,
        isFullReportDownloading,
        onFullReportDownload,
        onComponentsDataLoadedStatusChange,
        selectedReportItem,
        reportSettings,
        titleValues,
        showSelectedReportItem,
        editSelectedReportItem,
        onShowSelectedReportItemChange,
        onEditSelectedReportItemChange,
        onCommentsChange,
        comments,
    }) => {
        const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;
        const queryClient = useQueryClient();
        const isMutating = useIsMutating({ mutationKey: ['portfolio', portfolioId, 'update'] });
        const [isReportItemToEditDownloading, setIsReportItemToEditDownloading] = useState(false);
        const portfolio = queryClient.getQueryData<PortfolioByIdResponseData>(['portfolio', portfolioId]);
        const [queriesLoadedStatus, setQueriesLoadedStatus] = useState<Record<string, LoadedStatus>>({
            historicalReturnsNetPortfolioQuery: '',
            historicalReturnsPortfolioQuery: '',
            historicalReturnsBenchmarkQuery: '',
            portfolioInstrumentsQuery: '',
            typologyAssetsStocksQuery: '',
            typologyAssetsBondsQuery: '',
            typologyAssetsFundsQuery: '',
            topFiveReturnOnValueQuery: '',
            topFiveDividendsQuery: '',
            topFiveTotalReturnQuery: '',
            portfolioRiskMetricsQuery: '',
            portfolioPerformanceStressTestModelQuery: '',
            portfolioPerformanceVolatilityModelQuery: '',
            cashFlowAnnualQuery: '',
            cashFlowAccumulatedQuery: '',
            mostCorrelatingToolsQuery: '',
            leastCorrelatingToolsQuery: '',
            intraDiversificationQuery: '',
            expectationsQuery: '',
            investmentProfileQuery: '',
            assetsBehaviorQuery: '',
            scenariosQuery: '',
            keyStatisticDataQuery: '',
            portfolioDynamicsQuery: '',
            scenarioIndicatorQuery: '',
            simulationResultQuery: '',
            riskProfileQuery: '',
            portfolioCostQuery: '',
            coreMetricsQuery: '',
            geoDiversificationQuery: '',
            sectoralDiversificationQuery: '',
            liquidityQuery: '',
            instrumentsLiquidityQuery: '',
            maxRiskQuery: '',
            diversificationQuery: '',
        });

        const [screens, setScreens] = useState<Record<ReportItemType, string[]>>({
            [ReportItemType.ASSET_ALLOCATION]: [''],
            [ReportItemType.ASSET_TYPOLOGY]: [''],
            [ReportItemType.CASH_FLOW_AVERAGE]: [''],
            [ReportItemType.CASH_FLOW_CUMULATIVE]: [''],
            [ReportItemType.CORRELATION]: [''],
            [ReportItemType.DIVERSIFICATION]: [''],
            [ReportItemType.DYNAMICS]: [''],
            [ReportItemType.HISTORIC_RETURNS]: [''],
            [ReportItemType.LIQUIDITY]: [''],
            [ReportItemType.MAXIMUM_DRAWDOWN]: [''],
            [ReportItemType.PERFORMANCE_DRAWDOWN]: [''],
            [ReportItemType.PERFORMANCE_VOLATILITY]: [''],
            [ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS]: [''],
            [ReportItemType.RISK_METRICS]: [''],
            [ReportItemType.STRESS_TEST_SCENARIOS]: [''],
            [ReportItemType.STRESS_TESTS_SUMMARY]: [''],
            [ReportItemType.SUMMARY_ANALYTICS]: ['', ''],
        });

        const handleQueryLoadedStatusChange = useCallback(
            (queryName: string) => (status: LoadedStatus) => {
                setQueriesLoadedStatus((prevState) => ({
                    ...prevState,
                    [queryName]: status,
                }));
            },
            [],
        );

        useEffect(() => {
            if (!isMutating) {
                setQueriesLoadedStatus({
                    historicalReturnsNetPortfolioQuery: '',
                    historicalReturnsPortfolioQuery: '',
                    historicalReturnsBenchmarkQuery: '',
                    portfolioInstrumentsQuery: '',
                    typologyAssetsStocksQuery: '',
                    typologyAssetsBondsQuery: '',
                    typologyAssetsFundsQuery: '',
                    topFiveReturnOnValueQuery: '',
                    topFiveDividendsQuery: '',
                    topFiveTotalReturnQuery: '',
                    portfolioRiskMetricsQuery: '',
                    portfolioPerformanceStressTestModelQuery: '',
                    portfolioPerformanceVolatilityModelQuery: '',
                    cashFlowAnnualQuery: '',
                    cashFlowAccumulatedQuery: '',
                    mostCorrelatingToolsQuery: '',
                    leastCorrelatingToolsQuery: '',
                    intraDiversificationQuery: '',
                    expectationsQuery: '',
                    investmentProfileQuery: '',
                    assetsBehaviorQuery: '',
                    scenariosQuery: '',
                    keyStatisticDataQuery: '',
                    portfolioDynamicsQuery: '',
                    scenarioIndicatorQuery: '',
                    simulationResultQuery: '',
                    riskProfileQuery: '',
                    portfolioCostQuery: '',
                    coreMetricsQuery: '',
                    geoDiversificationQuery: '',
                    sectoralDiversificationQuery: '',
                    liquidityQuery: '',
                    instrumentsLiquidityQuery: '',
                    maxRiskQuery: '',
                    diversificationQuery: '',
                });
                setScreens({
                    [ReportItemType.ASSET_ALLOCATION]: [''],
                    [ReportItemType.ASSET_TYPOLOGY]: [''],
                    [ReportItemType.CASH_FLOW_AVERAGE]: [''],
                    [ReportItemType.CASH_FLOW_CUMULATIVE]: [''],
                    [ReportItemType.CORRELATION]: [''],
                    [ReportItemType.DIVERSIFICATION]: [''],
                    [ReportItemType.DYNAMICS]: [''],
                    [ReportItemType.HISTORIC_RETURNS]: [''],
                    [ReportItemType.LIQUIDITY]: [''],
                    [ReportItemType.MAXIMUM_DRAWDOWN]: [''],
                    [ReportItemType.PERFORMANCE_DRAWDOWN]: [''],
                    [ReportItemType.PERFORMANCE_VOLATILITY]: [''],
                    [ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS]: [''],
                    [ReportItemType.RISK_METRICS]: [''],
                    [ReportItemType.STRESS_TEST_SCENARIOS]: [''],
                    [ReportItemType.STRESS_TESTS_SUMMARY]: [''],
                    [ReportItemType.SUMMARY_ANALYTICS]: ['', ''],
                });
            }
        }, [isMutating]);

        const historicalReturnsNetPortfolioQuery = useGetHistoricalReturnsQuery(
            portfolioId,
            'NET_PORTFOLIO',
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS)) ||
                selectedReportItem?.id === ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS) &&
                !queriesLoadedStatus.historicalReturnsNetPortfolioQuery,
            handleQueryLoadedStatusChange('historicalReturnsNetPortfolioQuery'),
        );

        const historicalReturnsPortfolioQuery = useGetHistoricalReturnsQuery(
            portfolioId,
            'PORTFOLIO',
            (Boolean(
                inReportItems.find(
                    ({ id }) => id === ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS || id === ReportItemType.HISTORIC_RETURNS,
                ),
            ) ||
                selectedReportItem?.id === ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS ||
                selectedReportItem?.id === ReportItemType.HISTORIC_RETURNS) &&
                !queriesLoadedStatus.historicalReturnsPortfolioQuery,
            handleQueryLoadedStatusChange('historicalReturnsPortfolioQuery'),
        );

        const historicalReturnsBenchmarkQuery = useGetHistoricalReturnsQuery(
            portfolioId,
            'BENCHMARK',
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS)) ||
                selectedReportItem?.id === ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS) &&
                !queriesLoadedStatus.historicalReturnsBenchmarkQuery,
            handleQueryLoadedStatusChange('historicalReturnsBenchmarkQuery'),
        );

        const typologyAssetsStocksQuery = useGetTypologyAssetsStocksQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.ASSET_TYPOLOGY)) ||
                selectedReportItem?.id === ReportItemType.ASSET_TYPOLOGY) &&
                !queriesLoadedStatus.typologyAssetsStocksQuery,
            handleQueryLoadedStatusChange('typologyAssetsStocksQuery'),
        );

        const typologyAssetsBondsQuery = useGetTypologyAssetsBondsQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.ASSET_TYPOLOGY)) ||
                selectedReportItem?.id === ReportItemType.ASSET_TYPOLOGY) &&
                !queriesLoadedStatus.typologyAssetsBondsQuery,
            handleQueryLoadedStatusChange('typologyAssetsBondsQuery'),
        );

        const typologyAssetsFundsQuery = useGetTypologyAssetsFundsQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.ASSET_TYPOLOGY)) ||
                selectedReportItem?.id === ReportItemType.ASSET_TYPOLOGY) &&
                !queriesLoadedStatus.typologyAssetsFundsQuery,
            handleQueryLoadedStatusChange('typologyAssetsFundsQuery'),
        );

        const portfolioInstrumentsQuery = useGetPortfolioInstrumentsQuery(
            portfolioId,
            undefined,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.ASSET_ALLOCATION)) ||
                selectedReportItem?.id === ReportItemType.ASSET_ALLOCATION) &&
                !queriesLoadedStatus.portfolioInstrumentsQuery,
            handleQueryLoadedStatusChange('portfolioInstrumentsQuery'),
        );

        const topFiveReturnOnValueQuery = useGetTopFiveReturnOnValueQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.HISTORIC_RETURNS)) ||
                selectedReportItem?.id === ReportItemType.HISTORIC_RETURNS) &&
                !queriesLoadedStatus.topFiveReturnOnValueQuery,
            handleQueryLoadedStatusChange('topFiveReturnOnValueQuery'),
        );

        const topFiveDividendsQuery = useGetTopFiveDividendsQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.HISTORIC_RETURNS)) ||
                selectedReportItem?.id === ReportItemType.HISTORIC_RETURNS) &&
                !queriesLoadedStatus.topFiveDividendsQuery,
            handleQueryLoadedStatusChange('topFiveDividendsQuery'),
        );

        const topFiveTotalReturnQuery = useGetTopFiveTotalReturnQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.HISTORIC_RETURNS)) ||
                selectedReportItem?.id === ReportItemType.HISTORIC_RETURNS) &&
                !queriesLoadedStatus.topFiveTotalReturnQuery,
            handleQueryLoadedStatusChange('topFiveTotalReturnQuery'),
        );

        const portfolioRiskMetricsQuery = useGetPortfolioRiskMetricsQuery(
            portfolioId,
            undefined,
            (Boolean(
                inReportItems.find(
                    ({ id }) => id === ReportItemType.MAXIMUM_DRAWDOWN || id === ReportItemType.RISK_METRICS,
                ),
            ) ||
                selectedReportItem?.id === ReportItemType.MAXIMUM_DRAWDOWN ||
                selectedReportItem?.id === ReportItemType.RISK_METRICS) &&
                !queriesLoadedStatus.portfolioRiskMetricsQuery,
            handleQueryLoadedStatusChange('portfolioRiskMetricsQuery'),
        );

        const portfolioPerformanceStressTestModelQuery = useGetPortfolioPerformanceQuery(
            portfolioId,
            'STRESS_TEST_MODEL',
            (Boolean(
                inReportItems.find(
                    ({ id }) => id === ReportItemType.PERFORMANCE_VOLATILITY || id === ReportItemType.SUMMARY_ANALYTICS,
                ),
            ) ||
                selectedReportItem?.id === ReportItemType.PERFORMANCE_VOLATILITY ||
                selectedReportItem?.id === ReportItemType.SUMMARY_ANALYTICS) &&
                !queriesLoadedStatus.portfolioPerformanceStressTestModelQuery,
            handleQueryLoadedStatusChange('portfolioPerformanceStressTestModelQuery'),
        );

        const portfolioPerformanceVolatilityModelQuery = useGetPortfolioPerformanceQuery(
            portfolioId,
            'VOLATILITY_MODEL',
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.PERFORMANCE_DRAWDOWN)) ||
                selectedReportItem?.id === ReportItemType.PERFORMANCE_DRAWDOWN) &&
                !queriesLoadedStatus.portfolioPerformanceVolatilityModelQuery,
            handleQueryLoadedStatusChange('portfolioPerformanceVolatilityModelQuery'),
        );

        const cashFlowAnnualQuery = useCashFlowQuery(
            portfolioId,
            'ANNUAL',
            (Boolean(
                inReportItems.find(
                    ({ id }) => id === ReportItemType.CASH_FLOW_AVERAGE || id === ReportItemType.SUMMARY_ANALYTICS,
                ),
            ) ||
                selectedReportItem?.id === ReportItemType.CASH_FLOW_AVERAGE ||
                selectedReportItem?.id === ReportItemType.SUMMARY_ANALYTICS) &&
                !queriesLoadedStatus.cashFlowAnnualQuery,
            handleQueryLoadedStatusChange('cashFlowAnnualQuery'),
        );

        const cashFlowAccumulatedQuery = useCashFlowQuery(
            portfolioId,
            'ACCUMULATED',
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.CASH_FLOW_CUMULATIVE)) ||
                selectedReportItem?.id === ReportItemType.CASH_FLOW_CUMULATIVE) &&
                !queriesLoadedStatus.cashFlowAccumulatedQuery,
            handleQueryLoadedStatusChange('cashFlowAccumulatedQuery'),
        );

        const mostCorrelatingToolsQuery = useGetMostCorrelatingToolsQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.CORRELATION)) ||
                selectedReportItem?.id === ReportItemType.CORRELATION) &&
                !queriesLoadedStatus.mostCorrelatingToolsQuery,
            handleQueryLoadedStatusChange('mostCorrelatingToolsQuery'),
        );

        const leastCorrelatingToolsQuery = useGetLeastCorrelatingToolsQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.CORRELATION)) ||
                selectedReportItem?.id === ReportItemType.CORRELATION) &&
                !queriesLoadedStatus.leastCorrelatingToolsQuery,
            handleQueryLoadedStatusChange('leastCorrelatingToolsQuery'),
        );

        const intraDiversificationQuery = useGetIntraPortfolioCorrelationQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.CORRELATION)) ||
                selectedReportItem?.id === ReportItemType.CORRELATION) &&
                !queriesLoadedStatus.intraDiversificationQuery,
            handleQueryLoadedStatusChange('intraDiversificationQuery'),
        );

        const expectationsQuery = useGetExpectationsQuery(
            portfolioId,
            (Boolean(
                inReportItems.find(
                    ({ id }) => id === ReportItemType.RISK_METRICS || id === ReportItemType.SUMMARY_ANALYTICS,
                ),
            ) ||
                selectedReportItem?.id === ReportItemType.RISK_METRICS ||
                selectedReportItem?.id === ReportItemType.SUMMARY_ANALYTICS) &&
                !queriesLoadedStatus.expectationsQuery,
            handleQueryLoadedStatusChange('expectationsQuery'),
        );

        const investmentProfileQuery = useGetInvestmentProfileQuery(
            portfolioId,
            (Boolean(
                inReportItems.find(
                    ({ id }) => id === ReportItemType.STRESS_TESTS_SUMMARY || id === ReportItemType.SUMMARY_ANALYTICS,
                ),
            ) ||
                selectedReportItem?.id === ReportItemType.STRESS_TESTS_SUMMARY ||
                selectedReportItem?.id === ReportItemType.SUMMARY_ANALYTICS) &&
                !queriesLoadedStatus.investmentProfileQuery,
            handleQueryLoadedStatusChange('investmentProfileQuery'),
        );

        const scenariosQuery = useGetScenariosQuery(
            portfolioId,
            (Boolean(
                inReportItems.find(
                    ({ id }) => id === ReportItemType.STRESS_TESTS_SUMMARY || id === ReportItemType.STRESS_TEST_SCENARIOS,
                ),
            ) ||
                selectedReportItem?.id === ReportItemType.STRESS_TESTS_SUMMARY ||
                selectedReportItem?.id === ReportItemType.STRESS_TEST_SCENARIOS) &&
                !queriesLoadedStatus.scenariosQuery,
            handleQueryLoadedStatusChange('scenariosQuery'),
        );

        // TODO
        const scenarioUuid = readLocalStorageValue<string>({
            key: PortfolioFilterKeys.STRESS_TESTS_FILTER,
            defaultValue: scenariosQuery.data?.content[0]?.uuid,
        });

        const assetsBehaviorQuery = useGetAssetsBehaviorQuery(
            portfolioId,
            scenarioUuid,
            (Boolean(
                inReportItems.find(
                    ({ id }) => id === ReportItemType.STRESS_TESTS_SUMMARY || id === ReportItemType.STRESS_TEST_SCENARIOS,
                ),
            ) ||
                selectedReportItem?.id === ReportItemType.STRESS_TESTS_SUMMARY ||
                selectedReportItem?.id === ReportItemType.STRESS_TEST_SCENARIOS) &&
                !queriesLoadedStatus.assetsBehaviorQuery,
            handleQueryLoadedStatusChange('assetsBehaviorQuery'),
        );

        const keyStatisticDataQuery = useGetKeyStatisticDataQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.DYNAMICS)) ||
                selectedReportItem?.id === ReportItemType.DYNAMICS) &&
                !queriesLoadedStatus.keyStatisticDataQuery,
            handleQueryLoadedStatusChange('keyStatisticDataQuery'),
        );

        const portfolioDynamicsQuery = useGetPortfolioDynamicsQuery(
            portfolioId,
            undefined,
            (Boolean(
                inReportItems.find(
                    ({ id }) => id === ReportItemType.DYNAMICS || id === ReportItemType.SUMMARY_ANALYTICS,
                ),
            ) ||
                selectedReportItem?.id === ReportItemType.DYNAMICS ||
                selectedReportItem?.id === ReportItemType.SUMMARY_ANALYTICS) &&
                !queriesLoadedStatus.portfolioDynamicsQuery,
            handleQueryLoadedStatusChange('portfolioDynamicsQuery'),
        );

        const scenarioIndicatorQuery = useGetScenarioIndicatorQuery(
            portfolioId,
            scenarioUuid,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.STRESS_TEST_SCENARIOS)) ||
                selectedReportItem?.id === ReportItemType.STRESS_TEST_SCENARIOS) &&
                !queriesLoadedStatus.scenarioIndicatorQuery,
            handleQueryLoadedStatusChange('scenarioIndicatorQuery'),
        );

        const simulationResultQuery = useGetSimulationResultQuery(
            portfolioId,
            scenarioUuid,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.STRESS_TEST_SCENARIOS)) ||
                selectedReportItem?.id === ReportItemType.STRESS_TEST_SCENARIOS) &&
                !queriesLoadedStatus.simulationResultQuery,
            handleQueryLoadedStatusChange('simulationResultQuery'),
        );

        const riskProfileQuery = useGetRiskProfileQuery(
            portfolioId,
            scenarioUuid,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.STRESS_TEST_SCENARIOS)) ||
                selectedReportItem?.id === ReportItemType.STRESS_TEST_SCENARIOS) &&
                !queriesLoadedStatus.riskProfileQuery,
            handleQueryLoadedStatusChange('riskProfileQuery'),
        );

        const portfolioCostQuery = useGetPortfolioCostQuery(
            portfolioId,
            (Boolean(
                inReportItems.find(
                    ({ id }) => id === ReportItemType.DIVERSIFICATION || id === ReportItemType.SUMMARY_ANALYTICS,
                ),
            ) ||
                selectedReportItem?.id === ReportItemType.DIVERSIFICATION ||
                selectedReportItem?.id === ReportItemType.SUMMARY_ANALYTICS) &&
                !queriesLoadedStatus.portfolioCostQuery,
            handleQueryLoadedStatusChange('portfolioCostQuery'),
        );

        const coreMetricsQuery = useGetCoreMetricsQuery(
            portfolioId,
            (Boolean(
                inReportItems.find(
                    ({ id }) => id === ReportItemType.DIVERSIFICATION || id === ReportItemType.SUMMARY_ANALYTICS,
                ),
            ) ||
                selectedReportItem?.id === ReportItemType.DIVERSIFICATION ||
                selectedReportItem?.id === ReportItemType.SUMMARY_ANALYTICS) &&
                !queriesLoadedStatus.coreMetricsQuery,
            handleQueryLoadedStatusChange('coreMetricsQuery'),
        );

        const geoDiversificationQuery = useGetGeoDiversificationQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.DIVERSIFICATION)) ||
                selectedReportItem?.id === ReportItemType.DIVERSIFICATION) &&
                !queriesLoadedStatus.geoDiversificationQuery,
            handleQueryLoadedStatusChange('geoDiversificationQuery'),
        );

        const sectoralDiversificationQuery = useGetSectoralDiversificationQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.DIVERSIFICATION)) ||
                selectedReportItem?.id === ReportItemType.DIVERSIFICATION) &&
                !queriesLoadedStatus.sectoralDiversificationQuery,
            handleQueryLoadedStatusChange('sectoralDiversificationQuery'),
        );

        const liquidityQuery = useGetLiquidityQuery(
            portfolioId,
            (Boolean(
                inReportItems.find(
                    ({ id }) => id === ReportItemType.LIQUIDITY || id === ReportItemType.SUMMARY_ANALYTICS,
                ),
            ) ||
                selectedReportItem?.id === ReportItemType.LIQUIDITY ||
                selectedReportItem?.id === ReportItemType.SUMMARY_ANALYTICS) &&
                !queriesLoadedStatus.liquidityQuery,
            handleQueryLoadedStatusChange('liquidityQuery'),
        );

        const instrumentsLiquidityQuery = useGetInstrumentsLiquidityQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.LIQUIDITY)) ||
                selectedReportItem?.id === ReportItemType.LIQUIDITY) &&
                !queriesLoadedStatus.instrumentsLiquidityQuery,
            handleQueryLoadedStatusChange('instrumentsLiquidityQuery'),
        );

        const maxRiskQuery = useGetMaxRiskQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.SUMMARY_ANALYTICS)) ||
                selectedReportItem?.id === ReportItemType.SUMMARY_ANALYTICS) &&
                !queriesLoadedStatus.maxRiskQuery,
            handleQueryLoadedStatusChange('maxRiskQuery'),
        );

        const diversificationQuery = useGetDiversificationQuery(
            portfolioId,
            (Boolean(inReportItems.find(({ id }) => id === ReportItemType.SUMMARY_ANALYTICS)) ||
                selectedReportItem?.id === ReportItemType.SUMMARY_ANALYTICS) &&
                !queriesLoadedStatus.diversificationQuery,
            handleQueryLoadedStatusChange('diversificationQuery'),
        );

        const getComponentDataLoadedStatus = useCallback((queriesLoadedStatuses: LoadedStatus[]): LoadedStatus => {
            if (queriesLoadedStatuses.some((s) => s === 'error')) {
                return 'error';
            }

            if (queriesLoadedStatuses.every((s) => s === 'success')) {
                return 'success';
            }

            if (queriesLoadedStatuses.some((s) => s === 'pending')) {
                return 'pending';
            }

            return '';
        }, []);

        const componentsDataLoadedStatus = useMemo<Record<ReportItemType, LoadedStatus>>(
            () => ({
                [ReportItemType.ASSET_ALLOCATION]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.portfolioInstrumentsQuery,
                ]),
                [ReportItemType.ASSET_TYPOLOGY]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.typologyAssetsBondsQuery,
                    queriesLoadedStatus.typologyAssetsFundsQuery,
                    queriesLoadedStatus.typologyAssetsStocksQuery,
                ]),
                [ReportItemType.CASH_FLOW_AVERAGE]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.cashFlowAnnualQuery,
                ]),
                [ReportItemType.CASH_FLOW_CUMULATIVE]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.cashFlowAccumulatedQuery,
                ]),
                [ReportItemType.CORRELATION]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.mostCorrelatingToolsQuery,
                    queriesLoadedStatus.leastCorrelatingToolsQuery,
                    queriesLoadedStatus.intraDiversificationQuery,
                ]),
                [ReportItemType.DIVERSIFICATION]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.portfolioCostQuery,
                    queriesLoadedStatus.coreMetricsQuery,
                    queriesLoadedStatus.geoDiversificationQuery,
                    queriesLoadedStatus.sectoralDiversificationQuery,
                ]),
                [ReportItemType.DYNAMICS]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.keyStatisticDataQuery,
                    queriesLoadedStatus.portfolioDynamicsQuery,
                ]),
                [ReportItemType.HISTORIC_RETURNS]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.historicalReturnsPortfolioQuery,
                    queriesLoadedStatus.topFiveReturnOnValueQuery,
                    queriesLoadedStatus.topFiveDividendsQuery,
                    queriesLoadedStatus.topFiveTotalReturnQuery,
                ]),
                [ReportItemType.LIQUIDITY]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.liquidityQuery,
                    queriesLoadedStatus.instrumentsLiquidityQuery,
                ]),
                [ReportItemType.MAXIMUM_DRAWDOWN]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.portfolioRiskMetricsQuery,
                ]),
                [ReportItemType.PERFORMANCE_DRAWDOWN]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.portfolioPerformanceVolatilityModelQuery,
                ]),
                [ReportItemType.PERFORMANCE_VOLATILITY]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.portfolioPerformanceStressTestModelQuery,
                ]),
                [ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.historicalReturnsBenchmarkQuery,
                    queriesLoadedStatus.historicalReturnsNetPortfolioQuery,
                    queriesLoadedStatus.historicalReturnsPortfolioQuery,
                ]),
                [ReportItemType.RISK_METRICS]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.portfolioRiskMetricsQuery,
                    queriesLoadedStatus.expectationsQuery,
                ]),
                [ReportItemType.STRESS_TEST_SCENARIOS]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.assetsBehaviorQuery,
                    queriesLoadedStatus.scenariosQuery,
                    queriesLoadedStatus.scenarioIndicatorQuery,
                    queriesLoadedStatus.simulationResultQuery,
                    queriesLoadedStatus.riskProfileQuery,
                ]),
                [ReportItemType.STRESS_TESTS_SUMMARY]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.investmentProfileQuery,
                    queriesLoadedStatus.assetsBehaviorQuery,
                    queriesLoadedStatus.scenariosQuery,
                ]),
                [ReportItemType.SUMMARY_ANALYTICS]: getComponentDataLoadedStatus([
                    queriesLoadedStatus.portfolioCostQuery,
                    queriesLoadedStatus.coreMetricsQuery,
                    queriesLoadedStatus.cashFlowAnnualQuery,
                    queriesLoadedStatus.expectationsQuery,
                    queriesLoadedStatus.portfolioDynamicsQuery,
                    queriesLoadedStatus.maxRiskQuery,
                    queriesLoadedStatus.portfolioPerformanceStressTestModelQuery,
                    queriesLoadedStatus.investmentProfileQuery,
                    queriesLoadedStatus.diversificationQuery,
                    queriesLoadedStatus.liquidityQuery,
                ]),
            }),
            [queriesLoadedStatus, getComponentDataLoadedStatus],
        );

        const components: Record<ReportItemType, ReactNode[]> = {
            [ReportItemType.ASSET_ALLOCATION]: [
                componentsDataLoadedStatus.ASSET_ALLOCATION === 'success'
? (
                    <ReportAssetAllocation data={portfolioInstrumentsQuery.data!.content} />
                )
: null,
            ],
            [ReportItemType.ASSET_TYPOLOGY]: [
                componentsDataLoadedStatus.ASSET_TYPOLOGY === 'success'
? (
                    <ReportTypologyAssets
                        typologyAssetsBondsQuery={typologyAssetsBondsQuery}
                        typologyAssetsFundsQuery={typologyAssetsFundsQuery}
                        typologyAssetsStocksQuery={typologyAssetsStocksQuery}
                    />
                )
: null,
            ],
            [ReportItemType.CASH_FLOW_AVERAGE]: [
                componentsDataLoadedStatus.CASH_FLOW_AVERAGE === 'success'
? (
                    <ReportCashFlowAverage data={cashFlowAnnualQuery.data!} />
                )
: null,
            ],
            [ReportItemType.CASH_FLOW_CUMULATIVE]: [
                componentsDataLoadedStatus.CASH_FLOW_CUMULATIVE === 'success'
? (
                    <ReportCashFlowAccumulated data={cashFlowAccumulatedQuery.data!} />
                )
: null,
            ],
            [ReportItemType.CORRELATION]: [
                componentsDataLoadedStatus.CORRELATION === 'success'
? (
                    <ReportCorrelation
                        leastCorrelatingTools={leastCorrelatingToolsQuery.data!}
                        intraPortfolioCorrelation={intraDiversificationQuery.data!}
                        mostCorrelatingTools={mostCorrelatingToolsQuery.data!}
                    />
                )
: null,
            ],
            [ReportItemType.DIVERSIFICATION]: [
                componentsDataLoadedStatus.DIVERSIFICATION === 'success'
? (
                    <ReportDiversification
                        portfolioCostQuery={portfolioCostQuery}
                        coreMetricsQuery={coreMetricsQuery}
                        geoDiversification={geoDiversificationQuery.data!}
                        sectoralDiversification={sectoralDiversificationQuery.data!}
                    />
                )
: null,
            ],
            [ReportItemType.DYNAMICS]: [
                componentsDataLoadedStatus.DYNAMICS === 'success'
? (
                    <ReportDynamics
                        dynamics={portfolioDynamicsQuery.data!}
                        keyStatistics={keyStatisticDataQuery.data!}
                    />
                )
: null,
            ],
            [ReportItemType.HISTORIC_RETURNS]: [
                componentsDataLoadedStatus.HISTORIC_RETURNS === 'success'
? (
                    <ReportPortfolioReturns
                        portfolio={historicalReturnsPortfolioQuery.data!}
                        topFiveDividends={topFiveDividendsQuery.data!}
                        topFiveReturnOnValue={topFiveReturnOnValueQuery.data!}
                        topFiveTotalReturn={topFiveTotalReturnQuery.data!}
                    />
                )
: null,
            ],
            [ReportItemType.LIQUIDITY]: [
                componentsDataLoadedStatus.LIQUIDITY === 'success'
? (
                    <ReportLiquidity
                        portfolioLiquidity={liquidityQuery.data!.value}
                        instrumentsLiquidity={instrumentsLiquidityQuery.data!}
                    />
                )
: null,
            ],
            [ReportItemType.MAXIMUM_DRAWDOWN]: [
                componentsDataLoadedStatus.MAXIMUM_DRAWDOWN === 'success'
? (
                    <ReportLosses data={portfolioRiskMetricsQuery.data!} />
                )
: null,
            ],
            [ReportItemType.PERFORMANCE_DRAWDOWN]: [
                componentsDataLoadedStatus.PERFORMANCE_DRAWDOWN === 'success'
? (
                    <ReportPerformanceRisk data={portfolioPerformanceVolatilityModelQuery.data!} />
                )
: null,
            ],
            [ReportItemType.PERFORMANCE_VOLATILITY]: [
                componentsDataLoadedStatus.PERFORMANCE_VOLATILITY === 'success'
? (
                    <ReportPerformance data={portfolioPerformanceStressTestModelQuery.data!} />
                )
: null,
            ],
            [ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS]: [
                componentsDataLoadedStatus.PORTFOLIO_AND_BENCHMARK_RETURNS === 'success'
? (
                    <ReportPortfolioAndBenchmarkReturns
                        netPortfolio={historicalReturnsNetPortfolioQuery.data!}
                        portfolio={historicalReturnsPortfolioQuery.data!}
                        benchmark={historicalReturnsBenchmarkQuery.data!}
                    />
                )
: null,
            ],
            [ReportItemType.RISK_METRICS]: [
                componentsDataLoadedStatus.RISK_METRICS === 'success'
? (
                    <ReportRiskMetrics
                        riskMetrics={portfolioRiskMetricsQuery.data!.riskMetrics}
                        expectations={expectationsQuery.data!}
                    />
                )
: null,
            ],
            [ReportItemType.STRESS_TEST_SCENARIOS]: [
                componentsDataLoadedStatus.STRESS_TEST_SCENARIOS === 'success'
? (
                    <ReportStressTestsScenarios
                        assetsBehavior={assetsBehaviorQuery.data!}
                        scenarios={scenariosQuery.data!}
                        scenarioIndicator={scenarioIndicatorQuery.data!}
                        simulationData={simulationResultQuery.data!}
                        riskProfile={riskProfileQuery.data!}
                    />
                )
: null,
            ],
            [ReportItemType.STRESS_TESTS_SUMMARY]: [
                componentsDataLoadedStatus.STRESS_TESTS_SUMMARY === 'success'
? (
                    <ReportStressTestsGeneralInfo
                        investmentProfile={investmentProfileQuery.data!}
                        assetsBehavior={assetsBehaviorQuery.data!}
                        scenarios={scenariosQuery.data!}
                        scenarioUuid={scenarioUuid}
                    />
                )
: null,
            ],
            [ReportItemType.SUMMARY_ANALYTICS]: [
                componentsDataLoadedStatus.SUMMARY_ANALYTICS === 'success'
? (
                    <ReportGeneralInfoFirstPage
                        portfolioCostQuery={portfolioCostQuery}
                        coreMetricsQuery={coreMetricsQuery}
                        cashFlowType={cashFlowAnnualQuery.data!}
                        expectations={expectationsQuery.data!}
                        dynamics={portfolioDynamicsQuery.data!}
                        maxRisk={maxRiskQuery.data!}
                    />
                )
: null,
                componentsDataLoadedStatus.SUMMARY_ANALYTICS === 'success'
? (
                    <ReportGeneralInfoSecondPage
                        portfolioPerformance={portfolioPerformanceStressTestModelQuery.data!}
                        investmentProfileQuery={investmentProfileQuery}
                        diversificationQuery={diversificationQuery}
                        portfolioLiquidity={liquidityQuery.data!.value}
                    />
                )
: null,
            ],
        };

        const ReportFullDocumentComponent = (
            <ReportFullDocument
                comments={comments}
                componentsDataLoadedStatus={componentsDataLoadedStatus}
                finalValues={finalValues}
                inReportItems={inReportItems}
                reportSettings={reportSettings}
                screens={screens}
                titleValues={titleValues}
            />
        );

        const SelectedReportItemDocumentComponent = (
            <Document>
                {selectedReportItem && screens[selectedReportItem.id].every((screen) => Boolean(screen))
? (
                    <PdfPage
                        reportItem={selectedReportItem}
                        screens={screens[selectedReportItem.id]}
                        withPageNumbers={reportSettings.includes('withPageNumbers')}
                        comment={comments[selectedReportItem.id]}
                    />
                )
: null}
            </Document>
        );

        useEffect(() => {
            onComponentsDataLoadedStatusChange(componentsDataLoadedStatus);
        }, [onComponentsDataLoadedStatusChange, componentsDataLoadedStatus]);

        return (
            <Fragment>
                {inReportItems.map((item) => {
                    if (
                        screens[item.id].every((screen) => Boolean(screen)) ||
                        componentsDataLoadedStatus[item.id] !== 'success'
                    ) return null;

                    return (
                        <Fragment key={item.id}>
                            {components[item.id].map((Component, index) => {
                                const i = index;

                                return (
                                    <ComponentScreener
                                        key={`${item.id}_${i}`}
                                        Component={Component}
                                        onScreenChange={(screen) => {
                                            setScreens((prevState) => {
                                                const screenArr = prevState[item.id];

                                                screenArr[i] = screen;

                                                return {
                                                    ...prevState,
                                                    [item.id]: screenArr,
                                                };
                                            });
                                        }}
                                    />
                                );
                            })}
                        </Fragment>
                    );
                })}

                {/* Скриншот для страницы ВНЕ отчета */}
                {selectedReportItem &&
                screens[selectedReportItem.id].some((screen) => !screen) &&
                componentsDataLoadedStatus[selectedReportItem.id] === 'success'
? (
                    <Fragment>
                        {components[selectedReportItem.id].map((Component, index) => {
                            const i = index;

                            return (
                                <ComponentScreener
                                    key={`${selectedReportItem.id}_${i}`}
                                    Component={Component}
                                    onScreenChange={(screen) => {
                                        setScreens((prevState) => {
                                            const screenArr = prevState[selectedReportItem.id];

                                            screenArr[i] = screen;

                                            return {
                                                ...prevState,
                                                [selectedReportItem.id]: screenArr,
                                            };
                                        });
                                    }}
                                />
                            );
                        })}
                    </Fragment>
                )
: null}

                <PdfModal
                    opened={Boolean(
                        selectedReportItem &&
                            screens[selectedReportItem.id].every((screen) => Boolean(screen)) &&
                            showSelectedReportItem,
                    )}
                    onClose={() => onShowSelectedReportItemChange(false)}
                >
                    {SelectedReportItemDocumentComponent}
                </PdfModal>

                <PdfModal opened={fullReportModalOpened} onClose={fullReportModalClose}>
                    {ReportFullDocumentComponent}
                </PdfModal>

                <ReportItemEditCommentModal
                    opened={Boolean(selectedReportItem && editSelectedReportItem)}
                    onClose={() => onEditSelectedReportItemChange(false)}
                    onDownload={() => setIsReportItemToEditDownloading(true)}
                    onSave={(comment) => {
                        if (selectedReportItem) {
                            onCommentsChange(selectedReportItem.id, comment);
                        }
                    }}
                    isDownloadButtonLoading={Boolean(
                        selectedReportItem &&
                            (screens[selectedReportItem.id].some((screen) => !screen) ||
                                componentsDataLoadedStatus[selectedReportItem.id] !== 'success'),
                    )}
                    onShowClick={() => onShowSelectedReportItemChange(true)}
                    title={selectedReportItem?.title ?? ''}
                    comment={selectedReportItem ? comments[selectedReportItem.id] : ''}
                />

                {isFullReportDownloading
? (
                    <PdfDocumentDownloader
                        pdfDocument={ReportFullDocumentComponent}
                        onDownload={onFullReportDownload}
                        isFullReportDownloading={isFullReportDownloading}
                        portfolioName={portfolio?.name}
                    />
                )
: null}

                {isReportItemToEditDownloading
? (
                    <PdfDocumentDownloader
                        pdfDocument={SelectedReportItemDocumentComponent}
                        onDownload={() => setIsReportItemToEditDownloading(false)}
                        portfolioName={portfolio?.name}
                    />
                )
: null}
            </Fragment>
        );
    },
);
