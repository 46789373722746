import { type FC } from 'react';
import {
    CartesianGrid,
    Legend,
    ReferenceDot,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    type ScatterProps,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { type PortfolioPerformance } from '../../../../entities';
import { ViewLoader } from '../../../../shared/Layout';
import { CustomLegend } from './CustomLegend';
import { CustomYLabel } from './CustomYLabel';
import { CustomizedShape } from './CustomizedShape';
import { TooltipContent } from './TooltipContent';
import { useFindMinDeltaIndex } from '../Table/useFindMinDeltaIndex';

type Props = {
    data: PortfolioPerformance[];
    performanceType: string;
    isInReport?: boolean;
    isPending?: boolean;
};

export const PerformanceChart: FC<Props> = ({ performanceType, data, isInReport, isPending }) => {
    const minDeltaIndex = useFindMinDeltaIndex(data);
    const dotColor = data[minDeltaIndex.value]?.expectedProfit > data[0]?.expectedProfit ? '#F2433D' : '#0C8026';
    const dotAreaColor =
        data[minDeltaIndex.value]?.expectedProfit > data[0]?.expectedProfit
            ? 'rgba(242, 67, 61, 0.20)'
            : 'rgba(12,128,38, 0.20)';

    if (isPending) {
        return <ViewLoader />;
    }

    return (
        <ResponsiveContainer width="100%" height={550}>
            <ScatterChart
                margin={{
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid />
                <XAxis
                    tickFormatter={(value: number) => value.toLocaleString('ru')}
                    tickMargin={10}
                    tickSize={0}
                    type="number"
                    dataKey="value"
                    unit="%"
                    style={{
                        fontSize: 11,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        textAnchor: 'end',
                        fill: '#8A96A6',
                        lineHeight: '20px',
                    }}
                />
                <YAxis
                    tickFormatter={(value: number) => value.toLocaleString('ru')}
                    tickMargin={10}
                    tickSize={0}
                    type="number"
                    dataKey="expectedProfit"
                    unit="%"
                    label={<CustomYLabel performanceType={performanceType} />}
                    style={{
                        fontSize: 11,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        textAnchor: 'end',
                        fill: '#8A96A6',
                        lineHeight: '20px',
                    }}
                />
                <Tooltip
                    contentStyle={{ border: 'none' }}
                    content={(props) => <TooltipContent {...props} performanceType={performanceType} />}
                    cursor={{ stroke: '#2380EB', strokeWidth: 1, strokeDasharray: 5 }}
                    wrapperStyle={{
                        border: '1px solid #D8DFE6',
                        background: '#FFFFFF',
                        borderRadius: '6px',
                        padding: '9px 18px 13px 18px',
                        width: '320px',
                        zIndex: 400,
                    }}
                />
                <Legend
                    content={<CustomLegend performanceType={performanceType} />}
                    wrapperStyle={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                />
                <ReferenceDot
r={18} fill={dotAreaColor} stroke="none"
x={data[0]?.value} y={data[0]?.expectedProfit} />
                <Scatter
                    data={data}
                    isAnimationActive={!isInReport}
                    shape={(props: ScatterProps) => <CustomizedShape props={props} dotColor={dotColor} />}
                />
            </ScatterChart>
        </ResponsiveContainer>
    );
};
