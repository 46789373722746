import classNames from 'classnames';
import { FavouriteButtonModalWrapper } from '@libs/components';
import { getFavouriteSecuritiesAndWatchlistsThunk, useAppDispatch } from '@store/store';
import { useEffect, useRef } from 'react';
import { isValueExist } from '@libs/utils';
import { FavoriteStar } from '@modules/Investorpro/shared/components/FavoriteStar';
import { useFavoriteSecurity } from '@modules/Investorpro/shared/utils/useFavouriteSecurity';
import { usePermission } from '@libs/utils/hooks/usePermission';

import styles from './styles.module.scss';

type Props = {
    isin: string | null | undefined;
    className?: string;
};

export const AddFavouriteButton = ({ isin, className }: Props) => {
    const { hasPermissionWatchlist } = usePermission();
    const buttonRef = useRef<HTMLButtonElement>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getFavouriteSecuritiesAndWatchlistsThunk());
    }, [dispatch]);

    const isAddedToFavourites = useFavoriteSecurity(isin);

    if (!isValueExist(isin)) {
        return null;
    }

    return (
        <FavouriteButtonModalWrapper
            isin={isin}
            render={(handleButtonClick) => (
                <button
                    ref={buttonRef}
                    className={classNames(styles.addFavouriteButton, className)}
                    onClick={handleButtonClick}
                    disabled={!hasPermissionWatchlist}
                >
                    <FavoriteStar
                        parentRef={buttonRef}
                        disabled={!hasPermissionWatchlist}
                        active={Boolean(isAddedToFavourites)}
                    />
                    Добавить в избранное
                </button>
            )}
        />
    );
};
