import { Stack, Text } from '@mantine/core';
import { type UseQueryResult } from '@tanstack/react-query';
import { type FC } from 'react';

import {
    type Diversification,
    type InvestmentProfile,
    type PortfolioPerformanceResponse,
} from '../../../../entities/portfolio';
import { GradientScale } from '../../../../shared/GradientScale/GradientScale';
import { DiversificationScale } from '../../GeneralInfo/Scales/DiversificationScale';
import { InvestmentScale } from '../../GeneralInfo/Scales/InvestmentScale';
import { PerformanceChart } from '../../Performance/Chart/PerformanceChart';

type Props = {
    portfolioPerformance: PortfolioPerformanceResponse;
    investmentProfileQuery: UseQueryResult<InvestmentProfile, Error>;
    diversificationQuery: UseQueryResult<Diversification, Error>;
    portfolioLiquidity: number;
};

export const ReportGeneralInfoSecondPage: FC<Props> = ({
    portfolioPerformance,
    investmentProfileQuery,
    diversificationQuery,
    portfolioLiquidity,
}) => (
    <Stack gap={33} w={1350} p={5}>
        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={30}>
            <Text fz={36} fw={400}>
                {'Диверсифицированость портфеля'}
            </Text>
            <DiversificationScale diversification={diversificationQuery} isInReport={true} />
        </Stack>
        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={30}>
            <Text fz={36} fw={400}>
                {'Инвестиционный профиль портфеля'}
            </Text>
            <InvestmentScale investmentProfile={investmentProfileQuery} isInReport={true} />
        </Stack>
        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400} lh="38px">
                {'Ликвидность портфеля'}
            </Text>
            <GradientScale
                rightValue={0}
                leftValue={1}
                value={portfolioLiquidity}
                labels={['Ликвидный', 'Неликвидный']}
            />
        </Stack>
        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400} lh="38px">
                {'Эффективность'}
            </Text>
            <PerformanceChart
                data={portfolioPerformance.allocations ?? []}
                performanceType="STRESS_TEST_MODEL"
                isInReport={true}
            />
        </Stack>
    </Stack>
);
