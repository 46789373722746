import { httpClient } from '@configs/httpclient.config';
import { formatDateToQuery } from '@libs/utils';
import {
    type GetActualQuotesDataResponse,
    type GetProfitForPeriodRequestResponse,
    type GetActualQuotesDataReqBody,
    type GetCandlesRequestBody,
    type GetCandlesDataResponse,
    type GetProfitForPeriodRequestBody,
} from '@modules/Investorpro/types/quote.type';

const QUOTES_SERVICE_URL = '/moex-datashop-quoteservice/'

const QUOTES_URL = QUOTES_SERVICE_URL + 'api/quote/v1/'

export const getActualQuotesData = async (params: GetActualQuotesDataReqBody): Promise<GetActualQuotesDataResponse> => {
    return await httpClient.get(QUOTES_URL + 'quotes', { params })
}

export const getProfitForPeriod = async ({ signal, ...body }: GetProfitForPeriodRequestBody):
Promise<GetProfitForPeriodRequestResponse> => {
    return await httpClient.get(QUOTES_SERVICE_URL + 'api/shares/profit/v1', {
        params: body,
        signal,
    })
}

export const getCandlesData = async ({ arrayPos, secId, columns, till, from, ...body }: GetCandlesRequestBody):
Promise<GetCandlesDataResponse> => {
    return await httpClient.post(QUOTES_URL + 'candles', {
        ...body,
        till: formatDateToQuery(typeof till === 'string' ? new Date(till) : till),
        from: formatDateToQuery(typeof from === 'string' ? new Date(from) : from),
        secid: secId,
        columns: columns ?? [],
        arrayPos: arrayPos ?? ['0'],
    })
}
