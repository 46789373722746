import { FavouriteButtonModalWrapper, HelpLineWrapper } from '@libs/components';
import { type Security } from '@modules/Investorpro/modules/ScreenersPage/types';
import { FavoriteStar } from '@modules/Investorpro/shared/components/FavoriteStar';
import { useFavoriteSecurity } from '@modules/Investorpro/shared/utils/useFavouriteSecurity';

type IsFavouritePropsType = {
    security?: Security;
    isin: string;
    isDisabled?: boolean;
};

export const IsFavourite = ({ security, isin, isDisabled = false }: IsFavouritePropsType) => {
    const selected = useFavoriteSecurity(isin);

    return (
        <FavouriteButtonModalWrapper
            isin={isin}
            render={(openModal) => (
                <HelpLineWrapper
                    message={selected ? 'helpline.screeners.removeFromFavorites' : 'helpline.screeners.addToFavorites'}
                >
                    <FavoriteStar
                        active={selected}
                        onClick={!isDisabled ? openModal : undefined}
                        disabled={isDisabled}
                    />
                </HelpLineWrapper>
            )}
        />
    );
};
