import { useEffect, useState } from "react";
import { SCREEN_LG, SCREEN_MD, SCREEN_SM, SCREEN_XL } from "@libs/constants";

export const useResize = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = (event: UIEvent) => {
            setWidth((event.target as Window).innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return {
        width,
        isScreenLessSm: width < SCREEN_SM,
        isScreenSm: width >= SCREEN_SM && width < SCREEN_MD,
        isScreenMd: width >= SCREEN_MD && width < SCREEN_LG,
        isScreenLg: width >= SCREEN_LG && width < SCREEN_XL,
        isScreenXl: width >= SCREEN_XL,
    };
};

export type Resize = {
    width: number;
    isScreenLessSm: boolean;
    isScreenSm: boolean;
    isScreenMd: boolean;
    isScreenLg: boolean;
    isScreenXl: boolean;
};

export type ResizeFlags = Omit<Resize, 'width'>;
