import { Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { type InvestmentProfile, type StressScenarios } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { GradientScale } from '../../../../shared/GradientScale';
import { AssetsBehavior } from '../../StressTests/AssetBehavior/AssetsBehavior';
import { StressScenarioCard } from '../../StressTests/StressScripts/StressScenarioCard';
import { InfoIcon } from '../ReportIcons/InfoIcon';

type Props = {
    investmentProfile: InvestmentProfile;
    assetsBehavior: Array<{
        name: string;
        value: number;
    }>;
    scenarios: StressScenarios;
    scenarioUuid: string;
};

export const ReportStressTestsGeneralInfo: FC<Props> = ({
    investmentProfile,
    assetsBehavior,
    scenarios,
    scenarioUuid,
}) => (
    <Stack gap={33} w={1350} p={5}>
        <Stack
w="100%" bg="#EB2F2F1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
            <Group align="center" justify="space-between">
                <Text
fz="45px" fw={400} lh="47px"
style={{ whiteSpace: 'pre-wrap' }}>
                    {'Сводная информация по стресс тестам'}
                </Text>
                <Group
                    gap={12}
                    miw={180}
                    mih={24}
                    px={16}
                    align="center"
                    py={8}
                    style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                >
                    <InfoIcon />
                    <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                        {'Информация'}
                    </Text>
                </Group>
            </Group>
            <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px" style={{ whiteSpace: 'pre-wrap' }}>
                {
                    'В разделе стресс-тесты вы можете посмотреть, как ваш \
портфель вел себя при различных шоковых событиях в прошлом.'
                }
            </Text>
        </Stack>
        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400} lh="38px">
                {'Инвестиционный профиль портфеля'}
            </Text>
            <GradientScale
                leftValue={0}
                rightValue={-100}
                value={investmentProfile?.investmentProfile ?? 0}
                labels={[
                    'Консервативный',
                    'Умеренно консервативный',
                    'Сбалансированный',
                    'Умеренно агрессивный',
                    'Агрессивный',
                ]}
            />
        </Stack>

        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400} lh="38px">
                {'Поведение активов в портфеле в выбранном сценарии'}
            </Text>
            <AssetsBehavior assetsBehavior={assetsBehavior ?? []} isInReport={true} />
        </Stack>
        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400} lh="38px">
                {'Сценарии стресс-тестирования'}
            </Text>
            {scenarios?.content?.map((scenario) => (
                <StressScenarioCard
                    key={scenario.uuid}
                    scenario={scenario}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={() => {}}
                    isSelected={scenario.uuid === scenarioUuid}
                />
            ))}
        </Stack>
    </Stack>
);
