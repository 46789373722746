import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getStockQuotesThunk, type RootState, useAppDispatch } from '@store/store';
import { type Client } from '@stomp/stompjs';
import { setQuotes } from '@store/store/slices/investorpro/stock.slice';
import { SelectedButtonTabs } from '@modules/Investorpro/shared/components/SelectedButtonTabs';
import { InstrumentEngineType, type WebSocketQuote, type QuoteType } from '@modules/Investorpro/types/quote.type';
import { SecurityMarketType } from '@libs/types';
import { getCandleSocketTicker } from '@modules/Investorpro/shared/utils/service.utils';
import { SecurityBoards } from '@modules/Investorpro/modules/MarketPages/shared/constants';
import { format } from 'date-fns';

import styles from './styles.module.scss';
import { Quotes } from './components/Quotes';
import { Trades } from './components/Trades';

type Props = {
    ticker: string | undefined;
    client: Client;
    isConnected: boolean;
};

export const TableBlock = ({ ticker, client, isConnected }: Props) => {
    const { quotes } = useSelector((state: RootState) => state.stock);
    const { data, loaded } = quotes;

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isConnected && ticker) {
            const subscription = client.subscribe(
                '/topic/quotes/' +
                    getCandleSocketTicker({
                        secId: ticker,
                        market: SecurityMarketType.STOCK,
                        board: SecurityBoards.TQBR,
                    }),
                (message) => {
                    const body = JSON.parse(message.body) as WebSocketQuote[];

                    if (body.length > 0) {
                        const payload: QuoteType[] = body.map((quote) => {
                            const dateTime = new Date(quote.timestamp);

                            return {
                                price: quote.price,
                                quantity: quote.quantity,
                                buysell: quote.buysell,
                                updateTime: format(dateTime, 'HH:mm:ss'),
                            };
                        });
                        dispatch(setQuotes(payload));
                    }
                },
            );

            return subscription.unsubscribe;
        }
    }, [isConnected, ticker, client, dispatch]);

    useEffect(() => {
        if (ticker && !loaded) {
            dispatch(
                getStockQuotesThunk({
                    secId: ticker,
                    engine: InstrumentEngineType.STOCK,
                    market: SecurityMarketType.STOCK,
                    board: SecurityBoards.TQBR,
                }),
            );
        }
    }, [ticker, dispatch, loaded]);

    const options = [
        {
            label: 'Стакан',
            value: 'quotes',
            tab: <Quotes data={data} />,
        },
        {
            label: 'Торги',
            value: 'trades',
            tab: <Trades data={data} />,
        },
    ];

    const [currentTab, setCurrentTab] = useState(options[0].value);
    const currentOption = options.find((option) => option.value === currentTab);

    return (
        <SelectedButtonTabs
            options={options}
            value={currentTab}
            className={styles.tabSelector}
            handleChange={setCurrentTab}
            title={'График ' + ticker ?? ''}
        >
            {currentOption?.tab}
        </SelectedButtonTabs>
    );
};
