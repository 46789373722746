import { Group, LoadingOverlay, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { type FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { NEW_REPORT_ITEMS, type NewReportItem, ReportItemType } from '../../../entities';
import { type InferParams } from '../../../shared/router';
import { useGetReportSettingsQuery } from './useGetReportSettingsQuery';
import { useUpdateReportSettingsMutation } from './useUpdateReportSettingsMutation';
import { ControlButtons } from './ControlButtons';
import { DragArea } from './DragArea';
import { PdfShaper } from './PdfShaper';
import {
    type ReportFormFinalValues,
    type ReportFormTitleValues,
    reportFormFinalSchema,
    reportFormTitleSchema,
} from './ReportForm.schema';
import { ReportFormattingSettings } from './ReportFormattingSettings';

export type LoadedStatus = 'error' | 'success' | 'pending' | '';

export const ReportLayout: FC = () => {
    const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;
    const portfolioSettingsQuery = useGetReportSettingsQuery(portfolioId);
    const updateReportSettingsMutation = useUpdateReportSettingsMutation();
    const [titleValues, setTitleValues] = useState<ReportFormTitleValues>(reportFormTitleSchema.getDefault());
    const [finalValues, setFinalValues] = useState<ReportFormFinalValues>(reportFormFinalSchema.getDefault());
    const [selectedReportItem, setSelectedReportItem] = useState<NewReportItem>();
    const [showSelectedReportItem, setShowSelectedReportItem] = useState(false);
    const [editSelectedReportItem, setEditSelectedReportItem] = useState(false);
    const [fullReportModalOpened, { open: fullReportModalOpen, close: fullReportModalClose }] = useDisclosure(false);
    const [isFullReportDownloading, setIsFullReportDownloading] = useState(false);
    const [isAllScreensReady, setIsAllScreensReady] = useState(false);
    const [isDownloadReportAvailable, setIsDownloadReportAvailable] = useState(false);

    const [componentsDataLoadedStatus, setComponentsDataLoadedStatus] = useState<Record<ReportItemType, LoadedStatus>>({
        [ReportItemType.ASSET_ALLOCATION]: '',
        [ReportItemType.ASSET_TYPOLOGY]: '',
        [ReportItemType.CASH_FLOW_AVERAGE]: '',
        [ReportItemType.CASH_FLOW_CUMULATIVE]: '',
        [ReportItemType.CORRELATION]: '',
        [ReportItemType.DIVERSIFICATION]: '',
        [ReportItemType.DYNAMICS]: '',
        [ReportItemType.HISTORIC_RETURNS]: '',
        [ReportItemType.LIQUIDITY]: '',
        [ReportItemType.MAXIMUM_DRAWDOWN]: '',
        [ReportItemType.PERFORMANCE_DRAWDOWN]: '',
        [ReportItemType.PERFORMANCE_VOLATILITY]: '',
        [ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS]: '',
        [ReportItemType.RISK_METRICS]: '',
        [ReportItemType.STRESS_TEST_SCENARIOS]: '',
        [ReportItemType.STRESS_TESTS_SUMMARY]: '',
        [ReportItemType.SUMMARY_ANALYTICS]: '',
    });

    const [comments, setComments] = useState<Record<ReportItemType, string>>({
        [ReportItemType.ASSET_ALLOCATION]: '',
        [ReportItemType.ASSET_TYPOLOGY]: '',
        [ReportItemType.CASH_FLOW_AVERAGE]: '',
        [ReportItemType.CASH_FLOW_CUMULATIVE]: '',
        [ReportItemType.CORRELATION]: '',
        [ReportItemType.DIVERSIFICATION]: '',
        [ReportItemType.DYNAMICS]: '',
        [ReportItemType.HISTORIC_RETURNS]: '',
        [ReportItemType.LIQUIDITY]: '',
        [ReportItemType.MAXIMUM_DRAWDOWN]: '',
        [ReportItemType.PERFORMANCE_DRAWDOWN]: '',
        [ReportItemType.PERFORMANCE_VOLATILITY]: '',
        [ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS]: '',
        [ReportItemType.RISK_METRICS]: '',
        [ReportItemType.STRESS_TEST_SCENARIOS]: '',
        [ReportItemType.STRESS_TESTS_SUMMARY]: '',
        [ReportItemType.SUMMARY_ANALYTICS]: '',
    });

    const [reportSettings, setReportSettings] = useState<string[]>([]);
    const [notInReportItems, setNotInReportItems] = useState<NewReportItem[]>([]);
    const [inReportItems, setInReportItems] = useState<NewReportItem[]>([]);

    const moveToOpposite = useCallback((item: NewReportItem, isInReport: boolean) => {
        if (isInReport) {
            setInReportItems((prevState) => prevState.filter(({ id }) => id !== item.id));
            setNotInReportItems((prevState) => [...prevState, item]);
        }

        if (!isInReport) {
            setNotInReportItems((prevState) => prevState.filter(({ id }) => id !== item.id));
            setInReportItems((prevState) => [...prevState, item]);
        }
    }, []);

    const onCommentsChange = (id: ReportItemType, comment: string) => {
        setComments((prevState) => ({
            ...prevState,
            [id]: comment,
        }));
    };

    const handleReportPageEdit = useCallback((item: NewReportItem) => {
        setSelectedReportItem(item);
        setEditSelectedReportItem(true);
    }, []);

    const handleReportPageShow = useCallback((item: NewReportItem) => {
        setSelectedReportItem(item);
        setShowSelectedReportItem(true);
    }, []);

    const handleFullReportSave = () => {
        const options = JSON.stringify({
            inReport: inReportItems.map((el) => ({
                name: el.id,
                comment: comments[el.id],
            })),
            outReport: notInReportItems.map((el) => ({
                name: el.id,
                comment: comments[el.id],
            })),
            reportSettings,
            titleVals: titleValues,
            finalVals: finalValues,
        });

        updateReportSettingsMutation.mutate({ portfolioId, options });
        setIsFullReportDownloading(true);
    };

    useEffect(() => {
        if (portfolioSettingsQuery.isSuccess) {
            if (portfolioSettingsQuery.data.inReport.length !== 0) {
                const inReport = portfolioSettingsQuery.data.inReport.reduce<{
                    ReportItem: NewReportItem[];
                    Comments: Record<ReportItemType, string>;
                }>(
                    (acc, cur) => {
                        const findItem = NEW_REPORT_ITEMS.find((item) => item.id === cur.name);

                        if (findItem) {
                            acc.ReportItem.push({
                                ...findItem,
                            });
                        }

                        if (cur.comment) acc.Comments[cur.name] = cur.comment;

                        return acc;
                    },
                    { ReportItem: [], Comments: {} as Record<ReportItemType, string> },
                );

                setInReportItems(inReport.ReportItem ?? []);
                setComments((prevState) => ({ ...prevState, ...inReport.Comments }));
            }

            if (portfolioSettingsQuery.data.outReport.length !== 0) {
                const outReport = portfolioSettingsQuery.data.outReport.reduce<{
                    ReportItem: NewReportItem[];
                    Comments: Record<ReportItemType, string>;
                }>(
                    (acc, cur) => {
                        const findItem = NEW_REPORT_ITEMS.find((item) => item.id === cur.name);

                        if (findItem) {
                            acc.ReportItem.push({
                                ...findItem,
                            });
                        }

                        if (cur.comment) acc.Comments[cur.name] = cur.comment;

                        return acc;
                    },
                    { ReportItem: [], Comments: {} as Record<ReportItemType, string> },
                );

                setNotInReportItems(outReport.ReportItem ?? []);
                setComments((prevState) => ({ ...prevState, ...outReport.Comments }));
            }

            if (portfolioSettingsQuery.data.reportSettings) {
                setReportSettings(portfolioSettingsQuery.data.reportSettings);
            }

            if (portfolioSettingsQuery.data.titleVals) setTitleValues(portfolioSettingsQuery.data.titleVals);

            if (portfolioSettingsQuery.data.finalVals) setFinalValues(portfolioSettingsQuery.data.finalVals);
        }
    }, [portfolioSettingsQuery.data, portfolioSettingsQuery.isSuccess]);

    useEffect(() => {
        setIsAllScreensReady(
            inReportItems.every(
                ({ id }) => componentsDataLoadedStatus[id] === 'error' || componentsDataLoadedStatus[id] === 'success',
            ),
        );
        setIsDownloadReportAvailable(false);

        if (
            inReportItems.some(({ id }) => componentsDataLoadedStatus[id] === 'success') ||
            reportSettings.includes('withPageTitle') ||
            reportSettings.includes('withFinalPage')
        ) {
            setIsDownloadReportAvailable(true);
        }
    }, [inReportItems, componentsDataLoadedStatus, reportSettings]);

    return (
        <Fragment>
            <LoadingOverlay visible={isFullReportDownloading || portfolioSettingsQuery.isLoading} />
            <Group
gap={32} w="100%" wrap="nowrap"
align="flex-start" justify="space-between">
                <Group gap={0} flex={1} wrap="nowrap">
                    <DragArea
                        isInReport={false}
                        key="notInReport"
                        moveToOpposite={moveToOpposite}
                        onEditClick={handleReportPageEdit}
                        onShowClick={handleReportPageShow}
                        items={notInReportItems}
                        setReportItems={setNotInReportItems}
                        componentsDataLoadedStatus={componentsDataLoadedStatus}
                    />
                    <DragArea
                        isInReport={true}
                        key="inReport"
                        moveToOpposite={moveToOpposite}
                        onEditClick={handleReportPageEdit}
                        onShowClick={handleReportPageShow}
                        items={inReportItems}
                        setReportItems={setInReportItems}
                        componentsDataLoadedStatus={componentsDataLoadedStatus}
                    />
                </Group>
                <Stack gap={30}>
                    <ControlButtons
                        isDownloadReportAvailable={isDownloadReportAvailable}
                        isAllScreensReady={isAllScreensReady}
                        finalValues={finalValues}
                        onFinalValuesChange={setFinalValues}
                        onFullReportSave={handleFullReportSave}
                        onFullReportShow={fullReportModalOpen}
                        onTitleValuesChange={setTitleValues}
                        titleValues={titleValues}
                    />
                    <ReportFormattingSettings settings={reportSettings} onSettingsChange={setReportSettings} />
                </Stack>
            </Group>

            <PdfShaper
                comments={comments}
                onCommentsChange={onCommentsChange}
                finalValues={finalValues}
                fullReportModalClose={fullReportModalClose}
                fullReportModalOpened={fullReportModalOpened}
                inReportItems={inReportItems}
                isFullReportDownloading={isFullReportDownloading}
                onFullReportDownload={() => setIsFullReportDownloading(false)}
                onComponentsDataLoadedStatusChange={setComponentsDataLoadedStatus}
                selectedReportItem={selectedReportItem}
                reportSettings={reportSettings}
                titleValues={titleValues}
                showSelectedReportItem={showSelectedReportItem}
                editSelectedReportItem={editSelectedReportItem}
                onShowSelectedReportItemChange={setShowSelectedReportItem}
                onEditSelectedReportItemChange={setEditSelectedReportItem}
            />
        </Fragment>
    );
};
