import { configureStore } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import {
    cardReducer,
    loadingReducer,
    userReducer,
    stockReducer,
    bondReducer,
    screenersReducer,
    helpLineReducer,
    investorProfileReducer,
    alertsReducer,
    permissionsReducer,
} from './slices';
import { technicalReducer } from './slices/technical.slice';

const store = configureStore({
    reducer: {
        user: userReducer,
        loading: loadingReducer,
        card: cardReducer,
        technical: technicalReducer,
        stock: stockReducer,
        bond: bondReducer,
        investorProfile: investorProfileReducer,
        helpline: helpLineReducer,
        screeners: screenersReducer,
        alerts: alertsReducer,
        permissions: permissionsReducer,
    },
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export { store };

export * from './thunk';
