import { MarkType } from "@libs/components/misc/ProductCard/Mark/types";

export type CardData = {
  logo: string;
  content: string;
  marks: MarkType[];
  link?: string;
  image: string;
};

export enum ProductMnemoCodes {
    CKI = "ЦКИ",
    MOEX_DATA = "MOEX_DATA",
    INVESTOR_PRO = "Investor_PRO",
    MARKET_DIVE = "Market_Dive",
    COMPLIENCE_TOOL = "Complience_tool",
    PRO_PACK = "PRO_Pack",
    TPC_PRODUKT_ALGO_PACK = "TPC_PRODUKT_ALGO_PACK",
    ALGOPACK = "ALGOPACK",
    TPC_PRICE_CENTER = "TPC_PRICE_CENTER",
};
