import { type SortOrderTypes } from '@libs/types';
import { Security } from '../modules/ScreenersPage/types/investorprofile.types';

export type Watchlist = {
    id: number;
    name: string;
    count: number;
    securities: Security[];
};

export enum SortFields {
    SHORTNAME = 'shortName',
    CLOSE = 'close',
    CHANGE1D = 'change1D',
    CHANGE1M = 'change1M',
    CHANGE1Y = 'change1Y',
    CHANGE_SINCE_YEAR = 'changeSinceYear',
    MCAP = 'mcap',
}

export type WatchlistDataReq = {
    sortField: keyof WatchlistRow;
    sortOrder: SortOrderTypes;
};

export type WatchlistRow = {
    isin: string;
    seqId: string;
    shortName: string;
    close: number;
    change1D: number;
    change1M: number;
    change1Y: number;
    changeSinceYear: number;
    updateDate: string;
    type: 'STOCK' | 'BOND';
};

export type CopyWatchlistReq = {
    watchlistId: number;
    name: string;
};
