import { getStockCandlesThunk, type RootState, useAppDispatch } from '@store/store';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { type Client } from '@stomp/stompjs';
import { useChoosePeriod } from '@libs/utils';
import { DatePeriodSelector } from '@libs/components';
import { type CandleType } from '@modules/Investorpro/types/quote.type';
import { getCandleSocketTicker } from '@modules/Investorpro/shared/utils/service.utils';
import { SecurityMarketType } from '@libs/types';
import {
    setCandlesLoadedStatus,
    setCandlesOptions,
    setCandlesPeriod,
    setCandlesData,
    updateLastCandleData,
} from '@store/store/slices/investorpro/stock.slice';
import { SecurityBoards } from '@modules/Investorpro/modules/MarketPages/shared/constants';
import { SUBSCRIBE_TO_CANDLES_SOCKET_URL } from '@modules/Investorpro/constants';
import { type Period } from '@libs/utils/hooks/useChoosePeriod';
import { type PeriodId } from '@libs/types/instrument.type';
import { WithLoader } from '@libs/components/loaders/ViewLoader';

import styles from './styles.module.scss';
import { CandleChart } from './components/CandleChart';
import { INTERVAL_OPTIONS } from './constants';

type Props = {
    ticker: string | undefined;
    client: Client;
    isConnected: boolean;
};

export const Candle = ({ client, ticker, isConnected }: Props) => {
    const { data, loaded, period, currentPeriodOption, initialized } = useSelector(
        (state: RootState) => state.stock.candles,
    );
    const { getStockCandles: isCandlesLoading } = useSelector((state: RootState) => state.stock.loadings);
    const lastTradeDate = useSelector((state: RootState) => state.stock.info.updatedAt);

    const dispatch = useAppDispatch();

    const { options, handleOptionChange } = useChoosePeriod({
        handleOptionChange: (option: string | null) => dispatch(setCandlesOptions(option)),
        handleChangePeriod: (period: Period) => dispatch(setCandlesPeriod(period)),
        intervalTypes: INTERVAL_OPTIONS,
        withAll: true,
        endDate: lastTradeDate ? new Date(lastTradeDate) : undefined,
    });

    useEffect(() => {
        if (!loaded && ticker && lastTradeDate) {
            dispatch(
                getStockCandlesThunk({
                    from: period.from,
                    till: period.to,
                    interval: period.interval,
                    secId: ticker,
                    intervalType: currentPeriodOption as PeriodId,
                }),
            );
        }
    }, [dispatch, loaded, currentPeriodOption, ticker, lastTradeDate]);

    const handlePeriodChange = (value: string | null) => {
        handleOptionChange(value);
        dispatch(setCandlesLoadedStatus(false));
    };

    useEffect(() => {
        if (isConnected && ticker) {
            const subscription = client.subscribe(
                SUBSCRIBE_TO_CANDLES_SOCKET_URL +
                    getCandleSocketTicker({
                        secId: ticker,
                        market: SecurityMarketType.STOCK,
                        board: SecurityBoards.TQBR,
                    }),
                (message: any) => {
                    const body = JSON.parse(message.body);

                    if (body.data.length > 0) {
                        dispatch(updateLastCandleData(body.data));
                    }
                },
            );

            return subscription.unsubscribe;
        }
    }, [isConnected, client, ticker, dispatch, loaded]);

    return (
        <div className={styles.candles}>
            <WithLoader isLoading={isCandlesLoading} hideChildrenOnLoading={!initialized}>
                <CandleChart
                    width={1388}
                    intervalType={period.interval}
                    height={611}
                    currentPeriodOption={currentPeriodOption}
                    data={data}
                />
            </WithLoader>
            <div className={styles.periodSelector}>
                <DatePeriodSelector
                    disabled={isCandlesLoading}
                    options={options}
                    currentOption={currentPeriodOption}
                    handlePeriodOptionChange={handlePeriodChange}
                />
            </div>
        </div>
    );
};
