import { StompProvider } from '@libs/utils';
import { useHelplineDefaultMessage } from '@libs/utils/hooks/useDefaultMessage';

import { TotalMarketDashboard } from './components/TotalMarketDashboard';
import styles from './styles.module.scss';

export const TotalMarketPage = () => {
    useHelplineDefaultMessage('helpline.marketDashboards.total.page');

    return (
        <div className={styles.totalMarketDashboardWrapper}>
            <StompProvider url={process.env.REACT_APP_WS_QUOTESERVICE_URL!} autoConnect>
                <TotalMarketDashboard />
            </StompProvider>
        </div>
    );
};
