import { StyleSheet } from '@react-pdf/renderer';

export const tableOfContentsStyles = StyleSheet.create({
    page: {
        fontFamily: 'Inter',
        padding: 30,
        color: '#14171F',
    },
    title: {
        color: '#1417F',
        font: 'Inter',
        fontSize: '12px',
        paddingBottom: '10px',
        borderBottom: '1px solid #BFC9D6',
        marginBottom: 20,
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    block: {
        display: 'flex',
        borderTop: ' 1px solid  #14171F',
        width: '48%',
        height: '80px',
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingTop: '10px',
    },
    itemTitle: {
        fontSize: '16px',
        marginRight: '40px',
    },
    pageNumber: {
        fontSize: '32px',
        fontWeight: 100,
    },
});

export const componentPdfStyles = StyleSheet.create({
    page: { marginLeft: 20, paddingBottom: 30, paddingRight: 20 },
    section: { color: 'white', margin: 30 },
    title: {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: 400,
        marginBottom: 8,
    },
    subTitle: {
        fontFamily: 'Inter',
        fontSize: '10px',
        fontWeight: 400,
    },
    componentScreen: {
        marginTop: 20,
        marginBottom: 0,
        width: 560,
        maxHeight: 780,
    },
    pageNumber: {
        fontSize: '16px',
        fontWeight: 100,
    },
});
