import { Box, Group, SimpleGrid, Stack, Text, Tooltip } from '@mantine/core';
import { ViewError } from '@modules/Investorpro/modules/PortfolioPage/shared/Layout';
import { type FC, Fragment } from 'react';

type Props = {
    assetsBehavior: Array<{
        name: string;
        value: number;
    }>;
    isInReport?: boolean;
    error?: Error | null;
};

export const AssetsBehavior: FC<Props> = ({ assetsBehavior, isInReport, error }) => {
    if (error) {
        return (
            <Stack>
                <Text fz={19} fw={400}>
                    {'Поведение активов в портфеле в выбранном сценарии'}
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по поведению активов" />
            </Stack>
        );
    }

    return (
        <Stack miw={344}>
            {!isInReport
? (
                <Text w="100%" fz={19} fw={400}>
                    {'Поведение активов в портфеле в выбранном сценарии'}
                </Text>
            )
: null}
            <SimpleGrid
w="100%" cols={3} spacing={0}
verticalSpacing={0}>
                {assetsBehavior?.map((item) => (
                    <Fragment key={item.name}>
                        <Tooltip label={item.name} position="top-start">
                            <Text
fz={15} fw={300} truncate="end"
style={{ alignSelf: 'center' }}>
                                {item.name}
                            </Text>
                        </Tooltip>
                        <Group justify="end" wrap="nowrap" pos="relative">
                            {Number(item.value) < 0
? (
                                <Fragment>
                                    <Text
                                        h={35}
                                        style={{
                                            right: `calc(${-Number(item.value < -100 ? -50 : item.value / 2)}% + 10px)`,
                                            position: 'absolute',
                                            color: '#E42821',
                                            top: '6px',
                                        }}
                                    >
                                        {item.value
                                            ? `${item.value.toLocaleString('ru', { maximumFractionDigits: 2 })}%`
                                            : ''}
                                    </Text>
                                    <Box
                                        h={32}
                                        bg="#F2433D"
                                        w={`calc(${Math.abs(item.value < -100 ? -50 : item.value / 2 ?? 0)}%)`}
                                        style={{ borderRadius: '2px 0px 0px 2px' }}
                                    />
                                </Fragment>
                            )
: null}
                        </Group>
                        <Group
wrap="nowrap" h={35} pos="relative"
style={{ borderLeft: '2px solid #D8E1EB' }}>
                            {Number(item.value) >= 0
? (
                                <Fragment>
                                    <Box
                                        h={32}
                                        bg="#42A62E"
                                        w={`calc(${item.value > 100 ? 50 : item.value / 2}%)`}
                                        style={{ borderRadius: '0px 2px 2px 0px' }}
                                    />
                                    <Text
                                        style={{
                                            left: `calc(${item.value > 100 ? 50 : item.value / 2}% + 10px)`,
                                            position: 'absolute',
                                            color: '#288C14',
                                            top: '6px',
                                        }}
                                    >
                                        {item.value
                                            ? `+${item.value.toLocaleString('ru', { maximumFractionDigits: 2 })}%`
                                            : ''}
                                    </Text>
                                </Fragment>
                            )
: null}
                        </Group>
                    </Fragment>
                ))}
            </SimpleGrid>
        </Stack>
    );
};
