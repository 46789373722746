import { Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import {
    type IntraPortfolioCorrelation,
    type LeastCorrelationToolsResponse,
    type MostCorrelationToolsResponse,
} from '../../../../entities/portfolio';
import { GradientScale } from '../../../../shared/GradientScale';
import { ViewEmptyStub } from '../../../../shared/Layout';
import { Table } from '../../../../shared/Table';
import { useDiversificationColumns } from '../../Diversification/useDiversificationColumns';
import { InfoIcon } from '../ReportIcons/InfoIcon';

type Props = {
    leastCorrelatingTools: LeastCorrelationToolsResponse;
    intraPortfolioCorrelation: IntraPortfolioCorrelation;
    mostCorrelatingTools: MostCorrelationToolsResponse;
};

export const ReportCorrelation: FC<Props> = ({
    leastCorrelatingTools,
    intraPortfolioCorrelation,
    mostCorrelatingTools,
}) => {
    const columns = useDiversificationColumns();

    return (
        <Stack gap={33} w={1350} p={5}>
            <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
                <Group align="center" justify="space-between">
                    <Text
fz="45px" fw={400} lh="47px"
style={{ whiteSpace: 'pre-wrap' }}>
                        {'Корреляция инструментов в портфеле'}
                    </Text>
                    <Group
                        gap={12}
                        miw={180}
                        mih={24}
                        px={16}
                        align="center"
                        py={8}
                        style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                    >
                        <InfoIcon />
                        <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                            {'Информация'}
                        </Text>
                    </Group>
                </Group>
                <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px" style={{ whiteSpace: 'pre-wrap' }}>
                    {
                        'В разделе Корреляция вы можете проанализировать, насколько инструменты в \
портфеле коррелируют, т.е. связаны, между собой. Как правильно, чем выше общая (внутрипортфельная) \
корреляция, тем ниже диверсификация портфеля. Если это так, то вы сможете добавить инструменты \
с низкой корреляцией, чтобы повысить диверсификацию.'
                    }
                </Text>
            </Stack>
            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'Внутрипортфельная корреляция'}
                </Text>
                <GradientScale
                    rightValue={1}
                    leftValue={0}
                    value={intraPortfolioCorrelation?.correlation ?? 0}
                    labels={['Полностью диверсифицирован', 'Не диверсифицирован']}
                />
            </Stack>

            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'ТОП 5 наиболее коррелирующих инструментов'}
                </Text>
                <Table
                    columns={columns}
                    data={mostCorrelatingTools.mostCorrelatingInstruments}
                    striped={true}
                    emptyStub={<ViewEmptyStub title="Данных по наиболее коррелирующим инструментам нет" />}
                />
            </Stack>

            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'ТОП 5 наименее коррелирующих инструментов'}
                </Text>
                <Table
                    columns={columns}
                    data={leastCorrelatingTools.leastCorrelatingInstruments}
                    striped={true}
                    emptyStub={<ViewEmptyStub title="Данных по наименее коррелирующим инструментам нет" />}
                />
            </Stack>
        </Stack>
    );
};
