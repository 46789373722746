import { type FC } from 'react';

export const TelevisionRadioIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path
d="M12 27.7844H16.652" stroke="#636E80" strokeWidth="1.3"
strokeLinecap="round" />
            <path
                d="M12.0066 27.6086L13.5 21.5M16.6332 27.6086L15.1796 22.5211"
                stroke="#636E80"
                strokeWidth="1.2"
                strokeLinecap="round"
            />
            <circle
                cx="23.1663"
                cy="10.7047"
                r="1.15892"
                transform="rotate(45.8325 23.1663 10.7047)"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
                d="M26.8356 10.9576C26.8431 10.4439 26.7493 9.93373 26.5596 9.45625C26.3699 8.97876 26.088 8.54332
                25.73 8.17478C25.372 7.80623 24.945 7.51181 24.4732 7.30831C24.0014 7.10482 23.4942 6.99624 22.9805
                6.98878"
                stroke="#636E80"
                strokeWidth="1.2"
                strokeLinecap="round"
            />
            <path
                d="M29.6526 11.5893C29.6665 10.6322 29.4918 9.68171 29.1384 8.79213C28.7849 7.90254 28.2598
                7.09128 27.5928 6.40467C26.9259 5.71805 26.1302 5.16952 25.2513 4.7904C24.3723 4.41128
                23.4273 4.20899 22.4702 4.19508"
                stroke="#636E80"
                strokeWidth="1.2"
                strokeLinecap="round"
            />
            <path
                d="M19.9294 13.8485C21.5139 15.4798 22.7359 17.1309 23.437 18.4684C23.7894 19.1407 23.9936
                19.7025 24.0616 20.1242C24.1328 20.5663 24.0362 20.7108 24.0014 20.7446C22.5515 22.1529
                20.1682 23.2904 18.1882 23.2242C16.165 23.1567 14.2152 22.287 12.7719 20.8011C11.3285
                19.3151 10.5158 17.3409 10.5071 15.3166C10.4985 13.3356 11.7047 10.9862 13.1545
                9.57794C13.1894 9.54406 13.3366 9.45174 13.7764 9.5358C14.196 9.61599 14.7516
                9.8364 15.4134 10.2082C16.73 10.9478 18.3449 12.2173 19.9294 13.8485Z"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
                d="M24.0014 20.7446C23.9665 20.7786 23.8192 20.8709 23.3794 20.7868C22.9599 20.7066
                22.4042 20.4862 21.7424 20.1144C20.4259 19.3748 18.811 18.1053 17.2265 16.474C15.642
                14.8428 14.4199 13.1917 13.7189 11.8542C13.3664 11.1819 13.1623 10.6201 13.0943
                10.1984C13.0231 9.75637 13.1196 9.61184 13.1545 9.57794C13.1894 9.54404 13.3367
                9.45172 13.7765 9.53577C14.196 9.61595 14.7517 9.83636 15.4135 10.2082C16.73
                10.9478 18.3449 12.2173 19.9294 13.8485C21.5139 15.4798 22.736 17.1309 23.437
                18.4683C23.7895 19.1407 23.9936 19.7025 24.0616 20.1242C24.1328 20.5662 24.0363
                20.7107 24.0014 20.7446Z"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
d="M21.5741 11.4718L16.809 12.9836" stroke="#636E80" strokeWidth="1.2"
strokeLinecap="round" />
            <path
d="M22.3529 12.2738L20.7033 16.9929" stroke="#636E80" strokeWidth="1.2"
strokeLinecap="round" />
        </g>
    </svg>
);
