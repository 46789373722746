import { type SelectedSecurity, type BondMarketData, SecurityMarketType } from '@libs/types';
import { useState, useEffect } from 'react';
import { useAppDispatch } from '@store/store';
import { useCurrentPriceUpdate } from '@modules/Investorpro/modules/MarketPages/shared/utils/useCurrentPriceUpdate';
import { InstrumentEngineType } from '@modules/Investorpro/types/quote.type';
import { getTopBondsByVolume } from '@libs/services/instrument.service';
import { LOADING_KEY } from '@modules/Investorpro/modules/MarketPages/StockMarketPage/components/StockMarketDashboard/constants';
import { refetchDataUntilFindNotEmpty } from '@modules/Investorpro/modules/MarketPages/shared/utils/fetch.utils';
import { getPrevTradeDayDate } from '@modules/Investorpro/modules/MarketPages/shared/utils/date.utils';
import { formatDateToQuery } from '@libs/utils';
import { SecurityBoards } from '@modules/Investorpro/modules/MarketPages/shared/constants';
import { useLoading } from '@libs/utils/hooks/useLoading';

import { TOP_SECURITIES_COLUMNS } from './columns';
import styles from './styles.module.scss';
import { TableWithCheckBox } from '../../../../../shared/components/TableWithCheckBox';

type Props = {
    selectedBonds: SelectedSecurity[];
    handleSelectBond: (bond: SelectedSecurity) => void;
    isMaxSelected: boolean;
    disabled: boolean;
};

export const TopBonds = ({ selectedBonds, handleSelectBond, isMaxSelected, disabled }: Props) => {
    const [topBonds, setTopBonds] = useState<BondMarketData[]>([]);
    const dispatch = useAppDispatch();
    const { startLoading, stopLoading } = useLoading(LOADING_KEY);

    useEffect(() => {
        startLoading();

        const loadData = async () => {
            try {
                const [bondsData] = await refetchDataUntilFindNotEmpty({
                    fetchData: async (date) => await getTopBondsByVolume(date),
                    start: getPrevTradeDayDate(),
                    step: 1,
                    format: formatDateToQuery,
                    responseDataAccessor: (resp) => resp.data.bondsBidding,
                });

                const repeatedBonds: Record<string, number> = {};
                const topBondsData = bondsData
                    .filter(({ boardId }) => boardId === SecurityBoards.TQOB)
                    .filter(({ secId }) => {
                        repeatedBonds[secId] = (repeatedBonds[secId] || 0) + 1;

                        return repeatedBonds[secId] === 1;
                    })
                    .sort((a, b) => b.volume - a.volume)
                    .slice(0, 10);

                const topBonds: BondMarketData[] = topBondsData.map(({ secId, boardId, shortName, last, volume }) => ({
                    secId,
                    name: shortName,
                    board: boardId,
                    price: last,
                    market: SecurityMarketType.BOND,
                    engine: InstrumentEngineType.STOCK,
                    prevTradeDayPrice: last,
                    vol: volume,
                    type: SecurityMarketType.STOCK,
                    changeRelative: 0,
                }));

                setTopBonds(topBonds);
            } catch (error) {
                console.log('e', error);
            } finally {
                stopLoading();
            }
        };

        loadData();
    }, [dispatch]);

    useCurrentPriceUpdate({
        securities: topBonds,
        updateSecurities: setTopBonds,
    });

    return (
        <div className={styles.topBonds}>
            <h2>Топ облигаций по объему торгов</h2>
            <TableWithCheckBox
                data={topBonds}
                colorOffsetIndex={3}
                columns={TOP_SECURITIES_COLUMNS}
                selected={selectedBonds}
                handleSelect={handleSelectBond}
                isMaxSelected={isMaxSelected}
                disabled={disabled}
            />
        </div>
    );
};
