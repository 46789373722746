import { Table } from '@mantine/core';
import { type Row } from '@tanstack/react-table';

import { TableCell } from './TableCell';
import { type HoveredIds } from '.';

type Props<T extends Record<string, unknown>> = {
    row: Row<T>;
    selected?: boolean;
    onCellMouseEnter: (ids: HoveredIds) => void;
    onCellMouseLeave: () => void;
    hoveredIds: HoveredIds;
    highlightColumnOnHover?: boolean;
};

export const TableRow = <TableData extends Record<string, unknown>>({
    row,
    onCellMouseEnter,
    onCellMouseLeave,
    hoveredIds,
    highlightColumnOnHover,
}: Props<TableData>) => (
    <Table.Tr>
        {row.getVisibleCells().map((cell) => (
            <TableCell
                key={cell.id}
                cell={cell}
                onCellMouseEnter={onCellMouseEnter}
                onCellMouseLeave={onCellMouseLeave}
                hoveredIds={hoveredIds}
                highlightColumnOnHover={highlightColumnOnHover}
            />
        ))}
    </Table.Tr>
);
