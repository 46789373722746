import { Tabs as TabsComponent } from '@modules/Investorpro/shared/components/Tabs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState, getStockDescriptionInfoThunk } from '@store/store';
import { useParams } from 'react-router-dom';
import { WithLoader } from '@libs/components/loaders/ViewLoader';
import { useLoading } from '@libs/utils/hooks/useLoading';

import { ChartTab } from './components/ChartTab';
import { BaseInformationTab } from './components/BaseInformationTab';
import { FinancialReportTab } from './components/FinancialReportTab';
import { DividendsTab } from './components/DividentsTab';
import { NewsTab } from './components/NewsTab';
import styles from './styles.module.scss';
import { GET_STOCK_DESCRIPTION_INFO_LOADING_KEY } from '../../constants';

export const Tabs = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const { dispatch, startLoading, stopLoading } = useLoading(GET_STOCK_DESCRIPTION_INFO_LOADING_KEY);
    const { fininstId, fintoolId, loadings, dividends } = useSelector((state: RootState) => state.stock);
    const { getStockDividends, getStockDividendsGap, getStockQuotes } = loadings;
    const { ticker } = useParams();

    useEffect(() => setCurrentTab(0), [ticker]);

    useEffect(() => {
        if (fininstId && ticker && fintoolId) {
            startLoading();
            dispatch(getStockDescriptionInfoThunk({ fininstId, secId: ticker, fintoolId })).finally(() => {
                stopLoading();
            });
        }
    }, [fininstId, fintoolId]);

    const isDividendsTabLoading = getStockDividends && !dividends.initialized;

    const tabs = {
        'Базовая информация': <BaseInformationTab handleChangeTab={setCurrentTab} />,
        Дивиденды: (
            <WithLoader isLoading={isDividendsTabLoading} notRenderChildrenOnLoading={true}>
                <DividendsTab />
            </WithLoader>
        ),
        'Финансовая отчетность': <FinancialReportTab />,
        График: (
            <WithLoader isLoading={getStockQuotes} notRenderChildrenOnLoading={true}>
                <ChartTab />
            </WithLoader>
        ),
        'Новости и публикации': <NewsTab />,
    };

    return (
        <TabsComponent
            tabs={tabs}
            navContentClassName={styles.tabsWrapper}
            current={currentTab}
            handleTabChange={setCurrentTab}
        />
    );
};
