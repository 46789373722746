import { type MaxRisk } from '../../../../../PortfolioPage/entities';
import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getMaxRisk: ApiFactory<
    ApiEndpoint<MaxRisk, void, { id: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<MaxRisk>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/common-data/max-risk/${params.id}`,
        { signal },
    );

    return data;
};
