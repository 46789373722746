import {
    type PortfolioRequestData,
    type PortfolioResponseData,
} from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const createPortfolio: ApiFactory<
    ApiEndpoint<PortfolioResponseData, PortfolioRequestData>
> = (client) => async ({ signal, data }) => {
    const res = await client.post<PortfolioResponseData>(
        '/moex-datashop-investor-pro-portfolios-service/api/v1/portfolio',
        data,
        { signal },
    );

    return res.data;
};
