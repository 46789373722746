import { Button, Group, Modal } from '@mantine/core';
import { type FC, useEffect, useState } from 'react';

import { Download, Eye } from '../../../shared/Icon';
import { ReportItemCommentEditor } from './ReportItemCommentEditor';

type Props = {
    onClose: () => void;
    onDownload: () => void;
    onSave: (comment: string) => void;
    onShowClick: () => void;
    opened: boolean;
    title: string;
    comment: string;
    isDownloadButtonLoading: boolean;
};

export const ReportItemEditCommentModal: FC<Props> = ({
    onClose,
    onDownload,
    onSave,
    onShowClick,
    opened,
    title,
    comment,
    isDownloadButtonLoading,
}) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        if (!opened) {
            setValue('');

            return;
        }
        setValue(comment || '');
    }, [opened, comment]);

    const handleShowClick = () => {
        onClose();
        onShowClick();
    };

    const handleDownloadClick = () => {
        onClose();
        onDownload();
    };

    const handleSave = () => {
        onClose();
        onSave(value);
    };

    return (
        <Modal.Root
onClose={onClose} radius={6} opened={opened}
centered={true} size="1468px" zIndex={501}>
            <Modal.Overlay />
            <Modal.Content p={30}>
                <Modal.Header p={0}>
                    <Modal.Title fz={20} fw={700} lh="32px">
                        {`${title}. Комментарий к блоку:`}
                    </Modal.Title>
                    <Group gap={6}>
                        <Button
                            leftSection={<Eye color="#2F9CEB" height={24} width={24} />}
                            variant="outline"
                            onClick={handleShowClick}
                        >
                            {'Предпросмотр'}
                        </Button>
                        <Button
                            leftSection={<Download color="#FFFFFF" height={24} width={24} />}
                            onClick={handleDownloadClick}
                            loading={isDownloadButtonLoading}
                        >
                            {'Скачать'}
                        </Button>
                    </Group>
                </Modal.Header>
                <Modal.Body p={0}>
                    <ReportItemCommentEditor value={value} onChange={setValue} />
                    <Group justify="end" align="center" pt={40}>
                        <Group gap={26} wrap="nowrap">
                            <Button
w={196} size="large" variant="cancel"
fullWidth={true} onClick={onClose}>
                                {'Отменить'}
                            </Button>
                            <Button
variant="primary" w={196} size="large"
fullWidth={true} onClick={handleSave}>
                                {'Сохранить'}
                            </Button>
                        </Group>
                    </Group>
                </Modal.Body>
            </Modal.Content>
        </Modal.Root>
    );
};
