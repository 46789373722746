import {
    ActionIcon,
    Button,
    Checkbox,
    CloseButton,
    Combobox,
    Input,
    InputWrapper,
    Loader,
    type MantineThemeOverride,
    Modal,
    Notification,
    Pagination,
    SegmentedControl,
    Select,
    Table,
    Tabs,
    Text,
} from '@mantine/core';

import actionIconClasses from './Styles/ActionIcon.module.css';
import buttonClasses from './Styles/Button.module.css';
import checkboxClasses from './Styles/Checkbox.module.css';
import closeButtonClasses from './Styles/CloseButton.module.css';
import comboboxClasses from './Styles/Combobox.module.css';
import inputClasses from './Styles/Input.module.css';
import paginationClasses from './Styles/Pagination.module.css';
import segmentedControlClasses from './Styles/SegmentedControl.module.css';
import tabClasses from './Styles/Tab.module.css';
import tableClasses from './Styles/Table.module.css';
import { CustomLoader } from './Styles/CustomLoader';
import notificationClasses from './Styles/Notification.module.css';
import { ExclamationCircle } from '../shared/Icon';

export const theme: MantineThemeOverride = {
    fontFamily: 'FavoritPro, sans-serif',
    components: {
        Pagination: Pagination.extend({
            classNames: paginationClasses,
        }),

        Tabs: Tabs.extend({
            classNames: tabClasses,
        }),

        Input: Input.extend({
            classNames: inputClasses,
        }),

        InputWrapper: InputWrapper.extend({
            classNames: inputClasses,
        }),

        Select: Select.extend({
            classNames: inputClasses,
        }),

        Checkbox: Checkbox.extend({
            classNames: checkboxClasses,
        }),

        SegmentedControl: SegmentedControl.extend({
            classNames: segmentedControlClasses,
        }),

        Modal: Modal.extend({
            styles: {
                content: {
                    borderRadius: '6px',
                },
            },
        }),

        Button: Button.extend({
            classNames: buttonClasses,
            styles: {
                root: {
                    fontFamily: 'Inter',
                    borderRadius: '6px',
                },
            },
        }),

        ActionIcon: ActionIcon.extend({
            classNames: actionIconClasses,
        }),

        Table: Table.extend({
            classNames: tableClasses,
        }),

        Combobox: Combobox.extend({
            classNames: comboboxClasses,
        }),

        CloseButton: CloseButton.extend({
            classNames: closeButtonClasses,
        }),

        Loader: Loader.extend({
            defaultProps: {
                loaders: { ...Loader.defaultLoaders, custom: CustomLoader },
                type: 'custom',
                size: '50px',
            },
        }),

        Notification: Notification.extend({
            classNames: notificationClasses,
            styles: {
                description: { color: '#14171F' },
                closeButton: { backgroundColor: 'transparent' },
            },
            defaultProps: {
                icon: <ExclamationCircle />,
            },
        }),

        Text: Text.extend({
            styles: {
                root: {
                    color: '#14171F',
                    fontFeatureSettings: 'normal',
                },
            },
        }),
    },
};
