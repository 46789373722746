import { Image, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { type FC } from 'react';
import investorPro from '@shared/images/investorPro/investor-pro.png';
type Props = {
    withPageNumbers: boolean;
    title: string;
};

export const PdfPageFooter: FC<Props> = ({ withPageNumbers, title }) => (
    <View
        fixed={true}
        style={{
            position: 'absolute',
            bottom: 5,
            borderTop: ' 1px solid #dbe3ed',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 5,
            marginRight: 20,
            width: 570,
        }}
    >
        <Image style={{ width: 100 }} src={investorPro} />

        <Text
            style={{
                fontSize: '6px',
                fontFamily: 'Inter',
            }}
        >
            {`Сформировано: ${dayjs().format('DD.MM.YYYY')} в ${dayjs().format('HH:mm')}`}
        </Text>

        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '5px',
            }}
        >
            <Text
                style={{
                    fontSize: '6px',
                    fontFamily: 'Inter',
                    color: '#2F9CEB',
                }}
            >
                {title}
            </Text>
            {withPageNumbers
? (
                <Text style={{ fontSize: '6px', fontWeight: 100 }} render={({ pageNumber }) => pageNumber} />
            )
: null}
        </View>
    </View>
);
