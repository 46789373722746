import { type ReactNode } from 'react';
import classNames from 'classnames';
import { ReactComponent as Arrow } from '@shared/images/svg/arrow.svg';

import styles from './styles.module.scss';
type Props = {
    className?: string;
    children: ReactNode;
    handleNavigate: () => void;
};

export const LabelWithNavigation = ({ className, children, handleNavigate }: Props) => {
    return (
        <div className={classNames(styles.tabNavigationButtonWrapper, className)} onClick={handleNavigate}>
            {children}
            <div className={styles.navigationButtonPoint}>
                <Arrow />
            </div>
        </div>
    );
};
