import { type FC } from 'react';

export const PublicUtilitiesIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path
                d="M16.0131 13.9H27.6026C29.2028 13.9 30.5 15.1536 30.5 16.7L16.0131 18.1V26.5M16.0131
                13.9L16.0131 9.7L29.1304 8.43235C29.9079 8.35721 30.5 7.72493 30.5 6.96981C30.5 6.15805
                29.819 5.5 28.9791 5.5H14.5644C11.364 5.5 8.76962 8.00721 8.76962 11.1V26.5M16.0131
                13.9L8.76962 13.9M6.5 26.5H18.1861M29.0513 16.84V19.5H26.1539V17.12M29.0513
                25.1C29.0513 25.8732 28.4027 26.5 27.6026 26.5C26.8025 26.5 26.1539 25.8732
                26.1539 25.1C26.1539 24.3268 27.6026 22.3 27.6026 22.3C27.6026 22.3 29.0513 24.3268 29.0513 25.1Z"
                stroke="#636E80"
                strokeWidth="1.2"
                strokeMiterlimit="10"
            />
        </g>
    </svg>
);
