import { type Diversification } from '../../../../../PortfolioPage/entities';
import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getDiversification: ApiFactory<
    ApiEndpoint<Diversification, void, { id: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<Diversification>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/common-data/portfolio-diversification/${params.id}`,
        { signal },
    );

    return data;
};
