import { httpClient } from '@configs/httpclient.config'
import { type AxiosResponse } from 'axios'

import {
    type UpdateAlertRequestResponse,
    type CreateAlertRequestBody,
    type CreateAlertRequestResponse,
    type GetAlertsListRequestResponse,
    type UpdateAlertRequestBody,
} from './types'


const ALERT_URL = '/moex-datashop-alertservice/api/alerts/v1'

export const getAlertsList = async (): Promise<GetAlertsListRequestResponse> => {
    return await httpClient.get(ALERT_URL)
}

export const createAlert = async (body: CreateAlertRequestBody): Promise<CreateAlertRequestResponse> => {
    return await httpClient.post(ALERT_URL, body)
}

export const updateAlert = async ({ id, ...body }: UpdateAlertRequestBody): Promise<UpdateAlertRequestResponse> => {
    return await httpClient.post(ALERT_URL + '/' + id, {
        ...body,
        id,
    })
}

export const deleteAlert = async (id: number): Promise<AxiosResponse<void>> => {
    return await httpClient.delete(ALERT_URL + '/' + id)
}
