import { ModalWindow } from '@libs/components';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { type AppDispatch, updateWatchlistThunk } from '@store/store';
import { type Watchlist } from '@modules/Investorpro/types/watchlist.type';
import { MAX_INPUT_VALUE_LENGTH, MIN_INPUT_VALUE_LENGTH } from '@modules/Investorpro/modules/ScreenersPage/constants';

import styles from './styles.module.scss';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    item?: Watchlist;
};

export const EditModal = ({ isOpen, closeModal, item }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [value, setValue] = useState<string>(item?.name ?? '');

    useEffect(() => {
        setValue(item?.name ?? '');
    }, [item]);

    const updateWatchlist = () => {
        if (item && value) {
            dispatch(updateWatchlistThunk({ watchlistId: item.id, body: { name: value } }));
            closeModal();
        }
    };

    return (
        <ModalWindow isVisible={isOpen} closeModal={closeModal}>
            <div className={classNames(styles.editModalWrapper, 'flex', 'flex-column')}>
                <div className={classNames('flex', 'flex-column')}>
                    <h2>Переименовать список</h2>
                    <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        maxLength={MAX_INPUT_VALUE_LENGTH}
                        minLength={MIN_INPUT_VALUE_LENGTH}
                    />
                </div>
                <div className={classNames(styles.btnWrapper, 'flex')}>
                    <Button label={'Отменить'} className={styles.cancelBtn} onClick={closeModal} />
                    <Button
                        label={'Сохранить изменения'}
                        className={styles.confirmBtn}
                        onClick={updateWatchlist}
                        disabled={
                            value.trim().length < MIN_INPUT_VALUE_LENGTH || value.trim().length > MAX_INPUT_VALUE_LENGTH
                        }
                    />
                </div>
            </div>
        </ModalWindow>
    );
};
