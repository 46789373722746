import { createEndpointMap, createEndpoint } from '../../../core/endpoint';
import { getGeoDiversification } from './getGeoDiversification';
import { getIntraPortfolioCorrelation } from './getIntraPortfolioCorrelation';
import { getLeastCorrelatingTools } from './getLeastCorrelatingTools';
import { getMostCorrelatingTools } from './getMostCorrelatingTools';
import { getSectoralDiversification } from './getSectoralDiversification';

export const createDiversificationEndpoints = createEndpointMap((client) => ({
    getGeoDiversification: createEndpoint(getGeoDiversification, client),
    getIntraPortfolioCorrelation: createEndpoint(getIntraPortfolioCorrelation, client),
    getSectoralDiversification: createEndpoint(getSectoralDiversification, client),
    getLeastCorrelatingTools: createEndpoint(getLeastCorrelatingTools, client),
    getMostCorrelatingTools: createEndpoint(getMostCorrelatingTools, client),
}));
