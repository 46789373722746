import React from 'react';
import { classNames } from 'primereact/utils';

import { Loader } from '../Loader';
import styles from './style.module.scss';

type Props = {
    children: React.ReactNode;
    notRenderChildrenOnLoading?: boolean;
    hideChildrenOnLoading?: boolean;
    transition?: number;
} & Parameters<typeof Loader>[0];

export const WithLoader = ({
    children,
    notRenderChildrenOnLoading = false,
    hideChildrenOnLoading = false,
    isLoading,
    transition = 250,
    ...loaderProps
}: Props) => {
    const renderChildren = () => {
        if (notRenderChildrenOnLoading && isLoading) {
            return null;
        }

        if (hideChildrenOnLoading) {
            return (
                <div
                    style={{ transitionDuration: !isLoading ? `${transition}ms` : undefined }}
                    className={classNames(isLoading && 'opacity-0')}
                >
                    {children}
                </div>
            );
        }

        return children;
    };

    return (
        <div className={styles.viewLoaderWrapper}>
            <Loader {...loaderProps} isLoading={isLoading} />
            {renderChildren()}
        </div>
    );
};
