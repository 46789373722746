import { Group, Stack, Text } from '@mantine/core';
import { type FC, Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ViewError, ViewLoader } from '../../../shared/Layout';
import { SegmentController } from '../../../shared/SegmentController';
import { Table } from '../../../shared/Table';
import { type InferParams } from '../../../shared/router';
import { useGetHistoricalReturnsQuery } from './useGetHistoricalReturnsQuery';
import { useHistoricReturnsColumns } from './useHistoricReturnsColumns';

export const HistoricReturns: FC = () => {
    const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;
    const [instrument, setInstrument] = useState<string>('PORTFOLIO');

    const { data, isPending, isError, error } = useGetHistoricalReturnsQuery(portfolioId, instrument);
    const columns = useHistoricReturnsColumns(data?.seasons);

    if (isError) {
        return (
            <Stack>
                <Text fz={19} fw={400} lh="18px">
                    {'Историческая доходность'}
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по исторической доходности" />
            </Stack>
        );
    }

    if (isPending) {
        return (
            <Group justify="space-between" mb={28}>
                <Text fz={19} fw={400}>
                    {'Историческая доходность'}
                </Text>
                <SegmentController
                    value={instrument}
                    onValueChange={setInstrument}
                    buttons={[
                        { label: 'Портфель', value: 'PORTFOLIO' },
                        { label: 'Бенчмарк', value: 'BENCHMARK' },
                        { label: 'Нетто портфеля', value: 'NET_PORTFOLIO' },
                    ]}
                />
                <ViewLoader />
            </Group>
        );
    }

    return (
        <Fragment>
            <Group justify="space-between" mb={28}>
                <Text fz={19} fw={400}>
                    {'Историческая доходность'}
                </Text>
                <SegmentController
                    value={instrument}
                    onValueChange={setInstrument}
                    buttons={[
                        { label: 'Портфель', value: 'PORTFOLIO' },
                        { label: 'Бенчмарк', value: 'BENCHMARK' },
                        { label: 'Нетто портфеля', value: 'NET_PORTFOLIO' },
                    ]}
                />
            </Group>
            <Table
                columns={columns}
                data={data?.years ?? []}
                highlightOnHover={true}
                highlightColumnOnHover={true}
                isFooterVisible={true}
            />
        </Fragment>
    );
};
