import { SECURITY_DATASET_COLORS } from '@modules/Investorpro/modules/MarketPages/shared/constants';

import { BONDS_CATEGORY_OPTIONS } from '../../shared/constants';

export const CHECKBOX_OPTIONS = [
    { name: 'Кривая бескупонной доходности', code: 'curve' },
    ...BONDS_CATEGORY_OPTIONS,
];

export const CURVE_COLOR = '#7348ED';

export const CHECKBOX_COLORS = [CURVE_COLOR, ...SECURITY_DATASET_COLORS.map(({ table }) => table)];
