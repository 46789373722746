import { type CSSProperties, type ReactNode } from 'react';
import classNames from 'classnames';
import { formatNullableValue } from '@modules/Investorpro/shared/utils/format.util';
import { type ValueType } from '@modules/Investorpro/types/common.type';

import styles from './styles.module.scss';
import { NONE_SYMBOL } from '../../constants';
import { formatValueByType } from '../../utils/formatValueByType.util';

export type Column<T extends object> = {
    id: string;
    label: string | ReactNode;
    field?: keyof T;
    className?: string;
    type?: ValueType;
    style?: CSSProperties;
    align?: 'left' | 'right' | 'center';
    render?: (value: T, option: any) => ReactNode;
    width?: number | string;
    emptyValue?: string;
    measure?: string;
    numberDivider?: string;
    fontWeight?: number;
    fontSize?: number;
};

type Props = {
    data: object[];
    columns: Array<Column<any>>;
    className?: string;
    showHeaders?: boolean;
};

export const CustomTable = ({ data, columns, className, showHeaders = true }: Props) => {
    const getRenderValue = (
        row: object,
        {
            render,
            field,
            emptyValue = NONE_SYMBOL,
            type,
            measure,
            numberDivider,
        }: Pick<Column<any>, 'render' | 'field' | 'emptyValue' | 'type' | 'measure' | 'numberDivider'>,
    ) => {
        if (render) {
            return formatNullableValue(render(row, data), emptyValue);
        }

        const value = field ? row[field as keyof typeof row] : null;

        return formatValueByType({
            value,
            type,
            measure,
            emptyValue,
            numberDivider,
        });
    };

    return (
        <div
            className={classNames(styles.table, className)}
            role="table"
            aria-label="table"
            aria-rowcount={data.length}
            aria-colcount={columns.length}
            style={{
                gridTemplateColumns: columns.map((column) => column.width ?? '1fr').join(' '),
            }}
        >
            {showHeaders &&
                columns.map(({ label, align, id, width, className, style }, columnIndex) => {
                    const isLastElementInRow = columnIndex === columns.length - 1;

                    return (
                        <span
                            className={classNames(
                                styles.table_headerCell,
                                isLastElementInRow && styles.table_headerCell__last,
                                className,
                            )}
                            role="column-header"
                            style={{
                                textAlign: align,
                                width,
                                ...style,
                            }}
                            key={id}
                        >
                            {label}
                        </span>
                    );
                })}
            {data.length === 0 && (
                <div className={styles.table_empty} role="empty-data-alert" aria-label="empty-data-alert">
                    Нет данных
                </div>
            )}
            {data.map((row, index) => {
                return columns.map(
                    (
                        { id, className, style, align = 'right', width, fontSize, fontWeight, ...column },
                        columnIndex,
                    ) => {
                        const isOddRow = index % 2 === 0;
                        const isLastElementInRow = columnIndex === columns.length - 1;

                        return (
                            <div
                                key={id}
                                role="cell"
                                aria-label="cell"
                                aria-rowindex={index + 1}
                                aria-colindex={columnIndex + 1}
                                style={{
                                    width,
                                    ...style,
                                }}
                                className={classNames(
                                    styles.table_cell,
                                    isOddRow && styles.table_cell__filled,
                                    isLastElementInRow && styles.table_cell__last,
                                    className,
                                )}
                            >
                                <span
                                    style={{
                                        fontSize,
                                        fontWeight,
                                        textAlign: align,
                                    }}
                                    className={classNames(styles[`table_cell__${align}`], 'flex', className)}
                                >
                                    {getRenderValue(row, column)}
                                </span>
                            </div>
                        );
                    },
                );
            })}
        </div>
    );
};
