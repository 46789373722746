import { type FC, Fragment, useMemo } from 'react';
import { Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { type IntersectionType, type SimulationResultItem } from '@modules/Investorpro/modules/PortfolioPage/entities';
import { ViewError } from '@modules/Investorpro/modules/PortfolioPage/shared/Layout';

import { intersect } from './intersect';

const chartId = 'simulationChart';

const getIntersectionColor = (intersection: IntersectionType, isLast: boolean): string => {
    if (isLast) {
        return intersection.line1isHigherNext ? 'rgba(66, 166, 46, 0.2)' : 'rgba(242, 67, 61, 0.2)';
    }

    return intersection.line1isHigher ? 'rgba(66, 166, 46, 0.2)' : 'rgba(242, 67, 61, 0.2)';
};

type Props = {
    data: SimulationResultItem[];
    isInReport?: boolean;
    error?: Error | null;
};

export const SimulationChart: FC<Props> = ({ data, isInReport, error }) => {
    const dataWithRange: SimulationResultItem[] = useMemo(
        () => data.map((d) => ({
                date: d.date,
                portfolioValue: d.portfolioValue,
                benchmarkValue: d.benchmarkValue,
                range: d.portfolioValue && d.benchmarkValue ? [d.portfolioValue, d.benchmarkValue] : [],
            })),
        [data],
    );

    const intersections = useMemo(
        () => data
                .map((d, i) => intersect(
                        i,
                        d.portfolioValue,
                        i + 1,
                        data[i + 1]?.portfolioValue,
                        i,
                        d.benchmarkValue,
                        i + 1,
                        data[i + 1]?.benchmarkValue,
                    ),
                )
                .filter((d) => !!d && !isNaN((d as IntersectionType).x)),
        [data],
    );

    const filteredIntersections = useMemo(
        () => intersections?.filter(
                (d, i) => i === intersections.length - 1 ||
                    (d as IntersectionType)?.x !== (intersections[i - 1] as IntersectionType)?.x,
            ),
        [intersections],
    );

    if (error) {
        return <ViewError error={error} subtitle="Ошибка получения данных по результату симуляции сценария" />;
    }

    return (
        <ResponsiveContainer width="100%" aspect={2}>
            <ComposedChart
                data={dataWithRange}
                margin={{
                    top: 20,
                    bottom: 20,
                }}
            >
                <CartesianGrid
horizontal={true} vertical={false} stroke="#D8E1EB"
opacity={0.6} />
                <defs>
                    <linearGradient id={chartId}>
                        {filteredIntersections?.length
? (
                            filteredIntersections.map((intersection, index) => {
                                const i = index;
                                const nextIntersection = filteredIntersections[i + 1];

                                let closeColor = '';
                                let startColor = '';

                                const isLast = i === filteredIntersections.length - 1;

                                if (isLast) {
                                    if (typeof intersection !== 'boolean') {
                                        closeColor = getIntersectionColor(intersection, false);
                                        startColor = getIntersectionColor(intersection, true);
                                    }
                                } else {
                                    if (typeof intersection !== 'boolean' && typeof nextIntersection !== 'boolean') {
                                        closeColor = getIntersectionColor(intersection, false);
                                        startColor = getIntersectionColor(nextIntersection, false);
                                    }
                                }

                                const offset = (intersection as IntersectionType).x / (data.length - 1);

                                return (
                                    <Fragment key={i}>
                                        <stop offset={offset} stopColor={closeColor} stopOpacity={0.9} />
                                        <stop offset={offset} stopColor={startColor} stopOpacity={0.9} />
                                    </Fragment>
                                );
                            })
                        )
: (
                            <stop
                                offset={0}
                                stopColor={
                                    data[0]?.portfolioValue && data[0]?.benchmarkValue
                                        ? 'rgba(66, 166, 46, 0.2)'
                                        : 'rgba(242, 67, 61, 0.2)'
                                }
                            />
                        )}
                    </linearGradient>
                </defs>
                <XAxis
dataKey="date" hide={true} strokeWidth={1}
interval={0} axisLine={false} tickMargin={40} />
                <YAxis
                    tickMargin={50}
                    tickSize={0}
                    tickCount={8}
                    interval="preserveStart"
                    axisLine={false}
                    orientation="right"
                    style={{
                        fontSize: 11,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        textAnchor: 'end',
                        fill: '#8A96A6',
                    }}
                    tickFormatter={(value: number) => `${value.toFixed(1)}%`}
                />
                <Area
                    isAnimationActive={!isInReport}
                    animateNewValues={!isInReport}
                    dataKey="range"
                    stroke="#8884d8"
                    strokeWidth={0}
                    fill={`url(#${chartId})`}
                    type="linear"
                />
                <Line
                    isAnimationActive={!isInReport}
                    animateNewValues={!isInReport}
                    type="linear"
                    dataKey="portfolioValue"
                    strokeWidth={1.8}
                    stroke="#636E80"
                    dot={false}
                />
                <Line
                    isAnimationActive={!isInReport}
                    animateNewValues={!isInReport}
                    type="linear"
                    dataKey="benchmarkValue"
                    strokeWidth={1}
                    stroke="#A5B1C0"
                    strokeDasharray="3 3"
                    dot={false}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
};
