import { type PriceChanges, type SecurityPriceData } from '@libs/types';
import { round } from 'lodash';

import { compareSecurities } from './securities.utils';
import { useGetCurrentPrice } from '../../StockMarketPage/components/StockMarketDashboard/utils/useGetCurrentPrice';

type UseCurrentPriceUpdateArgs<T extends SecurityPriceData & Partial<PriceChanges>> = {
    securities: T[];
    updateSecurities: (securities: T[] | ((prev: T[]) => T[])) => void;
    getTicker?: (security: T) => string;
    hasPrevTradePrice?: boolean;
    updateSecurityByPrice?: (price: number, security: T) => Partial<T>;
    sortSecuritiesBeforeUpdate?: (a: T, b: T) => number;
}

export const useCurrentPriceUpdate = <T extends SecurityPriceData & Partial<PriceChanges>>({
    securities,
    updateSecurities,
    getTicker,
    updateSecurityByPrice,
    hasPrevTradePrice = true,
    sortSecuritiesBeforeUpdate,
}: UseCurrentPriceUpdateArgs<T>) => {
    useGetCurrentPrice({
        securities: securities.filter((security) => security.prevTradeDayPrice !== null),
        getTicker,
        disabled: !hasPrevTradePrice,
        setSecurityPrice: (securityForUpdate, currentPrice) => {
            const changes: Partial<Record<keyof T, number>> = {}

            updateSecurities(prev => {
                const security = prev.find((security) => compareSecurities(securityForUpdate, security))
                const priceWasChanged = security?.price !== currentPrice
                const prevTradeDayPrice = security?.prevTradeDayPrice
                const hasPrevTradeDayPrice = prevTradeDayPrice !== null && prevTradeDayPrice !== undefined

                if (!(priceWasChanged && hasPrevTradeDayPrice)) {
                    return prev
                }

                changes.changeAbsolute = currentPrice - prevTradeDayPrice
                changes.changeRelative = round((currentPrice - prevTradeDayPrice) / prevTradeDayPrice * 100, 2)
                changes.price = currentPrice

                let securities = prev.map((security) => {
                    if (compareSecurities(securityForUpdate, security)) {
                        return {
                            ...security,
                            ...changes,
                            ...(updateSecurityByPrice
                                    ? updateSecurityByPrice(currentPrice, security)
                                    : {}),
                        }
                    }

                    return security;
                })

                securities = sortSecuritiesBeforeUpdate
                    ? securities.sort(sortSecuritiesBeforeUpdate)
                    : securities

                return securities
            })
        },
    });
}
