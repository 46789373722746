import { Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { type KeyStatistics } from '@modules/Investorpro/modules/PortfolioPage/entities';

const columnHelper = createColumnHelper<KeyStatistics>();

export const useConfigureKeyStatisticsColumns = () => useMemo(
        () => [
            columnHelper.accessor('name', {
                id: 'name',
                header: 'Название',
                enableSorting: false,
                cell: (props) => (
                    <Text
                        style={{ textAlign: 'start', whiteSpace: 'nowrap' }}
                        fz={14}
                        lh="16px"
                        fs="normal"
                        c="#14171F"
                    >
                        {props?.getValue() ?? ''}
                    </Text>
                ),
            }),
            columnHelper.accessor('quarterToDate', {
                id: 'qtd',
                header: 'QTD',
                meta: { headerCellStyles: { justifyContent: 'end' } },
                enableSorting: false,
                cell: (props) => (
                    <Text
                        fz={14}
                        style={{
                            color: Number(props?.getValue()) > 0 ? '#26A65B' : '#E42821',
                            textAlign: 'end',
                        }}
                    >
                        {props?.getValue() ?? ''}
                        {'%'}
                    </Text>
                ),
            }),
            columnHelper.accessor('yearToDate', {
                id: 'ytd',
                header: 'YTD',
                meta: { headerCellStyles: { justifyContent: 'end' } },
                enableSorting: false,
                cell: (props) => (
                    <Text
                        fz={14}
                        style={{
                            color: Number(props?.getValue()) > 0 ? '#26A65B' : '#E42821',
                            textAlign: 'end',
                        }}
                    >
                        {props?.getValue() ?? ''}
                        {'%'}
                    </Text>
                ),
            }),
            columnHelper.accessor('oneYear', {
                id: 'oneYear',
                header: '1 год',
                meta: { headerCellStyles: { justifyContent: 'end' } },
                enableSorting: false,
                cell: (props) => (
                    <Text
                        fz={14}
                        style={{
                            color: Number(props?.getValue()) > 0 ? '#26A65B' : '#E42821',
                            textAlign: 'end',
                        }}
                    >
                        {props?.getValue() ?? ''}
                        {'%'}
                    </Text>
                ),
            }),
            columnHelper.accessor('fiveYears', {
                id: 'fiveYears',
                header: '5 лет',
                meta: { headerCellStyles: { justifyContent: 'end' } },
                enableSorting: false,
                cell: (props) => (
                    <Text
                        fz={14}
                        style={{
                            color: Number(props?.getValue()) > 0 ? '#26A65B' : '#E42821',
                            textAlign: 'end',
                        }}
                    >
                        {props?.getValue() ?? ''}
                        {'%'}
                    </Text>
                ),
            }),
            columnHelper.accessor('tenYears', {
                id: 'tenYears',
                header: '10 лет',
                meta: { headerCellStyles: { justifyContent: 'end' } },
                enableSorting: false,
                cell: (props) => (
                    <Text
                        fz={14}
                        style={{
                            color: Number(props?.getValue()) > 0 ? '#26A65B' : '#E42821',
                            textAlign: 'end',
                        }}
                    >
                        {props?.getValue() ?? ''}
                        {'%'}
                    </Text>
                ),
            }),
        ],
        [],
    );
