import { useAppSelector } from '@store/store';


export const useFavoriteSecurity = (isin: string | null | undefined): boolean => {
    return useAppSelector(
        (state) => !!state.investorProfile.watchlists.some(
            ({ securities }) => securities.some((security) => security.isin === isin),
        ),
    )
};
