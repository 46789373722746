import { SettingParam } from './types';

export const settingParams: SettingParam[] = [
    {
        code: 'change1D',
        name: 'Изменение за 1 день, %',
        shortName: 'Изм. за 1д',
    },
    {
        code: 'change1M',
        name: 'Изменение за 1 месяц, %',
        shortName: 'Изм. за 1м',
    },
    {
        code: 'change1Y',
        name: 'Изменение за 1 год, %',
        shortName: 'Изм. за 1г',
    },
    {
        code: 'changeSinceYear',
        name: 'Изменение снг, %',
        shortName: 'Изм. снг',
    },
];
