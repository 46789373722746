import { Navigation } from '@libs/components';
import classNames from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';
import { hasDocumentHorizontalScroll, hasDocumentVerticalScroll, useAuthorize } from '@libs/utils';
import { type RootState } from '@store/store';
import { useSelector } from 'react-redux';
import { useOverlay } from '@libs/utils/hooks/useOverlay';
import { WithLoader } from '@libs/components/loaders/ViewLoader';

import styles from './styles.module.scss';
import { Header } from './shared/components/Header';

type Props = {
    footerType?: 'black' | 'white';
};

export const InvestorProLayout = ({ footerType = 'white' }: Props) => {
    const { isAuthorized } = useAuthorize();
    const { pathname } = useLocation();
    const ROOT_URL = '/';
    const isCommonLoading = useSelector((state: RootState) => state.loading.isLoading);
    const isCommonLoadingByKeys = useSelector((state: RootState) => state.loading.loadingKeys.length > 0);
    const isUserDataLoading = useSelector((state: RootState) => state.user.isLoading);

    const isLoading = isCommonLoadingByKeys || isCommonLoading || isUserDataLoading;

    useOverlay('loader', isLoading && (hasDocumentVerticalScroll() || hasDocumentHorizontalScroll()));

    return (
        <div
            className={classNames(
                styles.pageWrapper,
                !isAuthorized && pathname === ROOT_URL && styles.pageWrapper__spaced,
            )}
        >
            <Navigation isHideIconsAlways isDesktopOnly>
                <Header />
            </Navigation>
            <WithLoader isLoading={isLoading} hideChildrenOnLoading withOverlay={true}>
                <Outlet />
            </WithLoader>
        </div>
    );
};
