import { Group, Stack, Text } from '@mantine/core';
import { type UseQueryResult } from '@tanstack/react-query';
import { type FC } from 'react';

import { type PortfolioTypologyAssetsMatrixData } from '../../../../entities/portfolio';
import { TypologyAssetsBondsMatrix } from '../../TypologyAssets/TypologyAssetsBondsMatrix';
import { TypologyAssetsFundsMatrix } from '../../TypologyAssets/TypologyAssetsFundsMatrix';
import { TypologyAssetsStocksMatrix } from '../../TypologyAssets/TypologyAssetsStocksMatrix';
import { InfoIcon } from '../ReportIcons/InfoIcon';

type Props = {
    typologyAssetsStocksQuery: UseQueryResult<PortfolioTypologyAssetsMatrixData, Error>;
    typologyAssetsBondsQuery: UseQueryResult<PortfolioTypologyAssetsMatrixData, Error>;
    typologyAssetsFundsQuery: UseQueryResult<PortfolioTypologyAssetsMatrixData, Error>;
};

export const ReportTypologyAssets: FC<Props> = ({
    typologyAssetsStocksQuery,
    typologyAssetsBondsQuery,
    typologyAssetsFundsQuery,
}) => (
    <Stack gap={33} w={1350} p={5}>
        <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
            <Group align="center" justify="space-between">
                <Text
fz="45px" fw={400} lh="47px"
style={{ whiteSpace: 'pre-wrap' }}>
                    {'Типология активов'}
                </Text>
                <Group
                    gap={12}
                    miw={180}
                    mih={24}
                    px={16}
                    align="center"
                    py={8}
                    style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                >
                    <InfoIcon />
                    <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                        {'Информация'}
                    </Text>
                </Group>
            </Group>
            <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px" style={{ whiteSpace: 'pre-wrap' }}>
                {
                    'Раздел Типология поможет провести более тонкую настройку диверсификации вашего \
портфеля.\n\nНапример, вы хотите инвестировать не просто в акции, а точно задать, \
какая доля должна быть распределена на акции компании крупной капитализации, а \
какая на компании малой капитализации. Или вы можете в корпоративные облигации \
инвестировать не более чем определенную долю своего капитала.'
                }
            </Text>
        </Stack>
        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400}>
                {'Акции'}
            </Text>
            <TypologyAssetsStocksMatrix typologyAssetsStocksQuery={typologyAssetsStocksQuery} isInReport={true} />
        </Stack>

        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400}>
                {'Облигации'}
            </Text>
            <TypologyAssetsBondsMatrix typologyAssetsBondsQuery={typologyAssetsBondsQuery} isInReport={true} />
        </Stack>

        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400}>
                {'Фонды'}
            </Text>
            <TypologyAssetsFundsMatrix typologyAssetsFundsQuery={typologyAssetsFundsQuery} isInReport={true} />
        </Stack>
    </Stack>
);
