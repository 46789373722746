export type SimulationResultItem = {
    date: string;
    portfolioValue: number;
    benchmarkValue: number;
    range?: number[];
};

export type SimulationData = {
    performance: SimulationResultItem[];
};

export type IntersectionType = {
    line1isHigher: boolean;
    line1isHigherNext: boolean;
    x: number;
    y: number;
};

export type Scenario = {
    uuid: string;
    name: string;
    description?: string;
    dateFrom: string;
    dateTill: string;
    value: number;
};

export type StressScenarios = {
    content: Scenario[];
};

export type ScenarioIndicatorType = {
    name: string;
    dateFromValue: number;
    dateTillValue: number;
    change: number;
};

export type RiskProfile = {
    value: number;
};

export enum AssetsName {
    STOCKS = 'stocks',
    BONDS = 'bonds',
    FUNDS = 'funds',
    CASH = 'cash',
    ALTERNATIVE = 'alternative'
}

export const assetsNames = {
    [AssetsName.STOCKS]: { name: 'Акции' },
    [AssetsName.BONDS]: { name: 'Облигации' },
    [AssetsName.FUNDS]: { name: 'Фонды' },
    [AssetsName.CASH]: { name: 'Денежные средства' },
    [AssetsName.ALTERNATIVE]: { name: 'Альтернативные' },
};

export type AssetsResponseData = {
    stocks: number;
    bonds: number;
    funds: number;
    cash: number;
    alternative: number;
};
