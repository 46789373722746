import { ActionIcon, Box, Button, Group, LoadingOverlay, Modal, Stack, Text, TextInput, Tooltip } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Fragment, useLayoutEffect, useState, type FC } from 'react';

import { useApi } from '../api';
import { type PortfolioUpdateRequestData } from '../entities';
import { Carriage, Check, Close } from '../shared/Icon';
import { notification } from '../shared/Layout/notificationStyles';
import { ListOfTools } from './ListOfTools/ListOfTools';
import { SelectedTools } from './ListOfTools/SelectedTools';
import { SelectedToolsHeader } from './ListOfTools/SelectedToolsHeader';
import { portfolioFormSchema, type PortfolioFormValues, type PortfolioToolValues } from './PortfolioForm.schema';
import { PortfolioMainSettings } from './PortfolioMainSettings';
import { usePortfolios } from './PortfoliosContext';

export const PatchModal: FC = () => {
    const api = useApi();
    const queryClient = useQueryClient();
    const { portfolioIdForEdit, onPortfolioIdForEditChange } = usePortfolios();
    const [isEditNameFieldAvailable, setIsEditNameFieldAvailable] = useState(false);
    const [intermediateNameFieldValue, setIntermediateNameFieldValue] = useState('');
    const [portfolioCurrency, setPortfolioCurrency] = useState('RUB');

    const onClose = () => {
        onPortfolioIdForEditChange(null);
        setIsEditNameFieldAvailable(false);
    };

    const form = useForm<PortfolioFormValues>({
        validate: yupResolver(portfolioFormSchema),
    });

    const getPortfolioByIdQuery = useQuery({
        queryKey: ['portfolio', portfolioIdForEdit],
        queryFn: async () => await api.portfolio.getPortfolioById({ params: { id: portfolioIdForEdit ?? '' } }),
        enabled: Boolean(portfolioIdForEdit),
    });

    const updatePortfolioByIdMutation = useMutation({
        mutationKey: ['portfolio', portfolioIdForEdit, 'update'],
        mutationFn: async (data: PortfolioUpdateRequestData) => await api.portfolio.updatePortfolio({
                data,
                params: { id: portfolioIdForEdit ?? '' },
            }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['portfolio', portfolioIdForEdit] });
            await queryClient.invalidateQueries({ queryKey: ['portfolio', 'list'] });
            onClose();
            notification('success', 'Портфель обновлен.');
        },
        onError: () => {
            notification('error', 'Произошла ошибка при редактировании портфеля.');
        },
    });

    useLayoutEffect(() => {
        if (getPortfolioByIdQuery.data) {
            form.setValues({
                benchmarkUuid: getPortfolioByIdQuery.data.benchmarkDto.uuid,
                tickers: getPortfolioByIdQuery.data.tickers,
                name: getPortfolioByIdQuery.data.name,
                value: getPortfolioByIdQuery.data.value,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPortfolioByIdQuery.data]);

    const resetAllTickers = () => {
        form.setFieldValue('tickers', []);
    };

    const handleToolsChange = (tools: PortfolioToolValues[]) => {
        form.setFieldValue('tickers', tools);
    };

    const handleNameFieldMakeAvailable = () => {
        setIsEditNameFieldAvailable(true);
        setIntermediateNameFieldValue(form.values.name);
    };

    const handleNameFieldSave = () => {
        if (form.validateField('name').hasError) {
            return;
        }
        setIsEditNameFieldAvailable(false);
    };

    const handleNameFieldReset = () => {
        setIsEditNameFieldAvailable(false);
        form.setFieldValue('name', intermediateNameFieldValue);
    };

    const handleSubmit = (values: PortfolioFormValues) => {
        updatePortfolioByIdMutation.mutate(values);
    };

    return (
        <Modal.Root
centered={true} size="1142px" opened={Boolean(portfolioIdForEdit)}
radius={6} onClose={onClose}>
            <Modal.Overlay />
            <Modal.Content>
                <LoadingOverlay visible={updatePortfolioByIdMutation.isPending} zIndex={1001} />
                <form onSubmit={form.onSubmit(handleSubmit)}>
                    <Modal.Header p="40px 48px 24px">
                        <Group
justify="space-between" w="100%" wrap="nowrap"
gap={8} align="flex-start">
                            <Text fz={24} fw={400} lh="36px">
                                {'Изменить портфель:'}
                            </Text>

                            {isEditNameFieldAvailable
? (
                                <Fragment>
                                    <TextInput {...form.getInputProps('name')} flex={1} />

                                    <ActionIcon
variant="outline" h={36} w={36}
onClick={handleNameFieldReset}>
                                        <Close width={20} height={20} />
                                    </ActionIcon>

                                    <ActionIcon
variant="outline" h={36} w={36}
onClick={handleNameFieldSave}>
                                        <Check width={20} height={20} color="#2F9CEB" />
                                    </ActionIcon>
                                </Fragment>
                            )
: (
                                <Fragment>
                                    <Tooltip label={form.values.name} position="top-start" zIndex={501}>
                                        <Text
flex={1} fw={300} fz={24}
lh="36px" truncate="end">
                                            {form.values.name}
                                        </Text>
                                    </Tooltip>

                                    <Button
                                        variant="outline"
                                        onClick={handleNameFieldMakeAvailable}
                                        leftSection={<Carriage width={24} height={24} color="#2F9CEB" />}
                                    >
                                        {'Переименовать'}
                                    </Button>
                                </Fragment>
                            )}
                        </Group>
                        <Modal.CloseButton
                            variant="transparent"
                            icon={<Close width={36} height={36} />}
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                height: '46px',
                                width: '46px',
                            }}
                        />
                    </Modal.Header>
                    <Modal.Body p={0} pb={48}>
                        <Group
gap="28px" mb={48} pl={48}
align="flex-start">
                            <PortfolioMainSettings form={form} onPortfolioCurrencyChange={setPortfolioCurrency} />
                        </Group>
                        <Group
                            pl="48"
                            w="100%"
                            h="fit-content"
                            gap={0}
                            align="flex-start"
                            wrap="nowrap"
                            style={{ borderBottom: '1px solid #D8E1EB' }}
                        >
                            <Box w={430} h={412}>
                                <ListOfTools tickers={form.values.tickers} onToolsChange={handleToolsChange} />
                            </Box>
                            <Box w={654}>
                                <Stack w="100%" gap={0}>
                                    <SelectedToolsHeader resetAllTickers={resetAllTickers} />
                                    <Box
pr={36} bg="#F2F7FE" h={479}
pl={50} pt={28}>
                                        <SelectedTools
                                            form={form}
                                            onToolsChange={handleToolsChange}
                                            portfolioCurrency={portfolioCurrency}
                                        />
                                    </Box>
                                </Stack>
                            </Box>
                        </Group>
                        <Group
justify="end" align="center" pt={40}
pr={48}>
                            <Group gap={26} wrap="nowrap">
                                <Button
w={196} size="large" variant="cancel"
fullWidth={true} onClick={onClose}>
                                    {'Отменить'}
                                </Button>
                                <Button
type="submit" variant="primary" w={196}
size="large" fullWidth={true}>
                                    {'Сохранить'}
                                </Button>
                            </Group>
                        </Group>
                    </Modal.Body>
                </form>
            </Modal.Content>
        </Modal.Root>
    );
};
