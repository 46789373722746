import * as yup from 'yup';

const dynamicRequestSchema = yup.object({
    comparingInstruments: yup.array().of(yup.string().required('Поле должно быть заполнено').default('')).default([]),
    events: yup.array().of(yup.string().required('Поле должно быть заполнено').default('')).default([]),
});

const datesSchema = yup.array(yup.date().default(null));

export const dynamicsFilterSchema = yup.object().shape({
    dates: datesSchema,
    dynamicRequestDto: dynamicRequestSchema,
});

export type DynamicsFilterValues = yup.InferType<typeof dynamicsFilterSchema>;
