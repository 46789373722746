import classNames from 'classnames';
import { Header, Navigation } from '@libs/components';
import { Header as InvestorproHeader } from '@modules/Investorpro/shared/components/Header';
import React from 'react';
import { Footer } from '@modules/Investorpro/shared/components/Footer';

import styles from './styles.module.scss';

export const ErrorPage = () => {
    return (
        <>
            <Header />
            <div className="main-wrapper">
                <div>
                    <Navigation isHideIconsAlways isDesktopOnly>
                        <InvestorproHeader />
                    </Navigation>
                    <div className={classNames('flex', 'flex-column', styles.errorPageWrapper)}>
                        <div className={styles.pageContent}>
                            <h1>
                                <span>404.</span>
                                <br /> СТРАНИЦА
                                <br /> НЕ НАЙДЕНА
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
