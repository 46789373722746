import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteSavedCard, getSavedCards, setDefaultSavedCard } from "@libs/services";

export const getSavedCardsThunk = createAsyncThunk('card/getSavedCardsThunk', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getSavedCards();

        return data.data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const deleteSavedCardThunk = createAsyncThunk('card/deleteSavedCartThunk', async (cardId: string, { rejectWithValue }) => {
    try {
        await deleteSavedCard(cardId);
    } catch (e) {
        console.error('e', e)

        return rejectWithValue(e);
    }
});

export const setDefaultSavedCardThunk = createAsyncThunk('card/setDefaultSavedCardThunk', async (cardId: string, { rejectWithValue }) => {
    try {
        await setDefaultSavedCard(cardId);
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});
