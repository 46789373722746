import { Box, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { ViewLoader } from '@modules/Investorpro/modules/PortfolioPage/shared/Layout';

import { type PortfolioPerformance } from '../../../../entities';
import { Table } from '../../../../shared/Table';
import { useFindMinDeltaIndex } from './useFindMinDeltaIndex';
import { usePerformanceColumns } from './usePerformanceColumns';

type Props = {
    data: PortfolioPerformance[];
    performanceType: string;
    isPending: boolean;
};

export const PerformanceTable: FC<Props> = ({ data, performanceType, isPending }) => {
    const minDeltaIndex = useFindMinDeltaIndex(data);
    const columns = usePerformanceColumns(performanceType, minDeltaIndex);

    if (isPending) {
        return <ViewLoader />;
    }

    return (
        <Box w="100%">
            <Table
columns={columns} data={data ?? []} striped={true}
highlightOnHover={true} />
        </Box>
    );
};
