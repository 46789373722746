import { type ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { composeUrl } from './composeUrl';
import { type WithParams } from './types';

type Props<To extends string> = WithParams<
    To,
    {
        readonly to: To;
        readonly search?: string;
        readonly hash?: string;
        readonly replace?: boolean;
        readonly state?: unknown;
    }
>;

export const RouterNavigate = <T extends string>({
    to,
    params,
    search,
    hash,
    replace,
    state,
}: Props<T>): ReactElement => (
    <Navigate to={composeUrl({ path: to, params, search, hash })} replace={replace} state={state} />
);
