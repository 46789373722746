/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { type AxiosInstance } from 'axios';

type RequestConfigBase = {
    readonly signal?: AbortSignal;
};

type RequestConfigWithData<D> = RequestConfigBase & {
    readonly data: D;
};

type RequestConfigWithParams<P> = RequestConfigBase & {
    readonly params: P;
};

type RequestConfigWithDataAndParams<D, P> = RequestConfigWithData<D> & RequestConfigWithParams<P>;

type RequestConfig<D, P> = D extends void ?
    (P extends void ? RequestConfigBase : RequestConfigWithParams<P>) :
    (P extends void ? RequestConfigWithData<D> : RequestConfigWithDataAndParams<D, P>);

export type ApiEndpoint<T, D = void, P = void> = (config: RequestConfig<D, P>) => Promise<T>;

export type ApiFactory<T = unknown> = (client: AxiosInstance) => T;

export const createEndpointMap = <T>(
    factory: (client: AxiosInstance) => T,
) => factory;

export const createEndpoint = <T>(
    factory: ApiFactory<T>,
    client: AxiosInstance,
) => factory(client);
