import { Column, type ColumnBodyOptions } from 'primereact/column';
import { DataTable, type DataTableStateEvent } from 'primereact/datatable';
import { type SettingParam } from '@modules/Investorpro/shared/components/WatchlistSidebar/types';
import classNames from 'classnames';
import { type WatchlistRow } from '@modules/Investorpro/types/watchlist.type';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import {
    deleteSecurityFromWatchlistThunk,
    getWatchlistDataThunk,
    getWatchlistsThunk,
    type RootState,
    useAppDispatch,
} from '@store/store';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SortOrderTypes } from '@libs/types';
import { EmptyData } from '@modules/Investorpro/shared/components/WatchlistSidebar/components/EmptyData';
import { type Security } from '@modules/Investorpro/modules/ScreenersPage/types';
import { addDays, addMinutes, format, isToday, parse } from 'date-fns';
import { da, ru } from 'date-fns/locale';
import { NONE_SYMBOL } from '@modules/Investorpro/shared/constants';
import { PositiveNegativeValue } from '@modules/Investorpro/shared/components/PositiveNegativeValue';
import { formatPrice } from '@modules/Investorpro/shared/utils/format.util';

import { ReactComponent as KebabIcon } from '../../../../../images/svg/favoritesSidebar/kebab.svg';
import { ReactComponent as TrashIcon } from '../../../../../images/svg/Bin.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../../images/svg/ArrowTooltipDownWhite.svg';
import { ReactComponent as SortIcon } from '../../../../../../shared/images/svg/Sort.svg';
import styles from './styles.module.scss';
import { formatUpdateDate } from './utils';

type Props = {
    selectedSettingParam: SettingParam;
};

type TooltipData = {
    updateDate: string | null;
    shortName: string;
    pos: number;
    visible: boolean;
};

export const WatchlistDataTable = ({ selectedSettingParam }: Props) => {
    const dispatch = useAppDispatch();
    const { secId, ticker } = useParams();
    const rootUrl = '';
    const navigate = useNavigate();
    const refs = useRef<any>({});
    const [nameTooltipData, setNameTooltipData] = useState<TooltipData>({
        updateDate: null,
        shortName: '',
        pos: 0,
        visible: false,
    });

    const onMouseLeaveName = () => {
        setNameTooltipData((prev) => ({
            ...prev,
            visible: false,
        }));
    };

    const onMouseEnterName = (row: WatchlistRow) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setNameTooltipData({
            updateDate: formatUpdateDate(row.updateDate),
            shortName: row.shortName,
            visible: true,
            pos: event.currentTarget.getBoundingClientRect().top,
        });
    };

    const { watchlistData, selectedWatchlist } = useSelector((state: RootState) => state.investorProfile);

    const nameTemplate = (row: WatchlistRow) => {
        return (
            <div
                className={classNames(styles.nameWrapper)}
                onClick={() => openSecurity(row)}
                onMouseEnter={onMouseEnterName(row)}
                onMouseLeave={onMouseLeaveName}
            >
                <span>{row.shortName || '—'}</span>
                <span>{row.seqId || '—'}</span>
            </div>
        );
    };

    const openSecurity = (row: WatchlistRow) => {
        const securityId = row.type === 'STOCK' ? row.seqId : row.isin;
        const type = row.type === 'STOCK' ? 'stocks' : 'bonds';
        navigate(`${rootUrl}/analytics/${type}/${securityId}`);
    };

    const closeTemplate = (row: WatchlistRow) => {
        return (
            <>
                <div onClick={() => openSecurity(row)} className={styles.closeWrapper}>
                    {row.close
? (
                        <>
                            {formatPrice(row.close)} <span>RUB</span>
                        </>
                    )
: (
                        '—'
                    )}
                </div>
            </>
        );
    };

    const paramTemplate = (row: WatchlistRow) => {
        const value = row[selectedSettingParam.code as keyof WatchlistRow];

        return <PositiveNegativeValue className={styles.paramWrapper} value={Number(value)} measure="%" />;
    };

    const actionBtnTemplate = (row: WatchlistRow, column: ColumnBodyOptions) => {
        return (
            <>
                <Button
                    icon={<KebabIcon />}
                    className={styles.actionWrapper}
                    onClick={(e) => toggleOverlay(e, column.rowIndex)}
                />
                <OverlayPanel
                    onClick={() => {
                        deleteWatchlistRow(row);
                        refs.current[column.rowIndex].hide();
                    }}
                    ref={(el) => (refs.current[column.rowIndex] = el)}
                    className={styles.overlayPanelWrapper}
                >
                    <div className={classNames(styles.contentWrapper, 'flex')}>
                        <TrashIcon />
                        <span>Удалить</span>
                    </div>
                </OverlayPanel>
            </>
        );
    };

    const toggleOverlay = (event: any, rowIndex: number) => {
        if (refs.current[rowIndex]) {
            refs.current[rowIndex].toggle(event);
        }
    };

    const deleteWatchlistRow = (row: WatchlistRow) => {
        if (selectedWatchlist) {
            const security = selectedWatchlist.securities?.find((item: Security) => item.isin === row.isin);

            if (security) {
                dispatch(
                    deleteSecurityFromWatchlistThunk({ watchlistId: selectedWatchlist?.id, securityId: security.id }),
                ).then(() => {
                    dispatch(getWatchlistsThunk());
                });
            }
        }
    };
    const [sortOrder, setSortOrder] = useState<1 | -1>(1);
    const [sortField, setSortField] = useState<keyof WatchlistRow>('shortName');

    const handleSort = (event: DataTableStateEvent) => {
        if (selectedWatchlist) {
            dispatch(
                getWatchlistDataThunk({
                    watchlistId: selectedWatchlist.id,
                    body: {
                        sortField: (event.sortField as keyof WatchlistRow) ?? sortField,
                        sortOrder: sortOrder === 1 ? SortOrderTypes.ASC : SortOrderTypes.DESC,
                    },
                }),
            ).then(() => {
                setSortField((event.sortField as keyof WatchlistRow) ?? sortField);
                setSortOrder((prev) => (prev === 1 ? -1 : 1));
            });
        }
    };

    const rowClass = (data: WatchlistRow) => {
        return data.seqId === ticker || data.isin === secId ? styles.currentRow : '';
    };

    if (!watchlistData.length) {
        return <EmptyData title={'Нет инструментов'} description={'Добавьте инструменты в список избранных.'} />;
    }

    return (
        <div className={styles.watchlistTableWrapper}>
            {nameTooltipData.visible && (
                <div
                    style={{ top: nameTooltipData.pos }}
                    className={classNames(styles.nameWrapper_tooltip, 'flex', 'flex-column')}
                >
                    <span>{nameTooltipData.shortName}</span>
                    <div className={classNames(styles.tooltip_date, 'flex')}>
                        <span>Последнее обновление:</span>
                        <span>{nameTooltipData.updateDate}</span>
                    </div>
                    <ArrowDownIcon />
                </div>
            )}
            <DataTable
                value={watchlistData}
                stripedRows
                removableSort
                rowClassName={rowClass}
                sortIcon={<SortIcon className={styles.sortIcon} />}
                onSort={handleSort}
                sortOrder={sortOrder}
                sortField={sortField}
                scrollable
                pt={{
                    table: { className: styles.table },
                    thead: { className: styles.tableHeader },
                    tbody: { className: styles.tableBody },
                    bodyRow: { className: styles.tableRow },
                }}
            >
                <Column
field={'shortName'} header={'Название'} body={nameTemplate}
sortable />
                <Column
field={'close'} header={'Цена посл. сделки'} body={closeTemplate}
sortable />
                <Column
                    field={selectedSettingParam.code}
                    header={selectedSettingParam.shortName}
                    body={paramTemplate}
                    sortable
                />
                <Column body={(row, column) => actionBtnTemplate(row, column)} />
            </DataTable>
        </div>
    );
};
