import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getReportUploadURL: ApiFactory<
    ApiEndpoint<{ uploadUrl: string; reportID: string }, void, { name?: string }>
> = (client) => async ({ signal, params }) => {
    const res = await client.get<{ uploadUrl: string; reportID: string }>(
        '/moex-datashop-investor-pro-portfolios-service/api/v1/reports/upload',
        { signal, params },
    );

    return res.data;
};
