import { Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import { type KeyTools } from '../../../../entities/portfolio';
import { ViewEmptyStub } from '../../../../shared/Layout';
import { Table } from '../../../../shared/Table';
import { useToolsTableColumns } from '../../GeneralInfo/ToolsTable/useToolsTableColumns';
import { InfoIcon } from '../ReportIcons/InfoIcon';

type Props = {
    data: KeyTools[];
};

export const ReportAssetAllocation: FC<Props> = ({ data }) => {
    const columns = useToolsTableColumns(true);

    return (
        <Stack gap={33} w={1350} p={5}>
            <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
                <Group align="center" justify="space-between">
                    <Text
fz="45px" fw={400} lh="47px"
style={{ whiteSpace: 'pre-wrap' }}>
                        {'Состав портфеля по инструментам'}
                    </Text>
                    <Group
                        gap={12}
                        miw={180}
                        mih={24}
                        px={16}
                        align="center"
                        py={8}
                        style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                    >
                        <InfoIcon />
                        <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                            {'Информация'}
                        </Text>
                    </Group>
                </Group>
                <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px" style={{ whiteSpace: 'pre-wrap' }}>
                    {
                        'В разделе Состав портфеля вы найдете весь список инструментов, \
из которых состоит портфель.\n\n\
По каждому инструменту вы можете посмотреть, к какому классу активов он относится, его долю в портфеле, и доходность, \
которую инструмент дал за рассматриваемый период. Эта информация поможет легко определять лидеров и аудсайдеров \
портфеля, и правильно корректировать его структуру.'
                    }
                </Text>
            </Stack>
            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={30}>
                <Text fz={36} fw={400}>
                    {'Инструменты в портфеле'}
                </Text>
                <Table
                    columns={columns}
                    data={data}
                    striped={true}
                    emptyStub={<ViewEmptyStub title="Данных по инструментам нет" />}
                />
            </Stack>
        </Stack>
    );
};
