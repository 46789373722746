import { type PortfolioTypologyAssetsMatrixData } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getStocks: ApiFactory<ApiEndpoint<
    PortfolioTypologyAssetsMatrixData, void, { portfolioId: string }
>> = (client) => async ({ signal, params }) => {
    const res = await client.get<PortfolioTypologyAssetsMatrixData>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/asset-typology/${params.portfolioId}/stocks`,
        { signal },
    );

    return res.data;
};
