import classNames from 'classnames';
import { EmptyData } from '@modules/Investorpro/shared/components/WatchlistSidebar/components/EmptyData';
import { Button } from 'primereact/button';
import { PlusIcon } from 'primereact/icons/plus';
import { AddWatchlistModal } from '@modules/Investorpro/shared/components/WatchlistSidebar/components/AddWatchlistModal';
import { useModal } from '@libs/utils';

import styles from './styles.module.scss';

export const EmptyFavorites = () => {
    const { isOpen, openModal, closeModal } = useModal();

    return (
        <>
            <div
                className={classNames(
                    styles.emptyFavoritesWrapper,
                    'flex',
                    'flex-column',
                    'align-items-center',
                    'justify-content-center',
                )}
            >
                <EmptyData
                    title={'Списков пока нет'}
                    description={
                        'Создайте свой первый список избранных инструментов. Это отличный способ анализировать и отслеживать\n' +
                        '                    ценные бумаги.'
                    }
                />
                <Button
                    className={classNames(styles.btn, 'flex', 'justify-content-center', 'align-items-center')}
                    icon={<PlusIcon />}
                    label={'Создать список'}
                    onClick={openModal}
                />
            </div>
            <AddWatchlistModal isOpen={isOpen} closeModal={closeModal} />
        </>
    );
};
