import { Chart } from 'primereact/chart';
import { type ReactNode, useEffect, useMemo, useRef, memo, useState, useCallback } from 'react';
import { Chart as ChartJS, type TooltipItem } from 'chart.js';
import 'chart.js/auto';

import { commonChartOptions } from '@modules/Investorpro/configs/commonChartOptions';
import { TooltipWrapper } from '@modules/Investorpro/shared/components/TooltipWrapper';
import { getNumberWithSpacing, useChartTooltip } from '@libs/utils';
import { debounce, round } from 'lodash';

import { CustomTreemapController } from './utils/controller';
import styles from './style.module.scss';
import { TreemapElement, checkIsContainer } from './utils/element';
import { CaptionTooltip } from './components/CaptionTooltip';

type Props<T = any> = {
    data: T[];
    width?: number;
    height?: number;
    title?: string;
    valueKey?: string;
    labelKey?: string;
    groups?: string[];
    measureKey?: string;
    getColor?: (item: T) => string;
    renderTooltip?: (data: any) => ReactNode;
};

type Alignment = 'left' | 'right' | 'bottom' | 'top';

export type CaptionTooltipData = {
    x: number;
    y: number;
    visible: boolean;
    title: string;
    alignment: Alignment;
};

const initCaptionTooltipData: CaptionTooltipData = {
    x: 0,
    y: 0,
    visible: false,
    alignment: 'top',
    title: '',
};

export const HeatMap = memo(
    ({
        data,
        width,
        height,
        valueKey = 'value',
        labelKey = 'label',
        measureKey = 'measure',
        groups,
        renderTooltip,
        getColor,
    }: Props) => {
        const chartRef = useRef<Chart>(null);
        const [captionTooltip, setCaptionTooltip] = useState<CaptionTooltipData>(initCaptionTooltipData);
        const captionTooltipRef = useRef<HTMLDivElement>(null);

        const handleCaptionTooltip = useCallback(
            debounce((x: number | null, y: number | null, title: string | null) => {
                if (!x || !y || !title) {
                    setCaptionTooltip((prev) => ({
                        ...prev,
                        visible: false,
                    }));

                    return;
                }
                const chart = chartRef.current?.getChart();
                const xPos = (chart.chartArea?.left ?? 0) + x;
                const yPos = (chart.chartArea?.top ?? 0) + y;

                const getPosWithOffsetByAlignment = (alignment: Alignment) => {
                    const tooltipWidth = captionTooltipRef.current?.clientWidth ?? 0;
                    const tooltipHeight = captionTooltipRef.current?.clientHeight ?? 0;
                    const PADDING = 10;

                    if (alignment === 'top') {
                        return {
                            left: xPos - tooltipWidth / 2 - PADDING,
                            right: xPos + tooltipWidth / 2 + PADDING,
                            top: yPos - tooltipHeight - PADDING,
                            bottom: yPos,
                        };
                    }

                    if (alignment === 'bottom') {
                        return {
                            left: xPos - tooltipWidth / 2 - PADDING,
                            right: xPos + tooltipWidth / 2 + PADDING,
                            top: yPos,
                            bottom: yPos + tooltipHeight + PADDING,
                        };
                    }

                    if (alignment === 'left') {
                        return {
                            left: xPos - tooltipWidth - PADDING,
                            right: xPos,
                            top: yPos - tooltipHeight / 2 - PADDING,
                            bottom: yPos + tooltipHeight / 2 + PADDING,
                        };
                    }

                    return {
                        left: xPos,
                        right: xPos + tooltipWidth + PADDING,
                        top: yPos - tooltipHeight / 2 - PADDING,
                        bottom: yPos + tooltipHeight / 2 + PADDING,
                    };
                };

                let currentAlignment: Alignment = 'top';
                const { width: chartWidth, height: chartHeight } = chartRef.current
                    ?.getElement()
                    ?.getBoundingClientRect() ?? { width: 0, height: 0 };

                let aligned = false;
                const triedAlignments: Alignment[] = [];

                while (!aligned) {
                    if (triedAlignments.length === 4) {
                        aligned = true;
                        break;
                    }

                    triedAlignments.push(currentAlignment);
                    const { left, right, bottom, top } = getPosWithOffsetByAlignment(currentAlignment);

                    if (top < 0) {
                        currentAlignment = 'bottom';
                    } else if (bottom > chartHeight + 50) {
                        currentAlignment = 'top';
                    } else if (right > chartWidth) {
                        currentAlignment = 'left';
                    } else if (left < 0) {
                        currentAlignment = 'right';
                    } else {
                        aligned = true;
                    }
                }

                setCaptionTooltip({
                    visible: true,
                    title: title ?? '',
                    alignment: currentAlignment,
                    x: xPos,
                    y: yPos,
                });
            }, 50),
            [debounce],
        );

        const withSelection = (groups?.length || 0) > 0;

        const filterTooltipData = (item: TooltipItem<any>) => !checkIsContainer(item.raw);

        const setTooltipPosition = (context: any, el: HTMLDivElement) => {
            const { tooltip } = context;

            const x = tooltip.caretX;
            const y = tooltip.caretY + context.chart.chartArea.top;

            return { y, x };
        };

        const [tooltipRef, dataPoints, setTooltipData, alignment, resetTooltipData] = useChartTooltip<any>({
            setTooltipPosition,
            filterTooltipData,
        });

        const getElementBackgroundColor = (ctx: any) => {
            if (ctx.type !== 'data') {
                return 'transparent';
            }

            if (checkIsContainer(ctx.raw)) {
                return '#B7CEE533';
            }

            if (getColor) {
                return getColor(ctx.raw._data.children[0]);
            }

            const pallette = {
                1: '#42A62E',
                2: '#F2433D',
                3: '#FFA800',
                4: '#FFD355',
                5: '#FFEA83',
                6: '#FFF9C4',
                7: '#FF69C5',
                8: '#FF79C6',
                9: '#FF89C7',
                10: '#FF99C8',
            };

            // @ts-expect-error - change
            return pallette[ctx.raw.v] || '#B7CEE533';
        };

        const chartData = useMemo(() => {
            return {
                datasets: [
                    {
                        tree: withSelection
                            ? data.map((item, i) => ({
                                  ...item,
                                  index: i + 1,
                                  captionTooltipHandler: handleCaptionTooltip,
                              }))
                            : data,
                        key: valueKey,
                        borderColor: 'transparent',
                        borderWidth: 0,
                        borderRadius: 5,
                        spacing: 1,
                        groups: groups && withSelection ? [...groups, 'index'] : undefined,
                        backgroundColor: getElementBackgroundColor,
                        padding: 5,
                        captions: {
                            align: 'center',
                            display: true,
                            color: '#8A8F9A',
                            font: {
                                size: 13,
                            },
                            height: 32,
                            padding: 5,
                            spacing: 2,
                            radius: 10,
                        },
                        labels: {
                            align: 'center',
                            display: true,
                            color: 'white',
                            font: {
                                size: 16,
                                weight: 350,
                                family: 'FavoritPro, sans-serif',
                                lineHeight: 20,
                            },
                            formatter: (ctx: any) => {
                                if (withSelection) {
                                    const name = (ctx.raw._data.children[0][labelKey] as string).toUpperCase();
                                    const value = round(+(ctx.raw._data.children[0][measureKey] as number), 2) + '%';
                                    const zoneLabel = name + ' ' + value;

                                    return zoneLabel;
                                }
                                const label = (ctx.raw._data[labelKey] as string) || '';

                                return label.toUpperCase();
                            },
                            position: 'center',
                        },
                    },
                ],
            };
        }, [data, valueKey, labelKey, measureKey]);

        useEffect(() => {
            ChartJS.register(CustomTreemapController, TreemapElement as any);
        }, []);

        const options = useMemo(() => {
            return {
                ...commonChartOptions,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        intersect: false,
                        mode: 'point',
                        enabled: false,
                        external: setTooltipData,
                    },
                },
            };
        }, []);

        const clearCaptionTooltip = () => {
            setTimeout(
                () => setCaptionTooltip((prev) => ({
                        ...prev,
                        visible: false,
                    })),
                100,
            );
        };

        const widthWithoutScrollbar = (width ?? 1371) - (document.body.offsetWidth - document.body.clientWidth);

        const onHeatMapMouseLeave = () => {
            clearCaptionTooltip();
            resetTooltipData();
        };

        return (
            <div
                className={styles.chart}
                onMouseLeave={onHeatMapMouseLeave}
                style={{
                    width: widthWithoutScrollbar,
                    maxWidth: widthWithoutScrollbar,
                }}
            >
                <CaptionTooltip {...captionTooltip} ref={captionTooltipRef} />
                <Chart
                    type="customTreemap"
                    ref={chartRef}
                    data={chartData}
                    width={widthWithoutScrollbar + 'px'}
                    height={(height ?? 681) + 'px'}
                    options={options}
                />
                <TooltipWrapper
                    ref={tooltipRef}
                    visible={!captionTooltip.visible && dataPoints?.length > 0}
                    transparent={false}
                    alignment={alignment}
                    className={styles.tooltip}
                >
                    {dataPoints?.length > 0 && renderTooltip?.(((dataPoints?.[0]?.raw as any) ?? [])._data.children[0])}
                </TooltipWrapper>
            </div>
        );
    },
);
