import { Chart } from 'primereact/chart';
import {
    type ChartOptions,
    type TooltipItem,
    type ChartTypeRegistry,
    type Chart as ChartType,
    type Point,
} from 'chart.js';
import { type CSSProperties, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'chart.js/auto';
import { useChartTooltip, getGradient, isValueExist } from '@libs/utils';
import { type TooltipContext, type ChartFillGradient, type DatasetWithColorOptions } from '@libs/types';
import classNames from 'classnames';
import { Loader } from '@libs/components';
import { WithLoader } from '@libs/components/loaders/ViewLoader';

import { TooltipWrapper } from '../../../../modules/Investorpro/shared/components/TooltipWrapper';
import { commonChartOptions } from '../../../../modules/Investorpro/configs/commonChartOptions';
import styles from './styles.module.scss';

export type MarkData<T extends keyof ChartTypeRegistry = 'line'> = {
    pos: number;
    color?: string;
    value: string | number | null;
    dataset: DatasetWithColorOptions<T>;
    datasetIndex: number;
    positionIndex?: number;
};

type MarksPositions = 'chartArea' | 'totalChart' | 'relative';

export type YAxisAnnotationMarks = {
    disabled?: boolean;
    renderMark: (data: MarkData, index: number, positionIndex?: number) => React.ReactNode;
    xOffset?: number;
    allocateMarks?: boolean;
    marksWrapperClassName?: string;
    position?: MarksPositions;
};

type Tooltip<T extends keyof ChartTypeRegistry> = {
    renderTooltipData: (data: Array<TooltipItem<T>>) => React.ReactNode;
    className?: string;
    disabled?: boolean;
    printPoints?: boolean;
    transparent?: boolean;
    handlePrintPoints?: (
        context: TooltipContext<'line'>,
        coords: Array<{ x: number; y: number; color: string }>,
        highestIndex: number,
    ) => void;
    setTooltipPosition?: (context: TooltipContext<'line'>, el: HTMLDivElement) => { x: number; y: number };
    mode?: 'nearest' | 'index' | 'point' | 'x' | 'y' | 'dataset';
};

type LegendProps = {
    position?: 'inside';
    xAxisTitle: string;
    yAxisTitle: string;
    xAxisOffsets?: {
        left?: number;
        bottom?: number;
    };
    yAxisOffsets?: {
        right?: number;
        top?: number;
    };
};

type Extra = {
    disabled?: boolean;
    timer?: number;
    renderExtra: (chartRef: Chart) => React.ReactNode;
};

type Props<T extends keyof ChartTypeRegistry = any> = {
    data: Array<DatasetWithColorOptions<T>>;
    width?: number;
    height?: number;
    options?: ChartOptions<'line'>;
    withHorizontalAnnotationLine?: boolean;
    withVerticalAnnotationLine?: boolean;
    tooltip?: Tooltip<T>;
    children?: React.ReactNode;
    yAxisAnnotationMarks?: YAxisAnnotationMarks;
    withGradient?: boolean;
    deleteXOffset?: boolean;
    loading?: boolean;
    initLoading?: boolean;
    className?: string;
    legend?: LegendProps;
    extra?: Extra;
    type?: string;
    style?: CSSProperties;
};

export const LinearChart = memo(
    ({
        data,
        width,
        height,
        options: customOptions,
        withHorizontalAnnotationLine = false,
        withVerticalAnnotationLine = false,
        tooltip,
        initLoading,
        loading,
        yAxisAnnotationMarks,
        children,
        withGradient,
        legend,
        className,
        deleteXOffset = false,
        extra,
        type,
        style,
    }: Props) => {
        const chartRef = useRef<Chart>(null);
        const [extraMounted, setExtraMounted] = useState(false);
        const [currentValues, setCurrentValues] = useState<MarkData[]>([]);
        const [renderCount, setRenderCount] = useState(0);
        const chartEl = chartRef.current;

        const chart = chartEl?.getChart() as ChartType | undefined;
        const pointRadius = 7;
        const canvas = chartEl?.getCanvas();

        const showTooltip = !tooltip?.disabled && tooltip;
        const showMarks = yAxisAnnotationMarks && !yAxisAnnotationMarks?.disabled;
        const showExtra = extra && !extra?.disabled && extraMounted;

        const marksPositions: Record<MarksPositions, object> = {
            chartArea: {
                left: (yAxisAnnotationMarks?.xOffset ?? 0) + (chart?.chartArea.right ?? 0) + 'px',
                position: 'absolute',
            },
            totalChart: {
                right: -(yAxisAnnotationMarks?.xOffset ?? 0) + 'px',
                position: 'absolute',
            },
            relative: {},
        };

        const printAnnotationLines = useCallback(
            (context: TooltipContext<'line'>) => {
                const {
                    chart: { ctx, chartArea },
                    tooltip: { dataPoints },
                } = context;

                if (!ctx || !chartArea) return;

                const coords = dataPoints.map((point) => ({
                    x: point.element.x,
                    y: point.element.y,
                    color: point.element.options.borderColor,
                }));

                let highestIndex = 0;

                for (let i = 0; i < coords.length; i++) {
                    if (coords[i]?.y < coords[highestIndex]?.y) {
                        highestIndex = i;
                    }
                }

                ctx.lineWidth = 2;
                ctx.strokeStyle = coords[highestIndex].color;

                const { x, y } = coords[highestIndex];

                ctx.save();
                ctx.beginPath();
                ctx.setLineDash([5, 5]);

                if (withHorizontalAnnotationLine) {
                    ctx.moveTo(x, y);
                    ctx.lineTo(chartArea.right, y);
                    ctx.stroke();
                }

                if (withVerticalAnnotationLine) {
                    ctx.moveTo(x, y);
                    ctx.lineTo(x, chartArea.bottom);
                    ctx.stroke();
                }

                ctx.setLineDash([]);

                if (tooltip?.printPoints !== false) {
                    if (tooltip?.handlePrintPoints) {
                        tooltip?.handlePrintPoints(context, coords, highestIndex);
                    } else {
                        for (let i = 0; i < coords.length; i++) {
                            ctx.beginPath();
                            const { x, y, color } = coords[i];
                            const strokeWidth = 4;
                            ctx.lineWidth = strokeWidth;
                            ctx.arc(x, y, pointRadius - strokeWidth, 0, 2 * Math.PI);
                            ctx.strokeStyle = color;
                            ctx.stroke();
                            ctx.fillStyle = 'white';
                            ctx.fill();
                            ctx.restore();
                        }
                    }
                }
                ctx.restore();
            },
            [data],
        );

        const dataSetsWithTooltip = useMemo(() => {
            return data
                .map((data, index) => (data?.tooltipEnabled !== false ? index : null))
                .filter((data) => data !== null) as number[];
        }, [data]);

        const setTooltipPosition = (context: TooltipContext<'line'>, el: HTMLDivElement) => {
            const { tooltip } = context;
            const x = tooltip.caretX;
            let y = Infinity;

            for (let i = 0; i < tooltip.dataPoints.length; i++) {
                y = Math.min(tooltip.dataPoints[i].element.y, y);
            }

            return { x, y };
        };

        const [tooltipRef, dataPoints, setTooltipData, tooltipAlignment, resetTooltipData] = useChartTooltip<
            any,
            'line'
        >({
            handleToolTipHover: printAnnotationLines,
            setTooltipPosition: tooltip?.setTooltipPosition ?? setTooltipPosition,
            datasets: dataSetsWithTooltip,
            yOffset: pointRadius * 2,
        });

        const chartData = useMemo(() => {
            return {
                datasets: data.map(
                    ({
                        color,
                        pointColor,
                        tooltipEnabled = true,
                        gradient,
                        backgroundColor,
                        borderWidth,
                        pointRadius,
                        pointBackgroundColor,
                        ...item
                    }) => ({
                        ...item,
                        backgroundColor:
                            backgroundColor ??
                            ((withGradient && gradient?.enabled !== false) || (!withGradient && gradient?.enabled)
                                ? getGradient({
                                      ...(gradient ?? ({} as ChartFillGradient)),
                                      color: gradient?.color ?? color,
                                  })
                                : undefined),
                        fill: withGradient ? true : item?.fill ?? false,
                        borderWidth: borderWidth ?? 2,
                        borderColor: color,
                        pointRadius: pointRadius ?? 0,
                        pointBorderColor: pointColor,
                        pointBackgroundColor: pointBackgroundColor ?? 'white',
                    }),
                ),
            };
        }, [data, withGradient, setTooltipData]);

        const options = useMemo(() => {
            const maxXValues = deleteXOffset
                ? Math.max(...data.map(({ data }) => (data as Point[]).map(({ x }) => x)).flat())
                : undefined;

            return {
                ...commonChartOptions,
                ...(customOptions ?? {}),
                plugins: {
                    ...(customOptions?.plugins ?? {}),
                    legend: {
                        display: false,
                        ...(customOptions?.plugins?.legend ?? {}),
                    },
                    tooltip: {
                        mode: tooltip?.mode ?? 'index',
                        ...(customOptions?.plugins?.tooltip ?? {}),
                        intersect: false,
                        enabled: false,
                        external: setTooltipData,
                    },
                },
                responsive: true,
                scales: {
                    ...commonChartOptions.scales,
                    ...(customOptions?.scales ?? {}),
                    x: {
                        max: maxXValues,
                        ...commonChartOptions.scales.x,
                        ...(customOptions?.scales?.x ?? {}),
                        ticks: {
                            ...(customOptions?.scales?.x?.ticks ?? {}),
                        },
                        grid: {
                            ...commonChartOptions.scales.x.grid,
                            ...(customOptions?.scales?.x?.grid ?? {}),
                        },
                        border: {
                            ...commonChartOptions.scales.x.border,
                            ...(customOptions?.scales?.x?.border ?? {}),
                        },
                    },
                    y: {
                        ...commonChartOptions.scales.y,
                        ...(customOptions?.scales?.y ?? {}),
                        offset: false,
                        ticks: {
                            maxTicksLimit: 8,
                            ...commonChartOptions.scales.y.ticks,
                            ...(customOptions?.scales?.y?.ticks ?? {}),
                        },
                        grid: {
                            ...commonChartOptions.scales.y.grid,
                            ...(customOptions?.scales?.y?.grid ?? {}),
                        },
                        border: {
                            ...commonChartOptions.scales.y.border,
                            ...(customOptions?.scales?.y?.border ?? {}),
                        },
                    },
                },
            };
        }, [data, tooltip?.mode, deleteXOffset]);

        const showLegend = Boolean(legend);

        useEffect(() => {
            resetTooltipData();
        }, [data]);

        useEffect(() => {
            const notHaveChartObject = !chart || !canvas;

            if (notHaveChartObject && renderCount < 3) {
                setTimeout(() => setRenderCount((prev) => prev + 1), 100);

                return;
            }

            if (notHaveChartObject) {
                return;
            }

            if (yAxisAnnotationMarks?.renderMark && yAxisAnnotationMarks.disabled !== true) {
                setTimeout(() => {
                    const getYPixelByValue = (value: number) => {
                        const topPadding = chart.chartArea.top;
                        const bottomPadding = chart.height - chart.chartArea.bottom;
                        const chartHeight = isValueExist(height)
                            ? Math.max(height - topPadding - bottomPadding, 0)
                            : chart.chartArea.height;
                        const { max, min } = chart.scales.y;

                        return chartHeight * (1 - (value - min) / (max - min)) + topPadding;
                    };

                    const currentValues = data
                        .map((dataset, index) => {
                            const value = (dataset?.data?.at(-1) as Point)?.y ?? null;

                            return {
                                dataset,
                                datasetIndex: index,
                                value,
                                color: dataset.color,
                                pos: getYPixelByValue(value),
                            };
                        })
                        .sort((a, b) => a.pos - b.pos);

                    const allocateMarks = (initPoints: MarkData[], radius = 10): MarkData[] => {
                        const chartHeight = chart.chartArea.height;
                        const SPACING = 2;
                        const notNeedToAllocate =
                            initPoints.length * 2 * radius + (initPoints.length - 1) * SPACING > chartHeight ||
                            initPoints.length < 2;

                        if (notNeedToAllocate) {
                            return initPoints;
                        }

                        const sortedPoints = initPoints.sort((a, b) => a.pos - b.pos);

                        let groups = sortedPoints.map((point) => ({
                            start: point.pos - radius,
                            end: point.pos + radius,
                            points: [point],
                        }));

                        let hasJoin = true;

                        do {
                            const initGroupsCount = groups.length;
                            let i = 1;

                            while (i < groups.length) {
                                const group = groups[i];
                                const prevGroup = groups[i - 1];

                                if (group.start < prevGroup.end + SPACING) {
                                    prevGroup.end = prevGroup.end + (SPACING + radius * 2) * group.points.length;
                                    group.points.forEach((point) => prevGroup.points.push(point));
                                    groups = groups.filter((g) => g !== group);
                                } else {
                                    i++;
                                }
                            }

                            hasJoin = initGroupsCount > groups.length;

                            if (hasJoin) {
                                for (let i = 0; i < groups.length; i++) {
                                    const groupCenter = groups[i].start + (groups[i].end - groups[i].start) / 2;
                                    const points = groups[i].points.map((point) => point.pos).sort((a, b) => a - b);
                                    const pointsCenter =
                                        points.length > 1
                                            ? points[0] + (points[points.length - 1] - points[0]) / 2
                                            : points[0];
                                    let delta = groupCenter - pointsCenter;
                                    delta = delta > 0 ? Math.min(delta, groups[i].start) : delta;
                                    delta = delta < 0 ? Math.min(delta, chartHeight - groups[i].end) : delta;
                                    groups[i].end -= delta;
                                    groups[i].start -= delta;
                                }
                            }
                        } while (hasJoin && groups.length > 1);

                        return groups.reduce<MarkData[]>((acc, group) => {
                            acc = [
                                ...acc,
                                ...group.points
                                    .sort((a, b) => a.pos - b.pos)
                                    .map((data, index) => ({
                                        ...data,
                                        pos: group.start + radius + (radius * 2 + SPACING) * index,
                                    })),
                            ];

                            return acc;
                        }, []);
                    };
                    const currentValuesMarksData = yAxisAnnotationMarks?.allocateMarks
                        ? allocateMarks(currentValues)
                        : currentValues;

                    setCurrentValues(currentValuesMarksData);
                });
            }
        }, [chart, canvas, data]);

        useEffect(() => {
            if (!extra?.disabled && extra) {
                if (extra.timer) {
                    const timer = setTimeout(() => {
                        setExtraMounted(true);
                    }, extra?.timer || 0);

                    return () => clearTimeout(timer);
                } else {
                    setExtraMounted(true);
                }
            }
        }, [extra?.disabled, extra?.timer]);

        const drawLegend = () => ({
            beforeDraw: (chart: ChartType) => {
                if (legend) {
                    const { xAxisTitle, yAxisTitle, xAxisOffsets, yAxisOffsets } = legend;
                    const { ctx } = chart;
                    ctx.restore();
                    const fontSize = 11;
                    ctx.font = fontSize + 'px sans-serif';
                    ctx.textBaseline = 'middle';

                    const measure = ctx.measureText(yAxisTitle).width;
                    const textX = chart.chartArea.right - (yAxisOffsets?.right ?? 10) - measure;
                    const textY = chart.chartArea.top + (yAxisOffsets?.top ?? 10);
                    ctx.fillText(yAxisTitle, textX, textY);

                    const botX = chart.chartArea.left + (xAxisOffsets?.left ?? 12);
                    const botY = chart.chartArea.bottom - (xAxisOffsets?.bottom ?? 32);
                    ctx.fillText(xAxisTitle, botX, botY);

                    ctx.save();
                }
            },
        });

        return (
            <div onMouseLeave={resetTooltipData} className={classNames(styles.chartWrapper, className, 'flex')}>
                <WithLoader isLoading={loading ?? false} hideChildrenOnLoading={initLoading} className="mr-8">
                    <Chart
                        type={type ?? 'line'}
                        ref={chartRef}
                        data={chartData}
                        plugins={[showLegend && drawLegend()]}
                        style={{ maxWidth: width ? width + 'px' : '1371px' }}
                        width={width ? width + 'px' : '1371px'}
                        height={height ? height + 'px' : '681px'}
                        options={options}
                    />
                    {showTooltip && (
                        <TooltipWrapper
                            alignment={tooltipAlignment}
                            ref={tooltipRef}
                            visible={dataPoints.length > 0}
                            transparent={tooltip.transparent}
                            className={tooltip.className}
                        >
                            {tooltip.renderTooltipData(dataPoints)}
                        </TooltipWrapper>
                    )}
                    {children}
                    {showExtra && extra && chartRef.current && extra.renderExtra(chartRef.current)}
                    {showMarks && (
                        <div
                            className={classNames(styles.marks, yAxisAnnotationMarks.marksWrapperClassName)}
                            style={marksPositions[yAxisAnnotationMarks.position ?? 'relative']}
                        >
                            {currentValues.map((markData, index) => yAxisAnnotationMarks.renderMark(markData, index))}
                        </div>
                    )}
                </WithLoader>
            </div>
        );
    },
);
