import { Dialog } from 'primereact/dialog';
import { type ReactNode } from 'react';
import { ReactComponent as CrossModalIcon } from '@shared/images/svg/CrossModalIcon.svg';
import classNames from 'classnames';
import { isMobileDevice } from '@libs/utils';
import { useOverlay } from '@libs/utils/hooks/useOverlay';

import styles from './styles.module.scss';

export type ModalWindowProps = {
    children?: ReactNode;
    isVisible: boolean;
    closeModal?: () => void;
    className?: string;
    maskClassName?: string;
    isClosable?: boolean;
    name?: string;
    overlayOnOpen?: boolean;
};

export const ModalWindow = ({
    children,
    isVisible,
    closeModal = () => null,
    className = '',
    maskClassName = '',
    isClosable = true,
    overlayOnOpen = true,
    name = 'modal',
}: ModalWindowProps) => {
    useOverlay(name, overlayOnOpen && isVisible);
    const isMobile = isMobileDevice();

    return (
        <Dialog
            showHeader={false}
            onHide={closeModal}
            visible={isVisible}
            keepInViewport={false}
            className={classNames(className, styles.modal)}
            closable={isClosable}
            pt={{
                mask: { className: classNames({ [maskClassName]: maskClassName, [styles.isMobile]: isMobile }) },
            }}
        >
            {isClosable && <CrossModalIcon onClick={closeModal} className={styles.closeIcon} />}
            {children}
        </Dialog>
    );
};
