import classNames from 'classnames';
import { type HTMLAttributes, type ReactNode } from 'react';
import { SelectButton } from '@modules/Investorpro/shared/components/SelectButton';

import styles from './styles.module.scss';

type Option = {
    label: string;
    value: string;
};

type Props = {
    title: string;
    options: Option[];
    value: string;
    type?: 'value' | 'index';
    handleChange: (value: string) => void;
    extra?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const SelectedButtonTabs = ({
    title,
    options,
    value,
    extra,
    type = 'index',
    handleChange,
    className,
    children,
    ...props
}: Props) => {
    return (
        <div className={classNames('flex', 'flex-column', styles.tabsContainer, className)} {...props}>
            <div className={classNames('flex', 'align-items-center', 'justify-content-between')}>
                <h2>{title}</h2>
                <div className={classNames('flex', 'align-items-center', styles.selectButtonWrapper)}>
                    <SelectButton options={options} value={value} handleValueChange={handleChange} />
                    {extra}
                </div>
            </div>
            {children}
        </div>
    );
};
