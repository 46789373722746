import { httpClient } from '@configs/httpclient.config'
import {
    type GetStockCompanyDescriptionResponse,
    type GetStockCompanyFoundDateResponse,
    type GetStockEventsRequestResponse,
    type GetStockCompanyProfitResponse,
    type GetStockDataRequestResponse,
    type GetStockDividendsGapRequestResponse,
    type GetStockEvEbitdaResponse,
    type GetStockNearestReportDateResponse,
    type GetStockPEResponse,
    type GetStockEventsRequestBody,
    type GetStockDividendsDataRequestBody,
    type GetStockDividendsDataRequestServerResponse,
    type GetStockDividendsDataRequestResponse,
} from '@libs/types/instrument.type'
import { parseServerNumberValue } from '@modules/Investorpro/shared/utils/service.utils'

export const STOCK_DATA_URL = '/moex-datashop-instrument-service/api/v1/'

export const getStockData = async (secId: string, tradeDate: string):
Promise<GetStockDataRequestResponse> => {
    return await httpClient.get<GetStockDataRequestResponse['data']>(STOCK_DATA_URL + 'shares/share_data', {
        params: {
            secId,
            tradeDate,
        },
    })
}

export const getStockCompanyDescription = async (fininstId: number): Promise<GetStockCompanyDescriptionResponse> => {
    return await httpClient.post(STOCK_DATA_URL + 'emitent/profile', { fininstId })
}

export const getStockDividends = async (body: GetStockDividendsDataRequestBody):
Promise<GetStockDividendsDataRequestResponse> => {
    const response = await httpClient.post<GetStockDividendsDataRequestServerResponse>(
        STOCK_DATA_URL + 'shares/dividend', body,
    )

    return {
        ...response.data,
        data: {
            ...response.data.data,
            rows: response.data.data.rows.map(({ gap_dividend, payStock, dy, gapLength, ...dividend }) => ({
                ...dividend,
                payStock: parseServerNumberValue(payStock)!,
                dy: parseServerNumberValue(dy),
                gapLength: parseServerNumberValue(gapLength),
                gap: parseServerNumberValue(gap_dividend),
            })),
        },
    }
}

export const getStockCompanyFoundDate = async (fininstId: number): Promise<GetStockCompanyFoundDateResponse> => {
    return await httpClient.post(STOCK_DATA_URL + 'emitent/info', { fininstId })
}

export const getStockNearestReportDate = async (fininstId: number): Promise<GetStockNearestReportDateResponse> => {
    return await httpClient.post(STOCK_DATA_URL + 'emitent/calendar', { fininstId })
}

export const getStockCompanyProfit = async (fininstId: number): Promise<GetStockCompanyProfitResponse> => {
    return await httpClient.post(STOCK_DATA_URL + 'emitent/report', { fininstId })
}

export const getStockPE = async (fintoolId: number): Promise<GetStockPEResponse> => {
    return await httpClient.get(STOCK_DATA_URL + 'shares/pe', { params: { fintoolId } })

}

export const getEV_EBITDAdata = async (fininstId: number): Promise<GetStockEvEbitdaResponse> => {
    return await httpClient.post(STOCK_DATA_URL + 'emitent/multipliers', { fininstId })

}

export const getStockDividendsGap = async (fininstId: number): Promise<GetStockDividendsGapRequestResponse> => {
    return await httpClient.get(STOCK_DATA_URL + 'shares/gap', { params: { fininstId } })
}


export const getStockEvents = async (body: GetStockEventsRequestBody): Promise<GetStockEventsRequestResponse> => {
    return await httpClient.post(STOCK_DATA_URL + 'emitent/calendar', body
    ? {
        ...body,
        dateFrom: body.dateFrom?.toJSON(),
        dateTo: body.dateTo?.toJSON(),
    }
    : {})
}
