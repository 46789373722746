import { Group, Pagination as MantinePagination, Text } from '@mantine/core';
import { type FC } from 'react';

type Props = {
    onChange: (value: number) => void;
    total: number;
    value: number;
};

export const Pagination: FC<Props> = ({ onChange, total, value }) => (
    <MantinePagination.Root
        boundaries={1}
        onChange={onChange}
        siblings={1}
        total={total}
        value={value}
        size={36}
        mt={20}
    >
        <Group gap={4} justify="start">
            {value !== 1
? (
                <MantinePagination.Previous px={26} icon={() => <Text fz={11}>{'Предыдущая'}</Text>} />
            )
: null}

            <MantinePagination.Items />

            {value !== total
? (
                <MantinePagination.Next px={26} icon={() => <Text fz={11}>{'Следующая'}</Text>} />
            )
: null}
        </Group>
    </MantinePagination.Root>
);
