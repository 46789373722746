import { usePDF } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { type FC, Fragment, type ReactElement, useEffect, useMemo, useState } from 'react';

import { useGetReportUploadURLQuery } from './useGetReportUploadURLQuery';
import { useUploadReportFileMutation } from './useUploadReportFileMutation';

type Props = {
    pdfDocument: ReactElement;
    onDownload: () => void;
    isFullReportDownloading?: boolean;
    portfolioName?: string;
};

export const PdfDocumentDownloader: FC<Props> = ({
    pdfDocument,
    onDownload,
    isFullReportDownloading = false,
    portfolioName,
}) => {
    const [fileToDownload, setFileToDownload] = useState<File | null>(null);
    const { mutate: uploadReportFile } = useUploadReportFileMutation();
    const [reportInstance] = usePDF({ document: pdfDocument });
    const reportName = useMemo(
        () => `${portfolioName ?? 'report'}_${dayjs().format('DD.MM.YYYY')}_${dayjs().format('HH:mm:ss')}.pdf`,
        [portfolioName],
    );

    const reportUploadURLQuery = useGetReportUploadURLQuery(reportName, isFullReportDownloading);

    useEffect(() => {
        if (fileToDownload && reportUploadURLQuery.data?.uploadUrl) {
            uploadReportFile({ file: fileToDownload, uploadUrl: reportUploadURLQuery.data.uploadUrl });
            onDownload();
        }
    }, [fileToDownload, reportUploadURLQuery.data?.uploadUrl, uploadReportFile, onDownload]);

    useEffect(() => {
        if (reportInstance.url) {
            const link = document.createElement('a');
            const file = new File([reportInstance.blob ?? ''], reportName);

            link.href = reportInstance.url || '';
            link.setAttribute('download', reportName);
            link.click();
            link.parentNode?.removeChild(link);

            if (isFullReportDownloading) {
                setFileToDownload(file);
            } else {
                onDownload();
            }
        }
    }, [isFullReportDownloading, onDownload, reportInstance, reportName]);

    return <Fragment />;
};
