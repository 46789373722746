import { useAppSelector } from '@store/store'

export enum Permission {
    WATCHLISTS = 'watchlists',
    ALERTS = 'alerts',
    PORTFOLIO = 'portfolios',
    ANALYTIC = 'analytics',
    SCREENER = 'screener',
    SEARCH = 'search',
    ALL = 'all',
}

export const usePermission = () => {
    const state = useAppSelector((state) => state.permissions);
    const {
        hasPermissionWatchlist,
        hasPermissionAlert,
        hasPermissionPortfolio,
        hasPermissionAnalytic,
        hasPermissionScreener,
        hasPermissionSearch,
    } = state;

    const getPermissionByKey = (key: Permission) => {
        switch (key) {
            case Permission.WATCHLISTS:
                return hasPermissionWatchlist;
            case Permission.ALERTS:
                return hasPermissionAlert;
            case Permission.PORTFOLIO:
                return hasPermissionPortfolio;
            case Permission.ANALYTIC:
                return hasPermissionAnalytic;
            case Permission.SCREENER:
                return hasPermissionScreener;
            case Permission.SEARCH:
                return hasPermissionSearch;
            case Permission.ALL:
                return true;
            default:
                return false;
        }
    }

    return {
        ...state,
        getPermissionByKey,
    }
}
