import classNames from 'classnames';
import { type ReactNode } from 'react';

import styles from './styles.module.scss';

export type TooltipContentType = {
    title?: string | ReactNode;
    content?: string | number | null | ReactNode;
    divider?: boolean;
};

type Props = {
    className?: string;
    title?: string | ReactNode;
    content: TooltipContentType[];
};

export const TooltipContent = ({ className, title, content }: Props) => {
    return (
        <div className={classNames('flex', 'flex-column', styles.tooltip, className)}>
            <div className={classNames('flex', 'align-items-center', 'tooltip_header', styles.tooltip_header)}>
                {title}
            </div>
            <div className={classNames('flex', 'flex-column', styles.tooltip_content)}>
                {content.map(({ title, content, divider }, index) => divider
? (
                        <div key={index} className={styles.tooltip_divider} />
                    )
: (
                        <div className="flex justify-content-between" key={index}>
                            <span>{title}</span>
                            <span>{content}</span>
                        </div>
                    ),
                )}
            </div>
        </div>
    );
};
