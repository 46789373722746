import { BreadCrumb as BreadCrumbComponent } from 'primereact/breadcrumb';
import { useLocation, NavLink } from 'react-router-dom';
import { type RootState } from '@store/store';
import { type MenuItem } from 'primereact/menuitem';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
    className?: string;
};

export const BreadCrumb = ({ className }: Props) => {
    const { pathname } = useLocation();
    const location = pathname.split('/');
    const stockName = useSelector((state: RootState) => state.stock.info?.name);
    const bondName = useSelector((state: RootState) => state.bond.info?.name);

    const rootUrl = '';
    const home = { template: <NavLink to={rootUrl}>Главная</NavLink>, disabled: true };

    const urlsLabels: Record<string, string> = {
        screeners: 'Скринеры',
        analytics: 'Аналитика',
        stocks: 'Акции',
        bonds: 'Облигации',
        portfolio: 'Портфель',
        market: 'Рынок',
        'bonds-market': 'Рынок облигаций',
        'stocks-market': 'Рынок акций',
    };

    let items: Array<Pick<MenuItem, 'label' | 'template' | 'disabled'> & { itemUrl: string }> = [];

    const getItemLabel = (item: string, prev: string) => {
        const label = urlsLabels[item] || '';

        if (prev === 'stocks' && stockName) {
            return stockName;
        }

        if (prev === 'bonds' && bondName) {
            return bondName;
        }

        return label;
    };

    for (let i = 0; i < location.length; i++) {
        items.push({
            label: getItemLabel(location[i], location[i - 1]),
            itemUrl: i === 0 ? rootUrl + location[i] : items[i - 1].itemUrl + '/' + location[i],
            disabled: i === location.length - 1 || ['bonds', 'stocks'].includes(location[i]),
        });
    }

    items = items.filter(({ label }) => label);
    items = items.map((item, index, arr) => ({
        ...item,
        template:
            index === arr.length - 1
? (
                <NavLink to={item.itemUrl}>
                    <span className={styles.current}>{item.label}</span>
                </NavLink>
            )
: (
                <NavLink to={item.itemUrl}>{item.label}</NavLink>
            ),
    }));

    return (
        <BreadCrumbComponent
            className={classNames(styles.breadCrumb, className)}
            separatorIcon={<span>/</span>}
            model={items}
            home={home}
        />
    );
};
