import {
    type PageableResponse,
    type PaginationParams,
    type PortfolioListItem,
} from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const getPortfolioList: ApiFactory<
    ApiEndpoint<PageableResponse<PortfolioListItem[]>, void, PaginationParams & { sort: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<PageableResponse<PortfolioListItem[]>>(
        '/moex-datashop-investor-pro-portfolios-service/api/v1/portfolio',
        { signal, params });

    return data;
};
