/* eslint-disable react/prop-types */
import { forwardRef } from 'react';
import { Box, type MantineLoaderComponent } from '@mantine/core';

import classes from './CustomLoader.module.css';

export const CustomLoader: MantineLoaderComponent = forwardRef(({ className, ...others }, ref) => {
    const combinedClasses = `${classes.loader} ${className ?? ''}`.trim();

    return (
        <Box
component="div" className={combinedClasses} {...others}
ref={ref}>
            <Box className={classes.bar} />
            <Box className={classes.bar} />
            <Box className={classes.bar} />
            <Box className={classes.bar} />
        </Box>
    );
});
