import { Table } from '@mantine/core';
import { type Cell, flexRender, type Row } from '@tanstack/react-table';

import { type HoveredIds } from './Table';
import { type CellStylesProperties } from './types';
import { type ColumnMeta } from '../../columnMetaType';

type Props<T extends Record<string, unknown>> = {
    cell: Cell<T, unknown>;
    onCellMouseEnter: (ids: HoveredIds) => void;
    onCellMouseLeave: () => void;
    hoveredIds: HoveredIds;
    highlightColumnOnHover?: boolean;
};

export const TableCell = <TableData extends Record<string, unknown>>({
    cell: { row, column, getContext },
    onCellMouseEnter,
    onCellMouseLeave,
    hoveredIds,
    highlightColumnOnHover,
}: Props<TableData>) => {
    const {
        backgroundColor,
        hoveredBackgroundColor = '#e7f0f9',
        padding = '13px 18px',
        ...styles
    } = (column.columnDef.meta as ColumnMeta)?.getCellStyles?.(row as Row<Record<string, unknown>>) ?? {};

    const cellStyles: CellStylesProperties = {
        ...styles,
        padding,
        backgroundColor:
            (hoveredIds.columnId === column.id && highlightColumnOnHover) || hoveredIds.rowId === row.id
                ? hoveredBackgroundColor
                : backgroundColor,
    };

    const handleMouseEnter = () => {
        onCellMouseEnter({ columnId: column.id, rowId: row.id });
    };

    const handleMouseLeave = () => {
        onCellMouseLeave();
    };

    return (
        <Table.Td
            align={(column.columnDef.meta as ColumnMeta)?.cellAlign}
            w={column.getSize()}
            style={cellStyles}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {flexRender(column.columnDef.cell, getContext())}
        </Table.Td>
    );
};
