/* eslint-disable @stylistic/js/max-len */
import { type FC } from 'react';

export const HealthcareIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m28.474 9.937-.6.01zm-9.394-6.19-.428.42zm-1.161 0 .428.42zm-9.393 6.19-.6-.01zm9.7 18.515.206-.564zm.546 0-.205-.564zm1.206-14.265h-.6a.6.6 0 0 0 .6.6zm0-3.281h.6a.6.6 0 0 0-.6-.6zm-2.956 0v-.6a.6.6 0 0 0-.6.6zm0 3.281v.6a.6.6 0 0 0 .6-.6zm-3.125 0v-.6a.6.6 0 0 0-.6.6zm0 3.106h-.6a.6.6 0 0 0 .6.6zm3.125 0h.6a.6.6 0 0 0-.6-.6zm0 3.281h-.6a.6.6 0 0 0 .6.6zm2.956 0v.6a.6.6 0 0 0 .6-.6zm0-3.282v-.6a.6.6 0 0 0-.6.6zm3.125 0v.6a.6.6 0 0 0 .6-.6zm0-3.105h.6a.6.6 0 0 0-.6-.6zm5.971-4.26c-.012-.677-.024-1.309-.024-1.92h-1.2c0 .624.012 1.267.024 1.94zm-.024-1.92c0-.786-.614-1.474-1.433-1.474v1.2c.102 0 .233.095.233.275zm-1.433-1.474c-3.42 0-5.972-1.023-8.108-3.205l-.857.84c2.386 2.436 5.26 3.565 8.965 3.565zM19.51 3.328a1.405 1.405 0 0 0-2.019 0l.858.84a.205.205 0 0 1 .304 0zm-2.019 0c-2.136 2.182-4.688 3.205-8.107 3.205v1.2c3.706 0 6.579-1.129 8.965-3.565zM9.383 6.533c-.82 0-1.433.688-1.433 1.475h1.2c0-.18.131-.275.233-.275zM7.95 8.008c0 .61-.012 1.242-.024 1.919l1.2.021c.012-.674.024-1.317.024-1.94zm-.024 1.919c-.057 3.122-.133 6.953 1.102 10.46 1.251 3.556 3.835 6.75 8.993 8.628l.41-1.127c-4.812-1.752-7.136-4.674-8.271-7.899-1.151-3.27-1.092-6.882-1.034-10.04zM18.02 29.016c.31.112.647.112.957 0l-.411-1.128a.2.2 0 0 1-.135 0zm.957 0c5.159-1.879 7.742-5.073 8.994-8.628 1.235-3.509 1.159-7.34 1.102-10.462l-1.2.022c.058 3.159.117 6.77-1.034 10.041-1.135 3.225-3.46 6.147-8.273 7.899zm1.6-14.829v-3.281h-1.2v3.281zm-.6-3.881h-2.956v1.2h2.956zm-3.556.6v3.281h1.2v-3.281zm.6 2.681h-3.125v1.2h3.125zm-3.725.6v3.106h1.2v-3.106zm.6 3.705h3.125v-1.2h-3.125zm2.525-.6v3.282h1.2v-3.282zm.6 3.882h2.956v-1.2h-2.956zm3.556-.6v-3.282h-1.2v3.282zm-.6-2.682h3.125v-1.2h-3.125zm3.725-.6v-3.105h-1.2v3.106zm-.6-3.705h-3.125v1.2h3.125z"
            fill="#636E80"
            opacity=".9"
        />
    </svg>
);
