/* eslint-disable react/prop-types */
import { Box, Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { type FC, useMemo } from 'react';
import { type DurationsAndExtreme } from '@modules/Investorpro/modules/PortfolioPage/entities';
import { ViewLoader } from '@modules/Investorpro/modules/PortfolioPage/shared/Layout';

import { Table } from '../../../../shared/Table';

const columnHelper = createColumnHelper<DurationsAndExtreme>();

type Props = {
    data: DurationsAndExtreme[];
    isPending?: boolean;
};

export const DecreasesDurationTable: FC<Props> = ({ data, isPending }) => {
    const columns = useMemo(
        () => [
            columnHelper.accessor('depth', {
                enableSorting: false,
                id: 'depth',
                meta: { headerCellStyles: { alignItems: 'end' } },
                header: 'Глубина',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'start', color: '#E42821' }}>
                        {props.getValue() ?? ''}
                        {'%'}
                    </Text>
                ),
            }),
            columnHelper.accessor('startDate', {
                enableSorting: false,
                id: 'startDate',
                meta: { headerCellStyles: { justifyContent: 'flex-end', whiteSpace: 'pre-wrap' } },
                header: 'Дата\nначала',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'end' }}>
                        {dayjs(props.getValue()).format('DD.MM.YYYY') ?? ''}
                    </Text>
                ),
            }),
            columnHelper.accessor('minimumDate', {
                enableSorting: false,
                id: 'minimumDate',
                meta: { headerCellStyles: { justifyContent: 'flex-end', whiteSpace: 'pre-wrap' } },
                header: 'Дата\nминимума',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'end' }}>
                        {dayjs(props.getValue()).format('DD.MM.YYYY') ?? ''}
                    </Text>
                ),
            }),
            columnHelper.accessor('endDate', {
                enableSorting: false,
                id: 'endDate',
                meta: { headerCellStyles: { justifyContent: 'flex-end', whiteSpace: 'pre-wrap' } },
                header: 'Дата\nокончания',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'end' }}>
                        {dayjs(props.getValue()).format('DD.MM.YYYY') ?? ''}
                    </Text>
                ),
            }),
            columnHelper.accessor('decreaseDuration', {
                enableSorting: false,
                id: 'decreaseDuration',
                meta: {
                    headerCellStyles: { justifyContent: 'flex-end', whiteSpace: 'pre-wrap', paddingRight: '18px' },
                },
                header: 'Длительность\nснижения',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'end' }}>
                        {props.getValue() ?? ''}
                    </Text>
                ),
            }),
            columnHelper.accessor('recoveryDuration', {
                enableSorting: false,
                id: 'recoveryDuration',
                meta: { headerCellStyles: { justifyContent: 'flex-end', whiteSpace: 'pre-wrap' } },
                header: 'Длительность\nвосстановления',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'end' }}>
                        {props.getValue() ?? ''}
                    </Text>
                ),
            }),
        ],
        [],
    );

    if (isPending) {
        return <ViewLoader />;
    }

    return (
        <Box w="100%">
            <Table
columns={columns} data={data} striped={true}
highlightOnHover={true} />
        </Box>
    );
};
