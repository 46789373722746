import { type PortfolioDynamicsPerformance } from '@modules/Investorpro/modules/PortfolioPage/entities';
import { type CartesianViewBox } from 'recharts/types/util/types';

export const renderCustomReferenceLabel = (
    props: { viewBox: CartesianViewBox },
    color: string,
    item: PortfolioDynamicsPerformance,
) => {
    const {
        viewBox: { width = 0, x = 0, y = 0 },
    } = props;

    return (
        <g>
            <foreignObject
x={width + x + 4} y={y - 12} width="100"
color="#fff" height={30}>
                <div
                    style={{
                        width: 'auto',
                        color: '#fff',
                        backgroundColor: color,
                        borderRadius: 4,
                        fontSize: 11,
                        display: 'inline-block',
                        textAlign: 'center',
                        padding: '0 8px',
                    }}
                >
                    {item.performance.at(-1)?.value}
                    {'%'}
                </div>
            </foreignObject>
        </g>
    );
};
