/* eslint-disable no-nested-ternary */
import { ActionIcon, Group, Loader, Text } from '@mantine/core';
import { type FC } from 'react';
import { type NewReportItem } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { DocumentSearch, Edit, ViewList } from '../../../shared/Icon';
import { type LoadedStatus } from './ReportLayout';

type Props = {
    moveToOpposite: (item: NewReportItem, inReport: boolean) => void;
    inReport: boolean;
    item: NewReportItem;
    onEditClick: (item: NewReportItem) => void;
    onShowClick: (item: NewReportItem) => void;
    componentDataLoadedStatus: LoadedStatus;
};

export const ReportItem: FC<Props> = ({
    item,
    inReport,
    moveToOpposite,
    onEditClick,
    componentDataLoadedStatus,
    onShowClick,
}) => (
    <Group
        justify="space-between"
        align="center"
        p="18px 16px 18px 18px"
        style={{
            userSelect: 'none',
            overflow: 'hidden',
            position: 'relative',
            cursor: 'move',
            border: '1px solid #D8E1EB',
            borderRadius: '6px',
        }}
    >
        <ActionIcon
            className="notDraggable"
            onClick={() => moveToOpposite(item, inReport)}
            size={25}
            style={{
                backgroundColor: inReport ? '#B4F7B7' : '#F7B4B4',
                transform: 'rotate(40deg)',
                borderRadius: 0,
                borderBottom: '1px solid #D8E1EB',
            }}
            pos="absolute"
            right={-12}
            top={-14}
        />

        <Group gap={16}>
            <ViewList color="#2F9CEB" height={24} width={24} />
            <Text
ff="Inter" fz={14} fw={400}
lh="16px">
                {item.title}
            </Text>
        </Group>

        {componentDataLoadedStatus === 'error'
? (
            <Text fz={12} c="red">
                {'Ошибка получения данных'}
            </Text>
        )
: componentDataLoadedStatus === 'pending'
? (
            <Loader size="xs" />
        )
: (
            <Group gap={14}>
                <ActionIcon className="notDraggable" onClick={() => onEditClick(item)} size={24}>
                    <Edit color="#2F9CEB" height={24} width={24} />
                </ActionIcon>

                <ActionIcon className="notDraggable" onClick={() => onShowClick(item)} size={24}>
                    <DocumentSearch color="#2F9CEB" height={24} width={24} />
                </ActionIcon>
            </Group>
        )}
    </Group>
);
