import { Button, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { Link } from 'react-router-dom';

import { Plus } from '../../shared/Icon';

export const PortfolioListEmptyStub: FC = () => (
    <Stack
pos="absolute" top={0} bottom={0}
align="center" justify="center" gap={0}>
        <Text fz={24} mb={18} lh="16px">
            {'Создайте свой первый портфель'}
        </Text>

        <Text
mb={36} fw={300} lh="24px"
style={{ whiteSpace: 'pre-wrap' }}>
            {
                'У вас пока нет сохраненного портфеля. Создайте первый\
\n инвестиционный портфель и следите за его аналитикой.'
            }
        </Text>
        <Button
            component={Link}
            to="create"
            variant="primary"
            w={226}
            size="large"
            leftSection={<Plus width={24} height={24} color="#FFFFFF" />}
        >
            {'Создать портфель'}
        </Button>
    </Stack>
);
