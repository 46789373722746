import { type PortfolioResponseData } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const deletePortfolio: ApiFactory<
    ApiEndpoint<PortfolioResponseData, void, { id: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.delete<PortfolioResponseData>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/portfolio/${params.id}`,
        { signal },
    );

    return data;
};
