import { Box, Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { ReactComponent as GeoMap } from '@shared/images/investorPro/geo-map.svg';

import { ChartLegend } from '../../../shared/ChartLegend';
import { ViewError, ViewLoader } from '../../../shared/Layout';
import { useGetGeoDiversificationQuery } from './useGetGeoDiversificationQuery';

type Props = {
    id: string;
};

export const GeoDiversification: FC<Props> = ({ id }) => {
    const geoDiversificationQuery = useGetGeoDiversificationQuery(id);

    if (geoDiversificationQuery.isError) {
        return (
            <Stack>
                <Text fz={19} fw={400}>
                    {'Географическая диверсификация'}
                </Text>
                <ViewError
                    error={geoDiversificationQuery.error}
                    subtitle="Ошибка получения данных по географической диверсификации"
                />
            </Stack>
        );
    }

    if (geoDiversificationQuery.isPending) {
        return (
            <Box>
                <Text fz={19} fw={400}>
                    {'Географическая диверсификация'}
                </Text>
                <ViewLoader />
            </Box>
        );
    }

    return (
        <Stack gap={0}>
            <Text fz={19} fw={400}>
                {'Географическая диверсификация'}
            </Text>
            <Group gap={60}>
                <Box w={727} h={428}>
                    <GeoMap />
                </Box>
                <Stack w={266}>
                    <ChartLegend data={geoDiversificationQuery.data} />
                </Stack>
            </Group>
        </Stack>
    );
};
