import { Text } from '@mantine/core';
import { type FC, Fragment } from 'react';

import { ViewError, ViewLoader } from '../../../../shared/Layout';
import { Table } from '../../../../shared/Table';
import { useConfigureColumns } from './useConfigureColumns';
import { useGetTopFiveDividendsQuery } from './useGetTopFiveDividendsQuery';

type Props = {
    portfolioId: string;
};

export const TopFiveDividends: FC<Props> = ({ portfolioId }) => {
    const columns = useConfigureColumns();
    const getTopFiveDividendsQuery = useGetTopFiveDividendsQuery(portfolioId);

    if (getTopFiveDividendsQuery.isError) {
        return (
            <Fragment>
                <Text fz={19} fw={400}>
                    {'ТОП 5 по доходности от дивидендов и купонов'}
                </Text>
                <ViewError
                    error={getTopFiveDividendsQuery.error}
                    subtitle="Ошибка получения данных ТОП 5 по доходности от дивидендов и купонов"
                />
            </Fragment>
        );
    }

    if (getTopFiveDividendsQuery.isPending) {
        return (
            <Fragment>
                <Text fz={19} lh="22px" fw={400}>
                    {'ТОП 5 по доходности от дивидендов и купонов'}
                </Text>
                <ViewLoader />
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Text fz={19} lh="22px" fw={400}>
                {'ТОП 5 по доходности от дивидендов и купонов'}
            </Text>
            <Table
                columns={columns}
                data={getTopFiveDividendsQuery.data ?? []}
                striped={true}
                highlightOnHover={true}
            />
        </Fragment>
    );
};
