import { type SimulationData } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getSimulationResult: ApiFactory<
    ApiEndpoint<SimulationData, void, {
        portfolioId: string;
        scenarioUuid: string;
    }>
> = (client) => async ({ signal, params: { portfolioId, scenarioUuid } }) => {
    const url = '/moex-datashop-investor-pro-portfolios-service' +
        `/api/v1/stress-test/${portfolioId}/simulation-result/${scenarioUuid}`;

    const { data } = await client.get<SimulationData>(url, { signal });

    return data;
};
