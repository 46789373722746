import { type TickerCalculatorData } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const calculateTickerValues: ApiFactory<ApiEndpoint<
    TickerCalculatorData[],
    Array<Omit<TickerCalculatorData, 'value'>>,
    { portfolioValue: number }
>> = (client) => async ({ signal, data, params }) => {
    const response = await client.post<Array<{ ticker: string; percent: number; value: number }>>(
        '/moex-datashop-investor-pro-portfolios-service/api/v1/calculator/ticker/value',
        data,
        { signal, params },
    );

    return response.data;
};
