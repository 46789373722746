import { type RequestScreenerType, type ScreenerType } from '@modules/Investorpro/modules/ScreenersPage/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    instrumentsGetRequest,
    screenerColumnsGetRequest,
    screenerCopyPostRequest,
    screenerCreateRequest,
    screenerDeleteRequest,
    screenerFiltersGetRequest,
    screenersListGetRequest,
    screenerUpdateRequest,
} from '@modules/Investorpro/shared/services';
import { type PageableType } from '@libs/types';

export const getScreenersThunk = createAsyncThunk('investpro/getScreeners', async (_, { rejectWithValue }) => {
    try {
        const { data } = await screenersListGetRequest();

        return data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const updateScreenerThunk = createAsyncThunk(
    'investpro/updateScreener',
    async (screener: Partial<RequestScreenerType>, { rejectWithValue }) => {
        if (!screener) {
            return;
        }
        try {
            return await screenerUpdateRequest(screener);
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const copyScreenerThunk = createAsyncThunk(
    'investpro/copyScreener',
    async (requestBody: { screenerId: ScreenerType['id']; name: ScreenerType['name'] }, { rejectWithValue }) => {
        if (!requestBody) {
            return;
        }
        try {
            const { data } = await screenerCopyPostRequest(requestBody);

            return data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const deleteScreenerThunk = createAsyncThunk(
    'investpro/deleteScreener',
    async (screenerId: ScreenerType['id'], { rejectWithValue }) => {
        if (!screenerId) {
            return;
        }
        try {
            await screenerDeleteRequest(screenerId);

            return screenerId;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const getInstrumentsThunk = createAsyncThunk(
    'investpro/getInstruments',
    async (
        {
            screenerId,
            body,
        }: {
            screenerId: ScreenerType['id'];
            body: { instrumentTypeId: ScreenerType['instrumentTypeId']; pageable?: Partial<PageableType> };
        },
        { rejectWithValue },
    ) => {
        if (!screenerId) {
            return;
        }
        try {
            const { data } = await instrumentsGetRequest(screenerId, body);

            return data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const getScreenerColumnsThunk = createAsyncThunk('investpro/getColumns', async (_, { rejectWithValue }) => {
    try {
        const { data } = await screenerColumnsGetRequest();

        return data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const getScreenerFiltersThunk = createAsyncThunk(
    'investpro/getScreenerFilters',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await screenerFiltersGetRequest();

            return data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const createScreenerThunk = createAsyncThunk(
    'investpro/createScreener',
    async (screener: Partial<RequestScreenerType>, { rejectWithValue }) => {
        if (!screener) {
            return;
        }
        try {
            return await screenerCreateRequest(screener);
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
