import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../../api';

export const useGetReportUploadURLQuery = (name: string, enabled: boolean) => {
    const api = useApi();

    return useQuery({
        queryKey: ['report', 'upload-url', name],
        queryFn: async () => await api.portfolio.report.getReportUploadURL({ params: { name } }),
        enabled: Boolean(name) && enabled,
    });
};
