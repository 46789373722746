import { addDays, addMonths, addYears, startOfDay, startOfYear } from 'date-fns';

export const getOneDayPeriod = (date: Date) => {
    return startOfDay(date)
};

export const getFiveDaysPeriod = (date: Date) => {
    return addDays(startOfDay(date), -5);
};

export const getOneMonthPeriod = (date: Date) => {
    return addMonths(startOfDay(date), -1);
};

export const getOneWeekPeriod = (date: Date) => {
    return addDays(startOfDay(date), -7);
};

export const getThreeMonthsPeriod = (date: Date) => {
    return addMonths(startOfDay(date), -3);
};

export const getSixMonthsPeriod = (date: Date) => {
    return addMonths(startOfDay(date), -6);
};

export const getOneYearPeriod = (date: Date) => {
    return addYears(startOfDay(date), -1);
};

export const getThreeYearsPeriod = (date: Date) => {
    return addYears(startOfDay(date), -3);
};

export const getFiveYearsPeriod = (date: Date) => {
    return addYears(startOfDay(date), -5);
};

export const getStartYearDate = () => {
    return startOfYear(new Date());
}

export const getTenYearsPeriod = (date: Date) => {
    return addYears(startOfDay(date), -10);
}

export const getStartOfMOEXDate = () => {
    return new Date(1990, 7, 1);
}
