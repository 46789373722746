export const NONE_SYMBOL = '–';

export const SCROLLBAR_WIDTH_CSS_VARIABLE = '--scrollbar-width';

export const DEFAULT_NUMBER_DIVIDER = ',';

export const ROOT_PATH = '/';

export const DEFAULT_AUTHORIZED_ROOT_PAGE = '/portfolio';

export const DEFAULT_NOT_AUTHORIZED_ROOT_PAGE = '/portfolio';
