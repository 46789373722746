import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { HelpLineWrapper, ModalWindow } from '@libs/components';
import { deleteScreenerThunk, type RootState, updateScreenerThunk, useAppDispatch, useAppSelector } from '@store/store';
import { CopyScreenerModal } from '@modules/Investorpro/modules/ScreenersPage/components/ScreenerFooter/components/CopyScreenerModal';
import { NewScreenerModal } from '@modules/Investorpro/modules/ScreenersPage/components/NewScreenerModal';
import { MAX_INPUT_VALUE_LENGTH } from '@modules/Investorpro/modules/ScreenersPage/constants';
import { type PageableType } from '@libs/types';

import { ReactComponent as RenameIcon } from '../../../../shared/images/svg/Rename.svg';
import { ReactComponent as CopyIcon } from '../../../../shared/images/svg/Copy.svg';
import { ReactComponent as BinIcon } from '../../../../shared/images/svg/Bin.svg';
import { ReactComponent as FilterIcon } from '../../../../shared/images/svg/Filter.svg';
import styles from './styles.module.scss';
import { type ScreenerType } from '../../types';

type ScreenerFooterProps = {
    screenerId: number;
    onDeleteScreener: () => void;
    onUpdateCurrentScreener: (
        screenerId: ScreenerType['id'],
        body: { instrumentTypeId: ScreenerType['instrumentTypeId']; pageable?: Partial<PageableType> },
    ) => void;
    onCreateScreener?: (screenerId?: ScreenerType['id']) => void;
};

export const ScreenerFooter = ({
    screenerId,
    onDeleteScreener,
    onUpdateCurrentScreener,
    onCreateScreener,
}: ScreenerFooterProps) => {
    const dispatch = useAppDispatch();
    const currentScreener: ScreenerType = useAppSelector(
        (root: RootState) => root.screeners.screeners.find(({ id }) => id === screenerId) ?? ({} as ScreenerType),
    );
    const [screenerTitle, setScreenerTitle] = useState(currentScreener.name ?? '');

    const isLoading: boolean = useAppSelector((state) => state.screeners.isLoading);

    // region RenameDialog
    const [isRenameDialogVisible, setIsRenameDialogVisible] = useState(false);

    const handleHideRenameDialog = () => {
        setIsRenameDialogVisible(false);
    };

    const handleInitRenameScreener = () => {
        setIsRenameDialogVisible(true);
        setScreenerTitle(currentScreener?.name ?? '');
    };

    const handleRenameScreener = () => {
        if (!currentScreener) {
            console.error('Screener not found');

            return;
        }

        const updatedScreener: ScreenerType = {
            ...currentScreener,
            name: screenerTitle,
        };

        dispatch(updateScreenerThunk(updatedScreener));
        setIsRenameDialogVisible(false);
    };

    // endregion RenameDialog

    // region CopyDialog
    const [isCopyDialogVisible, setIsCopyDialogVisible] = useState(false);

    const handleHideCopyDialog = () => {
        setIsCopyDialogVisible(false);
    };

    const handleInitCopyScreener = () => {
        setIsCopyDialogVisible(true);
        setScreenerTitle(currentScreener?.name ?? '');
    };
    // endregion CopyDialog

    // region DeleteDialog
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

    const handleHideDeleteDialog = () => setIsDeleteDialogVisible(false);

    const handleInitDeleteScreener = () => {
        setIsDeleteDialogVisible(true);
    };

    const handleDeleteScreener = useCallback(async () => {
        await dispatch(deleteScreenerThunk(screenerId));
        onDeleteScreener();
        setIsDeleteDialogVisible(false);
    }, [screenerId, dispatch]);
    // endregion DeleteDialog

    // region EditDialog
    const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
    // endregion EditDialog

    return (
        <div className={classNames(styles.wrapper, 'flex', 'justify-content-between', 'align-items-center')}>
            <div className={'flex'}>
                <HelpLineWrapper message="helpline.screeners.renameScreener">
                    <Button
                        severity="secondary"
                        outlined
                        className={classNames(styles.actionButton, 'flex', 'justify-content-center')}
                        onClick={handleInitRenameScreener}
                    >
                        <RenameIcon />
                    </Button>
                </HelpLineWrapper>
                <HelpLineWrapper message="helpline.screeners.copyScreener">
                    <Button
                        severity="secondary"
                        outlined
                        className={classNames(styles.actionButton, 'flex', 'justify-content-center')}
                        onClick={handleInitCopyScreener}
                    >
                        <CopyIcon />
                    </Button>
                </HelpLineWrapper>
                <HelpLineWrapper message="helpline.screeners.deleteScreener">
                    <Button
                        severity="secondary"
                        outlined
                        className={classNames(styles.actionButton, 'flex', 'justify-content-center')}
                        onClick={handleInitDeleteScreener}
                    >
                        <BinIcon />
                    </Button>
                </HelpLineWrapper>
                <HelpLineWrapper message="helpline.screeners.filterScreener">
                    <Button size="small" className={styles.filterButton} onClick={() => setIsOpenEditModal(true)}>
                        <FilterIcon />
                        <span>Фильтр</span>
                    </Button>
                </HelpLineWrapper>
            </div>
            <ModalWindow
                closeModal={handleHideRenameDialog}
                isVisible={isRenameDialogVisible}
                className={styles.dialogContainer}
            >
                <div className={styles.dialog}>
                    <div className={styles.dialogWrapper}>
                        <div className={styles.dialogHeader}>Переименовать скринер</div>
                        <InputText
                            value={screenerTitle}
                            className={styles.dialogInput}
                            onChange={(e) => setScreenerTitle(e.target.value)}
                            maxLength={MAX_INPUT_VALUE_LENGTH}
                        />
                        <div className={classNames('flex')}>
                            <Button
                                className={styles.dialogActionButton}
                                severity="secondary"
                                onClick={handleHideRenameDialog}
                            >
                                Отменить
                            </Button>
                            <Button
                                className={classNames(styles.dialogActionButton, styles.dialogActionButtonSuccess)}
                                onClick={handleRenameScreener}
                                disabled={!screenerTitle.trim().length || isLoading}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalWindow>

            <CopyScreenerModal
                isOpen={isCopyDialogVisible}
                onCloseModal={handleHideCopyDialog}
                onCreateScreener={onCreateScreener}
                screenerName={screenerTitle}
                screenerId={screenerId}
            />

            <ModalWindow
                closeModal={handleHideDeleteDialog}
                isVisible={isDeleteDialogVisible}
                className={styles.dialogContainer}
            >
                <div className={styles.dialog}>
                    <div className={styles.dialogWrapper}>
                        <div className={styles.dialogHeader}>Удалить скринер</div>
                        <div className={styles.dialogContent}>
                            Вы уверены, что хотите удалить скринер «{currentScreener.name}» ? После удаления
                            восстановить скринер не получится.
                        </div>
                        <div className={classNames('flex')}>
                            <Button
                                className={styles.dialogActionButton}
                                severity="secondary"
                                onClick={handleHideDeleteDialog}
                            >
                                Отменить
                            </Button>
                            <Button
                                className={classNames(styles.dialogActionButton, styles.dialogActionButtonSuccess)}
                                onClick={handleDeleteScreener}
                                disabled={isLoading}
                            >
                                Удалить
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalWindow>
            {isOpenEditModal && (
                <NewScreenerModal
                    isVisible={isOpenEditModal}
                    closeModal={() => setIsOpenEditModal(false)}
                    onUpdateCurrentScreener={onUpdateCurrentScreener}
                    screenerId={screenerId}
                />
            )}
        </div>
    );
};
