import {
    type PortfolioDynamicsChartData,
    type PortfolioDynamicsResponseData,
} from '@modules/Investorpro/modules/PortfolioPage/entities';

export const chartDataNormalize = (responseData: PortfolioDynamicsResponseData) => {
    const { performances, events } = responseData;
    const dataMap: Record<string, PortfolioDynamicsChartData> = {
    };

    performances
        .sort((a, b) => b.performance.length - a.performance.length)
        .forEach((performance) => {
            performance.performance.forEach(item => {
                if (!dataMap[item.date]) {
                    dataMap[item.date] = { date: item.date };
                }
                dataMap[item.date][performance.name] = item.value;
            });
        });

    events.forEach(event => {
        if (!dataMap[event.date]) {
            dataMap[event.date] = { date: event.date };
        }
        dataMap[event.date].events = {
            description: event.description,
            type: event.type,
        };
    });

    const chartData = Object.values(dataMap);

    return chartData;
};
