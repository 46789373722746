import classNames from 'classnames';
import { Button } from 'primereact/button';
import { PlusIcon } from 'primereact/icons/plus';
import { useModal } from '@libs/utils';

import styles from './styles.module.scss';
import { CreateAlertModal } from '../overlay/CreateAlertModal';

export const EmptyData = () => {
    const { isOpen, openModal, closeModal } = useModal();

    return (
        <div
            className={classNames(
                styles.emptyData,
                'flex',
                'flex-column',
                'align-items-center',
                'justify-content-center',
            )}
        >
            <div className="flex flex-column align-items-center">
                <p>Создайте уведомления, чтобы вовремя получать информацию о значимых ценовых событиях</p>
                <div className={classNames('flex', 'flex-column', 'align-items-center')}>
                    <Button
                        className={classNames(styles.btn, 'flex', 'justify-content-center', 'align-items-center')}
                        icon={<PlusIcon />}
                        label={'Создать уведомление'}
                        onClick={openModal}
                    />
                </div>
            </div>
            <CreateAlertModal isOpen={isOpen} closeModal={closeModal} />
        </div>
    );
};
