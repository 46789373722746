import { type SecurityMarketIndexesData } from '@libs/types/bondsmarket.type';
import { PositiveNegativeValue } from '@modules/Investorpro/shared/components/PositiveNegativeValue';
import { roundInstrumentPrice } from '@modules/Investorpro/modules/BondStockPage/shared/utils';

import { type TableWithCheckBoxColumn } from '../TableWithCheckBox';

export const BONDS_INDEXES_COLUMNS: Array<TableWithCheckBoxColumn<SecurityMarketIndexesData>> = [
    {
        id: 'name',
        field: 'name',
        align: 'left',
        label: 'Название',
        width: '29.91%',
    },
    {
        id: 'price',
        field: 'price',
        align: 'right',
        label: 'Цена',
        width: '20.52%',
        type: 'price',
    },
    {
        id: 'changeAbsolute',
        align: 'right',
        label: 'Изменение',
        width: '29.04%',
        render: (obj) => obj.prevTradeDayPrice !== null && (
                <PositiveNegativeValue positivePrefix="+" value={roundInstrumentPrice(obj.changeAbsolute)} />
            ),
    },
    {
        id: 'changeRelative',
        align: 'right',
        label: 'Изм,. %',
        width: '19.48%',
        render: (obj) => obj.prevTradeDayPrice !== null && (
                <PositiveNegativeValue positivePrefix="+" value={obj.changeRelative} measure="%" />
            ),
    },
];
