import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getReportSettings: ApiFactory<
    ApiEndpoint<{ options: string }, void, { id: string }>
> = (client) => async ({ signal, params: { id } }) => {
    const { data } = await client.get<{ options: string }>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/reports/options?portfolioId=${id}`,
        { signal },
    );

    return data;
};
