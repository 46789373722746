import { useMutation } from '@tanstack/react-query';

import { useApi } from '../../../api';

export const useUpdateReportSettingsMutation = () => {
    const api = useApi();

    return useMutation({
        mutationKey: ['report', 'settings', 'upload'],
        mutationFn: async (vars: { options: string; portfolioId: string }) => (
            await api.portfolio.report.saveReportSettings({
                data: { options: vars.options },
                params: { id: vars.portfolioId },
            })
        ),
    });
};
