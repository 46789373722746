import {
    createAlert,
    deleteAlert,
    getAlertsList,
    updateAlert,
} from '@modules/Investorpro/shared/components/AlertsSidebar/services/alerts.service';
import {
    Alert,
    type CreateAlertRequestBody,
    type UpdateAlertRequestBody,
} from '@modules/Investorpro/shared/components/AlertsSidebar/services/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAlertsListThunk = createAsyncThunk('investorpro/getAlertsList', async (_, { rejectWithValue }) => {
    try {
        const response = await getAlertsList();
        const data = response.data.alerts;

        const readFulfilledAlerts: Alert[] = data
            .filter(({ triggerDate, isRead }) => triggerDate && isRead)
            .sort((a, b) => new Date(b.triggerDate ?? 0).getTime() - new Date(a.triggerDate ?? 0).getTime());

        const notReadFulfilledAlerts: Alert[] = data
            .filter(({ triggerDate, isRead }) => triggerDate && !isRead)
            .sort((a, b) => new Date(b.triggerDate ?? 0).getTime() - new Date(a.triggerDate ?? 0).getTime());

        return {
            fulfilled: [...notReadFulfilledAlerts, ...readFulfilledAlerts],
            pending: data.filter(({ triggerDate }) => !triggerDate),
        };
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const createAlertThunk = createAsyncThunk(
    'investorpro/createAlert',
    async (body: CreateAlertRequestBody, { rejectWithValue }) => {
        try {
            const { data } = await createAlert(body);

            return data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const updateAlertThunk = createAsyncThunk(
    'investorpro/updateAlert',
    async (body: UpdateAlertRequestBody, { rejectWithValue }) => {
        try {
            const { data } = await updateAlert(body);

            return data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const deleteAlertThunk = createAsyncThunk(
    'investorpro/deleteAlert',
    async (id: number, { rejectWithValue }) => {
            try {
                await deleteAlert(id);

                return id;
            } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const readAlertThunk = createAsyncThunk(
    'investorpro/readAlert',
    async (body: UpdateAlertRequestBody, { rejectWithValue }) => {
        try {
            await updateAlert({
                ...body,
                isRead: true,
            });
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
