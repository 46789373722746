import { Group, Stack } from '@mantine/core';
import { type FC } from 'react';
import { useParams } from 'react-router-dom';

import { type InferParams } from '../../../shared/router';
import { CorrelationToolsTables } from './CorrelationToolsTable';
import { GeoDiversification } from './GeoDiversification';
import { IntraPortfolioCorrelation } from './IntraPortfolioCorrelation';
import { SectoralDiversification } from './SectoralDiversification';

export const Diversification: FC = () => {
    const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;

    return (
        <Group gap={60} justify="space-between" align="flex-start">
            <Stack gap={66} w={1054}>
                <GeoDiversification id={portfolioId} />
                <SectoralDiversification id={portfolioId} />
            </Stack>
            <Stack gap={34} flex={1}>
                <IntraPortfolioCorrelation id={portfolioId} />
                <CorrelationToolsTables id={portfolioId} />
            </Stack>
        </Group>
    );
};
