import { createAsyncThunk } from '@reduxjs/toolkit';
import { checkPermissions } from '@libs/services/permissions.service';

export const checkPermissionsThunk = createAsyncThunk(
    'permissions/checkPermissionsThunk',
    async (productGuid: string, { rejectWithValue }) => {
        try {
            const { data } = await checkPermissions(productGuid);

            return data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
