import { createBrowserRouter } from 'react-router-dom';
import { ErrorPage } from '@modules/ErrorPage';
import { TechnicPage } from '@modules/TechnicPage';
import { InvestorProLayout } from '@modules/Investorpro';
import { ScreenersPage } from '@modules/Investorpro/modules/ScreenersPage';
import { StockPage } from '@modules/Investorpro/modules/BondStockPage/StockPage';
import { BondPage } from '@modules/Investorpro/modules/BondStockPage/BondPage';
import { Layout } from '@libs/components';
import { BondsMarketPage } from '@modules/Investorpro/modules/MarketPages/BondsMarketPage';
import { StockMarketPage } from '@modules/Investorpro/modules/MarketPages/StockMarketPage';
import { AnalyticsPage } from '@modules/Investorpro/modules/AnaliticsPage';
import { PortfolioPage } from '@modules/Investorpro/modules/PortfolioPage';
import { TotalMarketPage } from '@modules/Investorpro/modules/MarketPages/TotalMarketPage';
import { PortfolioLayout } from '@modules/Investorpro/modules/PortfolioPage/components/PortfolioLayout';
import { ProtectedRoute } from '@modules/Investorpro/shared/components/ProtectedRoute';
import { Permission } from '@libs/utils/hooks/usePermission';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                element: <InvestorProLayout />,
                children: [
                    {
                        path: '',
                        element: <ProtectedRoute element={<PortfolioPage />} permission={Permission.PORTFOLIO} />,
                    },
                    {
                        path: 'portfolio',
                        element: <ProtectedRoute element={<PortfolioPage />} permission={Permission.PORTFOLIO} />,
                        children: [
                            {
                                path: ':portfolioId/*',
                                element: (
                                    <ProtectedRoute element={<PortfolioLayout />} permission={Permission.PORTFOLIO} />
                                ),
                            },
                        ],
                    },
                    {
                        path: 'screeners',
                        element: <ProtectedRoute element={<ScreenersPage />} permission={Permission.SCREENER} />,
                    },
                    {
                        path: 'analytics',
                        element: <ProtectedRoute element={<AnalyticsPage />} permission={Permission.ANALYTIC} />,
                    },
                    {
                        path: 'analytics/stocks-market',
                        element: <ProtectedRoute element={<StockMarketPage />} permission={Permission.ANALYTIC} />,
                    },
                    {
                        path: 'analytics/bonds-market',
                        element: <ProtectedRoute element={<BondsMarketPage />} permission={Permission.ANALYTIC} />,
                    },
                    {
                        path: 'analytics/stocks-market',
                        element: <ProtectedRoute element={<TechnicPage />} permission={Permission.ANALYTIC} />,
                    },
                    {
                        path: 'analytics/stocks/:ticker',
                        element: <ProtectedRoute element={<StockPage />} permission={Permission.SEARCH} />,
                    },
                    {
                        path: 'analytics/bonds/:secId',
                        element: <ProtectedRoute element={<BondPage />} permission={Permission.SEARCH} />,
                    },
                    {
                        path: 'analytics/market',
                        element: <ProtectedRoute element={<TotalMarketPage />} permission={Permission.ANALYTIC} />,
                    },
                    {
                        path: 'tech-page',
                        element: <TechnicPage />,
                    },
                ],
            },
        ],
    },
]);
