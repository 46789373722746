import { Dropdown as PrimeDropdown, type DropdownProps } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

import styles from './styles.module.scss';

export const Dropdown = (props: DropdownProps) => {
    return (
        <PrimeDropdown
            {...props}
            pt={{
                input: { className: classNames(styles.label, 'flex', 'align-items-center') },
                panel: { className: classNames(styles.panel, 'flex', 'flex-column') },
            }}
        />
    );
};
