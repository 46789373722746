import { type FC } from 'react';

export const CommunicationsIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path
                d="M11.2254 7.31659C13.1614 5.59366 15.7107 4.5 18.5 4.5C24.5401 4.5 29.5 9.45986 29.5
                15.5C29.5 16.7845 29.2321 18.018 28.8241 19.1642M25.7746 23.6834C23.8386
                25.4063 21.2893 26.5 18.5 26.5C12.4599 26.5 7.5 21.5401 7.5 15.5C7.5 14.2155
                7.76788 12.982 8.17588 11.8358M25.3465 15.5C25.3465 19.2812 22.2812 22.3465
                18.5 22.3465M25.3465 15.5C25.3465 11.7188 22.2812 8.65353 18.5 8.65353M25.3465
                15.5H11.6535M18.5 22.3465C14.7188 22.3465 11.6535 19.2812 11.6535 15.5M18.5
                22.3465V8.65353M18.5 22.3465C20.3906 22.3465 21.9232 19.2812 21.9232 15.5C21.9232 11.7188
                20.3906 8.65353 18.5 8.65353M18.5 22.3465C16.6094 22.3465 15.0768 19.2812 15.0768
                15.5C15.0768 11.7188 16.6094 8.65353 18.5 8.65353M11.6535 15.5C11.6535 11.7188 14.7188
                8.65353 18.5 8.65353M11.6535 8.3112C11.6535 9.06742 11.0404 9.6805 10.2842 9.6805C9.52802
                9.6805 8.91494 9.06742 8.91494 8.3112C8.91494 7.55499 9.52802 6.94191 10.2842
                6.94191C11.0404 6.94191 11.6535 7.55499 11.6535 8.3112ZM28.0851 22.6888C28.0851
                23.445 27.472 24.0581 26.7158 24.0581C25.9596 24.0581 25.3465 23.445 25.3465
                22.6888C25.3465 21.9326 25.9596 21.3195 26.7158 21.3195C27.472
                21.3195 28.0851 21.9326 28.0851 22.6888Z"
                stroke="#636E80"
                strokeWidth="1.2"
                strokeMiterlimit="10"
            />
        </g>
    </svg>
);
