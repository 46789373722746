import classNames from 'classnames';
import { HelpLineWrapper } from '@libs/components';
import { useRef, useState } from 'react';
import { useLastUpdate } from '@modules/Investorpro/shared/utils/useLastUpdate';
import { BreadCrumb } from '@modules/Investorpro/shared/components/BreadCrumb';
import { type SecurityIndex } from '@libs/types';
import { changeColumnsWidth } from '@modules/Investorpro/shared/utils/table.utils';

import { useSelectSecurity } from '../../../shared/utils/useSelectSecurity';
import styles from './styles.module.scss';
import { SecurityPriceChangesChart } from '../../../shared/components/ChangesChart';
import { SecurityIndexes } from '../../../shared/components/SecurityIndexes';
import { SECURITY_DATASET_COLORS } from '../../../shared/constants';
import { ALL_INDEXES, COUNT_OF_SECURITIES_BLOCK as COUNT_OF_INDEXES_BLOCKS } from './constants';
import { Header } from './components/Header';
import { TableWithCheckBox } from '../../../shared/components/TableWithCheckBox';
import { BONDS_INDEXES_COLUMNS } from '../../../shared/components/SecurityIndexes/columns';

export const TotalMarketDashboard = () => {
    const [isChartLoading, setIsChartLoading] = useState(false);
    const { selectSecurity, selectedSecurities, isMaxSelected } = useSelectSecurity(SECURITY_DATASET_COLORS);
    const { setLastUpdate, updateDateLabel } = useLastUpdate('Oбновлено', 'dd.MM.yyyy в HH:mm');
    const loadedIndexes = useRef(0);

    const handleLoadData = () => {
        loadedIndexes.current++;

        if (loadedIndexes.current === COUNT_OF_INDEXES_BLOCKS) {
            setLastUpdate();
        }
    };

    const navigateToIndexPage = (index: SecurityIndex) => {
        // TODO: change when get path to navigation from analytics
    };

    const tablesColumnsWidth = ['170px', '144px', '112px', '112px'];

    return (
        <div className={classNames(styles.totalMarketDashboardWrapper, 'flex', 'justify-content-between')}>
            <div className={classNames(styles.chartWrapper, 'w-full', 'flex', 'flex-column')}>
                <BreadCrumb className={styles.breadCrumb} />
                <div className="flex justify-content-between">
                    <h1>Дашборд всего рынка</h1>
                    <Header />
                </div>
                <SecurityPriceChangesChart
                    width={1118}
                    selectSecurity={selectSecurity}
                    selectedSecurities={selectedSecurities}
                    isLoading={isChartLoading}
                    setIsLoading={setIsChartLoading}
                />
            </div>
            <div className={classNames(styles.indexes_wrapper, 'flex', 'flex-column')}>
                <div className={classNames(styles.lastUpdate, 'flex', 'justify-content-end')}>{updateDateLabel}</div>
                <HelpLineWrapper message="helpline.marketDashboards.displayOnGraph">
                    <div className={classNames(styles.indexes_content, 'flex', 'flex-column')}>
                        <h2 className={styles.content_header}>{ALL_INDEXES[0].name}</h2>
                        <div>
                            <TableWithCheckBox
                                data={[]}
                                columns={changeColumnsWidth(BONDS_INDEXES_COLUMNS, tablesColumnsWidth)}
                                selected={[]}
                                handleSelect={selectSecurity}
                                isMaxSelected={false}
                                disabled={isChartLoading}
                            />
                        </div>
                        {ALL_INDEXES.map(({ name, indexes }, index) => (
                            <SecurityIndexes
                                title={index === 0 ? null : name}
                                key={name}
                                defaultActiveIndex={index === 0 ? 0 : undefined}
                                selectedIndexes={selectedSecurities}
                                indexes={indexes}
                                loadingKey={`loading-indexes-${index}`}
                                navigate={navigateToIndexPage}
                                columnsWidth={tablesColumnsWidth}
                                handleLoadData={handleLoadData}
                                handleSelectIndexes={selectSecurity}
                                className={classNames(
                                    index > 2 && styles.indexes_content__shortHeaderSymbols,
                                    index === 1 && styles.content_sectorIndexes,
                                    index === 0 && styles.content_stockIndexes,
                                    index === 3 && styles.content_currencies,
                                )}
                                isMaxSelected={isMaxSelected}
                                withoutTableHeader={true}
                                disabled={isChartLoading}
                            />
                        ))}
                    </div>
                </HelpLineWrapper>
            </div>
        </div>
    );
};
