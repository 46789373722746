import { PeriodId, IntervalType } from '@libs/types/instrument.type';
import {
    getOneDayPeriod,
    getFiveDaysPeriod,
    getOneMonthPeriod,
    getThreeMonthsPeriod,
    getSixMonthsPeriod,
    getOneYearPeriod,
    getStartYearDate,
    getFiveYearsPeriod,
    getTenYearsPeriod,
    getThreeYearsPeriod,
} from '@libs/utils';
import { round } from 'lodash';

import { getArrowPos } from '../../utils/fetch.utils';




export const PERIOD_OPTIONS = [
    {
        id: PeriodId.ONE_DAY,
        interval: IntervalType.ONE_MINUTE,
        arrowPos: ['0', '500'],
        label: {
            value: 1,
            measure: 'Д',
        },
        getStartDate: getOneDayPeriod,
    },
    {
        id: PeriodId.FIVE_DAYS,
        arrowPos: ['0', '500', '1000', '1500', '2000', '2500'],
        interval: IntervalType.ONE_MINUTE,
        label: {
            value: 5,
            measure: 'Д',
        },
        getStartDate: getFiveDaysPeriod,
    },
    {
        id: PeriodId.ONE_MONTH,
        interval: IntervalType.ONE_HOUR,
        arrowPos: ['0'],
        label: {
            value: 1,
            measure: 'М',
        },
        getStartDate: getOneMonthPeriod,
    },
    {
        id: PeriodId.THREE_MONTHS,
        interval: IntervalType.ONE_HOUR,
        arrowPos: ['0'],
        label: {
            value: 3,
            measure: 'М',
        },
        getStartDate: getThreeMonthsPeriod,
    },
    {
        id: PeriodId.SIX_MONTHS,
        arrowPos: ['0'],
        interval: IntervalType.ONE_HOUR,
        label: {
            value: 6,
            measure: 'М',
        },
        getStartDate: getSixMonthsPeriod,
    },
    {
        id: PeriodId.SNG,
        arrowPos: ['0'],
        interval: IntervalType.ONE_HOUR,
        label: {
            value: null,
            measure: 'СНГ',
        },
        getStartDate: getStartYearDate,
    },
    {
        id: PeriodId.ONE_YEAR,
        arrowPos: ['0'],
        interval: IntervalType.ONE_HOUR,
        label: {
            value: 1,
            measure: 'Г',
        },
        getStartDate: getOneYearPeriod,
    },
    {
        id: PeriodId.THREE_YEARS,
        arrowPos: ['0'],
        interval: IntervalType.ONE_DAY,
        label: {
            value: 3,
            measure: 'Г',
        },
        getStartDate: getThreeYearsPeriod,
    },
    {
        id: PeriodId.FIVE_YEARS,
        arrowPos: ['0'],
        interval: IntervalType.ONE_DAY,
        label: {
            value: 5,
            measure: 'Л',
        },
        getStartDate: getFiveYearsPeriod,
    },
    {
        id: PeriodId.TEN_YEARS,
        interval: IntervalType.ONE_DAY,
        arrowPos: getArrowPos(3600, IntervalType.ONE_DAY),
        getStartDate: getTenYearsPeriod,
        label: {
            value: 10,
            measure: 'Л',
        },
    },
]
