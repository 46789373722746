import { type TopFiveByProfitability } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getTopFiveDividends: ApiFactory<ApiEndpoint<
    TopFiveByProfitability[], void, { portfolioId: string }
>> = (client) => async ({ signal, params }) => {
    const url = '/moex-datashop-investor-pro-portfolios-service' +
        `/api/v1/asset-typology/${params.portfolioId}/most-yield-from-dividends-and-coupons`;

    const res = await client.get<TopFiveByProfitability[]>(url, { signal });

    return res.data;
};
