import { Box, Text } from '@mantine/core';
import { type FC } from 'react';

type Props = {
    performanceType: string;
};

export const CustomLegend: FC<Props> = ({ performanceType }) => (
    <Box
        style={{
            background: 'rgba(215, 230, 245, 0.20)',
            borderRadius: 6,
            padding: '8px 24px',
        }}
    >
        <Text
ff="Inter" fz={13} fw={500}
lh="20px" c="#8A96A6">
            {performanceType === 'STRESS_TEST_MODEL' ? 'Стресс-тест риск' : 'Риск'}
        </Text>
    </Box>
);
