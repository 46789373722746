import { useCallback, useState } from 'react';
import { AutoComplete, type AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { ReactComponent as SearchIcon } from '@shared/images/svg/Search.svg';
import classNames from 'classnames';
import { AngleRightIcon } from 'primereact/icons/angleright';
import { HighlightText } from '@libs/components';
import { objectToBase64String } from '@libs/utils';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css';

type SearchItem = {
    id: string;
    title: string;
    description: string;
};

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
};

const placeholderText = 'Поиск по Datashop';

const list: SearchItem[] = [
    {
        id: '0',
        title: 'Compience Pro',
        description:
            '... свою очередь, комплаенс-менеджмент (compliance management) – это инструментарий и набор процессов, применяемых в компании...',
    },
    {
        id: '1',
        title: 'Евразийский финансовый форум',
        description:
            'В рамках форума развивается онлайн-платформа для повышения компетенций женщин в сфере инвестиций, ...',
    },
];

export const Search = ({ isOpen, setIsOpen }: Props) => {
    const navigate = useNavigate();

    const [value, setValue] = useState<string>();
    const [filteredList, setFilteredList] = useState<SearchItem[]>([]);
    const [placeholder, setPlaceholder] = useState(placeholderText);

    const highlight = useCallback(
        (str: string) => {
            const style = { fontWeight: 700 };

            return HighlightText({ filter: value, str, style });
        },
        [value],
    );

    const search = (event: AutoCompleteCompleteEvent) => {
        setTimeout(() => {
            let _filteredList;

            if (!event.query.trim().length) {
                _filteredList = [...list];
            } else {
                _filteredList = [...list].filter((item) => {
                    return item.description.toLowerCase().includes(event.query.toLowerCase());
                });
            }

            setFilteredList(_filteredList);
        }, 250);
    };

    const onClick = () => {
        setIsOpen(!isOpen);
        setPlaceholder(placeholderText);
        setValue(undefined);
    };

    const goToSearchPage = () => {
        navigate(`/search-page?params=${objectToBase64String({ search: value })}`);
    };

    return (
        <div className={classNames(isOpen ? styles.AutoCompleteWrapper : undefined)}>
            {isOpen && (
                <>
                    <AutoComplete
                        className={styles.InputStyle}
                        inputId={'searchRef'}
                        field="title"
                        value={value}
                        suggestions={filteredList}
                        completeMethod={search}
                        placeholder={placeholder}
                        onChange={(e) => setValue(e.value)}
                        itemTemplate={(item) => (
                            <div key={item.id}>
                                <div className={styles.ItemTitle}>{item.title}</div>
                                <div className={styles.ItemDescription}>{highlight(item.description)}</div>
                            </div>
                        )}
                        panelFooterTemplate={
                            <>
                                <div className={styles.FooterWrapper} onClick={() => goToSearchPage()}>
                                    <AngleRightIcon className={styles.ArrowStyle} />
                                    <div>Все результаты поиска</div>
                                </div>
                                <div className={styles.FooterImage} />
                            </>
                        }
                        panelClassName={styles.PanelWrapper}
                        onFocus={() => setPlaceholder('')}
                    />
                    <div className={styles.BackgroundFilter} />
                </>
            )}
            <SearchIcon onClick={onClick} className={classNames(isOpen ? styles.SearchIcon : undefined)} />
        </div>
    );
};
