export const MOCK_DROPDOWN_OPTIONS = [
    {
        value: 'рсбу',
        label: 'РСБУ',
    },
    {
        value: 'test',
        label: 'Тест',
    },
];

export const OPTIONS = [
    {
        value: 'profitAndLossReport',
        label: 'Отчет о прибыли и убытках',
    },
    {
        value: 'balanceSheet',
        label: 'Баланс',
    },
    {
        value: 'cashFlow',
        label: 'Денежный поток',
    },
];
