import { useAppDispatch } from '@store/store';
import { openOverlay, closeOverlay } from '@store/store/slices/technical.slice';
import { useEffect } from 'react';

import { isValueExist } from '../utils';

export const useOverlay = (key: string, condition?: boolean) => {
    const dispatch = useAppDispatch();

    const open = () => {
        dispatch(openOverlay(key));
    };

    const close = () => {
        dispatch(closeOverlay(key));
    };

    useEffect(() => {
        if (isValueExist(condition)) {
            if (condition) {
                open();

                return () => {
                    close();
                };
            }
        }
    }, [condition, key]);

    return {
        open,
        close,
    };
};
