import { ReactComponent as DownArrow } from '@modules/Investorpro/shared/images/svg/ArrowDownGray.svg';
import classNames from 'classnames';
import { forwardRef } from 'react';

import styles from './styles.module.scss';
import { type CaptionTooltipData } from '../..';

export const CaptionTooltip = forwardRef<HTMLDivElement, CaptionTooltipData>(
    ({ title, x, y, visible, alignment }, ref) => {
        return (
            <div
                className={classNames(
                    styles.captionTooltip,
                    styles[`captionTooltip__${alignment}`],
                    !visible && styles.captionTooltip__hidden,
                )}
                style={{
                    top: y,
                    left: x,
                }}
                ref={ref}
            >
                <div className={classNames(styles.captionTooltip_mark, 'flex', 'align-items-center')}>{title}</div>
                <DownArrow className={styles.captionTooltip_arrow} />
            </div>
        );
    },
);
