import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const IssuerReportsLabel: FC<Props> = ({ width = 22, height = 22, color = 'currentColor' }) => (
    <svg
width={width} height={height} viewBox="0 0 1.76 1.76"
xmlns="http://www.w3.org/2000/svg">
        <path
            cx="32"
            cy="32"
            r="30"
            fill={color}
            d="M1.705 0.88A0.825 0.825 0 0 1 0.88 1.705A0.825 0.825 0 0 1 0.055 0.88A0.825 0.825 0 0 1 1.705 0.88z"
        />
        <path
            d="M1.089 0.5c0.03 0.014 0.055 0.03 0.074 0.055q0.025 0.029 0.041 0.066c0.011 0.025 0.014
            0.052 0.014 0.085 0 0.038 -0.008 0.074 -0.028 0.113s-0.05 0.063 -0.093 0.077c0.036 0.014
            0.063 0.036 0.077 0.063 0.017 0.028 0.022 0.069 0.022 0.124v0.052c0 0.036 0.003 0.061
            0.006 0.074 0.006 0.019 0.014 0.036 0.03 0.047v0.019h-0.184c-0.006 -0.017 -0.008 -0.033
            -0.011 -0.044 -0.006 -0.022 -0.006 -0.044 -0.008 -0.069v-0.074c0 -0.05 -0.008 -0.085
            -0.028 -0.102 -0.017 -0.017 -0.05 -0.025 -0.096 -0.025H0.743v0.314h-0.162v-0.797H0.963c0.055
            0.003 0.099 0.011 0.127 0.022m-0.344 0.118v0.214h0.179c0.036 0 0.063 -0.006 0.08 -0.014 0.03
            -0.017 0.047 -0.044 0.047 -0.091 0 -0.05 -0.017 -0.08 -0.047 -0.096 -0.017 -0.008 -0.044
            -0.014 -0.077 -0.014z"
            fill="#ffffff"
        />
    </svg>
);
