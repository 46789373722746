import { Table } from '@modules/Investorpro/shared/components/Table';
import { type QuoteType } from '@modules/Investorpro/types/quote.type';

import styles from './styles.module.scss';
import { TRADE_COLUMNS } from './columns';

type Props = {
    data: QuoteType[];
};

export const Trades = ({ data }: Props) => {
    const trades = data.slice(0, 13);

    return <Table data={trades} className={styles.trades} columns={TRADE_COLUMNS} />;
};
