import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { CurrencyCode } from '@libs/types/instrument.type';
import { PositiveNegativeValue } from '@modules/Investorpro/shared/components/PositiveNegativeValue';
import { getCurrencyRate, getKeyRate } from '@libs/services/instrument.service';
import { addDays, addYears } from 'date-fns';
import { round } from 'lodash';
import { formatPrice } from '@modules/Investorpro/shared/utils/format.util';

import { INIT_CURRENCY } from './constants';
import styles from './styles.module.scss';

export const Header = () => {
    const [keyRateDate, setKeyRateDate] = useState(new Date());
    const [currencyUpdateDate, setCurrencyUpdateDate] = useState(new Date());
    const [keyRate, setKeyRate] = useState(0);
    const [currencies, setCurrencies] = useState({
        [CurrencyCode.CNY]: { symbol: '¥', ...INIT_CURRENCY },
        [CurrencyCode.USD]: { symbol: '$', ...INIT_CURRENCY },
        [CurrencyCode.EUR]: { symbol: '€', ...INIT_CURRENCY },
    });

    useEffect(() => {
        const loadCurrenciesAndKeyRate = async () => {
            try {
                const currentDate = new Date();
                const prevDate = addDays(currentDate, -1);
                const [currentCurrencies, prevCurrencies, keyRate] = await Promise.all([
                    getCurrencyRate(currentDate),
                    getCurrencyRate(prevDate),
                    getKeyRate(addYears(currentDate, -1), currentDate),
                ]);

                const currenciesData = Object.keys(currencies).reduce<Partial<typeof currencies>>(
                    (acc, currencyCode) => {
                        const prevCurrencyValue = prevCurrencies.data.find(
                            (currency) => currency.vchCode === currencyCode,
                        )?.vcurs;
                        const currentCurrencyValue = currentCurrencies.data.find(
                            (currency) => currency.vchCode === currencyCode,
                        )?.vcurs;

                        acc[currencyCode as CurrencyCode] = {
                            ...currencies[currencyCode as CurrencyCode],
                            current: currentCurrencyValue ? Number(currentCurrencyValue) : 0,
                            prev: prevCurrencyValue ? Number(prevCurrencyValue) : 0,
                        };

                        return acc;
                    },
                    {},
                );

                setCurrencies(currenciesData as typeof currencies);

                const dollarCurrency = currentCurrencies.data.find((currency) => currency.vchCode === CurrencyCode.USD);
                setCurrencyUpdateDate(dollarCurrency ? new Date(dollarCurrency.tradeDate) : new Date());
                const lastKeyRate = keyRate.data[0];
                setKeyRate(lastKeyRate?.rate ? round(lastKeyRate.rate, 2) : 0);
                setKeyRateDate(lastKeyRate ? new Date(lastKeyRate.date) : new Date());
            } catch (e) {
                console.error('e', e);
            }
        };

        loadCurrenciesAndKeyRate();
    }, []);

    return (
        <div className={classNames(styles.totalMarketHeader, 'flex', 'justify-end')}>
            <div className={classNames(styles.totalMarketHeader_info, styles.info_keyRate, 'flex', 'flex-column')}>
                <h4>Ключевая ставка с&nbsp;{keyRateDate.toLocaleDateString()}</h4>
                <span>{formatPrice(keyRate)}%</span>
            </div>
            <div className={classNames(styles.totalMarketHeader_info, 'flex', 'flex-column')}>
                <h4>Курсы валют ЦБ РФ&nbsp;{currencyUpdateDate.toLocaleDateString()}</h4>
                <div className="flex">
                    {Object.keys(currencies).map((currencyCode) => {
                        const isPositive =
                            currencies[currencyCode as CurrencyCode]?.current >
                            currencies[currencyCode as CurrencyCode]?.prev;
                        const isNegative =
                            currencies[currencyCode as CurrencyCode]?.current <
                            currencies[currencyCode as CurrencyCode]?.prev;
                        const type = isPositive ? 'positive' : isNegative ? 'negative' : undefined;

                        return (
                            <div key={currencyCode} className={styles.currency}>
                                <span>{currencyCode},</span>
                                <span className={styles.currency_symbol}>
                                    1{currencies[currencyCode as CurrencyCode].symbol}
                                </span>
                                <PositiveNegativeValue
                                    value={currencies[currencyCode as CurrencyCode].current}
                                    withMark
                                    className={styles.currency_value}
                                    measure=" ₽"
                                    type={type}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
