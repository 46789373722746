import { LinearChart, DatePeriodSelector } from '@libs/components';
import { type PeriodOption } from '@libs/components/data/DatePeriodSelector';
import { useMemo } from 'react';
import { type PeriodId, type IntervalType } from '@libs/types/instrument.type';
import { compactNumber, getNumberWithSpacing } from '@libs/utils';
import { TooltipContent } from '@modules/Investorpro/shared/components/TooltipContent';
import { formatInstrumentTradeDate } from '@modules/Investorpro/modules/MarketPages/shared/utils/date.utils';
import { getXAxisCallbackByPeriodId } from '@modules/Investorpro/shared/utils/chart.utils';

import styles from './styles.module.scss';

type Props = {
    barsData: Array<{ x: string; y: number; raw: number }>;
    lineData: Array<{ x: string; y: number }>;
    handlePeriodOptionChange: (option: string | null) => void;
    currentPeriodOption: string | null;
    intervalType?: IntervalType;
    periodOptions: PeriodOption[];
    maxVol: number;
    width?: number;
    height?: number;
    measure?: string;
} & Pick<Parameters<typeof LinearChart>[0], 'loading' | 'initLoading'>;

export const LinearChartWithBars = ({
    barsData,
    lineData,
    maxVol,
    handlePeriodOptionChange,
    currentPeriodOption,
    periodOptions,
    width,
    height,
    measure = ' руб.',
    intervalType,
    ...props
}: Props) => {
    const options = useMemo(() => {
        const xTicksCount = 22;

        return {
            plugins: {},
            animation: false,
            scales: {
                x: {
                    offset: false,
                    type: 'timeseries',
                    ticks: {
                        maxTicksLimit: xTicksCount,
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0,
                        callback: getXAxisCallbackByPeriodId({
                            data: lineData,
                            ticksCount: xTicksCount,
                            period: currentPeriodOption as PeriodId,
                        }),
                    },
                },
                y: {
                    ticks: {
                        maxTicksLimit: 10,
                    },
                },
                barsY: {
                    max: maxVol * 3,
                    min: 0,
                    display: false,
                    type: 'linear',
                    grid: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
            },
        };
    }, [maxVol, lineData.length, currentPeriodOption]);
    const chartData = useMemo(() => {
        const isSinglePoint = lineData.length === 1;

        const pointProps = isSinglePoint
            ? {
                  pointRadius: 4,
                  pointColor: '#2F9CEB',
              }
            : {};

        return [
            {
                color: '#2F9CEB',
                data: lineData,
                type: 'line',
                label: 'Цена',
                ...pointProps,
            },
            {
                type: 'bar',
                borderWidth: 0,
                backgroundColor: '#F4F4F4',
                data: barsData,
                yAxisID: 'barsY',
                enableTooltip: false,
                barThickness: isSinglePoint ? 22 : undefined,
            },
        ];
    }, [barsData, lineData]);

    return (
        <div className={styles.linearChartWithBarsWrapper}>
            <LinearChart
                {...props}
                width={width ?? 1000}
                height={height ?? 600}
                options={options as any}
                data={chartData as any}
                withGradient
                withVerticalAnnotationLine
                deleteXOffset
                tooltip={{
                    mode: 'index',
                    handlePrintPoints: (context, coords, highestIndex) => {
                        const { ctx } = context.chart;
                        const pointRadius = 7;
                        ctx.beginPath();
                        const { x, y, color } = coords[highestIndex];
                        const strokeWidth = 4;
                        ctx.lineWidth = strokeWidth;
                        ctx.arc(x, y, pointRadius - strokeWidth, 0, 2 * Math.PI);
                        ctx.strokeStyle = color;
                        ctx.stroke();
                        ctx.fillStyle = 'white';
                        ctx.fill();
                    },
                    renderTooltipData: (data) => {
                        if (!data.length) return null;
                        const dataset = data[0];
                        const current = barsData[dataset?.dataIndex ?? 0];
                        const price = lineData[dataset?.dataIndex ?? 0]?.y ?? 0;
                        const date = formatInstrumentTradeDate(current?.x ?? '', intervalType);
                        const [volume, decimals] = compactNumber(current?.raw);

                        const content = [
                            { title: 'Дата', content: date },
                            { title: 'Цена', content: `${getNumberWithSpacing(price)} ${measure}` },
                            { title: 'Объем', content: `${volume} ${decimals} руб.` },
                        ];

                        return <TooltipContent content={content} />;
                    },
                }}
            />
            <div>
                <DatePeriodSelector
                    disabled={props.loading}
                    options={periodOptions}
                    currentOption={currentPeriodOption}
                    handlePeriodOptionChange={handlePeriodOptionChange}
                />
            </div>
        </div>
    );
};
