import { type FC } from 'react';
import { ResponsiveContainer, Treemap } from 'recharts';

import { type InstrumentLiquidity } from '../../../../entities';
import { ViewEmptyStub, ViewLoader } from '../../../../shared/Layout';
import { CustomizedContent } from './CustomizedContent';

type Props = {
    data: InstrumentLiquidity[];
    isInReport?: boolean;
    isPending?: boolean;
};

export const Chart: FC<Props> = ({ data, isInReport, isPending }) => {
    if (isPending) {
        return <ViewLoader />;
    }

    if (data && data.length === 0) {
        return <ViewEmptyStub title={'Данные отсутствуют'} />;
    }

    return (
        <ResponsiveContainer width="100%" height="100%" style={{ borderRadius: '12px', overflow: 'hidden' }}>
            <Treemap
                isAnimationActive={!isInReport}
                aspectRatio={1 / 1}
                data={data}
                dataKey="percent"
                stroke="#fff"
                fill="#A5B1C0"
                content={<CustomizedContent />}
            />
        </ResponsiveContainer>
    );
};
