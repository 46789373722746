import { SelectButton } from '@modules/Investorpro/shared/components/SelectButton';
import { type ProfitPeriod } from '@libs/types/instrument.type';
import { useState } from 'react';

import { HeatMapContainer } from '../../shared/components/HeatMapContainer';
import { DEFAULT_MARKET_MAP_PERIODS_OPTIONS } from '../../shared/constants';
import { HEAT_COLORS } from './constants';
import {
    type InstrumentStoreType,
    type InstrumentCategory,
    type InstrumentType,
    HeatMapType,
} from '../../shared/types';
import { useGetDataFromStore } from '../../shared/utils/useGetDataFromStore';
import { useInstrumentSelect } from '../../shared/utils/useInstrumentSelect';

type Props = {
    loadData: (
        type: InstrumentType,
        category: InstrumentCategory,
        profitPeriod: ProfitPeriod[],
        key: HeatMapType,
    ) => Promise<unknown>;
    instrumentsStore: InstrumentStoreType;
    tradeDate: Date | null;
};

export const MarketHeatMap = ({ loadData, instrumentsStore, tradeDate }: Props) => {
    const [currentPeriodOptionValue, setCurrentPeriodOptionValue] = useState<ProfitPeriod>(
        DEFAULT_MARKET_MAP_PERIODS_OPTIONS[0].value,
    );

    const {
        instrumentCategory,
        instrumentType,
        updateDateLabel,
        handleInstrumentCategoryChange,
        handleInstrumentTypeChange,
    } = useInstrumentSelect({
        loadData,
        profitPeriod: currentPeriodOptionValue,
        tradeDate,
        key: HeatMapType.MARKET,
    });

    const { data, loading } = useGetDataFromStore(
        HeatMapType.MARKET,
        instrumentsStore,
        instrumentType,
        instrumentCategory,
        currentPeriodOptionValue,
    );

    const handleProfitPeriodChange = async (periodId: string) => {
        setCurrentPeriodOptionValue(periodId as ProfitPeriod);

        if (!instrumentCategory) return;

        await loadData(instrumentType.code, instrumentCategory.code, [periodId as ProfitPeriod], HeatMapType.MARKET);
    };

    return (
        <HeatMapContainer
            title="Тепловая карта рынка"
            controls={
                <SelectButton
                    options={DEFAULT_MARKET_MAP_PERIODS_OPTIONS}
                    value={currentPeriodOptionValue}
                    handleValueChange={handleProfitPeriodChange}
                />
            }
            instrumentType={instrumentType}
            setInstrumentType={handleInstrumentTypeChange}
            data={data}
            setInstrumentCategory={handleInstrumentCategoryChange}
            instrumentCategory={instrumentCategory}
            heatColors={HEAT_COLORS}
            withRangeBarLabel
            valueKey="capitalization"
            loading={loading}
            labelKey={currentPeriodOptionValue}
            updatedDate={updateDateLabel}
            profitCode={currentPeriodOptionValue}
            groupKey="issuersector"
        />
    );
};
