import { Image, Page, Text, View } from '@react-pdf/renderer';
import { type FC } from 'react';

import { type ReportFormFinalValues } from './ReportForm.schema';

type Props = {
    finalValues: ReportFormFinalValues;
};

export const ReportFinalPage: FC<Props> = ({ finalValues }) => (
    <Page wrap={false} size="A4" style={{ backgroundColor: finalValues.background }}>
        <View
            style={{
                color: finalValues.textColor,
                margin: 24,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                height: '100vh',
                justifyContent: 'space-between',
            }}
        >
            {finalValues.logo ? <Image style={{ width: 180 }} src={finalValues.logo} /> : <View />}
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                }}
            >
                <Text
                    style={{
                        fontSize: '16px',
                        textAlign: 'right',
                        fontFamily: 'Inter',
                    }}
                >
                    {finalValues.name}
                </Text>
                <Text
                    style={{
                        fontSize: '12px',
                        textAlign: 'right',
                        fontFamily: 'Inter',
                    }}
                >
                    {finalValues.descriptionFinal}
                </Text>
            </View>
        </View>
    </Page>
);
