import { type ReactNode, useState, useEffect, memo } from 'react';
import { type IFrame, type Client, type IMessage, type StompConfig } from '@stomp/stompjs';

import { StompContext, type StompContextType } from '../contexts/stomp.context';
import { useStompCreate } from '../hooks/useStompCreate';

type Props = {
    children: ReactNode;
    url: string;
    config?: StompConfig;
    autoConnect?: boolean;
} & Partial<Client>;

export const StompProvider = memo(
    ({
        children,
        url,
        config,
        autoConnect = false,
        onConnect,
        onDisconnect,
        onWebSocketError,
        onStompError,
        onUnhandledMessage,
        onUnhandledReceipt,
        onUnhandledFrame,
        onWebSocketClose,
        onChangeState,
    }: Props) => {
        const [isConnected, setIsConnected] = useState(false);
        const [isActive, setIsActive] = useState(false);

        const stompClient = useStompCreate(url, config);

        useEffect(() => {
            if (autoConnect) {
                stompClient.activate();
            }
        }, [autoConnect]);

        useEffect(() => {
            return () => {
                stompClient.deactivate();
            };
        }, []);

        const setClientStatus = () => {
            setIsActive(stompClient.active);
            setIsConnected(stompClient.connected);
        };

        stompClient.onConnect = (frame: IFrame) => {
            setClientStatus();
            console.log('Connected: ' + JSON.stringify(frame));

            if (onConnect) onConnect(frame);
        };

        stompClient.onDisconnect = (frame: IFrame) => {
            setClientStatus();
            console.log('Disconnected: ' + JSON.stringify(frame));

            if (onDisconnect) onDisconnect(frame);
        };

        stompClient.onWebSocketError = (evt: any) => {
            setClientStatus();
            console.error('Error with websocket', evt);

            if (onWebSocketError) onWebSocketError(evt);
        };

        stompClient.onStompError = (frame: IFrame) => {
            setClientStatus();
            console.error('Broker reported error: ' + frame.headers['message']);
            console.error('Additional details: ' + frame.body);

            if (onStompError) onStompError(frame);
        };

        stompClient.onUnhandledMessage = (message: IMessage) => {
            setClientStatus();
            console.log('Unhandled message: ' + message.body);

            if (onUnhandledMessage) onUnhandledMessage(message);
        };

        stompClient.onUnhandledReceipt = (receipt: IFrame) => {
            setClientStatus();
            console.log('Unhandled receipt: ' + receipt.body);

            if (onUnhandledReceipt) onUnhandledReceipt(receipt);
        };

        stompClient.onUnhandledFrame = (frame: IFrame) => {
            setClientStatus();
            console.log('Unhandled frame: ' + frame.body);

            if (onUnhandledFrame) onUnhandledFrame(frame);
        };

        stompClient.onWebSocketClose = (event) => {
            setClientStatus();
            console.log('WebSocket closed: ' + JSON.stringify(event));

            if (onWebSocketClose) onWebSocketClose(event);
        };

        stompClient.onChangeState = (state) => {
            setClientStatus();

            if (onChangeState) onChangeState(state);
        };

        const contextValue: StompContextType = {
            isConnected,
            isActive,
            client: stompClient,
        };

        return <StompContext.Provider value={contextValue}>{children}</StompContext.Provider>;
    },
);
