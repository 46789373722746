import { ReactComponent as EmptyUserAvatar } from '@shared/images/svg/EmptyUserAvatar.svg';

import styles from './styles.module.scss';

type AvatarProps = {
    img?: string;
    size: number;
};

export const Avatar = ({ img, size }: AvatarProps) => {
    return img
? (
        <img
className={styles.avatar} width={size} height={size}
src={img} />
    )
: (
        <EmptyUserAvatar className={styles.avatar} width={size} height={size} />
    );
};
