import { Text } from '@mantine/core';
import { createColumnHelper, type Row } from '@tanstack/react-table';
import { useMemo } from 'react';
import { type PortfolioPerformance } from '@modules/Investorpro/modules/PortfolioPage/entities';

const columnHelper = createColumnHelper<PortfolioPerformance>();

export const usePerformanceColumns = (
    performanceType: string,
    minDeltaIndex: {
        expectedProfit: number;
        value: number;
        maxRisk: number;
    },
) => useMemo(
        () => [
            columnHelper.accessor('name', {
                enableSorting: false,
                id: 'name',
                size: 156,
                meta: { headerCellStyles: { whiteSpace: 'pre-wrap' } },
                header: 'Распределение\nинструментов в портфеле',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'start' }}>
                        {props?.getValue() ?? ''}
                    </Text>
                ),
            }),
            columnHelper.accessor('expectedProfit', {
                enableSorting: false,
                id: 'expectedProfit',
                size: 150,
                meta: {
                    headerCellStyles: { justifyContent: 'flex-end', whiteSpace: 'pre-wrap', textAlign: 'end' },
                    getCellStyles: (row: Row<PortfolioPerformance>) => ({
                        backgroundColor:
                            row.index === 0 || row.index === minDeltaIndex.expectedProfit
                                ? 'rgba(66, 166, 46, 0.2)'
                                : '',
                        hoveredBackgroundColor:
                            row.index === 0 || row.index === minDeltaIndex.expectedProfit
                                ? 'rgba(66, 166, 46, 0.4)'
                                : 'rgb(231, 240, 249)',
                    }),
                },
                header: 'Годовая доходность',
                cell: (props) => (
                    <Text
style={{ textAlign: 'end' }} fz="14px" lh="16px"
fs="normal">
                        {props.getValue().toLocaleString('ru') ?? ''}
                        {'%'}
                    </Text>
                ),
            }),

            columnHelper.accessor('value', {
                enableSorting: false,
                id: 'value',
                size: 150,
                meta: {
                    headerCellStyles: { justifyContent: 'flex-end' },
                    getCellStyles: (row: Row<PortfolioPerformance>) => ({
                        backgroundColor:
                            row.index === 0 || row.index === minDeltaIndex.value ? 'rgba(255, 170, 0, 0.2)' : '',
                        hoveredBackgroundColor:
                            row.index === 0 || row.index === minDeltaIndex.value
                                ? 'rgba(255, 170, 0, 0.4)'
                                : 'rgb(231, 240, 249)',
                    }),
                },
                header: () => (performanceType === 'STRESS_TEST_MODEL' ? 'Стресс-тест риск' : 'Волатильность'),
                cell: (props) => (
                    <Text
style={{ textAlign: 'end' }} fz="14px" lh="16px"
fs="normal">
                        {props.getValue().toLocaleString('ru') ?? ''}
                        {'%'}
                    </Text>
                ),
            }),

            columnHelper.accessor('maxRisk', {
                enableSorting: false,
                id: 'maxRisk',
                size: 150,
                meta: {
                    headerCellStyles: { justifyContent: 'flex-end', whiteSpace: 'pre-wrap' },
                    getCellStyles: (row: Row<PortfolioPerformance>) => ({
                        backgroundColor:
                            row.index === 0 || row.index === minDeltaIndex.maxRisk ? 'rgba(242, 67, 61, 0.2)' : '',
                        hoveredBackgroundColor:
                            row.index === 0 || row.index === minDeltaIndex.maxRisk
                                ? 'rgba(242, 67, 61, 0.4)'
                                : 'rgb(231, 240, 249)',
                    }),
                },
                header: 'Максимальная\n просадка',
                cell: (props) => (
                    <Text
style={{ textAlign: 'end' }} fz="14px" lh="16px"
fs="normal">
                        {props.getValue().toLocaleString('ru') ?? ''}
                        {'%'}
                    </Text>
                ),
            }),
        ],
        [minDeltaIndex, performanceType],
    );
