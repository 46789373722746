import { Box, Divider, Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import styles from './GradientScaleVertical.module.css';

type Props = {
    value: number;
    labels: Array<{ name: string; diapasonStart: number; diapasonEnd: number }>;
    topValue: -1 | 0 | 1 | 100 | -100;
    bottomValue: -1 | 0 | 1 | 100 | -100;
};

export const GradientScaleVertical: FC<Props> = ({ value, labels, topValue, bottomValue }) => {
    const position = Math.min(Math.max(((value - topValue) / (bottomValue - topValue)) * 100, 0), 100);
    const dividers = Array(labels.length + 1).fill({ w: 'auto' });

    return (
        <Group
gap={0} pl={80} wrap="nowrap"
w="100%">
            <Box h={205} w={8} className={styles.scale}>
                <Stack>
                    {dividers.map((_, index) => {
                        const i = index;

                        return (<Divider
className={styles.divider} key={i} orientation="horizontal"
w="auto" h={25} />);
                    })}
                </Stack>
                <Box
                    style={{
                        position: 'absolute',
                        top: `${position}%`,
                        right: 65,
                        width: 0,
                        height: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        className={styles.tooltip}
                        style={{
                            color: value >= 0 ? '#14171F' : '#F2433D',
                        }}
                    >
                        <Box
                            style={{
                                width: '8px',
                                height: '8px',
                                transform: 'rotate(45deg)',
                                backgroundColor: '#FFF',
                                position: 'absolute',
                                borderRight: '1px solid #D8E1EB',
                                borderTop: '1px solid #D8E1EB',
                                right: '-5px',
                                top: 'calc(50% - 4px)',
                            }}
                        />
                        {`${value?.toLocaleString() || ''}%`}
                    </Box>
                </Box>
            </Box>
            <Stack
justify="space-between" ml={18} gap={0}
h={205} w="100%">
                <Divider w="100%" />
                {labels.map((el) => (
                    <Stack
                        key={el.name}
                        pt={8}
                        gap={8}
                        className={value <= el.diapasonStart && value >= el.diapasonEnd ? styles.selectedDiapason : ''}
                    >
                        <Text
                            fz={15}
                            fw={value <= el.diapasonStart && value >= el.diapasonEnd ? 500 : 300}
                            className={value <= el.diapasonStart && value >= el.diapasonEnd ? styles.selectedText : ''}
                        >
                            {el.name}
                        </Text>
                        <Divider w="100%" />
                    </Stack>
                ))}
            </Stack>
        </Group>
    );
};
