import { Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { type TopFiveByProfitability } from '@modules/Investorpro/modules/PortfolioPage/entities';

const columnHelper = createColumnHelper<TopFiveByProfitability>();

export const useConfigureColumns = () => useMemo(
        () => [
            columnHelper.accessor('name', {
                enableSorting: false,
                id: 'name',
                size: 340,
                header: 'Название',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'start', color: '#18191B' }}>
                        {props.getValue() ?? ''}
                    </Text>
                ),
            }),

            columnHelper.accessor('isin', {
                enableSorting: false,
                id: 'isin',
                size: 190,
                header: 'ISIN',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'start', color: '#18191B' }}>
                        {props.getValue() ?? ''}
                    </Text>
                ),
            }),

            columnHelper.accessor('profit', {
                enableSorting: false,
                id: 'profit',
                size: 120,
                meta: { headerCellStyles: { justifyContent: 'end' } },
                header: 'Доход',
                cell: (props) => (
                    <Text
                        fz="14px"
                        lh="16px"
                        fs="normal"
                        style={{
                            textAlign: 'end',
                            // eslint-disable-next-line no-nested-ternary
                            color: props.getValue() > 0 ? '#26A65B' : props.getValue() < 0 ? '#E42821' : 'initial',
                        }}
                    >
                        {typeof props.getValue() === 'number' ? `${props.getValue().toLocaleString('ru')}%` : ''}
                    </Text>
                ),
            }),
        ],
        [],
    );
