import { Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { ViewError } from '@modules/Investorpro/modules/PortfolioPage/shared/Layout';

import { GradientScaleVertical } from '../../../../shared/GradientScaleVertical';

type Props = {
    riskProfile: number;
    isInReport?: boolean;
    error?: Error | null;
};

export const RiskProfileScale: FC<Props> = ({ riskProfile, isInReport, error }) => {
    if (error) {
        return (
            <Stack>
                <Text fz={19} fw={400}>
                    {'Риск профиль портфеля в заданном сценарии'}
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по риск профилю портфеля" />
            </Stack>
        );
    }

    return (
        <Stack miw={400}>
            {!isInReport
? (
                <Text fz={19} fw={400}>
                    {'Риск профиль портфеля в заданном сценарии'}
                </Text>
            )
: null}
            <GradientScaleVertical
                labels={[
                    { name: 'Агрессивный', diapasonStart: -80, diapasonEnd: -100 },
                    { name: 'Умеренно агрессивный', diapasonStart: -60, diapasonEnd: -79 },
                    { name: 'Сбалансированный', diapasonStart: -40, diapasonEnd: -59 },
                    { name: 'Умеренно консервативный', diapasonStart: -20, diapasonEnd: -39 },
                    { name: 'Консервативный', diapasonStart: 0, diapasonEnd: -19 },
                ]}
                value={riskProfile ?? 0}
                topValue={-100}
                bottomValue={0}
            />
        </Stack>
    );
};
