import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const Pencil: FC<Props> = ({ width = 24, height = 24, color = 'currentColor' }) => (
    <svg
width={width} height={height} viewBox="0 0 24 24"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 15L12.5 8.50006M6 15L6.58739 14.8825C8.68655 14.4627 10.5373 16.3135 10.1175 18.4126L10 19M6
            15L5 19M12.5 8.50006L15.6788 5.32117C15.8883 5.11176 16.1545 4.96084 16.4496 4.93613C18.5861
            4.75724 20.2428 6.41399 20.0639 8.55042C20.0392 8.84552 19.8883 9.11176 19.6789 9.32116L10
            19M12.5 8.50006C13.5345 8.14448 15.1705 8.31246 16 9.00001M10 19L6 20M6 20L5 20.5L4.5 20L5
            19M6 20V20C6.20601 19.382 5.61803 18.794 5 19V19"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
