import { ReactComponent as ArrowDownGrey } from '@shared/images/svg/ArrowDownGrey.svg';
import classNames from 'classnames';
import { Avatar } from '@libs/components';

import styles from './styles.module.scss';

type AvatarButtonProps = {
    img?: string;
    avatarSize: number;
    onClick: (e: any) => any;
    className?: string;
};

export const AvatarButton = ({ img, avatarSize, onClick, className }: AvatarButtonProps) => {
    return (
        <div onClick={onClick} className={classNames('flex', 'align-items-center', styles.avatarContainer, className)}>
            <ArrowDownGrey />
            <Avatar size={avatarSize} img={img} />
        </div>
    );
};
