import { getCountOfZerosAfterDotAndBeforeNumber, getNumberWithSpacing, isValueExist } from '@libs/utils';
import { NONE_SYMBOL } from '@modules/Investorpro/shared/constants';
import { round } from 'lodash';

export const roundInstrumentPrice = (price: number) => {
    if (price === 0) {
        return 0;
    }

    const precision = getCountOfZerosAfterDotAndBeforeNumber(price);
    const roundValue = precision >= 2 ? Math.max(5, precision + 4) : 2;

    return round(price, roundValue);
};

export const formatInstrumentPrice = (price: number | null | undefined) => {
    if (!isValueExist(price)) {
        return NONE_SYMBOL;
    }

    return getNumberWithSpacing(roundInstrumentPrice(price));
};
