import { useCallback, useState } from 'react';
import { SelectButton } from '@modules/Investorpro/shared/components/SelectButton';
import { Dropdown } from '@libs/components';
import { round } from 'lodash';
import { ProfitPeriod } from '@libs/types/instrument.type';
import { formatPrice } from '@modules/Investorpro/shared/utils/format.util';

import {
    type InstrumentType,
    type Option,
    type InstrumentCategory,
    QuoteType,
    type InstrumentStoreType,
    type InstrumentIndustryData,
    type LiquidityKeyType,
    HeatMapType,
} from '../../shared/types';
import { LIQUIDITY_OPTIONS, QUOTE_OPTIONS } from '../../shared/constants';
import { HeatMapContainer } from '../../shared/components/HeatMapContainer';
import { HEAT_COLORS } from './constants';
import { useGetDataFromStore } from '../../shared/utils/useGetDataFromStore';
import { useInstrumentSelect } from '../../shared/utils/useInstrumentSelect';

type Props = {
    instrumentsStore: InstrumentStoreType;
    loadData: (
        type: InstrumentType,
        category: InstrumentCategory,
        profitPeriod: ProfitPeriod[],
        key: HeatMapType,
    ) => Promise<unknown>;
    tradeDate: Date | null;
};

export const LiquidityHeatMap = ({ instrumentsStore, loadData, tradeDate }: Props) => {
    const [quoteType, setQuoteType] = useState<QuoteType>(QuoteType.BUY);
    const [liquidityType, setLiquidityType] = useState<Option<LiquidityKeyType>>(LIQUIDITY_OPTIONS[0]);
    const {
        instrumentCategory,
        instrumentType,
        updateDateLabel,
        handleInstrumentCategoryChange,
        handleInstrumentTypeChange,
    } = useInstrumentSelect({
        loadData,
        profitPeriod: ProfitPeriod.YEAR,
        key: HeatMapType.LIQUIDITY,
        tradeDate,
    });

    const handleQuoteTypeChange = async (value: string) => {
        setQuoteType(value as QuoteType);
    };

    const currentLiquidityCode = quoteType + '_' + liquidityType.code;

    const renderLiquidityContentInTooltip = useCallback(
        (data: InstrumentIndustryData) => [
            {
                title: 'Уровень ликвидности',
                content: formatPrice(round(+data[currentLiquidityCode as keyof InstrumentIndustryData], 2)) + '%',
            },
        ],
        [currentLiquidityCode],
    );

    const { data, loading } = useGetDataFromStore(
        HeatMapType.LIQUIDITY,
        instrumentsStore,
        instrumentType,
        instrumentCategory,
        ProfitPeriod.YEAR,
    );

    return (
        <HeatMapContainer
            title="Тепловая карта ликвидности"
            updatedDate={updateDateLabel}
            controls={
                <>
                    <Dropdown
                        optionLabel="name"
                        value={liquidityType}
                        options={LIQUIDITY_OPTIONS}
                        onChange={(e) => setLiquidityType(e.value as Option<LiquidityKeyType>)}
                    />
                    <SelectButton options={QUOTE_OPTIONS} value={quoteType} handleValueChange={handleQuoteTypeChange} />
                </>
            }
            instrumentType={instrumentType}
            setInstrumentType={handleInstrumentTypeChange}
            data={data}
            heatColors={HEAT_COLORS}
            rangeBarWidth={34}
            loading={loading}
            setInstrumentCategory={handleInstrumentCategoryChange}
            instrumentCategory={instrumentCategory}
            valueKey="capitalization"
            labelKey={currentLiquidityCode}
            measureTitle="Ликвидность"
            groupKey="issuersector"
            extraTooltipContent={renderLiquidityContentInTooltip}
            profitCode={ProfitPeriod.YEAR}
        />
    );
};
