import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

export const TechnicPage = () => {
    const description =
        'Datashop находится в стадии разработки. Скоро мы представим широчайшие возможности для всех участников финансового рынка. Запаситесь терпением.';

    return (
        <div className={classNames('flex', 'flex-column', styles.technicPageWrapper)}>
            <div className={styles.pageContent}>
                <h1>
                    СТРАНИЦА
                    <br /> СКОРО ПОЯВИТСЯ
                </h1>
                <p>{description}</p>
            </div>
        </div>
    );
};
