import {
    type PortfolioRiskMetricsRequestParams,
    type RiskMetricsType,
} from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getRiskMetrics: ApiFactory<
    ApiEndpoint<RiskMetricsType, void, PortfolioRiskMetricsRequestParams>
> = (client) => async ({ signal, params: { id, ...params } }) => {
    const { data } = await client.get<RiskMetricsType>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/risk-metrics/${id}`,
        { signal, params },
    );

    return data;
};
