import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';

type Props = {
    className?: string;
    text: string | null | undefined;
};

export const StockDescriptionText = ({ className, text }: Props) => {
    const [isDescriptionFullyOpened, setIsDescriptionFullyOpened] = useState(false);
    const toggleDescriptionOpen = () => setIsDescriptionFullyOpened(!isDescriptionFullyOpened);
    const [showToggleButton, setShowToggleButton] = useState(false);
    const textRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        setIsDescriptionFullyOpened(false);
        setShowToggleButton((textRef.current?.clientHeight ?? 0) > 96);
    }, [text]);

    return (
        <div className={classNames(styles.description, className)}>
            <h2>О компании</h2>
            <div
                className={classNames(
                    styles.description_content,
                    isDescriptionFullyOpened && styles.description_content__opened,
                )}
            >
                <p ref={textRef}>{text}</p>
            </div>
            {showToggleButton && (
                <button onClick={toggleDescriptionOpen}>
                    {isDescriptionFullyOpened ? 'Показать меньше' : 'Показать больше'}
                </button>
            )}
        </div>
    );
};
