import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { useApi } from '../../../api';

export const useGetTypologyAssetsFundsQuery = (
    portfolioId: string,
    enabled = true,
    onStatusChange?: (status: 'error' | 'success' | 'pending') => void,
) => {
    const api = useApi();

    const query = useQuery({
        queryKey: ['portfolio', portfolioId, 'typology-assets', 'funds'],
        queryFn: async () => await api.portfolio.typologyAssets.getFunds({ params: { portfolioId } }),
        enabled: Boolean(portfolioId) && enabled,
    });

    useEffect(() => {
        if (query.isLoading || query.isFetching) {
            onStatusChange?.('pending');
        }

        if (query.isError) {
            onStatusChange?.('error');
        }

        if (query.isSuccess && !query.isLoading && !query.isFetching) {
            onStatusChange?.('success');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isLoading, query.isError, query.isSuccess, query.isFetching, query.isPending]);

    return query;
};
