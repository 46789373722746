import {
    type SecurityDatasetColor,
} from '@modules/Investorpro/modules/MarketPages/BondsMarketPage/components/BondMarketChartBlock/types';
import { type GetCandlesRequestBody, type InstrumentEngineType } from '@modules/Investorpro/types/quote.type';

import { type NotNullable, type IntervalType, type PeriodId } from './instrument.type';

export type BondMarketAuctionData = {
    name: string;
    begDistDate: string;
    distVal: number;
}

export type BondMarketAmortisationData = {
    name: string;
    mtyDate: string;
    payPerBond: number;
    payPerMarket: number;
}

export type BondMarketRedemptionData = {
    name: string;
    mtyDate: string;
    payPerMarket: number;
}

export type BondMarketOfferData = {
    name: string;
    offerDate: string;
    sumIssueVal: number;
}

export type BondMarketCouponData = {
    name: string;
    payPerBond: number;
    payDate: string;
}

export type SecurityData = Pick<GetCandlesRequestBody, 'engine' | 'board' | 'market' | 'secId'>;

export type SecurityPriceData = {
    price: number;
    prevTradeDayPrice: number | null;
} & SecurityData;

export type PriceChanges = {
    changeRelative: number;
    changeAbsolute: number;
}

export type SecurityMarketIndexesData = {
    name: string;
    color?: SecurityDatasetColor;
    tradeDate?: Date;
    interval?: IntervalType;
} & SecurityPriceData & PriceChanges

export type SecurityIndex = {
    board?: string;
    engine?: InstrumentEngineType;
    market?: SecurityMarketType;
    color?: SecurityDatasetColor;
    interval?: IntervalType;
} & Pick<SecurityMarketIndexesData, 'secId' | 'name'>;

export type BondMarketData = {
    name: string;
    vol: number;
} & SecurityPriceData & Pick<PriceChanges, 'changeRelative'>

export type PeriodPriceValues = {
    [PeriodId.ONE_DAY]: number | null;
    [PeriodId.FIVE_DAYS]: number | null;
    [PeriodId.ONE_MONTH]: number | null;
    [PeriodId.THREE_MONTHS]: number | null;
    [PeriodId.SIX_MONTHS]: number | null;
    [PeriodId.TWO_YEARS]: number | null;
    [PeriodId.SNG]: number | null;
    [PeriodId.ONE_YEAR]: number | null;
    [PeriodId.THREE_YEARS]: number | null;
    [PeriodId.FIVE_YEARS]: number | null;
    [PeriodId.TEN_YEARS]: number | null;
    [PeriodId.ALL]: number | null;
}

export type StockMarketData = {
    name: string;
    capitalization: number;
    vol: number;
    issuesize: number;
    price: number;
    prevTradeDayPrice: PeriodPriceValues;
    changeRelative: NotNullable<PeriodPriceValues>;
    changeAbsolute: NotNullable<PeriodPriceValues>;
} & SecurityData;

export enum SecurityMarketType {
    BOND = 'bonds',
    STOCK = 'shares',
    INDEX = 'index',
    CURRENCY = 'selt',
    GOODS = 'forts'
}

export type SelectedSecurity = {
    color: SecurityDatasetColor;
    hasOwnColor: boolean;
    name: string;
    interval?: IntervalType;
    tradeDate?: Date;
} & Pick<SecurityPriceData, 'engine' | 'board' | 'market' | 'secId'>;

export type StockChangeLeader = {
    name: string;
    price: number;
    changeRelative: number;
}

export type TopStockByCapitalization = {
    name: string;
    price: number;
    changeRelative: number;
    changeAbsolute: number;
    capitalization: number;
}

export type TopStockByVol = {
    name: string;
    price: number;
    changeRelative: number;
    vol: number;
}

export type StockDividend = {
    secId: string | null;
    price: number;
    paymentData: string | null;
    paymentVol: number;
    currentProfit: number;
}

export type StockEvent = {
    date: Date;
    name: string;
    tickers: string[];
    fininstId: string;
}

export enum ServerBondIndustry {
    CORP = 'Корп',
    GOV = 'Гос',
    MONI = 'Муни',
}

export enum ServerBondTypeCode {
    EXCHANGE_BOND = 'exchange_bond',
    IFI_BOND = 'ifi_bond',
    CORPORATE_BOND = 'corporate_bond',
    MUNICIPAL_BOND = 'municipal_bond',
    OFZ_BOND = 'ofz_bond',
    SUBFEDERAL_BOND = 'subfederal_bond',
}
