import { ModalWindow } from '@libs/components';
import { useAuthorize } from '@libs/utils';
import { LoginForm } from '@libs/components/form/LoginForm';

import styles from './styles.module.scss';

type LoginModalProps = {
    isVisible: boolean;
};

export const LoginModal = ({ isVisible }: LoginModalProps) => {
    const { loginHandler } = useAuthorize();

    return (
        <ModalWindow isVisible={isVisible} isClosable={false} maskClassName={styles.overlay}>
            <LoginForm loginHandler={loginHandler} />
        </ModalWindow>
    );
};
