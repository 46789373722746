import { Box, Stack, Text } from '@mantine/core';
import { type UseQueryResult } from '@tanstack/react-query';
import { type FC } from 'react';

import { type Diversification } from '../../../../entities';
import { GradientScale } from '../../../../shared/GradientScale';
import { ViewError, ViewLoader } from '../../../../shared/Layout';

type Props = {
    diversification: UseQueryResult<Diversification, Error>;
    isInReport?: boolean;
};

export const DiversificationScale: FC<Props> = ({ diversification, isInReport }) => {
    if (diversification.isError) {
        return (
            <Stack>
                <Text fz={19} fw={400} lh="18px">
                    {'Диверсифицированность портфеля'}
                </Text>
                <ViewError
                    error={diversification.error}
                    subtitle="Ошибка получения данных по диверсифицированности портфеля"
                />
            </Stack>
        );
    }

    if (diversification.isPending) {
        return (
            <Box>
                <Text fz={19} fw={400}>
                    {'Диверсифицированность портфеля'}
                </Text>
                <ViewLoader />
            </Box>
        );
    }

    return (
        <Stack gap={15}>
            {!isInReport
? (
                <Text fz={19} fw={400}>
                    {'Диверсифицированность портфеля'}
                </Text>
            )
: null}
            <GradientScale
                rightValue={1}
                leftValue={0}
                value={diversification.data.diversification}
                labels={['Полностью диверсифицирован', 'Не диверсифицирован']}
            />
        </Stack>
    );
};
