import { Center, Group, SimpleGrid, Stack } from '@mantine/core';
import { type FC, useMemo } from 'react';
import { type PortfolioTypologyAssetsMatrixData } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { MatrixDescriptionText } from './MatrixDescriptionText';
import { MatrixRowDescription } from './MatrixRowDescription';

type Props = {
    keysDecryption: Record<string, string>;
    matrixData: PortfolioTypologyAssetsMatrixData;
};

export const Matrix: FC<Props> = ({ matrixData: { j, i, matrix }, keysDecryption }) => {
    const processedMatrix = useMemo(
        () => Object.entries(matrix).reduce(
                (result, [key, value]) => {
                    const intermediateResult = [...result];
                    const [jKey, iKey] = key.split(':');

                    const iIndex = i.findIndex((item) => item === iKey);
                    const jIndex = j.findIndex((item) => item === jKey);

                    if (typeof intermediateResult[iIndex]?.[jIndex] === 'number') {
                        intermediateResult[iIndex][jIndex] = value;
                    }

                    return intermediateResult;
                },
                Array.from(new Array(i.length), () => Array.from(new Array(j.length), () => 0)),
            ),
        [matrix, i, j],
    );

    return (
        <Group
gap={4} align="start" w="100%"
wrap="nowrap">
            <Stack w="100%" gap={4}>
                <SimpleGrid cols={j.length} spacing={4} verticalSpacing={4}>
                    {processedMatrix.map((item, iIndx) => item.map((nestedItem, jIndx) => {
                            const iIndex = iIndx;
                            const jIndex = jIndx;

                            return (
                                <Center
                                    key={`matrix${iIndex}${jIndex}`}
                                    h={128}
                                    style={{
                                        borderTopLeftRadius: iIndex === 0 && jIndex === 0 ? 10 : 0,
                                        borderTopRightRadius: iIndex === 0 && jIndex === j.length - 1 ? 10 : 0,
                                        borderBottomLeftRadius: iIndex === i.length - 1 && jIndex === 0 ? 10 : 0,
                                        borderBottomRightRadius:
                                            iIndex === i.length - 1 && jIndex === j.length - 1 ? 10 : 0,
                                        background: `rgb(47, 156, 235, ${Math.min((nestedItem + 10) / 100, 1)})`,
                                    }}
                                >
                                    {nestedItem}
                                    {'%'}
                                </Center>
                            );
                        }),
                    )}
                </SimpleGrid>

                <Group gap={4}>
                    {j.map((col) => (
                        <MatrixDescriptionText key={col}>{keysDecryption[col]}</MatrixDescriptionText>
                    ))}
                </Group>
            </Stack>

            <Stack gap={4} align="start">
                {i.map((row) => (
                    <MatrixRowDescription key={row}>{keysDecryption[row]}</MatrixRowDescription>
                ))}
            </Stack>
        </Group>
    );
};
