import { httpClient } from '@configs/httpclient.config';
import type { CopyWatchlistReq, Watchlist, WatchlistDataReq } from '@modules/Investorpro/types/watchlist.type';
import { WatchlistRow } from '@modules/Investorpro/types/watchlist.type';
import { type AxiosResponse } from 'axios';
import { Security, SecurityType } from '@modules/Investorpro/modules/ScreenersPage/types';
import { ResponseInfo } from '@libs/types';

export const INVESTOR_PROFILE_SERVICE_WATCHLIST_URL = '/moex-datashop-investorprofileservice/api/watchlists/v1/';

// POST /api/watchlists/v1/{watchlist_id} Изменение вотчлиста
export const updateWatchlist = (watchlistId: number, body: { name: string }): Promise<ResponseInfo<Watchlist>> =>
    httpClient.post(`${INVESTOR_PROFILE_SERVICE_WATCHLIST_URL}${watchlistId}`, body).then(({ data }) => data);

// DELETE /api/watchlists/v1/{watchlist_id} Удаление вотчлиста
export const deleteWatchlist = (watchlistId: number): Promise<void> =>
    httpClient.delete(`${INVESTOR_PROFILE_SERVICE_WATCHLIST_URL}${watchlistId}`);

// POST /api/watchlists/v1/{watchlist_id}/securities Добавление инструмента в вотчлист
export const addSecurityToWatchList = (
    id: number,
    data: { isin: string; exchange?: string; type?: SecurityType },
): Promise<ResponseInfo<Required<Watchlist>>> =>
    httpClient
        .post(`${INVESTOR_PROFILE_SERVICE_WATCHLIST_URL}${id}/securities`, {
            ...data,
            exchange: data.exchange || 'moex',
            type: data.type || SecurityType.STOCK,
        })
        .then(({ data }) => data);

// POST /api/watchlists/v1/{watchlist_id}/data Получение данных инструментов вотчлиста
export const getWatchlistData = (
    watchlistId: number,
    body: WatchlistDataReq,
): Promise<AxiosResponse<ResponseInfo<WatchlistRow[]>>> =>
    httpClient.post(`${INVESTOR_PROFILE_SERVICE_WATCHLIST_URL}${watchlistId}/data`, body);

// POST /api/watchlists/v1/copy Копирование вотчлиста
export const copyWatchlist = (body: CopyWatchlistReq): Promise<ResponseInfo<Watchlist>> =>
    httpClient.post(`${INVESTOR_PROFILE_SERVICE_WATCHLIST_URL}copy`, body).then(({ data }) => data);

// POST /api/watchlists/v1/ Получение списка вотчлистов
export const getWatchlists = (): Promise<ResponseInfo<Watchlist[]>> =>
    httpClient.get(INVESTOR_PROFILE_SERVICE_WATCHLIST_URL).then(({ data }) => data);

// POST /api/watchlists/v1/ Создание вотчлиста
export const createWatchlist = (body: { name: string }): Promise<ResponseInfo<Watchlist>> =>
    httpClient.post(INVESTOR_PROFILE_SERVICE_WATCHLIST_URL, body).then(({ data }) => data);

// DELETE /api/watchlists/v1/{watchlist_id}/securities/{security_id} Удаление инструмента из вотчлиста
export const deleteSecurityFromWatchlist = (
    watchlistId: Watchlist['id'],
    securityId: Security['id'],
): Promise<ResponseInfo<Watchlist>> =>
    httpClient
        .delete(`${INVESTOR_PROFILE_SERVICE_WATCHLIST_URL}${watchlistId}/securities/${securityId}`)
        .then(({ data }) => data);
