import { Link, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { WatchlistSidebar } from '@modules/Investorpro/shared/components/WatchlistSidebar';
import { useAuthorize } from '@libs/utils';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, getWatchlistsThunk, type RootState, useAppSelector } from '@store/store';
import { setSelectedWatchlist } from '@store/store/slices/investorpro/investorprofile.slice';
import classNames from 'classnames';
import { getAlertsListThunk } from '@store/store/thunk/investorpro/alerts.thunk';
import { AvatarButton } from '@libs/components/pageBlocks/Navigation/AvatarButton';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DropdownUserPanel } from '@libs/components/pageBlocks/Navigation/DropdownUserPanel';
import { MENU_ITEMS } from '@modules/Investorpro/shared/components/Header/header.constants';
import { getInvestorProLoginParams } from '@libs/utils/hooks/useAuthorize';
import { useOverlay } from '@libs/utils/hooks/useOverlay';
import { Permission, usePermission } from '@libs/utils/hooks/usePermission';

import styles from './styles.module.scss';
import { ReactComponent as InvestorproLogo } from '../../images/svg/InvestorProLogo.svg';
import { Search } from './components/Search';
import { ReactComponent as InvestorproBell } from '../../images/svg/Bell.svg';
import { ReactComponent as InvestorproStar } from '../../images/svg/InvestorProStar.svg';
import { ReactComponent as InvestorproPerson } from '../../images/svg/User.svg';
import { AlertsSidebar } from '../AlertsSidebar';
import { ROOT_PATH } from '../../constants';

export const Header = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { isLoading: isAlertsLoading, fulfilledAlerts } = useAppSelector((state) => state.alerts);
    const notReadAlerts = fulfilledAlerts.filter((alert) => !alert.isRead);
    const location = useLocation();
    const [activeMenuIndex, setActiveMenuIndex] = useState<number>(0);

    const [openedSidebar, setOpenedSidebar] = useState<'watch-list' | 'alerts' | null>(null);
    const closeSidebar = () => setOpenedSidebar(null);
    const alertsSidebarOpen = openedSidebar === 'alerts';
    const isOpenFavoritesSidebar = openedSidebar === 'watch-list';

    useOverlay('side-bar', Boolean(openedSidebar));

    const toggleSidebar = (sidebarType: 'watch-list' | 'alerts') => {
        if (openedSidebar === sidebarType) {
            setOpenedSidebar(null);
        } else {
            setOpenedSidebar(sidebarType);
        }
    };

    const { isAuthorized } = useAuthorize();
    const imageAvatarSelector = useSelector((state: RootState) => state.user.userAvatar);
    const watchlists = useSelector((state: RootState) => state.investorProfile.watchlists);
    const op = useRef(null);
    const opMenu = useRef(null);

    const {
        hasPermissionWatchlist,
        hasPermissionAlert,
        hasPermissionSearch,
        getPermissionByKey,
        isPermissionsInitialized,
    } = usePermission();

    const contextMenuItems = [
        {
            url: `${process.env.REACT_APP_DS_URL}/personal-account?${getInvestorProLoginParams(isAuthorized)}`,
            label: 'Личный кабинет DataShop',
            isExternal: true,
        },
    ];

    useEffect(() => {
        if (isAuthorized && isPermissionsInitialized && hasPermissionAlert) {
            dispatch(getAlertsListThunk());
        }
    }, [dispatch, isAuthorized, openedSidebar, isPermissionsInitialized, location]);

    useEffect(() => {
        if (isAuthorized && isPermissionsInitialized && hasPermissionWatchlist) {
            dispatch(getWatchlistsThunk());
        }
    }, [dispatch, isAuthorized, isPermissionsInitialized]);

    const { pathname } = useLocation();

    useEffect(() => {
        const defaultActiveMenuIndex: number = MENU_ITEMS.findIndex(({ url }) => url && pathname.includes(url));
        setActiveMenuIndex(defaultActiveMenuIndex < 0 ? 0 : defaultActiveMenuIndex);
    }, [pathname]);

    const currentLocation = location.pathname;

    const getDisabledStatusByUrl = (url: string, permission: Permission = Permission.ALL) => {
        const permissionStatus = getPermissionByKey(permission);
        const formattedUrl = ROOT_PATH + url;
        const isPageAlreadyOpened = currentLocation === formattedUrl;

        return !permissionStatus && !isPageAlreadyOpened;
    };

    const searchPlaceholder = hasPermissionSearch ? 'Поиск по тикеру или названию' : 'Ознакомительный режим';

    return (
        <section className={classNames(styles.headerWrapper, 'flex', 'justify-content-center')}>
            <div className={styles.headerPanelWrapper}>
                <div className={classNames('flex align-items-center justify-content-between', styles.headerLinksBlock)}>
                    <InvestorproLogo
                        className={styles.investorproLogo}
                        onClick={() => (window.location.href = `${process.env.REACT_APP_DS_URL}/products/investorpro?${getInvestorProLoginParams(isAuthorized)}`)}
                    />

                    <Search
                        placeholder={searchPlaceholder}
                        className={styles.search}
                        disabled={!hasPermissionSearch}
                        onClick={() => closeSidebar()}
                    />

                    <nav className={styles.headerNavigationList}>
                        <ul className={styles.navigationList}>
                            {MENU_ITEMS.map(({ label, url, items, permission }, index) => (
                                <li
                                    className={classNames(
                                        styles.navigationItem,
                                        {
                                            [styles.navigationItem__active]: activeMenuIndex === index,
                                        },
                                        getDisabledStatusByUrl(url, permission) && styles.disabled,
                                    )}
                                    key={url}
                                >
                                    <Link
                                        to={ROOT_PATH + url}
                                        className={styles.navigationLink}
                                        onMouseEnter={(e: any) => {
                                            if (items?.length) {
                                                (opMenu.current as any).show(e);
                                                (op.current as any).hide(e);
                                            }
                                        }}
                                        onMouseLeave={(e: any) => items?.length && (opMenu.current as any).hide(e)}
                                    >
                                        {label}
                                        {items?.length && (
                                            <OverlayPanel
                                                pt={{
                                                    root: { className: styles.overPanelDropDown },
                                                    content: { className: styles.content },
                                                }}
                                                ref={opMenu}
                                                onClick={(event) => event.stopPropagation()}
                                            >
                                                <ul className={styles.dropdown_list}>
                                                    {items.map(({ url, label }) => (
                                                        <li key={url} className={styles.dropdown_item}>
                                                            <Link to={url}>
                                                                <p>{label}</p>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </OverlayPanel>
                                        )}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>

                <div className={classNames('flex flex-nowrap align-items-center', styles.iconsBtnWrapper)}>
                    <Button
                        className={classNames(styles.iconBtn, styles.alertsButton)}
                        onClick={() => {
                            if (!isAlertsLoading) {
                                toggleSidebar('alerts');
                            }
                        }}
                        text
                        disabled={!hasPermissionAlert}
                    >
                        <InvestorproBell className={styles.menuIcon} />
                        {notReadAlerts.length > 0 && !isAlertsLoading && <span />}
                    </Button>
                    <Button
                        className={styles.iconBtn}
                        text
                        onClick={() => {
                            dispatch(setSelectedWatchlist(watchlists.length ? watchlists[0] : null));
                            toggleSidebar('watch-list');
                        }}
                        disabled={!hasPermissionWatchlist}
                    >
                        <InvestorproStar className={styles.menuIcon} />
                    </Button>
                    {isAuthorized
? (
                        <>
                            <AvatarButton
                                img={imageAvatarSelector}
                                avatarSize={28}
                                onClick={(e: any) => (op.current as any).toggle(e)}
                                className={styles.avatarContainer}
                            />
                            <OverlayPanel
                                pt={{
                                    root: { className: styles.overPanelUser },
                                }}
                                ref={op}
                            >
                                <DropdownUserPanel items={contextMenuItems} />
                            </OverlayPanel>
                        </>
                    )
: (
                        <Button className={styles.iconBtn} text>
                            <InvestorproPerson className={styles.menuIcon} />
                        </Button>
                    )}
                </div>
            </div>
            <AlertsSidebar isOpen={alertsSidebarOpen} closeSidebar={closeSidebar} />
            <WatchlistSidebar
                isOpen={isOpenFavoritesSidebar}
                closeSidebar={() => {
                    dispatch(setSelectedWatchlist(null));
                    closeSidebar();
                }}
            />
        </section>
    );
};
