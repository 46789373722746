import { DataTable, type DataTableProps, type DataTableValueArray } from 'primereact/datatable';
import { Column as ColumnComponent } from 'primereact/column';
import { useRef, type CSSProperties, type ReactNode } from 'react';
import classNames from 'classnames';
import { formatNullableValue } from '@modules/Investorpro/shared/utils/format.util';
import { type ValueType } from '@modules/Investorpro/types/common.type';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ReactComponent as ArrowDownIcon } from '@modules/Investorpro/shared/images/svg/ArrowTooltipDownWhite.svg';

import './styles.scss';
import styles from './styles.module.scss';
import { NONE_SYMBOL } from '../../constants';
import { formatValueByType } from '../../utils/formatValueByType.util';

export type Column<T extends object> = {
    id: string;
    label: string | ReactNode;
    field?: keyof T;
    className?: string;
    type?: ValueType;
    sortable?: boolean;
    style?: CSSProperties;
    align?: 'left' | 'right' | 'center';
    render?: (value: T, option: any) => ReactNode;
    width?: number | string;
    emptyValue?: string;
    measure?: string;
    numberDivider?: string;
    fontWeight?: number;
    fontSize?: number;
    alignEmptyCell?: 'left' | 'right' | 'center';
    trimText?: boolean;
};

type Props = {
    data: DataTableValueArray;
    columns: Array<Column<any>>;
    showHeaders?: boolean;
    separateColumns?: boolean;
} & Omit<DataTableProps<any>, 'data' | 'columns'>;

export const Table = ({
    data,
    columns,
    className,
    showHeaders = true,
    separateColumns = true,
    selection,
    onSelectionChange,
    pt,
    ...props
}: Props) => {
    const tooltipRefs = useRef<any>({});

    return (
        <DataTable
            {...(props as any)}
            className={classNames(
                className,
                'investor-pro-custom-table',
                !showHeaders && 'without-headers',
                separateColumns && 'separate-columns',
                data.length === 0 && 'empty-table',
            )}
            value={data}
            selection={selection}
            onSelectionChange={onSelectionChange}
            showHeaders={showHeaders}
            emptyMessage="Нет данных"
            pt={{
                ...(pt ?? {}),
                header: { className: styles.tableHeader },
            }}
        >
            {columns.map(
                ({
                    id,
                    label,
                    className,
                    type,
                    sortable,
                    field,
                    render,
                    style,
                    align = 'right',
                    width,
                    emptyValue = NONE_SYMBOL,
                    fontSize,
                    numberDivider,
                    fontWeight,
                    measure = '',
                    alignEmptyCell,
                    trimText,
                }) => {
                    const getRenderValue = () => {
                        if (render) {
                            return (option: any, options: any) => {
                                return formatNullableValue(render(option, options), emptyValue);
                            };
                        }

                        return (option: any) => {
                            return formatValueByType({
                                value: option[field!],
                                type,
                                measure,
                                emptyValue,
                                numberDivider,
                            });
                        };
                    };

                    return (
                        <ColumnComponent
                            key={id}
                            field={field as string}
                            body={(option, options) => {
                                const renderedValue = getRenderValue()(option, options);

                                const resultedEmptyCell = alignEmptyCell ?? align;

                                const baseClassNames =
                                    renderedValue === emptyValue
                                        ? classNames(
                                              resultedEmptyCell === 'right' && styles.alignRight,
                                              resultedEmptyCell === 'left' && styles.alignLeft,
                                              resultedEmptyCell === 'center' && styles.alignCenter,
                                          )
                                        : undefined;

                                const combinedClassNames = trimText
                                    ? classNames(baseClassNames, styles.trimText)
                                    : baseClassNames;

                                return (
                                    <>
                                        <span
                                            style={{ fontSize, fontWeight }}
                                            className={combinedClassNames}
                                            onMouseEnter={(e) => {
                                                if (trimText) {
                                                    tooltipRefs.current[id].show(e);
                                                }
                                            }}
                                            onMouseLeave={() => {
                                                if (trimText) {
                                                    tooltipRefs.current[id].hide();
                                                }
                                            }}
                                        >
                                            {renderedValue}
                                        </span>
                                        {trimText && (
                                            <OverlayPanel
                                                ref={(el) => (tooltipRefs.current[id] = el)}
                                                className={'investor-pro-custom-table-overlaylPanel'}
                                            >
                                                <div className={classNames(styles.tooltip, 'flex', 'flex-column')}>
                                                    {renderedValue}
                                                    <span>
                                                        <ArrowDownIcon />
                                                    </span>
                                                </div>
                                            </OverlayPanel>
                                        )}
                                    </>
                                );
                            }}
                            header={label}
                            sortable={sortable}
                            style={{
                                width: width ?? 200,
                                overflow: 'hidden',
                                ...style,
                            }}
                            className={classNames(styles.tableColumn, className)}
                            align={align}
                        />
                    );
                },
            )}
        </DataTable>
    );
};
