import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const ProgressOff: FC<Props> = ({ width = 24, height = 24, color = 'currentColor' }) => (
    <svg
width={width} height={height} fill={color}
viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.6524 6H7.34763C6.74039 6 6.35454 6.64998 6.64532 7.18308L11.2977
        15.7124C11.6009 16.2683 12.3991 16.2683 12.7023 15.7124L17.3547
        7.18308C17.6455 6.64998 17.2596 6 16.6524 6Z"
        />
    </svg>
);
