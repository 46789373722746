import { type Column } from '@modules/Investorpro/shared/components/Table';
import { ValueWithMeasure } from '@modules/Investorpro/modules/BondStockPage/shared/components/ValueWithMeasure';
import { round } from 'lodash';
import { ReactComponent as CheckIcon } from '@modules/Investorpro/shared/images/svg/CheckedGrey.svg';
import { ReactComponent as ClockIcon } from '@modules/Investorpro/shared/images/svg/Clock.svg';
import { classNames } from 'primereact/utils';
import { transformColumnsFromFixedWidthToPercentage } from '@modules/Investorpro/shared/utils/table.utils';
import { formatPrice } from '@modules/Investorpro/shared/utils/format.util';
import { getNumberWithSpacing } from '@libs/utils';

import styles from './styles.module.scss';
import { type DividendData } from '../../../../types';

export const DIVIDENDS_COLUMNS: Array<Column<DividendData>> = transformColumnsFromFixedWidthToPercentage([
    {
        id: 'payoutPeriod',
        field: 'payoutPeriod',
        type: 'string',
        label: 'Период выплаты',
        width: '262px',
        align: 'left',
        render: (item) => <span className={styles.payoutPeriod}>{item.payoutPeriod}</span>,
    },
    {
        id: 'buyUntil',
        field: 'buyUntil',
        label: 'Купить до',
        width: '262px',
        type: 'date',
    },
    {
        id: 'recordDate',
        field: 'recordDate',
        label: 'Дата закрытия реестра',
        width: '262px',
        type: 'date',
    },
    {
        id: 'dividendAmount',
        field: 'dividendAmount',
        label: 'Сумма дивиденда',
        render: (item) => (
            <ValueWithMeasure
                value={round(item.dividendAmount, 2)}
                className={styles.dividendsAmount}
                measureCLassName={styles.dividendsAmountMeasure}
                measure="RUB"
                roundStart="none"
            />
        ),
        width: '262px',
    },
    {
        id: 'yield',
        label: 'Доходность',
        render: (item) => (
            <span className={classNames(styles.dividendCell, 'flex', 'align-items-center')}>
                {formatPrice(item.yield, ',')}
                {item.recordDate && item.dividendAmount ? <CheckIcon /> : null}
            </span>
        ),
        width: '262px',
    },
    {
        id: 'dividendGap',
        field: 'dividendGap',
        label: 'Дивидендный гэп',
        width: '262px',
        type: 'price',
        measure: '%',
    },
    {
        id: 'closingTimeInTradingDays',
        field: 'closingTimeInTradingDays',
        label: 'Время закрытия, торг. дней',
        width: '262px',
        render: (item) => (
            <span className={classNames(styles.dividendCell, 'flex', 'align-items-center')}>
                {item.closingTimeInTradingDays
? (
                    <span>{getNumberWithSpacing(item.closingTimeInTradingDays)}</span>
                )
: (
                    <ClockIcon />
                )}
            </span>
        ),
    },
]);
