import { Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import { type HistoricReturnsResponse, type TopFiveByProfitability } from '../../../../entities/portfolio';
import { ViewEmptyStub } from '../../../../shared/Layout';
import { Table } from '../../../../shared/Table';
import { useHistoricReturnsColumns } from '../../HistoricReturns/useHistoricReturnsColumns';
import { useConfigureColumns } from '../../TypologyAssets/Tables/useConfigureColumns';
import { InfoIcon } from '../ReportIcons/InfoIcon';

type Props = {
    portfolio: HistoricReturnsResponse;
    topFiveReturnOnValue: TopFiveByProfitability[];
    topFiveDividends: TopFiveByProfitability[];
    topFiveTotalReturn: TopFiveByProfitability[];
};

export const ReportPortfolioReturns: FC<Props> = ({
    portfolio,
    topFiveReturnOnValue,
    topFiveDividends,
    topFiveTotalReturn,
}) => {
    const columns = useConfigureColumns();
    const portfolioColumns = useHistoricReturnsColumns(portfolio?.seasons);

    return (
        <Stack gap={33} w={1350} p={5}>
            <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
                <Group align="center" justify="space-between">
                    <Text
fz="45px" fw={400} lh="47px"
style={{ whiteSpace: 'pre-wrap' }}>
                        {'Историческая доходность портфеля'}
                    </Text>
                    <Group
                        gap={12}
                        miw={180}
                        mih={24}
                        px={16}
                        align="center"
                        py={8}
                        style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                    >
                        <InfoIcon />
                        <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                            {'Информация'}
                        </Text>
                    </Group>
                </Group>
                <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px" style={{ whiteSpace: 'pre-wrap' }}>
                    {
                        'В разделе Историческая доходность - портфель вы можете оценить историческую \
доходность портфеля в разбивке по месяцам и годам.\n\nНа основе этих данных \
вы можете дополнительно проанализировать “сезонность” инвестиций и определить, \
какие месяцы были наиболее прибыльными/убыточными для вашего портфеля. \
Дополнительно можно посмотреть, какие инструменты дали максимальную доходность в портфеле.'
                    }
                </Text>
            </Stack>
            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'Портфель'}
                </Text>
                <Table
                    columns={portfolioColumns}
                    data={portfolio?.years || []}
                    highlightOnHover={true}
                    highlightColumnOnHover={true}
                    isFooterVisible={true}
                    emptyStub={<ViewEmptyStub title="Данных по исторической доходности нет" />}
                />
            </Stack>

            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'ТОП 5 по доходности от изменения стоимости'}
                </Text>
                <Table
                    columns={columns}
                    data={topFiveReturnOnValue}
                    striped={true}
                    highlightOnHover={true}
                    emptyStub={<ViewEmptyStub title="Данных по доходности от изменения стоимости нет" />}
                />
            </Stack>

            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'ТОП 5 по доходности от дивидендов и купонов'}
                </Text>
                <Table
                    columns={columns}
                    data={topFiveDividends}
                    striped={true}
                    highlightOnHover={true}
                    emptyStub={<ViewEmptyStub title="Данных по доходности от изменения стоимости нет" />}
                />
            </Stack>

            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'ТОП 5 по совокупной доходности'}
                </Text>
                <Table
                    columns={columns}
                    data={topFiveTotalReturn}
                    striped={true}
                    highlightOnHover={true}
                    emptyStub={<ViewEmptyStub title="Данных по доходности от изменения стоимости нет" />}
                />
            </Stack>
        </Stack>
    );
};
