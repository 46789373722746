import { Box, Stack, Text } from '@mantine/core';
import { type SortingState } from '@tanstack/react-table';
import { type FC, useState } from 'react';

import { ViewError, ViewLoader } from '../../../../shared/Layout';
import { Table } from '../../../../shared/Table';
import { useGetPortfolioInstrumentsQuery } from './useGetPortfolioInstrumentsQuery';
import { useToolsTableColumns } from './useToolsTableColumns';

type Props = {
    portfolioId: string;
};

export const ToolsTable: FC<Props> = ({ portfolioId }) => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const { data, isError, error, isPending } = useGetPortfolioInstrumentsQuery(portfolioId, sorting);
    const columns = useToolsTableColumns();

    if (isError) {
        return (
            <Stack w="100vh">
                <Text fz={19} fw={400} lh="18px">
                    {'Инструменты в портфеле'}
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по инструментам в портфеле" />
            </Stack>
        );
    }

    if (isPending) {
        return (
            <Box w="100%">
                <Text fz={19} fw={400}>
                    {'Инструменты в портфеле'}
                </Text>
                <ViewLoader />
            </Box>
        );
    }

    return (
        <Stack gap={20}>
            <Text fz={19} fw={400} lh="18px">
                {'Инструменты в портфеле'}
            </Text>
            <Table
                columns={columns}
                data={data?.content ?? []}
                striped={true}
                sorting={sorting}
                onSortingChange={setSorting}
            />
        </Stack>
    );
};
