import { type FC } from 'react';

type ReportResponseItem = {
    name: ReportItemType;
    comment: string;
};

export type ReportSettings = {
    inReport: ReportResponseItem[];
    outReport: ReportResponseItem[];
    titleVals: {
        name: string;
        description: string;
        footer: string;
        background: string;
        textColor: string;
        logo: string;
    };
    finalVals: {
        name: string;
        descriptionFinal: string;
        background: string;
        textColor: string;
        logo: string;
    };
    reportSettings: string[];
};

export type ReportItem = {
    id: ReportItemType;
    title: string;
    Component: FC | null;
    SecondComponent?: FC;
    customComment?: string;
};

export enum ReportItemType {
    SUMMARY_ANALYTICS = 'SUMMARY_ANALYTICS',
    PERFORMANCE_VOLATILITY = 'PERFORMANCE_VOLATILITY',
    PERFORMANCE_DRAWDOWN = 'PERFORMANCE_DRAWDOWN',
    CASH_FLOW_CUMULATIVE = 'CASH_FLOW_CUMULATIVE',
    CASH_FLOW_AVERAGE = 'CASH_FLOW_AVERAGE',
    CORRELATION = 'CORRELATION',
    RISK_METRICS = 'RISK_METRICS',
    MAXIMUM_DRAWDOWN = 'MAXIMUM_DRAWDOWN',
    HISTORIC_RETURNS = 'HISTORIC_RETURNS',
    STRESS_TESTS_SUMMARY = 'STRESS_TESTS_SUMMARY',
    DYNAMICS = 'DYNAMICS',
    STRESS_TEST_SCENARIOS = 'STRESS_TEST_SCENARIOS',
    ASSET_ALLOCATION = 'ASSET_ALLOCATION',
    DIVERSIFICATION = 'DIVERSIFICATION',
    PORTFOLIO_AND_BENCHMARK_RETURNS = 'PORTFOLIO_AND_BENCHMARK_RETURNS',
    ASSET_TYPOLOGY = 'ASSET_TYPOLOGY',
    LIQUIDITY = 'LIQUIDITY',
}

export type NewReportItem = {
    id: ReportItemType;
    title: string;
};

export const NEW_REPORT_ITEMS: NewReportItem[] = [
    {
        id: ReportItemType.ASSET_TYPOLOGY,
        title: 'Типология активов',
    },
    {
        id: ReportItemType.ASSET_ALLOCATION,
        title: 'Распределение активов',
    },
    {
        id: ReportItemType.PORTFOLIO_AND_BENCHMARK_RETURNS,
        title: 'Историческая доходность портфеля и бенчмарка',
    },
    {
        id: ReportItemType.HISTORIC_RETURNS,
        title: 'Историческая доходность портфеля',
    },
    {
        id: ReportItemType.SUMMARY_ANALYTICS,
        title: 'Сводная аналитика',
    },
    {
        id: ReportItemType.PERFORMANCE_VOLATILITY,
        title: 'Эффективность (по волатильности)',
    },
    {
        id: ReportItemType.PERFORMANCE_DRAWDOWN,
        title: 'Эффективность (по просадке)',
    },
    {
        id: ReportItemType.CASH_FLOW_AVERAGE,
        title: 'Денежный поток (среднегодовой)',
    },
    {
        id: ReportItemType.CASH_FLOW_CUMULATIVE,
        title: 'Денежный поток (нарастающим итогом)',
    },
    {
        id: ReportItemType.CORRELATION,
        title: 'Корреляция инструментов в портфеле',
    },
    {
        id: ReportItemType.RISK_METRICS,
        title: 'Риск метрики',
    },
    {
        id: ReportItemType.MAXIMUM_DRAWDOWN,
        title: 'Просадки',
    },
    {
        id: ReportItemType.STRESS_TESTS_SUMMARY,
        title: 'Сводная информация по стресс-тестам',
    },
    {
        id: ReportItemType.DYNAMICS,
        title: 'Динамика',
    },
    {
        id: ReportItemType.STRESS_TEST_SCENARIOS,
        title: 'Сценарии стресс-тестирования',
    },
    {
        id: ReportItemType.DIVERSIFICATION,
        title: 'Диверсификация',
    },
    {
        id: ReportItemType.LIQUIDITY,
        title: 'Ликвидность',
    },
];
