import { type SelectedSecurity } from '@libs/types';
import { useMemo, useState } from 'react';

import { type SecurityDatasetColor } from '../../BondsMarketPage/components/BondMarketChartBlock/types';
import { AVAILABLE_FOR_SELECTION_COLORS } from '../constants';

export type SecurityForSelect = Omit<SelectedSecurity, 'color' | 'hasOwnColor'> & {
    color?: SecurityDatasetColor;
};

export const useSelectSecurity = (extraColorsForSelection?: SecurityDatasetColor[]) => {
    const [selectedSecurities, setSelectedSecurities] = useState<SelectedSecurity[]>([]);
    const [availableColors, setAvailableColors] = useState<SecurityDatasetColor[]>([
        ...(extraColorsForSelection ?? []),
        ...AVAILABLE_FOR_SELECTION_COLORS,
    ]);
    const MAX_SELECTED_SECURITIES = 7;

    const selectSecurity = (security: SecurityForSelect) => {
        const foundSecurity = selectedSecurities.find(
            ({ secId, board, market }) =>
                secId === security.secId && board === security.board && market === security.market,
        );

        if (foundSecurity) {
            setSelectedSecurities((prev) => prev.filter((sec) => sec !== foundSecurity));

            if (!foundSecurity.hasOwnColor) {
                setAvailableColors((prev) => [foundSecurity.color, ...prev]);
            }
        } else if (selectedSecurities.length < MAX_SELECTED_SECURITIES) {
            if (security.color) {
                setSelectedSecurities([
                    ...selectedSecurities,
                    {
                        ...security,
                        hasOwnColor: true,
                    } as SelectedSecurity,
                ]);
            } else {
                setSelectedSecurities([
                    ...selectedSecurities,
                    {
                        ...security,
                        hasOwnColor: false,
                        color: availableColors[0],
                    },
                ]);
                setAvailableColors((prev) => prev.slice(1));
            }
        }
    };
    const isMaxSelected = useMemo(() => selectedSecurities.length >= MAX_SELECTED_SECURITIES, [selectedSecurities]);

    return {
        selectedSecurities,
        selectSecurity,
        isMaxSelected,
    };
};
