import { Box, Group, Text } from '@mantine/core';
import { type FC } from 'react';
import { type PortfolioDynamicsResponseData } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { Circle } from '../../../../shared/Icon';

type Props = {
    data?: PortfolioDynamicsResponseData;
    chartColors: Record<string, string>;
};

export const CustomLegend: FC<Props> = ({ data, chartColors }) => (
    <Group gap={40}>
        {data?.performances.map((entry) => (
            <Group key={`performance-${entry.name}`} gap={8}>
                <Box w={20} h={2} style={{ background: chartColors[entry.name] ?? '#2F9CEB' }} />
                <Text
lh="16px" fw={400} fz={14}
style={{ color: '#8A96A6' }}>
                    {entry.name}
                </Text>
            </Group>
        ))}
        {data?.events.find((el) => el.type === 'DIVIDENDS')
? (
            <Group key="event-dividends" gap={8}>
                <Circle width={8} height={8} color="#2F9CEB" />
                <Text
lh="16px" fw={400} fz={14}
style={{ color: '#8A96A6' }}>
                    {'Дивиденды'}
                </Text>
            </Group>
        )
: null}

        {data?.events.find((el) => el.type === 'ISSUER_REPORTS')
? (
            <Group key="event-report" gap={8}>
                <Circle width={8} height={8} color="#FFAA00" />
                <Text
lh="16px" fw={400} fz={14}
style={{ color: '#8A96A6' }}>
                    {'Отчетность эмитентов'}
                </Text>
            </Group>
        )
: null}

        {data?.events.find((el) => el.type === 'KEY_RATE')
? (
            <Group key="event-key-rate" gap={8}>
                <Circle width={8} height={8} color="#422feb" />
                <Text
lh="16px" fw={400} fz={14}
style={{ color: '#8A96A6' }}>
                    {'Ключевая ставка'}
                </Text>
            </Group>
        )
: null}
    </Group>
);
