import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState, type ReactNode } from 'react';
import { Dropdown, Loader } from '@libs/components';
import { ReactComponent as DownArrow } from '@modules/Investorpro/shared/images/svg/ArrowDownGray.svg';
import { TooltipContent, type TooltipContentType } from '@modules/Investorpro/shared/components/TooltipContent';
import { round } from 'lodash';
import { compactNumber, getNumberWithSpacing } from '@libs/utils';
import { PositiveNegativeValue } from '@modules/Investorpro/shared/components/PositiveNegativeValue';
import { type ProfitPeriod } from '@libs/types/instrument.type';
import { ValueWithMeasure } from '@modules/Investorpro/modules/BondStockPage/shared/components/ValueWithMeasure';
import { useAppSelector } from '@store/store';

import styles from './styles.module.scss';
import { HeatMap } from './components/HeatMap';
import {
    InstrumentType,
    type Option,
    type HeatColorInterval,
    type InstrumentCategory,
    type InstrumentIndustryData,
} from '../../types';
import {
    BONDS_CATEGORY_OPTIONS,
    INSTRUMENT_TYPE_OPTIONS,
    STOCK_CATEGORY_OPTIONS,
    TOTAL_LOADING_KEY,
} from '../../constants';

type Props = {
    title: string;
    controls: ReactNode;
    instrumentType: Option<InstrumentType>;
    setInstrumentType: (type: Option<InstrumentType>) => void;
    valueKey: string;
    data: unknown[];
    heatColors: HeatColorInterval[];
    loading: boolean;
    groupKey: string;
    updatedDate: string | null;
    labelKey: string;
    instrumentCategory: Option<InstrumentCategory> | null;
    measureTitle?: string;
    rangeBarWidth?: number;
    withRangeBarLabel?: boolean;
    setInstrumentCategory: (category: Option<InstrumentCategory> | null) => void;
    extraTooltipContent?: (data: InstrumentIndustryData) => TooltipContentType[];
    profitCode: ProfitPeriod;
};

type TooltipProps = {
    offsetX: number;
    arrowOffsetX: number;
    visible: boolean;
    title: string;
    available: boolean;
};

export const HeatMapContainer = ({
    title,
    controls,
    instrumentType,
    setInstrumentType,
    data,
    valueKey,
    profitCode,
    heatColors: rawHeatColors,
    groupKey,
    loading: extraLoading,
    extraTooltipContent,
    instrumentCategory,
    setInstrumentCategory,
    measureTitle,
    updatedDate,
    withRangeBarLabel = false,
    rangeBarWidth = 40,
    labelKey,
}: Props) => {
    const totalLoading = useAppSelector((store) => store.loading.loadingKeys.includes(TOTAL_LOADING_KEY));
    const loading = totalLoading || extraLoading;
    const getMarkerIntervalTitle = (left: number, right: number, includeLeft: boolean, includeRight: boolean) => {
        if (left === right) return `= ${left}%`;

        const leftBoundCompareSymbol = includeLeft ? '≥' : '>';
        const rightBoundCompareSymbol = includeRight ? '≤' : '<';
        const leftBound = left !== -Infinity ? `${leftBoundCompareSymbol} ${getNumberWithSpacing(left)}%` : undefined;
        const rightBound =
            right !== Infinity ? `${rightBoundCompareSymbol} ${getNumberWithSpacing(right)}%` : undefined;

        return [leftBound, rightBound].filter(Boolean).join(' и ');
    };

    const [tooltipData, setTooltipData] = useState<TooltipProps>({
        offsetX: 0,
        arrowOffsetX: 0,
        visible: false,
        title: '',
        available: false,
    });

    const ref = useRef<HTMLDivElement>(null);
    const RANGE_BAR_GAP = 2;
    const tooltipPriceMeasureLabel = instrumentType.code === InstrumentType.STOCKS ? 'RUB' : '%';

    const handleMouseMove = (title: string, index: number) => {
        setTooltipData((prev) => ({
            ...prev,
            title,
            arrowOffsetX: index * rangeBarWidth + index * RANGE_BAR_GAP + rangeBarWidth / 2,
            available: true,
        }));
    };

    const handleMouseOut = () => {
        setTooltipData((prev) => ({
            ...prev,
            visible: false,
            available: false,
        }));
    };

    useEffect(() => {
        if (tooltipData.available) {
            const maxBlockWidth = rangeBarWidth * rawHeatColors.length + RANGE_BAR_GAP * (rawHeatColors.length - 1);
            const tooltipWidth = ref.current?.clientWidth ?? 0;
            const delta = Math.max(0, tooltipData.arrowOffsetX + tooltipWidth / 2 - maxBlockWidth);

            setTooltipData((prev) => ({
                ...prev,
                visible: true,
                offsetX: prev.arrowOffsetX - delta,
            }));
        }
    }, [tooltipData.available, tooltipData.title]);

    const getMarkerIntervalLabel = (left: number, right: number, includeLeft: boolean, includeRight: boolean) => {
        if ((includeLeft && includeRight) || (!includeLeft && !includeRight)) {
            return left + (right - left) / 2 + '%';
        }

        if (includeLeft) {
            return left + '%';
        }

        if (includeRight) {
            return right + '%';
        }

        return '';
    };

    const heatColors = useMemo(() => {
        return rawHeatColors
            .map(
                (
                    { leftBound, rightBound, color, leftBoundInclude = true, rightBoundInclude = false, title },
                    index,
                    arr,
                ) => {
                    const left = leftBound ?? arr[index - 1]?.rightBound ?? -Infinity;
                    const right = rightBound ?? arr[index + 1]?.leftBound ?? Infinity;

                    return {
                        leftBound: left,
                        rightBound: right,
                        color,
                        leftBoundInclude,
                        label: getMarkerIntervalLabel(left, right, leftBoundInclude, rightBoundInclude),
                        rightBoundInclude,
                        title: title ?? getMarkerIntervalTitle(left, right, leftBoundInclude, rightBoundInclude),
                    };
                },
            )
            .sort((a, b) => a.leftBound - b.leftBound);
    }, [rawHeatColors]);

    const getHeatColor = (item: Record<string, unknown>) => {
        const value = item[labelKey] as number;

        return (
            heatColors.find(
                ({ leftBound, rightBound, leftBoundInclude, rightBoundInclude }) => (leftBoundInclude ? value >= leftBound : value > leftBound) &&
                    (rightBoundInclude ? value <= rightBound : value < rightBound),
            )?.color ?? '#A5B1C0'
        );
    };

    const renderTooltip = useCallback(
        (data: InstrumentIndustryData) => {
            const [capitalizationValue, decimals] = compactNumber(round(data.capitalization, 2));
            const intrumentCode = instrumentType.code === InstrumentType.STOCKS ? 'secId' : 'isin';

            return (
                <TooltipContent
                    title={data.shortName}
                    className={styles.tooltip}
                    content={[
                        {
                            title: data[intrumentCode as keyof InstrumentIndustryData],
                            content: (
                                <div className={classNames(styles.tooltip_profitWrapper, 'flex')}>
                                    <ValueWithMeasure
                                        className={styles.tooltip_price}
                                        value={data.close}
                                        roundStart="none"
                                        measure={tooltipPriceMeasureLabel}
                                        isPercent={tooltipPriceMeasureLabel === '%'}
                                    />
                                    <PositiveNegativeValue
                                        positivePrefix="+"
                                        value={round(+data[profitCode], 2)}
                                        measure="%"
                                    />
                                </div>
                            ),
                        },
                        { divider: true },
                        {
                            title: 'Капитализация',
                            content: `${capitalizationValue} ${decimals} руб.`,
                        },
                        ...(extraTooltipContent?.(data) ?? []),
                    ]}
                />
            );
        },
        [profitCode, extraTooltipContent, tooltipPriceMeasureLabel],
    );

    const noData = !loading && data.length === 0;

    return (
        <div className={classNames(styles.heatMapContainer, 'flex', 'flex-column')}>
            <div className={classNames(styles.titleWrapper, 'flex', 'justify-content-between', 'align-items-center')}>
                <h2>{title}</h2>
                <div className={classNames(styles.controls, 'flex')}>
                    <Dropdown
                        optionLabel="name"
                        value={instrumentType}
                        options={INSTRUMENT_TYPE_OPTIONS}
                        onChange={(e) => setInstrumentType(e.value)}
                    />
                    <Dropdown
                        optionLabel="name"
                        value={instrumentCategory}
                        onChange={(e) => setInstrumentCategory(e.value)}
                        options={instrumentType.code === 'stocks' ? STOCK_CATEGORY_OPTIONS : BONDS_CATEGORY_OPTIONS}
                    />
                    {controls}
                </div>
            </div>
            <div
                className={classNames(
                    styles.heatMapWrapper,
                    'flex',
                    'justify-content-center',
                    'align-items-center',
                    noData && styles.heatMapWrapper__noData,
                )}
            >
                {!noData && (
                    <HeatMap
                        valueKey={valueKey}
                        getColor={getHeatColor}
                        data={data}
                        labelKey={'secId'}
                        measureKey={labelKey}
                        width={1850}
                        height={620}
                        renderTooltip={renderTooltip}
                        groups={groupKey ? [groupKey] : undefined}
                    />
                )}

                {extraLoading && !totalLoading && <Loader isLoading={extraLoading} />}

                {noData && <span className={styles.heatMapWrapper_emptyTitle}>Нет данных</span>}
            </div>
            <div
                className={classNames(
                    'flex',
                    'justify-content-between',
                    'align-items-center',
                    styles.heatMapColorMarkersWrapper,
                )}
            >
                <span>{updatedDate}</span>
                <div className="relative">
                    <div
                        className={classNames(
                            styles.colorRangeTooltip_wrapper,
                            !tooltipData.visible && styles.colorRangeTooltip_wrapper__hidden,
                        )}
                    >
                        <div
                            className={styles.colorRangeTooltip_mark}
                            ref={ref}
                            style={{
                                left: tooltipData.offsetX,
                            }}
                        >
                            {tooltipData.title}
                        </div>
                        <DownArrow
                            className={styles.colorRangeTooltip_arrow}
                            style={{ left: tooltipData.arrowOffsetX }}
                        />
                    </div>
                    <div className={classNames('flex', 'justify-content-end', styles.colorRange)}>
                        {heatColors.map(({ title, color, label }, index) => (
                            <div
                                key={color}
                                onMouseEnter={() => handleMouseMove(measureTitle ? `${measureTitle} ${title}` : title, index)}
                                onMouseLeave={handleMouseOut}
                                className={classNames(
                                    styles.heatMapColorMarker,
                                    'heat-map-color-marker',
                                    'flex',
                                    'justify-content-center',
                                    'align-items-center',
                                )}
                                style={{ backgroundColor: color, width: rangeBarWidth }}
                            >
                                {withRangeBarLabel && label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
