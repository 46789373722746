import { ActionIcon } from '@mantine/core';
import { type SortDirection } from '@tanstack/react-table';
import { type FC } from 'react';

import { Sort, SortAsc, SortDesc } from '../../shared/Icon';
import styles from './SortButton.module.css';

type Props = {
    sortDirection: SortDirection | false;
    toggleSort: () => undefined | ((event: unknown) => void);
};

export const SortButton: FC<Props> = ({ sortDirection, toggleSort }) => {
    let Icon;

    if (sortDirection === false) {
        Icon = <Sort width={16} height={16} />;
    } else if (sortDirection === 'desc') {
        Icon = <SortDesc width={16} height={16} />;
    } else {
        Icon = <SortAsc width={16} height={16} />;
    }

    return (
        <ActionIcon size={16} onClick={toggleSort()} className={styles.sortButton}>
            {Icon}
        </ActionIcon>
    );
};
