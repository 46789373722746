import * as yup from 'yup';

export const portfolioToolSchema = yup.object({
    name: yup.string().required('Поле должно быть заполнено').default(''),
    ticker: yup.string().required('Поле должно быть заполнено').default(''),
    percent: yup.number().min(0).max(100).default(0),
    value: yup.number().min(0).max(999999999999).default(0),
});

export type PortfolioToolValues = yup.InferType<typeof portfolioToolSchema>;

export const portfolioFormSchema = yup.object().shape({
    name: yup.string()
        .required('Поле должно быть заполнено')
        .default('')
        .max(50, 'Название портфеля должно содержать не более 50 символов')
        .trim(),
    benchmarkUuid: yup.string().required('Поле должно быть заполнено').default(''),
    value: yup.number()
        .default(0)
        .min(1, 'Стоимость портфеля должна быть больше 0')
        .max(10000000000, 'Стоимость портфеля должна быть целым числом не более 10 000 000 000')
        .typeError('Поле должно быть заполнено'),
    tickers: yup.array().of(portfolioToolSchema).default([]),
});

export type PortfolioFormValues = yup.InferType<typeof portfolioFormSchema>;
