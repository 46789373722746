import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const ProgressOn: FC<Props> = ({ width = 24, height = 24, color = 'currentColor' }) => (
    <svg
width={width} height={height} fill={color}
viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.6524 17H7.34763C6.74039 17 6.35454 16.35 6.64532 15.8169L11.2977 7.28758C11.6009
        6.73169 12.3991 6.73169 12.7023 7.28758L17.3547 15.8169C17.6455 16.35 17.2596 17 16.6524 17Z"
        />
    </svg>
);
