import { type BondMarketData } from '@libs/types';
import { PositiveNegativeValue } from '@modules/Investorpro/shared/components/PositiveNegativeValue';
import { ValueWithMeasure } from '@modules/Investorpro/modules/BondStockPage/shared/components/ValueWithMeasure';

import { type TableWithCheckBoxColumn } from '../../../../../shared/components/TableWithCheckBox';
import styles from '../../styles.module.scss';

export const TOP_SECURITIES_COLUMNS: Array<TableWithCheckBoxColumn<BondMarketData>> = [
    {
        id: 'name',
        field: 'name',
        align: 'left',
        label: 'Название',
        width: '140px',
        render: (obj) => (
            <span style={{ width: 140 }} className={styles.noTextOverflow}>
                {obj.name}
            </span>
        ),
    },
    {
        id: 'price',
        field: 'price',
        align: 'right',
        label: 'Цена',
        type: 'price',
        width: '118px',
    },
    {
        id: 'vol',
        align: 'right',
        label: 'Объем',
        width: '167px',
        field: 'vol',
        render: (obj) => obj.prevTradeDayPrice !== null && (
                <ValueWithMeasure value={obj.vol} measure="RUB" measureCLassName={styles.tableValueMeasureWrapper} />
            ),
    },
    {
        id: 'changeRelative',
        align: 'right',
        label: 'Изм,. %',
        width: '112px',
        render: (obj) => obj.prevTradeDayPrice !== null && (
                <PositiveNegativeValue positivePrefix="+" value={obj.changeRelative} measure="%" />
            ),
    },
];
