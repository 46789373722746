/* eslint-disable */
// @ts-nocheck * /
import { isObject, toFont, valueOrDefault } from "chart.js/helpers";
import { TreemapController } from "chartjs-chart-treemap";
import {group, normalizeTreeToArray, getGroupKey} from './utils.ts';
import squarify from './squarify.ts';
import {arrayNotEqual, scaleRect} from './helpers.ts';
import { checkIsContainer, parseBorderWidth, shouldDrawCaption } from "./element.ts";


function buildData(tree, dataset, keys, mainRect) {
    const treeLeafKey = dataset.treeLeafKey || '_leaf';
    if (isObject(tree)) {
      tree = normalizeTreeToArray(keys, treeLeafKey, tree);
    }
    const groups = dataset.groups || [];
    const glen = groups.length;
    const offset = valueOrDefault(dataset.padding, 0) + valueOrDefault(dataset.captions.spacing, 0)
    const captions = dataset.captions || {};
    const font = toFont(captions.font);
    const padding = valueOrDefault(captions.padding, 3);
    const captionHeight = captions.height || (font.lineHeight + padding * 2);
  
    function recur(treeElements, gidx, rect, parent, gs) {
      const g = getGroupKey(groups[gidx]);
      const pg = (gidx > 0) && getGroupKey(groups[gidx - 1]);
      const gdata = group(treeElements, g, keys, treeLeafKey, pg, parent, groups.filter((item, index) => index <= gidx));
      const gsq = squarify(gdata, rect, keys, g, gidx, gs);
     
      const ret = gsq.slice();
      if (gidx < glen - 1) {
        gsq.forEach((sq) => {
          const bw = parseBorderWidth(dataset.borderWidth, sq.w / 2, sq.h / 2);
          const subRect = {
            ...rect,
            x: sq.x + offset  + bw.l,
            y: sq.y + offset + bw.t,
            w: sq.w - offset * 2 - bw.l - bw.r,
            h: sq.h - offset * 2 - bw.t - bw.b,
          };
          if (shouldDrawCaption(subRect, captions)) {
            subRect.y += captionHeight;
            subRect.h -= captionHeight;
          }
          gdata.forEach((gEl) => {
            ret.push(...recur(gEl.children, gidx + 1, subRect, sq.g, sq.s));
          });
        });
      }
      return ret;
    }
  
    return glen
      ? recur(tree, 0, mainRect)
      : squarify(tree, mainRect, keys);
  }

export class CustomTreemapController extends TreemapController {
    static id = 'customTreemap'
    update(mode) {
        const dataset = this.getDataset();
        const {data} = this.getMeta();
        const idKey = dataset.idKey || 'index'
        let groups = dataset.groups || [];
        const keys = [dataset.key || ''].concat(dataset.sumKeys || []);
        let tree = dataset.tree = dataset.tree || dataset.data || [];
    
        if (mode === 'reset') {
          // reset is called before 2nd configure and is only called if animations are enabled. So wen need an extra configure call here.
          this.configure();
        }
        
    
        if (this._rectChanged || arrayNotEqual(this._keys, keys) || arrayNotEqual(this._groups, groups) || this._prevTree !== tree) {
          this._groups = groups.slice();
          this._keys = keys.slice();
          this._prevTree = tree;
          this._rectChanged = false;
    
          dataset.data = buildData(tree, dataset, this._keys, this._rect);
          
          if (groups.length > 0) {
            dataset.data.filter((data) => checkIsContainer(data)).forEach((item, i) => {
                item._data.children.forEach((child) => {
                    const font =  toFont(dataset.captions.font);
                    const containenrPadding = dataset.captions.spacing + dataset.padding
                    const padding = valueOrDefault(dataset.captions.padding, 3);

                    const el = dataset.data.find(el => el._data[idKey] === child[idKey])
                    if (!el) return

                    const isLeft = Math.round(item.x + containenrPadding) === Math.round(el.x)
                    const isBottom = Math.round(item.y + item.h - containenrPadding) === Math.round(el.y + el.h)
                    const isRight = Math.round(item.x + item.w - containenrPadding) === Math.round(el.x + el.w)
                    const isTop = Math.round(item.y + padding * 2 + containenrPadding + font.lineHeight) === Math.round(el.y)
                    
                    el.isTopLeft = isLeft && isTop
                    el.isTopRight = isRight && isTop
                    el.isBottomLeft = isLeft && isBottom
                    el.isBottomRight = isRight && isBottom
                    
               })
               
            })
          } else {
             dataset.data.forEach((item) => {
                const { x, y, w, h } = item
                    const isLeft = x === 0
                    const isBottom = Math.round(y + h) === Math.round(this.chart.chartArea.bottom)
                    const isRight = Math.round(x + w) === Math.round(this.chart.chartArea.right)
                    const isTop = y === 0
        
                    item.isTopLeft = isLeft && isTop
                    item.isTopRight = isRight && isTop
                    item.isBottomLeft = isLeft && isBottom
                    item.isBottomRight = isRight && isBottom

             })
          }
          // @ts-ignore using private stuff
          this._dataCheck();
          // @ts-ignore using private stuff
          this._resyncElements();
        }
    
        this.updateElements(data, 0, data.length, mode);
      }

      updateElements(rects, start, count, mode) {
        const reset = mode === 'reset';
        const dataset = this.getDataset();
        const firstOpts = this._rect.options = this.resolveDataElementOptions(start, mode);
        const sharedOptions = this.getSharedOptions(firstOpts);
        const includeOptions = this.includeOptions(mode, sharedOptions);
        const {xScale, yScale} = this.getMeta(this.index);
        
        for (let i = start; i < start + count; i++) {
          const options = sharedOptions || this.resolveDataElementOptions(i, mode);
          const isContainer = checkIsContainer(dataset.data[i])
          const sp = isContainer ? options.captions.spacing : options.spacing;
          const properties = scaleRect(dataset.data[i], xScale, yScale, sp);
          if (reset) {
            properties.width = 0;
            properties.height = 0;
          }
    
          if (includeOptions) {
            properties.options = options;
          }
          this.updateElement(rects[i], i, properties, mode);
        }
    
        this.updateSharedOptions(sharedOptions, mode, firstOpts);
      }
}
