import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const DividendsLabel: FC<Props> = ({ width = 22, height = 22, color = 'currentColor' }) => (
    <svg
width={width} height={height} viewBox="0 0 1.76 1.76"
xmlns="http://www.w3.org/2000/svg">
        <path
            cx="32"
            cy="32"
            r="30"
            fill="#FFFFFF"
            d="M1.705 0.88A0.825 0.825 0 0 1 0.88 1.705A0.825 0.825 0 0 1 0.055 0.88A0.825 0.825 0 0 1 1.705 0.88z"
        />
        <path
            d="M0.88 0.055C0.424 0.055 0.055 0.424 0.055 0.88s0.369 0.825 0.825 0.825 0.825 -0.369 0.825
            -0.825S1.336 0.055 0.88 0.055m0.301 1.075q-0.087 0.149 -0.268 0.149H0.57V0.481h0.344q0.074
            0.001 0.124 0.017 0.084 0.028 0.136 0.101 0.042 0.06 0.057 0.129c0.015 0.069 0.015 0.09
            0.015 0.132q0 0.159 -0.064 0.27"
            fill={color}
        />
        <path
            d="M0.886 0.62h-0.154v0.521h0.154q0.118 0 0.165 -0.116 0.025 -0.064 0.025 -0.152 0 -0.122
            -0.038 -0.187 -0.038 -0.065 -0.152 -0.065"
            fill={color}
        />
    </svg>
);
