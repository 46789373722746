import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';

import { type Security } from '../../../modules/ScreenersPage/types';
import { Watchlist } from '@modules/Investorpro/types/watchlist.type';

export const INVESTOR_PROFILE_SECURITIES_SERVICE_URL = '/moex-datashop-investorprofileservice/api/securities/v1/';

export const getSecurities = (): Promise<AxiosResponse<{ securities: Security[]; watchlists: Watchlist[] }>> =>
    httpClient.get(INVESTOR_PROFILE_SECURITIES_SERVICE_URL).then(({ data }) => data);
