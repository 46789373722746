import { type Refs, type PaddingKey, type SetScrollAttributesForElementArgs } from './types';

export const updatePaddingOfElements = (refs: Refs, paddingKey: PaddingKey, cb: (padding: number) => number) => {
    refs.forEach((ref) => {
        if (ref.current) {
            const computedStyle = getComputedStyle(ref.current);
            const previousPadding = Number(computedStyle[paddingKey]?.split('px')?.[0]) ?? 0;
            const newPaddingRight = cb(previousPadding);

            ref.current.style[paddingKey] = `${newPaddingRight}px`;
        }
    });
}

export const setScrollAttributesForElement = ({
    ref,
    hasOverlay,
    horizontalScroll,
    verticalScroll,
}: SetScrollAttributesForElementArgs) => {
    if (ref.current) {
        ref.current.setAttribute('data-document-has-horizontal-scroll', horizontalScroll.toString());
        ref.current.setAttribute('data-document-has-vertical-scroll', verticalScroll.toString());
        ref.current.setAttribute('data-document-has-overlay', hasOverlay.toString());
    }
}

type Overflow = ElementCSSInlineStyle['style']['overflow']

export const setBodyOverflow = (value: Overflow) => {
    document.body.style.overflow = value;
}

export const setRootOverflow = (value: Overflow) => {
    setBodyOverflow(value)

    const el = document.getElementById('root')

    if (el) {
        el.style.overflow = value;
    }
}
