import { type FC } from 'react';

export const OtherIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path
                d="M8.86293 7.49997C8.86295 6.1745 9.93746 5.1 11.2629 5.1H21.2831C21.9196 5.1 22.5301
                5.35286 22.9802 5.80294L26.7228 9.54556C27.1729 9.99566 27.4257 10.6061 27.4257
                11.2427L27.4255 23.5C27.4255 24.8255 26.351 25.9 25.0255 25.9H11.2627C9.93721
                25.9 8.86269 24.8255 8.86271 23.5L8.86293 7.49997Z"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
                d="M27.0254 10.5H22.3254C22.1597 10.5 22.0254 10.3657 22.0254 10.2V5.5"
                stroke="#636E80"
                strokeWidth="1.2"
                strokeLinecap="round"
            />
            <circle
cx="13.0911" cy="16.5657" r="1.46572"
stroke="#636E80" strokeWidth="1.2" />
            <circle
cx="18.0911" cy="16.5657" r="1.46572"
stroke="#636E80" strokeWidth="1.2" />
            <circle
cx="23.0911" cy="16.5657" r="1.46572"
stroke="#636E80" strokeWidth="1.2" />
        </g>
    </svg>
);
