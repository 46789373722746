import React, { Suspense, useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AuthProvider, type TAuthConfig, type TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce';
import { generateState, getUuid } from '@libs/utils';
import { useConfig } from '@dynamic-env/useConfig';
import { defaultConfig, dynamicConfigUrl } from '@dynamic-env/config';
import { httpClient } from '@configs/httpclient.config';
import { getInvestorProLoginParams } from '@libs/utils/hooks/useAuthorize';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { Loader } from '@libs/components';

import { router } from './routes';
import { store } from './store/store';

const configLoadingErrorMessage =
    'Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)';

function App() {
    const { config, setConfig } = useConfig();

    const [configLoadingState, setConfigLoadingState] = useState<'loading' | 'ready' | 'error'>('loading');

    useEffect(() => {
        if (config.NODE_ENV === 'development') {
            setConfig(defaultConfig);
            setConfigLoadingState('ready');
        } else {
            httpClient
                .get(dynamicConfigUrl)
                .then((response: any) => {
                    setConfig(response.data);
                    console.debug('Global config fetched: ', response.data);
                    setConfigLoadingState('ready');
                })
                .catch((e: any) => {
                    console.log(
                        configLoadingErrorMessage,
                        `Have you provided the config file '${dynamicConfigUrl}'?`,
                        e,
                    );
                    setConfigLoadingState('error');
                });
        }
    }, [setConfig]);

    const onRefreshTokenExpire = (event: TRefreshTokenExpiredEvent) => window.confirm('Session expired. Refresh page to continue using the site?') && event.logIn();

    const authConfig: TAuthConfig = {
        clientId: process.env.REACT_APP_CLIENT_ID || '',
        authorizationEndpoint: process.env.REACT_APP_AUTH_ENDPOINT || '',
        tokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT || '',
        logoutEndpoint: process.env.REACT_APP_LOGOUT_ENDPOINT || '',
        redirectUri: window.location.href.split('?')[0],
        logoutRedirect: `${process.env.REACT_APP_DS_URL}/products/investorpro?${getInvestorProLoginParams(false)}`,
        scope: process.env.REACT_APP_SCOPE || '',
        onRefreshTokenExpire,
        autoLogin: false,
        state: generateState(),
        extraAuthParameters: {
            nonce: getUuid(),
            response_mode: 'query',
            response_type: 'code',
        },
    };

    return (
        <Provider store={store}>
            <AuthProvider authConfig={authConfig}>
                <Suspense fallback={<Loader isLoading withOverlay />}>
                    <RouterProvider router={router} />
                </Suspense>
            </AuthProvider>
        </Provider>
    );
}

export default App;
