import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const Search: FC<Props> = ({ width = 24, height = 24, color = 'currentColor' }) => (
    <svg
width={width} height={height} viewBox="0 0 24 24"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
cx="11" cy="11" r="7"
stroke={color} strokeWidth="1.8" strokeLinecap="round" />
        <path
            d="M16.6364 15.3636L16 14.7272L14.7272 16L15.3636 16.6364L16.6364 15.3636ZM19.3636 20.6364C19.7151
            20.9879 20.2849 20.9879 20.6364 20.6364C20.9879 20.2849 20.9879 19.7151 20.6364 19.3636L19.3636
            20.6364ZM15.3636 16.6364L19.3636 20.6364L20.6364 19.3636L16.6364 15.3636L15.3636 16.6364Z"
            fill={color}
        />
    </svg>
);
