import { Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { type CorrelationTools } from '@modules/Investorpro/modules/PortfolioPage/entities';

const columnHelper = createColumnHelper<CorrelationTools>();

export const useDiversificationColumns = () => useMemo(
        () => [
            columnHelper.accessor('name', {
                id: 'name',
                header: 'Инструмент',
                enableSorting: false,
                cell: (props) => (
                    <Text
fz={14} lh="16px" fs="normal"
c="#14171F" ta="start">
                        {props?.getValue() ?? ''}
                    </Text>
                ),
            }),
            columnHelper.accessor('correlation', {
                id: 'correlation',
                header: 'Корреляция',
                enableSorting: false,
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                cell: (props) => (
                    <Text
fz={14} lh="16px" fs="normal"
c="#14171F" ta="end">
                        {props?.getValue().toFixed(3).replace(/\./g, ',') ?? ''}
                    </Text>
                ),
            }),
            columnHelper.accessor('correlationPair', {
                id: 'correlationPair',
                header: 'Корреляционная пара',
                enableSorting: false,
                cell: (props) => (
                    <Text
fz={14} lh="16px" fs="normal"
c="#14171F" ta="start">
                        {props?.getValue() ?? ''}
                    </Text>
                ),
            }),
        ],
        [],
    );
