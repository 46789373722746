import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

type IState = {
    isLoading: boolean;
    loadingKeys: string[];
};

const initialState: IState = {
    isLoading: false,
    loadingKeys: [],
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        showLoader: (state) => {
            state.isLoading = true;
        },
        hideLoader: (state) => {
            state.isLoading = false;
        },
        addKeyToLoading: (state, action: PayloadAction<string>) => {
            state.loadingKeys.push(action.payload);
        },
        removeKeyFromLoading: (state, action: PayloadAction<string>) => {
            state.loadingKeys = state.loadingKeys.filter((key) => key !== action.payload);
        },
    },
});

export const loadingReducer = loadingSlice.reducer;

export const { showLoader, hideLoader, addKeyToLoading, removeKeyFromLoading } = loadingSlice.actions;
