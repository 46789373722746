import { type AxiosResponse } from 'axios';

import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const saveReportSettings: ApiFactory<
    ApiEndpoint<AxiosResponse, { options: string }, { id: string }>
> = (client) => async ({ signal, data: { options }, params: { id } }) => {
    const res = await client.post<AxiosResponse>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/reports/options?portfolioId=${id}`,
        options,
        { signal },
    );

    return res;
};
