import { Group, Select, Stack, Text } from '@mantine/core';
import { type FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ChevronDown } from '../../../shared/Icon';
import { ViewError } from '../../../shared/Layout';
import { type InferParams } from '../../../shared/router';
import { PerformanceChart } from './Chart/PerformanceChart';
import { PerformanceTable } from './Table/PerformanceTable';
import { useGetPortfolioPerformanceQuery } from './useGetPortfolioPerformanceQuery';

const modelVariants = [
    { value: 'STRESS_TEST_MODEL', label: 'Стресс-тест модель' },
    { value: 'VOLATILITY_MODEL', label: 'Риск модель' },
];

export const Performance: FC = () => {
    const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;
    const [performanceType, setPerformanceType] = useState('STRESS_TEST_MODEL');
    const { data, isError, error, isPending } = useGetPortfolioPerformanceQuery(portfolioId, performanceType);

    if (isError) {
        return (
            <Stack>
                <Text fz={19} lh="18px">
                    {'Параметры эффективности'}
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по эффективности" />
            </Stack>
        );
    }

    return (
        <Group
gap={42} w="100%" wrap="nowrap"
align="flex-start">
            <Stack flex={1} gap={24}>
                <Group
justify="space-between" align="center" h={36}
pr={18}>
                    <Text fz={19} lh="18px">
                        {'Кривая эффективности'}
                    </Text>
                    <Select
                        w={210}
                        value={performanceType ?? 'STRESS_TEST_MODEL'}
                        onChange={(value) => setPerformanceType(value ?? 'STRESS_TEST_MODEL')}
                        data={modelVariants}
                        allowDeselect={false}
                        styles={{ input: { paddingRight: '36px' } }}
                        rightSection={<ChevronDown width={24} height={24} color="#88919A" />}
                    />
                </Group>
                <PerformanceChart
                    performanceType={performanceType}
                    data={data?.allocations ?? []}
                    isPending={isPending}
                />
            </Stack>
            <Stack flex={1} gap={25} mt={8}>
                <Text fz={19} lh="18px">
                    {'Параметры эффективности'}
                </Text>
                <PerformanceTable
                    performanceType={performanceType}
                    data={data?.allocations ?? []}
                    isPending={isPending}
                />
            </Stack>
        </Group>
    );
};
