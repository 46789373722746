import { useQuery } from '@tanstack/react-query';

import { useApi } from '../../../api';
import { type ReportSettings } from '../../../entities';

export const useGetReportSettingsQuery = (id: string) => {
    const api = useApi();

    return useQuery({
        queryKey: ['portfolio', id, 'report', 'settings'],
        queryFn: async () => await api.portfolio.report.getReportSettings({ params: { id } }),
        enabled: Boolean(id),
        select: (data) => {
            const options: ReportSettings =
                JSON.parse(data.options) as ReportSettings;

            return options;
        },
    });
};
