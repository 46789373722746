import { Portal } from '@mantine/core';
import { type FC, type ReactNode, useEffect, useRef } from 'react';

import { useHtmlToCanvas } from './useHtmlToCanvas';

type Props = {
    Component: ReactNode;
    onScreenChange: (screen: string) => void;
};

export const ComponentScreener: FC<Props> = ({ Component, onScreenChange }) => {
    const ref = useRef<HTMLDivElement>(null);
    const { screen, setElement } = useHtmlToCanvas();

    useEffect(() => {
        if (screen) {
            onScreenChange(screen);
        }
    }, [screen, onScreenChange]);

    useEffect(() => {
        if (ref.current) {
            setElement(ref.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);

    return (
        <Portal
            ref={ref}
            style={{
                position: 'absolute',
                bottom: '100%',
            }}
        >
            {Component}
        </Portal>
    );
};
