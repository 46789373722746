/* eslint-disable react/prop-types */
import { Box, Group, Text } from '@mantine/core';
import { type FC } from 'react';
import { type Props } from 'recharts/types/component/DefaultLegendContent';

type CustomLegendContentProps = {
    props?: Props;
};

export const CustomLegendContent: FC<CustomLegendContentProps> = ({ props }) => (
    <Group
justify="space-between" align="center" pr={8}
pl={15}>
        {props?.payload?.map((el) => {
            return (
                <Group key={el.id} gap={5}>
                    <Box h={8} w={8} style={{ borderRadius: '2px', background: el.color }} />
                    <Text
ff="Inter" fz={13} fw={500}
lh="22px" style={{ color: '#8A96A6' }}>
                        {el.value}
                    </Text>
                </Group>
            );
        })}
    </Group>
);
