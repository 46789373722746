import { type ReactElement } from 'react';
import classNames from 'classnames';
import { Loader } from '@libs/components';

import styles from './styles.module.scss';

export const EmptyContent = ({
    isModalSearch = false,
    isLoading = false,
    icon = 'pi pi-exclamation-circle',
    children,
}: {
    isModalSearch: boolean;
    isLoading: boolean;
    icon?: string;
    children: ReactElement;
}) => {
    return (
        <div className={classNames(styles.empty, isModalSearch && styles.isModal)}>
            {isLoading
? (
                <Loader isLoading withOverlay={false} />
            )
: (
                <>
                    <span
                        className={classNames(icon, styles.empty_warning)}
                        style={{ fontSize: '36px', color: '#A5B1C0' }}
                    />
                    {children}
                </>
            )}
        </div>
    );
};
