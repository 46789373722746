import { Table } from '@modules/Investorpro/shared/components/Table';
import classNames from 'classnames';
import { ValueWithMeasure } from '@modules/Investorpro/modules/BondStockPage/shared/components/ValueWithMeasure';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState, getStockDividendsThunk } from '@store/store';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Paginator } from '@modules/Investorpro/shared/components/Paginator';
import { setStockDividendsPage } from '@store/store/slices/investorpro/stock.slice';
import { round } from 'lodash';
import { SortingDirections } from '@libs/types/instrument.type';
import { WithLoader } from '@libs/components/loaders/ViewLoader';

import { DIVIDENDS_COLUMNS } from './columns';
import styles from './styles.module.scss';

const PAGE_SIZE = 14;

export const DividendsTab = () => {
    const { dividends, baseInfo, fintoolId, fininstId, loadings } = useSelector((state: RootState) => state.stock);
    const { loaded, data, total, page } = dividends;
    const { datePeriodsProfit } = baseInfo;

    const dispatch = useDispatch<AppDispatch>();
    const { ticker } = useParams();
    useEffect(() => {
        if (ticker && !loaded && fintoolId && fininstId) {
            dispatch(
                getStockDividendsThunk({
                    fintoolId,
                    pageNumber: page,
                    pageSize: PAGE_SIZE,
                    fininstId,
                    sortFields: 'periodShortNameInt',
                    sortingDirection: SortingDirections.DESC,
                }),
            );
        }
    }, [page, dispatch, ticker, loaded, fintoolId, fininstId]);

    const handlePageChange = (page: number) => {
        dispatch(setStockDividendsPage(page));
    };

    const dividendsYieldForYear = datePeriodsProfit[0]?.['y1y'];

    return (
        <div className={classNames('flex', 'flex-column', styles.dividendsTabWrapper)}>
            <div className={classNames('flex', 'justify-content-between', 'align-items-center')}>
                <h2>История выплат дивидендов</h2>
                <div className={classNames('flex', 'align-items-center', styles.dividentsProfitability)}>
                    <span>Дивидендная доходность за последние 12 месяцев:</span>
                    {dividendsYieldForYear && (
                        <ValueWithMeasure
                            value={round(dividendsYieldForYear, 2)}
                            measure="%"
                            className={styles.value}
                        />
                    )}
                </div>
            </div>
            <WithLoader isLoading={loadings.getStockDividends}>
                <Table data={data} columns={DIVIDENDS_COLUMNS} />
            </WithLoader>
            <Paginator
                limit={PAGE_SIZE}
                page={page}
                className={styles.paginator}
                total={total}
                isLoading={loadings.getStockDividends}
                handlePageChange={handlePageChange}
            />
        </div>
    );
};
