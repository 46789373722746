import classNames from 'classnames';

import styles from './styles.module.scss';

export const InDevelopmentStageStub = () => {
    return (
        <div className={classNames(styles.stub, 'flex', 'justify-content-center', 'align-items-center')}>
            Страница находится в разработке
        </div>
    );
};
