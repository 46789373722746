import { type InstrumentLiquidity } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getInstrumentsLiquidity: ApiFactory<
    ApiEndpoint<InstrumentLiquidity[], void, { id: string }>
> = (client) => async ({ signal, params: { id } }) => {
    const { data } = await client.get<InstrumentLiquidity[]>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/liquidity/${id}/instrument`,
        { signal },
    );

    return data;
};
