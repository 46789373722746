/* eslint-disable no-nested-ternary */
import { Text } from '@mantine/core';
import { type DateValue } from '@mantine/dates';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { type ScenarioIndicatorType } from '@modules/Investorpro/modules/PortfolioPage/entities';

const columnHelper = createColumnHelper<ScenarioIndicatorType>();

export const useScenarioIndicatorColumns = (dates: [DateValue, DateValue]) => useMemo(
        () => [
            columnHelper.accessor('name', {
                id: 'name',
                header: 'Показатель',
                cell: (props) => (
                    <Text fz="14px" lh="16px" fs="normal">
                        {props.getValue() ?? ''}
                    </Text>
                ),
                enableSorting: false,
            }),
            columnHelper.accessor('dateFromValue', {
                id: 'dateFromValue',
                header: `На ${dates[0] ? dayjs(dates[0]).format('DD.MM.YYYY') : '-'}`,
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                cell: (props) => (
                    <Text style={{ textAlign: 'end' }} fz="14px" ff="Inter">
                        {typeof props.getValue() === 'number' ? props.getValue().toLocaleString('ru') : '–'}
                    </Text>
                ),
                enableSorting: false,
            }),
            columnHelper.accessor('dateTillValue', {
                id: 'dateTillValue',
                header: `На ${dates[1] ? dayjs(dates[1]).format('DD.MM.YYYY') : '-'}`,
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                cell: (props) => (
                    <Text style={{ textAlign: 'end' }} fz="14px" ff="Inter">
                        {typeof props.getValue() === 'number' ? props.getValue().toLocaleString('ru') : '–'}
                    </Text>
                ),
                enableSorting: false,
            }),
            columnHelper.accessor('change', {
                id: 'change',
                header: 'Изменения',
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                cell: (props) => (
                    <Text
                        ff="Inter"
                        fz="14px"
                        style={{
                            color:
                                Number(props.getValue()) > 0
                                    ? '#26A65B'
                                    : Number(props.getValue()) < 0
                                      ? '#E42821'
                                      : '#14171F',
                            textAlign: 'end',
                        }}
                    >
                        {typeof props.getValue() === 'number'
                            ? `${Number(props.getValue()) > 0 ? '+' : ''}${props.getValue().toLocaleString('ru')}%`
                            : '–'}
                    </Text>
                ),
                enableSorting: false,
            }),
        ],
        [dates],
    );
