/* eslint-disable no-nested-ternary */
import { Text } from '@mantine/core';
import { createColumnHelper, type Row } from '@tanstack/react-table';
import { useMemo } from 'react';
import {
    type PortfolioHistoricReturnsType,
    type SeasonData,
} from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type CellStylesProperties } from '../../../shared/Table';
import { BodyCell } from './BodyCell';
import { FooterCell } from './FooterCell';

const columnHelper = createColumnHelper<PortfolioHistoricReturnsType>();

const getCellStyles = (value?: number): CellStylesProperties => ({
    backgroundColor:
        typeof value === 'number' ? (value > 0 ? 'rgba(66, 166, 46, 0.2)' : 'rgba(242, 67, 61, 0.2)') : undefined,
    hoveredBackgroundColor:
        typeof value === 'number' ? (value > 0 ? 'rgba(66, 166, 46, 0.4)' : 'rgba(242, 67, 61, 0.4)') : undefined,
    cursor: 'pointer',
});

export const useHistoricReturnsColumns = (seasons?: SeasonData) => useMemo(
        () => [
            columnHelper.accessor('year', {
                id: 'year',
                header: 'Дата',
                enableSorting: false,
                cell: (props) => (
                    <Text
style={{ textAlign: 'start' }} fz="14px" lh="16px"
fs="normal">
                        {props?.getValue() ?? ''}
                    </Text>
                ),
                footer: () => (
                    <Text
style={{ textAlign: 'start' }} fz="15px" lh="16px"
fs="normal" fw={400}>
                        {'Сезонность'}
                    </Text>
                ),
            }),
            columnHelper.accessor('months.jan', {
                id: 'jan',
                header: 'Январь',
                enableSorting: false,
                meta: {
                    headerCellStyles: { justifyContent: 'end' },
                    getCellStyles: (row: Row<PortfolioHistoricReturnsType>) => getCellStyles(row.original.months.jan),
                },
                cell: ({ cell }) => <BodyCell value={cell.getValue()} />,
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.months.jan.positiveProfits}
                        negativeSum={seasons?.months.jan.negativeProfits}
                    />
                ),
            }),
            columnHelper.accessor('months.feb', {
                id: 'feb',
                header: 'Февраль',
                enableSorting: false,
                meta: {
                    headerCellStyles: { justifyContent: 'end' },
                    getCellStyles: (row: Row<PortfolioHistoricReturnsType>) => getCellStyles(row.original.months.feb),
                },
                cell: ({ cell }) => <BodyCell value={cell.getValue()} />,
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.months.feb.positiveProfits}
                        negativeSum={seasons?.months.feb.negativeProfits}
                    />
                ),
            }),
            columnHelper.accessor('months.mar', {
                id: 'mar',
                header: 'Март',
                enableSorting: false,
                meta: {
                    headerCellStyles: { justifyContent: 'end' },
                    getCellStyles: (row: Row<PortfolioHistoricReturnsType>) => getCellStyles(row.original.months.mar),
                },
                cell: ({ cell }) => <BodyCell value={cell.getValue()} />,
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.months.mar.positiveProfits}
                        negativeSum={seasons?.months.mar.negativeProfits}
                    />
                ),
            }),
            columnHelper.accessor('months.apr', {
                id: 'apr',
                header: 'Апрель',
                enableSorting: false,
                meta: {
                    headerCellStyles: { justifyContent: 'end' },
                    getCellStyles: (row: Row<PortfolioHistoricReturnsType>) => getCellStyles(row.original.months.apr),
                },
                cell: ({ cell }) => <BodyCell value={cell.getValue()} />,
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.months.apr.positiveProfits}
                        negativeSum={seasons?.months.apr.negativeProfits}
                    />
                ),
            }),
            columnHelper.accessor('months.may', {
                id: 'may',
                header: 'Май',
                enableSorting: false,
                meta: {
                    headerCellStyles: { justifyContent: 'end' },
                    getCellStyles: (row: Row<PortfolioHistoricReturnsType>) => getCellStyles(row.original.months.may),
                },
                cell: ({ cell }) => <BodyCell value={cell.getValue()} />,
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.months.may.positiveProfits}
                        negativeSum={seasons?.months.may.negativeProfits}
                    />
                ),
            }),
            columnHelper.accessor('months.jun', {
                id: 'jun',
                header: 'Июнь',
                enableSorting: false,
                meta: {
                    headerCellStyles: { justifyContent: 'end' },
                    getCellStyles: (row: Row<PortfolioHistoricReturnsType>) => getCellStyles(row.original.months.jun),
                },
                cell: ({ cell }) => <BodyCell value={cell.getValue()} />,
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.months.jun.positiveProfits}
                        negativeSum={seasons?.months.jun.negativeProfits}
                    />
                ),
            }),
            columnHelper.accessor('months.jul', {
                id: 'jul',
                header: 'Июль',
                enableSorting: false,
                meta: {
                    headerCellStyles: { justifyContent: 'end' },
                    getCellStyles: (row: Row<PortfolioHistoricReturnsType>) => getCellStyles(row.original.months.jul),
                },
                cell: ({ cell }) => <BodyCell value={cell.getValue()} />,
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.months.jul.positiveProfits}
                        negativeSum={seasons?.months.jul.negativeProfits}
                    />
                ),
            }),
            columnHelper.accessor('months.aug', {
                id: 'aug',
                header: 'Август',
                enableSorting: false,
                meta: {
                    headerCellStyles: { justifyContent: 'end' },
                    getCellStyles: (row: Row<PortfolioHistoricReturnsType>) => getCellStyles(row.original.months.aug),
                },
                cell: ({ cell }) => <BodyCell value={cell.getValue()} />,
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.months.aug.positiveProfits}
                        negativeSum={seasons?.months.aug.negativeProfits}
                    />
                ),
            }),
            columnHelper.accessor('months.sep', {
                id: 'sep',
                header: 'Сентябрь',
                enableSorting: false,
                meta: {
                    headerCellStyles: { justifyContent: 'end' },
                    getCellStyles: (row: Row<PortfolioHistoricReturnsType>) => getCellStyles(row.original.months.sep),
                },
                cell: ({ cell }) => <BodyCell value={cell.getValue()} />,
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.months.sep.positiveProfits}
                        negativeSum={seasons?.months.sep.negativeProfits}
                    />
                ),
            }),
            columnHelper.accessor('months.oct', {
                id: 'oct',
                header: 'Октябрь',
                enableSorting: false,
                meta: {
                    headerCellStyles: { justifyContent: 'end' },
                    getCellStyles: (row: Row<PortfolioHistoricReturnsType>) => getCellStyles(row.original.months.oct),
                },
                cell: ({ cell }) => <BodyCell value={cell.getValue()} />,
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.months.oct.positiveProfits}
                        negativeSum={seasons?.months.oct.negativeProfits}
                    />
                ),
            }),
            columnHelper.accessor('months.nov', {
                id: 'nov',
                header: 'Ноябрь',
                enableSorting: false,
                meta: {
                    headerCellStyles: { justifyContent: 'end' },
                    getCellStyles: (row: Row<PortfolioHistoricReturnsType>) => getCellStyles(row.original.months.nov),
                },
                cell: ({ cell }) => <BodyCell value={cell.getValue()} />,
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.months.nov.positiveProfits}
                        negativeSum={seasons?.months.nov.negativeProfits}
                    />
                ),
            }),
            columnHelper.accessor('months.dec', {
                id: 'dec',
                header: 'Декабрь',
                enableSorting: false,
                meta: {
                    headerCellStyles: { justifyContent: 'end' },
                    getCellStyles: (row: Row<PortfolioHistoricReturnsType>) => getCellStyles(row.original.months.dec),
                },
                cell: ({ cell }) => <BodyCell value={cell.getValue()} />,
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.months.dec.positiveProfits}
                        negativeSum={seasons?.months.dec.negativeProfits}
                    />
                ),
            }),
            columnHelper.accessor('total', {
                id: 'total',
                meta: { headerCellStyles: { justifyContent: 'end' } },
                header: 'Годовая',
                enableSorting: false,
                cell: (props) => (
                    <Text
                        style={{
                            color: Number(props?.getValue()) > 0 ? '#26A65B' : '#E42821',
                            textAlign: 'end',
                        }}
                        fz="14px"
                        lh="16px"
                        fs="normal"
                    >
                        {props?.getValue() ?? ''}
                        {'%'}
                    </Text>
                ),
                footer: () => (
                    <FooterCell
                        positiveSum={seasons?.total.positiveProfits}
                        negativeSum={seasons?.total.negativeProfits}
                    />
                ),
            }),
        ],
        [seasons],
    );
