import { notifications } from '@mantine/notifications';

type Props = 'error' | 'success' | 'warning';

const notificationStyles = {
    error: {
        styles: {
            root: { backgroundColor: 'rgba(255, 227, 228, 0.80)' },
            icon: { backgroundColor: '#FF8787' },
        },
    },
    warning: {
        styles: {
            root: { backgroundColor: 'rgba(255, 241, 182, 0.80)' },
            icon: { backgroundColor: '#F7D762' },
        },
    },
    success: {
        styles: {
            root: { backgroundColor: 'rgba(211, 249, 216, 0.80)' },
            icon: { backgroundColor: '#92DC9E' },
        },
    },
};

export const notification = (type: Props, message?: string) => {
    const titles = {
        error: 'Ошибка сервера',
        warning: 'Ошибка',
        success: 'Сообщение',
    };

    const title = titles[type];

    const notificationData = {
        title,
        message,
        ...(notificationStyles[type]),
    };

    notifications.show(notificationData);
}
