import * as yup from 'yup';

const reportFormGeneralSchema = yup.object({
    name: yup.string().required('Поле должно быть заполнено').default(''),
    background: yup.string().required('Поле должно быть заполнено').default('#F2F7FE'),
    textColor: yup.string().required('Поле должно быть заполнено').default('#636E80'),
    logo: yup.string().default(''),
});

export const reportFormTitleSchema = reportFormGeneralSchema.concat(yup.object({
    footer: yup.string().required('Поле должно быть заполнено').default(''),
    description: yup.string().max(200, 'Максимальная длина 200 символов').default(''),
}));

export const reportFormFinalSchema = reportFormGeneralSchema.concat(yup.object({
    descriptionFinal: yup.string().max(50, 'Максимальная длина 50 символов').default(''),
}));

export type ReportFormTitleValues = yup.InferType<typeof reportFormTitleSchema>;

export type ReportFormFinalValues = yup.InferType<typeof reportFormFinalSchema>;
