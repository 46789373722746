import { createSlice } from "@reduxjs/toolkit";
import { getSavedCardsThunk } from "@store/store/thunk/card.thunk";
import { SavedCard } from "@libs/types";

type IState = {
    savedCards: SavedCard[];
};

export const initialState: IState = {
    savedCards: [],
};

export const cardSlice = createSlice({
    name: 'card',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSavedCardsThunk.fulfilled, (state, action) => {
            state.savedCards = action.payload!;
        });
    },
});

export const cardReducer = cardSlice.reducer;
