import classNames from 'classnames';
import { Button } from 'primereact/button';

import styles from './styles.module.scss';

type Props = {
    loginHandler: (args?: { isRegistration?: boolean }) => void;
};

export const LoginForm = ({ loginHandler }: Props) => {
    return (
        <div className={classNames('flex flex-column', styles.dialog)}>
            <div className={styles.dialogHeader}>Войдите в аккаунт</div>

            <div className={classNames('flex mt-auto')}>
                <Button className={styles.dialogActionButton} severity="secondary" onClick={() => loginHandler()}>
                    Вход
                </Button>
                <Button
                    className={classNames(styles.dialogActionButton, styles.dialogActionButtonSuccess)}
                    onClick={() => loginHandler({ isRegistration: true })}
                >
                    Регистрация
                </Button>
            </div>
        </div>
    );
};
