import { type BondRatingInfo } from '@modules/Investorpro/modules/BondStockPage/BondPage/services/types';
import { type Column } from '@modules/Investorpro/shared/components/Table';

import { type ObjectRowData, type ObjectKeyColumnRow } from '../../utils/types';


export const RATING_INFO_ROWS: Array<ObjectKeyColumnRow<BondRatingInfo>> = [
    { label: 'Рейтинговое агентство', key: 'raitingAgency' },
    { label: 'Рейтинг заемщика', key: 'borrowerRaiting' },
];

export const RATING_INFO_COLUMNS: Array<Column<ObjectRowData>> = [
    {
        id: 'label',
        field: 'label',
        label: '',
        align: 'left',
        width: '50%',
    },
    {
        id: 'value',
        field: 'value',
        label: '',
        align: 'left',
        width: '50%',
    },
];
