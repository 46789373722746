import classNames from 'classnames';
import { Table, type Column } from '@modules/Investorpro/shared/components/Table';

import styles from './styles.module.scss';

type Props = {
    title: string;
    columns: Array<Column<any>>;
    data: object[];
};

export const TableWithTitle = ({ title, data, columns }: Props) => {
    return (
        <div className={classNames('flex', 'flex-column', 'align-items-start', styles.wrapper)}>
            <h3>{title}</h3>
            <Table className={styles.table} columns={columns} data={data} />
        </div>
    );
};
