import { useConfig } from '@dynamic-env/useConfig';
import { useEffect, useState } from 'react';
import { defaultConfig, dynamicConfigUrl } from '@dynamic-env/config';
import { httpClient } from '@configs/httpclient.config';

const configLoadingErrorMessage =
    'Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)';

export const ConfiguredApp = ({ children }: any) => {
    const { config, setConfig } = useConfig();

    const [configLoadingState, setConfigLoadingState] = useState<'loading' | 'ready' | 'error'>('loading');

    useEffect(() => {
        if (config.NODE_ENV === 'development') {
            setConfig(defaultConfig);
            setConfigLoadingState('ready');
        } else {
            httpClient
                .get(dynamicConfigUrl)
                .then((response: any) => {
                    setConfig(response.data);
                    console.debug('Global config fetched: ', response.data);
                    setConfigLoadingState('ready');
                })
                .catch((e: any) => {
                    console.log(
                        configLoadingErrorMessage,
                        `Have you provided the config file '${dynamicConfigUrl}'?`,
                        e,
                    );
                    setConfigLoadingState('error');
                });
        }
    }, [setConfig]);

    return <>{children}</>;
};
