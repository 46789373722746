import { type ServerBondIndustry } from '@libs/types';
import { type ProfitPeriod, type CapitalizationInstrumentData, type NotNullable } from '@libs/types/instrument.type';
import { type ProfitDividendInfo } from '@modules/Investorpro/modules/BondStockPage/StockPage/types';
import { type BondIndexes } from '@modules/Investorpro/types/bond.type';

export enum InstrumentType {
    STOCKS = 'stocks',
    BONDS = 'bonds',
};

export enum StockCategoryOptionValue {
    ALL_STOCKS = 'allStocks',
    TOP_100 = 'MOEXBMI',
    MOEX_INDEX = 'IMOEX',
};

export type IndustryCategoryOptionValue = BondIndexes | StockCategoryOptionValue;

export type Option<T = string> = {
    name: string;
    code: T;
}

export type HeatColorInterval = {
    leftBound?: number;
    rightBound?: number;
    color: string;
    leftBoundInclude?: boolean;
    rightBoundInclude?: boolean;
    title?: string;
}

export type FormattedColorInterval = {
    leftBound: number;
    rightBound: number;
    color: string;
    leftBoundInclude: boolean;
    label: string;
    rightBoundInclude: boolean;
    title: string;
}

export type InstrumentCategory = ServerBondIndustry | StockCategoryOptionValue | BondIndexes;


export enum LiquidityKeyType {
    l100k = 'liquidit100000',
    l1m = 'liquidit1000000',
    l5m = 'liquidit5000000',
    l10m = 'liquidit10000000',
    l50m = 'liquidit50000000',
    l100m = 'liquidit100000000',
}

export type LiquidityType = {
    sell_liquidit100000: number;
    sell_liquidit1000000: number;
    sell_liquidit5000000: number;
    sell_liquidit10000000: number;
    sell_liquidit50000000: number;
    sell_liquidit100000000: number;
    buy_liquidit100000: number;
    buy_liquidit1000000: number;
    buy_liquidit5000000: number;
    buy_liquidit10000000: number;
    buy_liquidit50000000: number;
    buy_liquidit100000000: number;
}

export type InstrumentIndustryData = CapitalizationInstrumentData & {
    [ProfitPeriod.DAY]: number;
    [ProfitPeriod.WEEK]: number;
    [ProfitPeriod.MONTH]: number;
    [ProfitPeriod.YEAR]: number;
    [ProfitPeriod.YTD]: number;
} & LiquidityType & {
    close: number;
}

export type InstrumentStoreType = Partial<Record<string, HeatMapState>>

export enum QuoteType {
    BUY = 'buy',
    SELL = 'sell',
}

export enum HeatMapType {
    MARKET = 'MARKET_HEAT_MAP',
    LIQUIDITY = 'LIQUIDITY_HEAT_MAP',
}

export type LoadingState = {
    [HeatMapType.MARKET]: string[];
    [HeatMapType.LIQUIDITY]: string[];
}

export type HeatMapState = {
    data: InstrumentIndustryData[];
    liquidityLoaded: boolean;
    profitLoaded: ProfitPeriod[];
    loadings: LoadingState;
}

export type MetaLoadingState = Record<string, LoadingState>
