import { createAsyncThunk } from "@reduxjs/toolkit";
import { downloadFile } from "@libs/services";

export const downloadFileThunk = createAsyncThunk(
    'attachment/downloadFile',
    async ({ bucket, ticket }: { bucket: string; ticket: string }, { rejectWithValue }) => {
        try {
            const { data } = await downloadFile(bucket, ticket);
            return URL.createObjectURL(data);
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
