import { Stack } from '@mantine/core';
import { type UseQueryResult } from '@tanstack/react-query';
import { type FC, Fragment } from 'react';
import { type PortFolioCost } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { ChartLegend } from '../../../shared/ChartLegend';
import { ViewError, ViewLoader } from '../../../shared/Layout';
import { PieChart } from '../../../shared/PieChart';

type Props = {
    portfolioCostQuery: UseQueryResult<PortFolioCost, Error>;
    coreMetricsQuery: UseQueryResult<
        Array<{
            name: string;
            value: number;
            color: string;
        }>,
        Error
    >;
    isInReport?: boolean;
};

export const CoreMetricsChart: FC<Props> = ({ portfolioCostQuery, coreMetricsQuery, isInReport }) => {
    if (portfolioCostQuery.isError || coreMetricsQuery.isError) {
        return (
            <ViewError
                error={portfolioCostQuery.error ?? coreMetricsQuery.error}
                subtitle="Ошибка получения данных по основным метрикам"
            />
        );
    }

    if (portfolioCostQuery.isPending || coreMetricsQuery.isPending) {
        return <ViewLoader />;
    }

    return (
        <Fragment>
            <PieChart
                isInReport={isInReport}
                data={coreMetricsQuery.data}
                price={{
                    value: portfolioCostQuery.data.costPortfolio,
                    currency: portfolioCostQuery.data.currency,
                }}
            />
            <Stack w={236}>
                <ChartLegend data={coreMetricsQuery.data} />
            </Stack>
        </Fragment>
    );
};
