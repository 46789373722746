import { ColorInput, type ColorInputProps } from '@mantine/core';
import { type FC } from 'react';

export const CustomInputColor: FC<ColorInputProps> = (props) => (
    <ColorInput
        {...props}
        h={65}
        maxLength={7}
        withEyeDropper={false}
        closeOnColorSwatchClick={true}
        styles={{
            input: { padding: '10px 10px 10px 43px', fontSize: '13px', height: '40px' },
            section: { width: '43px', paddingRight: '7px', left: '4px', top: '4px', bottom: '4px' },
            colorPreview: { width: '32px', height: '32px', '--cs-radius': '4px' },
        }}
    />
);
