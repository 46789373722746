import { Table } from '@modules/Investorpro/shared/components/Table';
import { type BondRatingInfo } from '@modules/Investorpro/modules/BondStockPage/BondPage/services/types';

import { useTableData } from '../../utils/useTableData';
import { RATING_INFO_COLUMNS, RATING_INFO_ROWS } from './columns';
import styles from './styles.module.scss';

type Props = {
    data: BondRatingInfo | null;
};

export const Rating = ({ data: raitingInfo }: Props) => {
    const data = useTableData(raitingInfo, RATING_INFO_ROWS);

    return (
        <div className={styles.raitingInfoWrapper}>
            <Table
                className={styles.raitingInfoTable}
                data={data}
                columns={RATING_INFO_COLUMNS}
                showHeaders={false}
                separateColumns={false}
            />
        </div>
    );
};
