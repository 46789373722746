import { type FC, Fragment, useMemo } from 'react';
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import dayjs from 'dayjs';
import { type CartesianViewBox } from 'recharts/types/util/types';
import { type PortfolioDynamicsResponseData } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { ViewLoader } from '../../../../shared/Layout';
import { CustomLegend } from './CustomLegend';
import { EventsLabel } from './EventsLabel';
import { chartColorGenerator } from './chartColorGenerator';
import { chartDataNormalize } from './chartDataNormalize';
import { renderCustomReferenceLabel } from './renderCustomReferenceLabel';
import { renderTooltipContent } from './renderTooltipContent';

type Props = {
    data: PortfolioDynamicsResponseData;
    isPending?: boolean;
    isInReport?: boolean;
};

export const Chart: FC<Props> = ({ data, isPending, isInReport }) => {
    const chartData = useMemo(() => chartDataNormalize(data), [data]);
    const chartColors = useMemo(() => chartColorGenerator(data.performances), [data]);

    if (isPending) {
        return <ViewLoader />;
    }

    return (
        <ResponsiveContainer height="100%" width="100%">
            <AreaChart data={chartData} margin={{ top: 20, bottom: 40, left: 48 }}>
                <CartesianGrid
horizontal={true} vertical={false} stroke="#D8E1EB"
opacity={0.6} />

                <XAxis
                    tickMargin={20}
                    tickSize={0}
                    axisLine={{ strokeWidth: 1, stroke: '#A5B1C0' }}
                    dataKey="date"
                    tickFormatter={(props: string) => dayjs(props).format('DD.MM.YYYY')}
                    interval="preserveStartEnd"
                    style={{
                        fontSize: '11px',
                        fontFamily: 'Inter',
                        fontWeight: '400',
                        textAnchor: 'end',
                        fill: '#8A96A6',
                    }}
                />
                <YAxis
                    unit="%"
                    tickMargin={20}
                    tickSize={0}
                    tickCount={9}
                    axisLine={false}
                    orientation="right"
                    style={{
                        fontSize: 11,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        textAnchor: 'start',
                        fill: '#8A96A6',
                    }}
                />

                {data.performances.map((item) => {
                    const color = chartColors[item.name];

                    return (
                        <Fragment key={item.name}>
                            <defs>
                                <linearGradient
id={item.name.replaceAll(' ', '_')} x1="0" y1="0"
x2="0" y2="1">
                                    <stop offset="5%" stopColor={color} stopOpacity={1} />
                                    <stop offset="95%" stopColor={color} stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <ReferenceLine
                                y={item.performance.at(-1)?.value}
                                stroke="none"
                                ifOverflow="extendDomain"
                                label={(props: { viewBox: CartesianViewBox }) => renderCustomReferenceLabel(props, color ?? '#2F9CEB', item)}
                            />

                            <Area
                                animateNewValues={!isInReport}
                                isAnimationActive={!isInReport}
                                type={'linear'}
                                dataKey={item.name}
                                stroke={color ?? '#FFFFFF'}
                                fill={`url(#${item.name.replaceAll(' ', '_')})`}
                                connectNulls={true}
                            />
                        </Fragment>
                    );
                })}

                {data.events.map((event) => (
                    <ReferenceLine
                        key={`${event.uuid}_${event.date}`}
                        x={event.date}
                        stroke="none"
                        label={(props: { viewBox: CartesianViewBox }) => <EventsLabel event={event} props={props} />}
                    />
                ))}

                <Tooltip
                    contentStyle={{ border: 'none' }}
                    content={renderTooltipContent}
                    cursor={{ stroke: '#2380EB', strokeWidth: 1, strokeDasharray: 5 }}
                    wrapperStyle={{
                        border: '1px solid #D8DFE6',
                        background: '#FFFFFF',
                        borderRadius: '6px',
                        padding: '9px 18px 13px 18px',
                        width: '250px',
                        zIndex: 400,
                    }}
                />

                <Legend
                    wrapperStyle={{ bottom: 0 }}
                    content={<CustomLegend data={data} chartColors={chartColors || {}} />}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};
