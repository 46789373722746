import { useChoosePeriod } from '@libs/utils';
import { type Period } from '@libs/utils/hooks/useChoosePeriod';
import { LinearChartWithBars } from '@modules/Investorpro/modules/BondStockPage/shared/components/LinearChartWithBars';
import { getInstrumentProfitCurvesDataThunk, useAppDispatch, useAppSelector } from '@store/store';
import {
    setProfitCurvesOption,
    setProfitCurvesPeriod,
    setProfitCurvesLoadedStatus,
} from '@store/store/slices/investorpro/stock.slice';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { type PeriodId } from '@libs/types/instrument.type';
import { SecurityMarketType } from '@libs/types';
import { SecurityBoards } from '@modules/Investorpro/modules/MarketPages/shared/constants';
import { InstrumentEngineType } from '@modules/Investorpro/types/quote.type';

import { INTERVAL_OPTIONS } from '../../../ChartTab/components/Candle/constants';

export const StockPriceChart = () => {
    const { ticker: secId } = useParams();
    const {
        profitCurvesData,
        loadings,
        info: { updatedAt },
    } = useAppSelector((state) => state.stock);
    const { loaded, volData, priceData, maxVol, period, currentPeriodOption, initialized } = profitCurvesData;
    const { getProfitData } = loadings;

    const dispatch = useAppDispatch();

    const { options, handleOptionChange } = useChoosePeriod({
        handleOptionChange: (option: string | null) => dispatch(setProfitCurvesOption(option)),
        handleChangePeriod: (period: Period) => dispatch(setProfitCurvesPeriod(period)),
        intervalTypes: INTERVAL_OPTIONS,
        endDate: updatedAt ? new Date(updatedAt) : undefined,
    });

    useEffect(() => {
        if (!loaded && secId && updatedAt) {
            dispatch(
                getInstrumentProfitCurvesDataThunk({
                    secId,
                    intervalType: currentPeriodOption as PeriodId,
                    interval: period.interval,
                    from: period.from,
                    till: period.to,
                    engine: InstrumentEngineType.STOCK,
                    market: SecurityMarketType.STOCK,
                    board: SecurityBoards.TQBR,
                }),
            );
        }
    }, [dispatch, loaded, currentPeriodOption, updatedAt]);

    const handlePeriodChange = (value: string | null) => {
        handleOptionChange(value);
        dispatch(setProfitCurvesLoadedStatus(false));
    };

    return (
        <LinearChartWithBars
            barsData={volData}
            maxVol={maxVol}
            lineData={priceData}
            width={1250}
            initLoading={!initialized}
            loading={getProfitData}
            height={540}
            intervalType={period.interval}
            handlePeriodOptionChange={handlePeriodChange}
            currentPeriodOption={currentPeriodOption}
            periodOptions={options}
        />
    );
};
