import { Box, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import { GradientScale } from '../../../shared/GradientScale';
import { ViewError, ViewLoader } from '../../../shared/Layout';
import { useGetIntraPortfolioCorrelationQuery } from './useGetIntraPortfolioCorrelationQuery';

type Props = {
    id: string;
};

export const IntraPortfolioCorrelation: FC<Props> = ({ id }) => {
    const { data, isError, error, isPending } = useGetIntraPortfolioCorrelationQuery(id);

    if (isError) {
        return (
            <Stack>
                <Text fz={19} fw={400}>
                    {'Внутрипортфельная корреляция'}
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по внутрипортфельной диверсификации" />
            </Stack>
        );
    }

    if (isPending) {
        return (
            <Box>
                <Text fz={19} fw={400}>
                    {'Внутрипортфельная корреляция'}
                </Text>
                <ViewLoader />
            </Box>
        );
    }

    return (
        <Stack gap={25}>
            <Text fz={19} fw={400}>
                {'Внутрипортфельная корреляция'}
            </Text>
            <GradientScale
                rightValue={1}
                leftValue={0}
                value={data.correlation}
                labels={['Полностью диверсифицирован', 'Не диверсифицирован']}
            />
        </Stack>
    );
};
