import { BreadCrumb } from '@modules/Investorpro/shared/components/BreadCrumb';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { ProfitPeriod } from '@libs/types/instrument.type';
import { useLoading } from '@libs/utils/hooks/useLoading';

import {
    type InstrumentIndustryData,
    InstrumentType,
    type InstrumentCategory,
    HeatMapType,
    StockCategoryOptionValue,
    type LoadingState,
} from './shared/types';
import styles from './styles.module.scss';
import { AnalyticalMap } from './components/AnalyticalMap';
import { MarketHeatMap } from './components/MarketHeatMap';
import {
    formatDataLoadingKey,
    formatLiquidityLoadingKey,
    formatProfitLoadingKey,
    getInstrumentStoreCode,
} from './shared/utils';
import { useCurrentRequests } from './shared/utils/useCurrentRequests';
import { LiquidityHeatMap } from './components/LiquidityHeatMap';
import { PROFIT_LOADING_KEY, TOTAL_LOADING_KEY } from './shared/constants';
import { useInstrumentStore } from './shared/utils/useInstrumentStore';

export const AnalyticsPage = () => {
    const [tradeDate, setTradeDate] = useState<Date | null>(null);
    const { startLoading, stopLoading } = useLoading(TOTAL_LOADING_KEY);
    const { addCallback, addRequest, abortRequests, resetRequests, callCallbacks, resetCallbacks } =
        useCurrentRequests();
    const {
        instrumentsStore,
        setInstrumentStoreDataByKey,
        addProfitData,
        addLiquidityData,
        addInstrumentsData,
        getNoLoadedPeriods,
        getHeatMapWithCertainLoadingKey,
        resetLoadingsForHeatMap,
        addInstrumentLoadingKeys,
        getUniqueLoadingKeysForHeatMap,
    } = useInstrumentStore();

    const removeTotalLoading = (permission: boolean) => {
        if (permission) {
            stopLoading();
        }
    };

    const loadData = async (
        instrumentType: InstrumentType,
        instrumentCategory: InstrumentCategory,
        profitPeriod: ProfitPeriod[],
        heatMapKey: HeatMapType,
        disableTotalLoading = false,
    ) => {
        const withLiquidity = heatMapKey === HeatMapType.LIQUIDITY;
        const currentStoreKey = getInstrumentStoreCode(instrumentType, instrumentCategory);
        const liquidityLoadingKey = formatLiquidityLoadingKey(currentStoreKey);
        const dataLoadingKey = formatDataLoadingKey(currentStoreKey);
        const instrumentStoreElement = instrumentsStore[currentStoreKey];

        let instruments = instrumentsStore[currentStoreKey]?.data ?? [];
        const instrumentsAreNotEmpty = instruments.length > 0;
        const liquidityLoaded = instrumentStoreElement?.liquidityLoaded;

        const notLoadedPeriods = getNoLoadedPeriods(currentStoreKey, profitPeriod);
        const hasNotLoadedPeriods = notLoadedPeriods.length > 0;

        const addLiquidity = async (data: InstrumentIndustryData[]) => {
            await addLiquidityData(instrumentType, instrumentCategory, heatMapKey, addRequest)(data);
        };

        const addProfit = (period: ProfitPeriod) => {
            return addProfitData(instrumentType, instrumentCategory, heatMapKey, period, addRequest);
        };

        const isAlreadyLoading =
            !hasNotLoadedPeriods &&
            (instrumentsStore[currentStoreKey]?.loadings[heatMapKey]?.includes(liquidityLoadingKey) ||
                instrumentsStore[currentStoreKey]?.loadings[heatMapKey]?.includes(dataLoadingKey));

        const isAllDataLoaded =
            instrumentsAreNotEmpty && !hasNotLoadedPeriods && (withLiquidity ? liquidityLoaded : true);

        const notNeedToLoad = !tradeDate || isAllDataLoaded || isAlreadyLoading;

        if (notNeedToLoad) {
            return;
        }

        setInstrumentStoreDataByKey(currentStoreKey, (prev) => ({
            loadings: {
                ...prev.loadings,
                [heatMapKey]: [
                    ...prev.loadings[heatMapKey].filter((item) => !new RegExp(PROFIT_LOADING_KEY).test(item)),
                    ...profitPeriod
                        .filter((item) => !instrumentStoreElement?.profitLoaded.includes(item))
                        .map((item) => formatProfitLoadingKey(currentStoreKey, item)),
                ],
            },
        }));

        try {
            const { storeKeyWithLoading, keys } = getUniqueLoadingKeysForHeatMap(heatMapKey, currentStoreKey);

            if (storeKeyWithLoading) {
                abortRequests(heatMapKey, keys);
                resetCallbacks(heatMapKey, keys);
                resetLoadingsForHeatMap(storeKeyWithLoading, heatMapKey);
            }

            const notHaveLoadedInstruments = instrumentCategory && instrumentType && !instrumentsAreNotEmpty;

            if (notHaveLoadedInstruments) {
                addInstrumentLoadingKeys(currentStoreKey, heatMapKey, [dataLoadingKey]);
            }

            const instrumentsDataIsAlreadyLoading = Object.keys(instrumentStoreElement?.loadings ?? {}).some((key) => instrumentStoreElement?.loadings[key as keyof LoadingState]?.includes(dataLoadingKey),
            );

            const needToLoadInstruments = notHaveLoadedInstruments && !instrumentsDataIsAlreadyLoading;
            const liquidityIsNotLoaded = withLiquidity && !liquidityLoaded;

            if (liquidityIsNotLoaded) {
                addInstrumentLoadingKeys(currentStoreKey, HeatMapType.LIQUIDITY, [liquidityLoadingKey]);
            }

            const needToLoadProfitImmediately = instrumentsAreNotEmpty && hasNotLoadedPeriods;
            const needToLoadLiquidityImmediately = instrumentsAreNotEmpty && liquidityIsNotLoaded;
            const needToPostponeLoadLiquidity = liquidityIsNotLoaded && !instrumentsAreNotEmpty;
            const needToPostponeLoadProfit = hasNotLoadedPeriods && !instrumentsAreNotEmpty;

            const keyWithInstrumentsDataLoading =
                getHeatMapWithCertainLoadingKey(currentStoreKey, dataLoadingKey) ?? heatMapKey;

            await Promise.all([
                needToLoadProfitImmediately &&
                    notLoadedPeriods.map(async (period) => await addProfit(period)(instruments)),
                needToLoadLiquidityImmediately && addLiquidity(instruments),
            ]);

            if (needToPostponeLoadLiquidity) {
                addCallback(keyWithInstrumentsDataLoading, addLiquidity, liquidityLoadingKey);
            }

            if (needToPostponeLoadProfit) {
                notLoadedPeriods.forEach((period) => {
                    addCallback(
                        keyWithInstrumentsDataLoading,
                        addProfit(period),
                        formatProfitLoadingKey(currentStoreKey, period),
                    );
                });
            }

            if (needToLoadInstruments) {
                instruments = await addInstrumentsData(
                    instrumentType,
                    instrumentCategory,
                    heatMapKey,
                    tradeDate,
                    addRequest,
                );

                callCallbacks({
                    data: instruments,
                    key: heatMapKey,
                    filterFullInclude: false,
                    filter: currentStoreKey,
                });
            }

            removeTotalLoading(disableTotalLoading);
        } catch (e) {
            console.error('e', e);
            abortRequests(heatMapKey);
            resetRequests(heatMapKey);
            const storeKey = getInstrumentStoreCode(instrumentType, instrumentCategory);
            resetLoadingsForHeatMap(storeKey, heatMapKey);
        }
    };

    useEffect(() => {
        if (tradeDate) {
            startLoading();
            void loadData(
                InstrumentType.STOCKS,
                StockCategoryOptionValue.ALL_STOCKS,
                [ProfitPeriod.DAY, ProfitPeriod.YEAR],
                HeatMapType.LIQUIDITY,
                true,
            );
        }
    }, [tradeDate]);

    return (
        <div>
            <BreadCrumb />
            <div className={classNames(styles.analyticsPage, 'flex', 'flex-column')}>
                <h1>Аналитика</h1>
                <div className={classNames('flex', 'flex-column')}>
                    <MarketHeatMap tradeDate={tradeDate} instrumentsStore={instrumentsStore} loadData={loadData} />
                    <AnalyticalMap setTradeDate={setTradeDate} />
                    <LiquidityHeatMap instrumentsStore={instrumentsStore} loadData={loadData} tradeDate={tradeDate} />
                </div>
            </div>
        </div>
    );
};
