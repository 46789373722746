import { ActionIcon, Group, NumberInput, Stack, Text, Tooltip } from '@mantine/core';
import { type FC } from 'react';

import { Trash } from '../../shared/Icon';
import { type PortfolioToolValues } from '../PortfolioForm.schema';

type Props = {
    onPercentChange: (percent: number | string) => void;
    onRemoveClick: () => void;
    tool: PortfolioToolValues;
};

export const SelectedToolItem: FC<Props> = ({ tool, onRemoveClick, onPercentChange }) => (
    <Group wrap="nowrap" gap={10}>
        <Group
            w="100%"
            wrap="nowrap"
            py={5}
            style={{ borderBottom: '1px solid #D8E1EB' }}
            justify="space-between"
            align="flex-start"
        >
            <Stack gap={0} w={200}>
                <Tooltip label={tool?.name} position="top-start" zIndex={501}>
                    <Text
fz={14} fw={400} lh="20px"
c="#14171F" truncate={true}>
                        {tool?.name}
                    </Text>
                </Tooltip>
                <Text
fz={12} fw={400} lh="20px"
c="#8A96A6">
                    {tool?.ticker}
                </Text>
            </Stack>
            <Group gap={8} wrap="nowrap" justify="end">
                <NumberInput
                    maw={140}
                    suffix="%"
                    decimalScale={2}
                    decimalSeparator=","
                    hideControls={true}
                    value={tool.percent}
                    max={100}
                    clampBehavior="strict"
                    allowNegative={false}
                    thousandSeparator=" "
                    onChange={onPercentChange}
                    styles={{ input: { textAlign: 'end' } }}
                />
                <NumberInput
                    value={tool.value}
                    maw={156}
                    disabled={true}
                    hideControls={true}
                    max={999999999999}
                    thousandSeparator=" "
                    styles={{ input: { textAlign: 'end' } }}
                />
            </Group>
        </Group>
        <ActionIcon size={16} variant="gray" onClick={onRemoveClick}>
            <Trash width={16} height={16} />
        </ActionIcon>
    </Group>
);
