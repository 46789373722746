import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const ChevronUp: FC<Props> = ({ width = 24, height = 24, color = 'currentColor' }) => (
    <svg
width={width} height={height} viewBox="0 0 24 24"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
d="M7 14L12 9L17 14" stroke={color} strokeWidth="1.5"
strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
