import { getCompanyLogo } from '@libs/services/instrument.service';
import { SecurityMarketType } from '@libs/types';
import { type GetStockDividendsDataRequestBody, IntervalType, PeriodId } from '@libs/types/instrument.type';
import { formatDateToQuery, isValueExist } from '@libs/utils';
import {
    getEV_EBITDAdata,
    getStockCompanyDescription,
    getStockCompanyFoundDate,
    getStockCompanyProfit,
    getStockData,
    getStockDividends,
    getStockEvents,
    getStockPE,
} from '@modules/Investorpro/modules/BondStockPage/StockPage/services/stock.service';
import { type DividendData } from '@modules/Investorpro/modules/BondStockPage/StockPage/types';
import {
    createCandlesWithPeriod,
    getCandlePeriodByServerInterval,
    getEndBoundsCandlesByPeriod,
    transformQuoteOrderBookToQuoteEntity,
} from '@modules/Investorpro/modules/BondStockPage/StockPage/utils/thunk.utils';
import { roundInstrumentPrice } from '@modules/Investorpro/modules/BondStockPage/shared/utils';
import { SecurityBoards } from '@modules/Investorpro/modules/MarketPages/shared/constants';
import {
    getArrowPosByDatePeriod,
    refetchDataUntilFindNotEmpty,
} from '@modules/Investorpro/modules/MarketPages/shared/utils/fetch.utils';
import { parseCandleDate } from '@modules/Investorpro/shared/utils/service.utils';
import {
    type CandleBookType,
    CandleDataColumnType,
    type GetActualQuotesDataReqBody,
    type GetCandlesRequestBody,
    InstrumentEngineType,
} from '@modules/Investorpro/types/quote.type';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActualQuotesData, getCandlesData, getProfitForPeriod } from '@shared/services/quote.service';
import { differenceInDays, format, subDays, subYears } from 'date-fns';
import { round } from 'lodash';

type GetStockCandlesThunkArgs = Pick<GetCandlesRequestBody, 'secId' | 'interval' | 'from' | 'till'> & {
    intervalType: PeriodId;
};

export const getStockCandlesThunk = createAsyncThunk(
    'investpro/getStockCandles',
    async ({ intervalType, till, from, interval, ...body }: GetStockCandlesThunkArgs, { rejectWithValue }) => {
        try {
            const [response] = await refetchDataUntilFindNotEmpty({
                fetchData: async (from) =>
                    await getCandlesData({
                        ...body,
                        till,
                        from,
                        interval,
                        arrayPos: getArrowPosByDatePeriod(interval, till, from),
                        columns: [
                            CandleDataColumnType.OPEN,
                            CandleDataColumnType.CLOSE,
                            CandleDataColumnType.HIGH,
                            CandleDataColumnType.LOW,
                            CandleDataColumnType.VALUE,
                            CandleDataColumnType.VOLUME,
                            CandleDataColumnType.BEGIN,
                            CandleDataColumnType.END,
                        ],
                        engine: InstrumentEngineType.STOCK,
                        market: SecurityMarketType.STOCK,
                        board: SecurityBoards.TQBR,
                    }),
                start: new Date(from),
                step: 1,
                format: formatDateToQuery,
                responseDataAccessor: (resp) => resp.data.candles.data,
            });
            const period =
                intervalType === PeriodId.FIVE_YEARS
                    ? '1_MONTH'
                    : getCandlePeriodByServerInterval(interval, response.length);

            return {
                candles: createCandlesWithPeriod(response, interval, period, {
                    begin: 6,
                    end: 7,
                    high: 2,
                    low: 3,
                    close: 1,
                    open: 0,
                    value: 4,
                    volume: 5,
                }).map(({ open, close, high, low, ...item }) => ({
                    ...item,
                    open: roundInstrumentPrice(open),
                    close: roundInstrumentPrice(close),
                    high: roundInstrumentPrice(high),
                    low: roundInstrumentPrice(low),
                })),
                rawCandles: response,
                periodOfCandles: period,
            };
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const getStockQuotesThunk = createAsyncThunk(
    'investpro/getStockQuotes',
    async (body: GetActualQuotesDataReqBody, { rejectWithValue }) => {
        try {
            const { data } = await getActualQuotesData(body);

            return transformQuoteOrderBookToQuoteEntity(data.orderbook);
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const getStockBaseInfoThunk = createAsyncThunk(
    'investpro/getStockHeaderInfo',
    async (ticker: string, { rejectWithValue }) => {
        try {
            const parsePriceValue = (data: CandleBookType['data'][number]) => data[0][0] as number;
            const parseVolumeValue = (data: CandleBookType['data'][number]) => data[0][3] as number;
            const parseDateWithVolume = (data: CandleBookType['data'][number]) => parseCandleDate(data[0][4] as string);
            const parseDateWithoutVolume = (data: CandleBookType['data'][number]) =>
                parseCandleDate(data[0][3] as string);

            let yearPrices: Array<CandleBookType['data'][number]> = [];

            try {
                const [response] = await refetchDataUntilFindNotEmpty({
                    fetchData: async (from, _) =>
                        await getCandlesData({
                            from,
                            till: formatDateToQuery(new Date()),
                            engine: InstrumentEngineType.STOCK,
                            secId: ticker,
                            interval: IntervalType.ONE_DAY,
                            arrayPos: getArrowPosByDatePeriod(IntervalType.ONE_DAY, from),
                            market: SecurityMarketType.STOCK,
                            columns: [
                                CandleDataColumnType.CLOSE,
                                CandleDataColumnType.END,
                                CandleDataColumnType.VALUE,
                                CandleDataColumnType.HIGH,
                                CandleDataColumnType.LOW,
                            ],
                            board: SecurityBoards.TQBR,
                        }),
                    start: subYears(new Date(), 1),
                    step: 365,
                    maxTries: 10,
                    format: formatDateToQuery,
                    responseDataAccessor: (resp) => resp.data.candles.data,
                });

                yearPrices = response;
            } catch (e) {
                console.error('e', e);
            }

            const lastYearPriceData = yearPrices[yearPrices.length - 1];
            const currentPriceDayDateWithTime = lastYearPriceData
                ? new Date(lastYearPriceData[0][4] as string).toJSON()
                : new Date().toJSON();

            const [dayPricesResponse, stocksDataResponse] = await Promise.allSettled([
                refetchDataUntilFindNotEmpty({
                    fetchData: async (from, _, i) =>
                        await getCandlesData({
                            from,
                            till: formatDateToQuery(new Date(currentPriceDayDateWithTime)),
                            engine: InstrumentEngineType.STOCK,
                            secId: ticker,
                            arrayPos: getArrowPosByDatePeriod(
                                i > 1 ? IntervalType.ONE_HOUR : IntervalType.ONE_MINUTE,
                                currentPriceDayDateWithTime,
                                from,
                            ),
                            interval: i > 1 ? IntervalType.ONE_HOUR : IntervalType.ONE_MINUTE,
                            market: SecurityMarketType.STOCK,
                            columns: [
                                CandleDataColumnType.CLOSE,
                                CandleDataColumnType.END,
                                CandleDataColumnType.HIGH,
                                CandleDataColumnType.LOW,
                            ],
                            board: SecurityBoards.TQBR,
                        }),
                    start: subDays(currentPriceDayDateWithTime, 1),
                    step: [3, 60, 180, 360],
                    format: formatDateToQuery,
                    responseDataAccessor: (resp) => resp.data.candles.data,
                }),
                refetchDataUntilFindNotEmpty({
                    fetchData: async (date) => await getStockData(ticker, date),
                    start: new Date(currentPriceDayDateWithTime),
                    step: 1,
                    format: formatDateToQuery,
                    responseDataAccessor: (resp) => resp.data.share,
                }),
            ]);

            const stocksData = stocksDataResponse.status === 'fulfilled' ? stocksDataResponse.value[0] : [];

            const dayPrices = dayPricesResponse.status === 'fulfilled' ? dayPricesResponse.value[0] : [];

            const currentDayPrice = dayPrices.length > 0 ? parsePriceValue(dayPrices[dayPrices.length - 1]) : null;
            const currentPriceDate = currentPriceDayDateWithTime
                ? format(currentPriceDayDateWithTime, 'yyyy-MM-dd')
                : null;
            let prevDayPrice = null;
            let yearMaxPrice: number | null = -Infinity;
            let yearMinPrice: number | null = Infinity;
            let dayMaxPrice: number | null = -Infinity;
            let dayMinPrice: number | null = Infinity;

            const stockData = stocksData[0];
            const tqbrStock = stocksData.find((stock) => stock.boardId === SecurityBoards.TQBR);

            const getCurrentVolume = () => {

                if (isValueExist(tqbrStock?.value)) {
                    return tqbrStock?.value;
                }

                if (lastYearPriceData) {
                    return parseVolumeValue(lastYearPriceData);
                }

                return null;
            };

            const currentVolume = getCurrentVolume();
            const volumesForAvg10Days = [];

            for (let i = 0; i < dayPrices.length; i++) {
                if (currentPriceDate) {
                    const date = parseDateWithoutVolume(dayPrices[i]);

                    if (Math.abs(differenceInDays(date, currentPriceDate)) === 0) {
                        const low = dayPrices[i][0][2] as number;
                        const high = dayPrices[i][0][1] as number;
                        dayMaxPrice = Math.max(dayMaxPrice, high);
                        dayMinPrice = Math.min(dayMinPrice, low);
                    }
                }
            }

            for (let i = yearPrices.length - 1; i >= 0; i--) {
                if (currentPriceDate) {
                    const deltaInDays = Math.abs(
                        differenceInDays(parseDateWithVolume(yearPrices[i]), currentPriceDate),
                    );

                    if (deltaInDays < 10) {
                        volumesForAvg10Days.push(parseVolumeValue(yearPrices[i]));
                    }

                    if (deltaInDays < 365) {
                        const low = yearPrices[i][0][2] as number;
                        const high = yearPrices[i][0][1] as number;
                        yearMaxPrice = Math.max(yearMaxPrice, high);
                        yearMinPrice = Math.min(yearMinPrice, low);
                    }
                }

                if (prevDayPrice === null && parseCandleDate(parseDateWithVolume(yearPrices[i])) !== currentPriceDate) {
                    prevDayPrice = parsePriceValue(yearPrices[i]);
                }
            }

            prevDayPrice = prevDayPrice ?? currentDayPrice;
            yearMaxPrice = yearMaxPrice === -Infinity ? null : yearMaxPrice;
            yearMinPrice = yearMinPrice === Infinity ? null : yearMinPrice;
            dayMaxPrice = dayMaxPrice === -Infinity ? null : dayMaxPrice;
            dayMinPrice = dayMinPrice === Infinity ? null : dayMinPrice;

            return {
                ...(stockData ?? {}),
                currentPrice: currentDayPrice,
                prevPrice: prevDayPrice,
                lastPriceUpdate: currentPriceDayDateWithTime,
                dayMaxPrice,
                hasStockData: Boolean(stocksData),
                dayMinPrice,
                yearMaxPrice,
                currentVolume,
                avg10DaysVolume:
                    volumesForAvg10Days.length > 0
                        ? round(
                              volumesForAvg10Days.reduce((acc, item) => acc + item, 0) / volumesForAvg10Days.length,
                              2,
                          )
                        : null,
                yearMinPrice,
                capitalization: tqbrStock?.capitalization,
            };
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

type GetStockDescriptionInfoReqBody = {
    fininstId: number;
    fintoolId: number;
    secId: string;
};

export const getStockDescriptionInfoThunk = createAsyncThunk(
    'investpro/getStockDescriptionInfo',
    async ({ fininstId, secId, fintoolId }: GetStockDescriptionInfoReqBody, { rejectWithValue }) => {
        try {
            const [
                descriptionResponse,
                foundationDateResponse,
                nearestReportDateResponse,
                profitResponse,
                peResponse,
                evEbitdaResponse,
                stockPeriodProfitsResponse,
                companyLogoResponse,
            ] = await Promise.allSettled([
                getStockCompanyDescription(fininstId),
                getStockCompanyFoundDate(fininstId),
                getStockEvents({ fininstId }),
                getStockCompanyProfit(fininstId),
                getStockPE(fintoolId),
                getEV_EBITDAdata(fininstId),
                getProfitForPeriod({
                    secId,
                    engine: InstrumentEngineType.STOCK,
                    market: SecurityMarketType.STOCK,
                    board: SecurityBoards.TQBR,
                }),
                getCompanyLogo([fininstId]),
            ]);

            return {
                description:
                    descriptionResponse.status === 'fulfilled'
                        ? descriptionResponse.value.data[0]?.description ?? null
                        : null,
                foundationDate:
                    foundationDateResponse.status === 'fulfilled'
                        ? foundationDateResponse.value.data[0]?.primaryRegDate ?? null
                        : null,
                nearestReportDate:
                    nearestReportDateResponse.status === 'fulfilled'
                        ? nearestReportDateResponse.value.data[0]?.eventDate ?? null
                        : null,
                profit: profitResponse.status === 'fulfilled' ? profitResponse.value.data : null,
                pe: peResponse.status === 'fulfilled' ? peResponse.value.data : null,
                evEbitda:
                    evEbitdaResponse.status === 'fulfilled'
                        ? evEbitdaResponse.value.data[0]?.evEbitda ?? evEbitdaResponse.value.data[0]?.ebitda
                        : null,
                stockPeriodProfits:
                    stockPeriodProfitsResponse.status === 'fulfilled' ? stockPeriodProfitsResponse.value.data : null,
                companyLogo:
                    companyLogoResponse.status === 'fulfilled' ? companyLogoResponse.value.data[0]?.link ?? null : null,
            };
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

type GetStockDividendsThunkArgs = GetStockDividendsDataRequestBody & {
    fininstId: number;
};

export const getStockDividendsThunk = createAsyncThunk(
    'investpro/getStockDividends',
    async ({ fininstId, ...body }: GetStockDividendsThunkArgs, { rejectWithValue }) => {
        try {
            const response = await getStockDividends(body);

            const dividends: DividendData[] = response.data.rows.map((item) => {
                return {
                    payoutPeriod: item.periodShortName,
                    buyUntil: item.declaredPayDate,
                    recordDate: item.listDate,
                    dividendAmount: item.payStock,
                    yield: item.dy,
                    dividendGap: item.gap,
                    closingTimeInTradingDays: item.gapLength,
                };
            });

            return {
                count: response.data.totalRows,
                data: dividends,
            };
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

type GetInstrumentProfitCurvesDataThunkArgs = GetCandlesRequestBody & {
    intervalType: PeriodId;
    size?: number;
};

export const getInstrumentProfitCurvesDataThunk = createAsyncThunk(
    'investpro/getStockProfitCurvesData',
    async (
        { intervalType, from, till, size = 50, interval, ...body }: GetInstrumentProfitCurvesDataThunkArgs,
        { rejectWithValue },
    ) => {
        try {
            const [response] = await refetchDataUntilFindNotEmpty({
                fetchData: async (date) =>
                    await getCandlesData({
                        ...body,
                        till,
                        from: date,
                        interval,
                        arrayPos: getArrowPosByDatePeriod(interval, till, date),
                        columns: [CandleDataColumnType.CLOSE, CandleDataColumnType.END, CandleDataColumnType.VALUE],
                    }),
                start: new Date(from),
                step: 1,
                format: formatDateToQuery,
                responseDataAccessor: (resp) => resp.data.candles.data,
            });

            const period = getCandlePeriodByServerInterval(interval, response.length);
            const candles = getEndBoundsCandlesByPeriod(response, interval, period, {
                price: 0,
                volume: 1,
                date: 2,
            });

            let priceData: Array<{ x: string; y: number; vol: number }> = [];
            let volData: Array<{ x: string; y: number; raw: number }> = [];
            let maxPrice = 0;
            let maxVol = 0;
            let minPrice = Infinity;
            let minVol = Infinity;

            (candles ?? []).forEach(({ price, volume, date }) => {
                maxPrice = Math.max(maxPrice, price);
                maxVol = Math.max(maxVol, volume);
                minPrice = Math.min(minPrice, price);
                minVol = Math.min(minVol, volume);
                priceData.push({ x: date, y: roundInstrumentPrice(price), vol: volume });
                volData.push({ x: date, y: volume, raw: volume });
            });

            priceData = priceData.sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime());
            volData = volData.sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime());

            return { priceData, volData, minPrice, maxPrice, minVol, maxVol };
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
