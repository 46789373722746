import { type KeyStatistics } from '../../../../../PortfolioPage/entities';
import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getKeyStatisticData: ApiFactory<
    ApiEndpoint<KeyStatistics[], void, { id: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<KeyStatistics[]>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/common-data/key-statistic-data/${params.id}`,
        { signal },
    );

    return data;
};
