import { transformDateFromMoscowToLocal } from '@modules/Investorpro/modules/MarketPages/shared/utils/date.utils';
import {
    type CandleType,
    type CandleBookType,
    type QuoteType,
    type QuotesBookType,
    type GetCandlesDataResponse,
    QuoteBuySell,
} from '@modules/Investorpro/types/quote.type';
import { IntervalType } from '@libs/types/instrument.type';
import { differenceInDays, differenceInHours, differenceInMinutes, format } from 'date-fns';

import { roundInstrumentPrice } from '../../shared/utils';


export const transformCandleOrderBookToCandleEntity = (orderBook: CandleBookType['data']): CandleType[] => {
    let maxVolume = 0;
    let minVolume = Infinity

    return orderBook.map<CandleType>(item => {
        const candle = item[0]
        maxVolume = Math.max(maxVolume, candle[5] as number);
        minVolume = Math.min(minVolume, candle[5] as number);
        const begin = transformDateFromMoscowToLocal(candle[6] as string).toJSON();
        const end = transformDateFromMoscowToLocal(candle[7] as string).toJSON();

        return {
            maxVolume: 0,
            minVolume: 0,
            x: new Date(candle[6] as string).toJSON(),
            open: roundInstrumentPrice(candle[0] as CandleType['open']),
            close: roundInstrumentPrice(candle[1] as CandleType['close']),
            high: roundInstrumentPrice(candle[2] as CandleType['high']),
            low: roundInstrumentPrice(candle[3] as CandleType['low']),
            value: candle[4] as CandleType['value'],
            volume: candle[5] as CandleType['volume'],
            begin,
            end,
        }
    }).map(item => ({
        ...item,
        minVolume,
        maxVolume,
    }));
}

export const transformQuoteOrderBookToQuoteEntity = (orderBook: QuotesBookType): QuoteType[] => {
    const getTime = (time: string) => {
        const [hours, minutes, seconds] = time.split(':');
        const newDate = new Date();
        newDate.setHours(Number(hours));
        newDate.setMinutes(Number(minutes));
        newDate.setSeconds(Number(seconds));

        return format(transformDateFromMoscowToLocal(newDate), 'HH:mm:ss')
    }


    return orderBook.data.map<QuoteType>(item => ({
        buysell: item[2] === 'B' ? QuoteBuySell.BUY : QuoteBuySell.SELL,
        price: item[3] as number,
        quantity: item[4] as number,
        updateTime: getTime(item[6] as string),
    }));
}

export const CANDLE_PERIODS = [
    '1_MONTH',
    '2_WEEKS',
    '1_WEEK',
    '6_DAYS',
    '5_DAYS',
    '4_DAYS',
    '3_DAYS',
    '2_DAYS',
    '1_DAY',
    '5_HOURS',
    '4_HOURS',
    '3_HOURS',
    '2_HOURS',
    '1_HOUR',
    '30_MINUTES',
    '15_MINUTES',
    '10_MINUTES',
    '5_MINUTES',
    '1_MINUTE',
] as const

export type CandlePeriodType = typeof CANDLE_PERIODS[number]
type PeriodsValue = Partial<Record<CandlePeriodType, number>>
type ServerCandlesData = GetCandlesDataResponse['data']['candles']['data']

export const PERIODS_VALUE_FOR_ONE_MINUTE_INTERVAL: PeriodsValue = {
    '30_MINUTES': 30,
    '15_MINUTES': 15,
    '10_MINUTES': 10,
    '5_MINUTES': 5,
    '1_MINUTE': 1,
}

export const PERIODS_VALUE_FOR_ONE_HOUR_INTERVAL: PeriodsValue = {
    '5_HOURS': 5,
    '4_HOURS': 4,
    '3_HOURS': 3,
    '2_HOURS': 2,
    '1_HOUR': 1,
}

export const PERIODS_VALUE_FOR_ONE_DAY_INTERVAL: PeriodsValue = {
    '1_MONTH': 30,
    '2_WEEKS': 14,
    '1_WEEK': 7,
    '6_DAYS': 6,
    '5_DAYS': 5,
    '4_DAYS': 4,
    '3_DAYS': 3,
    '2_DAYS': 2,
    '1_DAY': 1,
}

export const PERIODS_VALUES_FOR_INTERVALS: Record<IntervalType, PeriodsValue> = {
    [IntervalType.ONE_DAY]: PERIODS_VALUE_FOR_ONE_DAY_INTERVAL,
    [IntervalType.ONE_HOUR]: PERIODS_VALUE_FOR_ONE_HOUR_INTERVAL,
    [IntervalType.ONE_MINUTE]: PERIODS_VALUE_FOR_ONE_MINUTE_INTERVAL,
}

export const getCandlePeriodByCount = (
    periods: CandlePeriodType[],
    periodsValue: PeriodsValue,
    count: number,
): CandlePeriodType => {
    let currentCandlePeriod = periods[0]

    for (const period of periods) {
        const countOfCandles = count / (periodsValue[period] ?? 1)
        currentCandlePeriod = period

        if (countOfCandles >= 47) {
            break
        }
    }

    return currentCandlePeriod
}

export const getCandlePeriodByServerInterval = (
    serverInterval: IntervalType,
    responseDataCount: number,
): CandlePeriodType => {
    const periodStartIndexByIntervalType: Record<IntervalType, CandlePeriodType> = {
        [IntervalType.ONE_MINUTE]: '30_MINUTES',
        [IntervalType.ONE_HOUR]: '5_HOURS',
        [IntervalType.ONE_DAY]: '1_MONTH',
    }
    const periodEndIndexByIntervalType: Record<IntervalType, CandlePeriodType> = {
        [IntervalType.ONE_MINUTE]: '1_MINUTE',
        [IntervalType.ONE_HOUR]: '1_HOUR',
        [IntervalType.ONE_DAY]: '1_DAY',
    }
    const periodsValuesByIntervalType: Record<IntervalType, PeriodsValue> = {
        [IntervalType.ONE_MINUTE]: PERIODS_VALUE_FOR_ONE_MINUTE_INTERVAL,
        [IntervalType.ONE_HOUR]: PERIODS_VALUE_FOR_ONE_HOUR_INTERVAL,
        [IntervalType.ONE_DAY]: PERIODS_VALUE_FOR_ONE_DAY_INTERVAL,
    }

    const AVAILABLE_PERIODS = CANDLE_PERIODS.slice(
        CANDLE_PERIODS.indexOf(periodStartIndexByIntervalType[serverInterval] ?? 'ONE_MONTH'),
        CANDLE_PERIODS.indexOf(periodEndIndexByIntervalType[serverInterval] ?? 'ONE_MONTH') + 1,
    )

    return getCandlePeriodByCount(
        AVAILABLE_PERIODS,
        periodsValuesByIntervalType[serverInterval] ?? {},
        responseDataCount,
    )
}

export type BoundCandleData = { date: string; volume: number; price: number };

export const getEndBoundsCandlesByPeriod = (
    data: ServerCandlesData,
    interval: IntervalType,
    period: CandlePeriodType,
    indexes: {
        volume: number;
        date: number;
        price: number;
    },
): BoundCandleData[] => {
    const resultData: BoundCandleData[] = []

    const transformDataToBoundCandle = (data: ServerCandlesData[number][number]): BoundCandleData => ({
        date: transformDateFromMoscowToLocal(data[indexes.date] as string).toJSON(),
        price: data[indexes.price] as number ?? 0,
        volume: data[indexes.volume] as number ?? 0,
    })

    const groupedCandles = groupCandlesByPeriod(
        data,
        interval,
        period,
        indexes.date,
    )

    if (groupedCandles.length > 0) {
        resultData.push(transformDataToBoundCandle(groupedCandles[0][0][0]))

        for (let i = 1; i < groupedCandles.length; i++) {
            resultData.push(transformDataToBoundCandle(groupedCandles[i].at(-1)![0]))
        }
    }

    return resultData
}

export const groupCandlesByPeriod = (
    data: ServerCandlesData,
    interval: IntervalType,
    period: CandlePeriodType,
    dateIndex: number,
) => {
    const resultData: ServerCandlesData[] = []
    const getDataDate = (i: number) => new Date(data[i]?.[0][dateIndex] as string)
    const step = PERIODS_VALUES_FOR_INTERVALS[interval][period] ?? 1
    const getTimeDelta = (start: Date, end: Date) => {
        const deltaByInterval: Record<IntervalType, () => number> = {
            [IntervalType.ONE_MINUTE]: () => differenceInMinutes(start, end),
            [IntervalType.ONE_HOUR]: () => differenceInHours(start, end),
            [IntervalType.ONE_DAY]: () => differenceInDays(start, end),
        }

        return Math.abs(deltaByInterval[interval]())
    }
    let startOfPeriod: Date | null = getDataDate(0)
    const isMonthPeriod = period === '1_MONTH'
    let needAddDataToNextPeriod = false
    let periodSlice: ServerCandlesData = []
    let currentMonth = getDataDate(0).getMonth()

    for (let i = 0; i < data.length; i++) {
        const isLastItem = i === data.length - 1

        if (!isMonthPeriod) {
            const date = getDataDate(i)
            const timeAfterStart = getTimeDelta(startOfPeriod, date)
            const nextItemDate = data[i + 1] ? getDataDate(i + 1) : date
            const WITHIN_ONE_DAY_PERIODS = CANDLE_PERIODS.slice(
                CANDLE_PERIODS.indexOf('5_HOURS'),
                CANDLE_PERIODS.indexOf('1_MINUTE'),
            )
            const isPeriodWithinOneDay = interval !== IntervalType.ONE_DAY && WITHIN_ONE_DAY_PERIODS.includes(period)
                ? date.getDate() === nextItemDate.getDate()
                : true

            if (timeAfterStart >= step) {
                startOfPeriod = null
                needAddDataToNextPeriod = true
            } else {
                periodSlice.push(data[i])

                if (!(isPeriodWithinOneDay && !isLastItem)) {
                    startOfPeriod = null
                }
            }
        } else {
            if ((getDataDate(i).getMonth() === currentMonth && !isLastItem)) {
                periodSlice.push(data[i])
            } else {
                startOfPeriod = null
                needAddDataToNextPeriod = true
            }

            currentMonth = getDataDate(i).getMonth()
        }

        if (!startOfPeriod) {
            if (periodSlice.length > 0) {
                resultData.push(periodSlice)
            }

            periodSlice = []
            startOfPeriod = getDataDate(i)

            if (needAddDataToNextPeriod) {
                periodSlice = [data[i]]
                needAddDataToNextPeriod = false

                if (isLastItem) {
                    resultData.push(periodSlice)
                }
            }
        }
    }

    return resultData
}

export const createCandlesWithPeriod = (
    data: ServerCandlesData,
    interval: IntervalType,
    period: CandlePeriodType,
    indexes: {
        begin: number;
        end: number;
        high: number;
        low: number;
        close: number;
        open: number;
        value: number;
        volume: number;
    },
): CandleType[] => {
    let maxVolume = 0;
    let minVolume = Infinity

    const createPeriod = (dataSlice: ServerCandlesData, dateLabelKey: 'begin' | 'end' = 'end'): CandleType => {
        const lastElement = dataSlice.at(-1)?.[0]
        const firstElement = dataSlice[0]?.[0]

        const volume = lastElement?.[indexes.volume] as number ?? 0
        maxVolume = Math.max(maxVolume, volume)
        minVolume = Math.min(minVolume, volume)
        let high = 0
        let low = Infinity
        const end = lastElement?.[indexes.end] as string
            ? transformDateFromMoscowToLocal(lastElement?.[indexes.end] as string).toJSON()
            : new Date().toJSON()
        const begin = firstElement?.[indexes.begin] as string
            ? transformDateFromMoscowToLocal(firstElement?.[indexes.begin] as string).toJSON()
            : new Date().toJSON()

        for (const item of dataSlice) {
            high = Math.max(high, (item[0]?.[indexes.high] as number ?? 0))
            low = Math.min(low, item[0]?.[indexes.low] as number ?? 0)
        }

        return {
            x: dateLabelKey === 'begin' ? begin : end,
            end,
            close: lastElement?.[indexes.close] as number ?? 0,
            open: firstElement?.[indexes.open] as number ?? 0,
            begin,
            value: lastElement?.[indexes.value] as number ?? 0,
            high,
            low,
            volume,
            maxVolume: 0,
            minVolume: 0,
        }
    }

    return groupCandlesByPeriod(
        data,
        interval,
        period,
        indexes.end,
    )
    .map(item => createPeriod(item))
    .map(item => ({
        ...item,
        minVolume,
        maxVolume,
    }))
}
