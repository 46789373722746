import { Button, Group, LoadingOverlay, Modal, Stack, Text } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FC } from 'react';

import { useApi } from '../api/core/ApiProvider';
import { Close } from '../shared/Icon';
import { notification } from '../shared/Layout/notificationStyles';

type Props = {
    onClose: () => void;
    portfolioId?: string;
    portfolioName?: string;
};

export const DeleteModal: FC<Props> = ({ onClose, portfolioId, portfolioName }) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const deletePortfolio = useMutation({
        mutationKey: ['portfolio', portfolioId, 'delete'],
        mutationFn: async () => await api.portfolio.deletePortfolio({
                params: { id: portfolioId ?? '' },
            }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['portfolio', 'list'] });
            notification('success', 'Портфель удален.');
            onClose();
        },
        onError: () => {
            notification('error', 'Произошла ошибка при удалении портфеля.');
        },
    });

    return (
        <Modal.Root
centered={true} size="514px" opened={Boolean(portfolioId)}
radius={6} onClose={onClose}>
            <Modal.Overlay />
            <Modal.Content>
                <Modal.Header p={0} style={{ alignItems: 'flex-start' }}>
                    <Modal.Title
fz={24} fw={400} lh="16px"
p="48px 48px 0 48px">
                        {'Удалить портфель'}
                    </Modal.Title>
                    <Modal.CloseButton
                        variant="transparent"
                        icon={<Close width={36} height={36} />}
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            height: '46px',
                            width: '46px',
                        }}
                    />
                </Modal.Header>
                <Modal.Body p="24px 48px 48px 48px">
                    <LoadingOverlay visible={deletePortfolio.isPending} />
                    <Stack gap={30}>
                        <Text
fz="16px" fw="400" lh="24px"
style={{ wordBreak: 'break-word' }}>
                            {`Вы уверены, что хотите удалить портфель «${portfolioName}»? \
                            После удаления восстановить портфель не получится.`}
                        </Text>
                        <Group gap={26} justify="space-between" wrap="nowrap">
                            <Button
variant="cancel" size="large" fullWidth={true}
onClick={onClose}>
                                {'Отменить'}
                            </Button>
                            <Button
                                size="large"
                                variant="primary"
                                fullWidth={true}
                                onClick={() => deletePortfolio.mutate()}
                            >
                                {'Удалить'}
                            </Button>
                        </Group>
                    </Stack>
                </Modal.Body>
            </Modal.Content>
        </Modal.Root>
    );
};
