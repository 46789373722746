import classNames from 'classnames';
import { ReactComponent as ArrowFilled } from '@modules/Investorpro/shared/images/svg/arrowFilled.svg';
import { isValueExist } from '@libs/utils';

import styles from './styles.module.scss';
import { DEFAULT_NUMBER_DIVIDER, NONE_SYMBOL } from '../../constants';
import { formatPrice } from '../../utils/format.util';

type Props = {
    value?: number | null;
    measure?: string;
    type?: 'positive' | 'negative';
    withMark?: boolean;
    prefix?: string;
    positivePrefix?: string;
    negativePrefix?: string;
    decimalSymbol?: string;
    formatter?: (value: number) => string;
    measureClassName?: string;
} & React.HTMLAttributes<HTMLSpanElement>;

export const PositiveNegativeValue = ({
    value,
    measure,
    className,
    children,
    type,
    positivePrefix,
    negativePrefix,
    withMark,
    prefix,
    measureClassName,
    formatter,
    decimalSymbol = DEFAULT_NUMBER_DIVIDER,
    ...props
}: Props) => {
    if (!isValueExist(value)) {
        return <>{NONE_SYMBOL}</>;
    }

    const isPositive = type ? type === 'positive' : !children && value && value > 0;
    const isNegative = type ? type === 'negative' : !children && value && value < 0;

    let color = isPositive ? '#42A62E' : undefined;
    color = isNegative ? '#E42821' : color;
    const valueForRender = formatter ? formatter(value) : formatPrice(value, decimalSymbol);

    return (
        <span
            {...props}
            className={classNames(className, styles.neutral, 'inline-flex', 'align-items-center')}
            style={{
                color,
            }}
        >
            {children ?? (
                <>
                    {prefix}
                    {isPositive ? positivePrefix : null}
                    {isNegative ? negativePrefix : null}
                    {valueForRender}
                    {measureClassName
? (
                        <span
                            className={measureClassName}
                            style={{
                                color,
                            }}
                        >
                            {measure}
                        </span>
                    )
: (
                        measure
                    )}
                </>
            )}
            {withMark && (isPositive || isNegative) && (
                <ArrowFilled
                    width={10}
                    height={9}
                    className={classNames(isNegative && styles.negative, isPositive && styles.positive)}
                />
            )}
        </span>
    );
};
