import { ScrollArea, Stack } from '@mantine/core';
import { type UseFormReturnType } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { type FC, useCallback, useEffect, useMemo } from 'react';
import { type AxiosError } from 'axios';

import { useApi } from '../../api';
import { type PortfolioFormValues, type PortfolioToolValues } from '../PortfolioForm.schema';
import { FreeMoneyHeader } from './FreeMoneyHeader';
import { FreeMoneyItem } from './FreeMoneyItem';
import { SelectedToolItem } from './SelectedToolItem';
import { notification } from '../../shared/Layout';

type Props = {
    onToolsChange: (tools: PortfolioToolValues[]) => void;
    form: UseFormReturnType<PortfolioFormValues>;
    portfolioCurrency: string;
};

export const SelectedTools: FC<Props> = ({ onToolsChange, form, portfolioCurrency }) => {
    const api = useApi();
    const [debouncedFormValue] = useDebouncedValue(form.values.value, 500);

    const handleRemove = useCallback(
        (ticker: string) => () => {
            onToolsChange(form.values.tickers.filter((tool) => tool.ticker !== ticker));
        },
        [form.values.tickers, onToolsChange],
    );

    const handlePercentChange = useCallback(
        (index: number) => (percent: string | number) => {
            form.setFieldValue(`tickers.${index}.percent`, Number(percent));
        },
        [form],
    );

    const calculateTickerValuesQuery = useQuery({
        queryKey: ['ticker', 'calculator', debouncedFormValue, form.values.tickers],
        queryFn: async () => await api.ticker.calculateTickerValues({
                data: form.values.tickers.map(({ ticker, percent }) => ({
                    ticker,
                    percent,
                })),
                params: {
                    portfolioValue: debouncedFormValue,
                },
            }),
        enabled: Boolean(debouncedFormValue),
    });

    const freeMoney = useMemo(
        () => calculateTickerValuesQuery.data?.find(({ ticker }) => ticker === 'FREE_MONEY'),
        [calculateTickerValuesQuery.data],
    );

    useEffect(() => {
        if (calculateTickerValuesQuery.isSuccess) {
            form.setFieldValue(
                'tickers',
                form.values.tickers.map((ticker) => ({
                    ...ticker,
                    ...calculateTickerValuesQuery.data.find((item) => item.ticker === ticker.ticker),
                })),
            );
        }

        if (calculateTickerValuesQuery.isError) {
            const error = calculateTickerValuesQuery.error as AxiosError<Array<{ message: string; error: string }>>;

            if (error.response?.data[0].error === 'MORE_THAN_100_PERCENTS') {
                notification('warning', 'Сумма всех долей всех инструментов в портфеле не должна превышать 100%');
            } else {
                notification('error', 'Произошла ошибка при получении доли инструмента.');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculateTickerValuesQuery.data, calculateTickerValuesQuery.isSuccess, calculateTickerValuesQuery.isError]);

    return (
        <Stack gap={0} h="100%">
            <Stack gap={10} pr={46}>
                <FreeMoneyHeader percent={freeMoney?.percent ?? 0} portfolioCurrency={portfolioCurrency} />
                <FreeMoneyItem
                    percent={freeMoney?.percent ?? 0}
                    value={freeMoney?.value ?? 0}
                    portfolioCurrency={portfolioCurrency}
                />
            </Stack>
            <ScrollArea.Autosize scrollbarSize={6}>
                <Stack gap={0} pr={20}>
                    {form?.values?.tickers?.map((tool, index) => (
                        <SelectedToolItem
                            onPercentChange={handlePercentChange(index)}
                            key={tool.ticker}
                            tool={tool}
                            onRemoveClick={handleRemove(tool.ticker)}
                        />
                    ))}
                </Stack>
            </ScrollArea.Autosize>
        </Stack>
    );
};
