export enum SortOrderTypes {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type PageableType<T = string, S = SortOrderTypes> = {
    pageSize?: number;
    pageNumber?: number;
    sortField?: T;
    sortOrder?: S;
};

export type PageableResponse<T> = {
    totalRows: number;
    totalPages: number;
    size: number;
    rows: T[];
    pageNumber: number;
};
