import { type FC } from 'react';

export const EnergyResourcesIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path
d="M12.6904 27.5046H20.3095" stroke="#636E80" strokeWidth="1.2"
strokeLinecap="round" />
            <path d="M14.1191 23.5046V27.5046M18.881 23.5046V27.5046" stroke="#636E80" strokeWidth="1.2" />
            <path
                d="M9.07058 11.4405C9.10001 11.2448 9.26819 11.1 9.46613 11.1H23.5339C23.7318 11.1 23.9
                11.2448 23.9294 11.4405L25.7339 23.4405C25.7703 23.6824 25.583 23.9 25.3384
                23.9H7.66162C7.41701 23.9 7.22969 23.6824 7.26607 23.4405L9.07058 11.4405Z"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
d="M8.5 17.5H24.5" stroke="#636E80" strokeWidth="1.2"
strokeLinecap="round" />
            <path
d="M14.5 11.5L13.5 23.5" stroke="#636E80" strokeWidth="1.2"
strokeLinecap="round" />
            <path
d="M19.5 23.5L18.5 11.5" stroke="#636E80" strokeWidth="1.2"
strokeLinecap="round" />
            <path
                d="M26.124 15.5046C27.5263 14.8421 28.5 13.3855 28.5 11.6951C28.5 9.38078
                26.6748 7.50464 24.4233 7.50464C22.9548 7.50464 21.6676 8.30275 20.95 9.5"
                stroke="#636E80"
                strokeWidth="1.2"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.1461 4.15C25.1461 3.81863 24.8775 3.55 24.5461 3.55C24.2148 3.55 23.9461 3.81863 23.9461
                4.15V5.06353C23.9461 5.3949 24.2148 5.66353 24.5461 5.66353C24.8775 5.66353 25.1461 5.3949 25.1461
                5.06353V4.15ZM20.5923 4.60953C20.8792 4.44384 21.2462 4.54216 21.4119 4.82914L21.8689 5.62065C22.0346
                5.90762 21.9362 6.27458 21.6493 6.44026C21.3623 6.60595 20.9953 6.50763 20.8296 6.22065L20.3727
                5.42914C20.2075.14217 20.3053 4.77521 20.5923 4.60953ZM28.7205 5.4292C28.8862 5.14224 28.7879
                4.77527 28.5009 4.60956C28.214 4.44384 27.847 4.54213 27.6813 4.82908L27.2243 5.62038C27.0586
                5.90734 27.1569 6.2743 27.4439 6.44002C27.7308 6.60574 28.0978 6.50745 28.2635 6.22049L28.7205
                5.4292ZM31.1756 8.32351C31.4626 8.15784 31.5609 7.79089 31.3953 7.50391C31.2296 7.21692 30.8626
                7.11858 30.5757 7.28425L29.784 7.74123C29.4971 7.9069 29.3987 8.27385 29.5644 8.56083C29.73
                8.84782 30.097 8.94616 30.384 8.78049L31.1756 8.32351ZM30.3413 11.4578C30.3413 11.1264 30.6099
                10.8578 30.9413 10.8578H31.8548C32.1861 10.8578 32.4548 11.1264 32.4548 11.4578C32.4548 11.7891
                32.1861 12.0578 31.8548 12.0578H30.9413C30.6099 12.0578 30.3413 11.7891 30.3413 11.4578ZM30.3843
                14.1359C30.0973 13.9702 29.7303 14.0685 29.5646 14.3555C29.3989 14.6425 29.4972 15.0094 29.7842
                15.1751L30.5756 15.6321C30.8626 15.7978 31.2295 15.6995 31.3952 15.4125C31.5609 15.1256 31.4626
                14.7586 31.1757 14.5929L30.3843 14.1359ZM27.4439 16.4762C27.7309 16.3105 28.0978 16.4088 28.2635
                16.6958L28.7205 17.4872C28.8862 17.7742 28.7879 18.1411 28.5009 18.3068C28.214 18.4725 27.847
                18.3742 27.6813 18.0873L27.2243 17.2959C27.0586 17.0089 27.1569 16.6419 27.4439 16.4762ZM18.5175
                7.28426C18.2306 7.11858 17.8636 7.2169 17.6979 7.50387C17.5322 7.79085 17.6306 8.1578 17.9175
                8.32349L18.709 8.78048C18.996 8.94616 19.363 8.84784 19.5287 8.56087C19.6943 8.27389 19.596
                7.90694 19.309 7.74125L18.5175 7.28426Z"
                fill="#636E80"
            />
        </g>
    </svg>
);
