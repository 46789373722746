import { Client, type StompConfig } from '@stomp/stompjs';
import { useEffect, useState } from 'react';

export const useStompCreate = (url: string, config?: StompConfig): Client => {
    const createStompClient = (url: string) => {
        return new Client({
            brokerURL: url,
            ...(config ?? {}),
        });
    };

    const [stompClient, setStompClient] = useState<Client>(createStompClient(url));

    useEffect(() => {
        if (stompClient.brokerURL !== url) {
            stompClient
                .deactivate()
                .then(() => {
                    setStompClient(createStompClient(url));
                    stompClient.activate();
                })
                .catch((e) => console.error('deactivate error', e));
        }
    }, [url]);

    return stompClient;
};
