import { PositiveNegativeValue } from '@modules/Investorpro/shared/components/PositiveNegativeValue';
import { type Column } from '@modules/Investorpro/shared/components/Table';
import { QuoteBuySell, type QuoteType } from '@modules/Investorpro/types/quote.type';
import { round } from 'lodash';

export const TRADE_COLUMNS: Array<Column<QuoteType>> = [
    {
        id: 'time',
        label: 'Время',
        align: 'center',
        width: '93px',
        field: 'updateTime',
    },
    {
        id: 'price',
        label: 'Цена, RUB',
        type: 'number',
        width: '113px',
        align: 'center',
        render: (obj) => (
            <PositiveNegativeValue
                type={obj.buysell === QuoteBuySell.BUY ? 'positive' : 'negative'}
                value={obj.price}
            />
        ),
    },
    {
        id: 'volume',
        label: 'Объем',
        align: 'center',
        width: '81px',
        field: 'quantity',
        type: 'string',
    },
    {
        id: 'summ',
        label: 'Сумма, RUB',
        width: '124px',
        align: 'center',
        render: (obj) => (
            <PositiveNegativeValue
                value={round(obj.quantity * obj.price, 2)}
                type={obj.buysell === QuoteBuySell.BUY ? 'positive' : 'negative'}
            />
        ),
    },
];
