import { Box, Group, Table, Text } from '@mantine/core';
import { type Header, flexRender } from '@tanstack/react-table';

import { SortButton } from './SortButton';
import { type ColumnMeta } from '../../columnMetaType';

type Props<T extends Record<string, unknown>> = {
    header: Header<T, unknown>;
};

export const TableHeaderCell = <TableData extends Record<string, unknown>>({ header }: Props<TableData>) => (
    <Table.Th style={{ width: header.getSize(), padding: '0 0 13px 0' }}>
        <Group
            gap="6px"
            wrap="nowrap"
            pl="18px"
            justify="space-between"
            style={(header.column.columnDef.meta as ColumnMeta)?.headerCellStyles}
        >
            <Text style={{ color: '#8A96A6', fontSize: '14px', lineHeight: '16px' }}>
                {flexRender(header.column.columnDef.header, header.getContext())}
            </Text>
            {header.column?.getCanSort()
? (
                <SortButton
                    toggleSort={header.column.getToggleSortingHandler}
                    sortDirection={header.column.getIsSorted()}
                />
            )
: (
                <Box w={18} />
            )}
        </Group>
    </Table.Th>
);
