import { type FC } from 'react';

export const TechnologiesIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path
                d="M21.2801 10.3197C20.7083 10.1164 20.0956 10 19.4544 10C16.4294 10 13.9772 12.4624
                13.9772 15.5C13.9772 18.5376 16.4294 21 19.4544 21C20.0956 21 20.7083 20.8836 21.2801
                20.6804M27.7614 15.5C27.7614 16.2594 28.3745 16.875 29.1307 16.875C29.8869 16.875 30.5
                16.2594 30.5 15.5C30.5 14.7406 29.8869 14.125 29.1307 14.125C28.3745 14.125 27.7614 14.7406
                27.7614 15.5ZM27.7614 15.5L21.2801 15.5M24.5664 25.125C24.5664 25.8844 25.1795 26.5 25.9357
                26.5C26.6919 26.5 27.305 25.8844 27.305 25.125C27.305 24.3656 26.6919 23.75 25.9357 23.75C25.1795
                23.75 24.5664 24.3656 24.5664 25.125ZM24.5664 25.125H21.2801M24.5664 5.875C24.5664 5.11563 25.1795
                4.5 25.9357 4.5C26.6919 4.5 27.305 5.11563 27.305 5.875C27.305 6.63437 26.6919 7.25 25.9357
                7.25C25.1795 7.25 24.5664 6.63437 24.5664 5.875ZM24.5664 5.875L21.2801 5.875M21.2801
                12.2917H26.3921L28.0836 10.8762M28.0836 20.1238L26.3921 18.7083H21.2801M17.6286
                4.5V6.0543C16.3392 6.30418 15.1405 6.80922 14.0913 7.52184L12.9995 6.42546L10.4175
                9.01816L11.5093 10.1145C10.7996 11.1681 10.2967 12.3719 10.0479 13.6667H8.5V17.3333H10.0479C10.2967
                18.6281 10.7996 19.8318 11.5093 20.8855L10.4175 21.9818L12.9995 24.5745L14.0912 23.4782C15.1404
                24.1908 16.3392 24.6958 17.6286 24.9457V26.5H21.2801V4.5H17.6286ZM30.5 21C30.5 21.7594 29.8869
                22.375 29.1307 22.375C28.3745 22.375 27.7614 21.7594 27.7614 21C27.7614 20.2406 28.3745 19.625
                29.1307 19.625C29.8869 19.625 30.5 20.2406 30.5 21ZM30.5 10C30.5 9.24063 29.8869 8.625 29.1307
                8.625C28.3745 8.625 27.7614 9.24063 27.7614 10C27.7614 10.7594 28.3745 11.375 29.1307
                11.375C29.8869 11.375 30.5 10.7594 30.5 10Z"
                stroke="#636E80"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
