import { TabPanel, TabView } from 'primereact/tabview';
import { type ReactNode, useRef } from 'react';
import { classNames } from 'primereact/utils';

import styles from './styles.module.scss';

type ObjectTab = {
    label: ReactNode | string;
    tab: ReactNode | null;
    disabled?: boolean;
};

type Props = {
    tabs: Record<string, null | ReactNode> | ObjectTab[];
    current: number;
    className?: string;
    navContentClassName?: string;
    handleTabChange: (index: number) => void;
};

export const Tabs = ({ tabs, handleTabChange, current, className, navContentClassName }: Props) => {
    const tabViewRef = useRef<TabView>(null);

    return (
        <TabView
            ref={tabViewRef}
            className={classNames(styles.tabView, className)}
            activeIndex={current}
            onTabChange={({ index }) => {
                if (tabViewRef.current?.getElement().children[1].scrollTop) {
                    tabViewRef.current.getElement().children[1].scrollTop = 0;
                }
                handleTabChange(index);
            }}
            pt={{
                navContent: {
                    className: classNames(styles.tabViewNavContent, navContentClassName),
                },
            }}
        >
            {!Array.isArray(tabs)
                ? Object.entries(tabs).map(([label, tab]) => (
                      <TabPanel key={label} header={label} className={styles.tabPanel}>
                          {tab}
                      </TabPanel>
                  ))
                : tabs.map(({ label, tab, disabled }, index) => (
                      <TabPanel
key={index} disabled={disabled} header={label}
className={styles.tabPanel}>
                          {tab}
                      </TabPanel>
                  ))}
        </TabView>
    );
};
