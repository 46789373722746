

import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { type CustomTooltipItem, type TooltipContext } from '@libs/types';
import { type TooltipItem, type ChartType } from 'chart.js'
import { type TooltipAlignment } from '@modules/Investorpro/shared/components/TooltipWrapper';

export type OnToolTipChangePosArgs = {
    context: TooltipContext<any, any>;
    el: HTMLDivElement;
    alignment: TooltipAlignment;
    x: number;
    y: number;
}

type TooltipChartHookArgs<T, R, D extends ChartType = 'bar'> = {
    debounceTime?: number;
    handleToolTipHover?: (context: TooltipContext<D, T>) => void;
    xOffset?: number;
    yOffset?: number;
    setTooltipPosition?: (context: TooltipContext<D, T>, el: HTMLDivElement) => { x: number; y: number };
    mapTooltipData?: (data: R | TooltipItem<D>) => R;
    disableDebounce?: boolean;
    filterTooltipData?: (data: R | TooltipItem<D>) => boolean;
    sortTooltipData?: (a: R, b: R) => number;
    datasets?: number[];
    tooltipAlignment?: {
        mode?: 'fixed' | 'auto';
        default: TooltipAlignment;
    };
    alignmentOffset?: Partial<Record<TooltipAlignment, { x?: number; y?: number }>>;
    onTooltipPositionChange?: (args: OnToolTipChangePosArgs) => void;
};


type UseChartTooltipReturn<T extends object, R, D extends ChartType = 'bar'>
    = [
        React.RefObject<HTMLDivElement>,
        R[],
        (context: TooltipContext<D, T>) => void,
        TooltipAlignment,
        () => void,
    ];

export const useChartTooltip = <T extends object, D extends ChartType = 'bar', R = CustomTooltipItem<D, T>>(
    {
        debounceTime = 50,
        handleToolTipHover,
        xOffset = 0,
        alignmentOffset,
        yOffset = 0,
        setTooltipPosition,
        mapTooltipData,
        sortTooltipData,
        tooltipAlignment: tooltipAlignmentProps,
        datasets,
        filterTooltipData,
        onTooltipPositionChange,
    }: TooltipChartHookArgs<T, R, D> = {}): UseChartTooltipReturn<T, R, D> => {
    const [tooltipData, setTooltipData] = useState<R[]>([]);
    const [
        tooltipAlignment,
        setTooltipAlignment,
    ] = useState<TooltipAlignment>(tooltipAlignmentProps?.default ?? 'top');

    const ref = useRef<HTMLDivElement>(null);
    const tooltipHovered = useRef(false);
    useEffect(() => {
        const tooltipEl = ref.current;
        const showTooltip = () => {
            tooltipHovered.current = true;
        }
        const hideTooltip = () => {
            if (ref.current) {
                ref.current.style.visibility = 'hidden';
                ref.current.style.userSelect = 'none';
            }
            tooltipHovered.current = false;
        }

        tooltipEl?.addEventListener('mouseenter', showTooltip)
        tooltipEl?.addEventListener('mouseleave', hideTooltip)

        return () => {
            tooltipEl?.removeEventListener('mouseenter', showTooltip)
            tooltipEl?.removeEventListener('mouseleave', hideTooltip)
        }
    }, [ref.current])

    const resetTooltipData = useCallback(() => {
        setTooltipData([])
    }, [])

    const tooltipCallback = useCallback(debounce((context: TooltipContext<D, T>) => {
        const tooltipEl = ref.current;

        if (!tooltipEl) return;

        const tooltipModel = context.tooltip;

        let tooltipData = datasets
            ? tooltipModel.dataPoints?.filter((point) => datasets.includes(point.datasetIndex))
            : tooltipModel.dataPoints as R[];

        if (!tooltipData?.length || !tooltipModel?.opacity) {
            if (!tooltipHovered.current) {
                tooltipEl.style.visibility = 'hidden';
                tooltipEl.style.userSelect = 'none';
            }

            return;
        }

        tooltipData = mapTooltipData
            ? tooltipData.map(mapTooltipData)
            : tooltipData as R[];

        tooltipData = filterTooltipData
            ? tooltipData.filter(filterTooltipData)
            : tooltipData;

        if (tooltipData.length === 0) return

        tooltipData = sortTooltipData ? tooltipData.sort(sortTooltipData) : tooltipData;

        setTooltipData(tooltipData)

        if (handleToolTipHover) handleToolTipHover(context)

        let x = 0
        let y = 0
        const PADDING = 15;

        if (setTooltipPosition) {
            const { x: tooltipX, y: tooltipY } = setTooltipPosition(context, ref.current)
            x = tooltipX;
            y = tooltipY;
        } else {
            x = tooltipModel.caretX;
            y = tooltipModel.caretY + context.chart.chartArea.top;
        }

        const getPosWithOffsetByAlignment = (alignment: TooltipAlignment) => {
            const { left, top } = tooltipEl.parentElement?.getBoundingClientRect() ??
                { left: 0, top: 0 }
            const tooltipWidth = tooltipEl.firstElementChild?.clientWidth ?? 0
            const tooltipHeight = tooltipEl.firstElementChild?.clientHeight ?? 0
            let xPos = x
            let yPos = y

            if (alignment === 'top') {
                xPos += xOffset
                yPos -= yOffset

                return {
                    x: xPos,
                    y: yPos,
                    left: left + xPos - (tooltipWidth / 2) - PADDING,
                    right: left + xPos + (tooltipWidth / 2) + PADDING,
                    top: top + yPos - tooltipHeight - PADDING,
                    bottom: top + yPos,
                }
            }

            if (alignment === 'bottom') {
                xPos += xOffset
                yPos += yOffset

                return {
                    x: xPos,
                    y: yPos,
                    left: left + xPos - (tooltipWidth / 2) - PADDING,
                    right: left + xPos + (tooltipWidth / 2) + PADDING,
                    top: top + yPos,
                    bottom: top + yPos + tooltipHeight + PADDING,
                }
            }

            if (alignment === 'left') {
                xPos = xPos - yOffset
                yPos += xOffset

                return {
                    x: xPos,
                    y: yPos,
                    left: left + xPos - tooltipWidth - PADDING,
                    right: left + xPos,
                    top: top + yPos - (tooltipHeight / 2) - PADDING,
                    bottom: top + yPos + (tooltipHeight / 2) + PADDING,
                }
            }

            xPos += yOffset
            yPos += xOffset

            return {
                x: xPos,
                y: yPos,
                left: left + xPos,
                right: left + xPos + tooltipWidth + PADDING,
                top: top + yPos - (tooltipHeight / 2) - PADDING,
                bottom: top + yPos + (tooltipHeight / 2) + PADDING,
            }
        }

        let currentAlignment = tooltipAlignmentProps?.default ?? 'top';

        if (tooltipAlignmentProps?.mode === 'fixed') {
            const { x: tooltipX, y: tooltipY } = getPosWithOffsetByAlignment(currentAlignment)
            x = tooltipX;
            y = tooltipY;
        } else {
            let aligned = false
            const triedAlignments: TooltipAlignment[] = []

            while (!aligned) {
                if (triedAlignments.length === 4) {
                    aligned = true;
                    break
                }

                const windowWidth = window.innerWidth;
                const windowHeight = window.innerHeight;
                triedAlignments.push(currentAlignment);

                const {
                    x: tooltipX,
                    y: tooltipY,
                    left,
                    right,
                    bottom,
                    top,
                } = getPosWithOffsetByAlignment(currentAlignment);

                if (top < 0) {
                    currentAlignment = 'bottom';
                } else if (bottom > windowHeight) {
                    currentAlignment = 'top';
                } else if (right > windowWidth) {
                    currentAlignment = 'left';
                } else if (left < 0) {
                    currentAlignment = 'right';
                } else {
                    aligned = true;
                    x = tooltipX;
                    y = tooltipY;
                }
            }
        }

        tooltipEl.style.left = x + (alignmentOffset?.[currentAlignment]?.x ?? 0) + 'px';
        tooltipEl.style.top = y + (alignmentOffset?.[currentAlignment]?.y ?? 0) + 'px';
        setTooltipAlignment(currentAlignment)
        onTooltipPositionChange?.({
            context,
            el: ref.current,
            alignment: currentAlignment,
            x,
            y,
        })
        tooltipEl.style.opacity = '1';
        tooltipEl.style.visibility = 'visible';
        tooltipEl.style.userSelect = 'auto';

    }, debounceTime), [ref.current, debounceTime, tooltipAlignment, handleToolTipHover, datasets])

    return [ref, tooltipData, tooltipCallback, tooltipAlignment, resetTooltipData];
}
