import { type ScenarioIndicatorType } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getScenarioIndicator: ApiFactory<
    ApiEndpoint<ScenarioIndicatorType[], void, { portfolioId: string; scenarioUuid: string }>
> = (client) => async ({ signal, params }) => {
    const url = '/moex-datashop-investor-pro-portfolios-service' +
        `/api/v1/stress-test/${params.portfolioId}/scenario-indicator/${params.scenarioUuid}`;

    const { data } = await client.get<ScenarioIndicatorType[]>(url, { signal });

    return data;
};
