import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { useApi } from '../../../api/index';

export const useGetLeastCorrelatingToolsQuery = (
    id: string,
    enabled = true,
    onStatusChange?: (status: 'error' | 'success' | 'pending') => void,
) => {
    const api = useApi();

    const query = useQuery({
        queryKey: ['portfolio', id, 'diversification', 'least-correlating-tools'],
        queryFn: async () => await api.portfolio.diversification.getLeastCorrelatingTools({ params: { id } }),
        enabled: Boolean(id) && enabled,
    });

    useEffect(() => {
        if (query.isLoading || query.isFetching) {
            onStatusChange?.('pending');
        }

        if (query.isError) {
            onStatusChange?.('error');
        }

        if (query.isSuccess && !query.isLoading && !query.isFetching) {
            onStatusChange?.('success');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isLoading, query.isError, query.isSuccess, query.isFetching, query.isPending]);

    return query;
};
