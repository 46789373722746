import { useMutation } from '@tanstack/react-query';

import { useApi } from '../../../api';

export const useUploadReportFileMutation = () => {
    const api = useApi();

    return useMutation({
        mutationKey: ['report', 'file', 'upload'],
        mutationFn: async (vars: { file: File; uploadUrl: string }) => (
            await api.portfolio.report.uploadReportFile({
                data: { file: vars.file },
                params: { uploadUrl: vars.uploadUrl },
            })
        ),
    });
};
