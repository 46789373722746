/* eslint-disable react/prop-types */
import dayjs from 'dayjs';
import { type FC, useEffect, useMemo, useRef, useState } from 'react';
import { type Props } from 'recharts/types/component/Label';
import { type DurationsAndExtreme } from '@modules/Investorpro/modules/PortfolioPage/entities';

type CustomReferenceLabelProps = {
    extremes: DurationsAndExtreme[];
    props: Props;
};

export const CustomReferenceLabel: FC<CustomReferenceLabelProps> = ({ extremes, props }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(74);
    const { x = 0, y = 0, value = '' } = props;
    const labelValue = useMemo(
        () => extremes.find((el) => dayjs(value).isSame(el.minimumDate))?.depth,
        [extremes, value],
    );

    useEffect(() => {
        if (ref.current?.offsetWidth) {
            setContainerWidth(ref.current?.offsetWidth);
        }
    }, [ref]);

    if (labelValue) {
        return (
            <g>
                <foreignObject
                    x={Number(x) - containerWidth / 2}
                    y={Number(y) + 10}
                    width={containerWidth}
                    style={{
                        color: '#fff',
                    }}
                    height={40}
                >
                    <div
                        ref={ref}
                        style={{
                            marginTop: '5px',
                            width: 'auto',
                            backgroundColor: '#EB2F31',
                            borderRadius: 4,
                            fontSize: 13,
                            fontWeight: 500,
                            display: 'inline-block',
                            textAlign: 'center',
                            padding: '5px 10px',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                width: '8px',
                                height: '8px',
                                transform: 'rotate(45deg)',
                                backgroundColor: '#EB2F31',
                                position: 'absolute',
                                top: '-4px',
                                left: 'calc(50% - 4px)',
                            }}
                        />
                        {labelValue}
                        {'%'}
                    </div>
                </foreignObject>
            </g>
        );
    }

    return null;
};
