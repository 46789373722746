/* eslint-disable react/prop-types */
import { ActionIcon, Box, Text } from '@mantine/core';
import { type SortingState, createColumnHelper } from '@tanstack/react-table';
import { type Dispatch, type FC, type SetStateAction, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '@libs/components';

import { type PortfolioListItem } from '../../entities';
import { Pencil, Trash } from '../../shared/Icon';
import { Pagination, Table } from '../../shared/Table';
import { DeleteModal } from '../DeleteModal';
import { usePortfolios } from '../PortfoliosContext';
import { Sparkline } from './Sparkline';
import styles from './Table.module.css';

type Props = {
    data: PortfolioListItem[];
    isLoading: boolean;
    onPageChange: (page: number) => void;
    page: number;
    setSorting: Dispatch<SetStateAction<SortingState>>;
    sorting: SortingState;
    totalPages: number;
};

const columnHelper = createColumnHelper<PortfolioListItem>();

export const PortfolioTable: FC<Props> = ({ data, isLoading, onPageChange, page, setSorting, sorting, totalPages }) => {
    const { onPortfolioIdForEditChange } = usePortfolios();
    const [portfolioForDelete, setPortfolioForDelete] = useState<{
        portfolioName: string;
        portfolioId: string;
    }>();

    const handleDeleteModalClose = () => {
        setPortfolioForDelete(undefined);
    };

    const handlePatchPortfolio = useMemo(
        () => (portfolioId: string) => {
            onPortfolioIdForEditChange(portfolioId);
        },
        [onPortfolioIdForEditChange],
    );

    const columns = useMemo(
        () => [
            columnHelper.accessor('name', {
                id: 'name',
                header: 'Название портфеля',
                size: 411,
                cell: (props) => (
                    <Text
                        component={Link}
                        to={String(props?.row?.original?.uuid)}
                        style={{ textAlign: 'start' }}
                        className={styles.cell}
                        fz="14px"
                        lh="16px"
                        fs="normal"
                    >
                        {props.getValue() ?? '-'}
                    </Text>
                ),
            }),
            columnHelper.accessor('updatedAt', {
                id: 'updatedAt',
                header: 'Обновлен',
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                cell: (props) => (
                    <Text style={{ textAlign: 'end' }} fz="14px">
                        {new Date(props?.getValue())?.toLocaleDateString('ru') ?? '-'}
                    </Text>
                ),
            }),
            columnHelper.accessor('riskProfile', {
                id: 'riskProfile',
                header: 'Риск-профиль',
                enableSorting: false,
                size: 300,
                cell: (props) => <Text fz="14px">{props.getValue() ?? '-'}</Text>,
            }),
            columnHelper.accessor('liquidity', {
                id: 'liquidity',
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                header: 'Ликвидность',
                enableSorting: false,
                size: 145,
                cell: (props) => (
                    <Text style={{ textAlign: 'end' }} fz="14px">
                        {props.getValue() ?? '-'}
                    </Text>
                ),
            }),
            columnHelper.accessor('diversification', {
                id: 'diversification',
                header: 'Диверсификация',
                enableSorting: false,
                size: 145,
                cell: (props) => (
                    <Text style={{ textAlign: 'end' }} fz="14px">
                        {props.getValue() ?? '-'}
                    </Text>
                ),
            }),
            columnHelper.accessor('premiumPercent', {
                id: 'premiumPercent',
                header: 'Доходность, 5 лет',
                enableSorting: false,
                meta: {
                    headerCellStyles: {
                        whiteSpace: 'nowrap',
                    },
                },
                cell: (props) => (
                    <Text
                        fz="14px"
                        style={{
                            color: Number(props?.getValue()) > 0 ? '#26A65B' : '#E42821',
                            fontSize: '15px',
                            textAlign: 'end',
                        }}
                    >
                        {props.getValue() ?? '-'}
                    </Text>
                ),
            }),
            columnHelper.accessor('riskPercent', {
                id: 'riskPercent',
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                header: 'Просадка',
                enableSorting: false,
                cell: (props) => (
                    <Text
                        fz="14px"
                        style={{
                            color: Number(props?.getValue()) > 0 ? '#26A65B' : '#E42821',
                            fontSize: '15px',
                            textAlign: 'end',
                        }}
                    >
                        {props.getValue() ?? '-'}
                    </Text>
                ),
            }),
            columnHelper.accessor('sparkline', {
                id: 'sparkline',
                header: '',
                enableSorting: false,
                size: 153,
                meta: {
                    getCellStyles: () => ({
                        padding: '10px 20px',
                    }),
                },
                cell: (props) => <Sparkline points={props.getValue().points} />,
            }),
            columnHelper.accessor('uuid', {
                id: 'edit',
                header: '',
                enableSorting: false,
                size: 40,
                meta: {
                    cellAlign: 'center',
                    getCellStyles: () => ({
                        padding: 0,
                        textAlign: 'center',
                    }),
                },
                cell: ({ cell }) => (
                    <ActionIcon variant="gray" size={20} onClick={() => handlePatchPortfolio(cell.row.original.uuid)}>
                        <Pencil width={20} height={20} />
                    </ActionIcon>
                ),
            }),
            columnHelper.accessor('uuid', {
                id: 'remove',
                header: '',
                enableSorting: false,
                size: 40,
                meta: {
                    cellAlign: 'center',
                    getCellStyles: () => ({
                        padding: 0,
                        textAlign: 'center',
                    }),
                },
                cell: ({ cell }) => (
                    <ActionIcon
                        variant="gray"
                        size={16}
                        onClick={() => {
                            setPortfolioForDelete({
                                portfolioId: cell.row.original.uuid,
                                portfolioName: cell.row.original.name,
                            });
                        }}
                    >
                        <Trash width={16} height={16} />
                    </ActionIcon>
                ),
            }),
        ],
        [handlePatchPortfolio],
    );

    if (isLoading) {
        return <Loader isLoading={isLoading} withOverlay={true} />;
    }

    return (
        <Box w="100%">
            <Table
                columns={columns}
                data={data}
                striped={true}
                highlightOnHover={true}
                sorting={sorting}
                onSortingChange={setSorting}
                isDataLoading={isLoading}
                footer={<Pagination onChange={onPageChange} total={totalPages} value={page} />}
            />
            <DeleteModal
                onClose={handleDeleteModalClose}
                portfolioId={portfolioForDelete?.portfolioId}
                portfolioName={portfolioForDelete?.portfolioName}
            />
        </Box>
    );
};
