import { useLocalStorage } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { PortfolioFilterKeys } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { useApi } from '../../../api';
import { type DynamicsFilterValues, dynamicsFilterSchema } from './DynamicsFilter.schema';

export const useGetPortfolioDynamicsQuery = (
    portfolioId: string,
    filters?: DynamicsFilterValues,
    enabled = true,
    onStatusChange?: (status: 'error' | 'success' | 'pending') => void,
) => {
    const api = useApi();

    const [localStorageValue, setLocalStorageValue] = useLocalStorage({
        key: PortfolioFilterKeys.DYNAMICS_FILTER,
        defaultValue: {
            ...dynamicsFilterSchema.getDefault(),
            dates: [dayjs().subtract(10, 'years').toDate(), dayjs().toDate()],
        },
        getInitialValueInEffect: false,
    });

    useEffect(() => {
        if (filters) {
            setLocalStorageValue(filters as typeof localStorageValue);
        }
    }, [filters, setLocalStorageValue]);

    const query = useQuery({
        queryKey: ['portfolio', portfolioId, 'dynamics', 'performance', localStorageValue],
        queryFn: async () => await api.portfolio.dynamics.getPerformance({
            params: {
                dateFrom: localStorageValue.dates?.[0],
                dateTill: localStorageValue.dates?.[1],
                id: portfolioId,
            },
            data: localStorageValue.dynamicRequestDto,
        }),
        enabled: !!localStorageValue.dates?.[0] && !!localStorageValue.dates?.[1] && !!portfolioId && enabled,
    });

    useEffect(() => {
        if (query.isLoading || query.isFetching) {
            onStatusChange?.('pending');
        }

        if (query.isError) {
            onStatusChange?.('error');
        }

        if (query.isSuccess && !query.isLoading && !query.isFetching) {
            onStatusChange?.('success');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isLoading, query.isError, query.isSuccess, query.isFetching, query.isPending]);

    return query;
};
