import { type SelectButtonChangeEvent, SelectButton as SelectButtonComponent } from 'primereact/selectbutton';
import cn from 'classnames';

import './styles.scss';

type Option = {
    label: string;
    value: string | number;
};

type Props = {
    size?: 'small' | 'medium' | 'large';
    className?: string;
    value: string;
    handleValueChange: (value: string) => void;
    options: Option[];
};

export const SelectButton = ({ size = 'medium', className, value, handleValueChange, options }: Props) => {
    const handleChange = (e: SelectButtonChangeEvent) => {
        if (e.value) {
            handleValueChange(e.value as string);
        }
    };

    return (
        <div className="flex flex-column">
            <SelectButtonComponent
                className={cn(className, 'investor-pro-custom-select-button')}
                value={value}
                onChange={handleChange}
                optionLabel="label"
                options={options}
            />
        </div>
    );
};
