import { useEffect, useState } from 'react';
import {
    type BondMarketAmortisationData,
    type BondMarketAuctionData,
    type BondMarketCouponData,
    type BondMarketOfferData,
    type BondMarketRedemptionData,
} from '@libs/types';
import { HelpLineWrapper } from '@libs/components';
import {
    getBondsAuctionData,
    getBondsCouponsData,
    getBondsAmortisationsData,
    getBondsOffersData,
    getInstrumentName,
} from '@libs/services/instrument.service';
import { type GetBondsAmortisationsResponse, SortingDirections } from '@libs/types/instrument.type';
import { useLoading } from '@libs/utils/hooks/useLoading';

import { TableWithTitle } from './components/TableWithTitle';
import styles from './styles.module.scss';
import { AMMORTISATION_COLUMNS, AUCTION_COLUMNS, OFFERS_COLUMNS, REDEMPTION_COLUMNS, COUPONS_COLUMNS } from './columns';

enum DataType {
    auction = 0,
    coupons = 1,
    ammortisation = 2,
    offer = 3,
    redemption = 4,
}

export const CashFlow = () => {
    const [auctionBonds, setAuctionBonds] = useState<BondMarketAuctionData[]>([]);
    const [offerBonds, setOfferBonds] = useState<BondMarketOfferData[]>([]);
    const [ammortisationBonds, setAmmortisationBonds] = useState<BondMarketAmortisationData[]>([]);
    const [redemptionBonds, setRedemptionBonds] = useState<BondMarketRedemptionData[]>([]);
    const [couponsBonds, setCouponsBonds] = useState<BondMarketCouponData[]>([]);
    const { startLoading, stopLoading } = useLoading('bonds-market-cash-flow');

    useEffect(() => {
        const loadData = async () => {
            try {
                startLoading();

                const getAmmortizationData = async (flagFilter: boolean) => {
                    const MAX_TRIES = 30;
                    const COUNT_OF_AMMORTIZATIONS = 3;
                    let data: GetBondsAmortisationsResponse = [];

                    for (let i = 0; i < MAX_TRIES; i++) {
                        if (data.length === COUNT_OF_AMMORTIZATIONS) break;

                        const response = await getBondsAmortisationsData({
                            filter: 'Погашение согласно документам эмиссии',
                            flagFilter,
                            pageNumber: i + 1,
                            sortFields: 'mty_date',
                            sortingDirection: SortingDirections.ASC,
                        });

                        data = [...data, ...response.slice(0, COUNT_OF_AMMORTIZATIONS - data.length)];
                    }

                    return data;
                };

                const [auctionData, couponsData, ammortisationData, offerData, redemptionData, instrumentName] =
                    await Promise.allSettled([
                        getBondsAuctionData({
                            pageSize: 3,
                            sortFields: 'begdist_date',
                            sortingDirection: SortingDirections.ASC,
                        }),
                        getBondsCouponsData({
                            pageSize: 3,
                            sortFields: 'pay_date',
                            sortingDirection: SortingDirections.ASC,
                        }),
                        getAmmortizationData(false),
                        getBondsOffersData({
                            pageSize: 3,
                            sortFields: 'offer_date',
                            sortingDirection: SortingDirections.ASC,
                        }),
                        getAmmortizationData(true),
                        getInstrumentName(),
                    ]);
                const notFoundName: Array<{ id: number; type: DataType; index: number }> = [];

                if (instrumentName.status === 'rejected') {
                    console.error('Error while getting instrument name');

                    return;
                }

                const findInstrument = (id: number, type: DataType, index: number) => {
                    const instrument = instrumentName.value.data.find((instrument) => instrument.fintoolid === id);

                    if (!instrument) {
                        notFoundName.push({ id, type, index });

                        return null;
                    }

                    return instrument;
                };

                let auction: BondMarketAuctionData[] = [];
                let ammortisations: BondMarketAmortisationData[] = [];
                let coupons: BondMarketCouponData[] = [];
                let offers: BondMarketOfferData[] = [];
                let redemptions: BondMarketRedemptionData[] = [];

                if (auctionData.status === 'fulfilled') {
                    auction = auctionData.value.map(({ idFintool, begdistDate, askVal }, index) => ({
                        name: findInstrument(idFintool, DataType.auction, index)?.nickname ?? '',
                        begDistDate: begdistDate,
                        distVal: askVal,
                    }));
                } else {
                    console.error('Error while getting auction data');
                }

                if (couponsData.status === 'fulfilled') {
                    coupons = couponsData.value.map(({ idFintool, payDate, payPerBond }, index) => ({
                        name: findInstrument(idFintool, DataType.coupons, index)?.nickname ?? '',
                        payPerBond,
                        payDate,
                    }));
                } else {
                    console.error('Error while getting coupons data');
                }

                if (ammortisationData.status === 'fulfilled') {
                    ammortisations = ammortisationData.value
                        .slice(0, 3)
                        .map(({ idFintool, mtyDate, payPerBond, payPerMarket }, index) => ({
                            name: findInstrument(idFintool, DataType.ammortisation, index)?.nickname ?? '',
                            payPerBond,
                            payPerMarket,
                            mtyDate,
                        }));
                } else {
                    console.error('Error while getting ammortisation data');
                }

                if (offerData.status === 'fulfilled') {
                    offers = offerData.value.map(({ idFintool, offerDate }, index) => {
                        const instrument = findInstrument(idFintool, DataType.offer, index);

                        return {
                            name: instrument?.nickname ?? '',
                            offerDate,
                            sumIssueVal: instrument?.sumissueval ?? 0,
                        };
                    });
                } else {
                    console.error('Error while getting offer data');
                }

                if (redemptionData.status === 'fulfilled') {
                    redemptions = redemptionData.value
                        .slice(0, 3)
                        .map(({ idFintool, mtyDate, payPerMarket }, index) => ({
                            name: findInstrument(idFintool, DataType.redemption, index)?.nickname ?? '',
                            payPerMarket,
                            mtyDate,
                        }));
                } else {
                    console.error('Error while getting redemption data');
                }

                const notFoundNamesResponse = await Promise.all(
                    notFoundName.map(async ({ id }) => await getInstrumentName({ id })),
                );

                notFoundName.forEach(({ id, type, index }, i) => {
                    if (type === DataType.auction) {
                        auction[index].name = notFoundNamesResponse[i].data?.[0]?.nickname ?? '';
                    }

                    if (type === DataType.coupons) {
                        coupons[index].name = notFoundNamesResponse[i].data?.[0]?.nickname ?? '';
                    }

                    if (type === DataType.ammortisation) {
                        ammortisations[index].name = notFoundNamesResponse[i].data?.[0]?.nickname ?? '';
                    }

                    if (type === DataType.offer) {
                        offers[index].name = notFoundNamesResponse[i].data?.[0]?.nickname ?? '';
                        offers[index].sumIssueVal = notFoundNamesResponse[i].data?.[0]?.sumissueval ?? 0;
                    }

                    if (type === DataType.redemption) {
                        redemptions[index].name = notFoundNamesResponse[i].data?.[0]?.nickname ?? '';
                    }
                });

                setAuctionBonds(auction);
                setAmmortisationBonds(ammortisations);
                setCouponsBonds(coupons);
                setOfferBonds(offers);
                setRedemptionBonds(redemptions);
            } catch (error) {
                console.error('e', error);
            } finally {
                stopLoading();
            }
        };

        loadData();
    }, []);

    return (
        <div className={styles.cashFlowWrapper}>
            <h2>Денежный поток</h2>
            <div className={styles.dataContainer}>
                <HelpLineWrapper message="helpline.marketDashboards.bonds.placementOfBonds">
                    <TableWithTitle data={auctionBonds} columns={AUCTION_COLUMNS} title="Размещение" />
                </HelpLineWrapper>
                <HelpLineWrapper message="helpline.marketDashboards.bonds.redemptionOfBonds">
                    <TableWithTitle data={redemptionBonds} columns={REDEMPTION_COLUMNS} title="Погашение" />
                </HelpLineWrapper>
                <HelpLineWrapper message="helpline.marketDashboards.bonds.offersOnBonds">
                    <TableWithTitle data={offerBonds} columns={OFFERS_COLUMNS} title="Оферта" />
                </HelpLineWrapper>
                <HelpLineWrapper message="helpline.marketDashboards.bonds.bondCoupons">
                    <TableWithTitle data={couponsBonds} columns={COUPONS_COLUMNS} title="Купоны" />
                </HelpLineWrapper>
                <HelpLineWrapper message="helpline.marketDashboards.bonds.bondAmortization">
                    <TableWithTitle data={ammortisationBonds} columns={AMMORTISATION_COLUMNS} title="Амортизация" />
                </HelpLineWrapper>
            </div>
        </div>
    );
};
