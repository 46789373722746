import { ValueWithMeasure } from '@modules/Investorpro/modules/BondStockPage/shared/components/ValueWithMeasure';
import { round } from 'lodash';

import { type Column } from '../Table';
import { type Alert } from './services/types';
import styles from './styles.module.scss';
import { formatDate, formatTime } from '../../utils/format.util';

export const MAX_POSSIBLE_ALERTS_COUNT = 1_000_000;

export const PENDING_ALERTS_COLUMNS: Array<Column<Alert>> = [
    {
        id: 'name',
        label: 'Название',
        width: 'calc(100% - 174px)',
        align: 'left',
        render: (obj, { rowIndex }) => (
            <div
                className={styles.alertName}
                style={{ zIndex: MAX_POSSIBLE_ALERTS_COUNT - rowIndex }}
                data-read={obj.isRead}
            >
                {obj.shortName}
                <span className={styles.alertTicker}>{obj.secId}</span>
            </div>
        ),
    },
    {
        align: 'right',
        id: 'price',
        label: 'Целевая цена',
        width: '180px',
        render: (obj, { rowIndex }) => (
            <span
                className={styles.alertPrice}
                data-read={obj.isRead}
                style={{ zIndex: MAX_POSSIBLE_ALERTS_COUNT - rowIndex }}
            >
                <ValueWithMeasure
                    roundStart="none"
                    value={round(obj.price, 2)}
                    measureCLassName={styles.priceMeasure}
                    measure="RUB"
                />
            </span>
        ),
    },
];

export const FULLFILLED_ALERTS_COLUMNS: Array<Column<Alert>> = [
    {
        ...PENDING_ALERTS_COLUMNS[0],
        width: 'calc(100% - 284px)',
    },
    {
        ...PENDING_ALERTS_COLUMNS[1],
        width: '130px',
    },
    {
        id: 'date',
        type: 'date',
        label: 'Дата',
        width: '160px',
        align: 'right',
        render: (obj, { rowIndex }) => (
            <span
                className={styles.alertPrice}
                style={{ zIndex: MAX_POSSIBLE_ALERTS_COUNT - rowIndex }}
                data-read={obj.isRead}
            >
                {obj.triggerDate && formatDate(obj.triggerDate)}
                <br /> в {obj.triggerDate && formatTime(obj.triggerDate)}
            </span>
        ),
    },
];
