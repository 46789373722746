import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type GetCandlesDataResponse, type CandleType, type QuoteType } from '@modules/Investorpro/types/quote.type';
import {
    type FinanceAndAnalyticsInfo,
    type DividendData,
    type ProfitDividendInfo,
    type StockGeneralInfo,
    type StockInfo,
} from '@modules/Investorpro/modules/BondStockPage/StockPage/types';
import { type Period } from '@libs/utils/hooks/useChoosePeriod';
import { IntervalType, PeriodId } from '@libs/types/instrument.type';
import { subYears } from 'date-fns';
import {
    createCandlesWithPeriod,
    type CandlePeriodType,
} from '@modules/Investorpro/modules/BondStockPage/StockPage/utils/thunk.utils';
import { roundInstrumentPrice } from '@modules/Investorpro/modules/BondStockPage/shared/utils';
import {
    calculateAbsoluteChange,
    calculateRelativeChange,
    resetSliceState,
} from '@modules/Investorpro/shared/utils/slice.utils';

import {
    getStockBaseInfoThunk,
    getStockCandlesThunk,
    getStockDescriptionInfoThunk,
    getStockDividendsThunk,
    getInstrumentProfitCurvesDataThunk,
    getStockQuotesThunk,
} from '../../thunk';

export type IState = {
    info: StockInfo;
    fintoolId: number | null;
    fininstId: number | null;
    secId: string | null;
    baseInfo: {
        generalInfo: StockGeneralInfo;
        financials: FinanceAndAnalyticsInfo;
        datePeriodsProfit: Array<ProfitDividendInfo & { bid: string }>;
    };
    dividends: {
        total: number;
        data: DividendData[];
        page: number;
        loaded: boolean;
        initialized: boolean;
    };
    profitCurvesData: {
        maxVol: number;
        priceData: Array<{ x: string; y: number; vol: number }>;
        volData: Array<{ x: string; y: number; raw: number }>;
        loaded: boolean;
        initialized: boolean;
        period: {
            from: string;
            to: string;
            interval: number;
            arrowPos: string[];
        };
        currentPeriodOption: string | null;
    };
    candles: {
        data: CandleType[];
        loaded: boolean;
        rawCandles: GetCandlesDataResponse['data']['candles']['data'];
        periodOfCandles: CandlePeriodType;
        period: {
            from: string;
            to: string;
            interval: number;
            arrowPos: string[];
        };
        initialized: boolean;
        currentPeriodOption: string | null;
    };
    quotes: {
        data: QuoteType[];
        loaded: boolean;
    };
    loadings: {
        getStockCandles: boolean;
        getStockDividends: boolean;
        getStockDividendsGap: boolean;
        getStockQuotes: boolean;
        getProfitData: boolean;
    };
    errors: string[];
};

export const initialState: IState = {
    candles: {
        initialized: false,
        data: [],
        loaded: false,
        rawCandles: [],
        periodOfCandles: '1_MONTH',
        period: {
            from: subYears(new Date(), 1).toJSON(),
            to: new Date().toJSON(),
            interval: IntervalType.ONE_DAY,
            arrowPos: ['0'],
        },
        currentPeriodOption: PeriodId.ONE_YEAR,
    },
    quotes: {
        data: [],
        loaded: false,
    },
    info: {
        name: null,
        logo: null,
        prevTradeDayPrice: null,
        currentPrice: null,
        updatedAt: null,
        tradeVolume: null,
        avgTradeVolume10d: null,
        change: {
            relative: null,
            absolute: null,
        },
        dailyRange: {
            min: 0,
            max: 0,
        },
        yearlyRange: {
            min: 0,
            max: 0,
        },
    },
    fintoolId: null,
    fininstId: null,
    secId: null,
    baseInfo: {
        datePeriodsProfit: [],
        generalInfo: {
            about: null,
            capitalization: null,
            dividendYield: null,
            sector: null,
            founded: null,
            website: null,
            country: null,
            stockExchange: null,
            isin: null,
            earningsReportDate: null,
        },
        financials: {
            revenue: null,
            netProfit: null,
            pS: null,
            pE: null,
            evEbitda: null,
        },
    },
    profitCurvesData: {
        volData: [],
        priceData: [],
        initialized: false,
        maxVol: 0,
        loaded: false,
        period: {
            from: subYears(new Date(), 1).toJSON(),
            to: new Date().toJSON(),
            interval: IntervalType.ONE_DAY,
            arrowPos: ['0'],
        },
        currentPeriodOption: PeriodId.ONE_YEAR,
    },
    dividends: {
        total: 0,
        data: [],
        initialized: false,
        loaded: false,
        page: 0,
    },
    loadings: {
        getProfitData: false,
        getStockCandles: false,
        getStockDividends: false,
        getStockDividendsGap: false,
        getStockQuotes: false,
    },
    errors: [],
};

export const stockSlice = createSlice({
    name: 'investpro/stock',
    initialState,
    reducers: {
        setStockDividendsPage: (state, action: PayloadAction<number>) => {
            state.dividends.page = action.payload;
            state.dividends.loaded = false;
        },
        setProfitCurvesOption: (state, action: PayloadAction<string | null>) => {
            state.profitCurvesData.currentPeriodOption = action.payload;
        },
        setCandlesOptions: (state, action: PayloadAction<string | null>) => {
            state.candles.currentPeriodOption = action.payload;
        },
        setProfitCurvesPeriod: (state, action: PayloadAction<Period>) => {
            state.profitCurvesData.period.from = action.payload.from.toJSON();
            state.profitCurvesData.period.to = action.payload.to.toJSON();
            state.profitCurvesData.period.interval = action.payload.interval;
            state.profitCurvesData.period.arrowPos = action.payload.arrowPos;
        },
        resetStockPageData: (state) => {
            resetSliceState(state, initialState);
        },
        setCandlesPeriod: (state, action: PayloadAction<Period>) => {
            state.candles.period.from = action.payload.from.toJSON();
            state.candles.period.to = action.payload.to.toJSON();
            state.candles.period.interval = action.payload.interval;
            state.candles.period.arrowPos = action.payload.arrowPos;
        },
        setProfitCurvesLoadedStatus: (state, action: PayloadAction<boolean>) => {
            state.profitCurvesData.loaded = action.payload;
        },
        setCandlesLoadedStatus: (state, action: PayloadAction<boolean>) => {
            state.candles.loaded = action.payload;
        },
        updateLastCandleData: (
            state,
            action: PayloadAction<Array<GetCandlesDataResponse['data']['candles']['data'][number][number]>>,
        ) => {
            const candleWithInterval = action.payload.find((item) => item[2] === state.candles.period.interval);

            if (candleWithInterval) {
                const lastCandleData = [
                    [
                        (candleWithInterval[5] as number[])[0],
                        (candleWithInterval[8] as number[])[0],
                        (candleWithInterval[6] as number[])[0],
                        (candleWithInterval[7] as number[])[0],
                        (candleWithInterval[9] as number[])[0],
                        candleWithInterval[10],
                        candleWithInterval[3],
                        candleWithInterval[4],
                    ],
                ];

                const newRawCandles = [
                    ...state.candles.rawCandles.slice(0, state.candles.rawCandles.length - 1),
                    lastCandleData,
                ];

                state.candles.data = createCandlesWithPeriod(
                    newRawCandles,
                    state.candles.period.interval,
                    state.candles.periodOfCandles,
                    {
                        begin: 6,
                        end: 7,
                        high: 2,
                        low: 3,
                        close: 1,
                        open: 0,
                        value: 4,
                        volume: 5,
                    },
                ).map(({ open, close, high, low, ...item }) => ({
                    ...item,
                    open: roundInstrumentPrice(open),
                    close: roundInstrumentPrice(close),
                    high: roundInstrumentPrice(high),
                    low: roundInstrumentPrice(low),
                }));
                state.candles.rawCandles = newRawCandles;
            }
        },
        setCandlesData: (
            state,
            action: PayloadAction<{
                data: CandleType[];
                rawCandles: GetCandlesDataResponse['data']['candles']['data'];
            }>,
        ) => {
            state.candles.data = action.payload.data;
            state.candles.rawCandles = action.payload.rawCandles;
        },
        setQuotes: (state, action: PayloadAction<QuoteType[]>) => {
            state.quotes.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStockCandlesThunk.fulfilled, (state, action) => {
            state.candles.data = action.payload.candles;
            state.candles.loaded = true;
            state.candles.rawCandles = action.payload.rawCandles;
            state.candles.periodOfCandles = action.payload.periodOfCandles;
            state.candles.initialized = true;
            state.loadings.getStockCandles = false;
        });
        builder.addCase(getStockCandlesThunk.pending, (state) => {
            state.loadings.getStockCandles = true;
        });
        builder.addCase(getStockCandlesThunk.rejected, (state, action) => {
            state.loadings.getStockCandles = false;
            state.errors.push(action.error.message ?? 'thunk error');
        });
        builder.addCase(getStockQuotesThunk.fulfilled, (state, action) => {
            if (action.payload.length > 0) {
                state.quotes.data = action.payload;
            }
            state.quotes.loaded = true;
            state.loadings.getStockQuotes = false;
        });
        builder.addCase(getStockQuotesThunk.pending, (state) => {
            state.loadings.getStockQuotes = true;
        });
        builder.addCase(getStockQuotesThunk.rejected, (state, action) => {
            state.loadings.getStockQuotes = false;
            state.errors.push(action.error.message ?? 'thunk error');
        });
        builder.addCase(getStockBaseInfoThunk.rejected, (state, action) => {
            state.errors.push(action.error.message ?? 'thunk error');
        });
        builder.addCase(getStockBaseInfoThunk.fulfilled, (state, { payload }) => {
            state.fininstId = payload.fininstId;
            state.secId = payload.secId;
            state.fintoolId = payload.fintoolId;
            state.baseInfo.generalInfo.sector = payload.issuersector;
            state.baseInfo.generalInfo.website = payload.url ?? null;
            state.baseInfo.generalInfo.country = payload.country ?? null;
            state.baseInfo.generalInfo.stockExchange = payload.regOrg ?? null;
            state.baseInfo.generalInfo.isin = payload.isin;
            state.info.name = payload.shortName;
            state.info.currentPrice = payload.currentPrice;
            state.info.updatedAt = payload.lastPriceUpdate;
            state.profitCurvesData.period.to = payload.lastPriceUpdate;
            state.profitCurvesData.period.from = subYears(new Date(payload.lastPriceUpdate), 1).toJSON();
            state.candles.period.to = payload.lastPriceUpdate;
            state.candles.period.from = subYears(new Date(payload.lastPriceUpdate), 1).toJSON();
            state.info.tradeVolume = payload.currentVolume ?? null;
            state.info.avgTradeVolume10d = payload.avg10DaysVolume ?? null;
            state.info.prevTradeDayPrice = payload.prevPrice;
            state.info.change.absolute = calculateAbsoluteChange(state.info.currentPrice, state.info.prevTradeDayPrice);
            state.info.change.relative = calculateRelativeChange(state.info.currentPrice, state.info.prevTradeDayPrice);
            state.info.dailyRange.min = payload.dayMinPrice;
            state.info.dailyRange.max = payload.dayMaxPrice;
            state.baseInfo.generalInfo.isin = payload.isin;
            state.info.yearlyRange.min = payload.yearMinPrice;
            state.info.yearlyRange.max = payload.yearMaxPrice;
            state.baseInfo.generalInfo.capitalization = payload.capitalization ?? null;
        });
        builder.addCase(getStockDescriptionInfoThunk.rejected, (state, action) => {
            state.errors.push(action.error.message ?? 'thunk error');
        });
        builder.addCase(getStockDescriptionInfoThunk.fulfilled, (state, { payload }) => {
            state.baseInfo.generalInfo.about = payload.description ?? null;
            state.baseInfo.generalInfo.founded = payload.foundationDate
                ? new Date(payload.foundationDate).toLocaleDateString()
                : null;
            state.baseInfo.generalInfo.earningsReportDate = payload.nearestReportDate ?? null;
            const power = payload.profit?.[0]?.power ? Number(payload.profit?.[0]?.power) : 1;
            state.baseInfo.financials = {
                revenue: payload.profit?.[0]?.revenue ? Number(payload.profit?.[0]?.revenue) * power : null,
                netProfit: payload.profit?.[0]?.netIncome ? Number(payload.profit?.[0]?.netIncome) * power : null,
                pS: null,
                pE: payload.pe?.pe ?? null,
                evEbitda: payload.evEbitda ?? null,
            };
            state.baseInfo.financials.pS =
                state.baseInfo.generalInfo.capitalization && state.baseInfo.financials.revenue
                    ? state.baseInfo.generalInfo.capitalization / state.baseInfo.financials.revenue
                    : null;
            state.info.logo = payload.companyLogo;
            state.baseInfo.datePeriodsProfit = payload.stockPeriodProfits
                ? [{ bid: 'Цена', ...(payload.stockPeriodProfits ?? {}) }]
                : []
            state.baseInfo.generalInfo.dividendYield = payload.stockPeriodProfits?.y1y ?? null;
        });

        builder.addCase(getStockDividendsThunk.rejected, (state, action) => {
            state.loadings.getStockDividends = false;
            state.dividends.initialized = true;
            state.errors.push(action.error.message ?? 'thunk error');
        });
        builder.addCase(getStockDividendsThunk.pending, (state, action) => {
            state.loadings.getStockDividends = true;
        });
        builder.addCase(getStockDividendsThunk.fulfilled, (state, action) => {
            state.dividends.total = action.payload.count;
            state.dividends.data = action.payload.data;
            state.dividends.loaded = true;
            state.dividends.initialized = true;
            state.loadings.getStockDividends = false;
        });

        builder.addCase(getInstrumentProfitCurvesDataThunk.pending, (state) => {
            state.loadings.getProfitData = true;
        });
        builder.addCase(getInstrumentProfitCurvesDataThunk.fulfilled, (state, { payload }) => {
            state.loadings.getProfitData = false;
            state.profitCurvesData.priceData = payload.priceData;
            state.profitCurvesData.volData = payload.volData;
            state.profitCurvesData.maxVol = payload.maxVol;
            state.profitCurvesData.loaded = true;
            state.profitCurvesData.initialized = true;
        });
        builder.addCase(getInstrumentProfitCurvesDataThunk.rejected, (state, action) => {
            state.loadings.getProfitData = false;
            state.profitCurvesData.initialized = true;
            state.errors.push(action.error.message ?? 'thunk error');
        });
    },
});

export const stockReducer = stockSlice.reducer;

export const {
    resetStockPageData,
    setCandlesData,
    setQuotes,
    setStockDividendsPage,
    setProfitCurvesLoadedStatus,
    setProfitCurvesOption,
    setProfitCurvesPeriod,
    setCandlesPeriod,
    setCandlesLoadedStatus,
    setCandlesOptions,
    updateLastCandleData,
} = stockSlice.actions;
