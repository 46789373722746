import { type MantineStyleProp, SegmentedControl } from '@mantine/core';
import { type FC } from 'react';

type Button = {
    label: string;
    value: string;
};

type Props = {
    value: string;
    onValueChange: (value: string) => void;
    buttons: Button[];
    style?: MantineStyleProp;
};

export const SegmentController: FC<Props> = ({ value, onValueChange, buttons, style }) => (
    <SegmentedControl
        style={style}
        data={buttons}
        value={value}
        onChange={onValueChange}
        p={0}
        color="#14171F"
        radius={5}
        fz={14}
        fw={400}
    />
);
