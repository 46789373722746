import { Group, Stack, Text } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { type FC } from 'react';
import { useParams } from 'react-router-dom';

import { PortfolioFilterKeys } from '../../../entities/portfolio';
import { ViewError } from '../../../shared/Layout';
import { SegmentController } from '../../../shared/SegmentController';
import { type InferParams } from '../../../shared/router';
import { CashFlowTable } from './CashFlowTable';
import { Chart } from './Chart/Chart';
import { useCashFlowQuery } from './useCashFlowQuery';

export const CashFlow: FC = () => {
    const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;

    const [cashFlowType, setCashFlowType] = useLocalStorage<string>({
        key: PortfolioFilterKeys.CASHFLOW_FILTER,
        defaultValue: 'ANNUAL',
        getInitialValueInEffect: false,
    });

    const portfolioCashFlowQuery = useCashFlowQuery(portfolioId, cashFlowType);

    if (portfolioCashFlowQuery.isError) {
        return (
            <Stack>
                <Text fz={19} lh="18px">
                    {'Денежный поток'}
                </Text>
                <ViewError
                    error={portfolioCashFlowQuery.error}
                    subtitle="Ошибка получения данных по денежному потоку"
                />
            </Stack>
        );
    }

    return (
        <Group
wrap="nowrap" align="flex-start" gap={60}
w="100%">
            <Stack gap={33} w="30%">
                <Group justify="space-between" wrap="nowrap">
                    <Text fz={19} lh="18px">
                        {'Денежный поток'}
                    </Text>
                    <SegmentController
                        value={cashFlowType}
                        onValueChange={setCashFlowType}
                        buttons={[
                            { label: 'Ежегодный', value: 'ANNUAL' },
                            { label: 'Накопленный', value: 'ACCUMULATED' },
                        ]}
                    />
                </Group>
                <CashFlowTable data={portfolioCashFlowQuery?.data ?? []} isPending={portfolioCashFlowQuery.isPending} />
            </Stack>
            <Stack gap={49} w="70%" h={500}>
                <Text pl={15} fz={19} lh="22px">
                    {'Среднегодовая доходность денежного потока'}
                </Text>
                <Chart
                    data={portfolioCashFlowQuery.data ?? []}
                    isPending={portfolioCashFlowQuery.isPending}
                    cashFlowType={cashFlowType}
                />
            </Stack>
        </Group>
    );
};
