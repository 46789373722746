import { classNames } from 'primereact/utils';

import styles from './styles.module.scss';

export const HasNotPermissionsLabel = () => {
    return (
        <p
            className={classNames(
                'flex',
                'justify-content-center',
                'align-items-center',
                styles.hasNotPermissionsLabel,
            )}
        >
            Для доступа к запрашиваемой странице
            <br />
            необходимо оплатить подписку
        </p>
    );
};
