import {
    type PortfolioDynamicsRequestData,
    type PortfolioDynamicsRequestParams,
    type PortfolioDynamicsResponseData,
} from '../../../../../PortfolioPage/entities';
import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getPerformance: ApiFactory<ApiEndpoint<
    PortfolioDynamicsResponseData, PortfolioDynamicsRequestData, PortfolioDynamicsRequestParams
>> = (client) => async ({ signal, data, params: { id, ...params } }) => {
    const res = await client.post<PortfolioDynamicsResponseData>(
        `/moex-datashop-investor-pro-portfolios-service/api/v2/dynamics/performance/${id}`,
        data,
        { params, signal },
    );

    return res.data;
};
