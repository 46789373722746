import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const KeyRateLabel: FC<Props> = ({ width = 22, height = 22, color = 'currentColor' }) => (
    <svg
width={width} height={height} viewBox="0 0 1.76 1.76"
xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path fill={color} d="M1.705.88a.825.825 0 0 1-.825.825A.825.825 0 0 1 .055.88a.825.825 0 0 1 1.65 0" />
        <path d="M.555.481H.72v.33l.308-.33h.214L.916.808l.344.47h-.215L.8.929l-.08.083v.267H.555z" fill="#fff" />
    </svg>
);
