import { BreadCrumb } from '@modules/Investorpro/shared/components/BreadCrumb';
import { StompProvider } from '@libs/utils';
import { useHelplineDefaultMessage } from '@libs/utils/hooks/useDefaultMessage';

import { StockMarketDashboard } from './components/StockMarketDashboard';

export const StockMarketPage = () => {
    useHelplineDefaultMessage('helpline.marketDashboards.stocks.page');

    return (
        <div>
            <StompProvider url={process.env.REACT_APP_WS_QUOTESERVICE_URL!} autoConnect>
                <BreadCrumb />
                <StockMarketDashboard />
            </StompProvider>
        </div>
    );
};
