import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { type FC, type ReactNode } from 'react';
import { httpClient } from '@configs/httpclient.config';

import { ApiProvider, createEndpoints } from '../api';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});

type Props = {
    children: ReactNode;
};

export const WithApi: FC<Props> = ({ children }) => (
    <QueryClientProvider client={queryClient}>
        <ApiProvider endpoints={createEndpoints(httpClient)}>{children}</ApiProvider>
    </QueryClientProvider>
);
