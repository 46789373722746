import {
    type TickerDictionaryItem,
    type TickerDictionaryRequestParams,
} from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const getTickerDictionary: ApiFactory<
    ApiEndpoint<TickerDictionaryItem[], void, TickerDictionaryRequestParams>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<TickerDictionaryItem[]>(
        '/moex-datashop-investor-pro-portfolios-service/api/v1/ticker/dictionary',
        { signal, params },
    );

    return data;
};
