import { DatesProvider } from '@mantine/dates';
import 'dayjs/locale/ru';
import { type FC, type ReactNode } from 'react';

import { PortfoliosProvider } from '../components/PortfoliosContext';
import { WithApi } from './WithApi';
import { WithMantine } from './WithMantine';

type Props = {
    children: ReactNode;
};

export const Providers: FC<Props> = ({ children }) => (
    <WithMantine>
        <WithApi>
            <DatesProvider settings={{ locale: 'ru', timezone: 'UTC' }}>
                <PortfoliosProvider>{children}</PortfoliosProvider>
            </DatesProvider>
        </WithApi>
    </WithMantine>
);
