import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import { type RootState } from '@store/store';
import {
    type FiltersGroupType,
    type FiltersInstrumentType,
    type FilterType,
    type ScreenerFilterValueType,
} from '@modules/Investorpro/modules/ScreenersPage/types';
import { filterIds, prepareCompliance } from '@modules/Investorpro/modules/ScreenersPage/components/Screener/utils';
import { Button } from 'primereact/button';
import { type FieldArrayWithId } from 'react-hook-form';

import { ReactComponent as SearchIcon } from '../../../../../../shared/images/svg/Search.svg';
import styles from '../../styles.module.scss';

type FiltersListProps = {
    activeInstrumentTypeId?: FiltersInstrumentType['id'];
    fields: Array<
        FieldArrayWithId<{
            filters: Array<ScreenerFilterValueType & { filterId: FilterType['id'] }>;
        }>
    >;
    preparedFilters: Record<FilterType['id'], FilterType>;
    handleSwitchFilter: (filterId: FilterType['id'], value?: boolean) => void;
};

export const FiltersList = ({
    activeInstrumentTypeId,
    fields,
    preparedFilters,
    handleSwitchFilter,
}: FiltersListProps) => {
    const { filters, groups } = useSelector((state: RootState) => state.screeners.filters);

    // region Поиск
    const [searchValue, setSearchValue] = useState<string>('');
    // endregion Поиск

    // region Группы фильтров
    const [preparedGroups, setPreparedGroups] = useState<Record<FiltersGroupType['id'], FiltersGroupType>>({});
    const [filteredGroupsIds, setFilteredGroupsIds] = useState<Array<FiltersGroupType['id']>>([]);
    const [activeGroupIndex, setActiveGroupIndex] = useState<number>(0);

    // Подготовка групп
    useEffect(() => {
        setPreparedGroups(prepareCompliance(groups));
        setFilteredGroupsIds(filterIds(groups, activeInstrumentTypeId, 'instrumentTypeId'));
    }, [groups]);

    // Фильтрация групп для выбранного типа инструмента
    useEffect(() => {
        setFilteredGroupsIds(filterIds(groups, activeInstrumentTypeId, 'instrumentTypeId'));
        setActiveGroupIndex(0);
    }, [activeInstrumentTypeId]);
    // endregion Группы фильтров

    // region Фильтры
    const [filteredFilters, setFilteredFilters] = useState<Array<FilterType['id']>>([]);

    // Фильтрация фильтров для выбранной группы
    useEffect(() => {
        if (searchValue) {
            const compareSearchValue: string = searchValue.toLowerCase();
            const updatedSearchResult = filters
                .filter(({ name }) => name.toLowerCase().includes(compareSearchValue))
                .map(({ id }) => id);
            setFilteredFilters(updatedSearchResult);
        } else {
            setFilteredFilters(filterIds(filters, filteredGroupsIds[activeGroupIndex], 'groupIds'));
        }
    }, [activeGroupIndex, filteredGroupsIds, preparedFilters, searchValue]);
    // endregion Фильтры

    const renderFilterItem = (filterId: FilterType['id']) => {
        const { fieldName, name } = preparedFilters[filterId];

        return (
            <label key={`filter-${filterId}`} className={classNames(styles.toolsAddListItem)}>
                <span>{name}</span>
                <InputSwitch
                    name={fieldName}
                    checked={fields.some((filter) => filter.filterId === filterId)}
                    onChange={(e) => handleSwitchFilter(filterId, e.value)}
                    className={classNames(styles.switch)}
                />
            </label>
        );
    };

    if (!filters) {
        return (
            <div>
                <h1>...loader mock</h1>
            </div>
        );
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.searchWrapper}>
                <SearchIcon className={styles.searchIcon} />
                <InputText
                    v-model="value1"
                    placeholder="Поиск"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className={classNames(styles.searchInput)}
                />
                <Button
                    icon="pi pi-times"
                    rounded
                    text
                    size="small"
                    aria-label="Cancel"
                    className={styles.clearIcon}
                    onClick={() => setSearchValue('')}
                />
            </div>
            {searchValue
? (
                <TabView activeIndex={0} className={styles.tabView}>
                    <TabPanel header="Результат поиска" className={classNames(styles.tabViewCustom)}>
                        <ul className={classNames(styles.toolsAddList, styles.customScroll)}>
                            {filteredFilters.map(renderFilterItem)}
                        </ul>
                    </TabPanel>
                </TabView>
            )
: (
                <TabView
                    activeIndex={activeGroupIndex}
                    onTabChange={({ index }) => setActiveGroupIndex(index)}
                    className={styles.tabView}
                    scrollable
                >
                    {filteredGroupsIds.map((groupId) => {
                        return (
                            <TabPanel
                                key={groupId}
                                header={preparedGroups[groupId]?.name}
                                className={classNames(styles.tabViewCustom)}
                            >
                                <ul className={classNames(styles.toolsAddList, styles.customScroll)}>
                                    {filteredFilters.map(renderFilterItem)}
                                </ul>
                            </TabPanel>
                        );
                    })}
                </TabView>
            )}
        </div>
    );
};
