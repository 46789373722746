import { Group, Stack, Text } from '@mantine/core';
import { type TooltipProps } from 'recharts';
import { type NameType, type ValueType } from 'recharts/types/component/DefaultTooltipContent';
import dayjs from 'dayjs';

import { Circle } from '../../../../shared/Icon';

export const renderTooltipContent = ({ payload, active, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload?.length) {
        return (
            <Stack gap={10}>
                <Text fz={14} lh="20px" ff="Inter">
                    {dayjs(label as string).format('DD.MM.YYYY')}
                </Text>
                <Stack gap={4}>
                    {payload.map((entry) => (
                        <Group justify="space-between" key={entry.name}>
                            <Group gap={8}>
                                <Circle width={8} height={8} color={entry.color} />
                                <Text
fw={300} fz={14} lh="20px"
c="#88919A">
                                    {entry.name}
                                </Text>
                            </Group>
                            <Text ff="Inter" fz={13} lh="20px">
                                {entry.value}
                                {'%'}
                            </Text>
                        </Group>
                    ))}
                </Stack>
            </Stack>
        );
    }

    return null;
};
