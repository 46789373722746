import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const Check: FC<Props> = ({ width = 24, height = 24, color = 'currentColor' }) => (
    <svg
width={width} height={height} viewBox="0 0 24 24"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 12.1429L9.83955 16.7849C9.96211 16.9024 10.1577 16.8949 10.2709 16.7683L19 7"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
        />
    </svg>
);
