import { Box, Button, Group, LoadingOverlay, Stack, Text, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useMutation } from '@tanstack/react-query';
import { Fragment, useState, type FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApi } from '../api';
import { type PortfolioRequestData } from '../entities';
import { Breadcrumbs } from '../shared/Breadcrumbs';
import { notification } from '../shared/Layout';
import { ListOfTools } from './ListOfTools/ListOfTools';
import { SelectedTools } from './ListOfTools/SelectedTools';
import { SelectedToolsHeader } from './ListOfTools/SelectedToolsHeader';
import { portfolioFormSchema, type PortfolioFormValues, type PortfolioToolValues } from './PortfolioForm.schema';
import { PortfolioMainSettings } from './PortfolioMainSettings';

export const CreatePortfolio: FC = () => {
    const api = useApi();
    const navigate = useNavigate();
    const [portfolioCurrency, setPortfolioCurrency] = useState('RUB');

    const breadcrumbs = [
        { title: 'Главная', href: '/portfolio' },
        { title: 'Портфели', href: '/portfolio' },
    ];

    const form = useForm<PortfolioFormValues>({
        initialValues: portfolioFormSchema.getDefault(),
        validate: yupResolver(portfolioFormSchema),
    });

    const resetAllTickers = () => {
        form.setFieldValue('tickers', []);
    };

    const handleCancel = () => {
        navigate('/portfolio');
    };

    const createPortfolioMutation = useMutation({
        mutationKey: ['portfolio', 'create'],
        mutationFn: async (data: PortfolioRequestData) => await api.portfolio.createPortfolio({
                data,
            }),
        onSuccess: () => {
            navigate('/portfolio');
            notification('success', 'Портфель добавлен.');
        },
        onError: () => {
            notification('error', 'Произошла ошибка при добавлении портфеля.');
        },
    });

    const handleToolsChange = (tools: PortfolioToolValues[]) => {
        form.setFieldValue('tickers', tools);
    };

    const handleSubmit = (values: PortfolioFormValues) => {
        createPortfolioMutation.mutate(values);
    };

    return (
        <Fragment>
            <Stack mb={66} gap={30}>
                <Breadcrumbs items={breadcrumbs} />
                <Text fz="24px" lh="24px" fw="400">
                    {'Создать портфель'}
                </Text>
            </Stack>

            <form onSubmit={form.onSubmit(handleSubmit)}>
                <Stack gap={0} pos="relative">
                    <LoadingOverlay visible={createPortfolioMutation.isPending} zIndex={1} />
                    <Group gap="28px" mb={48} align="flex-start">
                        <TextInput
                            {...form.getInputProps('name')}
                            w={295}
                            label="Название портфеля"
                            placeholder="Введите название"
                        />
                        <PortfolioMainSettings form={form} onPortfolioCurrencyChange={setPortfolioCurrency} />
                    </Group>
                    <Group
                        w="100%"
                        gap={0}
                        align="flex-start"
                        wrap="nowrap"
                        style={{ borderBottom: '1px solid #D8E1EB' }}
                    >
                        <Box w={620} h={498}>
                            <ListOfTools tickers={form.values.tickers} onToolsChange={handleToolsChange} />
                        </Box>
                        <Stack w="100%" gap={0}>
                            <SelectedToolsHeader resetAllTickers={resetAllTickers} />
                            <Box w="100%" bg="#F2F7FE">
                                <Box
h={567} w={702} pl={50}
pt={28}>
                                    <SelectedTools
                                        form={form}
                                        onToolsChange={handleToolsChange}
                                        portfolioCurrency={portfolioCurrency}
                                    />
                                </Box>
                            </Box>
                        </Stack>
                    </Group>
                    <Group justify="end" align="center" pt={40}>
                        <Group gap={26} wrap="nowrap">
                            <Button
w={196} size="large" variant="cancel"
fullWidth={true} onClick={handleCancel}>
                                {'Отменить'}
                            </Button>
                            <Button
type="submit" variant="primary" w={196}
size="large" fullWidth={true}>
                                {'Создать'}
                            </Button>
                        </Group>
                    </Group>
                </Stack>
            </form>
        </Fragment>
    );
};
