import { type TopFiveByProfitability } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getTopFiveTotalReturn: ApiFactory<ApiEndpoint<
    TopFiveByProfitability[], void, { portfolioId: string }
>> = (client) => async ({ signal, params }) => {
    const res = await client.get<TopFiveByProfitability[]>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/asset-typology/${params.portfolioId}/most-total-yield`,
        { signal },
    );

    return res.data;
};
