import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    addSecurityToWatchList,
    copyWatchlist,
    createWatchlist,
    deleteSecurityFromWatchlist,
    deleteWatchlist,
    getWatchlistData,
    getWatchlists,
    updateWatchlist,
} from '@modules/Investorpro/shared/services/investorprofile/watchlist.service';
import {
    type CopyWatchlistReq,
    type Watchlist,
    type WatchlistDataReq,
} from '@modules/Investorpro/types/watchlist.type';
import { type Security, type SecurityType } from '@modules/Investorpro/modules/ScreenersPage/types';

export const updateWatchlistThunk = createAsyncThunk(
    'investorpro/updateWatchlist',
    async ({ watchlistId, body }: { watchlistId: number; body: { name: string } }, { rejectWithValue }) => {
        try {
            return await updateWatchlist(watchlistId, body);
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const deleteWatchlistThunk = createAsyncThunk(
    'investorpro/deleteWatchlist',
    async (watchlistId: number, { rejectWithValue }) => {
        try {
            await deleteWatchlist(watchlistId);

            return watchlistId;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const addSecurityToWatchListThunk = createAsyncThunk(
    'investorpro/addSecurityToWatchList',
    async (
        {
            data: { isin },
            watchlistId,
        }: { watchlistId: number; data: { isin: string; exchange?: string; type?: SecurityType } },
        { rejectWithValue },
    ) => {
        try {
            if (isin && watchlistId) {
                return await addSecurityToWatchList(watchlistId, { isin });
            }

            return null;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const deleteSecurityFromWatchlistThunk = createAsyncThunk(
    'investorpro/deleteSecurityFromWatchlist',
    async (
        { securityId, watchlistId }: { watchlistId: Watchlist['id']; securityId: Security['id'] },
        { rejectWithValue },
    ) => {
        try {
            if (securityId && watchlistId) {
                return await deleteSecurityFromWatchlist(watchlistId, securityId);
            }

            return null;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const getWatchlistDataThunk = createAsyncThunk(
    'investorpro/getWatchlistData',
    async ({ watchlistId, body }: { watchlistId: number; body: WatchlistDataReq }, { rejectWithValue }) => {
        try {
            const { data } = await getWatchlistData(watchlistId, body);

            return data.data;
            // return watchlistRows;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const copyWatchlistThunk = createAsyncThunk(
    'investorpro/copyWatchlist',
    async (body: CopyWatchlistReq, { rejectWithValue }) => {
        try {
            return await copyWatchlist(body);
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const getWatchlistsThunk = createAsyncThunk('investorpro/getWatchlists', async (_, { rejectWithValue }) => {
    try {
        return await getWatchlists();
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const createWatchlistThunk = createAsyncThunk(
    'investorpro/createWatchlist',
    async (body: { name: string }, { rejectWithValue }) => {
        try {
            return await createWatchlist(body);
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
