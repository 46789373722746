import { Font, Image, Page, StyleSheet } from '@react-pdf/renderer';
import { type FC, Fragment } from 'react';
import { Html } from 'react-pdf-html';

import { type NewReportItem } from '../../../entities/report';
import { componentPdfStyles } from './PdfStyles';
import { PdfPageFooter } from './PdfPageFooter';

type Props = {
    screens: string[];
    reportItem: NewReportItem;
    withPageNumbers?: boolean;
    comment: string;
};

Font.register({
    family: 'Inter',
    fonts: [
        { src: '/fonts/Inter-Regular.ttf', fontWeight: 'normal' },
        { src: '/fonts/Inter-Italic.ttf', fontStyle: 'italic' },
    ],
});

const styles = StyleSheet.create({
    comment: {
        fontFamily: 'Inter',
        width: 560,
    },
});

export const PdfPage: FC<Props> = ({ reportItem, screens, withPageNumbers = false, comment }) => (
    <Fragment>
        {screens.map((screen, index) => {
            const i = index;

            return (
                <Page key={`${reportItem.id}_${i}`} size="A4" style={componentPdfStyles.page}>
                    <Image src={screen} style={componentPdfStyles.componentScreen} />
                    {comment && i === screens.length - 1 ? <Html style={styles.comment}>{comment}</Html> : null}
                    <PdfPageFooter withPageNumbers={withPageNumbers} title={reportItem.title} />
                </Page>
            );
        })}
    </Fragment>
);
