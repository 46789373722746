import { Text } from '@mantine/core';
import { type UseQueryResult } from '@tanstack/react-query';
import { type FC, Fragment } from 'react';
import {
    type PortfolioTypologyAssetsMatrixData,
    TypologyAssetsBonds,
} from '@modules/Investorpro/modules/PortfolioPage/entities';

import { ViewError, ViewLoader } from '../../../shared/Layout';
import { Matrix } from './Matrix/Matrix';

// TODO: Не передавать весь квэри
type Props = {
    typologyAssetsBondsQuery: UseQueryResult<PortfolioTypologyAssetsMatrixData, Error>;
    isInReport?: boolean;
};

export const TypologyAssetsBondsMatrix: FC<Props> = ({ typologyAssetsBondsQuery, isInReport }) => {
    if (typologyAssetsBondsQuery.isError) {
        return (
            <Fragment>
                <Text fz={19} fw={400}>
                    {'Облигации'}
                </Text>
                <ViewError error={typologyAssetsBondsQuery.error} subtitle="Ошибка получения данных по облигациям" />
            </Fragment>
        );
    }

    if (typologyAssetsBondsQuery.isPending) {
        return (
            <Fragment>
                <Text fz={19} fw={400}>
                    {'Облигации'}
                </Text>
                <ViewLoader />
            </Fragment>
        );
    }

    return (
        <Fragment>
            {!isInReport
? (
                <Text fz={19} fw={400}>
                    {'Облигации'}
                </Text>
            )
: null}
            <Matrix matrixData={typologyAssetsBondsQuery.data} keysDecryption={TypologyAssetsBonds} />
        </Fragment>
    );
};
