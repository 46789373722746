import { Text } from '@mantine/core';
import { type FC, Fragment } from 'react';

import { ViewError, ViewLoader } from '../../../../shared/Layout';
import { Table } from '../../../../shared/Table';
import { useConfigureColumns } from './useConfigureColumns';
import { useGetTopFiveReturnOnValueQuery } from './useGetTopFiveReturnOnValueQuery';

type Props = {
    portfolioId: string;
};

export const ReturnOnValueTable: FC<Props> = ({ portfolioId }) => {
    const columns = useConfigureColumns();
    const getTopFiveReturnOnValueQuery = useGetTopFiveReturnOnValueQuery(portfolioId);

    if (getTopFiveReturnOnValueQuery.isError) {
        return (
            <Fragment>
                <Text fz={19} fw={400}>
                    {'ТОП 5 по доходности от изменения стоимости'}
                </Text>
                <ViewError
                    error={getTopFiveReturnOnValueQuery.error}
                    subtitle="Ошибка получения данных ТОП 5 по доходности от изменения стоимости"
                />
            </Fragment>
        );
    }

    if (getTopFiveReturnOnValueQuery.isPending) {
        return (
            <Fragment>
                <Text fz={19} lh="22px" fw={400}>
                    {'ТОП 5 по доходности от изменения стоимости'}
                </Text>
                <ViewLoader />
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Text fz={19} lh="22px" fw={400}>
                {'ТОП 5 по доходности от изменения стоимости'}
            </Text>
            <Table
                columns={columns}
                data={getTopFiveReturnOnValueQuery.data ?? []}
                striped={true}
                highlightOnHover={true}
            />
        </Fragment>
    );
};
