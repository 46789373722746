import { httpClient } from '@configs/httpclient.config'
import {
    type GetProfitCurveDataResponse,
    type GeScatterProfitPointsRequestResponse,
} from '@libs/types/integration.type'
import { formatDateToQuery } from '@libs/utils'

const INTEGRATION_URL = '/moex-propack-integrationservice/'

export const getProfitCurveData = async (date: Date): Promise<GetProfitCurveDataResponse> => {
    return await httpClient.post(INTEGRATION_URL + 'api/curves/v1/profit-curve', {
        date: formatDateToQuery(date),
    })
}

export const getScatterProfitPoints = async (tradDate: Date):
    Promise<GeScatterProfitPointsRequestResponse> => {
        return await httpClient.post(INTEGRATION_URL + 'api/v1/instrument-points/', {
            tradeDate: formatDateToQuery(tradDate),
            methodNumber: 1,
            minYield: 0,
            maxYield: 10000,
        })
}
