import { BarController, BarElement, type BarOptions, type Point, type Scale } from 'chart.js'
import { clipArea, unclipArea } from 'chart.js/helpers'
import 'chart.js/auto';
import 'chartjs-adapter-luxon'
import { parseISO } from 'date-fns';

import { commonChartOptions } from '../../../../../../../../../../configs/commonChartOptions';


type CandlestickData = {
    x: number;
    open: number;
    high: number;
    low: number;
    close: number;
}

export type BarParsedData = {
    open: number;
    high: number;
    low: number;
    close: number;
    x: string;
    volume: number;
    maxVolume: number;
    minVolume: number;
}

type BarData = BarParsedData & {
    x: string;
}

type UpdateElementMode = 'reset' | 'resize' | 'none' | 'hide' | 'show' | 'default' | 'active'

class FinancialController extends BarController {
    static overrides = {
        label: '',
        parsing: false,
        hover: {
            mode: 'label',
        },
        animations: {
            numbers: {
            type: 'number',
            properties: ['x', 'y', 'base', 'width', 'open', 'high', 'low', 'close'],
            },
        },

        scales: {
            ...commonChartOptions.scales,
            x: {
                ...commonChartOptions.scales.x,
                type: 'timeseries',
                offset: true,
                ticks: {
                    major: {
                        enabled: true,
                    },
                    source: 'data',
                    maxRotation: 0,
                    autoSkip: true,
                    autoSkipPadding: 75,
                    sampleSize: 100,
                    color: '#8A96A6',
                    font: {
                        size: 11,
                        weight: 400,
                        family: 'Inter',
                    },
                },
            },
        },
    };

    getAllParsedValues(scale: Scale) {
        const parsed = this._cachedMeta._parsed;

        return parsed
            .filter((el: any) => Number.isFinite(el[scale.axis]))
            .map((el: any) => el[scale.axis]);
    }

    parse() {
        // @ts-expect-error unhandled type error
        const { _cachedMeta: meta, _data } = this;
        const { iScale } = meta;
        // @ts-expect-error unhandled type error
        const iAxis = iScale.axis;

        const data = _data.map((data: BarData) => ({
            ...data,
            // @ts-expect-error unhandled type error
            x: parseISO(data[iAxis]).getTime(),
        }));

        meta._parsed = data;
        meta._sorted = true;
    }

    getLabelAndValue(index: number) {
        const { o, h, l, c } = this.getParsed(index) as any;
        const value = `O: ${o}  H: ${h}  L: ${l}  C: ${c}`;

        return {
            label: 'test',
            value,
        };
    }

    _getRuler() {
        // @ts-expect-error unhandled type error
        const opts = this.options;
        const meta = this._cachedMeta;
        const { iScale } = meta;
        const pixels = [];
        let i, ilen;

        for (i = 0, ilen = meta.data.length; i < ilen; i++) {
            const parsedValue = this.getParsed(i)

            if (parsedValue) {
                // @ts-expect-error unhandled type error
                const pixel = iScale?.getPixelForValue(parsedValue[iScale.axis], i);
                pixels.push(pixel);
            }
        }
        const { barThickness } = opts;
        const min = 2

        return {
            min,
            pixels,
            // @ts-expect-error unhandled type error
            start: iScale._startPixel,
            // @ts-expect-error unhandled type error
            end: iScale._endPixel,
            // @ts-expect-error unhandled type error
            stackCount: this._getStackCount(),
            scale: iScale,
            grouped: opts.grouped,
            ratio: barThickness ? 1 : opts.categoryPercentage * opts.barPercentage
        };
    }

    getUserBounds(scale: Scale) {
        const { min, max, minDefined, maxDefined } = scale.getUserBounds();

        return {
            min: minDefined ? min : Number.NEGATIVE_INFINITY,
            max: maxDefined ? max : Number.POSITIVE_INFINITY,
        };
    }

    barPartSize = 0.3

    // @ts-expect-error unhandled type error
    getMinMax(scale: Scale) {
        const meta = this._cachedMeta;
        const parsed = meta._parsed as BarParsedData[];
        const axis = meta.iScale?.axis as keyof BarParsedData;
        // @ts-expect-error unhandled type error
        const otherScale = this._getOtherScale(scale);
        const { min: otherMin, max: otherMax } = this.getUserBounds(otherScale);

        if (parsed.length === 0) {
            return { min: 0, max: 1 };
        }

        if (scale === meta.iScale && axis) {
            return {min: parsed[0][axis], max: parsed[parsed.length - 1][axis]};
        }

        // const newParsedData = parsed.filter(({ begin }) => begin >= otherMin && begin < otherMax);
        const newParsedData = parsed

        let min = Number.POSITIVE_INFINITY;
        let max = Number.NEGATIVE_INFINITY;

        // TODO: make according bars presence
        for (let i = 0; i < newParsedData.length; i++) {
            const { low, high } = newParsedData[i];
            min = Math.min(min, low);
            max = Math.max(max, high);
        }
        const delta = max - min;
        min = Math.max((max - (delta / (1 - this.barPartSize))), 0)
        const realBarSize = max - delta - min;
        this.barPartSize = realBarSize / (max - min);

        return { min, max };
    }

    getBarValueFromData(data: BarParsedData, vscale: Scale) {
        const { min } = vscale;
        let max = min + ((vscale.max - vscale.min) * this.barPartSize)
        max = max - ((max - min) * 0.05)

        return (((data.volume - data.minVolume) * (max - min)) / (data.maxVolume - data.minVolume)) + min;
    }

    calculateElementProperties(index: number, ruler: any, reset: boolean, options: BarOptions) {
        const vscale = this._cachedMeta.vScale!;
        const base = vscale.getBasePixel();
        // @ts-expect-error unhandled type error
        const ipixels = this._calculateBarIndexPixels(index, ruler, options);

        // @ts-expect-error unhandled type error
        const data = this.chart.data.datasets[this.index].data[index] as BarParsedData;

        if (!data) {
            return {
                base: reset ? base : 0,
                x: 0,
                y: 0,
                width: 0,
                open: 0,
                high: 0,
                low: 0,
                close: 0,
            };
        }

        const open = vscale.getPixelForValue(data.open);
        const high = vscale.getPixelForValue(data.high);
        const low = vscale.getPixelForValue(data.low);
        const close = vscale.getPixelForValue(data.close);

        const barY = vscale.getPixelForValue(this.getBarValueFromData(data, vscale));

        return {
            base: reset ? base : low,
            x: ipixels.center,
            y: (low + high) / 2,
            width: ipixels.size,
            open,
            high,
            low,
            close,
            barY,
        };
    }

    draw() {
        const { chart } = this;
        const rects = this._cachedMeta.data;
        clipArea(chart.ctx, chart.chartArea);

        for (let i = 0; i < rects.length; ++i) {
            // @ts-expect-error unhandled type error
            rects[i].draw(this._ctx, rects.length);
        }

        unclipArea(chart.ctx);
    }
}

export const getBarBounds = (bar: FinancialElement, useFinalPosition: boolean) => {
    // @ts-expect-error unhandled type error
    const { x, y, base, width, height } = bar.getProps(['x', 'low', 'high', 'width', 'height'], useFinalPosition);

    let left, right, top, bottom, half;

    if (bar.horizontal) {
        half = (height as number) / 2;
        left = Math.min((x as number), base);
        right = Math.max((x as number), base);
        top = y - half;
        bottom = y + half;
    } else {
        half = (width as number) / 2;
        left = (x as number) - half;
        right = (x as number) + half;
        top = Math.min(y, base); // use min because 0 pixel at top of screen
        bottom = Math.max(y, base);
    }

    return { left, top, right, bottom };
}

const inRange = (bar: FinancialElement, x: number | null, y: number | null, useFinalPosition: boolean) => {
    const skipX = x === null;
    const skipY = y === null;
    const bounds = !bar || (skipX && skipY) ? false : getBarBounds(bar, useFinalPosition);

    return bounds &&
        (skipX || (x >= bounds.left && x <= bounds.right)) &&
        (skipY || (y >= bounds.top && y <= bounds.bottom));
}

export class FinancialElement extends BarElement {
    static defaults = {
        backgroundColors: {
            candle: {
                up: '#42A62E',
                down: '#F2433D',
                unchanged: 'rgba(201, 203, 207, 0.5)',
            },
            bar: {
                up: 'rgba(66, 166, 46, 0.5)',
                down: 'rgba(242, 67, 61,0.5)',
                unchanged: 'rgba(201, 203, 207, 0.5)',
            },
        },
        borderColors: {
            up: 'rgba(201, 203, 207, 0)',
            down: 'rgba(201, 203, 207, 0)',
            unchanged: 'rgba(201, 203, 207, 0)',
        },
        borderWidth: 0,
    };

    width = 0
    base = 0
    horizontal = true

    height() {
        return this.base - this.y;
    }

    inRange(mouseX: number, mouseY: number, useFinalPosition: boolean) {
        return inRange(this, mouseX, mouseY, useFinalPosition);
    }

    inXRange(mouseX: number, useFinalPosition: boolean) {
        return inRange(this, mouseX, null, useFinalPosition);
    }

    inYRange(mouseY: number, useFinalPosition: boolean) {
        return inRange(this, null, mouseY, useFinalPosition);
    }

    getRange(axis: string) {
        return axis === 'x' ? this.width / 2 : this.height() / 2;
    }

    getCenterPoint(useFinalPosition: boolean) {
        const { x, low, high } = this.getProps(['x', 'low', 'high'], useFinalPosition);

        return {
            x,
            y: ((high as number) - (low as number)) / 2 + (low as number)
        } as Point;
    }

    tooltipPosition(useFinalPosition: boolean) {
        const { x, open, close } = this.getProps(['x', 'open', 'close'], useFinalPosition);

        return {
            x,
            y: ((open as number) + (close as number)) / 2,
        } as Point;
    }
}

export class CandlestickElement extends FinancialElement implements CandlestickData {
    static id = 'candlestick';
    static defaults = {
        ...FinancialElement.defaults,
        borderWidth: 1,
    };

    x = 0
    open = 0
    high = 0
    low = 0
    close = 0
    barY = 0

    // @ts-expect-error unhandled type error
    draw(ctx: CanvasRenderingContext2D, countOfBars: number) {
        const { open, high, low, close, x, barY } = this;

        const { backgroundColors } = FinancialElement.defaults;

        let backgroundColor: keyof typeof backgroundColors.bar;
        const width = countOfBars > 100
            ? Math.min(5, (ctx.canvas.clientWidth - 30 - (countOfBars - 1)) / countOfBars)
            : 7

        if (close < open) {
            backgroundColor = 'up'
        } else if (close > open) {
            backgroundColor = 'down'
        } else {
            backgroundColor = 'unchanged'
        }

        ctx.fillStyle = backgroundColors.candle[backgroundColor];
        ctx.lineWidth = FinancialElement.defaults.borderWidth
        ctx.strokeStyle = backgroundColors.candle[backgroundColor];

        const x1 = x - (width / 2)
        const x2 = x + (width / 2)
        const y1 = Math.min(open, close)
        const y2 = Math.max(open, close)
        const radius = width / 5

        ctx.beginPath();
        ctx.moveTo(x, high);
        ctx.lineTo(x, Math.min(open, close));
        ctx.moveTo(x, low);
        ctx.lineTo(x, Math.max(open, close));
        ctx.stroke();
        ctx.moveTo(x1 + radius, y2);
        ctx.lineTo(x2 - radius, y2);
        ctx.arcTo(x2, y2, x2, y2 - radius, radius);
        ctx.lineTo(x2, y1 + radius);
        ctx.arcTo(x2, y1, x2 - radius, y1, radius);
        ctx.lineTo(x1 + radius, y1);
        ctx.arcTo(x1, y1, x1, y1 + radius, radius);
        ctx.lineTo(x1, y2 - radius)
        ctx.arcTo(x1, y2, x1 + radius, y2, radius);
        ctx.fill();
        ctx.closePath();

        const zero = ctx.canvas.height;
        ctx.fillStyle = backgroundColors.bar[backgroundColor];
        ctx.strokeStyle = backgroundColors.bar[backgroundColor];

        ctx.beginPath();
        ctx.moveTo(x1, zero);
        ctx.lineTo(x1, barY + radius);
        ctx.arcTo(x1, barY, x1 + radius, barY, radius);
        ctx.lineTo(x2 - radius, barY);
        ctx.arcTo(x2, barY, x2, barY + radius, radius);
        ctx.lineTo(x2, zero);
        ctx.fill();
        ctx.closePath();
    }
}

export class CandlestickController extends FinancialController {
    static id = 'candlestick';

    static defaults = {
        ...FinancialController.defaults,
        dataElementType: CandlestickElement.id,
    };

    static defaultRoutes = BarController.defaultRoutes;

    updateElements(elements: BarElement[], start: number, count: number, mode: UpdateElementMode) {
        const reset = mode === 'reset';
        const ruler = this._getRuler();
        // @ts-expect-error unhandled type error
        const {sharedOptions, includeOptions} = this._getSharedOptions(start, mode);

        for (let i = start; i < start + count; i++) {
            const options = sharedOptions || this.resolveDataElementOptions(i, mode);

            const baseProperties = this.calculateElementProperties(i, ruler, reset, options);

            if (includeOptions) {
                  // @ts-expect-error unhandled type error
                baseProperties.options = options;
            }
            this.updateElement(elements[i], i, baseProperties, mode);
        }
    }
}


