import { Image, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import nodata from '@shared/images/investorPro/nodata.png';

type Props = {
    title: string;
};

export const ViewEmptyStub: FC<Props> = ({ title }) => (
    <Stack
        align="center"
        justify="center"
        gap={8}
        p={16}
        mt={10}
        bg="rgba(215, 230, 245, 0.20)"
        style={{ border: '1px solid #D8E1EB', borderRadius: '12px' }}
        w="100%"
        h="100%"
    >
        <Image src={nodata} h={64} w={48} />
        <Text fz={16} c="#A5B1C0">
            {title}
        </Text>
    </Stack>
);
