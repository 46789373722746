import dayjs from 'dayjs';
import { type FC } from 'react';
import { Area, AreaChart, CartesianGrid, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { type Props } from 'recharts/types/component/Label';
import { type RiskMetricsType } from '@modules/Investorpro/modules/PortfolioPage/entities';
import { ViewLoader } from '@modules/Investorpro/modules/PortfolioPage/shared/Layout';

import { CustomReferenceLabel } from './CustomReferenceLabel';

type RiskChartProps = {
    data: RiskMetricsType;
    isInReport?: boolean;
    isPending?: boolean;
};

export const RiskChart: FC<RiskChartProps> = ({ data, isInReport, isPending }) => {
    if (isPending) {
        return <ViewLoader />;
    }

    return (
        <ResponsiveContainer height="100%" width="100%">
            <AreaChart data={data.potentialRisksGraphic} margin={{ top: 24, bottom: 20, left: 34 }}>
                <CartesianGrid
horizontal={true} vertical={false} stroke="#D8E1EB"
opacity={0.6} />
                <XAxis
                    tickMargin={20}
                    tickSize={0}
                    axisLine={{ strokeWidth: 1, stroke: '#A5B1C0' }}
                    dataKey="date"
                    tickFormatter={(props: string) => dayjs(props).format('DD.MM.YYYY')}
                    interval="preserveEnd"
                    style={{
                        fontSize: 11,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        textAnchor: 'end',
                        fill: '#8A96A6',
                    }}
                />
                <YAxis
                    dataKey="value"
                    unit="%"
                    tickMargin={40}
                    tickSize={0}
                    tickCount={9}
                    axisLine={false}
                    orientation="right"
                    style={{
                        fontSize: 11,
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        lineHeight: '20px',
                        textAnchor: 'end',
                        fill: '#A5B1C0',
                    }}
                />
                <defs>
                    <linearGradient
                        id="areaGradient"
                        x1="585"
                        y1="-9"
                        x2="585"
                        y2="326.476"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="white" stopOpacity="0.8" />
                        <stop offset="1" stopColor="#FF9B8B" />
                    </linearGradient>
                </defs>

                <Area
                    isAnimationActive={!isInReport}
                    dataKey="value"
                    stroke="#D3877A"
                    fill="url(#areaGradient)"
                    connectNulls={true}
                >
                    <LabelList
                        dataKey="date"
                        content={(props: Props) => (
                            <CustomReferenceLabel extremes={data.durationsAndExtremes} props={props} />
                        )}
                    />
                </Area>
            </AreaChart>
        </ResponsiveContainer>
    );
};
