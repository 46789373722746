/* eslint-disable react/prop-types */
import { Tooltip } from '@mantine/core';
import { type FC } from 'react';
import { type CartesianViewBox } from 'recharts/types/util/types';
import { type PortfolioDynamicsEvent } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { KeyRateLabel, IssuerReportsLabel, DividendsLabel } from '../../../../shared/Icon/icons';

type Props = {
    props: { viewBox: CartesianViewBox };
    payload?: { value: string | number };
    event: PortfolioDynamicsEvent;
};

export const EventsLabel: FC<Props> = ({ props, event }) => {
    const {
        viewBox: { x = 0, height = 425 },
    } = props;

    return (
        <g>
            <Tooltip label={typeof event?.description === 'string' ? `${event?.description}%` : 'Нет описания'}>
                <foreignObject
x={x - 10} y={height + 9} width={25}
height={30}>
                    {event?.type === 'ISSUER_REPORTS' ? <IssuerReportsLabel color="#FFAA00" /> : null}

                    {event?.type === 'DIVIDENDS' ? <DividendsLabel color="#2380EB" /> : null}

                    {event?.type === 'KEY_RATE' ? <KeyRateLabel color="#422feb" /> : null}
                </foreignObject>
            </Tooltip>
        </g>
    );
};
