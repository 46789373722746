import { formatDate, formatPrice } from '@modules/Investorpro/shared/utils/format.util';
import { TooltipContent } from '@modules/Investorpro/shared/components/TooltipContent';
import { LabelWithNavigation } from '@modules/Investorpro/modules/BondStockPage/shared/components/NavigateToTab';
import { LinearChart } from '@libs/components';
import { type DatasetWithColorOptions, type ServerBondIndustry } from '@libs/types';
import { type ProfitScatterPointData } from '@libs/types/integration.type';
import { getCountOfZerosAfterDotAndBeforeNumber, getNumberWithSpacing } from '@libs/utils';
import { ceil, floor, round } from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { BONDS_CATEGORY_OPTIONS } from '@modules/Investorpro/modules/AnaliticsPage/shared/constants';

import styles from './styles.module.scss';
import { type ChartDataTypeKey, type ChartDataVisibility, type CurveData, type PointsData } from '../../types';
import { CHECKBOX_COLORS, CURVE_COLOR } from '../../constants';

type Props = {
    curveData: CurveData;
    pointsData: PointsData;
    dataVisibility: ChartDataVisibility;
};

export const AnalyticalMapChart = ({ dataVisibility, curveData, pointsData }: Props) => {
    const navigate = useNavigate();
    const navigateToBondPage = (secId: string) => navigate(`/analytics/bonds/${secId}`);

    const chartDatasets = useMemo(() => {
        const datasets: Array<DatasetWithColorOptions<'line' | 'scatter'>> = [
            ...BONDS_CATEGORY_OPTIONS.map(({ name, code }, index) => ({
                label: name,
                type: 'scatter' as any,
                key: code,
                pointBorderWidth: 2,
                pointRadius: 5,
                pointBorderColor: 'white',
                pointBackgroundColor: CHECKBOX_COLORS[index + 1],
                data: pointsData[code],
                color: 'white',
            })),
            {
                type: 'line',
                key: 'curve',
                label: 'Кривая бескупонной доходности',
                data: curveData,
                pointRadius: 0,
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointHoverBorderWidth: 0,
                color: CURVE_COLOR,
                tooltipEnabled: false,
            },
        ];

        return datasets;
    }, [curveData, pointsData]);

    const chartData = chartDatasets.filter(({ key }) => dataVisibility[key as ChartDataTypeKey]);

    const chartOptions = useMemo(() => {
        let countOFData = 0;
        let maxX = 0;
        let minY = Infinity;
        let maxY = 0;
        let minX = Infinity;
        let minPointsX = Infinity;
        let maxPointsX = 0;

        if (dataVisibility.curve) {
            for (const { x, y } of curveData) {
                maxX = Math.max(maxX, x);
                minY = Math.min(minY, y);
                maxY = Math.max(maxY, y);
                minX = Math.min(minX, x);
                countOFData++;
            }
        }

        for (const key of Object.keys(pointsData)) {
            if (dataVisibility[key as ChartDataTypeKey]) {
                const data = pointsData[key as ServerBondIndustry];

                for (const { x, y } of data) {
                    maxX = Math.max(maxX, x);
                    minY = Math.min(minY, y);
                    maxY = Math.max(maxY, y);
                    minX = Math.min(minX, x);
                    minPointsX = Math.min(minPointsX, x);
                    maxPointsX = Math.max(maxPointsX, x);
                    countOFData++;
                }
            }
        }

        const deltaX = maxX - minX;
        const isSinglePointX = maxX === minX;
        const yAxisOffset = maxY === minY ? 1 : round((maxY - minY) * 0.05, 2);

        const minXDecadeExtent = getCountOfZerosAfterDotAndBeforeNumber(minX);
        const maxDecadeExtent = getCountOfZerosAfterDotAndBeforeNumber(maxX);
        const isExtraSmallX = minXDecadeExtent >= 1 && maxDecadeExtent >= 1;

        const xAxisOffset = isSinglePointX ? maxX * 0.05 : Math.max(deltaX * 0.005, isExtraSmallX ? 0 : 0.01);

        const isNeedOffsetForXAxis = minX === maxX || !dataVisibility.curve;
        const isNeedLeftOffsetForXAxis = isNeedOffsetForXAxis || minPointsX <= minX + xAxisOffset;
        const isNeedRightOffsetForXAxis = isNeedOffsetForXAxis || maxPointsX >= maxX - xAxisOffset;

        if (isNeedLeftOffsetForXAxis) {
            minX = minX - xAxisOffset < 0 && minX > 0.004 * deltaX ? 0 : minX - xAxisOffset;
        }

        maxX = isNeedRightOffsetForXAxis ? maxX + xAxisOffset : maxX;
        maxY = maxY + yAxisOffset;
        minY = Math.max(minY - yAxisOffset, 0);

        const xPrecision = isExtraSmallX ? getCountOfZerosAfterDotAndBeforeNumber(xAxisOffset) + 1 : 2;

        minX = minX < 0 ? floor(minX, isExtraSmallX ? xPrecision : 1) : round(minX, xPrecision);
        maxX = round(maxX, xPrecision);
        minY = round(minY, 2);
        maxY = round(maxY, 2);

        const isLeftBoundIsNegative = minX < 0;
        const integerBeforeXMax = floor(maxX);

        let stepSize;

        if (isLeftBoundIsNegative) {
            stepSize = isExtraSmallX ? round(0.1 ** minXDecadeExtent, minXDecadeExtent) : 0.1;
        }

        const countOfTicks = isLeftBoundIsNegative ? ceil((maxX - minX) / (stepSize ?? 1)) : 4;

        const getStepNearestFive = () => {
            const notRoundedStep = maxX / 5 / 5;

            return round(notRoundedStep, notRoundedStep < 1 ? 1 : 0) * 5;
        };
        const step = isExtraSmallX ? floor(countOfTicks / 6) * (stepSize ?? 1) : getStepNearestFive();

        const needToSkipTick = (rawValue: number, index: number) => {
            if (rawValue === maxX) {
                return false;
            }

            const value = round(rawValue, xPrecision);

            if (value === 0) {
                return false;
            }

            return value < 0 || value === integerBeforeXMax || value % step !== 0;
        };

        const tickCallbackForNegativeLeftBound = (value: number, index: number) => {
            if (countOFData === 0 || needToSkipTick(value, index)) {
                return '';
            }

            if (isLeftBoundIsNegative && value !== maxX) {
                return getNumberWithSpacing(round(value, xPrecision));
            }

            return getNumberWithSpacing(value);
        };

        const tickCallbackForPositiveLeftBound = (value: number) => {
            if (countOFData === 0) {
                return '';
            }

            return getNumberWithSpacing(round(value, xPrecision));
        };

        return {
            animation: false,
            scales: {
                x: {
                    type: 'linear',
                    position: 'bottom',
                    min: minX,
                    max: maxX,
                    grid: {
                        display: true,
                        drawTicks: false,
                    },
                    border: {
                        dash: isLeftBoundIsNegative
                            ? (context: any) => {
                                  if (needToSkipTick(context.tick.value, context.index)) {
                                      return [0, 10_000_000];
                                  }

                                  return [1, 0];
                              }
                            : undefined,
                    },
                    ticks: {
                        callback: isLeftBoundIsNegative
                            ? tickCallbackForNegativeLeftBound
                            : tickCallbackForPositiveLeftBound,
                        padding: 10,
                        stepSize,
                        maxRotation: 0,
                        minRotation: 0,
                        autoSkip: false,
                        maxTicksLimit: countOfTicks * 2,
                    },
                },
                y: {
                    max: maxY,
                    min: minY,
                    beginAtZero: true,
                    ticks: {
                        callback: (value: number, index: number) => {
                            if (+index === 0) {
                                return '';
                            }

                            return getNumberWithSpacing(value);
                        },
                        padding: 10,
                        maxTicksLimit: 8,
                    },
                    border: {
                        dash: (context: any) => {
                            if (context.tick.value === maxY) {
                                return [0, 10_000_000];
                            }

                            return [2, 2];
                        },
                    },
                },
            },
        };
    }, [curveData, dataVisibility]);

    return (
        <LinearChart
            data={chartData}
            width={1858}
            height={565}
            withGradient
            legend={{
                xAxisTitle: 'Лет до погашения',
                yAxisTitle: '% годовых',
            }}
            type="line"
            options={chartOptions as any}
            tooltip={{
                printPoints: false,
                mode: 'point',
                transparent: false,
                className: styles.tooltip,
                renderTooltipData: (data) => {
                    if (!data.length) return null;
                    const current = data[0]?.raw as ProfitScatterPointData;

                    return (
                        <TooltipContent
                            title={
                                <LabelWithNavigation
                                    className={styles.tooltip_navigationButton}
                                    handleNavigate={() => navigateToBondPage(current?.isin)}
                                >
                                    <span className={styles.tooltip_title}>{current?.title}</span>
                                </LabelWithNavigation>
                            }
                            content={[
                                { title: 'Доходность', content: `${formatPrice(round(current?.y ?? 0, 2))}%` },
                                {
                                    title: 'Дата погашения',
                                    content: formatDate(current?.matDate),
                                },
                            ]}
                        />
                    );
                },
            }}
        />
    );
};
