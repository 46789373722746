import { ModalWindow } from '@libs/components';
import classNames from 'classnames';
import { Search } from '@modules/Investorpro/shared/components/Header/components/Search';

import styles from './styles.module.scss';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    selectedWatchlistId?: number;
};

export const AddInstrumentModal = ({ isOpen, closeModal, selectedWatchlistId }: Props) => {
    return (
        <ModalWindow isVisible={isOpen} closeModal={closeModal}>
            <div className={classNames(styles.addInstrumentModalWrapper, 'flex', 'flex-column')}>
                <h2>Добавить инструмент</h2>
                <Search isModalSearch placeholder={'Тикер, например, GAZP'} selectedWatchlistId={selectedWatchlistId} />
            </div>
        </ModalWindow>
    );
};
