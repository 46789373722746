import { useState } from 'react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';
import { ModalWindow } from '@libs/components';
import { type Security } from '@modules/Investorpro/modules/ScreenersPage/types';
import { InputText } from 'primereact/inputtext';
import {
    addSecurityToWatchListThunk,
    createWatchlistThunk,
    deleteSecurityFromWatchlistThunk,
    getWatchlistsThunk,
    useAppDispatch,
    useAppSelector,
} from '@store/store';
import { declension } from '@modules/Investorpro/modules/ScreenersPage/components/Screener/utils';
import { type Watchlist } from '@modules/Investorpro/types/watchlist.type';
import { type ResponseInfo } from '@libs/types';
import { isWatchlistChecked } from '@modules/Investorpro/shared/utils/watchlist.utils';

import styles from './styles.module.scss';

type Props = {
    isin: string;
    render: (handleClick: () => void) => React.ReactNode;
};

export const FavouriteButtonModalWrapper = ({ isin, render }: Props) => {
    const dispatch = useAppDispatch();
    const onSetIsFavourite = async ({
        isin,
        watchlistId,
        securityId,
        value,
    }: {
        isin: Security['isin'];
        watchlistId: Watchlist['id'];
        value: boolean;
        securityId?: Security['id'];
    }) => {
        if (value) {
            await dispatch(addSecurityToWatchListThunk({ data: { isin }, watchlistId })).then(
                async () => await dispatch(getWatchlistsThunk()),
            );
        } else if (!value && securityId) {
            await dispatch(deleteSecurityFromWatchlistThunk({ securityId, watchlistId })).then(
                async () => await dispatch(getWatchlistsThunk()),
            );
        }
    };

    const [isFavouriteModalVisible, setIsFavouriteModalVisible] = useState<boolean>(false);
    const [isWatchlistModalVisible, setIsWatchlistModalVisible] = useState<boolean>(false);
    const [watchlistValue, setWatchlistValue] = useState<string>('');
    const { watchlists } = useAppSelector((state) => state.investorProfile);

    const handleOpenFavouriteModal = () => {
        setIsFavouriteModalVisible(true);
    };

    const handleHideFavouriteModal = () => {
        setIsFavouriteModalVisible(false);
    };

    const handleOpenCreateWatchlist = () => {
        setIsFavouriteModalVisible(false);
        setIsWatchlistModalVisible(true);
    };

    const handleCreateWatchlist = async () => {
        try {
            const { payload } = await dispatch(createWatchlistThunk({ name: watchlistValue }));
            const { data: watchlist } = payload as ResponseInfo<Watchlist>;
            onSetIsFavourite({ isin, watchlistId: watchlist.id, value: true });
            setIsWatchlistModalVisible(false);
            setIsFavouriteModalVisible(true);
            setWatchlistValue('');
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            {render(handleOpenFavouriteModal)}
            <ModalWindow
                isVisible={isFavouriteModalVisible}
                className={styles.modalWindow}
                closeModal={() => setIsFavouriteModalVisible(false)}
            >
                <div className={classNames('flex flex-column justify-content-stretch', styles.modalWindow_wrapper)}>
                    <h1 className={styles.modalWindow_title}>Добавить в избранное</h1>
                    <div className={classNames(styles.modalWindow_listWrapper)}>
                        {watchlists?.length
? (
                            watchlists.map((watchlist) => (
                                <div
                                    key={watchlist.id}
                                    className={classNames(
                                        'flex',
                                        'align-items-center',
                                        'justify-content-between',
                                        styles.list,
                                        isWatchlistChecked(watchlist, isin) ? styles.list__checked : '',
                                    )}
                                    onClick={() => {
                                        onSetIsFavourite({
                                            isin,
                                            watchlistId: watchlist.id,
                                            securityId: watchlist.securities.find((security) => security.isin === isin)
                                                ?.id,
                                            value: !isWatchlistChecked(watchlist, isin),
                                        });
                                    }}
                                >
                                    <div>
                                        <div className={styles.list_title}>{watchlist.name}</div>
                                        <div className={styles.list_subtitle}>
                                            {watchlist.count} {declension(watchlist.count, 'инструмент')}
                                        </div>
                                    </div>
                                    <Checkbox
                                        checked={isWatchlistChecked(watchlist, isin)}
                                        className={classNames(
                                            styles.checkbox,
                                            isWatchlistChecked(watchlist, isin) ? styles.checkbox__checked : '',
                                        )}
                                    />
                                </div>
                            ))
                        )
: (
                            <div className={styles.emptyData}>
                                У вас пока нет ни одного списка избранных инструментов.
                            </div>
                        )}
                    </div>
                    <div className={classNames('flex justify-content-stretch', styles.actionsWrapper)}>
                        <Button severity="secondary" label="Создать новый список" onClick={handleOpenCreateWatchlist} />
                        <Button label="Закрыть" onClick={handleHideFavouriteModal} />
                    </div>
                </div>
            </ModalWindow>
            <ModalWindow
                isVisible={isWatchlistModalVisible}
                className={classNames(styles.modalWindow, styles.modalWindow_watchlist)}
                closeModal={() => setIsWatchlistModalVisible(false)}
            >
                <div className={classNames('flex flex-column justify-content-stretch', styles.modalWindow_wrapper)}>
                    <h1 className={styles.modalWindow_title}>Новый список избранного</h1>
                    <div className={classNames(styles.listWrapper)}>
                        <InputText
                            className={styles.input}
                            value={watchlistValue}
                            onChange={(e) => setWatchlistValue(e.target.value)}
                        />
                    </div>
                    <div className={classNames('flex justify-content-stretch', styles.actionsWrapper)}>
                        <Button
                            severity="secondary"
                            label="Отменить"
                            onClick={() => {
                                setIsWatchlistModalVisible(false);
                                setIsFavouriteModalVisible(true);
                            }}
                        />
                        <Button label="Сохранить" onClick={handleCreateWatchlist} />
                    </div>
                </div>
            </ModalWindow>
        </>
    );
};
