import { type CashFlowType } from '../../../../../PortfolioPage/entities';
import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getCashFlow: ApiFactory<
    ApiEndpoint<CashFlowType[], void, { id: string; cashFlowType: string }>
> = (client) => async ({ signal, params: { id, ...params } }) => {
    const { data } = await client.get<CashFlowType[]>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/cash-flow/${id}`,
        { signal, params },
    );

    return data;
};
