import { Divider, Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { type Expectations } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { ProgressOffIcon } from '../ReportIcons/ProgressOffIcon';
import { ProgressOnIcon } from '../ReportIcons/ProgressOnIcon';

type Props = {
    expectations?: Expectations;
};

export const ReportExpectations: FC<Props> = ({ expectations }) => (
    <Stack
px={24} py={24} gap={16}
w="100%">
        <Text fz={36} fw={400}>
            {'Ожидания'}
        </Text>
        <Group wrap="nowrap" justify="space-between">
            <Group wrap="nowrap" gap={6}>
                <ProgressOnIcon />
                <Text fz={14}>{'Ожидаемая прибыль (5 лет)'}</Text>
            </Group>
            <Text fz={15} fw={600} c="#288C14">
                {expectations?.expectedProfit.toLocaleString('ru', { maximumFractionDigits: 2 })}
                {'%'}
            </Text>
        </Group>
        <Divider />
        <Group wrap="nowrap" justify="space-between">
            <Group wrap="nowrap" gap={6}>
                <ProgressOffIcon />
                <Text fz={14}>{'Потенциальная просадка'}</Text>
            </Group>
            <Text fz={15} fw={600} c="#E42821">
                {expectations?.riskPortfolio.toLocaleString('ru', { maximumFractionDigits: 2 })}
                {'%'}
            </Text>
        </Group>
    </Stack>
);
