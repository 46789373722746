/* eslint-disable react/prop-types */
import { Box } from '@mantine/core';
import { type FC } from 'react';
import { type BarProps } from 'recharts';

type Props = {
    props: BarProps;
};

export const CustomBar: FC<Props> = ({ props }) => {
    const { x, y, width, height } = props;

    return (
        <g>
            <foreignObject
x={x} y={y} width={width}
height={height}>
                <Box
                    style={{
                        width: '100%',
                        height: '100%',
                        background:
                            'linear-gradient(180deg, rgba(47, 156, 235, 0.25)' +
                            '0.48%, rgba(47, 156, 235, 0.00) 92.94%), rgba(213, 235, 251, 0.50)',
                        borderRadius: '6px 6px 0 0',
                    }}
                />
            </foreignObject>
        </g>
    );
};
