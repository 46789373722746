import { useAppDispatch } from '@store/store';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { ServerBondIndustry } from '@libs/types';
import { type ProfitScatterPointData } from '@libs/types/integration.type';
import { getBondsParams } from '@libs/services/instrument.service';
import { getProfitCurveData, getScatterProfitPoints } from '@libs/services/integration.service';
import { formatDateToQuery } from '@libs/utils';
import { refetchDataUntilFindNotEmpty } from '@modules/Investorpro/modules/MarketPages/shared/utils/fetch.utils';
import { getPrevTradeDayDate } from '@modules/Investorpro/modules/MarketPages/shared/utils/date.utils';
import { differenceInDays } from 'date-fns';
import { ColoredCheckbox } from '@libs/components';
import { useLoading } from '@libs/utils/hooks/useLoading';

import styles from './styles.module.scss';
import { CHECKBOX_COLORS, CHECKBOX_OPTIONS } from './constants';
import { type CurveData, type PointsData, type ChartDataVisibility } from './types';
import { AnalyticalMapChart } from './components/AnalyticalMapChart';
import { TOTAL_LOADING_KEY } from '../../shared/constants';

type Props = {
    setTradeDate: (date: Date) => void;
};

export const AnalyticalMap = ({ setTradeDate }: Props) => {
    const [curveData, setCurveData] = useState<CurveData>([]);
    const [pointsData, setPointsData] = useState<PointsData>({
        [ServerBondIndustry.CORP]: [],
        [ServerBondIndustry.GOV]: [],
        [ServerBondIndustry.MONI]: [],
    });
    const dispatch = useAppDispatch();
    const { startLoading, stopLoading } = useLoading('loading-analytical-map');

    const [chartDataVisibility, setChartDataVisibility] = useState<ChartDataVisibility>({
        curve: true,
        [ServerBondIndustry.GOV]: true,
        [ServerBondIndustry.CORP]: true,
        [ServerBondIndustry.MONI]: true,
    });

    useEffect(() => {
        const loadData = async () => {
            try {
                startLoading();
                startLoading(TOTAL_LOADING_KEY);

                const [bondsParams, tradeDate] = await refetchDataUntilFindNotEmpty({
                    fetchData: async (date) => await getBondsParams(date),
                    start: getPrevTradeDayDate(),
                    step: 1,
                    format: formatDateToQuery,
                    responseDataAccessor: (resp) => resp,
                });

                setTradeDate(tradeDate);

                const [curveData, pointsData] = await Promise.all([
                    getProfitCurveData(tradeDate),
                    getScatterProfitPoints(tradeDate),
                ]);

                setCurveData(
                    curveData.data.data.map(({ valDate, value }) => ({
                        x: valDate,
                        y: value,
                    })),
                );

                const points: Record<ServerBondIndustry, ProfitScatterPointData[]> = {
                    [ServerBondIndustry.GOV]: [],
                    [ServerBondIndustry.CORP]: [],
                    [ServerBondIndustry.MONI]: [],
                };

                pointsData.data.forEach(({ isin, value }, index) => {
                    const bond = bondsParams.find((params) => params.isin === isin);

                    if (bond?.matDate) {
                        const valDate = +(differenceInDays(new Date(bond.matDate), new Date()) / 365).toFixed(2);

                        const point: ProfitScatterPointData = {
                            title: bond.name ?? bond.isin,
                            y: Number(value),
                            isin,
                            matDate: bond.matDate,
                            x: valDate,
                        };

                        if (
                            [ServerBondIndustry.CORP, ServerBondIndustry.GOV, ServerBondIndustry.MONI].includes(
                                bond.securityType,
                            )
                        ) {
                            points[bond.securityType].push(point);
                        }
                    }
                });
                setPointsData(points);
            } catch (e) {
                console.error('e', e);
                setTradeDate(new Date());
                stopLoading(TOTAL_LOADING_KEY);
            } finally {
                stopLoading();
            }
        };
        loadData();
    }, [dispatch]);

    return (
        <div className={classNames('flex', 'flex-column', styles.analyticalMapWrapper)}>
            <h2>Аналитическая карта облигаций</h2>
            <div className={classNames('flex', 'align-items-center', styles.chartLegend)}>
                {CHECKBOX_OPTIONS.map(({ name, code }, index) => (
                    <div key={code} className={classNames('flex', 'align-items-center')}>
                        <div
                            className={code === 'curve' ? styles.curveMarker : styles.pointMarker}
                            style={{ backgroundColor: CHECKBOX_COLORS[index] }}
                        />
                        <span>{name}</span>
                    </div>
                ))}
            </div>
            <AnalyticalMapChart curveData={curveData} pointsData={pointsData} dataVisibility={chartDataVisibility} />
            <div className={classNames('flex', 'align-items-center', styles.checkboxBlock)}>
                <h4>Типы облигаций:</h4>
                {CHECKBOX_OPTIONS.map(({ name, code }, index) => (
                    <div key={code} className={classNames('flex', 'align-items-center')}>
                        <ColoredCheckbox
                            id={code}
                            checkedColor={CHECKBOX_COLORS[index]}
                            className={styles.checkbox}
                            handleClick={() => setChartDataVisibility((prev) => ({
                                    ...prev,
                                    [code]: !chartDataVisibility[code as keyof typeof chartDataVisibility],
                                }))}
                            checked={chartDataVisibility[code as keyof typeof chartDataVisibility]}
                        />
                        <label htmlFor={code}>{name}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};
