import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
    type BondCouponsInfo,
    type BondIssueInfo,
    type BondPriceInfo,
    type BondPaymentAmortisationData,
    type BondPaymentCouponData,
    type BondPaymentEarlyRedemptionData,
    type BondRatingInfo,
    type BondRedemptionInfo,
    type BondGeneralInfo,
    type BondInfo,
    type PaymentChartBarType,
    type TradeStat,
    PaymentType,
} from '@modules/Investorpro/modules/BondStockPage/BondPage/services/types';
import {
    getBondDataThunk,
    getBondPaymentsThunk,
    getBondTradesResultsThunk,
    getBondReferenceDataThunk,
    getBondDescriptionDataThunk,
    getBondPaymentsBarsDataThunk,
    getComponyLogoThunk,
} from '@store/store/thunk/investorpro/bond.thunk';
import { startOfDay, subYears } from 'date-fns';
import { BOARD_PAYMENTS_CHART_WINDOW_SIZE } from '@modules/Investorpro/modules/BondStockPage/BondPage/shared/constants';
import { IntervalType, PeriodId } from '@libs/types/instrument.type';
import { round } from 'lodash';
import { type Period } from '@libs/utils/hooks/useChoosePeriod';
import { getInstrumentProfitCurvesDataThunk } from '@store/store/thunk';
import {
    calculateAbsoluteChange,
    calculateRelativeChange,
    getBooleanValue,
    getNearestDataByDate,
    resetSliceState,
} from '@modules/Investorpro/shared/utils/slice.utils';
import { capitalize } from '@libs/utils';
import { AxiosError } from 'axios';

type PaymentState<T> = {
    total: number;
    data: T[];
    page: number;
    loaded: boolean;
    loading: boolean;
};

export type IState = {
    info: BondInfo;
    isin: string | null;
    secId: string | null;
    fintoolId: string | null;
    fininstId: string | null;
    generalInfo: BondGeneralInfo;
    board: string | null;
    payPerBond: number | null;
    boards: string[];
    tradeResultsCurves: {
        priceData: Array<{ x: string; y: number; vol: number }>;
        volData: Array<{ x: string; y: number; raw: number }>;
        loaded: boolean;
        currentPeriodOption: string | null;
        maxVol: number;
        period: {
            interval: IntervalType;
            from: string;
            to: string;
            arrowPos: string[];
        };
    };
    descriptionInfo: {
        issue: BondIssueInfo;
        price: BondPriceInfo;
        coupons: BondCouponsInfo;
        redemption: BondRedemptionInfo;
        rating: BondRatingInfo;
        loaded: boolean;
        tradesResults: {
            loaded: boolean;
            date: string | null;
            total: number;
            data: TradeStat[];
            page: number;
        };
    };
    paymentsCurves: {
        leftBound: number;
        data: PaymentChartBarType[];
        loaded: boolean;
    };
    payments: {
        [PaymentType.COUPON]: PaymentState<BondPaymentCouponData>;
        [PaymentType.AMORTIZATION]: PaymentState<BondPaymentAmortisationData>;
        [PaymentType.REDEMPTION]: PaymentState<BondPaymentEarlyRedemptionData>;
    };
    loadings: {
        getBondInfo: boolean;
        getBondPayments: boolean;
        getBondReferenceData: boolean;
        getBondTradesResults: boolean;
        getCompanyLogo: boolean;
        commonLoading: boolean;
        getBondDescriptionInfo: boolean;
        getBondProfitCurves: boolean;
    };
    errors: string[];
};

const initialPaymentState: PaymentState<any> = {
    total: 0,
    data: [],
    page: 0,
    loaded: false,
    loading: false,
};

export const initialState: IState = {
    payPerBond: null,
    boards: [],
    info: {
        name: null,
        companyName: null,
        currentValue: null,
        updatedAt: null,
        logo: null,
        isin: null,
        nominalValue: null,
        status: null,
        duration: null,
        dailyTradingVolume: null,
        lastDealProfitability: null,
        change: {
            absolute: null,
            relative: null,
        },
    },
    tradeResultsCurves: {
        currentPeriodOption: PeriodId.ONE_YEAR,
        period: {
            from: subYears(startOfDay(new Date()), 1).toJSON(),
            to: new Date().toJSON(),
            interval: IntervalType.ONE_DAY,
            arrowPos: ['0'],
        },
        priceData: [],
        volData: [],
        maxVol: 0,
        loaded: false,
    },
    paymentsCurves: {
        leftBound: 0,
        data: [],
        loaded: false,
    },
    isin: null,
    secId: null,
    board: null,
    fintoolId: null,
    fininstId: null,
    generalInfo: {
        fullNameOfBorrower: null,
        shortNameOfBorrower: null,
        sector: null,
        industry: null,
        typeOfIssue: null,
        registrationNumber: null,
        listingLevel: null,
        lombardList: null,
        forQualifiedInvestors: null,
        subordinatedBond: null,
    },
    descriptionInfo: {
        loaded: false,
        issue: {
            registrationDate: null,
            placementStartDate: null,
            placementEndDate: null,
            nominal: null,
            currency: null,
            issueVolumeUnits: null,
            issueVolumeCurrency: null,
            circulationVolumeUnits: null,
            circulationVolumeCurrency: null,
            nominalIndexed: null,
            nominalAmortization: null,
            earlyRepurchaseRedemption: null,
            guaranteeType: null,
            guaranteeCompleteness: null,
            guaranteedAmount: null,
            numberOfGuarantors: null,
            covenantsInProspectus: null,
            conversionToAnotherInstrument: null,
            placementPlatform: null,
            subscriptionType: null,
            placementMethod: null,
            placementOfferUnits: null,
            placementOfferCurrency: null,
            demandVolumeUnits: null,
            demandVolumeCurrency: null,
            placedVolumeUnits: null,
            placedVolumeCurrency: null,
            numberOfDealsAtPlacement: null,
            minPriceInPlacementBidsPercentage: null,
            maxPriceInPlacementBidsPercentage: null,
            weightedAvgPriceAtPlacementPercentage: null,
            yieldAtPlacementWeightedAvgPricePercentage: null,
        },
        price: {
            remainingNominal: null,
            cleanPricePercentage: null,
            cleanPriceCurrency: null,
            accruedInterestPercentage: null,
            accruedInterestCurrency: null,
            fullPricePercentage: null,
            fullPriceCurrency: null,
            yieldToMaturity: null,
            yieldToOffer: null,
            offerNumberForYield: null,
            lastDealCurrentYield: null,
            lastDealSimpleYield: null,
            macauleyDuration: null,
            modifiedDuration: null,
            convexity: null,
            pvbp: null,
        },
        coupons: {
            totalCoupons: null,
            couponsPerYear: null,
            couponType: null,
            fixedCouponRate: null,
            floatingCouponRate: null,
            spreadToFloatingRate: null,
            floatingRateReview: null,
            currentCouponNumber: null,
            currentCouponRate: null,
            couponPaymentDate: null,
            recordDate: null,
            couponsUntilMaturity: null,
            couponsUntilNextOffer: null,
        },
        redemption: {
            maturityDate: null,
            totalRedemptions: null,
            currentNominal: null,
            currentRedemptionNumber: null,
            partialRedemptionDate: null,
            recordDate: null,
            currentRedemptionPercentage: null,
            totalEarlyRepurchases: null,
            nextRepurchaseDate: null,
            earlyRepurchaseNumber: null,
            nextRepurchaseType: null,
            endOfRepurchaseSubmission: null,
            repurchasePrice: null,
            maxRepurchaseVolume: null,
        },
        rating: {
            raitingAgency: null,
            borrowerRaiting: null,
        },
        tradesResults: {
            date: null,
            loaded: false,
            total: 0,
            data: [],
            page: 0,
        },
    },
    payments: {
        [PaymentType.COUPON]: initialPaymentState,
        [PaymentType.AMORTIZATION]: initialPaymentState,
        [PaymentType.REDEMPTION]: initialPaymentState,
    },
    loadings: {
        getBondInfo: false,
        getBondReferenceData: false,
        getBondProfitCurves: false,
        getBondPayments: false,
        getCompanyLogo: false,
        getBondTradesResults: false,
        getBondDescriptionInfo: false,
        commonLoading: false,
    },
    errors: [],
};

export const bondSlice = createSlice({
    name: 'investpro/bond',
    initialState,
    reducers: {
        setTradesResultsCurvesPeriodOption: (state, action: PayloadAction<string | null>) => {
            state.tradeResultsCurves.currentPeriodOption = action.payload;
        },
        setTradesResultCurvesPeriod: (state, action: PayloadAction<Period>) => {
            state.tradeResultsCurves.period.arrowPos = action.payload.arrowPos;
            state.tradeResultsCurves.period.from = action.payload.from.toJSON();
            state.tradeResultsCurves.period.to = action.payload.to.toJSON();
            state.tradeResultsCurves.period.interval = action.payload.interval;
        },
        setTradeResultCurvesLoadedStatus: (state, action: PayloadAction<boolean>) => {
            state.tradeResultsCurves.loaded = action.payload;
        },
        resetBondPageData: (state) => {
            resetSliceState(state, initialState);
        },
        setPaymentsCurvesWindowLeftBound: (state, action: PayloadAction<number>) => {
            state.paymentsCurves.leftBound = action.payload;
        },
        setBondPaymentsPage: (state, action: PayloadAction<{ page: number; type: keyof IState['payments'] }>) => {
            state.payments[action.payload.type].page = action.payload.page;
            state.payments[action.payload.type].loaded = false;
        },
        setBondTradesResultsPage: (state, action: PayloadAction<number>) => {
            state.descriptionInfo.tradesResults.page = action.payload;
            state.descriptionInfo.tradesResults.loaded = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getInstrumentProfitCurvesDataThunk.pending, (state, { payload }) => {
            state.loadings.getBondProfitCurves = true;
        });
        builder.addCase(getInstrumentProfitCurvesDataThunk.fulfilled, (state, { payload }) => {
            state.loadings.getBondProfitCurves = false;
            state.tradeResultsCurves.priceData = payload.priceData;
            state.tradeResultsCurves.volData = payload.volData;
            state.tradeResultsCurves.loaded = true;
            state.tradeResultsCurves.maxVol = payload.maxVol;
        });
        builder.addCase(getInstrumentProfitCurvesDataThunk.rejected, (state, action) => {
            state.loadings.getBondProfitCurves = false;
            state.errors.push(action.error.message ?? 'thunk error');
        });
        builder.addCase(getBondDataThunk.pending, (state, action) => {
            state.loadings.getBondInfo = true;
        });
        builder.addCase(getComponyLogoThunk.pending, (state, action) => {
            state.loadings.getCompanyLogo = true;
        });
        builder.addCase(getComponyLogoThunk.fulfilled, (state, action) => {
            state.info.logo = action.payload;
            state.loadings.getCompanyLogo = false;
        });
        builder.addCase(getComponyLogoThunk.rejected, (state, action) => {
            state.loadings.getCompanyLogo = false;
            state.errors.push(action.error.message ?? 'thunk error');
        });
        builder.addCase(getBondDataThunk.fulfilled, (state, { payload }) => {
            state.fininstId = payload.fininstId ?? null;
            state.fintoolId = payload.fintoolId ?? null;
            state.info.name = payload.shortName ?? null;
            state.isin = payload.isin ?? null;
            state.boards = payload.boards ?? [];
            state.secId = payload.secId ?? null;
            state.info.updatedAt = payload.updateDate ?? null;
            state.board = payload.boardId ?? null;
            state.info.companyName = capitalize(payload.shortTitle ?? payload.shortName ?? null);
            state.info.status = payload.status ?? null;
            state.info.nominalValue = payload.initialFaceValue ?? null;
            state.descriptionInfo.issue.nominal = payload.initialFaceValue ?? null;
            state.info.duration = payload.duration ?? null;
            state.tradeResultsCurves.period.to = payload.updateDate;
            state.tradeResultsCurves.period.from = subYears(new Date(payload.updateDate), 1).toJSON();
            state.info.lastDealProfitability = payload.yieldClose ?? null;
            state.generalInfo.fullNameOfBorrower = capitalize(payload.title ?? null);
            state.generalInfo.shortNameOfBorrower = capitalize(payload.shortTitle ?? null);
            state.generalInfo.sector = payload.issuerSector ?? null;
            state.generalInfo.industry = payload.securityType ?? null;
            state.generalInfo.registrationNumber = payload.regCode ?? null;
            state.generalInfo.listingLevel = payload.listLevel ?? null;
            state.generalInfo.typeOfIssue = payload.type ?? null;
            state.info.dailyTradingVolume = payload.dayVolume ?? null;
            state.generalInfo.subordinatedBond = getBooleanValue(payload.isSubordinated);
            state.generalInfo.forQualifiedInvestors = getBooleanValue(payload.isQualifiedInvestors);
            state.loadings.getBondInfo = false;
            state.info.currentValue = payload.currentQuote ? round(payload.currentQuote, 2) : null;
            state.info.change.absolute = calculateAbsoluteChange(payload.currentQuote, payload.prevQuote);
            state.descriptionInfo.price.macauleyDuration = payload.duration ?? null;
            state.info.change.relative = calculateRelativeChange(payload.currentQuote, payload.prevQuote);
        });
        builder.addCase(getBondDataThunk.rejected, (state, action) => {
            state.loadings.getBondInfo = false;
            state.errors.push(action.error.message ?? 'thunk error');
        });
        builder.addCase(getBondReferenceDataThunk.pending, (state, { payload }) => {
            state.loadings.getBondReferenceData = true;
        });
        builder.addCase(getBondReferenceDataThunk.fulfilled, (state, { payload }) => {
            const referenceData = payload[0];
            const getSubscriptionTypeLabel = (guaranteeType: string | null) => {
                if (guaranteeType) {
                    const isClosed = Boolean(+guaranteeType);

                    return isClosed ? 'Закрытая' : 'Открытая';
                }

                return null;
            };
            state.generalInfo.lombardList = getBooleanValue(referenceData?.islombardcbrNrd);
            state.descriptionInfo.issue.guaranteeType = referenceData?.guaranteetype ?? null;
            state.descriptionInfo.issue.guaranteeCompleteness = referenceData?.guaranteeamount ?? null;
            state.descriptionInfo.issue.guaranteedAmount = referenceData?.guarantval ?? null;
            state.descriptionInfo.issue.numberOfGuarantors = referenceData?.numguarantors ?? null;
            state.descriptionInfo.issue.subscriptionType = getSubscriptionTypeLabel(referenceData?.privatedist);
            state.descriptionInfo.coupons.couponType = referenceData?.coupontype ?? null;
            state.descriptionInfo.redemption.currentNominal = referenceData?.currentfacevalueNrd ?? null;
            state.loadings.getBondReferenceData = false;
        });
        builder.addCase(getBondReferenceDataThunk.rejected, (state, action) => {
            state.loadings.getBondReferenceData = false;
            state.errors.push(action.error.message ?? 'thunk error');
        });

        builder.addCase(getBondDescriptionDataThunk.pending, (state) => {
            state.loadings.getBondDescriptionInfo = true;
        });
        builder.addCase(getBondDescriptionDataThunk.rejected, (state, action) => {
            state.loadings.getBondDescriptionInfo = false;
            state.errors.push(action.error.message ?? 'thunk error');
        });
        builder.addCase(getBondDescriptionDataThunk.fulfilled, (state, { payload }) => {
            const { auctions, couponsSum, ratings, calcaulations } = payload;
            const rating = ratings[0];
            const calcaulation = calcaulations[0];
            const auction = auctions[0];
            const couponSum = couponsSum[0];
            state.loadings.getBondDescriptionInfo = false;
            state.descriptionInfo.issue.weightedAvgPriceAtPlacementPercentage = auction?.waprice ?? null;
            state.descriptionInfo.issue.placementPlatform = auction?.exchName ?? null;
            state.descriptionInfo.issue.placementMethod = auction?.method ?? null;
            state.descriptionInfo.issue.placementOfferCurrency = auction?.askVal ?? null;
            state.descriptionInfo.issue.placementOfferUnits = auction?.askVol ?? null;
            state.descriptionInfo.issue.placedVolumeCurrency = auction?.distVal ?? null;
            state.descriptionInfo.issue.placedVolumeUnits = auction?.distVol ?? null;
            state.descriptionInfo.issue.demandVolumeCurrency = auction?.bidVal ?? null;
            state.descriptionInfo.issue.demandVolumeUnits = auction?.bidVol ?? null;
            state.descriptionInfo.issue.numberOfDealsAtPlacement = auction?.numTrades ?? null;
            state.descriptionInfo.issue.minPriceInPlacementBidsPercentage = auction?.minBid ?? null;
            state.descriptionInfo.issue.maxPriceInPlacementBidsPercentage = auction?.maxBid ?? null;
            state.descriptionInfo.coupons.couponsUntilNextOffer = couponSum?.sumCouponsOffer ?? null;
            state.descriptionInfo.rating.raitingAgency = rating?.codeName ?? null;
            state.descriptionInfo.rating.borrowerRaiting = rating?.idValue ?? null;
            state.descriptionInfo.price.remainingNominal = calcaulation?.facevalAtDate ?? null;
            state.descriptionInfo.price.cleanPricePercentage = calcaulation?.pricePct ?? null;
            state.descriptionInfo.price.cleanPriceCurrency = calcaulation?.price ?? null;
            state.descriptionInfo.price.accruedInterestPercentage = calcaulation?.accrintPct ?? null;
            state.descriptionInfo.price.accruedInterestCurrency = calcaulation?.accrint ?? null;
            state.descriptionInfo.price.fullPricePercentage = calcaulation?.fullpricePct ?? null;
            state.descriptionInfo.price.fullPriceCurrency = calcaulation?.fullprice ?? null;
            state.descriptionInfo.price.yieldToMaturity = payload.redemptionYield;
            state.descriptionInfo.price.yieldToOffer = payload.offerYield;
            state.descriptionInfo.issue.yieldAtPlacementWeightedAvgPricePercentage = auction?.yieldWaprice ?? null;
            state.descriptionInfo.price.offerNumberForYield = calcaulation.offerId;
            state.descriptionInfo.price.lastDealCurrentYield = calcaulation.cyield;
            state.descriptionInfo.price.lastDealSimpleYield = calcaulation.syield;
            state.descriptionInfo.price.pvbp = calcaulation.pvbp;
            state.descriptionInfo.price.convexity = calcaulation.convx;
            state.descriptionInfo.price.modifiedDuration = calcaulation.mD ?? null;
            state.descriptionInfo.loaded = true;
            state.descriptionInfo.coupons.couponsUntilMaturity = couponSum?.sumCouponsMty ?? null;
        });
        builder.addCase(getBondPaymentsThunk.fulfilled, (state, action) => {
            const { data, count, type } = action.payload;
            state.payments[type as keyof IState['payments']].data = data;
            state.payments[type as keyof IState['payments']].total = count;
            state.payments[type as keyof IState['payments']].loaded = true;
            state.payments[type as keyof IState['payments']].loading = false;
        });
        builder.addCase(getBondPaymentsThunk.pending, (state, action) => {
            const { type } = action.meta.arg;

            state.payments[type as keyof IState['payments']].loading = true;
        });
        builder.addCase(getBondPaymentsThunk.rejected, (state, action) => {
            const { type } = action.meta.arg;

            state.errors.push(action.error.message ?? 'thunk error');
            state.payments[type as keyof IState['payments']].loading = false;
        });
        builder.addCase(getBondTradesResultsThunk.pending, (state) => {
            state.loadings.commonLoading = true;
        });
        builder.addCase(getBondTradesResultsThunk.fulfilled, (state, action) => {
            state.descriptionInfo.tradesResults.data = action.payload.data;
            state.descriptionInfo.tradesResults.total = action.payload.count;
            state.descriptionInfo.tradesResults.loaded = true;
            state.loadings.commonLoading = false;
        });
        builder.addCase(getBondTradesResultsThunk.rejected, (state, action) => {
            state.loadings.commonLoading = false;
            state.errors.push(action.error.message ?? 'thunk error');
        });
        builder.addCase(getBondPaymentsBarsDataThunk.pending, (state) => {
            state.loadings.getBondPayments = true;
        });

        builder.addCase(getBondPaymentsBarsDataThunk.fulfilled, (state, { payload }) => {
            const { payments, offers, coupons, redemptions, ammortizations } = payload;
            const redemption = redemptions[0];
            const coupon = coupons[0];
            const nearestCoupon = getNearestDataByDate(coupons, 'fixDate');
            const ammortization = ammortizations[0];
            const nearestOffer = getNearestDataByDate(offers, 'offerDate');
            const nearestAmmortization = getNearestDataByDate(ammortizations, 'fixDate');
            state.paymentsCurves.data = payments;
            state.paymentsCurves.loaded = true;
            state.loadings.getBondPayments = false;
            state.paymentsCurves.leftBound = Math.max(0, payments.length - BOARD_PAYMENTS_CHART_WINDOW_SIZE);
            state.descriptionInfo.issue.registrationDate = redemption?.regDate ?? null;
            state.descriptionInfo.issue.placementStartDate = redemption?.begdist_date ?? null;
            state.descriptionInfo.issue.covenantsInProspectus = getBooleanValue(redemption?.hasCovenants);
            state.descriptionInfo.issue.placementEndDate = redemption?.enddist_date ?? null;
            state.descriptionInfo.issue.currency = redemption?.facevalueСurrency ?? null;
            state.descriptionInfo.issue.issueVolumeCurrency = redemption?.issueVal ?? null;
            state.descriptionInfo.issue.issueVolumeUnits = +redemption?.issueVol ?? null;
            state.descriptionInfo.issue.circulationVolumeUnits = redemption?.marketVol ?? null;
            state.descriptionInfo.issue.circulationVolumeCurrency = redemption?.marketVal ?? null;
            state.descriptionInfo.issue.nominalIndexed = getBooleanValue(redemption?.haveindexedfv);
            state.descriptionInfo.issue.nominalAmortization = getBooleanValue(redemption?.amortisedMty);
            state.descriptionInfo.issue.earlyRepurchaseRedemption = getBooleanValue(redemption?.haveOffer);
            state.descriptionInfo.issue.conversionToAnotherInstrument = getBooleanValue(redemption?.convertible);
            state.payPerBond = ammortization?.payPerBond ?? null;
            state.descriptionInfo.coupons.totalCoupons = redemption?.numCoupons ?? null;
            state.descriptionInfo.coupons.floatingRateReview = redemption?.raterevisionsperyear ?? null;
            state.descriptionInfo.coupons.spreadToFloatingRate = coupon?.rateSpreadPct ?? null;
            state.descriptionInfo.coupons.currentCouponNumber = nearestCoupon?.idCoupon ?? null;
            state.descriptionInfo.coupons.recordDate = nearestCoupon?.fixDate ?? null;
            state.descriptionInfo.redemption.maturityDate = redemption?.endmtyDate ?? null;
            state.descriptionInfo.redemption.partialRedemptionDate = nearestAmmortization?.mtyDate ?? null;
            state.descriptionInfo.redemption.recordDate = nearestAmmortization?.fixDate ?? null;
            state.descriptionInfo.redemption.nextRepurchaseDate = nearestOffer?.offerDate ?? null;
            state.descriptionInfo.redemption.earlyRepurchaseNumber = nearestOffer?.idOffer ?? null;
            state.descriptionInfo.redemption.endOfRepurchaseSubmission = nearestOffer?.endOrder ?? null;
            state.descriptionInfo.coupons.couponsPerYear = redemption?.couponsPerYear ?? null;
            state.descriptionInfo.coupons.fixedCouponRate = coupon?.couponRate ?? null;
            state.descriptionInfo.coupons.couponPaymentDate = nearestCoupon?.payDate ?? null;
            state.descriptionInfo.redemption.maxRepurchaseVolume = nearestOffer?.buyBackLimitVol ?? null;
            state.descriptionInfo.redemption.currentRedemptionNumber = nearestAmmortization?.idTranche ?? null;
            state.descriptionInfo.redemption.repurchasePrice = nearestOffer?.buyBackPrice ?? null;
            state.descriptionInfo.redemption.totalRedemptions = ammortizations.length;
            state.descriptionInfo.redemption.totalEarlyRepurchases = offers.length;
            state.descriptionInfo.redemption.currentRedemptionPercentage = nearestAmmortization?.mtyPart ?? null;
            state.descriptionInfo.coupons.floatingCouponRate = redemption?.floatratename ?? null;
            state.descriptionInfo.coupons.currentCouponRate = nearestCoupon?.couponRate ?? null;
            state.descriptionInfo.redemption.nextRepurchaseType = nearestOffer?.operationType ?? null;
        });
        builder.addCase(getBondPaymentsBarsDataThunk.rejected, (state, action) => {
            state.loadings.getBondPayments = false;
            state.errors.push(action.error.message ?? 'thunk error');
        });
    },
});

export const bondReducer = bondSlice.reducer;

export const {
    resetBondPageData,
    setBondPaymentsPage,
    setBondTradesResultsPage,
    setTradesResultCurvesPeriod,
    setTradesResultsCurvesPeriodOption,
    setPaymentsCurvesWindowLeftBound,
    setTradeResultCurvesLoadedStatus,
} = bondSlice.actions;
