import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import { HelpLineWrapper, ModalWindow } from '@libs/components';
import { ReactComponent as BurgerMenuIcon } from '@shared/images/svg/BurgerMenu.svg';
import { ReactComponent as LockIcon } from '@shared/images/svg/Lock.svg';
import { ReactComponent as SettingsIcon } from '@modules/Investorpro/shared/images/svg/Settings.svg';
import {
    type ColumnGroupType,
    type ColumnType,
    type RequestScreenerType,
    type ScreenerType,
    type ScreenerColumnType,
} from '@modules/Investorpro/modules/ScreenersPage/types';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState, updateScreenerThunk, useAppSelector } from '@store/store';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { SortableList } from '@modules/Investorpro/shared/components/SortableList';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';

import { getDefaultColumn } from '../../utils';
import { ReactComponent as SearchIcon } from '../../../../../../shared/images/svg/Search.svg';
import { ColumnItem } from '../ColumnItem';
import styles from './styles.module.scss';

type ColumnsSettingsProps = {
    screener: ScreenerType;
};

export const ColumnsSettings = ({ screener }: ColumnsSettingsProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const isLoading: boolean = useAppSelector((state) => state.screeners.isLoading);

    // region Поиск
    const [searchValue, setSearchValue] = useState<string>('');
    // endregion Поиск

    // Индекс текущей группы колонок в массиве
    const [activeGroupIndex, setActiveGroupIndex] = useState<number>(0);
    // Все колонки группы (левая часть формы)
    const [columnsByGroups, setColumnsByGroups] = useState<Record<ColumnGroupType['id'], Array<ColumnType['id']>>>({});

    const { groups, columns } = useSelector((state: RootState) => state.screeners.columns);

    const allPreparedColumns = useMemo(
        () => columns.reduce((acc: Record<ColumnType['id'], ColumnType>, column) => {
                acc[column.id] = column;

                return acc;
            }, {}),
        [columns],
    );

    const activeGroup: ColumnGroupType = useMemo(() => groups[activeGroupIndex], [groups, activeGroupIndex]);

    useEffect(() => {
        const defaultColumnsByGroups = groups.reduce(
            (acc: Record<ColumnGroupType['id'], Array<ColumnType['id']>>, { id }) => {
                acc[id] = columns
                    .filter(({ groupId, primary }) => !primary && groupId.includes(id))
                    .map(({ id }) => id);

                return acc;
            },
            {},
        );

        setColumnsByGroups(defaultColumnsByGroups);
    }, [groups, columns, isVisible]);

    const [filteredColumns, setFilteredColumns] = useState<Array<ColumnType['id']>>([]);

    // // Фильтрация колонок для выбранной группы
    useEffect(() => {
        if (searchValue) {
            const compareSearchValue: string = searchValue.toLowerCase();
            const updatedSearchResult = columns
                .filter(({ name }) => name.toLowerCase().includes(compareSearchValue))
                .map(({ id }) => id);
            setFilteredColumns(updatedSearchResult);
        } else {
            setFilteredColumns([]);
        }
    }, [allPreparedColumns, searchValue]);

    // Primary колонки (их id) выбранного скринера (зафиксированные сверху), они всегда checked=true
    const [primaryScreenerColumns, setPrimaryScreenerColumns] = useState<Array<ColumnType['id']>>([]);
    // Динамические колонки (их id) выбранного скринера, они всегда checked=true и отсортированы
    const [nonPrimaryScreenerColumns, setNonPrimaryScreenerColumns] = useState<Array<ColumnType['id']>>([]);

    useEffect(() => {
        const screenerColumns: Array<ColumnType['id']> = screener.columns.map(({ id }: ScreenerColumnType) => id);

        const primaryScreenerColumns: Array<ColumnType['id']> = columns
            .filter(({ id }) => allPreparedColumns[id]?.primary)
            .map(({ id }) => id);

        const nonPrimaryScreenerColumns: Array<ColumnType['id']> = screenerColumns.filter(
            (columnId) => !allPreparedColumns[columnId]?.primary,
        );

        setPrimaryScreenerColumns(primaryScreenerColumns);
        setNonPrimaryScreenerColumns(nonPrimaryScreenerColumns);

        return () => {
            setPrimaryScreenerColumns([]);
            setNonPrimaryScreenerColumns([]);
        };
    }, [columnsByGroups, columns]);

    const handleSwitchColumn = useCallback(
        (columnId: ColumnType['id'], value?: boolean) => {
            if (value) {
                setNonPrimaryScreenerColumns([...nonPrimaryScreenerColumns, columnId]);
            } else {
                setNonPrimaryScreenerColumns(
                    nonPrimaryScreenerColumns.filter((nonPrimaryColumnId) => columnId !== nonPrimaryColumnId),
                );
            }
        },
        [allPreparedColumns, nonPrimaryScreenerColumns],
    );

    const handleSettingsClick = useCallback(() => setIsVisible(true), []);

    useEffect(() => {
        if (!isVisible) {
            setActiveGroupIndex(0);
        }
    }, [isVisible]);

    const dispatch = useDispatch<AppDispatch>();
    const handleSaveColumns = () => {
        const updatedScreener: Partial<RequestScreenerType> = {
            ...screener,
            columns: [...primaryScreenerColumns, ...nonPrimaryScreenerColumns].map((id) => ({
                id,
            })),
        };
        dispatch(updateScreenerThunk(updatedScreener)).then(({ payload }) => {
            // @ts-expect-error При отсутствии ошибки
            const { errorMessage } = payload?.status ?? {};

            if (errorMessage) {
                console.error(errorMessage);
            } else {
                setIsVisible(false);
            }
        });
    };

    const renderColumnsItem = (columnId: ColumnType['id']) => {
        const { fieldName, name: columnName, primary } = allPreparedColumns[columnId];

        return (
            <label key={columnId} className={styles.groupColumns_column}>
                {columnName}
                <InputSwitch
                    name={fieldName}
                    checked={primary || nonPrimaryScreenerColumns.includes(columnId)}
                    onChange={(e) => handleSwitchColumn(columnId, e.value)}
                    className={styles.switch}
                    disabled={primary}
                />
            </label>
        );
    };

    if (!columns) {
        return (
            <div>
                <h1>...loader mock</h1>
            </div>
        );
    }

    return (
        <>
            <HelpLineWrapper message="helpline.screeners.changeColumns">
                <SettingsIcon className={styles.isFavouriteIcon} onClick={handleSettingsClick} />
            </HelpLineWrapper>
            <ModalWindow isVisible={isVisible} closeModal={() => setIsVisible(false)} className={styles.settingsWindow}>
                <>
                    <h1 className={styles.title}>Настроить колонки</h1>
                    <div className={classNames('flex align-items-stretch', styles.contentWrapper)}>
                        <div className={styles.wrapper}>
                            <div className={styles.searchWrapper}>
                                <SearchIcon className={styles.searchIcon} />
                                <InputText
                                    v-model="value1"
                                    placeholder="Поиск"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    className={classNames(styles.searchInput)}
                                />
                                <Button
                                    icon="pi pi-times"
                                    rounded
                                    text
                                    size="small"
                                    aria-label="Cancel"
                                    className={styles.clearIcon}
                                    onClick={() => setSearchValue('')}
                                />
                            </div>
                            {searchValue
? (
                                <TabView activeIndex={0} className={styles.tabView}>
                                    <TabPanel header="Результат поиска" className={classNames(styles.tabViewCustom)}>
                                        <ul className={classNames('flex flex-wrap', styles.columnsByGroups)}>
                                            {filteredColumns.map(renderColumnsItem)}
                                        </ul>
                                    </TabPanel>
                                </TabView>
                            )
: (
                                <TabView
                                    activeIndex={activeGroupIndex}
                                    onTabChange={(e) => setActiveGroupIndex(e.index)}
                                    className={styles.tabView}
                                    scrollable
                                >
                                    {groups.map(({ id: groupId, name: groupName }: ColumnGroupType) => (
                                        <TabPanel key={groupId} header={groupName} className={styles.tabPanel}>
                                            <div className={classNames('flex flex-wrap', styles.columnsByGroups)}>
                                                {columnsByGroups[groupId]?.map(renderColumnsItem)}
                                            </div>
                                        </TabPanel>
                                    ))}
                                </TabView>
                            )}
                        </div>
                        <div className={styles.selectedWrapper}>
                            <h2 className={styles.screenerColumns_title}>Выбранные колонки</h2>
                            <div className={styles.screenerColumns}>
                                {primaryScreenerColumns.map((primaryColumnId) => (
                                    <ColumnItem
                                        column={allPreparedColumns[primaryColumnId]}
                                        key={activeGroup?.id + primaryColumnId}
                                    >
                                        <LockIcon />
                                    </ColumnItem>
                                ))}
                                <SortableList
                                    items={
                                        nonPrimaryScreenerColumns.map(
                                            (nonPrimaryColumnId) => allPreparedColumns[nonPrimaryColumnId] ??
                                                getDefaultColumn(nonPrimaryColumnId),
                                        ) ?? []
                                    }
                                    onChange={(resortableColumns) => {
                                        setNonPrimaryScreenerColumns(resortableColumns.map(({ id }) => id));
                                    }}
                                    renderItem={(column) => (
                                        <SortableList.Item id={column.id} disabled={column.primary}>
                                            <ColumnItem column={column} handleDeleteColumn={handleSwitchColumn}>
                                                <SortableList.DragHandle>
                                                    <BurgerMenuIcon />
                                                </SortableList.DragHandle>
                                            </ColumnItem>
                                        </SortableList.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classNames('flex justify-content-end', styles.actionsWrapper)}>
                        <Button
                            label="Отменить"
                            onClick={() => setIsVisible(false)}
                            className={classNames(styles.cancelButton)}
                        />
                        <Button
                            label="Ok"
                            onClick={handleSaveColumns}
                            className={classNames(styles.createButton)}
                            disabled={isLoading}
                        />
                    </div>
                </>
            </ModalWindow>
        </>
    );
};
