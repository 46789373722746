import classNames from 'classnames';
import { Button } from 'primereact/button';
import { PlusIcon } from 'primereact/icons/plus';
import { WatchlistDropdown } from '@modules/Investorpro/shared/components/WatchlistSidebar/components/NotEmptyFavorites/WatchlistDropdown';
import { WatchlistDataTable } from '@modules/Investorpro/shared/components/WatchlistSidebar/components/NotEmptyFavorites/WatchlistDataTable';
import { AddInstrumentModal } from '@modules/Investorpro/shared/components/WatchlistSidebar/components/NotEmptyFavorites/AddInstrumentModal';
import { useModal } from '@libs/utils';
import { useEffect } from 'react';
import { type SettingParam } from '@modules/Investorpro/shared/components/WatchlistSidebar/types';
import { useSelector } from 'react-redux';
import { getWatchlistDataThunk, type RootState, useAppDispatch } from '@store/store';
import { AddWatchlistModal } from '@modules/Investorpro/shared/components/WatchlistSidebar/components/AddWatchlistModal';
import { SortOrderTypes } from '@libs/types';
import { SortFields } from '@modules/Investorpro/types/watchlist.type';

import styles from './styles.module.scss';

type Props = {
    selectedSettingParam: SettingParam;
};

export const NotEmptyFavorites = ({ selectedSettingParam }: Props) => {
    const dispatch = useAppDispatch();
    const { isOpen, openModal, closeModal } = useModal();
    const {
        isOpen: isOpenAddWatchlistModal,
        openModal: openAddWatchlistModal,
        closeModal: closeAddWatchlistModal,
    } = useModal();

    const selectedWatchlist = useSelector((state: RootState) => state.investorProfile.selectedWatchlist);

    useEffect(() => {
        if (selectedWatchlist) {
            dispatch(
                getWatchlistDataThunk({
                    watchlistId: selectedWatchlist.id,
                    body: { sortField: SortFields.SHORTNAME, sortOrder: SortOrderTypes.DESC },
                }),
            );
        }
    }, [selectedWatchlist]);

    return (
        <>
            <div
                className={classNames(
                    styles.notEmptyFavoritesWrapper,
                    'flex',
                    'flex-column',
                    'align-items-center',
                    'justify-content-between',
                )}
            >
                <div className={classNames(styles.favoritesListWrapper, 'flex')}>
                    <Button icon={<PlusIcon />} onClick={openAddWatchlistModal} />
                    <WatchlistDropdown />
                </div>
                <WatchlistDataTable selectedSettingParam={selectedSettingParam} />

                <Button
                    className={classNames(styles.btn, 'flex', 'justify-content-center', 'align-items-center')}
                    icon={<PlusIcon />}
                    label={'Добавить инструмент'}
                    onClick={openModal}
                />
            </div>
            <AddWatchlistModal isOpen={isOpenAddWatchlistModal} closeModal={closeAddWatchlistModal} />
            <AddInstrumentModal isOpen={isOpen} closeModal={closeModal} selectedWatchlistId={selectedWatchlist?.id} />
        </>
    );
};
