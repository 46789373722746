import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { useApi } from '../../../api/index';
import { type Sectors, sectors } from '../../../entities/portfolio/PortfolioDiversification';

export const useGetSectoralDiversificationQuery = (
    id: string,
    enabled = true,
    onStatusChange?: (status: 'error' | 'success' | 'pending') => void,
) => {
    const api = useApi();

    const query = useQuery({
        queryKey: ['portfolio', id, 'diversification', 'sectoral-diversification'],
        queryFn: async () => await api.portfolio.diversification.getSectoralDiversification({ params: { id } }),
        enabled: Boolean(id) && enabled,
        select: ({ sectoralDiversification }) => (
            Object.entries(sectors).map(([key, value]) => ({
                percent: sectoralDiversification[key as Sectors],
                label: value.label,
                Component: value.Component,
            }))
        ),
    });

    useEffect(() => {
        if (query.isLoading || query.isFetching) {
            onStatusChange?.('pending');
        }

        if (query.isError) {
            onStatusChange?.('error');
        }

        if (query.isSuccess && !query.isLoading && !query.isFetching) {
            onStatusChange?.('success');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isLoading, query.isError, query.isSuccess, query.isFetching, query.isPending]);

    return query;
};
