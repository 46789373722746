import { useEffect } from 'react';
import { SelectedButtonTabs } from '@modules/Investorpro/shared/components/SelectedButtonTabs';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getBondDescriptionDataThunk } from '@store/store/thunk/investorpro/bond.thunk';

import { Rating } from './components/Rating';
import { TwoTables } from './components/TwoTables';
import {
    LEFT_COUPONS_TABLE_ROWS,
    LEFT_ISSUE_TABLE_ROWS,
    LEFT_PRICE_TABLE_ROWS,
    LEFT_REDEMPTION_TABLE_ROWS,
    RIGHT_COUPONS_TABLE_ROWS,
    RIGHT_ISSUE_TABLE_ROWS,
    RIGHT_PRICE_TABLE_ROWS,
    RIGHT_REDEMPTION_TABLE_ROWS,
} from './utils/columnsAndRows';
import { TradeResults } from './components/TradeResults';
import styles from './styles.module.scss';

type Props = {
    currentTab: string;
    handleTabChange: (value: string) => void;
};

export const DescriptionInformationTab = ({ currentTab, handleTabChange }: Props) => {
    const {
        fintoolId,
        info,
        isin,
        descriptionInfo,
        loadings: { commonLoading },
    } = useAppSelector((state) => state.bond);
    const dispatch = useAppDispatch();
    const { updatedAt, currentValue } = info;
    const { issue, coupons, price, redemption, rating, loaded } = descriptionInfo;

    useEffect(() => {
        if (!loaded && fintoolId && updatedAt && isin && currentValue) {
            dispatch(
                getBondDescriptionDataThunk({
                    isin,
                    fintoolId,
                    tradeDate: updatedAt,
                    lastValue: currentValue,
                }),
            );
        }
    }, [loaded, dispatch, fintoolId, currentValue]);

    const options = [
        {
            value: 'issiance',
            label: 'Эмиссия',
            tab: (
                <TwoTables
                    leftRows={LEFT_ISSUE_TABLE_ROWS}
                    rightRows={RIGHT_ISSUE_TABLE_ROWS}
                    data={commonLoading ? null : issue}
                />
            ),
        },
        {
            value: 'price',
            label: 'Цена',
            tab: (
                <TwoTables
                    leftRows={LEFT_PRICE_TABLE_ROWS}
                    rightRows={RIGHT_PRICE_TABLE_ROWS}
                    data={commonLoading ? null : price}
                />
            ),
        },
        {
            value: 'coupons',
            label: 'Купоны',
            tab: (
                <TwoTables
                    leftRows={LEFT_COUPONS_TABLE_ROWS}
                    rightRows={RIGHT_COUPONS_TABLE_ROWS}
                    data={commonLoading ? null : coupons}
                />
            ),
        },
        {
            value: 'redemption',
            label: 'Погашение',
            tab: (
                <TwoTables
                    leftRows={LEFT_REDEMPTION_TABLE_ROWS}
                    rightRows={RIGHT_REDEMPTION_TABLE_ROWS}
                    data={commonLoading ? null : redemption}
                />
            ),
        },
        {
            value: 'rating',
            label: 'Рейтинг',
            tab: <Rating data={commonLoading ? null : rating} />,
        },
        {
            value: 'total',
            label: 'Итоги торгов',
            tab: <TradeResults />,
        },
    ];

    const currentOption = options.find((option) => option.value === currentTab);

    return (
        <SelectedButtonTabs
            options={options}
            value={currentTab}
            className={styles.descriptionInformationTab}
            handleChange={handleTabChange}
            title="Описательная информация"
        >
            {currentOption?.tab}
        </SelectedButtonTabs>
    );
};
