import { useMemo } from 'react'
import { formatNullableValue } from '@modules/Investorpro/shared/utils/format.util';
import { NONE_SYMBOL } from '@modules/Investorpro/shared/constants';
import { formatValueByType } from '@modules/Investorpro/shared/utils/formatValueByType.util';

import { type ObjectRowData, type ObjectKeyColumnRow } from './types'


export const useTableData = <T>(data: T | null, keys: Array<ObjectKeyColumnRow<T>>): ObjectRowData[] => {
    return useMemo(() => {
        const getValue = ({ render, key, type, numberDivider }: Omit<ObjectKeyColumnRow<T>, 'label'>) => {
            if (render) {
                return formatNullableValue(render(data));
            }

            if (!data || !key) return NONE_SYMBOL

            return formatValueByType({
                value: data[key],
                type,
                numberDivider,
            })
        }

        return keys.map(({ label, ...value }) => {
            return {
                label,
                value: getValue(value),
            }
        })
    }, [data])
}
