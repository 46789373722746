import classNames from 'classnames';
import { TimesIcon } from 'primereact/icons/times';

import styles from './styles.module.scss';

type Props = {
    color: string;
    withArrow?: boolean;
    position?: 'left' | 'right' | 'top' | 'bottom';
    children?: React.ReactNode;
    text?: string;
    className?: string;
    y: number;
    positionType?: 'absolute' | 'relative';
    handleDelete?: () => void;
    x?: number;
    width?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Mark = ({
    className,
    color,
    withArrow = false,
    position = 'left',
    children,
    text,
    positionType = 'absolute',
    y,
    x,
    handleDelete,
    width,
    ...props
}: Props) => {
    const markPosition =
        positionType === 'absolute'
            ? { left: `${x ?? 0}px`, top: `${y}px` }
            : { transform: `translate(${x ?? 0}px, ${y}px)` };

    return (
        <div
            className={styles.mark}
            style={{
                position: positionType,
                ...markPosition,
            }}
        >
            <div
                className={classNames(styles.mark_markContent, className)}
                data-with-arrow={withArrow}
                {...props}
                data-pos={position}
                style={{
                    backgroundColor: color,
                    width,
                }}
            >
                {children ?? <p>{text}</p>}
                {withArrow && (
                    <svg
width="7" height="22" viewBox="0 0 7 22"
fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7 21.5L7 0.5C5.94803 0.5 5.09524 1.35279 5.09524 2.40476L5.09524 4.09626C5.09524 4.67442 4.86321 5.2284 4.45119 5.63399L0.737516 9.28963C-0.217629 10.2299 -0.21763 11.7701 0.737514 12.7104L4.45118 16.366C4.86321 16.7716 5.09524 17.3256 5.09524 17.9037L5.09524 19.5952C5.09524 20.6472 5.94803 21.5 7 21.5Z"
                            fill={color}
                        />
                    </svg>
                )}
                {handleDelete && (
                    <div
                        className={classNames(styles.mark_deleteButton, 'flex', 'align-items-center')}
                        onClick={handleDelete}
                    >
                        <TimesIcon />
                    </div>
                )}
            </div>
        </div>
    );
};
