import { type RootState, type AppDispatch } from '@store/store';
import { setHelplineMessage } from '@store/store/slices';
import { type HTMLAttributes } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';

type HelpLineWrapperProps = {
    message: string;
} & HTMLAttributes<HTMLDivElement>;

export const HelpLineWrapper = ({ message, children, ...props }: HelpLineWrapperProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { defaultMessage, opened: isOpened } = useSelector((state: RootState) => state.helpline);

    const setMessage = () => {
        if (isOpened && message) {
            dispatch(setHelplineMessage(message));
        }
    };

    const resetHelplineMessage = () => {
        if (isOpened) {
            dispatch(setHelplineMessage(defaultMessage));
        }
    };

    return (
        <div
            {...props}
            className={styles.helpLineWrapper}
            onMouseEnter={setMessage}
            onMouseLeave={resetHelplineMessage}
        >
            {children}
        </div>
    );
};
