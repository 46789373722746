import { type StockMarketData } from '@libs/types';
import { roundInstrumentPrice } from '@modules/Investorpro/modules/BondStockPage/shared/utils';
import { calculateRelativeChange } from '@modules/Investorpro/shared/utils/slice.utils';

export const calculatePriceChanges = (
    stock: StockMarketData,
    periodId: string,
    prevPrice: number | null,
    currentPrice?: number,
) => {
    const stockCurrentPrice = currentPrice ?? stock.price;

    return {
        ...stock,
        price: stockCurrentPrice,
        prevTradeDayPrice: {
            ...stock.prevTradeDayPrice,
            [periodId]: prevPrice,
        },
        changeAbsolute: {
            ...stock.changeAbsolute,
            [periodId]: prevPrice ? roundInstrumentPrice(stockCurrentPrice - prevPrice) : 0,
        },
        changeRelative: {
            ...stock.changeRelative,
            [periodId]: prevPrice ? calculateRelativeChange(stockCurrentPrice, prevPrice) : 0,
        },
    };
};
