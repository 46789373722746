import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useAuthorize } from '@libs/utils';
import { ReactComponent as LogOutIcon } from '@shared/images/svg/LogOutIcon.svg';

import styles from './styles.module.scss';

const linkRoutes = [
    {
        url: '/personal-account',
        label: 'Личный кабинет',
    },
];

type DropdownUserPanelProps = {
    items?: Array<{ label: string; url: string; isExternal?: boolean }>;
};

export const DropdownUserPanel = ({ items = linkRoutes }: DropdownUserPanelProps) => {
    const { logoutHandler } = useAuthorize();

    const liClassString = ['flex', 'align-items-center'];

    return (
        <ul className={styles.functionList}>
            {items.map(({ url, label, isExternal }) => (
                <li className={classNames(liClassString)} key={url}>
                    {isExternal
? (
                        <p onClick={() => (window.location.href = url)}>{label}</p>
                    )
: (
                        <Link to={url}>
                            <p>{label}</p>
                        </Link>
                    )}
                </li>
            ))}
            {/* <li className={classNames(liClassString)}> */}
            {/*    <p>Уведомления</p> */}
            {/* </li> */}

            {/* todo: removed from april release */}
            {/* <li className={classNames(liClassString)}>
                <Link to={linkRoutes.cart} className={classNames(liClassString)}>
                    <p>Корзина</p>
                    {cartCount && <span className={styles.cartCount}>{cartCount}</span>}
                </Link>
            </li> */}

            {/* <li className={classNames(liClassString)}> */}
            {/*    <p>Справка</p> */}
            {/* </li> */}
            {/* <li className={classNames(liClassString)}> */}
            {/*    <p>Написать в поддержку</p> */}
            {/* </li> */}
            <li onClick={logoutHandler} className={classNames(liClassString, styles.logoutWrapper)}>
                <LogOutIcon />
                <p>Выйти</p>
            </li>
        </ul>
    );
};
