import { Box, Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import { type KeyStatistics, type PortfolioDynamicsResponseData } from '../../../../entities/portfolio';
import { ViewEmptyStub } from '../../../../shared/Layout';
import { Table } from '../../../../shared/Table/Table';
import { Chart } from '../../Dynamics/Chart/Chart';
import { useConfigureKeyStatisticsColumns } from '../../GeneralInfo/KeyStatisticsTable/useConfigureKeyStatisticsColumns';
import { InfoIcon } from '../ReportIcons/InfoIcon';

type Props = {
    dynamics: PortfolioDynamicsResponseData;
    keyStatistics: KeyStatistics[];
};

export const ReportDynamics: FC<Props> = ({ dynamics, keyStatistics }) => {
    const columns = useConfigureKeyStatisticsColumns();

    return (
        <Stack gap={33} w={1350} p={5}>
            <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
                <Group align="center" justify="space-between">
                    <Text fz="45px" fw={400} lh="47px">
                        {'Динамика'}
                    </Text>
                    <Group
                        gap={12}
                        miw={180}
                        mih={24}
                        px={16}
                        align="center"
                        py={8}
                        style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                    >
                        <InfoIcon />
                        <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                            {'Информация'}
                        </Text>
                    </Group>
                </Group>
                <Text
                    fz={18}
                    fw={400}
                    ff="FavoritPro"
                    lh="20px"
                    style={{ columnCount: 2, columnGap: '120px', whiteSpace: 'pre-wrap' }}
                >
                    {
                        'В разделе Динамика вы можете оценить историческую доходность портфеля на графике, \
включая реинвестированные дивиденды и купоны, но без учета комиссионных сборов и налогов.\n\n\
Анализ доходности проводится на горизонте 10 лет. Текущее распределение долей по инструментам \
актуально на сегодняшний день.\n\nЕсли в какой-то момент в прошлом инструмент не был доступен \
для покупки, то анализ портфеля проводился без него.\nДополнительно вы можете проанализировать, \
как на динамику вашего портфеля влияют различные события (например, ключевая ставка ЦБ), а также \
сравнить динамику портфеля с динамикой различных бенчмарков и инструментов (например, Индекс МосБиржи или Золото).\n\n\
Все расчеты сделаны на основе бэктестирования исторических данных и не подразумевают \
совершение каких-либо реальных сделок в прошлом.'
                    }
                </Text>
            </Stack>
            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'Ключевые статистические данные'}
                </Text>
                <Table
                    columns={columns}
                    data={keyStatistics}
                    striped={true}
                    emptyStub={<ViewEmptyStub title="Данных по ключевым статистическим данным нет" />}
                />
            </Stack>
            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'Перформанс портфеля'}
                </Text>
                <Box h={500} w="100%">
                    <Chart data={dynamics ?? { performances: [], events: [] }} isInReport={true} />
                </Box>
            </Stack>
        </Stack>
    );
};
