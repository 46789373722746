import {
    Center,
    Combobox,
    Group,
    InputBase,
    Loader,
    ScrollArea,
    type SelectFactory,
    type SelectProps,
    getOptionsLockup,
    getParsedComboboxData,
    isOptionsGroup,
    useCombobox,
    useResolvedStylesApi,
} from '@mantine/core';
import { useIntersection, useUncontrolled } from '@mantine/hooks';
import { type FC, useEffect, useMemo, useRef } from 'react';

import { Check, ChevronDown, ChevronUp, Close, Search } from '../Icon';

type Props = SelectProps & {
    fetchNextPage: () => void;
    hasNextPage: boolean;
    isLoading: boolean;
};

export const SelectAsyncPaginate: FC<Props> = ({
    allowDeselect,
    value,
    defaultValue,
    onDropdownOpen,
    onDropdownClose,
    onChange,
    data = [],
    placeholder,
    label,
    fetchNextPage,
    hasNextPage,
    clearable = true,
    disabled,
    readOnly,
    clearButtonProps,
    onClear,
    searchValue,
    defaultSearchValue,
    onSearchChange,
    onOptionSubmit,
    searchable,
    onFocus,
    onBlur,
    onClick,
    error,
    renderOption,
    isLoading,
    comboboxProps,
    styles,
    classNames,
    ...props
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { ref, entry } = useIntersection({
        root: containerRef.current,
        threshold: 1,
    });

    const combobox = useCombobox({
        onDropdownOpen: () => {
            onDropdownOpen?.();
            combobox.updateSelectedOptionIndex('active', { scrollIntoView: true });
        },
        onDropdownClose: () => {
            onDropdownClose?.();
            combobox.resetSelectedOption();
        },
    });

    const parsedData = useMemo(() => getParsedComboboxData(data), [data]);
    const optionsLockup = useMemo(() => getOptionsLockup(parsedData), [parsedData]);

    const [uncontrolledValue, setUncontrolledValue] = useUncontrolled({
        value,
        defaultValue,
        finalValue: null,
        onChange,
    });

    const selectedOption = typeof uncontrolledValue === 'string' ? optionsLockup[uncontrolledValue] : null;

    const [search, setSearch] = useUncontrolled({
        value: searchValue,
        defaultValue: defaultSearchValue,
        finalValue: selectedOption ? selectedOption.label : '',
        onChange: onSearchChange,
    });

    const { resolvedClassNames, resolvedStyles } = useResolvedStylesApi<SelectFactory>({
        props,
        styles,
        classNames,
    });

    useEffect(() => {
        if (value === null) {
            setSearch('');
        }

        if (typeof value === 'string' && selectedOption) {
            setSearch(selectedOption.label);
        }
    }, [value, selectedOption, setSearch]);

    const options = useMemo(
        () => parsedData.map((item, i) => {
                if (!isOptionsGroup(item)) {
                    return (
                        <Combobox.Option
                            value={item.value}
                            key={item.value}
                            active={item.value === uncontrolledValue}
                            data-checked={item.value === uncontrolledValue}
                        >
                            {typeof renderOption === 'function'
? (
                                renderOption({
                                    option: item,
                                    checked: item.value === uncontrolledValue,
                                })
                            )
: (
                                <Group justify="space-between" wrap="nowrap">
                                    <span ref={i === parsedData.length - 1 ? ref : null}>{item.label}</span>
                                    {item.value === uncontrolledValue
? (
                                        <Group flex={1} justify="flex-end">
                                            <Check width={24} height={24} color="#2F9CEB" />
                                        </Group>
                                    )
: null}
                                </Group>
                            )}
                        </Combobox.Option>
                    );
                }

                return null;
            }),
        [parsedData, ref, uncontrolledValue, renderOption],
    );

    useEffect(() => {
        if (entry?.isIntersecting && hasNextPage) fetchNextPage();
    }, [entry?.isIntersecting, hasNextPage, fetchNextPage]);

    const clearButton = clearable && !!uncontrolledValue && !disabled && !readOnly && (
        <Combobox.ClearButton
            {...clearButtonProps}
            size={24}
            icon={<Close width={24} height={24} />}
            onClear={() => {
                setUncontrolledValue(null, null);
                setSearch('');
                onClear?.();
            }}
        />
    );

    const searchButton = searchable && <Search color="#A5B1C0" width={24} height={24} />;

    return (
        <Combobox
            store={combobox}
            readOnly={readOnly}
            withinPortal={comboboxProps?.withinPortal}
            styles={resolvedStyles}
            classNames={resolvedClassNames}
            offset={2}
            onOptionSubmit={(val) => {
                onOptionSubmit?.(val);
                // eslint-disable-next-line no-nested-ternary
                const optionLockup = allowDeselect
                    ? optionsLockup[val].value === uncontrolledValue
                        ? null
                        : optionsLockup[val]
                    : optionsLockup[val];

                const nextValue = optionLockup ? optionLockup.value : null;

                setUncontrolledValue(nextValue, optionLockup);
                setSearch(typeof nextValue === 'string' ? optionLockup?.label ?? '' : '');
                combobox.closeDropdown();
            }}
        >
            <Combobox.Target targetType={searchable ? 'input' : 'button'}>
                <InputBase
                    {...props}
                    disabled={disabled}
                    label={label}
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    readOnly={readOnly || !searchable}
                    value={search}
                    leftSection={uncontrolledValue ? clearButton : searchButton}
                    leftSectionPointerEvents={clearButton ? 'all' : 'none'}
                    rightSectionPointerEvents="none"
                    onChange={(event) => {
                        setSearch(event.currentTarget.value);
                        combobox.openDropdown();
                    }}
                    onFocus={(event) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        searchable && combobox.openDropdown();
                        onFocus?.(event);
                    }}
                    onBlur={(event) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        searchable && combobox.closeDropdown();
                        setSearch(uncontrolledValue !== null ? optionsLockup[uncontrolledValue]?.label || '' : '');
                        onBlur?.(event);
                    }}
                    onClick={(event) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        searchable ? combobox.openDropdown() : combobox.toggleDropdown();
                        onClick?.(event);
                    }}
                    pointer={!searchable}
                    error={error}
                    placeholder={placeholder}
                    classNames={resolvedClassNames}
                    styles={resolvedStyles}
                    rightSection={
                        combobox.dropdownOpened
? (
                            <ChevronUp width={24} height={24} />
                        )
: (
                            <ChevronDown width={24} height={24} />
                        )
                    }
                />
            </Combobox.Target>
            <Combobox.Dropdown>
                <Combobox.Options ref={containerRef}>
                    <ScrollArea.Autosize mah={240} type="scroll">
                        {options.length === 0
? (
                            <Combobox.Empty>
                                {props.nothingFoundMessage ? props.nothingFoundMessage : 'Ничего не найдено'}
                            </Combobox.Empty>
                        )
: (
                            options
                        )}
                        {entry?.isIntersecting && hasNextPage && isLoading
? (
                            <Combobox.Option value="">
                                <Center>
                                    <Loader size={20} />
                                </Center>
                            </Combobox.Option>
                        )
: null}
                    </ScrollArea.Autosize>
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
};
