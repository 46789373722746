import { type LeastCorrelationToolsResponse } from '../../../../../PortfolioPage/entities';
import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getLeastCorrelatingTools: ApiFactory<
    ApiEndpoint<LeastCorrelationToolsResponse, void, { id: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<LeastCorrelationToolsResponse>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/diversification/least-correlating/${params.id}`,
        { signal },
    );

    return data;
};
