import { Center, Loader, ScrollArea, Tabs, TextInput } from '@mantine/core';
import { useDebouncedValue, useInViewport } from '@mantine/hooks';
import { useInfiniteQuery } from '@tanstack/react-query';
import { type FC, useCallback, useEffect, useState } from 'react';

import { useApi } from '../../api';
import { type TickerDictionaryItem } from '../../entities';
import { Search } from '../../shared/Icon';
import { type PortfolioToolValues } from '../PortfolioForm.schema';
import { ToolItem } from './ToolItem';

type Props = {
    onToolsChange: (tools: PortfolioToolValues[]) => void;
    tickers: PortfolioToolValues[];
};

const tickerDictionaryLimit = 10;

export const ListOfTools: FC<Props> = ({ tickers, onToolsChange }) => {
    const api = useApi();
    const { ref, inViewport } = useInViewport();
    const [activeTab, setActiveTab] = useState<string | null>('SHARE');
    const [search, setSearch] = useState<string>('');
    const [debounced] = useDebouncedValue(search, 500);

    // TODO: согласовать с бэком переработку эндпоинта с пагинацией
    const { data, hasNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: ['ticker', 'dictionary', debounced, activeTab],
        queryFn: async ({ pageParam }) => await api.ticker.getTickerDictionary({
                params: {
                    start: pageParam,
                    limit: tickerDictionaryLimit,
                    type: activeTab ?? 'SHARE',
                    query: debounced,
                },
            }),
        initialPageParam: 0,
        getNextPageParam: (lastPage, _, lastPageParam) => lastPage.length === tickerDictionaryLimit ? lastPageParam + tickerDictionaryLimit : null,
        select: ({ pages }) => pages.flatMap((item) => item),
    });

    const handleToggle = (tool: TickerDictionaryItem) => (checked: boolean) => {
        if (checked) {
            onToolsChange([...tickers, { ...tool, percent: 0.01, value: 0 }]);

            return;
        }
        onToolsChange(tickers?.filter(({ ticker }) => tool.ticker !== ticker));
    };

    const isToolChecked = useCallback(
        (ticker: string) => Boolean(tickers?.find((tool) => ticker === tool.ticker)),
        [tickers],
    );

    const fetchNextPageTickerDictionary = useCallback(() => {
        fetchNextPage().catch((e) => console.error(e));
    }, [fetchNextPage]);

    useEffect(() => {
        if (inViewport && hasNextPage) fetchNextPageTickerDictionary();
    }, [inViewport, fetchNextPageTickerDictionary, hasNextPage]);

    return (
        <Tabs
color="#F2433D" value={activeTab} onChange={setActiveTab}
h="100%">
            <Tabs.List pr={36}>
                <Tabs.Tab defaultValue="SHARE" value="SHARE">
                    {'Акции'}
                </Tabs.Tab>
                <Tabs.Tab value="BOND">{'Облигации'}</Tabs.Tab>
                <Tabs.Tab value="PPIF">{'Фонды'}</Tabs.Tab>
            </Tabs.List>
            <TextInput
                mt={26}
                mb={7}
                pr={36}
                leftSection={<Search width={24} height={24} color="#A5B1C0" />}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Поиск по инструментам"
            />
            <ScrollArea.Autosize w="100%" mah="100%" scrollbarSize={6}>
                {data?.map((tool) => (
                    <ToolItem
                        isChecked={isToolChecked(tool.ticker)}
                        tgl={handleToggle(tool)}
                        tool={tool}
                        key={tool.ticker}
                    />
                ))}
                <div ref={ref} style={{ height: 32 }}>
                    {inViewport && hasNextPage
? (
                        <Center h={60}>
                            <Loader />
                        </Center>
                    )
: null}
                </div>
            </ScrollArea.Autosize>
        </Tabs>
    );
};
