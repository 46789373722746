import { useState } from 'react';
import { InDevelopmentStageStub } from '@modules/Investorpro/modules/BondStockPage/shared/components/InDevelopmentStageStub';

import { OPTIONS } from './constants';

export const FinancialReportTab = () => {
    const [currentTab, setCurrentTab] = useState(OPTIONS[0].value);

    return <InDevelopmentStageStub />;
};
