import { Box, Image, Stack, Text } from '@mantine/core';
import { type UseFormReturnType } from '@mantine/form';
import { type FC } from 'react';

import { type ReportFormTitleValues } from './ReportForm.schema';

type Props = {
    form: UseFormReturnType<ReportFormTitleValues>;
};

export const ReportTitlePreview: FC<Props> = ({ form }) => (
    <Stack
mih={403} bg={form.values.background} w="100%"
h="100%" p="50px 43px 40px 44px" display="grid">
        {form.values.logo
? (
            <Image
h={20} w="fit-content" fit="contain"
key={form.values.logo} src={form.values.logo} />
        )
: (
            <Box h={20} />
        )}
        <Stack gap={8} style={{ alignSelf: 'center' }}>
            <Text
ff="Inter" fz={20} fw={700}
lh="22px" c={form.values.textColor} style={{ wordBreak: 'break-word' }}>
                {form.values.name}
            </Text>
            <Text
ff="Inter" fz={12} fw={400}
lh="16px" c={form.values.textColor} style={{ wordBreak: 'break-word' }}>
                {form.values.description}
            </Text>
        </Stack>
        <Text
            ff="Inter"
            fz={9}
            fw={400}
            lh="16px"
            c={form.values.textColor}
            style={{
                alignSelf: 'end',
                wordBreak: 'break-word',
            }}
        >
            {form.values.footer}
        </Text>
    </Stack>
);
