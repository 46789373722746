import { InputText } from 'primereact/inputtext';
import { Controller, type FieldPath, type FieldValues, type UseControllerProps } from 'react-hook-form';
import { type ChangeEventHandler } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type IControlledInputProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
    className?: string;
    type?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    maxLength?: number;
    withoutArrows?: boolean;
    placeholder?: string;
} & Omit<UseControllerProps<TFieldValues, TName>, 'render'>;

export const ControlledInput = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    className,
    type,
    onChange,
    maxLength,
    withoutArrows = true,
    placeholder,
    ...props
}: IControlledInputProps<TFieldValues, TName>) => {
    return (
        <Controller
            {...props}
            render={({ field }) => (
                <InputText
                    type={type}
                    {...field}
                    className={classNames(withoutArrows && styles.withoutArrows, className)}
                    maxLength={maxLength}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            )}
        />
    );
};
