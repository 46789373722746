import { Group, Stack, Text } from '@mantine/core';
import { type FC, useCallback } from 'react';
import { type TooltipProps } from 'recharts';
import { type NameType, type ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { Circle } from '../../../../shared/Icon';

type Props = TooltipProps<ValueType, NameType> & { performanceType: string };

export const TooltipContent: FC<Props> = ({ payload, active, performanceType }) => {
    const getLabel = useCallback(
        (name?: NameType) => {
            if (!name) return '';

            if (performanceType === 'STRESS_TEST_MODEL') {
                return {
                    value: 'Стресс-тест риск',
                    expectedProfit: 'Ожидаемый доход (5 лет)',
                }[name];
            }

            if (performanceType === 'VOLATILITY_MODEL') {
                return {
                    value: 'Риск',
                    expectedProfit: 'Годовая доходность',
                }[name];
            }
        },
        [performanceType],
    );

    if (!active || !payload?.length) return null;

    return (
        <Stack gap={4}>
            {payload.map((entry) => (
                <Group justify="space-between" key={entry.name} wrap="nowrap">
                    <Group gap={8} wrap="nowrap">
                        <Circle width={8} height={8} color={entry.color} />
                        <Text
fw={300} fz={14} lh="20px"
c="#88919A">
                            {getLabel(entry.name)}
                        </Text>
                    </Group>
                    <Text ff="Inter" fz={13} lh="20px">
                        {entry.value?.toLocaleString('ru')}
                        {'%'}
                    </Text>
                </Group>
            ))}
        </Stack>
    );
};
