import { SelectedButtonTabs } from '@modules/Investorpro/shared/components/SelectedButtonTabs';

import styles from './styles.module.scss';
import { PaymentType } from '../../../../services/types';
import { COUPONS_COLUMNS, AMORTISATION_COLUMNS, EARLY_REDEMPTION_COLUMNS } from './columns';
import { PaymentsTypeTab } from './components/PaymentsTypeTab';

type Props = {
    currentTab: string;
    handleTabChange: (value: string) => void;
};

export const PaymentsTab = ({ currentTab, handleTabChange }: Props) => {
    const options = [
        {
            value: 'coupons',
            label: 'Купоны',
            tab: <PaymentsTypeTab type={PaymentType.COUPON} columns={COUPONS_COLUMNS} />,
        },
        {
            value: 'amortisation',
            label: 'Амортизация',
            tab: <PaymentsTypeTab type={PaymentType.AMORTIZATION} columns={AMORTISATION_COLUMNS} />,
        },
        {
            value: 'earlyRedemption',
            label: 'Досрочное погашение',
            tab: <PaymentsTypeTab type={PaymentType.REDEMPTION} columns={EARLY_REDEMPTION_COLUMNS} />,
        },
    ];

    const currentOption = options.find((option) => option.value === currentTab);

    return (
        <SelectedButtonTabs
            className={styles.paymentsTab}
            title="Выплаты"
            options={options}
            value={currentTab}
            handleChange={handleTabChange}
        >
            {currentOption?.tab}
        </SelectedButtonTabs>
    );
};
