/* eslint-disable no-nested-ternary */
import { Group, Stack, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useLocalStorage } from '@mantine/hooks';
import dayjs from 'dayjs';
import { type FC } from 'react';
import { useParams } from 'react-router-dom';
import { PortfolioFilterKeys } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { Calendar } from '../../../shared/Icon';
import { ViewError } from '../../../shared/Layout';
import { type InferParams } from '../../../shared/router';
import { RiskChart } from './Chart/RiskChart';
import { DecreasesDurationTable } from './Tables/DecreasesDurationTable';
import { RiskMetricsTable } from './Tables/RiskMetricsTable';
import { useGetPortfolioRiskMetricsQuery } from './useGetPortfolioRiskMetricsQuery';

export const RiskMetrics: FC = () => {
    const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;

    const [dates, setDates] = useLocalStorage<[Date | null, Date | null]>({
        key: PortfolioFilterKeys.RISK_METRICS_FILTER,
        defaultValue: [dayjs().subtract(10, 'years').toDate(), dayjs().toDate()],
    });

    const { data, isError, error, isPending } = useGetPortfolioRiskMetricsQuery(portfolioId, dates);

    if (isError) {
        return (
            <Stack>
                <Text fz={19} lh="18px">
                    {'Риск метрики'}
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по риск метрике" />
            </Stack>
        );
    }

    return (
        <Group
wrap="nowrap" align="flex-start" gap={60}
w="100%">
            <Stack gap={25} w="70%">
                <Stack h={390} gap={0}>
                    <Group align="center" justify="space-between" pr={18}>
                        <Text lh="18px" fw={400} fz={19}>
                            {'Просадки портфеля от максимумов'}
                        </Text>
                        <DatePickerInput
                            disabled={true}
                            w={220}
                            radius={6}
                            maxDate={dayjs().toDate()}
                            rightSection={<Calendar color="#A5B1C0" width={24} height={24} />}
                            valueFormat="DD.MM.YYYY"
                            type="range"
                            placeholder="Выберите период"
                            value={[
                                dates[0] ? dayjs(dates[0]).toDate() : null,
                                dates[1] ? dayjs(dates[1]).toDate() : null,
                            ]}
                            onChange={setDates}
                            styles={{
                                input: { padding: '6px 18px 6px 12px' },
                            }}
                        />
                    </Group>
                    <RiskChart
                        data={data ?? { potentialRisksGraphic: [], durationsAndExtremes: [], riskMetrics: [] }}
                        isPending={isPending}
                    />
                </Stack>
                <Stack gap={25}>
                    <Text lh="18px" fw={400} fz={19}>
                        {'Длительность снижений и экстремумы'}
                    </Text>
                    <DecreasesDurationTable data={data?.durationsAndExtremes ?? []} isPending={isPending} />
                </Stack>
            </Stack>
            <Stack gap={25} w="30%">
                <Text lh="18px" fw={400} fz={19}>
                    {'Риск метрики'}
                </Text>
                <RiskMetricsTable data={data?.riskMetrics ?? []} isPending={isPending} />
            </Stack>
        </Group>
    );
};
