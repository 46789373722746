import { Group, NumberInput, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

type Props = {
    percent: number;
    value: number;
    portfolioCurrency: string;
};

export const FreeMoneyItem: FC<Props> = ({ percent, value, portfolioCurrency }) => (
    <Group
        wrap="nowrap"
        pb={4}
        justify="space-between"
        align="flex-start"
        style={{ borderBottom: '1px solid #D8E1EB' }}
    >
        <Stack gap={0}>
            <Text
fz={14} fw={400} lh="20px"
c="#14171F">
                {'Свободные деньги'}
            </Text>
            <Text
fz={12} fw={400} lh="20px"
c="#8A96A6">
                {portfolioCurrency}
            </Text>
        </Stack>
        <Group gap={8}>
            <NumberInput
                maw={140}
                suffix="%"
                decimalScale={2}
                decimalSeparator=","
                value={percent}
                max={100}
                allowNegative={false}
                disabled={true}
                hideControls={true}
                thousandSeparator=" "
                styles={{ input: { textAlign: 'end' } }}
            />
            <NumberInput
                maw={156}
                disabled={true}
                hideControls={true}
                value={value}
                max={999999999999}
                thousandSeparator=" "
                styles={{ input: { textAlign: 'end' } }}
            />
        </Group>
    </Group>
);
