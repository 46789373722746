import { Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import { type HistoricReturnsResponse } from '../../../../entities/portfolio';
import { Table } from '../../../../shared/Table/Table';
import { useHistoricReturnsColumns } from '../../HistoricReturns/useHistoricReturnsColumns';
import { InfoIcon } from '../ReportIcons/InfoIcon';
import { ViewEmptyStub } from '../../../../shared/Layout';

type Props = {
    benchmark: HistoricReturnsResponse;
    netPortfolio: HistoricReturnsResponse;
    portfolio: HistoricReturnsResponse;
};

export const ReportPortfolioAndBenchmarkReturns: FC<Props> = ({ benchmark, netPortfolio, portfolio }) => {
    const netPortfolioColumns = useHistoricReturnsColumns(netPortfolio?.seasons);
    const portfolioColumns = useHistoricReturnsColumns(portfolio?.seasons);
    const benchmarkColumns = useHistoricReturnsColumns(benchmark?.seasons);

    return (
        <Stack gap={20} w={1350} p={5}>
            <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
                <Group align="center" justify="space-between">
                    <Text fz={45} fw={400}>
                        {'Историческая доходность портфеля и бенчмарка'}
                    </Text>
                    <Group
                        gap={12}
                        miw={180}
                        mih={24}
                        px={16}
                        align="center"
                        py={8}
                        style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                    >
                        <InfoIcon />
                        <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                            {'Информация'}
                        </Text>
                    </Group>
                </Group>
                <Text
                    fz={18}
                    fw={400}
                    ff="FavoritPro"
                    style={{ columnCount: 2, columnGap: '120px', whiteSpace: 'pre-wrap' }}
                >
                    {
                        'В разделе Историческая доходность - сравнение вы можете оценить историческую \
доходность портфеля в разбивке по месяцам/годам в сравнении с выбранным \
бенчмарком.\n\nОсновной интерес представляет таблица Дельта, в которой \
наглядно видно, в какие месяцы портфель обгонял индекс \
по доходности, а в какие отставал от него.'
                    }
                </Text>
            </Stack>
            <Stack
w="100%" h="fit-content" pt={20}
gap={25}>
                <Text fz={30} fw={400}>
                    {'Портфель'}
                </Text>
                <Table
                    columns={portfolioColumns}
                    data={portfolio?.years || []}
                    highlightOnHover={true}
                    highlightColumnOnHover={true}
                    isFooterVisible={true}
                    emptyStub={<ViewEmptyStub title="Данных по исторической доходности нет" />}
                />
            </Stack>

            <Stack
w="100%" h="fit-content" pt={20}
gap={25}>
                <Text fz={30} fw={400}>
                    {'Бенчмарк'}
                </Text>
                <Table
                    columns={benchmarkColumns}
                    data={benchmark?.years || []}
                    highlightOnHover={true}
                    highlightColumnOnHover={true}
                    isFooterVisible={true}
                    emptyStub={<ViewEmptyStub title="Данных по исторической доходности нет" />}
                />
            </Stack>

            <Stack
w="100%" h="fit-content" pt={20}
gap={25}>
                <Text fz={30} fw={400}>
                    {'Дельта'}
                </Text>
                <Table
                    columns={netPortfolioColumns}
                    data={netPortfolio?.years || []}
                    highlightOnHover={true}
                    highlightColumnOnHover={true}
                    isFooterVisible={true}
                    emptyStub={<ViewEmptyStub title="Данных по исторической доходности нет" />}
                />
            </Stack>
        </Stack>
    );
};
