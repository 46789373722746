import { type CheckmarkListResponse } from '../../../../../PortfolioPage/entities';
import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getCheckMarks: ApiFactory<ApiEndpoint<
    CheckmarkListResponse, void, void
>> = (client) => async ({ signal }) => {
    const res = await client.get<CheckmarkListResponse>(
        '/moex-datashop-investor-pro-portfolios-service/api/v2/dynamics/checkmark-list',
        { signal },
    );

    return res.data;
};
