import { type FC } from 'react';

export const IndustryIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path
                d="M18 17H28C28.2761 17 28.5 17.2239 28.5 17.5V26.385C28.5 26.6611 28.2761 26.885 28 26.885H16.5"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
                d="M26.1666 27V23.0167C26.1666 22.7418 25.9446 22.5185 25.6697
                22.5168L23.5031 22.5032C23.2258 22.5014 23 22.7258 23 23.0031L23 27"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
                d="M9.58972 19.591C10.174 16.4751 10.1626 13.3714 10.1251 12.0805H17.8749C17.8374
                13.3714 17.826 16.4751 18.4103 19.591C18.9649 22.5491 20.0518 25.4928 20.6037
                26.8805H7.39634C7.94824 25.4928 9.03509 22.5491 9.58972 19.591Z"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
d="M10.5 14.9805H17.5" stroke="#636E80" strokeWidth="1.2"
strokeLinecap="square" />
            <path
                d="M16.4872 3.97382L16.4872 3.97381C15.4485 3.69289 14.0638 4.23578 13.578
                5.48977C13.3362 5.39566 13.0866 5.3491 12.8363 5.3491C12.2874 5.3491 11.7901
                5.57275 11.4141 5.90691C10.8119 6.44224 10.4533 7.3384 10.7184 8.21866C10.5013
                8.3834 10.3316 8.58812 10.2144 8.82236C10.0104 9.22975 9.98728 9.6811 10.0859
                10.0845C10.2613 10.8016 10.8652 11.4876 11.7482 11.573C11.7975 11.6229 11.8594
                11.6844 11.9262 11.7432C12.0597 11.8606 12.3341 12.0803 12.7057 12.0803H16.338C16.5504
                12.0803 16.7696 12.0453 16.9705 11.9401C17.1811 11.8299 17.3284 11.6653 17.4237
                11.4801C17.5178 11.2972 17.5608 11.0901 17.5811 10.8947C17.7876 10.7976 18.0033
                10.6732 18.2012 10.5297C18.5145 10.3025 18.9002 9.94405 19.0266 9.46857L19.0267
                9.46826C19.1381 9.04826 19.1753 8.56452 18.9675 8.08649C18.8374 7.78727 18.6284
                7.52923 18.3487 7.30701C18.6229 6.72093 18.572 6.07448 18.323 5.52541C17.9949
                4.80205 17.3112 4.19673 16.4872 3.97382Z"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
                d="M26.6 10.5C26.6 10.1686 26.3314 9.9 26 9.9C25.6686 9.9 25.4 10.1686 25.4 10.5L26.6
                10.5ZM25.4 16.5L25.4 17.1L26.6 17.1L26.6 16.5L25.4 16.5ZM25.4 10.5L25.4 16.5L26.6
                16.5L26.6 10.5L25.4 10.5Z"
                fill="#636E80"
            />
            <path
                d="M23.6 12.5C23.6 12.1686 23.3314 11.9 23 11.9C22.6686 11.9 22.4 12.1686 22.4 12.5L23.6
                12.5ZM22.4 16.5L22.4 17.1L23.6 17.1L23.6 16.5L22.4 16.5ZM22.4 12.5L22.4 16.5L23.6
                16.5L23.6 12.5L22.4 12.5Z"
                fill="#636E80"
            />
        </g>
    </svg>
);
