import { BreadCrumb } from '@modules/Investorpro/shared/components/BreadCrumb';
import { StompProvider } from '@libs/utils';
import { useHelplineDefaultMessage } from '@libs/utils/hooks/useDefaultMessage';

import { BondMarketChartBlock } from './components/BondMarketChartBlock';
import { CashFlow } from './components/CashFlow';

export const BondsMarketPage = () => {
    useHelplineDefaultMessage('helpline.marketDashboards.bonds.page');

    return (
        <div>
            <StompProvider url={process.env.REACT_APP_WS_QUOTESERVICE_URL!} autoConnect>
                <BreadCrumb />
                <BondMarketChartBlock />
                <CashFlow />
            </StompProvider>
        </div>
    );
};
