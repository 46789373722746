import { Watchlist } from '@modules/Investorpro/types/watchlist.type';

export enum SecurityType {
    STOCK = 'STOCK',
    BOND = 'BOND',
}

export type Security = {
    id: number;
    isin: string;
    type: SecurityType;
    exchange: string;
    watchlistIds: Watchlist['id'][];
};
