import { type ColumnType } from '@modules/Investorpro/modules/ScreenersPage/types';
import { Button } from 'primereact/button';
import { ReactComponent as TrashIcon } from '@shared/images/svg/Trash.svg';
import { type PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

type ColumnItemProps = {
    column: ColumnType;
    handleDeleteColumn?: (columnId: ColumnType['id']) => void;
};

export const ColumnItem = ({ children, column, handleDeleteColumn }: PropsWithChildren<ColumnItemProps>) => {
    return (
        <div className={classNames(styles.item, 'flex align-items-center')}>
            {children}
            <div className={classNames(styles.column)}>{column.name}</div>
            {column.primary
? null
: (
                <Button
                    rounded
                    text
                    severity="secondary"
                    className="flex-shrink-0"
                    onClick={() => {
                        handleDeleteColumn?.(column.id);
                    }}
                >
                    <TrashIcon />
                </Button>
            )}
        </div>
    );
};
