import { InputText } from 'primereact/inputtext';
import { ModalWindow } from '@libs/components';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { type AppDispatch, createWatchlistThunk } from '@store/store';
import { MAX_INPUT_VALUE_LENGTH, MIN_INPUT_VALUE_LENGTH } from '@modules/Investorpro/modules/ScreenersPage/constants';

import styles from './styles.module.scss';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
};

export const AddWatchlistModal = ({ isOpen, closeModal }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [value, setValue] = useState('');

    const createWatchlist = () => {
        dispatch(createWatchlistThunk({ name: value.trim() }));
        closeModal();
    };

    useEffect(() => {
        if (isOpen) {
            setValue('');
        }
    }, [isOpen]);

    return (
        <ModalWindow isVisible={isOpen} closeModal={closeModal}>
            <div className={classNames(styles.createFavoritesListModalWrapper, 'flex', 'flex-column')}>
                <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                    <h2>Создать новый список</h2>
                    <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder={'Введите название списка'}
                        maxLength={MAX_INPUT_VALUE_LENGTH}
                        minLength={MIN_INPUT_VALUE_LENGTH}
                    />
                </div>

                <div className={classNames(styles.btnWrapper, 'flex')}>
                    <Button label={'Отменить'} className={styles.cancelBtn} onClick={closeModal} />
                    <Button
                        label={'Создать список'}
                        className={styles.confirmBtn}
                        disabled={!value.trim().length}
                        onClick={createWatchlist}
                    />
                </div>
            </div>
        </ModalWindow>
    );
};
