import React, { memo, type MouseEvent, useRef } from 'react';
import classNames from 'classnames';
import { ECountryFlags, type TSecurity } from '@libs/types';
import { ReactComponent as Ru } from '@shared/images/svg/flags/Ru.svg';
import { useClickOutside } from '@libs/utils';
import {
    addSecurityToWatchListThunk,
    deleteSecurityFromWatchlistThunk,
    getWatchlistsThunk,
    type RootState,
    useAppDispatch,
    useAppSelector,
} from '@store/store';
import { type Watchlist } from '@modules/Investorpro/types/watchlist.type';
import { type Security } from '@modules/Investorpro/modules/ScreenersPage/types';
import { useSelector } from 'react-redux';
import { HelpLineWrapper } from '@libs/components';
import { usePermission } from '@libs/utils/hooks/usePermission';

import styles from './styles.module.scss';
import { FavoriteStar } from '../../../FavoriteStar';

type IProps = {
    item: TSecurity;
    isModalSearch: boolean;
    selectedWatchlistId?: number;
    withFavouriteDropdown?: boolean;
    handleClick?: () => void;
};

const countryFlags = {
    [ECountryFlags.RU]: Ru,
};

const securityTypes = {
    BOND: 'Облигация',
    STOCK: 'Акция',
};

const AngleDown = ({ onClick, open }: { onClick?: () => void; open: boolean }) => {
    return (
        <span
            onClick={onClick}
            className={classNames('pi pi-angle-down', { [`${styles.angleDownOpen}`]: open }, styles.angleDownIcon)}
        />
    );
};

const getCurrentSecurityInWatchlist = (securityIsin: string, watchLists?: Watchlist[]) => {
    if (!watchLists?.length) {
        return undefined;
    }

    for (const watchlist of watchLists) {
        const security = watchlist.securities?.find((security) => security.isin === securityIsin);

        if (security) {
            return security;
        }
    }

    return undefined;
};

export const SearchSecurityItem = memo(
    ({ item, isModalSearch, selectedWatchlistId, withFavouriteDropdown, handleClick }: IProps) => {
        const CountryFlag = countryFlags[item.exchangeCountry];
        const [dropDownOpen, setDropDownOpen] = React.useState<boolean>(false);

        const dispatch = useAppDispatch();

        const watchLists = useAppSelector((state) => [...state.investorProfile.watchlists].sort((a, b) => a.name.localeCompare(b.name)),
        );
        const { hasPermissionWatchlist } = usePermission();

        const currentSecurityInWatchlist = getCurrentSecurityInWatchlist(item.isin, watchLists);

        const securytiesWatchLists = watchLists.reduce((acc: number[], watchlist: Watchlist) => {
            const currentSecurityInWatchlist = watchlist.securities?.find(
                (security: Security) => security.isin === item.isin,
            );

            return currentSecurityInWatchlist ? [...acc, watchlist.id] : [...acc];
        }, []);

        const handleDropdownToggle = () => setDropDownOpen((prev) => !prev);

        const handleClickFavorite = (watchlistId?: number) => {
            if (!watchlistId) {
                return;
            }

            const currentWatchList = watchLists.find((list: Watchlist) => list.id === watchlistId);
            const hasSecurityInWatchList = !!currentWatchList?.securities?.find(
                (security: Security) => security.isin === item.isin,
            );

            if (hasSecurityInWatchList && currentSecurityInWatchlist) {
                dispatch(
                    deleteSecurityFromWatchlistThunk({ watchlistId, securityId: currentSecurityInWatchlist.id }),
                ).then(async () => await dispatch(getWatchlistsThunk()));

                return;
            }
            dispatch(
                addSecurityToWatchListThunk({
                    data: {
                        isin: item.isin,
                        type: item.securityType,
                        exchange: item.exchange,
                    },
                    watchlistId,
                }),
            ).then(async () => await dispatch(getWatchlistsThunk()));
        };

        const dropDownRef = useRef<HTMLDivElement>(null);
        useClickOutside(dropDownRef, () => setDropDownOpen(false));

        const handleClickFavouriteDropdown = (event: MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();

            if (!hasPermissionWatchlist) {
                return;
            }
            isModalSearch ? handleClickFavorite(selectedWatchlistId) : handleDropdownToggle();
        };

        return (
            <div
                className={classNames(styles.card)}
                onClick={() => {
                    setDropDownOpen(false);
                    handleClick?.();
                }}
            >
                <div className={classNames(styles.textSide)}>
                    <p className={classNames(styles.title)}>{item.shortName}</p>
                    <p className={classNames(styles.secText)}>{item.secId}</p>
                </div>
                <div className={classNames(styles.tools)} ref={dropDownRef}>
                    <div className={classNames(styles.flagBox)}>
                        <CountryFlag className={classNames(styles.flagBox_icon)} />
                        <p className={classNames(styles.flagBox_text)}>{item.exchange}</p>
                    </div>
                    <p className={classNames(styles.securityType)}>{securityTypes[item.securityType]}</p>
                    {withFavouriteDropdown && (
                        <HelpLineWrapper message="helpline.search.addToFavorites">
                            <div
                                className={classNames(
                                    'flex align-items-center justify-content-center',
                                    styles.favoriteBox,
                                    !hasPermissionWatchlist && styles.favoriteBox__disabled,
                                )}
                                onClick={handleClickFavouriteDropdown}
                            >
                                <FavoriteStar
                                    disabled={!hasPermissionWatchlist}
                                    active={
                                        selectedWatchlistId
                                            ? securytiesWatchLists.some(
                                                  (id: Watchlist['id']) => id === selectedWatchlistId,
                                              )
                                            : !!securytiesWatchLists.length
                                    }
                                />
                                {!isModalSearch && <AngleDown open={dropDownOpen} />}
                            </div>
                        </HelpLineWrapper>
                    )}
                    {withFavouriteDropdown && dropDownOpen && (
                        <div
                            className={classNames(styles.dropdown)}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                        >
                            {watchLists.length
? (
                                watchLists.map((list: Watchlist) => (
                                    <div className={classNames(styles.dropdown_item)} key={list.id}>
                                        <p className={classNames(styles.dropdown_itemText)}>{list.name}</p>
                                        <FavoriteStar
                                            active={securytiesWatchLists.includes(list.id)}
                                            onClick={() => handleClickFavorite(list.id)}
                                        />
                                    </div>
                                ))
                            )
: (
                                <div className={classNames(styles.dropdown_item)}>
                                    <p className={classNames(styles.dropdown_itemText)}>
                                        У вас пока нет ни одного списка избранных инструментов.
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    },
);
