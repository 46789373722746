import { Page, Text, View } from '@react-pdf/renderer';
import { type FC, useMemo } from 'react';

import { type NewReportItem, type ReportItemType } from '../../../entities/report';
import { tableOfContentsStyles } from './PdfStyles';
import { type LoadedStatus } from './ReportLayout';

type Props = {
    componentsDataLoadedStatus: Record<ReportItemType, LoadedStatus>;
    inReportItems: NewReportItem[];
    screens: Record<ReportItemType, string[]>;
    withPageTitle: boolean;
};

export const ReportTableOfContentsPage: FC<Props> = ({
    inReportItems,
    withPageTitle,
    screens,
    componentsDataLoadedStatus,
}) => {
    const reportItemsToShow = inReportItems.filter(({ id }) => componentsDataLoadedStatus[id] === 'success');

    const pageNumbers = useMemo(
        () => reportItemsToShow.reduce<number[]>((res, _, i, arr) => {
                if (!res.length) {
                    res.push(2 + Number(withPageTitle));
                } else {
                    res.push(res[i - 1] + screens[arr[i - 1]?.id]?.length);
                }

                return res;
            }, []),
        [reportItemsToShow, withPageTitle, screens],
    );

    return (
        <Page size="A4" style={tableOfContentsStyles.page}>
            <View>
                <Text style={tableOfContentsStyles.title}>{'Содержание'}</Text>
                <View style={tableOfContentsStyles.content}>
                    {reportItemsToShow.map((item, index) => {
                        const i = index;

                        return (
                            <View
                                key={`${item.id}_${i}`}
                                style={{
                                    ...tableOfContentsStyles.block,
                                    marginLeft: i % 2 !== 0 ? '20px' : '0',
                                }}
                            >
                                <Text style={tableOfContentsStyles.itemTitle}>{item.title}</Text>
                                <Text style={tableOfContentsStyles.pageNumber}>{pageNumbers[i]}</Text>
                            </View>
                        );
                    })}
                </View>
            </View>
        </Page>
    );
};
