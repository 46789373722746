import { type HeaderGroup } from '@tanstack/react-table';
import { Table } from '@mantine/core';

import { TableHeaderCell } from './TableHeaderCell';

type Props<T extends Record<string, unknown>> = {
    headerGroup: HeaderGroup<T>;
};

export const TableHeaderRow = <TableData extends Record<string, unknown>>({ headerGroup }: Props<TableData>) => (
    <Table.Tr style={{ borderBottom: '1px solid #636E80' }}>
        {headerGroup.headers.map((header) => (
            <TableHeaderCell key={header.id} header={header} />
        ))}
    </Table.Tr>
);
