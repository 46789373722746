import { useHelplineDefaultMessage } from '@libs/utils/hooks/useDefaultMessage';

import { BondHeader } from './components/Header';
import { Tabs } from './components/Tabs';
import { BreadCrumb } from '../../../shared/components/BreadCrumb';

export const BondPage = () => {
    useHelplineDefaultMessage('Подробная информация по облигации');

    return (
        <div>
            <BreadCrumb />
            <BondHeader />
            <Tabs />
        </div>
    );
};
