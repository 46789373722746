import { Chart } from 'primereact/chart';
import { Chart as ChartJS } from 'chart.js';
import { useEffect, useMemo, useRef, memo } from 'react';
import 'chart.js/auto';
import { getNumberWithSpacing, useChartTooltip } from '@libs/utils';
import cn from 'classnames';
import _ from 'lodash';
import { TooltipWrapper } from '@modules/Investorpro/shared/components/TooltipWrapper';
import { type CandleType } from '@modules/Investorpro/types/quote.type';
import { type PeriodId, type IntervalType } from '@libs/types/instrument.type';
import { formatInstrumentTradeDate } from '@modules/Investorpro/modules/MarketPages/shared/utils/date.utils';
import { getXAxisCallbackByPeriodId } from '@modules/Investorpro/shared/utils/chart.utils';

import { type BarParsedData, CandlestickController, CandlestickElement } from '../../utils/candle.class.utils';
import styles from './styles.module.scss';

type Props = {
    data: Array<CandleType & { x: string }>;
    width?: number;
    height?: number;
    intervalType?: IntervalType;
    currentPeriodOption?: string | null;
};

export const CandleChart = memo(({ data, width, height, intervalType, currentPeriodOption }: Props) => {
    const chartRef = useRef<Chart>(null);
    const [tooltipRef, dataPoints, setTooltipData, alignment, resetTooltipData] = useChartTooltip<CandleType>();

    const dataLabels: Partial<Record<keyof BarParsedData, string>> = {
        open: 'Открытие',
        high: 'Максимальная',
        low: 'Минимальная',
        close: 'Закрытие',
    };

    const chartData = useMemo(() => {
        return { datasets: [{ data }] };
    }, [data]);

    useEffect(() => {
        ChartJS.register(CandlestickController, CandlestickElement);
    }, []);

    const tooltipData = dataPoints?.[0]?.parsed;
    const isGreenTooltip = tooltipData?.open < tooltipData?.close;
    const isRedTooltip = tooltipData?.close < tooltipData?.open;

    const options = useMemo(() => {
        return {
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    intersect: false,
                    mode: 'nearest',
                    enabled: false,
                    external: setTooltipData,
                },
            },
            responsive: true,
            scales: {
                x: {
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0,
                        callback: getXAxisCallbackByPeriodId({
                            data,
                            ticksCount: 22,
                            disabledAutoSkip: true,
                            period: currentPeriodOption as PeriodId,
                        }),
                    },
                },
            },
        };
    }, [currentPeriodOption, data.length]);

    return (
        <div className={styles.chartWrapper} onMouseLeave={resetTooltipData}>
            <Chart
                type="candlestick"
                ref={chartRef}
                data={chartData}
                width={(width ?? 1371) + 'px'}
                height={(height ?? 681) + 'px'}
                options={options}
            />
            <TooltipWrapper
                ref={tooltipRef}
                alignment={alignment}
                visible={dataPoints?.length > 0}
                className={cn(isGreenTooltip && styles.green, isRedTooltip && styles.red)}
            >
                <div className={styles.container}>
                    <div className={styles.line}>
                        <h3>Дата открытия</h3>
                        <p>{formatInstrumentTradeDate(tooltipData?.begin, intervalType)}</p>
                    </div>
                    <div className={styles.line}>
                        <h3>Дата закрытия</h3>
                        <p>{formatInstrumentTradeDate(tooltipData?.end, intervalType)}</p>
                    </div>
                    <div className={styles.line}>
                        <h3>Объем</h3>
                        <p>{getNumberWithSpacing(_.round(tooltipData?.volume, 2))}</p>
                    </div>
                    {Object.keys(dataLabels).map((key) => (
                        <div key={key} className={styles.line}>
                            <h3>{dataLabels[key as keyof BarParsedData]}</h3>
                            <p>{getNumberWithSpacing(+tooltipData?.[key as keyof CandleType])}</p>
                        </div>
                    ))}
                </div>
            </TooltipWrapper>
        </div>
    );
});
