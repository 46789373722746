import { StompProvider } from '@libs/utils';
import { useHelplineDefaultMessage } from '@libs/utils/hooks/useDefaultMessage';

import { StockHeader } from './components/Header';
import { Tabs } from './components/Tabs';
import { BreadCrumb } from '../../../shared/components/BreadCrumb';

export const StockPage = () => {
    useHelplineDefaultMessage('Подробная информация по акции компании.');

    return (
        <div style={{ height: 'auto' }}>
            <StompProvider url={process.env.REACT_APP_WS_QUOTESERVICE_URL!} autoConnect>
                <BreadCrumb />
                <StockHeader />
                <Tabs />
            </StompProvider>
        </div>
    );
};
