import { Table } from '@modules/Investorpro/shared/components/Table';
import { useMemo } from 'react';
import { QuoteBuySell, type QuoteType } from '@modules/Investorpro/types/quote.type';

import styles from './styles.module.scss';
import { QUOTE_COLUMNS, type QuoteDataLine } from './columns';

type Props = {
    data: QuoteType[];
};

export const Quotes = ({ data }: Props) => {
    const tableData: QuoteDataLine[] = useMemo(() => {
        const buyData = data
            .filter((item) => item.buysell === QuoteBuySell.BUY)
            .sort((a, b) => b.price - a.price)
            .slice(0, 13);
        const sellData = data
            .filter((item) => item.buysell === QuoteBuySell.SELL)
            .sort((a, b) => a.price - b.price)
            .slice(0, 13);
        const maxBuyVolume = Math.max(...buyData.map((item) => item.quantity));
        const maxSellVolume = Math.max(...sellData.map((item) => item.quantity));
        const emptyData = { price: null, quantity: null };

        const maxCountData = buyData.length > sellData.length ? buyData : sellData;
        const lessCountData = buyData.length > sellData.length ? sellData : buyData;

        return maxCountData.map((item, i) => ({
            bid: maxCountData === buyData ? item : lessCountData[i] ?? emptyData,
            maxBuyVolume,
            maxSellVolume,
            ask: maxCountData === sellData ? item : lessCountData[i] ?? emptyData,
        }));
    }, [data]);

    return <Table data={tableData} className={styles.quotes} columns={QUOTE_COLUMNS} />;
};
