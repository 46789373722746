import { color as colorFn } from 'chart.js/helpers'
import { type SecurityIndex } from '@libs/types'

import { type SecurityDatasetColor } from '../BondsMarketPage/components/BondMarketChartBlock/types'
import { IntervalType } from '@libs/types/instrument.type'
import { BondIndexes } from '@modules/Investorpro/types/bond.type'


export enum SecurityBoards {
    SNDX = 'SNDX',
    TQBR = 'TQBR',
    TQOB = 'TQOB',
    CETS = 'CETS',
    rfud = 'RFUD',
    TQCB = 'TQCB',
}

export const COLORS = [
    '#F7A831',
    '#602FEB',
    '#02818A',
    '#003C96',
    '#ADCE31',
    '#a70202',
    '#5CB0FE',
    '#592851',
]

export const SECURITY_DATASET_COLORS: SecurityDatasetColor[] = [
    { table: '#51ABED', chart: '#2380EB' },
    { table: '#FF4143', chart: '#F2433D' },
    { table: '#549E6A', chart: '#288C14' },
]

export const AVAILABLE_FOR_SELECTION_COLORS: SecurityDatasetColor[] = [
    ...COLORS.map((color) => ({
        table: colorFn(color).alpha(0.8).rgbString(),
        chart: color,
    })),
]

export const STOCK_INDEXES: SecurityIndex[] = [
    { name: 'Московская Биржа', secId: 'IMOEX' },
    { name: 'РТС', secId: 'RTSI' },
    { name: 'Голубые фишки', secId: 'MOEXBC' },
];

export const BOND_INDEXES: SecurityIndex[] = [
    { name: 'Государственных', secId: BondIndexes.GOV },
    { name: 'Корпоративных', secId: BondIndexes.CORP, interval: IntervalType.ONE_DAY },
    { name: 'Муниципальных', secId: BondIndexes.MONI, interval: IntervalType.ONE_DAY },
]
