import { useEffect, useState } from 'react';
import { PeriodId, IntervalType } from '@libs/types/instrument.type';

import {
    getFiveDaysPeriod,
    getFiveYearsPeriod,
    getOneDayPeriod,
    getOneMonthPeriod,
    getOneYearPeriod,
    getSixMonthsPeriod,
    getStartOfMOEXDate,
    getStartYearDate,
    getThreeMonthsPeriod,
} from '../datePeriods.utils';

type DefaultOptionType = {
    id: PeriodId;
    interval: IntervalType;
    arrowPos: string[];
    label: {
        value: number | null;
        measure: string;
    };
    getStartDate: (endDate: Date) => Date;
};

const DEFAULT_PERIOD_OPTIONS: DefaultOptionType[] = [
    {
        id: PeriodId.ONE_DAY,
        interval: IntervalType.ONE_MINUTE,
        arrowPos: ['0', '500'],
        label: {
            value: 1,
            measure: 'Д',
        },
        getStartDate: getOneDayPeriod,
    },
    {
        id: PeriodId.FIVE_DAYS,
        arrowPos: ['0', '500', '1000', '1500', '2000', '2500'],
        interval: IntervalType.ONE_MINUTE,
        label: {
            value: 5,
            measure: 'Д',
        },
        getStartDate: getFiveDaysPeriod,
    },
    {
        id: PeriodId.ONE_MONTH,
        interval: IntervalType.ONE_HOUR,
        arrowPos: ['0'],
        label: {
            value: 1,
            measure: 'М',
        },
        getStartDate: getOneMonthPeriod,
    },
    {
        id: PeriodId.THREE_MONTHS,
        interval: IntervalType.ONE_HOUR,
        arrowPos: ['0'],
        label: {
            value: 3,
            measure: 'М',
        },
        getStartDate: getThreeMonthsPeriod,
    },
    {
        id: PeriodId.SIX_MONTHS,
        arrowPos: ['0'],
        interval: IntervalType.ONE_HOUR,
        label: {
            value: 6,
            measure: 'М',
        },
        getStartDate: getSixMonthsPeriod,
    },
    {
        id: PeriodId.SNG,
        arrowPos: ['0'],
        interval: IntervalType.ONE_HOUR,
        label: {
            value: null,
            measure: 'СНГ',
        },
        getStartDate: getStartYearDate,
    },
    {
        id: PeriodId.ONE_YEAR,
        arrowPos: ['0'],
        interval: IntervalType.ONE_HOUR,
        label: {
            value: 1,
            measure: 'Г',
        },
        getStartDate: getOneYearPeriod,
    },
    {
        id: PeriodId.FIVE_YEARS,
        arrowPos: ['0'],
        interval: IntervalType.ONE_DAY,
        label: {
            value: 5,
            measure: 'Л',
        },
        getStartDate: getFiveYearsPeriod,
    },
];

export type Period = {
    from: Date;
    interval: IntervalType;
    to: Date;
    arrowPos: string[];
};

type Option = {
    id: PeriodId;
    interval?: IntervalType;
    arrowPos?: string[];
    getStartDate: (endDate: Date) => Date;
};

type OptionId = (typeof DEFAULT_PERIOD_OPTIONS)[number]['id'];

export type UseChoosePeriodArgs<T> = {
    defaultValue?: OptionId;
    extraOptions?: T[];
    options?: T[];
    arrayPoses?: string[][] | Partial<Record<PeriodId, string[]>> | ((id: PeriodId) => string[]);
    intervalTypes?: IntervalType[] | Partial<Record<PeriodId, IntervalType>> | ((id: PeriodId) => IntervalType);
    handleChangePeriod?: (period: Period) => void;
    handleOptionChange?: (option: string | null) => void;
    withAll?: boolean;
    endDate?: Date;
};

export const useChoosePeriod = <T extends Option = DefaultOptionType>({
    defaultValue,
    withAll,
    extraOptions,
    arrayPoses,
    intervalTypes,
    options,
    handleChangePeriod: setExtraPeriod,
    handleOptionChange: setExtraOption,
    endDate,
}: UseChoosePeriodArgs<T>) => {
    const periodOptions = options ?? [...DEFAULT_PERIOD_OPTIONS, ...(extraOptions ?? [])];
    const defaultOption = defaultValue
        ? periodOptions.find(({ id }) => id === defaultValue) ?? periodOptions[6]
        : periodOptions.at(7) ?? periodOptions[0];

    const [currentOption, setCurrentOption] = useState<OptionId | null>(defaultOption.id);

    const periodSelectionOptions = withAll
        ? [
              ...periodOptions,
              {
                  id: PeriodId.ALL,
                  label: {
                      value: null,
                      measure: 'Все',
                  },
                  interval: IntervalType.ONE_DAY,
                  getStartDate: getStartOfMOEXDate,
              },
          ]
        : periodOptions;

    const getInitEndDate = () => {
        if (endDate !== undefined) {
            return endDate;
        }

        return new Date();
    };

    const getStartDate = (option: Option, endDate: Date) => {
        const startDate = option?.getStartDate(endDate) ?? endDate;

        return startDate;
    };

    const getArrowPosForOption = (option: Option) => {
        const defaultArrowPos = option.arrowPos ?? ['0'];

        if (!arrayPoses) {
            return defaultArrowPos;
        }

        if (typeof arrayPoses === 'function') {
            return arrayPoses(option.id) ?? defaultArrowPos;
        }

        if (Array.isArray(arrayPoses)) {
            const index = periodOptions.findIndex(({ id }) => id === option.id);

            return arrayPoses[index] ?? ['0'];
        }

        return arrayPoses[option.id] ?? ['0'] ?? defaultArrowPos;
    };

    const getIntervalTypeForOption = (option: Option) => {
        const defaultInterval = option.interval ?? IntervalType.ONE_MINUTE;

        if (!intervalTypes) {
            return defaultInterval;
        }

        if (typeof intervalTypes === 'function') {
            return intervalTypes(option.id) ?? defaultInterval;
        }

        if (Array.isArray(intervalTypes)) {
            const index = periodOptions.findIndex(({ id }) => id === option.id);

            return intervalTypes[index] ?? defaultInterval;
        }

        return intervalTypes[option.id] ?? defaultInterval;
    };

    const getInitPeriod = () => {
        const endDate = getInitEndDate();

        return {
            from: getStartDate(defaultOption, endDate),
            interval: defaultOption.interval ?? 1,
            to: endDate,
            arrowPos: defaultOption.arrowPos ?? ['0'],
        };
    };

    const [period, setPeriod] = useState<Period>(getInitPeriod());

    const handleChangePeriod = ({ from, to, interval, arrowPos }: Period) => {
        const changePeriodHandler = setExtraPeriod ?? setPeriod;
        changePeriodHandler({ from, to, interval, arrowPos });
    };

    useEffect(() => {
        if (defaultValue) handleOptionChange(defaultValue);
    }, [defaultValue]);

    const handleOptionChange = <T extends PeriodId | string | null>(optionId: T) => {
        const changeOptionHandler = setExtraOption ?? setCurrentOption;

        const option = periodSelectionOptions.find(({ id }) => optionId === id);
        const changePeriodHandler = setExtraPeriod ?? setPeriod;

        if (option) {
            changeOptionHandler(optionId as PeriodId);

            const to = getInitEndDate();

            changePeriodHandler({
                from: getStartDate(option as DefaultOptionType, to),
                interval: getIntervalTypeForOption(option as DefaultOptionType),
                arrowPos: getArrowPosForOption(option as DefaultOptionType),
                to,
            });
        }
    };

    const currentIntervalOption = periodSelectionOptions.find(({ id }) => currentOption === id);

    const currentGetStartDate = currentIntervalOption?.getStartDate ?? ((date: Date) => date);

    return {
        options: periodSelectionOptions as T[],
        currentOption,
        period,
        getStartDateByEndDate: currentGetStartDate,
        handleChangePeriod,
        handleOptionChange,
        currentIntervalOption,
    };
};
