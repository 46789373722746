import { transformDateFromMoscowToLocal } from '@modules/Investorpro/modules/MarketPages/shared/utils/date.utils';
import { NONE_SYMBOL } from '@modules/Investorpro/shared/constants';
import { addMinutes, isToday, format } from 'date-fns';
import { ru } from 'date-fns/locale';

export const formatUpdateDate = (date: string | null) => {
    if (!date) {
        return NONE_SYMBOL;
    }

    try {
        let targetDate = new Date(date);
        const now = new Date();
        const nowInMoscow = addMinutes(now, now.getTimezoneOffset() + 180);
        const deadline = new Date();
        deadline.setHours(18, 39, 59, 59);

        if (!date.includes(':')) {
            if (isToday(targetDate) && nowInMoscow <= deadline) {
                targetDate = now;
            } else {
                targetDate.setHours(18, 39, 59, 59);
                targetDate = transformDateFromMoscowToLocal(targetDate);
            }
        }

        return format(targetDate, 'd MMMM yyyy HH:mm', { locale: ru });
    } catch (e) {
        return NONE_SYMBOL;
    }
};
