import { getTradeDayResultsData } from '@libs/services/instrument.service';
import { formatDateToQuery } from '@libs/utils';
import { SecurityBoards } from '@modules/Investorpro/modules/MarketPages/shared/constants';
import { refetchDataUntilFindNotEmpty } from '@modules/Investorpro/modules/MarketPages/shared/utils/fetch.utils';


export const getStocksDataFacade = async (date: Date) => {
    const [stocksData] = await refetchDataUntilFindNotEmpty({
        fetchData: async (date) => await getTradeDayResultsData(date),
        start: date,
        step: 1,
        maxTries: 10,
        format: formatDateToQuery,
        responseDataAccessor: (resp) => resp.data.shares_bidding,
    });

    const repeatedStocks: Record<string, number> = {};

    return stocksData
        .filter(({ boardId }) => boardId === SecurityBoards.TQBR)
        .filter(({ secId }) => {
            repeatedStocks[secId] = (repeatedStocks[secId] || 0) + 1;

            return repeatedStocks[secId] === 1;
        })
};
