import { Checkbox, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

type Props = {
    settings: string[];
    onSettingsChange: (settings: string[]) => void;
};

export const ReportFormattingSettings: FC<Props> = ({ settings, onSettingsChange }) => (
    <Stack gap={14}>
        <Text
ff="Inter" fz={19} fw={400}
lh="22px">
            {'Оформление отчета'}
        </Text>
        <Checkbox.Group value={settings} onChange={onSettingsChange}>
            <Stack
                gap={0}
                p={22}
                style={{
                    border: '1px solid #EFE7E7',
                    borderRadius: '6px',
                }}
            >
                <Checkbox size="md" value="withPageTitle" label="Титульный лист" />
                <Checkbox size="md" label="Концевой титульный лист" value="withFinalPage" />
                <Checkbox size="md" label="Оглавление" value="withTableOfContents" />
                <Checkbox size="md" label="Нумерация страниц" value="withPageNumbers" />
            </Stack>
        </Checkbox.Group>
    </Stack>
);
