import { Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import { type InstrumentLiquidity } from '../../../../entities/portfolio';
import { GradientScale } from '../../../../shared/GradientScale';
import { Chart } from '../../Liquidity/Chart/Chart';
import { InstrumentsLiquidityTable } from '../../Liquidity/InstrumentsLiquidityTable';
import { InfoIcon } from '../ReportIcons/InfoIcon';

type Props = {
    portfolioLiquidity: number;
    instrumentsLiquidity: InstrumentLiquidity[];
};

export const ReportLiquidity: FC<Props> = ({ portfolioLiquidity, instrumentsLiquidity }) => (
    <Stack gap={33} w={1350} p={5}>
        <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
            <Group align="center" justify="space-between">
                <Text
fz="45px" fw={400} lh="47px"
style={{ whiteSpace: 'pre-wrap' }}>
                    {'Ликвидность'}
                </Text>
                <Group
                    gap={12}
                    miw={180}
                    mih={24}
                    px={16}
                    align="center"
                    py={8}
                    style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                >
                    <InfoIcon />
                    <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                        {'Информация'}
                    </Text>
                </Group>
            </Group>
            <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px" style={{ whiteSpace: 'pre-wrap' }}>
                {
                    'Раздел Ликвидность позволяет увидеть, сколько денег вы потеряете, если продадите \
все инструменты из портфеля в моменте.\n\nДополнительно можно оценить ликвидность каждого отдельного \
инструмента. Это поможет решить, как правильно подойти к вопросу закрытия позиций при срочной \
необходимости или заранее сократить количество неликвидных инструментов в портфеле.'
                }
            </Text>
        </Stack>
        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400} lh="38px">
                {'Ликвидность портфеля'}
            </Text>
            <GradientScale
                rightValue={0}
                leftValue={1}
                value={portfolioLiquidity ?? 0}
                labels={['Ликвидный', 'Неликвидный']}
            />
        </Stack>
        <Stack
w="100%" h="500px" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400} lh="38px">
                {'Карта ликвидности портфеля'}
            </Text>
            <Chart data={instrumentsLiquidity ?? []} isInReport={true} />
        </Stack>
        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400} lh="38px">
                {'Ликвидность инструментов в портфеле'}
            </Text>
            <InstrumentsLiquidityTable data={instrumentsLiquidity ?? []} isInReport={true} />
        </Stack>
    </Stack>
);
