import { useMemo } from 'react';
import { type PortfolioPerformance } from '@modules/Investorpro/modules/PortfolioPage/entities';

export const useFindMinDeltaIndex = (data: PortfolioPerformance[]) => useMemo(
        () => data.reduce(
                (minIndices, cur, i, arr) => {
                    if (i < 2) return minIndices;

                    const currentExpectedProfitDelta = Math.abs(cur.expectedProfit - arr[0].expectedProfit);
                    const currentValueDelta = Math.abs(cur.value - arr[0].value);
                    const currentMaxRiskDelta = Math.abs(cur.maxRisk - arr[0].maxRisk);

                    const minExpectedProfitDelta = Math.abs(
                        arr[minIndices.expectedProfit].expectedProfit - arr[0].expectedProfit,
                    );
                    const minValueDelta = Math.abs(arr[minIndices.value].value - arr[0].value);
                    const minMaxRiskDelta = Math.abs(arr[minIndices.maxRisk].maxRisk - arr[0].maxRisk);

                    return {
                        expectedProfit:
                            currentExpectedProfitDelta < minExpectedProfitDelta ? i : minIndices.expectedProfit,
                        value: currentValueDelta < minValueDelta ? i : minIndices.value,
                        maxRisk: currentMaxRiskDelta < minMaxRiskDelta ? i : minIndices.maxRisk,
                    };
                },
                {
                    expectedProfit: 1,
                    value: 1,
                    maxRisk: 1,
                },
            ),
        [data],
    );
