import {
    getArrowPosByDatePeriod,
    refetchDataUntilFindNotEmpty,
} from '@modules/Investorpro/modules/MarketPages/shared/utils/fetch.utils';
import {
    CandleDataColumnType,
    type GetCandlesRequestBody,
} from '@modules/Investorpro/types/quote.type';
import { getCandlesData } from '@shared/services/quote.service';
import { subDays } from 'date-fns';
import { IntervalType } from '@libs/types/instrument.type';
import { getPrevTradeDayDate } from '@modules/Investorpro/modules/MarketPages/shared/utils/date.utils';
import { formatDateToQuery } from '@libs/utils';


type GetPrevTradeDatePriceArgs = Pick<GetCandlesRequestBody, 'secId' | 'board' | 'market' | 'engine'> & {
    from?: Date;
    till?: Date;
    interval?: IntervalType;
}

export const getPrevTradeDayPrice = async (
    { from, till, interval, ...body }: GetPrevTradeDatePriceArgs,
): Promise<[number, Date]> => {
    const endDate = till ?? getPrevTradeDayDate();

    const [prices] = await refetchDataUntilFindNotEmpty({
        data: [],
        fetchData: async (start: string, _, i) => await getCandlesData({
            ...body,
            from: start,
            till: formatDateToQuery(endDate),
            columns: [CandleDataColumnType.CLOSE, CandleDataColumnType.END],
            interval: interval ?? IntervalType.ONE_DAY,
            arrayPos: getArrowPosByDatePeriod(interval ?? IntervalType.ONE_DAY, start, endDate),
        }),
        start: from ?? subDays(endDate, 3),
        step: [14, 30, 60, 180],
        maxTries: 3,
        end: endDate,
        responseDataAccessor: (resp) => resp.data.candles.data,
    });

    if (prices.length === 0) {
        return [0, new Date()]
    }

    const lastTrade = prices[prices.length - 1][0]

    return [lastTrade[0] as number, new Date(lastTrade[1] as string)];
};
