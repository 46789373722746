import { createAsyncThunk } from "@reduxjs/toolkit";
import { type FeedbackReq } from "@libs/components/pageBlocks/Feedback/services/feedback.type";
import { sendFeedback } from "@libs/components/pageBlocks/Feedback/services/feedback.service";

export const sendFeedbackThunk = createAsyncThunk('feedback/sendFeedback', async (body: FeedbackReq, {rejectWithValue }) => {
  try {
    const { data } = await sendFeedback(body);

    return data.data;
  } catch (e) {
    console.error('e', e);

    return rejectWithValue(e);
  }
});
