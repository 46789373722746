import { Badge, Box, Divider, Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import styles from './GradientScale.module.css';

type Props = {
    labels: string[];
    leftValue: -1 | 0 | 1 | 100 | -100;
    rightValue: -1 | 0 | 1 | 100 | -100;
    value: number;
    withPercent?: boolean;
};

export const GradientScale: FC<Props> = ({ labels, leftValue, rightValue, value, withPercent = false }) => {
    const position = Math.min(Math.max(((value - leftValue) / (rightValue - leftValue)) * 100, 0), 100);
    const dividers = Array(labels.length + 1).fill({ h: 10 });

    return (
        <Stack gap={0} pb={60}>
            <Group justify="space-between">
                {labels.map((text) => (
                    <Text
className={styles.text} key={text} fz={14}
fw={400} c="#8A96A6">
                        {text}
                    </Text>
                ))}
            </Group>

            <Box h={10} mt={5} className={styles.scale}>
                <Group justify="space-between">
                    {dividers.map((_, index) => {
                        const i = index;

                        return (<Divider
className={styles.divider} key={i} orientation="vertical"
w={2} h={10} />);
                    })}
                </Group>
                <Box
                    style={{
                        position: 'absolute',
                        left: `${position}%`,
                        top: '40px',
                        width: 0,
                        height: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        className={styles.tooltip}
                        style={{
                            color: value >= 0 ? '#14171F' : '#F2433D',
                        }}
                    >
                        <Box
                            style={{
                                width: '8px',
                                height: '8px',
                                transform: 'rotate(45deg)',
                                backgroundColor: '#FFF',
                                position: 'absolute',
                                borderLeft: '1px solid #D8E1EB',
                                borderTop: '1px solid #D8E1EB',
                                top: '-5px',
                                right: 'calc(50% - 4px)',
                            }}
                        />
                        {`${value.toLocaleString('ru')}${withPercent ? '%' : ''}`}
                    </Box>
                </Box>
                <Badge
                    circle={true}
                    w={12}
                    h={12}
                    radius={50}
                    color="#FFF"
                    style={{
                        position: 'absolute',
                        left: `${position}%`,
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderColor: '#C1E448',
                        border: '1px solid #C1E448',
                        boxShadow: 'none',
                    }}
                />
            </Box>
        </Stack>
    );
};
