import { type Column } from '../components/Table';

export const transformColumnsFromFixedWidthToPercentage = <T extends object>(columns: Array<Column<T>>) => {
    const checkIsPxWidth = (width: number | string | undefined): width is string => {
        return !!width && ((typeof width === 'string' && width.includes('px')));
    };

    const totalWidth = columns.reduce((acc: number, column: Column<T>) => {
        if (checkIsPxWidth(column.width)) {
            const [width] = column.width.split('px');

            return acc + Number(width);

        }

        if (column.width && typeof column.width === 'number') {
            return acc + column.width;
        }

        return acc;
    }, 0);

    return columns.map((column: Column<T>) => {
        if (checkIsPxWidth(column.width)) {
            const [width] = column.width.split('px');

            return {
                ...column,
                width: Number(width) / totalWidth * 100 + '%',
            };
        }

        if (column.width && typeof column.width === 'number') {
            return {
                ...column,
                width: column.width / totalWidth * 100 + '%',
            };
        }

        return column;
    })
};

export const changeColumnsWidth = <T extends { width?: string | number | undefined }>(
    columns: T[],
    columnsWidths: Array<string | number>,
): T[] => {
    return columns.map((col, index) => ({ ...col, width: columnsWidths[index] ?? col.width }));
}
