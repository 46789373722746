import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { SearchIcon } from 'primereact/icons/search';
import { type HTMLAttributes, type ChangeEvent } from 'react';

import styles from './styles.module.scss';

const ClearIcon = ({ onClick }: { onClick: () => void }) => {
    return (
        <button className={classNames(styles.clearButton)} onClick={onClick}>
            <span className={classNames('pi pi-times', styles.searchIcon)} />
        </button>
    );
};

type Props = {
    handleChange: (value: string) => void;
    placeholder?: string;
    isModalSearch?: boolean;
    value: string | null;
    disabled?: boolean;
    className?: string;
    handleClear?: () => void;
} & HTMLAttributes<HTMLInputElement>;

export const SearchInput = ({
    handleChange,
    placeholder = 'Поиск',
    isModalSearch = false,
    value,
    disabled,
    className,
    handleClear,
    ...props
}: Props) => {
    const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value);

    return (
        <div
            className={classNames(
                'flex align-items-center justify-content-between',
                styles.searchWrapper,
                'p-input-icon-left',
                isModalSearch && styles.isModal,
                className,
            )}
        >
            <SearchIcon />
            {value && handleClear && <ClearIcon onClick={handleClear} />}

            <InputText
                {...props}
                disabled={disabled}
                onChange={handleSearchValueChange}
                placeholder={placeholder}
                className={styles.searchInput}
                value={value ?? ''}
            />
        </div>
    );
};
