import classNames from 'classnames';

import style from './style.module.scss';

type Props = {
    isLoading: boolean;
    withOverlay?: boolean;
    className?: string;
};

export const Loader = ({ isLoading, withOverlay = false, className }: Props) => {
    if (!isLoading) {
        return null;
    }

    const loader = <div className={classNames(style.loader, className)} />;
    const wrapped = (
        <div className={classNames(style.loadingWrapper, 'flex', 'align-items-center', 'justify-content-center')}>
            {loader}
        </div>
    );

    return withOverlay ? wrapped : loader;
};
