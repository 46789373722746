import { type AssetsResponseData } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getAssetsBehavior: ApiFactory<
    ApiEndpoint<AssetsResponseData, void, { portfolioId: string; scenarioUuid: string }>
> = (client) => async ({ signal, params }) => {
    const url = '/moex-datashop-investor-pro-portfolios-service' +
        `/api/v1/stress-test/${params.portfolioId}/assets-behavior/${params.scenarioUuid}`;

    const { data } = await client.get<AssetsResponseData>(url, { signal });

    return data;
};
