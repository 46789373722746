import { createSlice } from '@reduxjs/toolkit';
import { checkPermissionsThunk } from '@store/store/thunk/investorpro/permissions.thunk';

export type IState = {
    hasPermissionWatchlist: boolean;
    hasPermissionAlert: boolean;
    hasPermissionPortfolio: boolean;
    hasPermissionAnalytic: boolean;
    hasPermissionScreener: boolean;
    hasPermissionSearch: boolean;
    isPermissionsInitialized: boolean;
    isLoading: boolean;
};

export const initialState: IState = {
    hasPermissionWatchlist: false,
    hasPermissionAlert: false,
    hasPermissionPortfolio: true,
    hasPermissionAnalytic: false,
    isPermissionsInitialized: false,
    hasPermissionSearch: false,
    hasPermissionScreener: false,
    isLoading: false,
};

export const permissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(checkPermissionsThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                // TODO: remove this when all MOEX give us permissions
                // state.hasPermissionWatchlist = action.payload.permissions.includes('watchlists');
                // state.hasPermissionAlert = action.payload.permissions.includes('alerts');
                // state.hasPermissionAnalytic = action.payload.permissions.includes('analytics');
                // state.hasPermissionPortfolio = action.payload.permissions.includes('portfolios');
                state.isPermissionsInitialized = true;
            })
            .addCase(checkPermissionsThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(checkPermissionsThunk.rejected, (state) => {
                state.isLoading = false;
                state.hasPermissionWatchlist = false;
                state.hasPermissionAlert = false;
                state.hasPermissionPortfolio = false;
                state.hasPermissionAnalytic = false;
                state.isPermissionsInitialized = true;
            });
    },
});

export const permissionsReducer = permissionsSlice.reducer;
