import { useStompClient } from '@libs/utils';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

import styles from './styles.module.scss';
import { TableBlock } from './components/TableBlock';
import { Candle } from './components/Candle';

export const ChartTab = () => {
    const { client, isConnected } = useStompClient();
    const { ticker } = useParams();

    return (
        <div className={cn(styles.chartTabContainer)}>
            <TableBlock ticker={ticker} client={client} isConnected={isConnected} />
            <Candle ticker={ticker} client={client} isConnected={isConnected} />
        </div>
    );
};
