import { useContext, useEffect } from 'react';

import { StompContext } from '../contexts/stomp.context';

export const useStompClient = (connect?: boolean) => {
    const { client, ...data } = useContext(StompContext);

    if (client === null) {
        throw new Error('useStomp must be used within a StompProvider');
    }

    useEffect(() => {
        if (connect) {
            client.activate();
        }
    }, [connect, client]);

    return {
        ...data,
        client,
    };
}
