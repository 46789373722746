import { createEndpoint, createEndpointMap } from '../../../core/endpoint';
import { getBonds } from './getBonds';
import { getFunds } from './getFunds';
import { getStocks } from './getStocks';
import { getTopFiveDividends } from './getTopFiveDividends';
import { getTopFiveReturnOnValue } from './getTopFiveReturnOnValue';
import { getTopFiveTotalReturn } from './getTopFiveTotalReturn';

export const createTypologyAssetsEndpoints = createEndpointMap((client) => ({
    getBonds: createEndpoint(getBonds, client),
    getFunds: createEndpoint(getFunds, client),
    getStocks: createEndpoint(getStocks, client),
    getTopFiveReturnOnValue: createEndpoint(getTopFiveReturnOnValue, client),
    getTopFiveDividends: createEndpoint(getTopFiveDividends, client),
    getTopFiveTotalReturn: createEndpoint(getTopFiveTotalReturn, client),
}));
