import { createEndpointMap } from '../core/endpoint';
import { createBenchmarkEndpoints } from './benchmark';
import { createPortfolioEndpoints } from './portfolio';
import { createTickerEndpoints } from './ticker';

export const createEndpoints = createEndpointMap((client) => ({
    portfolio: createPortfolioEndpoints(client),
    benchmark: createBenchmarkEndpoints(client),
    ticker: createTickerEndpoints(client),
}));

export type ApiEndpoints = ReturnType<typeof createEndpoints>;
