import { type SecurityIndex } from '@libs/types';

import { BOND_INDEXES, SECURITY_DATASET_COLORS } from '../../../shared/constants';

export const BONDS_INDEXES = [
    {
        ...BOND_INDEXES[0],
        color: SECURITY_DATASET_COLORS[0],
    },
    {
        ...BOND_INDEXES[1],
        color: SECURITY_DATASET_COLORS[1],
    },
    {
        ...BOND_INDEXES[2],
        color: SECURITY_DATASET_COLORS[2],
    },
] as SecurityIndex[];
