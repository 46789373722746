import { createEndpoint, createEndpointMap } from '../../../core/endpoint';
import { getCoreMetrics } from './getCoreMetrics';
import { getDiversification } from './getDiversification';
import { getExpectations } from './getExpectations';
import { getInvestmentProfile } from './getInvestmentProfile';
import { getKeyStatisticData } from './getKeyStatisticData';
import { getMaxRisk } from './getMaxRisk';
import { getPortfolioCost } from './getPortfolioCost';
import { getPortfolioInstruments } from './getPortfolioInstruments';

export const createGeneralInfoEndpoints = createEndpointMap((client) => ({
    getKeyStatistic: createEndpoint(getKeyStatisticData, client),
    getPortfolioInstruments: createEndpoint(getPortfolioInstruments, client),
    getDiversification: createEndpoint(getDiversification, client),
    getInvestmentProfile: createEndpoint(getInvestmentProfile, client),
    getExpectations: createEndpoint(getExpectations, client),
    getMaxRisk: createEndpoint(getMaxRisk, client),
    getPortfolioCost: createEndpoint(getPortfolioCost, client),
    getCoreMetrics: createEndpoint(getCoreMetrics, client),
}));
