import classNames from 'classnames';
import { ChevronLeftIcon } from 'primereact/icons/chevronleft';
import { forwardRef, useEffect, useState } from 'react';
import { getNotifications } from '@libs/services/notification.service';
import { useLocation } from 'react-router-dom';
import { getInvestorProLoginParams, useAuthorize } from '@libs/utils/hooks/useAuthorize';

import styles from './styles.module.scss';

export const Header = forwardRef<HTMLDivElement, unknown>((_, ref) => {
    const [notificationCount, setNotificationCount] = useState<number>(0);
    const location = useLocation();
    const { isAuthorized, loginHandler } = useAuthorize();

    useEffect(() => {
        if (isAuthorized) {
            const getNotificateCount = async () => {
                try {
                    const response = await getNotifications();
                    setNotificationCount(response.data.unread);
                } catch (error) {
                    loginHandler();
                    console.error('Error:', error);
                }
            };
            getNotificateCount();
        }
    }, [location, isAuthorized]);

    return (
        <div className={classNames(styles.headerWrapper, 'flex', 'justify-content-center')} ref={ref}>
            <div className={classNames('flex')}>
                <div className={classNames('flex align-items-center gap-3')}>
                    <div className={classNames(styles.link, 'flex align-items-center gap-3')}>
                        <ChevronLeftIcon className={styles.link_icon} color={'#88919A'} />
                        <a href={process.env.REACT_APP_URL_STOCK}>МОСКОВСКАЯ БИРЖА</a>
                    </div>

                    <div className={classNames(styles.link, styles.link__notificated, 'flex align-items-center gap-3')}>
                        <ChevronLeftIcon className={styles.link_icon} color={'#88919A'} />
                        <a href={`${process.env.REACT_APP_DS_URL}?${getInvestorProLoginParams(isAuthorized)}`}>
                            DATASHOP
                        </a>
                        {![0, null, undefined].includes(notificationCount) && (
                            <span
                                className={classNames(
                                    'flex align-items-center justify-content-center',
                                    styles.link_notificationCount,
                                )}
                            >
                                {notificationCount}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
