import { SecurityMarketType, type SecurityIndex } from '@libs/types'
import { InstrumentEngineType } from '@modules/Investorpro/types/quote.type'
import { IntervalType } from '@libs/types/instrument.type'

import { SecurityBoards, STOCK_INDEXES, BOND_INDEXES } from '../../../shared/constants'

export const SECTOR_INDEXES: SecurityIndex[] = [
    { name: 'Нефть и газ', secId: 'MOEXOG' },
    { name: 'Электроэнергетика', secId: 'MOEXEU' },
    { name: 'Телекоммуникации', secId: 'MOEXTL' },
    { name: 'Металлы и добыча', secId: 'MOEXMM' },
    { name: 'Финансы', secId: 'MOEXFN' },
    { name: 'Потребительский сектор', secId: 'MOEXCN' },
    { name: 'Химии и нефтехимии', secId: 'MOEXCH' },
    { name: 'Информационных технологий', secId: 'MOEXIT', interval: IntervalType.ONE_DAY },
    { name: 'Строительных компаний', secId: 'MOEXRE', interval: IntervalType.ONE_DAY },
    { name: 'Транспорта', secId: 'MOEXTN' },
]

const CURRENCY_INDEX = {
    engine: InstrumentEngineType.CURRENCY,
    board: SecurityBoards.CETS,
    market: SecurityMarketType.CURRENCY,
}

export const CURRENCY_INDEXES: SecurityIndex[] = [
    { name: 'Доллар', secId: 'USD000UTSTOM', ...CURRENCY_INDEX },
    { name: 'Евро', secId: 'EUR_RUB__TOM', ...CURRENCY_INDEX },
    { name: 'Юань', secId: 'CNYRUB_TOM', ...CURRENCY_INDEX },
]

export const GOODS_INDEXES: SecurityIndex[] = [
    {
        name: 'Нефть',
        secId: 'BRK4',
        board: SecurityBoards.CETS,
        engine: InstrumentEngineType.FUTURES,
        market: SecurityMarketType.GOODS,


    },
    {
        name: 'Золото',
        secId: 'GLDRUB_TOM',
        board: SecurityBoards.CETS,
        engine: InstrumentEngineType.CURRENCY,
        market: SecurityMarketType.CURRENCY,
    },
    {
        name: 'Серебро',
        secId: 'SLVRUB_TOM',
        board: SecurityBoards.CETS,
        engine: InstrumentEngineType.CURRENCY,
        market: SecurityMarketType.CURRENCY,
    },
    {
        name: 'Пшеница',
        secId: 'W4J4',
        market: SecurityMarketType.GOODS,
        engine: InstrumentEngineType.FUTURES,
        board: SecurityBoards.rfud,
    },
]

type IndexesBlock = {
    name: string;
    indexes: SecurityIndex[];
}

export const ALL_INDEXES: IndexesBlock[] = [
    { name: 'Индексы акций', indexes: STOCK_INDEXES },
    { name: 'Индексы отраслевые', indexes: SECTOR_INDEXES },
    { name: 'Индексы облигаций', indexes: BOND_INDEXES },
    { name: 'Валюты', indexes: CURRENCY_INDEXES },
    { name: 'Товары', indexes: GOODS_INDEXES },
]

export const COUNT_OF_SECURITIES_BLOCK = ALL_INDEXES.length
