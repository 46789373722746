import { useAppDispatch } from '@store/store';
import { useEffect } from 'react';

import { setHelplineDefaultMessage } from '../../../store/store/slices/investorpro/helpline.slice';

export const useHelplineDefaultMessage = (message: string) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setHelplineDefaultMessage(message));

        return () => {
            dispatch(setHelplineDefaultMessage(''));
        };
    }, [message, dispatch]);
};
