import { Group, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { type FC } from 'react';
import { type Scenario } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { StressProgress } from './StressProgress/StressProgressNegative';
import styles from './StressScenario.module.css';

type Props = {
    scenario: Scenario;
    onClick: (uuid: string, dateFrom: string, dateTill: string) => void;
    isSelected: boolean;
};

export const StressScenarioCard: FC<Props> = ({
    scenario: { uuid, name, description, dateFrom, dateTill, value },
    onClick,
    isSelected,
}) => (
    <Stack
        miw="99%"
        px={24}
        py={16}
        gap={8}
        className={isSelected ? styles.scenarioSelected : styles.scenario}
        onClick={() => onClick(uuid, dateFrom, dateTill)}
    >
        <Text fz={16} fw={400} c="#14171F">
            {name}
        </Text>
        <Text fz={14} fw={400} c="#18191B">
            {description}
        </Text>
        <Group>
            <Text fz={13} fw={400} c="#8A96A6">
                {'Таймфрейм:'}
            </Text>
            <Text
fz={13} fw={600} c="#14171F"
ff="Inter">
                {dateFrom ? dayjs(dateFrom).format('DD.MM.YYYY') : '-'}
                {'–'}
                {dateTill ? dayjs(dateTill).format('DD.MM.YYYY') : '-'}
            </Text>
        </Group>
        <Group justify="space-between" wrap="nowrap">
            <StressProgress value={value} />
            <Text
fz={13} fw={600} ff="Inter"
style={{ color: value >= 0 ? '#288C14' : '#E42821' }}>
                {value > 0 ? `+${value}` : value}
                {'%'}
            </Text>
        </Group>
    </Stack>
);
