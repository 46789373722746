import { Group, Stack, Text } from '@mantine/core';
import { readLocalStorageValue } from '@mantine/hooks';
import dayjs from 'dayjs';
import { type FC } from 'react';

import { PortfolioFilterKeys, type RiskMetricsType } from '../../../../entities/portfolio';
import { RiskChart } from '../../RiskMetrics/Chart/RiskChart';
import { DecreasesDurationTable } from '../../RiskMetrics/Tables/DecreasesDurationTable';
import { CalendarIcon } from '../ReportIcons/CalendarIcon';
import { InfoIcon } from '../ReportIcons/InfoIcon';

type Props = {
    data: RiskMetricsType;
};

export const ReportLosses: FC<Props> = ({ data }) => {
    const dates = readLocalStorageValue<[Date | null, Date | null]>({
        key: PortfolioFilterKeys.RISK_METRICS_FILTER,
        defaultValue: [dayjs().subtract(10, 'years').toDate(), dayjs().toDate()],
    });

    return (
        <Stack gap={33} w={1350} p={5}>
            <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
                <Group align="center" justify="space-between">
                    <Text
fz="45px" fw={400} lh="47px"
style={{ whiteSpace: 'pre-wrap' }}>
                        {'Просадки'}
                    </Text>
                    <Group
                        gap={12}
                        miw={180}
                        mih={24}
                        px={16}
                        align="center"
                        py={8}
                        style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                    >
                        <InfoIcon />
                        <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                            {'Информация'}
                        </Text>
                    </Group>
                </Group>
                <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px" style={{ whiteSpace: 'pre-wrap' }}>
                    {
                        'Раздел Просадки показывает историческую глубину снижения стоимости вашего портфеля \
в процентах. Самая нижняя точка показывает максимальный размер убытка, который был \
за исследуемый период.\n\nВы можете наглядно оценить, сколько денег терял портфель, \
как долго длилось снижение, и сколько времени заняло восстановление до максимальной \
стоимости. Это поможет вам понять, готовы ли вы терпеть потенциальные потери по портфелю \
в будущем, а если нет, то своевременно изменить его структуру и сократить риски.'
                    }
                </Text>
            </Stack>
            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Group align="center" justify="space-between">
                    <Text fz={36} fw={400} lh="38px">
                        {'Просадки портфеля от максимумов'}
                    </Text>
                    <Group gap={14} align="center">
                        <Text fz={20} fw={400} lh="22px">
                            {dayjs(dates[0]).format('DD.MM.YYYY')} {'–'} {dayjs(dates[1]).format('DD.MM.YYYY')}
                        </Text>
                        <CalendarIcon />
                    </Group>
                </Group>
                <Group h={390}>
                    <RiskChart data={data} isInReport={true} />
                </Group>
            </Stack>
            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'Длительность снижений и экстремумы'}
                </Text>
                <DecreasesDurationTable data={data?.durationsAndExtremes ?? []} />
            </Stack>
        </Stack>
    );
};
