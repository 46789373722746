import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';
import {
    type ResponseInfo, type UserProfileType, type UserProfileUpdateResponseType,
    type AvatarFileType, type EmailSendRequestType, type EmailSendResponseType
    } from '@libs/types';


export const USER_PROFILE_URL = '/moex-datashop-profileservice/api/userprofile/v1/';
export const ATTACH_URL = '/moex-datashop-attachmentservice/api/file-service/v1/';

const getUserProfileRequest = (): Promise<AxiosResponse<ResponseInfo<UserProfileType>>> => httpClient.get(USER_PROFILE_URL);

const updateUserProfileRequest = (
    requestData: UserProfileUpdateResponseType,
): Promise<AxiosResponse<ResponseInfo<UserProfileType>>> => httpClient.post(USER_PROFILE_URL, requestData);

const uploadTicket = () => httpClient.get(USER_PROFILE_URL + 'avatar/upload-ticket');

const removeAvatar = () => httpClient.delete(USER_PROFILE_URL + 'avatar/');

const uploadAvatarRequest = (
    requestData: {
        file: any;
    },
    ticket: number,
): Promise<AxiosResponse<ResponseInfo<{ bucket: string; ticketId: number }>>> =>
    httpClient.post(ATTACH_URL + `upload-by-ticket/${ticket}`, requestData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });

const downloadAvatarRequest = (bucket: string, ticket: number): Promise<AxiosResponse<string>> =>
    httpClient.get(ATTACH_URL + `download/${bucket}/${ticket}`, {
        responseType: 'arraybuffer',
    });

const updateEmailRequest = (
    requestData: EmailSendRequestType,
): Promise<AxiosResponse<ResponseInfo<EmailSendResponseType>>> => httpClient.post(USER_PROFILE_URL + 'email', requestData);

const confirmEmailRequest = (): Promise<AxiosResponse<ResponseInfo<EmailSendResponseType>>> =>
    httpClient.post(USER_PROFILE_URL + `email/confirm`);

const checkEmailRequest = (token: string): Promise<AxiosResponse<any>> =>
    httpClient.get(`${USER_PROFILE_URL}/email/check-code/${token}`);

export {
    updateEmailRequest,
    getUserProfileRequest,
    updateUserProfileRequest,
    confirmEmailRequest,
    checkEmailRequest,
    uploadAvatarRequest,
    downloadAvatarRequest,
    uploadTicket,
    removeAvatar,
};
