import { createContext, type FC, type ReactNode, useContext, useMemo } from 'react';

import type { ApiEndpoints } from '../endpoints';

const ApiContext = createContext<ApiEndpoints | null>(null);

export const useApi = (): ApiEndpoints => {
    const context = useContext(ApiContext);

    if (!context) {
        throw new Error('ApiContext: useApi should only be used inside an ApiProvider');
    }

    return context;
};

type Props = {
    endpoints: ApiEndpoints;
    children: ReactNode;
};

export const ApiProvider: FC<Props> = ({ endpoints, children }) => {
    const context = useContext(ApiContext);

    const value = useMemo(() => ({ ...(context ?? {}), ...endpoints }), [context, endpoints]);

    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};
