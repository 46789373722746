import { createEndpoint, createEndpointMap } from '../../core/endpoint';
import { createCashFlowEndpoints } from './cashFlow';
import { createPortfolio } from './createPortfolio';
import { deletePortfolio } from './deletePortfolio';
import { createDiversificationEndpoints } from './diversification';
import { createDynamicsEndpoints } from './dynamics';
import { createGeneralInfoEndpoints } from './generalInfo';
import { getPortfolioById } from './getPortfolioById';
import { getPortfolioList } from './getPortfolioList';
import { createHistoricReturnsEndpoints } from './historicReturns';
import { createLiquidityEndpoints } from './liquidity';
import { createPerformanceEndpoints } from './performance';
import { createReportEndpoints } from './report';
import { createRiskMetricsEndpoints } from './riskMetrics';
import { createStressTestsEndpoints } from './stressTests';
import { createTypologyAssetsEndpoints } from './typologyAssets';
import { updatePortfolio } from './updatePortfolio';

export const createPortfolioEndpoints = createEndpointMap((client) => ({
    cashFlow: createCashFlowEndpoints(client),
    createPortfolio: createEndpoint(createPortfolio, client),
    deletePortfolio: createEndpoint(deletePortfolio, client),
    diversification: createEndpoint(createDiversificationEndpoints, client),
    dynamics: createDynamicsEndpoints(client),
    generalInfo: createGeneralInfoEndpoints(client),
    getPortfolioById: createEndpoint(getPortfolioById, client),
    getPortfolioList: createEndpoint(getPortfolioList, client),
    historicReturns: createHistoricReturnsEndpoints(client),
    liquidity: createLiquidityEndpoints(client),
    performance: createPerformanceEndpoints(client),
    report: createEndpoint(createReportEndpoints, client),
    riskMetrics: createRiskMetricsEndpoints(client),
    stressTests: createStressTestsEndpoints(client),
    typologyAssets: createTypologyAssetsEndpoints(client),
    updatePortfolio: createEndpoint(updatePortfolio, client),
}));
