import { getNumberWithSpacing } from '@libs/utils';
import { type ValueType } from '@modules/Investorpro/types/common.type';

import { NONE_SYMBOL } from '../constants';
import { formatPrice, formatTextBoolean, formatDate } from './format.util';

type FormatValueByTypeArgs = {
    type: ValueType;
    value: unknown;
    measure?: string | number;
    emptyValue?: string;
    numberDivider?: string;
}

export const formatValueByType = ({
    type,
    value,
    emptyValue = NONE_SYMBOL,
    measure = '',
    numberDivider,
}: FormatValueByTypeArgs): string => {
    if (value === undefined || value === null) {
        return emptyValue;
    }

    if (type === 'string') {
        return value as string + measure;
    }

    if (type === 'price') {
        return formatPrice(Number(value), numberDivider) + measure;
    }

    if (type === 'date') {
        return formatDate(value as string)
    }

    if (type === 'number' || typeof value === 'number') {
        return getNumberWithSpacing(Number(value)) + measure;
    }

    if (type === 'boolean' || typeof value === 'boolean') {
        return formatTextBoolean(value as boolean)
    }

    return value as string + measure;
}
