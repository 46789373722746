import { httpClient } from '@configs/httpclient.config';
import {
    type GetBondsParamsRequestResponse,
    type GetBondsRequestResponse,
    type GetSharesIndexesRequestResponse,
    type GetSharesRequestResponse,
    type GetBondsAmortisationsReqBody,
    type GetBondsAmortisationsResponse,
    type GetBondsAuctionResponse,
    type GetBondsCouponsResponse,
    type GetBondsOffersResponse,
    type GetInstrumentNameResponse,
    type GetTopBondsByVolumeRequestResponse,
    type PageableReqBody,
    type ServerBondsParamsData,
    type ServerBondsOfferData,
    type ServerBondAuctionData,
    type ServerBondsAmortisationData,
    type ServerBondsCouponsData,
    type GetTradeDayResultsResponse,
    type GetKeyRateRequestResponse,
    type GetCurrencyRateRequestResponse,
    type GetIndexForInstrumentRequestBody,
    type GetIndexForInstrumentResponse,
    type GetBondsCouponsReqBody,
    type GetBondsOffersReqBody,
    type GetBondsAuctionReqBody,
    type GetCompanyLogoResponse,
    type GetInstrumentLiquidityRequestBody,
    type GetInstrumentLiquidityResponse,
    type GetInstrumentProfitRequestBody,
    type GetInstrumentProfitResponse,
} from '@libs/types/instrument.type';
import { formatDateToQuery } from '@libs/utils';
import qs from 'qs';

const INSTRUMENTS_URL = '/moex-datashop-instrument-service/api/v1/';
const CASH_FLOW_URL = INSTRUMENTS_URL + 'bonds/';

export const getShares = async (
    tradeDate: Date | string,
    boardIds: string[] = [],
    signal?: AbortSignal,
): Promise<GetSharesRequestResponse> => {
    const response = await httpClient.get<GetSharesRequestResponse['data']>(INSTRUMENTS_URL + 'shares/shares_data', {
        signal,
        params: {
            tradeDate: formatDateToQuery(tradeDate),
            group: 'stock_shares',
            boardIds,
        },
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    });

    return response;
};

export const getSharesIndexes = async (
    tradeDate: Date,
    index: string | number,
    signal?: AbortSignal,
): Promise<GetSharesIndexesRequestResponse> => {
    return await httpClient.get(INSTRUMENTS_URL + `shares/index/${index}`, {
        signal,
        params: {
            tradeDate: formatDateToQuery(tradeDate),
        },
    });

};

export const getIndexForInstrument = async (body: GetIndexForInstrumentRequestBody):
Promise<GetIndexForInstrumentResponse> => {
    return await httpClient.post(INSTRUMENTS_URL + 'instrument/secid', body);
}

export const getBonds = async (
    tradeDate: Date | string,
    securityType: string,
    boardIds: string[] = [],
    signal?: AbortSignal,
):
Promise<GetBondsRequestResponse> => {
    const response = await httpClient.get<GetBondsRequestResponse['data']>(INSTRUMENTS_URL + 'bonds/bonds_data', {
        signal,
        params: {
            tradeDate: formatDateToQuery(tradeDate),
            securityType,
            boardIds,
        },
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    });

    return response
};

export const getBondsParams = async (tradeDate: Date | string): Promise<GetBondsParamsRequestResponse> => {
    const response = await httpClient.post<ServerBondsParamsData>(INSTRUMENTS_URL + 'bonds/bonds_params', {
        trade_date: formatDateToQuery(tradeDate),
    });

    return response.data.bonds.map((bond) => ({
        isin: bond.isin,
        name: bond.name,
        issuersector: bond.issuersector,
        matDate: bond.mat_date,
        securityType: bond.security_type,
    }));
};

export const getCurrencyRate = async (tradeDate: Date | string): Promise<GetCurrencyRateRequestResponse> => {
    return await httpClient.get(INSTRUMENTS_URL + 'cbr/get_curs', {
        params: {
            tradeDate: formatDateToQuery(tradeDate),
        },
    })
};

export const getKeyRate = async (fromDate: Date, toDate: Date): Promise<GetKeyRateRequestResponse> => {
    return await httpClient.get(INSTRUMENTS_URL + 'cbr/key_rate', {
        params: {
            fromDate: formatDateToQuery(fromDate),
            toDate: formatDateToQuery(toDate),
        },
    });
};

export const getTradeDayResultsData = async (tradeDate: string): Promise<GetTradeDayResultsResponse> => {
    return await httpClient.get(INSTRUMENTS_URL + 'shares/shares_bidding', {
        params: {
            tradeDate,
        },
    });
};

export const getBondsOffersData = async (body?: GetBondsOffersReqBody): Promise<GetBondsOffersResponse> => {
    const response = await httpClient.post<ServerBondsOfferData>(CASH_FLOW_URL + 'offers', body ?? {});

    return response.data.map((offer) => ({
        idFintool: offer.id_fintool,
        offerDate: offer.offer_date,
        buyBackPrice: offer.buy_back_price,
        buyBackVol: offer.buy_back_vol,
    }));
};

export const getBondsCouponsData = async (body?: GetBondsCouponsReqBody): Promise<GetBondsCouponsResponse> => {
    const response = await httpClient.post<ServerBondsCouponsData>(CASH_FLOW_URL + 'coupons', body ?? {});

    return response.data.map((coupon) => ({
        idFintool: coupon.id_fintool,
        couponRate: coupon.coupon_rate,
        payPerBond: coupon.pay_per_bond,
        payDate: coupon.pay_date,
    }));
};

export const getBondsAuctionData = async (body?: GetBondsAuctionReqBody): Promise<GetBondsAuctionResponse> => {
    const response = await httpClient.post<ServerBondAuctionData>(CASH_FLOW_URL + 'auctiondata', body ?? {});

    return response.data.map((auction) => ({
        idFintool: auction.id_fintool,
        begdistDate: auction.begdist_date,
        askVal: auction.ask_val,
    }));
};

export const getInstrumentName = async (body?: PageableReqBody): Promise<GetInstrumentNameResponse> => {
    return await httpClient.post(`${CASH_FLOW_URL}instrument`, body ?? {})
};

export const getBondsAmortisationsData = async (
    body: GetBondsAmortisationsReqBody,
): Promise<GetBondsAmortisationsResponse> => {
    const response = await httpClient.post<ServerBondsAmortisationData>(CASH_FLOW_URL + 'amortizations', {
        pageNumber: 0,
        flagFilter: true,
        ...body,
    } as GetBondsAmortisationsReqBody);

    return response.data.map((amortisation) => ({
        idFintool: amortisation.id_fintool,
        mtyDate: amortisation.mty_date,
        payPerBond: amortisation.pay_per_bond,
        payPerMarket: amortisation.pay_per_market,
        mtyType: amortisation.mty_type,
    }));
};

export const getTopBondsByVolume = async (
    tradeDate: string,
): Promise<GetTopBondsByVolumeRequestResponse> => {
    return await httpClient.get(CASH_FLOW_URL + 'bonds_bidding', {
        params: {
            tradeDate,
        },
    });
};

export const getCompanyLogo = async (fininstIds: Array<string | number>): Promise<GetCompanyLogoResponse> => {
    return await httpClient.post(INSTRUMENTS_URL + 'info/emitentdocs', {
        fininstIds,
    });
};

export const getStocksLiquidity = async ({ signal, ...body }: GetInstrumentLiquidityRequestBody):
Promise<GetInstrumentLiquidityResponse> => {
    return await httpClient.post(INSTRUMENTS_URL + 'shares/liquidity', body, { signal });
}

export const getBondsLiquidity = async ({ signal, ...body }: GetInstrumentLiquidityRequestBody):
Promise<GetInstrumentLiquidityResponse> => {
    return await httpClient.post(INSTRUMENTS_URL + 'bonds/liquidity', body, { signal });
}

export const getStocksProfit = async ({ signal, ...body }: GetInstrumentProfitRequestBody):
Promise<GetInstrumentProfitResponse> => {
    const response = await httpClient.post<GetInstrumentProfitResponse>(
        INSTRUMENTS_URL + 'shares/profit', body, { signal },
    );

    return response.data;
}

export const getBondsProfit = async ({ signal, ...body }: GetInstrumentProfitRequestBody):
Promise<GetInstrumentProfitResponse> => {
    const response = await httpClient.post<GetInstrumentProfitResponse>(
        INSTRUMENTS_URL + 'bonds/profit', body, { signal },
    );

    return response.data;
}
