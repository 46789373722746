import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

type IState = {
    message: string;
    opened: boolean;
    defaultMessage: string;
};

export const initialState: IState = {
    message: '',
    opened: false,
    defaultMessage: '',
};

export const helplineSlice = createSlice({
    name: 'helpline',
    initialState,
    reducers: {
        setHelplineMessage: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
        },
        setHelplineOpened: (state, action: PayloadAction<boolean>) => {
            state.opened = action.payload;
        },
        setHelplineDefaultMessage: (state, action: PayloadAction<string>) => {
            state.defaultMessage = action.payload;
        },
    },
});

export const { setHelplineMessage, setHelplineOpened, setHelplineDefaultMessage } = helplineSlice.actions;

export const helpLineReducer = helplineSlice.reducer;
