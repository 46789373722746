import { useQuery } from '@tanstack/react-query';
import { type SortingState } from '@tanstack/react-table';
import { useEffect } from 'react';

import { useApi } from '../../../../api';

export const useGetPortfolioInstrumentsQuery = (
    portfolioId: string,
    sorting?: SortingState,
    enabled = true,
    onStatusChange?: (status: 'error' | 'success' | 'pending') => void,
) => {
    const api = useApi();

    const query = useQuery({
        queryKey: ['portfolio', portfolioId, 'general-info', 'portfolio-instruments', sorting],
        queryFn: async () => await api.portfolio.generalInfo.getPortfolioInstruments({
            params: {
                id: portfolioId,
                sort: sorting?.map((sortItem) => `${sortItem.id},${sortItem.desc ? 'desc' : 'asc'}`)[0] ?? 'asc',
            },
        }),
        enabled: Boolean(portfolioId) && enabled,
    });

    useEffect(() => {
        if (query.isLoading || query.isFetching) {
            onStatusChange?.('pending');
        }

        if (query.isError) {
            onStatusChange?.('error');
        }

        if (query.isSuccess && !query.isLoading && !query.isFetching) {
            onStatusChange?.('success');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isLoading, query.isError, query.isSuccess, query.isFetching, query.isPending]);

    return query;
};
