export type PortfolioDynamicsEvent = {
    uuid: string;
    description: string;
    date: string;
    type: string;
};

type PerformanceItem = {
    date: string;
    value: number;
};

export type PortfolioDynamicsPerformance = {
    name: Charts;
    performance: PerformanceItem[];
};

export type PortfolioDynamicsResponseData = {
    performances: PortfolioDynamicsPerformance[];
    events: PortfolioDynamicsEvent[];
};

export type PortfolioDynamicsRequestParams = {
    id: string;
    dateFrom?: Date;
    dateTill?: Date;
};

export type PortfolioDynamicsRequestData = {
    comparingInstruments: string[];
    events: string[];
};

export type PortfolioDynamicsChartData = {
    [key: string]: number | string | Partial<PortfolioDynamicsEvent> | undefined;
    date: string;
    events?: Partial<PortfolioDynamicsEvent>;
};

export type CheckmarkListResponse = {
    events: Record<EVENTS, string>;
    comparingInstruments: Record<COMPARING_INSTRUMENTS, string>;
};

enum COMPARING_INSTRUMENTS {
    MOEX = 'MOEX',
    MOEXBC = 'MOEXBC',
    RUABITR = 'RUABITR',
    BRENT = 'BRENT',
    GOLD = 'GOLD',
}

enum EVENTS {
    KEY_RATE = 'KEY_RATE',
    TRENDSETTING_EVENTS = 'TRENDSETTING_EVENTS',
    DIVIDENDS = 'DIVIDENDS',
    ISSUER_REPORTS = 'ISSUER_REPORTS',
}

export enum Charts {
    Портфель = 'Портфель',
    GLDRUB_TOM = 'GLDRUB_TOM',
    BRF4 = 'BRF4',
    RUABITR = 'RUABITR',
    MOEX = 'MOEX',
    MOEXBC = 'MOEXBC',
}
