/* eslint-disable react/prop-types */
import { Document } from '@react-pdf/renderer';
import { type FC, memo } from 'react';

import { type NewReportItem, type ReportItemType } from '../../../entities/report';
import { type ReportFormFinalValues, type ReportFormTitleValues } from './ReportForm.schema';
import { PdfPage } from './PdfPage';
import { ReportFinalPage } from './ReportFinalPage';
import { type LoadedStatus } from './ReportLayout';
import { ReportTableOfContentsPage } from './ReportTableOfContentsPage';
import { ReportTitlePage } from './ReportTitlePage';

type Props = {
    comments: Record<ReportItemType, string>;
    componentsDataLoadedStatus: Record<ReportItemType, LoadedStatus>;
    finalValues: ReportFormFinalValues;
    inReportItems: NewReportItem[];
    reportSettings: string[];
    screens: Record<ReportItemType, string[]>;
    titleValues: ReportFormTitleValues;
};

export const ReportFullDocument: FC<Props> = memo(
    ({ reportSettings, titleValues, inReportItems, screens, finalValues, comments, componentsDataLoadedStatus }) => (
        <Document>
            {reportSettings.includes('withPageTitle') ? <ReportTitlePage titleValues={titleValues} /> : null}
            {reportSettings.includes('withTableOfContents')
? (
                <ReportTableOfContentsPage
                    componentsDataLoadedStatus={componentsDataLoadedStatus}
                    inReportItems={inReportItems}
                    screens={screens}
                    withPageTitle={reportSettings.includes('withPageTitle')}
                />
            )
: null}
            {inReportItems.map((item, i) => screens[item.id].every((screen) => Boolean(screen)) ? (
                    <PdfPage
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${item.id}_${i}`}
                        reportItem={item}
                        screens={screens[item.id]}
                        withPageNumbers={reportSettings.includes('withPageNumbers')}
                        comment={comments[item.id]}
                    />
                ) : null,
            )}
            {reportSettings.includes('withFinalPage') ? <ReportFinalPage finalValues={finalValues} /> : null}
        </Document>
    ),
);
