import { type FC } from 'react';

export const CalendarIcon: FC = () => (
    <svg
xmlns="http://www.w3.org/2000/svg" width="36" height="37"
viewBox="0 0 36 37" fill="none">
        <path
            d="M6 15.5V27C6 28.1046 6.89543 29 8 29H28C29.1046 29 30 28.1046 30 27V15.5M6 15.5V10C6
            8.89543 6.89543 8 8 8H13.5M6 15.5H30M30 15.5V10C30 8.89543 29.1046 8 28 8H25.5M13.5
            10.25V8M13.5 5.75V8M22.5 5.75V8M22.5 10.25V8M16.5 8H22.5"
            stroke="#2F9CEB"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
    </svg>
);
