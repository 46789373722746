import { createEndpoint, createEndpointMap } from '../../../core/endpoint';
import { getReportSettings } from './getReportSettings';
import { getReportUploadURL } from './getReportUploadURL';
import { saveReportSettings } from './saveReportSettings';
import { uploadReportFile } from './uploadReportFile';

export const createReportEndpoints = createEndpointMap((client) => ({
    getReportSettings: createEndpoint(getReportSettings, client),
    saveReportSettings: createEndpoint(saveReportSettings, client),
    getReportUploadURL: createEndpoint(getReportUploadURL, client),
    uploadReportFile: createEndpoint(uploadReportFile, client),
}));
