import { Box, Group, Image, Stack, Text } from '@mantine/core';
import { type UseFormReturnType } from '@mantine/form';
import { type FC } from 'react';

import { type ReportFormFinalValues } from './ReportForm.schema';

type Props = {
    form: UseFormReturnType<ReportFormFinalValues>;
};

export const FinalPreview: FC<Props> = ({ form }) => (
    <Stack
mih={403} bg={form.values.background} w="100%"
h="100%" p="50px 43px 40px 44px" justify="end">
        <Group gap={0} w="100%" justify="space-between">
            {form.values.logo
? (
                <Image
h={15} w="fit-content" fit="contain"
key={form.values.logo} src={form.values.logo} />
            )
: (
                <Box h={15} />
            )}
            <Stack gap={0}>
                <Text
                    ff="Inter"
                    fz={8}
                    fw={400}
                    lh="16px"
                    c={form.values.textColor}
                    style={{ wordBreak: 'break-word', textAlign: 'end' }}
                >
                    {form.values.name}
                </Text>
                <Text
                    ff="Inter"
                    fz={8}
                    fw={400}
                    lh="16px"
                    c={form.values.textColor}
                    style={{ wordBreak: 'break-word', textAlign: 'end' }}
                >
                    {form.values.descriptionFinal}
                </Text>
            </Stack>
        </Group>
    </Stack>
);
