import { type RootState, type AppDispatch } from '@store/store';
import { setBondPaymentsPage, type IState } from '@store/store/slices/investorpro/bond.slice';
import { getBondPaymentsThunk } from '@store/store/thunk/investorpro/bond.thunk';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, type Column } from '@modules/Investorpro/shared/components/Table';
import { WithLoader } from '@libs/components/loaders/ViewLoader';
import { Paginator } from '@modules/Investorpro/shared/components/Paginator';

import styles from './styles.module.scss';

type Props = {
    type: keyof IState['payments'];
    columns: Array<Column<any>>;
};

export const PaymentsTypeTab = ({ type, columns }: Props) => {
    const { payments, isin, fintoolId } = useSelector((state: RootState) => state.bond);
    const { page, total, data, loaded, loading } = payments[type];
    const dispatch = useDispatch<AppDispatch>();

    const handlePageChange = (page: number) => {
        dispatch(setBondPaymentsPage({ page, type }));
    };

    useEffect(() => {
        if (!loaded && isin && fintoolId) {
            dispatch(
                getBondPaymentsThunk({
                    isin,
                    fintoolId,
                    type,
                    page,
                }),
            );
        }
    }, [page, dispatch, loaded, isin, fintoolId]);

    return (
        <>
            <WithLoader isLoading={loading}>
                <Table data={data} columns={columns} />
            </WithLoader>
            <Paginator
                page={page}
                isLoading={loading}
                className={styles.paginator}
                total={total}
                handlePageChange={handlePageChange}
                limit={14}
            />
        </>
    );
};
