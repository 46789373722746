import { ModalWindow } from '@libs/components';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { type Watchlist } from '@modules/Investorpro/types/watchlist.type';
import { deleteWatchlistThunk, getWatchlistsThunk, useAppDispatch } from '@store/store';

import styles from './styles.module.scss';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    item?: Watchlist;
};

export const DeleteModal = ({ isOpen, closeModal, item }: Props) => {
    const dispatch = useAppDispatch();

    const deleteWatchlist = () => {
        if (item) {
            dispatch(deleteWatchlistThunk(item?.id)).then(async () => await dispatch(getWatchlistsThunk()));
            closeModal();
        }
    };

    return (
        <ModalWindow isVisible={isOpen} closeModal={closeModal}>
            <div className={classNames(styles.deleteModalWrapper, 'flex', 'flex-column')}>
                <div className={classNames('flex', 'flex-column')}>
                    <h2>Удалить список</h2>
                    <p>
                        Вы уверены, что хотите удалить список «<span className={styles.name}>{item?.name}</span>»? После
                        удаления восстановить список не получится.
                    </p>
                </div>
                <div className={classNames(styles.btnWrapper, 'flex')}>
                    <Button label={'Отменить'} className={styles.cancelBtn} onClick={closeModal} />
                    <Button label={'Удалить'} className={styles.confirmBtn} onClick={deleteWatchlist} />
                </div>
            </div>
        </ModalWindow>
    );
};
