import { useEffect, useRef } from 'react';

import { type InstrumentIndustryData } from '../types';

type CallCallbacksArgs = {
    data: InstrumentIndustryData[];
    key: string;
    filter?: string;
    ignore?: string[];
    filterFullInclude?: boolean;
};

export const useCurrentRequests = () => {
    const currentRequests = useRef<
        Record<
            string,
            {
                abortControllers: Array<{ abortController: AbortController; filter?: string }>;
                callbacks: Array<{
                    cb: (data: InstrumentIndustryData[]) => void;
                    filter?: string;
                }>;
            }
        >
    >({});

    const resetRequests = (key: string) => {
        currentRequests.current[key] = {
            abortControllers: [],
            callbacks: [],
        };
    };

    const resetCallbacks = (key: string, filters?: string[]) => {
        currentRequests.current[key].callbacks = currentRequests.current[key].callbacks ?? [];

        currentRequests.current[key].callbacks = filters
            ? currentRequests.current[key].callbacks.filter(({ filter: filterCallback }) => filterCallback ? !filters.includes(filterCallback) : true,
              )
            : [];
    };

    const callCallbacks = ({ data, key, filter, ignore = [], filterFullInclude = true }: CallCallbacksArgs) => {
        if (currentRequests.current[key]) {
            let callbacks = currentRequests.current[key].callbacks ?? [];
            callbacks = filter
                ? callbacks.filter(({ filter: filterCallback }) => {
                      if (filter && filterCallback) {
                          if (filterFullInclude) {
                              return filterCallback === filter;
                          }

                          return new RegExp(filter).test(filterCallback);
                      }

                      return true;
                  })
                : callbacks;
            callbacks = ignore
                ? callbacks.filter(({ filter }) => (filter ? !ignore.includes(filter) : true))
                : callbacks;
            callbacks.forEach(({ cb }) => cb(data));
            currentRequests.current[key].callbacks = currentRequests.current[key].callbacks.filter(
                (cb) => !callbacks.includes(cb),
            );
        }
    };

    const addCallback = (
        key: string,
        callback: (data: InstrumentIndustryData[]) => void | Promise<void>,
        filter?: string,
    ) => {
        currentRequests.current[key] = currentRequests.current[key] ?? {
            abortControllers: [],
            callbacks: [],
        };
        currentRequests.current[key].callbacks.push({ cb: callback, filter });
    };
    const addRequest = (key: string, filter?: string) => {
        const abortController = new AbortController();
        currentRequests.current[key] = currentRequests.current[key] ?? {
            abortControllers: [],
            callbacks: [],
        };
        currentRequests.current[key].abortControllers.push({ abortController, filter });

        return abortController.signal;
    };
    const abortRequests = (key: string, filter?: string[]) => {
        if (currentRequests.current[key]) {
            const abortControllers = currentRequests.current[key].abortControllers ?? [];
            const lessControllers = [];

            for (const { abortController, filter: requestFilter } of abortControllers) {
                if ((requestFilter && filter && filter.includes(requestFilter)) || !requestFilter || !filter) {
                    abortController.abort();
                } else {
                    lessControllers.push({ abortController, filter: requestFilter });
                }
            }

            currentRequests.current[key].abortControllers = lessControllers;
        }
    };

    const abortAllRequests = () => {
        Object.keys(currentRequests.current).forEach((key) => {
            abortRequests(key);
        });
    };

    useEffect(() => {
        return abortAllRequests;
    }, []);

    return {
        addCallback,
        addRequest,
        abortRequests,
        resetCallbacks,
        resetRequests,
        currentRequests,
        abortAllRequests,
        callCallbacks,
    };
};
