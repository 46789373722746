import { ScrollArea, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { type NewReportItem, type ReportItemType } from '@modules/Investorpro/modules/PortfolioPage/entities';

import './DragStyles.css';
import { ReportItem } from './ReportItem';
import { type LoadedStatus } from './ReportLayout';

type Props = {
    isInReport: boolean;
    items: NewReportItem[];
    moveToOpposite: (item: NewReportItem, isInReport: boolean) => void;
    onEditClick: (item: NewReportItem) => void;
    onShowClick: (item: NewReportItem) => void;
    setReportItems: (items: NewReportItem[]) => void;
    componentsDataLoadedStatus: Record<ReportItemType, LoadedStatus>;
};

export const DragArea: FC<Props> = ({
    isInReport,
    items,
    moveToOpposite,
    onEditClick,
    onShowClick,
    setReportItems,
    componentsDataLoadedStatus,
}) => (
    <Stack
        h="50dvh"
        w="100%"
        px={32}
        pt={14}
        gap={15}
        style={{
            border: '1px solid #D8E1EB',
            borderRightColor: !isInReport ? '#EFE7E7' : '#D8E1EB',
            borderLeftColor: isInReport ? '#EFE7E7' : '#D8E1EB',
            borderTopLeftRadius: !isInReport ? '6px' : '0px',
            borderTopRightRadius: isInReport ? '6px' : '0px',
            borderBottomLeftRadius: !isInReport ? '6px' : '0px',
            borderBottomRightRadius: isInReport ? '6px' : '0px',
        }}
    >
        <Text
ff="Inter" fz={19} fw={400}
lh="22px" mb={11}>
            {isInReport ? 'Включено в отчет' : 'Не включено в отчет'}
        </Text>
        <ScrollArea.Autosize
mb={14} w="100%" type="scroll"
scrollbars="y" scrollbarSize={6} offsetScrollbars={true}>
            <ReactSortable
                filter=".notDraggable"
                animation={150}
                fallbackOnBody={true}
                swapThreshold={0.6}
                group="shared"
                forceFallback={true}
                ghostClass="ghost"
                chosenClass="chosen"
                dragClass="drag"
                list={items}
                setList={setReportItems}
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 15,
                }}
            >
                {items.length <= 0
? (
                    <Stack justify="center" mih="40dvh" className="notDraggable">
                        <Text
                            ff="Inter"
                            fz={20}
                            lh="30px"
                            fw={400}
                            style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
                        >
                            {isInReport
                                ? 'Для создания отчета необходимо добавить\n хотя бы один элемент.\n ' +
                                  'Перетяните нужные элементы из блока слева.'
                                : 'Выбраны все доступные\nдля отчета элементы.'}
                        </Text>
                    </Stack>
                )
: (
                    items.map((item) => (
                        <ReportItem
                            item={item}
                            key={item.id}
                            inReport={isInReport}
                            moveToOpposite={moveToOpposite}
                            onShowClick={onShowClick}
                            onEditClick={onEditClick}
                            componentDataLoadedStatus={componentsDataLoadedStatus[item.id]}
                        />
                    ))
                )}
            </ReactSortable>
        </ScrollArea.Autosize>
    </Stack>
);
