import { useRef, useState } from 'react';
import { type RootState } from '@store/store';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { RadioButton } from 'primereact/radiobutton';
import { type SettingParam } from '@modules/Investorpro/shared/components/WatchlistSidebar/types';
import { settingParams } from '@modules/Investorpro/shared/components/WatchlistSidebar/data';
import { EmptyFavorites } from '@modules/Investorpro/shared/components/WatchlistSidebar/components/EmptyFavorites';
import { NotEmptyFavorites } from '@modules/Investorpro/shared/components/WatchlistSidebar/components/NotEmptyFavorites';

import { ReactComponent as SettingFavoriteIcon } from '../../images/svg/SettingFavorite.svg';
import styles from './styles.module.scss';

type Props = {
    isOpen: boolean;
    closeSidebar: () => void;
};

export const WatchlistSidebar = ({ isOpen, closeSidebar }: Props) => {
    const settingRef = useRef<any>(null);

    const [selectedSettingParam, setSelectedSettingParam] = useState<SettingParam>(settingParams[0]);

    const { watchlists, watchlistData } = useSelector((state: RootState) => state.investorProfile);

    const headerTemplate = (
        <div className={styles.headerWrapper}>
            Избранное
            {watchlistData.length > 0 && (
                <>
                    <Button icon={<SettingFavoriteIcon />} onClick={(e) => settingRef.current.toggle(e)} />
                    <OverlayPanel ref={settingRef} className={styles.overlayPanelWrapper}>
                        <div className={classNames(styles.settingsWrapper)}>
                            {settingParams.map((param) => (
                                <div
                                    key={param.code}
                                    className={classNames('flex')}
                                    onClick={() => setSelectedSettingParam(param)}
                                >
                                    <RadioButton
                                        inputId={param.code}
                                        value={param.code}
                                        checked={param.code === selectedSettingParam.code}
                                        pt={{
                                            root: { className: styles.radioButtonSettings },
                                            box: { className: styles.radioButtonSettings_box },
                                            icon: { className: styles.radioButtonSettings_icon },
                                        }}
                                    />
                                    <span>{param.name}</span>
                                </div>
                            ))}
                        </div>
                    </OverlayPanel>
                </>
            )}
        </div>
    );

    return (
        <>
            <Sidebar
                visible={isOpen}
                onHide={closeSidebar}
                position="right"
                className={styles.favoritesSidebarWrapper}
                header={headerTemplate}
                maskClassName={classNames(styles.watchlistSidebarWrapper)}
            >
                {watchlists.length
? (
                    <NotEmptyFavorites selectedSettingParam={selectedSettingParam} />
                )
: (
                    <EmptyFavorites />
                )}
            </Sidebar>
        </>
    );
};
