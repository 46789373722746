import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@store/store';
import { Sidebar } from 'primereact/sidebar';
import { readAlertThunk } from '@store/store/thunk/investorpro/alerts.thunk';
import { classNames } from 'primereact/utils';
import { TabPanel, TabView } from 'primereact/tabview';

import styles from './styles.module.scss';
import { EmptyData } from './components/EmptyData';
import { TabWithSearch } from './shared/components/TabWithSearch';
import { FULLFILLED_ALERTS_COLUMNS, PENDING_ALERTS_COLUMNS } from './columns';
import { type Alert } from './services/types';

type Props = {
    isOpen: boolean;
    closeSidebar: () => void;
};

export const AlertsSidebar = ({ isOpen, closeSidebar }: Props) => {
    const [currentTab, setCurrentTab] = useState(0);
    const { fulfilledAlerts, pendingAlerts } = useAppSelector((state) => state.alerts);
    const noFulfilledAlerts = fulfilledAlerts.length === 0;
    const noPendingAlerts = pendingAlerts.length === 0;

    const dispatch = useAppDispatch();
    const hasUnreadAlerts = fulfilledAlerts.some((alert) => !alert.isRead);

    const handleReadAlert = (alert: Alert) => {
        if (!alert.isRead) {
            dispatch(readAlertThunk(alert));
        }
    };

    const tabs = [
        {
            label: (
                <div className={styles.tabLabel}>
                    <span>Ожидаемые</span>
                    <span className={styles.countAlerts}>{pendingAlerts.length}</span>
                </div>
            ),
            tab: noPendingAlerts
? (
                <EmptyData />
            )
: (
                <TabWithSearch withAddAlertButton alerts={pendingAlerts} columns={PENDING_ALERTS_COLUMNS} />
            ),
        },
        {
            label: (
                <div className={classNames(styles.tabLabel, hasUnreadAlerts && styles.hasUnreadAlerts)}>
                    <span>Сработавшие</span>
                    <span className={styles.countAlerts}>{fulfilledAlerts.length}</span>
                </div>
            ),
            disabled: noFulfilledAlerts,
            tab: (
                <TabWithSearch
                    readAlert={handleReadAlert}
                    alerts={fulfilledAlerts}
                    className={styles.fullfilledAlertsTab}
                    columns={FULLFILLED_ALERTS_COLUMNS}
                />
            ),
        },
    ];

    const headerTemplate = <div className={styles.alertsSidebarHeaderWrapper}>Уведомления</div>;

    return (
        <Sidebar
            visible={isOpen}
            onHide={closeSidebar}
            position="right"
            className={styles.alertsSidebar}
            pt={{
                header: { className: styles.alertsSidebar_header },
            }}
            header={headerTemplate}
            maskClassName={styles.alertsSidebarWrapper}
        >
            <TabView
                className={classNames(styles.tabView)}
                activeIndex={currentTab}
                onTabChange={({ index }) => setCurrentTab(index)}
                pt={{
                    navContent: {
                        className: classNames(styles.tabView_navContent),
                    },
                    panelContainer: { className: classNames(styles.tabView_panelContainer) },
                }}
            >
                {tabs.map(({ label, tab, disabled }, index) => (
                    <TabPanel
key={index} disabled={disabled} header={label}
className={styles.tabPanel}>
                        {tab}
                    </TabPanel>
                ))}
            </TabView>
        </Sidebar>
    );
};
