import axios, { type AxiosResponse } from 'axios';

import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const uploadReportFile: ApiFactory<ApiEndpoint<AxiosResponse, { file: File }, { uploadUrl: string }>
> = () => async ({ signal, data: { file }, params: { uploadUrl } }) => {
    const res = await axios.put(uploadUrl, file, {
        signal,
        headers: {
                'Content-Type': 'application/pdf',
            },
        });

    return res;
};
