import { Divider, Group, Text } from '@mantine/core';
import { type FC } from 'react';

type Props = {
    positiveSum?: number;
    negativeSum?: number;
};

export const FooterCell: FC<Props> = ({ positiveSum, negativeSum }) => (
    <Group justify="flex-end">
        <Text
fz="14px" lh="16px" fs="normal"
c="#288C14">
            {positiveSum}
        </Text>
        <Divider
orientation="vertical" color="#A5B1C0" h={13}
style={{ alignSelf: 'center' }} />
        <Text
fz="14px" lh="16px" fs="normal"
c="#E42821">
            {negativeSum}
        </Text>
    </Group>
);
