import { ScrollArea, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { type Scenario } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { StressScenarioCard } from './StressScenarioCard';

type Props = {
    scenarios: Scenario[];
    handleCardClick: (uuid: string, dateFrom: string, dateTill: string) => void;
    scenarioUuid: string;
};

export const StressScenarioList: FC<Props> = ({ scenarios, handleCardClick, scenarioUuid }) => (
    <Stack gap={6} maw={420}>
        <Text fz={19} fw={400}>
            {'Сценарии стресс-тестирования'}
        </Text>
        <ScrollArea.Autosize mah="74dvh" miw={410} scrollbarSize={6}>
            {scenarios?.map((scenario) => (
                <StressScenarioCard
                    key={scenario.uuid}
                    scenario={scenario}
                    onClick={handleCardClick}
                    isSelected={scenario.uuid === scenarioUuid}
                />
            ))}
        </ScrollArea.Autosize>
    </Stack>
);
