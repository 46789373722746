import { useLocalStorage } from '@mantine/hooks';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { PortfolioFilterKeys } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { useApi } from '../../../api';

export const useGetPortfolioRiskMetricsQuery = (
    portfolioId: string,
    dates?: [Date | null, Date | null],
    enabled = true,
    onStatusChange?: (status: 'error' | 'success' | 'pending') => void,
) => {
    const api = useApi();
    const [localStorageValue, setLocalStorageValue] = useLocalStorage<[Date | null, Date | null]>({
        key: PortfolioFilterKeys.RISK_METRICS_FILTER,
        defaultValue: [dayjs().subtract(10, 'years').toDate(), dayjs().toDate()],
    });

    useEffect(() => {
        if (dates) {
            setLocalStorageValue(dates);
        }
    }, [dates, setLocalStorageValue]);

    const query = useQuery({
        queryKey: ['portfolio', portfolioId, 'risk-metrics', localStorageValue[0], localStorageValue[1]],
        queryFn: async () => await api.portfolio.riskMetrics.getRiskMetrics({
            params: {
                id: portfolioId,
                dateFrom: localStorageValue[0],
                dateTill: localStorageValue[1],

            },
        }),
        placeholderData: keepPreviousData,
        enabled: Boolean(portfolioId) && Boolean(localStorageValue[0]) && Boolean(localStorageValue[1]) && enabled,
    });

    useEffect(() => {
        if (query.isLoading || query.isFetching) {
            onStatusChange?.('pending');
        }

        if (query.isError) {
            onStatusChange?.('error');
        }

        if (query.isSuccess && !query.isLoading && !query.isFetching) {
            onStatusChange?.('success');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isLoading, query.isError, query.isSuccess, query.isFetching, query.isPending]);

    return query;
};
