import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const ViewList: FC<Props> = ({ width = 24, height = 24, color = 'currentColor' }) => (
    <svg
width={width} height={height} viewBox="0 0 24 24"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="view-list">
            <path
                id="Icon"
                d="M4 6H20M4 10H20M4 14H20M4 18H20"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
