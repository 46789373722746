import { type Nullable } from 'primereact/ts-helpers';

export type ScreenerFilterValueType = {
    id: FilterType['id'];
    // values, если [FilterTypes.SINGLE_SELECT, FilterTypes.MULTIPLE_SELECT].includes(FilterType['type'])
    values?: Array<FilterValueType['id']>;

    // startValue и endValue, если [FilterTypes.RANGE].includes(type)
    startValue?: string | null;
    endValue?: string | null;

    // dates, если [FilterTypes.RANGE].includes(type) И [ValueTypes.DATE, ValueTypes.TIME, ValueTypes.DATE_TIME].includes(valueType)
    /**
     * @deprecated
     */
    dates?: Nullable<Array<Date | null>>;

    // values, если [FilterTypes.INPUT].includes(FilterType['type'])
    value?: string;
};

export type ScreenerColumnType = {
    id: number;
};

export type ScreenerType = {
    id: number;
    name: string;
    instrumentTypeId: number;
    filters: ScreenerFilterValueType[];
    columns: ScreenerColumnType[];
};

export type RequestScreenerType = {
    id?: number;
    name: string;
    instrumentTypeId: number;
    filters: ScreenerFilterValueType[];
    columns?: Array<ScreenerColumnType>;
};

export type ColumnType = {
    id: number;
    fieldName: string;
    name: string;
    groupId: number[];
    colored: boolean;
    percentaged: boolean;
    showCurrency: boolean;
    primary: boolean;
    reducedToMil: boolean;
};

export type ColumnGroupType = {
    id: number;
    name: string;
    instrumentTypeId: number;
};

export type ColumnsResponseType = {
    columns: ColumnType[];
    groups: ColumnGroupType[];
};

export type InstrumentsCountRequestType = {
    instrumentTypeId: number;
    filters: Array<Omit<ScreenerFilterValueType, 'dates'>>;
};

export type InstrumentsCountResponseType = { count: number };

export type CopyScreenersReqType = {
    screenerId: number;
    name: string;
};

export enum FilterTypes {
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTIPLE_SELECT = 'MULTIPLE_SELECT',
    INPUT = 'INPUT',
    RANGE = 'RANGE',
}

export enum ValueTypes {
    DATE = 'DATE',
    TIME = 'TIME',
    DATE_TIME = 'DATE_TIME',
    STRING = 'STRING',
    NUMBER = 'NUMBER',
}

export type FilterValueType = {
    id: number;
    value: string;
    name: string;
};

export type FilterType = {
    id: number;
    fieldName: string;
    name: string;
    groupIds: number[];
    type: FilterTypes;
    valueType: ValueTypes;

    // values заполнено, если [FilterTypes.SINGLE_SELECT, FilterTypes.MULTIPLE_SELECT].includes(type)
    values?: FilterValueType[];

    // minValue и maxValue заполнено, если [FilterTypes.RANGE].includes(type)
    minValue?: string;
    maxValue?: string;

    // NOTE isCurrencyAvailable пока никак не обрабатывается, так как ТЗ не реализовано
    isCurrencyAvailable: boolean;
};

export type FiltersGroupType = {
    id: number;
    name: string;
    instrumentTypeId: number;
    order: number;
};

export type FiltersInstrumentType = {
    id: number;
    name: string;
    isAvailable: boolean;
};

export type FiltersResponseType = {
    filters: FilterType[];
    groups: FiltersGroupType[];
    instrumentTypes: FiltersInstrumentType[];
};
