/* eslint-disable no-case-declarations */
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getGradient, useChartTooltip } from '@libs/utils';
import { Chart } from 'primereact/chart';
import { commonChartOptions } from '@modules/Investorpro/configs/commonChartOptions';
import { TooltipWrapper } from '@modules/Investorpro/shared/components/TooltipWrapper';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getBondPaymentsBarsDataThunk } from '@store/store/thunk/investorpro/bond.thunk';
import {
    type CouponPayment,
    type PaymentChartBarType,
    type RedemptionPayment,
    type AmmortizationPayment,
    type OfferPayment,
    PaymentType,
} from '@modules/Investorpro/modules/BondStockPage/BondPage/services/types';
import { setPaymentsCurvesWindowLeftBound } from '@store/store/slices/investorpro/bond.slice';
import {
    BOARD_PAYMENTS_CHART_STEP,
    BOARD_PAYMENTS_CHART_WINDOW_SIZE,
} from '@modules/Investorpro/modules/BondStockPage/BondPage/shared/constants';
import { type ChartData, type Chart as ChartType } from 'chart.js';
import { format, isBefore, subDays } from 'date-fns';
import { type Point } from 'chart.js/dist/core/core.controller';

import { DateNavigationButtons } from './components/DateNavigationButtons';
import { TooltipContent } from './components/TooltipContent';
import styles from './styles.module.scss';

export const PaymentsChart = () => {
    const dispatch = useAppDispatch();
    const { data, loaded, leftBound } = useAppSelector((state) => state.bond.paymentsCurves);
    const { isin, fintoolId } = useAppSelector((state) => state.bond);
    const [hasPlaceForBottomArrow, setHasPlaceForBottomArrow] = useState<boolean>(false);
    const chartRef = useRef<Chart>(null);
    const yTooltipOffset = { y: 30 };
    const [tooltipRef, tooltipDataPoints, tooltipCallback, tooltipAlignment] = useChartTooltip<Date>({
        alignmentOffset: {
            left: yTooltipOffset,
            right: yTooltipOffset,
            bottom: yTooltipOffset,
        },
        onTooltipPositionChange: ({ alignment, context, el, x, y }) => {
            if (alignment === 'bottom') {
                const {
                    chart: { canvas },
                } = context;
                const chartBottomPosition = canvas.getBoundingClientRect()?.bottom ?? 0;
                const chartTopPosition = canvas.getBoundingClientRect()?.top ?? 0;
                const tooltipHeight = el?.firstElementChild?.getBoundingClientRect()?.height ?? 0;
                const tooltipBottomPosition = chartTopPosition + y + tooltipHeight;
                const isBottomBoundOfTooltipOnChartArea = tooltipBottomPosition <= chartBottomPosition;

                setHasPlaceForBottomArrow(isBottomBoundOfTooltipOnChartArea);
            }
        },
    });

    const handleDateShiftLeft = () => {
        dispatch(setPaymentsCurvesWindowLeftBound(Math.max(0, leftBound - BOARD_PAYMENTS_CHART_STEP)));
    };
    const handleDateShiftRight = () => {
        dispatch(
            setPaymentsCurvesWindowLeftBound(
                Math.min(leftBound + BOARD_PAYMENTS_CHART_STEP, data.length - BOARD_PAYMENTS_CHART_WINDOW_SIZE),
            ),
        );
    };

    const tooltipData = tooltipDataPoints?.[0]?.raw as any;

    const [chartData, setChartData] = useState<ChartData>();
    const [count, setCount] = useState(0);
    const chart = chartRef.current?.getChart() as ChartType<'line'> | undefined;
    const canvas = chartRef.current?.getCanvas();

    useEffect(() => {
        const dontFindChart = !chart || !canvas;

        if (dontFindChart && count < 3) {
            setTimeout(() => setCount((prev) => prev + 1), 50);

            return;
        }

        const getItemChartValue = (item: PaymentChartBarType) => {
            switch (item.type) {
                case PaymentType.REDEMPTION:
                    const { paymentPerBond, endmtyDate } = item as RedemptionPayment;

                    return {
                        type: item.type,
                        x: endmtyDate,
                        y: paymentPerBond,
                    };
                case PaymentType.AMORTIZATION:
                    const { mtyDate, payPerBond } = item as AmmortizationPayment;

                    return {
                        type: item.type,
                        x: mtyDate,
                        y: payPerBond,
                    };
                case PaymentType.COUPON:
                    const { paymentDate, paymentAmount } = item as CouponPayment;

                    return {
                        type: item.type,
                        x: paymentDate,
                        y: paymentAmount,
                    };
                case PaymentType.OFFER:
                    const { offerDate, buyBackPrice } = item as OfferPayment;

                    return {
                        type: item.type,
                        x: offerDate,
                        y: buyBackPrice,
                    };
                default:
                    return {
                        type: item.type,
                        x: new Date().toJSON(),
                        y: 0,
                    };
            }
        };
        const visibleData: Array<PaymentChartBarType & { x: string; y: number }> = data
            .filter((_, index) => index >= leftBound && index < leftBound + BOARD_PAYMENTS_CHART_WINDOW_SIZE)
            .map((item) => ({
                ...item,
                ...getItemChartValue(item),
            }));

        const dataColors: Record<PaymentType, any[]> = {
            [PaymentType.COUPON]: [],
            [PaymentType.AMORTIZATION]: [],
            [PaymentType.REDEMPTION]: [],
            [PaymentType.OFFER]: [],
        };

        const commonDatasetOptions = {
            borderWidth: 0,
            grouped: true,
            borderRadius: 4,
        };

        const datasets: Record<PaymentType, any> = {
            [PaymentType.REDEMPTION]: {
                data: [],
                ...commonDatasetOptions,
            },
            [PaymentType.COUPON]: {
                data: [],
                ...commonDatasetOptions,
            },
            [PaymentType.AMORTIZATION]: {
                data: [],
                ...commonDatasetOptions,
            },
            [PaymentType.OFFER]: {
                data: [],
                borderDash: [5, 5],
                grouped: true,
                borderColor: '#42A62E66',
                borderRadius: 4,
            },
        };
        const secondPartOpacitySteps = { 1: 0.9, 0: 0.45 };
        const firstPartPartOpacitySteps = { 1: 0.3, 0: 0.15 };

        visibleData.forEach((item, index) => {
            if (chart) {
                const ctx = { chart };
                const isBeforeToday = isBefore(item.date, new Date());

                const opacitySteps = isBeforeToday ? firstPartPartOpacitySteps : secondPartOpacitySteps;

                if (item.type === 'redemption') {
                    const color = '#F2433D';
                    dataColors[item.type].push(getGradient({ color, opacitySteps })(ctx));
                }

                if (item.type === 'coupon') {
                    const color = '#2F9CEB';
                    dataColors[item.type].push(getGradient({ color, opacitySteps })(ctx));
                }

                if (item.type === 'offer') {
                    const color = '#42A62E';
                    const opacitySteps = isBeforeToday ? { 1: 0.07, 0: 0.035 } : { 1: 0.15, 0: 0.075 };
                    dataColors[item.type].push(getGradient({ color, opacitySteps })(ctx));
                }

                if (item.type === 'amortization') {
                    const color = '#F7D762';
                    dataColors[item.type].push(getGradient({ color, opacitySteps })(ctx));
                }

                datasets[item.type].data.push(item);
            }
        });

        setChartData({
            datasets: Object.keys(datasets).map((key) => ({
                ...datasets[key as PaymentType],
                backgroundColor: dataColors[key as PaymentType],
                barThickness: 22,
            })),
        });
    }, [data, count, leftBound]);

    useEffect(() => {
        if (!loaded && fintoolId && isin) {
            dispatch(getBondPaymentsBarsDataThunk({ fintoolId, isin }));
        }
    }, [loaded, fintoolId]);

    const options = useMemo(() => {
        let minDate = (chartData?.datasets?.[0]?.data?.[0] as Point)?.x
            ? new Date((chartData?.datasets?.[0]?.data?.[0] as Point)?.x)
            : new Date();

        if (chartData) {
            for (let i = 0; i < chartData.datasets.length; i++) {
                const date = (chartData.datasets[i].data[0] as Point)?.x
                    ? new Date((chartData.datasets[i].data[0] as Point)?.x)
                    : minDate;

                if (isBefore(date, minDate)) {
                    minDate = date;
                }
            }
        }

        minDate = subDays(minDate, 1);

        return {
            ...commonChartOptions,
            animation: {
                duration: 0,
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    mode: 'nearest',
                    intersect: false,
                    enabled: false,
                    external: tooltipCallback,
                },
            },
            scales: {
                ...commonChartOptions.scales,
                x: {
                    ...commonChartOptions.scales.x,
                    type: 'timeseries',
                    stacked: true,
                    ticks: {
                        callback: (value: number, index: number) => {
                            if (value === minDate.getTime()) {
                                return '';
                            }

                            return format(new Date(value), 'dd.MM.yyyy');
                        },
                    },
                    min: minDate.toJSON(),
                },
                y: {
                    ...commonChartOptions.scales.y,
                    ticks: {
                        ...commonChartOptions.scales.y.ticks,
                        padding: 7,
                    },
                },
            },
            responsive: true,
        };
    }, [chartData]);

    const getArrowTooltipAlignment = () => {
        if (tooltipAlignment === 'bottom') {
            return hasPlaceForBottomArrow ? 'top' : 'bottom';
        }

        return undefined;
    };

    return (
        <div className={classNames('flex', 'flex-column', 'relative', styles.paymentsChartBlock)}>
            <DateNavigationButtons
                handleShiftLeft={handleDateShiftLeft}
                handleShiftRight={handleDateShiftRight}
                isLeftDisabled={leftBound === 0}
                isRightDisabled={leftBound === Math.max(0, data.length - BOARD_PAYMENTS_CHART_WINDOW_SIZE)}
            />
            <Chart
                type="bar"
                ref={chartRef}
                className={styles.paymentsChart}
                data={chartData}
                options={options}
                width={1206 + 'px'}
                height={470 + 'px'}
            />
            <TooltipWrapper
                ref={tooltipRef}
                className={styles.tooltip}
                alignment={tooltipAlignment}
                arrowAlignment={getArrowTooltipAlignment()}
            >
                {tooltipData && <TooltipContent data={tooltipData} />}
            </TooltipWrapper>
        </div>
    );
};
