import classNames from 'classnames';
import { Button } from 'primereact/button';
import {
    Paginator,
    type PaginatorNextPageLinkOptions,
    type PaginatorPageLinksOptions,
    type PaginatorPrevPageLinkOptions,
    type PaginatorTemplateOptions,
} from 'primereact/paginator';

import styles from './styles.module.scss';

const DEFAULT_PAGE_LINK_SIZE = 5;

type PaginationControllerProps = {
    total: number;
    current: number;
    setAsyncCurrent: (num: number) => void;
    paginatorOptions?: PaginatorTemplateOptions;
    nextBtn?: string;
    prevBtn?: string;
    currentPageCustomClass?: string;
    customClass?: string;
    disabled?: boolean;
    pageLinkSize?: number;
};

export const PaginationController = ({
    total,
    current,
    setAsyncCurrent,
    paginatorOptions,
    nextBtn,
    prevBtn,
    currentPageCustomClass,
    customClass,
    disabled = false,
    pageLinkSize = DEFAULT_PAGE_LINK_SIZE,
}: PaginationControllerProps) => {
    const previousButton = (option: PaginatorPrevPageLinkOptions) => !option.disabled
? (
            <Button
                onClick={option.onClick}
                disabled={disabled}
                className={classNames(styles.textButton, styles.prevButton)}
                label={prevBtn || 'Предыдущая'}
            />
        )
: (
            <></>
        );

    const nextButton = (option: PaginatorNextPageLinkOptions) => !option.disabled
? (
            <Button
                onClick={option.onClick}
                disabled={disabled}
                className={classNames(styles.textButton)}
                label={nextBtn || 'Cледующая'}
            />
        )
: (
            <></>
        );

    const paginationTemplate = paginatorOptions || {
        layout: 'PrevPageLink PageLinks NextPageLink',
        PrevPageLink: previousButton,
        NextPageLink: nextButton,
        PageLinks: (options: PaginatorPageLinksOptions) => {
            const showStartPage = options.view.startPage === options.page && options.view.startPage !== 0;
            const showEndPage = options.view.endPage === options.page && options.page + 1 !== options.totalPages;

            if (showStartPage || showEndPage) {
                const className = classNames(options.className, { 'p-disabled': true }, styles.threeDotsP);

                return (
                    <>
                        {showStartPage && (
                            <Button
                                disabled={disabled}
                                className={classNames(
                                    options.className,
                                    styles.pagButton,
                                    current === options.page && (currentPageCustomClass || styles.current),
                                )}
                                onClick={() => setAsyncCurrent(0)}
                            >
                                1
                            </Button>
                        )}
                        {total > pageLinkSize && (
                            <span className={className} style={{ userSelect: 'none' }}>
                                ...
                            </span>
                        )}
                        {showEndPage && (
                            <Button
                                disabled={disabled}
                                className={classNames(
                                    options.className,
                                    styles.pagButton,
                                    current === options.page && (currentPageCustomClass || styles.current),
                                )}
                                onClick={() => setAsyncCurrent(total - 1)}
                            >
                                {total}
                            </Button>
                        )}
                    </>
                );
            }

            return (
                <Button
                    className={classNames(
                        options.className,
                        styles.pagButton,
                        current === options.page && (currentPageCustomClass || styles.current),
                    )}
                    disabled={disabled}
                    onClick={options.onClick}
                >
                    {options.page + 1}
                </Button>
            );
        },
    };

    return (
        <Paginator
            className={classNames('flex', 'justify-content-start', customClass)}
            template={paginationTemplate}
            first={current}
            rows={1}
            totalRecords={total}
            onPageChange={(e) => setAsyncCurrent(e.page)}
            pageLinkSize={pageLinkSize}
        />
    );
};
