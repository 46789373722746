import * as yup from 'yup';

export interface FormData {
    name: string;
    email: string;
    text: string;
}

const lettersOnlyRegex = /^[a-zA-Zа-яА-ЯёЁ\s-]+$/;

export const schema = yup.object().shape({
    name: yup
        .string()
        .min(3, 'Недостаточное количество введенных символов.')
        .max(128, 'Превышено максимальное количество введенных символов.')
        .matches(lettersOnlyRegex, 'Использованы запрещенные символы при вводе.')
        .required('Не заполнены имя и фамилия.'),
    email: yup
        .string()
        .min(5, 'Недостаточное количество введенных символов.')
        .max(64, 'Превышено максимальное количество введенных символов.')
        .email('Использованы запрещенные символы при вводе.')
        .required('Не заполнен email.'),
    text: yup
        .string()
        .min(10, 'Недостаточное количество введенных символов.')
        .max(1024, 'Превышено максимальное количество введенных символов.')
        .required('Не заполнен вопрос.'),
});
