export type DynamicConfig = {
    [key: string]: string;
    environment: 'DEV' | 'PROD';
}

export const defaultConfig: DynamicConfig = {
    INVESTOR_PRO_PRODUCT_GUID: 'e58ed763-928c-4155-bee9-fdbaaadc1555',
    environment: 'DEV',
};

export const dynamicConfigUrl = 'config.json';
