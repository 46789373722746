import { type SecurityMarketType } from '@libs/types';
import { type AxiosResponse } from 'axios';

import { type ProfitDividendInfo } from '../modules/BondStockPage/StockPage/types';

export type GetActualQuotesDataReqBody = {
    engine: InstrumentEngineType;
    market: SecurityMarketType;
    board: string;
    secId: string;
}

export type QuoteType = {
    price: number;
    quantity: number;
    buysell: QuoteBuySell;
    updateTime: string;
}

export enum InstrumentEngineType {
    FUTURES = 'futures',
    STOCK = 'stock',
    CURRENCY = 'currency',
    BOND = 'bond'
}

export enum CandleDataColumnType {
    OPEN = 'open',
    CLOSE = 'close',
    DELTA_ABS = 'delta_abs',
    DELTA_PROC = 'delta_proc',
    VALUE = 'value',
    BEGIN = 'begin',
    HIGH = 'high',
    LOW = 'low',
    VOLUME = 'volume',
    END = 'end',
}

export type GetCandlesRequestBody = {
    engine: InstrumentEngineType;
    secId: string;
    board: string;
    market: SecurityMarketType;
    arrayPos?: string[];
    from: string | Date;
    till: string | Date;
    interval: number;
    columns?: CandleDataColumnType[];
    pageSize?: number;
    pageNumber?: number;
}

export type GetCandlesDataResponse = AxiosResponse<{
    candles: CandleBookType;
}>

export type GetActualQuotesDataResponse = AxiosResponse<{
    orderbook: QuotesBookType;
}>

export type QuotesBookType = {
    columns: string[];
    data: Array<Array<string | number | number[] | string[]>>;
}

export enum QuoteBuySell {
    BUY = 'BUY',
    SELL = 'SELL'
}

export type WebSocketQuote = {
    buysell: QuoteBuySell;
    price: number;
    quantity: number;
    timestamp: string;
    repovalue: number | null;
}

export type CandleBookType = {
    columns: string[];
    data: Array<Array<Array<string | number | number[] | string[]>>>;
}

export type CandleType = {
    open: number;
    close: number;
    high: number;
    low: number;
    volume: number;
    value: number;
    begin: string;
    end: string;
    x: string;
    minVolume: number;
    maxVolume: number;
}

export type GetProfitForPeriodRequestResponse = AxiosResponse<ProfitDividendInfo>

export type GetProfitForPeriodRequestBody = {
    secId: string;
    market?: SecurityMarketType;
    board?: string;
    engine?: InstrumentEngineType;
    signal?: AbortSignal;
}
