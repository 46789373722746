import { isValueExist } from '@libs/utils';
import { differenceInDays } from 'date-fns';
import { round } from 'lodash';

export const resetSliceState = <T extends object>(state: T, initState: T) => {
    Object.keys(state).forEach((key) => {
        state[key as keyof T] = initState[key as keyof T];
    })
}

export const calculateAbsoluteChange = (currentPrice: number | null, prevPrice: number | null, roundValue?: number) => {
    if (!isValueExist(currentPrice) || !isValueExist(prevPrice)) {
        return null;
    }

    const absoluteChange = currentPrice - prevPrice;

    return roundValue
        ? round(absoluteChange, roundValue)
        : absoluteChange;
}

export const calculateRelativeChange = (currentPrice: number | null, prevPrice: number | null) => {
    if (!isValueExist(currentPrice) || !isValueExist(prevPrice)) {
        return null;
    }

    return round((currentPrice - prevPrice) / (prevPrice ?? 1) * 100, 2);
}

export const getBooleanValue = (value: boolean | null | undefined | string | number) => {
    if (isValueExist(value)) {
        return Boolean(+value);
    }

    return null;
};

export const getNearestDataByDate = <T extends object>(data: T[], dateKey: keyof T): T | null => {
    try {
            let nearestDataIndex = 0
            let minDeltaInDays = Infinity

            for (let i = 0; i < data.length; i++) {
                const dataObjDate = data[i][dateKey];

                if (dataObjDate && typeof dataObjDate === 'string') {
                    const deltaInDays = Math.abs(
                        differenceInDays(
                            new Date(dataObjDate as string),
                            new Date(),
                        ),
                    )

                    if (deltaInDays < minDeltaInDays) {
                        minDeltaInDays = deltaInDays;
                        nearestDataIndex = i;
                    }
                }
            }

            return data[nearestDataIndex] ?? null;
    } catch (e) {
        console.error('e', e);

        return data[0] ?? null;
    }
}
