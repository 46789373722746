import { Box, Text } from '@mantine/core';
import { type FC } from 'react';

type Props = {
    performanceType: string;
};

export const CustomYLabel: FC<Props> = ({ performanceType }) => (
    <foreignObject
width="48" height="100%" x={0}
y={150}>
        <Box
            style={{
                transform: 'rotate(180deg)',
                backgroundColor: 'rgba(215, 230, 245, 0.20)',
                borderRadius: 6,
                padding: '24px 8px',
                color: '#8A96A6',
                writingMode: 'vertical-lr',
                textAlign: 'center',
            }}
        >
            <Text
ff="Inter" fz={13} fw={500}
lh="20px">
                {performanceType === 'STRESS_TEST_MODEL' ? 'Ожидаемый доход (5 лет)' : 'Годовая доходность'}
            </Text>
        </Box>
    </foreignObject>
);
