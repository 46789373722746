
export const HEAT_COLORS = [
    { color: '#A5B1C0', rightBound: 0, leftBound: 0, rightBoundInclude: true },
    { color: '#F2433D', leftBound: 0, rightBound: 75, rightBoundInclude: false, leftBoundInclude: false },
    { color: '#FF7D79', leftBound: 75, leftBoundInclude: true, rightBound: 90, rightBoundInclude: false },
    { color: '#FF9794', leftBound: 90, leftBoundInclude: true, rightBound: 97, rightBoundInclude: false },
    { color: '#FAB3B3', leftBound: 97, leftBoundInclude: true, rightBound: 99.5, rightBoundInclude: false },
    { color: '#42A62E', leftBound: 99.5, leftBoundInclude: true, rightBoundInclude: false },
]

export const CURRENT_DATE = new Date();
