/* eslint-disable react/prop-types */
import { type FC } from 'react';
import { type ScatterProps } from 'recharts';

type Props = {
    props: ScatterProps;
    dotColor: string;
};

export const CustomizedShape: FC<Props> = ({ props, dotColor }) => {
    if (props.name === 'Мой портфель') {
        return (
            <g transform={`translate(${Number(props.cx)},${Number(props.cy)})`}>
                <circle
r="5" fill={dotColor} stroke="none"
fillOpacity="1" strokeWidth="1" />
            </g>
        );
    }

    return (
        <g transform={`translate(${Number(props.cx) - 6},${Number(props.cy) - 6})`}>
            <svg
xmlns="http://www.w3.org/2000/svg" width="12" height="12"
viewBox="0 0 12 12" fill="none">
                <path
d="M1 1L11 11M1 11L11 1" stroke="#575E66" strokeWidth="1.8"
strokeLinecap="round" />
            </svg>
        </g>
    );
};
