import { SecurityMarketType } from '@libs/types';
import { type PeriodId } from '@libs/types/instrument.type';
import { type Period, useChoosePeriod } from '@libs/utils/hooks/useChoosePeriod';
import { INTERVAL_OPTIONS } from '@modules/Investorpro/modules/BondStockPage/StockPage/components/Tabs/components/ChartTab/components/Candle/constants';
import { LinearChartWithBars } from '@modules/Investorpro/modules/BondStockPage/shared/components/LinearChartWithBars';
import { InstrumentEngineType } from '@modules/Investorpro/types/quote.type';
import { getInstrumentProfitCurvesDataThunk, useAppDispatch, useAppSelector } from '@store/store';
import {
    setTradeResultCurvesLoadedStatus,
    setTradesResultCurvesPeriod,
    setTradesResultsCurvesPeriodOption,
} from '@store/store/slices/investorpro/bond.slice';
import { useEffect } from 'react';

import styles from './styles.module.scss';

export const TradesResultsChart = () => {
    const {
        secId,
        board,
        tradeResultsCurves: tradeResults,
        loadings,
        info: { updatedAt },
    } = useAppSelector((state) => state.bond);
    const { loaded, volData, priceData, maxVol, period, currentPeriodOption } = tradeResults;
    const dispatch = useAppDispatch();

    const { options, handleOptionChange } = useChoosePeriod({
        handleOptionChange: (option: string | null) => dispatch(setTradesResultsCurvesPeriodOption(option)),
        handleChangePeriod: (period: Period) => dispatch(setTradesResultCurvesPeriod(period)),
        intervalTypes: INTERVAL_OPTIONS,
        endDate: updatedAt ? new Date(updatedAt) : undefined,
    });

    useEffect(() => {
        if (!loaded && secId && updatedAt && board) {
            dispatch(
                getInstrumentProfitCurvesDataThunk({
                    secId,
                    intervalType: currentPeriodOption as PeriodId,
                    interval: period.interval,
                    from: period.from,
                    till: period.to,
                    engine: InstrumentEngineType.STOCK,
                    market: SecurityMarketType.BOND,
                    board,
                    size: 75,
                }),
            );
        }
    }, [dispatch, loaded, currentPeriodOption, secId, updatedAt, board]);

    const handlePeriodChange = (value: string | null) => {
        handleOptionChange(value);
        dispatch(setTradeResultCurvesLoadedStatus(false));
    };

    return (
        <div className={styles.tradesResultsChart}>
            <LinearChartWithBars
                barsData={volData}
                maxVol={maxVol}
                width={1270}
                height={480}
                loading={loadings.getBondProfitCurves}
                measure="%"
                lineData={priceData}
                intervalType={period.interval}
                handlePeriodOptionChange={handlePeriodChange}
                currentPeriodOption={currentPeriodOption}
                periodOptions={options}
            />
        </div>
    );
};
