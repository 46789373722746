import { useAuthorize } from '@libs/utils';
import { Permission, usePermission } from '@libs/utils/hooks/usePermission';
import { useLocation, useSearchParams, Navigate } from 'react-router-dom';
import { IS_INVESTORPRO_REDIRECT_PARAMETER } from '@libs/utils/hooks/useAuthorize';

import { HasNotPermissionsLabel } from './components/HasNotPermissionsLabel';
import { DEFAULT_AUTHORIZED_ROOT_PAGE, DEFAULT_NOT_AUTHORIZED_ROOT_PAGE, ROOT_PATH } from '../../constants';

type Props = {
    element: JSX.Element;
    permission?: Permission;
};

export const ProtectedRoute = ({ element, permission = Permission.ALL }: Props) => {
    const { pathname } = useLocation();
    const { isAuthorized } = useAuthorize();
    const [searchParams] = useSearchParams();

    const { getPermissionByKey, isPermissionsInitialized } = usePermission();

    if (!isPermissionsInitialized) {
        return null;
    }

    const isOpenedNotAuthorizedRootPage = DEFAULT_NOT_AUTHORIZED_ROOT_PAGE === pathname;
    const isOpenedAuthorizedRootPage = DEFAULT_AUTHORIZED_ROOT_PAGE === pathname;
    const isOpenedAPPRootPage = pathname === ROOT_PATH;
    const hasPermissionForSection = getPermissionByKey(permission);
    const isAuthorizingInProcess = searchParams.get(IS_INVESTORPRO_REDIRECT_PARAMETER);

    if (!hasPermissionForSection && (isOpenedNotAuthorizedRootPage || isOpenedAuthorizedRootPage)) {
        return <HasNotPermissionsLabel />;
    }

    if (isAuthorized && isOpenedAPPRootPage) {
        return <Navigate to={DEFAULT_AUTHORIZED_ROOT_PAGE} />;
    }

    if ((!isAuthorized && !isAuthorizingInProcess) || !hasPermissionForSection) {
        return <Navigate to={DEFAULT_NOT_AUTHORIZED_ROOT_PAGE} />;
    }

    return element;
};
