import { Group, Stack, Text } from '@mantine/core';
import { type UseQueryResult } from '@tanstack/react-query';
import { type FC } from 'react';

import { type PortFolioCost } from '../../../../entities/portfolio';
import { ChartLegend } from '../../../../shared/ChartLegend/ChartLegend';
import { PercentRowCard } from '../../../../shared/PercentRowCard/PercentRowCard';
import { CoreMetricsChart } from '../../GeneralInfo/CoreMetricsChart';
import { InfoIcon } from '../ReportIcons/InfoIcon';

type Props = {
    portfolioCostQuery: UseQueryResult<PortFolioCost, Error>;
    coreMetricsQuery: UseQueryResult<
        Array<{
            name: string;
            value: number;
            color: string;
        }>,
        Error
    >;
    geoDiversification: Array<{
        name: string;
        value: number;
        color: string;
    }>;
    sectoralDiversification: Array<{
        percent: number;
        label: string;
        Component?: FC;
    }>;
};

export const ReportDiversification: FC<Props> = ({
    portfolioCostQuery,
    coreMetricsQuery,
    geoDiversification,
    sectoralDiversification,
}) => (
    <Stack gap={33} w={1350} p={5}>
        <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
            <Group align="center" justify="space-between">
                <Text
fz="45px" fw={400} lh="47px"
style={{ whiteSpace: 'pre-wrap' }}>
                    {'Диверсификация '}
                </Text>
                <Group
                    gap={12}
                    miw={180}
                    mih={24}
                    px={16}
                    align="center"
                    py={8}
                    style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                >
                    <InfoIcon />
                    <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                        {'Информация'}
                    </Text>
                </Group>
            </Group>
            <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px" style={{ whiteSpace: 'pre-wrap' }}>
                {
                    'В разделе Диверсификация вы можете оценить, насколько равномерно инструменты \
в портфеле распределены по классам активов, регионам и секторам экономики.'
                }
            </Text>
        </Stack>
        <Group wrap="nowrap" justify="space-between">
            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400}>
                    {'Основные метрики'}
                </Text>
                <Group gap={40}>
                    <CoreMetricsChart
                        isInReport={true}
                        portfolioCostQuery={portfolioCostQuery}
                        coreMetricsQuery={coreMetricsQuery}
                    />
                </Group>
            </Stack>

            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400}>
                    {'Географическая диверсификация'}
                </Text>
                <ChartLegend data={geoDiversification ?? []} />
            </Stack>
        </Group>

        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400}>
                {'Секторальная диверсификация'}
            </Text>
            <Group gap="12px 24px" w="100%">
                {sectoralDiversification.map(({ percent, label, Component }) => (
                    <PercentRowCard
key={label} percent={percent} label={label}
IconComponent={Component} />
                ))}
            </Group>
        </Stack>
    </Stack>
);
