import { Stack, Text } from '@mantine/core';
import { type DateValue } from '@mantine/dates';
import { type FC } from 'react';

import { type ScenarioIndicatorType } from '../../../../entities';
import { ViewError } from '../../../../shared/Layout';
import { Table } from '../../../../shared/Table';
import { useScenarioIndicatorColumns } from './useScenarioIndicatorColumns';

type Props = {
    scenarioIndicator: ScenarioIndicatorType[];
    error?: Error | null;
    isError?: boolean;
    isRefetching?: boolean;
    isLoading?: boolean;
    dates: [DateValue, DateValue];
    isFromReport?: boolean;
};

export const ScenarioIndicatorTable: FC<Props> = ({
    scenarioIndicator,
    error,
    isError,
    isRefetching,
    isLoading,
    dates,
    isFromReport,
}) => {
    const columns = useScenarioIndicatorColumns(dates);

    if (isError) {
        return (
            <Stack>
                <Text fz={19} fw={400}>
                    {'Показатели сценария'}
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по показателям сценария" />
            </Stack>
        );
    }

    return (
        <Stack miw={500}>
            {!isFromReport
? (
                <Text fz={19} fw={400}>
                    {'Показатели сценария'}
                </Text>
            )
: null}
            <Table
                columns={columns}
                data={scenarioIndicator || []}
                striped={true}
                highlightOnHover={true}
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                isDataLoading={isLoading || isRefetching}
            />
        </Stack>
    );
};
