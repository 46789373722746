import { Button, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { type FC, Fragment } from 'react';

import { Download, Eye, Settings } from '../../../shared/Icon';
import { type ReportFormFinalValues, type ReportFormTitleValues } from './ReportForm.schema';
import { ReportSettingsModal } from './ReportSettingsModal';

type Props = {
    finalValues: ReportFormFinalValues;
    isAllScreensReady: boolean;
    onFinalValuesChange: (values: ReportFormFinalValues) => void;
    onFullReportSave: () => void;
    onFullReportShow: () => void;
    onTitleValuesChange: (values: ReportFormTitleValues) => void;
    titleValues: ReportFormTitleValues;
    isDownloadReportAvailable: boolean;
};

export const ControlButtons: FC<Props> = ({
    finalValues,
    isAllScreensReady,
    onFinalValuesChange,
    onFullReportSave,
    onFullReportShow,
    onTitleValuesChange,
    titleValues,
    isDownloadReportAvailable,
}) => {
    const [reportSettingsModalOpened, { open: onReportSettingsModalOpen, close: onReportSettingsModalClose }] =
        useDisclosure(false);

    return (
        <Fragment>
            <Group gap={6}>
                <Button
                    onClick={onReportSettingsModalOpen}
                    variant="outline"
                    leftSection={<Settings color="#2F9CEB" height={24} width={24} />}
                >
                    {'Титульный лист'}
                </Button>
                <Button
                    disabled={!isDownloadReportAvailable}
                    variant="outline"
                    leftSection={<Eye color="#2F9CEB" height={24} width={24} />}
                    onClick={onFullReportShow}
                    loading={!isAllScreensReady}
                >
                    {'Предпросмотр'}
                </Button>
                <Button
                    disabled={!isDownloadReportAvailable}
                    variant="primary"
                    leftSection={<Download color="#FFFFFF" height={24} width={24} />}
                    onClick={onFullReportSave}
                    loading={!isAllScreensReady}
                >
                    {'Сохранить'}
                </Button>
            </Group>

            <ReportSettingsModal
                finalValues={finalValues}
                onClose={onReportSettingsModalClose}
                onFinalValuesChange={onFinalValuesChange}
                onTitleValuesChange={onTitleValuesChange}
                opened={reportSettingsModalOpened}
                titleValues={titleValues}
            />
        </Fragment>
    );
};
