import { type CSSVariablesResolver, MantineProvider } from '@mantine/core';
import { type FC, type ReactNode } from 'react';
import { Notifications } from '@mantine/notifications';

import { theme } from '../config';

type Props = {
    children: ReactNode;
};

const resolver: CSSVariablesResolver = () => ({
    variables: {
        '--mantine-font-size-sm': '14px',
        '--mantine-primary-color-filled': '#2F9CEB',
        '--footer-height': '215px',
    },
    light: {
        '--mantine-font-size-sm': '14px',
        '--mantine-primary-color-filled': '#2F9CEB',
    },
    dark: {
        '--mantine-font-size-sm': '14px',
        '--mantine-primary-color-filled': '#2F9CEB',
    },
});

export const WithMantine: FC<Props> = ({ children }) => (
    <MantineProvider theme={theme} cssVariablesResolver={resolver}>
        <Notifications />
        {children}
    </MantineProvider>
);
