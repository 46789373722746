import { useState } from 'react';
import classNames from 'classnames';
import { HelpLineWrapper } from '@libs/components';

import styles from './styles.module.scss';
import { SecurityPriceChangesChart } from '../../../shared/components/ChangesChart';
import { SecurityIndexes } from '../../../shared/components/SecurityIndexes';
import { TopBonds } from './components/TopBonds';
import { useSelectSecurity } from '../../../shared/utils/useSelectSecurity';
import { BONDS_INDEXES } from './constants';

export const BondMarketChartBlock = () => {
    const [isCharLoading, setIsChartLoading] = useState(false);
    const { selectSecurity, selectedSecurities, isMaxSelected } = useSelectSecurity();

    return (
        <div className={styles.bondsMarketWrapper}>
            <h1>Рынок облигаций</h1>
            <div className={classNames('flex', 'justify-content-between', styles.dataContainer)}>
                <SecurityPriceChangesChart
                    selectSecurity={selectSecurity}
                    width={1145}
                    selectedSecurities={selectedSecurities}
                    isLoading={isCharLoading}
                    setIsLoading={setIsChartLoading}
                />
                <HelpLineWrapper message="helpline.marketDashboards.displayOnGraph">
                    <div className={classNames(styles.bondIndexesWrapper, 'flex', 'flex-column')}>
                        <SecurityIndexes
                            title="Индексы облигаций"
                            selectedIndexes={selectedSecurities}
                            indexes={BONDS_INDEXES}
                            defaultActiveIndex={0}
                            columnsWidth={['140px', '118px', '167px', '112px']}
                            handleSelectIndexes={selectSecurity}
                            isMaxSelected={isMaxSelected}
                            disabled={isCharLoading}
                        />
                        <TopBonds
                            selectedBonds={selectedSecurities}
                            handleSelectBond={selectSecurity}
                            isMaxSelected={isMaxSelected}
                            disabled={isCharLoading}
                        />
                    </div>
                </HelpLineWrapper>
            </div>
        </div>
    );
};
