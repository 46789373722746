import { Box, Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import { ViewError, ViewLoader } from '../../../shared/Layout';
import { PercentRowCard } from '../../../shared/PercentRowCard';
import { useGetSectoralDiversificationQuery } from './useGetSectoralDiversificationQuery';

type Props = {
    id: string;
};

export const SectoralDiversification: FC<Props> = ({ id }) => {
    const { data, isError, error, isPending } = useGetSectoralDiversificationQuery(id);

    if (isError) {
        return (
            <Stack>
                <Text fz={19} fw={400}>
                    {'Секторальная диверсификация'}
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по секторальной диверсификации" />
            </Stack>
        );
    }

    if (isPending) {
        return (
            <Box>
                <Text fz={19} fw={400}>
                    {'Секторальная диверсификация'}
                </Text>
                <ViewLoader />
            </Box>
        );
    }

    return (
        <Stack gap={25}>
            <Text fz={19} fw={400} lh="18px">
                {'Секторальная диверсификация'}
            </Text>
            <Group gap="6px 32px">
                {data.map(({ percent, label, Component }) => (
                    <PercentRowCard
key={label} percent={percent} label={label}
IconComponent={Component} />
                ))}
            </Group>
        </Stack>
    );
};
