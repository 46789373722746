import { IntervalType, PeriodId } from '@libs/types/instrument.type';


export const INTERVAL_OPTIONS: Partial<Record<PeriodId, IntervalType>> = {
    [PeriodId.ONE_DAY]: IntervalType.ONE_MINUTE,
    [PeriodId.FIVE_DAYS]: IntervalType.ONE_HOUR,
    [PeriodId.ONE_MONTH]: IntervalType.ONE_HOUR,
    [PeriodId.THREE_MONTHS]: IntervalType.ONE_DAY,
    [PeriodId.SIX_MONTHS]: IntervalType.ONE_DAY,
    [PeriodId.ONE_YEAR]: IntervalType.ONE_DAY,
    [PeriodId.SNG]: IntervalType.ONE_DAY,
    [PeriodId.FIVE_YEARS]: IntervalType.ONE_DAY,
}
