import { Box, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import { GradientScale } from '../../../shared/GradientScale';
import { ViewError, ViewLoader } from '../../../shared/Layout';
import { useGetLiquidityQuery } from './useGetLiquidityQuery';

type Props = {
    portfolioId: string;
};

export const PortfolioLiquidity: FC<Props> = ({ portfolioId }) => {
    const { data, isPending, error, isError } = useGetLiquidityQuery(portfolioId);

    if (isPending) {
        return (
            <Box>
                <Text fz={19} fw={400}>
                    {'Ликвидность портфеля'}
                </Text>
                <ViewLoader />
            </Box>
        );
    }

    if (isError) {
        return (
            <Stack>
                <Text fz={19} fw={400} lh="18px">
                    <Text fz={19} lh="22px" fw={400}>
                        {'Ликвидность портфеля'}
                    </Text>
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по ликвидности портфеля" />
            </Stack>
        );
    }

    return (
        <Stack gap={24}>
            <Text fz={19} lh="22px" fw={400}>
                {'Ликвидность портфеля'}
            </Text>
            <GradientScale
                rightValue={0}
                leftValue={1}
                value={data?.value ?? 0}
                labels={['Ликвидный', 'Неликвидный']}
            />
        </Stack>
    );
};
