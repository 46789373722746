import classNames from 'classnames';

import styles from './styles.module.scss';

export const LoaderFlashing = () => {
    return (
        <div className={classNames('flex justify-content-center align-items-center', styles['loader'])}>
            <div className={styles['dots']} />
        </div>
    );
};
