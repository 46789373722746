export const MIN_SEARCH_VALUE_LENGTH = 1;

export const tabViewlPT = {
    navContent: {
        style: {
            borderColor: 'red',
        },
    },
    navContainer: {
        style: {
            padding: '24px 24px 0px 24px',
        },
    },
    inkbar: {
        style: {
            background: 'red',
            borderColor: 'red',
        },
    },
    panelContainer: {
        style: {
            height: '100%',
        },
    },
    // root: {
    //     style: {
    //         height: '100%',
    //     },
    // },
};

export const tabPanelPT = {
    headerTitle: { style: { borderColor: 'red', padding: '10px' } },
};
