import { Button } from 'primereact/button';
import classNames from 'classnames';

import { ReactComponent as EditIcon } from '../../../../shared/images/svg/Edit.svg';
import styles from './styles.module.scss';

type Props = {
    onNewScreener: () => void;
};

export const Empty = ({ onNewScreener }: Props) => {
    return (
        <div className={classNames(styles.wrapper, 'flex flex-column align-items-center justify-content-center ')}>
            <div className={styles.title}>Создайте свой первый скринер</div>
            <div className={styles.subtitle}>
                Сканируйте более 100 000 ценных бумаг по всему миру, используя 6000 критериев поиска.
            </div>
            <Button
                onClick={() => onNewScreener()}
                className={classNames(styles.addButton, 'flex justify-content-center')}
            >
                <EditIcon />
                Создать скринер
            </Button>
        </div>
    );
};
