import { ActionIcon, Group, Stack, Text, Tooltip } from '@mantine/core';
import { type FC } from 'react';

import { type TickerDictionaryItem } from '../../entities';
import { Check, Plus } from '../../shared/Icon';
import styles from './ToolItem.module.css';

type Props = {
    isChecked: boolean;
    tool: TickerDictionaryItem;
    tgl: (checked: boolean) => void;
};

export const ToolItem: FC<Props> = ({ tool, isChecked, tgl }) => {
    const handleChange = () => {
        tgl(!isChecked);
    };

    return (
        <Group
            onClick={handleChange}
            mr={36}
            pb={13}
            pl={4}
            align="center"
            justify="space-between"
            style={{ borderBottom: '1px solid #EBECF0' }}
            className={styles.tool}
        >
            <Stack gap={0} w={255}>
                <Tooltip label={tool?.name} position="top-start" zIndex={501}>
                    <Text
style={{ cursor: 'pointer' }} fz={14} fw={400}
mb={-2} lh="20px" c="#14171F"
truncate={true}>
                        {tool?.name}
                    </Text>
                </Tooltip>
                <Text
fz={12} fw={400} lh="20px"
c="#8A96A6">
                    {tool?.ticker}
                </Text>
            </Stack>
            <ActionIcon variant="gray" size={24} onClick={handleChange}>
                {isChecked ? <Check width={24} height={24} color="#2F9CEB" /> : <Plus width={24} height={24} />}
            </ActionIcon>
        </Group>
    );
};
