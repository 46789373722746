import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const List: FC<Props> = ({ width = 24, height = 24, color = 'currentColor' }) => (
    <svg
width={width} height={height} viewBox="0 0 24 24"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
d="M10 7H19M5 7H6.5" stroke={color} strokeWidth="1.8"
strokeLinecap="round" />
        <path
d="M10 12H19M5 12H6.5" stroke={color} strokeWidth="1.8"
strokeLinecap="round" />
        <path
d="M10 17H19M5 17H6.5" stroke={color} strokeWidth="1.8"
strokeLinecap="round" />
    </svg>
);
