/* eslint-disable react/prop-types */
import { Box, Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { type FC, useMemo } from 'react';
import { type CashFlowType } from '@modules/Investorpro/modules/PortfolioPage/entities';
import dayjs from 'dayjs';

import { Table } from '../../../shared/Table';
import { ViewLoader } from '../../../shared/Layout';

const columnHelper = createColumnHelper<CashFlowType>();

type Props = {
    data: CashFlowType[];
    isPending?: boolean;
};

export const CashFlowTable: FC<Props> = ({ data, isPending }) => {
    const columns = useMemo(
        () => [
            columnHelper.accessor('year', {
                enableSorting: false,
                id: 'year',
                header: 'Год',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'start' }}>
                        {props.getValue() ?? ''} {dayjs().isSame(dayjs(props.getValue()), 'year') ? '(текущая)' : ''}
                    </Text>
                ),
            }),

            columnHelper.accessor('annualYield', {
                enableSorting: false,
                id: 'annualYield',
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                header: 'Годовая доходность',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'end' }}>
                        {typeof props.getValue() === 'number' ? `${props.getValue()}%` : '–'}
                    </Text>
                ),
            }),

            columnHelper.accessor('cashFlow', {
                enableSorting: false,
                id: 'cashFlow',
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                header: 'Денежный поток',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'end' }}>
                        {props.getValue().toLocaleString('ru') ?? ''}
                    </Text>
                ),
            }),
        ],
        [],
    );

    if (isPending) {
        return <ViewLoader />;
    }

    return (
        <Box w="100%">
            <Table
columns={columns} data={data ?? []} striped={true}
highlightOnHover={true} />
        </Box>
    );
};
