import { type AxiosResponse } from 'axios';

import { type PageableResponse, type ResponseInfo, type PageableType } from '.';
import { type ServerBondIndustry, type SecurityMarketType, type StockDividend } from './bondsmarket.type';
import { SecurityBoards } from '@modules/Investorpro/modules/MarketPages/shared/constants';

export enum ShareIndexes {
    TOP_100 = 'MOEXBMI',
    MOEX = 'IMOEX',
}

export type CapitalizationInstrumentData = {
    secId: string;
    shortName: string;
    boardId: string;
    isin: string;
    issuersector: string;
    capitalization: number;
    group: string;
    close: number | null;
};

export type NotNullable<T extends object> = {
    [P in keyof T]: Exclude<T[P], null | undefined>;
};

export type GetSharesRequestResponse = AxiosResponse<{
    shares: CapitalizationInstrumentData[];
}>;

export type GetSharesIndexesRequestResponse = AxiosResponse<{
    shares: CapitalizationInstrumentData[];
}>;

export type GetBondsRequestResponse = AxiosResponse<{
    bonds: CapitalizationInstrumentData[];
}>;

export type GetIndexForInstrumentRequestBody = {
    fintoolId?: number | string;
    fininstId?: number | string;
    toolSelection: SecurityMarketType;
    pageSize?: number;
    pageNumber?: number;
};

export type GetIndexForInstrumentResponse = AxiosResponse<
    Array<{
        secId: string;
        shortName: string;
        title: string;
        shortTitle: string;
        fintoolId: 0;
        fininstId: 0;
    }>
>;

export type ServerBondsParamsData = {
    bonds: Array<{
        isin: string;
        name: string;
        issuersector: string;
        mat_date: string;
        security_type: ServerBondIndustry;
    }>;
};

export type GetBondsParamsRequestResponse = Array<{
    isin: string;
    name: string;
    issuersector: string;
    matDate: string;
    securityType: ServerBondIndustry;
}>;

export enum CurrencyCode {
    CNY = 'CNY',
    USD = 'USD',
    EUR = 'EUR',
}

export enum IntervalType {
    ONE_MINUTE = 1,
    ONE_HOUR = 60,
    ONE_DAY = 24,
}

export type ServerBondAuctionData = Array<{
    id_fintool: number;
    begdist_date: string;
    ask_val: number;
}>;

export type GetBondsAuctionReqBody = PageableReqBody<keyof ServerBondAuctionData[number]>;

export type GetBondsAmortisationsReqBody = {
    filter?: string;
    flagFilter?: boolean;
} & PageableReqBody<keyof ServerBondsAmortisationData[number]>;

export type GetBondsCouponsReqBody = PageableReqBody<keyof ServerBondsCouponsData[number]>;

export type GetBondsOffersReqBody = PageableReqBody<keyof ServerBondsOfferData[number]>;

export type GetBondsAuctionResponse = Array<{
    idFintool: number;
    begdistDate: string;
    askVal: number;
}>;

export type ServerBondsAmortisationData = Array<{
    id_fintool: number;
    mty_date: string;
    pay_per_bond: number;
    pay_per_market: number;
    mty_type: string;
}>;

export type GetBondsAmortisationsResponse = Array<{
    idFintool: number;
    mtyDate: string;
    payPerBond: number;
    payPerMarket: number;
    mtyType: string;
}>;

export type PageableReqBody<T = string> = {
    id?: number | string;
    pageSize?: number;
    pageNumber?: number;
    sortFields?: T;
    sortingDirection?: SortingDirections;
};

export type ServerBondsOfferData = Array<{
    id_fintool: number;
    offer_date: string;
    buy_back_price: number;
    buy_back_vol: number;
}>;

export type GetBondsOffersResponse = Array<{
    idFintool: number;
    offerDate: string;
    buyBackPrice: number;
    buyBackVol: number;
}>;

export type GetInstrumentPriceResponse = AxiosResponse<{
    candles: {
        columns: string[];
        data: unknown[][];
    };
}>;

export type GetInstrumentVolumeReqBody = {
    instrument: 'boards' | 'shares';
    boardgroups: '58' | '57';
    pageSize?: number;
    pageNumber?: number;
};

export type GetTradeDayResultsResponse = AxiosResponse<{
    shares_bidding: Array<{
        tradeDate: string;
        shortName: string;
        secId: string;
        boardId: string;
        volume: number;
        trades: number;
        open: number | null;
        high: number | null;
        low: number | null;
        waPrice: number;
        last: number | null;
        issueSize: number;
    }>;
}>;

export type GetKeyRateRequestResponse = AxiosResponse<
    Array<{
        date: string;
        rate: number;
    }>
>;

export type GetCurrencyRateRequestResponse = AxiosResponse<
    Array<{
        tradeDate: string;
        vcurs: string;
        vchCode: CurrencyCode;
    }>
>;

export type ServerBondsCouponsData = Array<{
    id_fintool: number;
    coupon_rate: number;
    pay_per_bond: number;
    pay_date: string;
}>;

export type GetBondsCouponsResponse = Array<{
    idFintool: number;
    couponRate: number;
    payPerBond: number;
    payDate: string;
}>;

export type GetInstrumentNameResponse = AxiosResponse<
    Array<{
        fintoolid: number;
        isincode: string;
        nickname: string;
        regcode: string;
        sumissueval: number;
    }>
>;

export type GetStockDividendsRequestResponse = AxiosResponse<StockDividend[]>;

export type GetTopBondsByVolumeRequestResponse = AxiosResponse<{
    bondsBidding: Array<{
        tradeDate: string;
        shortName: string;
        secId: string;
        boardId: string;
        issuerName: string;
        duration: number;
        faceUnit: string;
        yieldClose: number;
        couponPrecent: number;
        volume: number;
        closePrecent: number;
        last: number;
        endMtyDate: string;
        issuesize: number;
    }>;
}>;

export type GetStockDataRequestResponse = AxiosResponse<{
    share: Array<{
        tradeDate: string;
        shortName: string;
        secId: string;
        group: string;
        regOrg?: string;
        issuersector: string;
        capitalization: number;
        url?: string;
        value?: number;
        boardId: SecurityBoards;
        country?: string;
        isin: string;
        fintoolId: number;
        fininstId: number;
    }>;
}>;

export type GetStockCompanyDescriptionResponse = AxiosResponse<
    Array<{
        description: string;
        finistId: number;
    }>
>;

export type GetStockCompanyFoundDateResponse = AxiosResponse<
    Array<{
        primaryRegDate: string;
        updateDate: string;
        finistId: number;
    }>
>;

export type GetStockNearestReportDateResponse = AxiosResponse<{
    eventDate: string;
    nameRus: string;
}>;

export type GetStockCompanyProfitResponse = AxiosResponse<
    Array<{
        revenue: string;
        netIncome: string;
        power: string;
    }>
>;

export type GetStockEvEbitdaResponse = AxiosResponse<
    Array<{
        evEbitda: number | null;
        ebitda: number | null;
        fininstId: number;
    }>
>;

export type GetStockPEResponse = AxiosResponse<{
    pe: number;
}>;

export type GetStockDividendsGapRequestResponse = AxiosResponse<{
    gap: number;
}>;

export type ServerDividendData = {
    fintoolId: number;
    periodShortName: string;
    periodShortNameInt: number;
    declaredPayDate: string;
    listDate: string;
    payStock: string;
    recomendDate: string;
    dy: string | null;
    gapLength: string | null;
    gap_dividend: string | null;
};

export type DividendData = Omit<ServerDividendData, 'gap_dividend' | 'payStock' | 'dy' | 'gapLength'> & {
    gap: number | null;
    payStock: number;
    dy: number | null;
    gapLength: number | null;
};

export type GetStockDividendsDataRequestServerResponse = ResponseInfo<
    Omit<PageableResponse<ServerDividendData>, 'size'> & { pageSize: number }
>;

export type GetStockDividendsDataRequestResponse = ResponseInfo<
    Omit<PageableResponse<DividendData>, 'size'> & { pageSize: number }
>;

export enum SortingDirections {
    DESC = 'desc',
    ASC = 'asc',
}

export type GetStockDividendsDataRequestBody = {
    fintoolId?: number;
} & PageableReqBody<keyof GetStockDividendsDataRequestServerResponse['data']['rows'][number]>;

export type GetStockEventsRequestBody = {
    fininstId?: number;
    dateFrom?: Date;
    filter?: any;
    filterDecl?: any;
    dateTo?: Date;
} & Partial<Pick<PageableType, 'pageNumber' | 'pageSize'>>;

export type GetStockEventsRequestResponse = AxiosResponse<
    Array<{
        eventDate: string;
        nameRus: string;
        fininstId: string;
        fullnameRus: string;
        eventDescr: string;
    }>
>;

export type GetCompanyLogoResponse = AxiosResponse<
    Array<{
        fininstId: string;
        link: string | null;
    }>
>;

export type GetInstrumentLiquidityRequestBody = {
    isin: string[];
    signal?: AbortSignal;
};

type Liquidity = {
    liq100k: number;
    liq1000k: number;
    liq5000k: number;
    liq10000k: number;
    liq50000k: number;
    liq100000k: number;
};

export type GetInstrumentLiquidityResponse = AxiosResponse<
    Array<{
        isin: string | null;
        buy: Liquidity | null;
        sell: Liquidity | null;
    }>
>;

export enum ProfitPeriod {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
    YTD = 'YTD',
}

export type GetInstrumentProfitResponse = Array<{
    isin: string;
    profit: number | null;
}>;

export type GetInstrumentProfitRequestBody = {
    isin: string[];
    period: ProfitPeriod;
    signal?: AbortSignal;
};

export enum PeriodId {
    ONE_DAY = '1d',
    FIVE_DAYS = '5d',
    ONE_MONTH = '1m',
    THREE_MONTHS = '3m',
    SIX_MONTHS = '6m',
    TWO_YEARS = '2y',
    ONE_YEAR = '1y',
    SNG = 'SNG',
    THREE_YEARS = '3y',
    FIVE_YEARS = '5y',
    TEN_YEARS = '10y',
    ALL = 'all',
}
