import { type ObjectKeyColumnRow } from '../../utils/types';
import styles from './styles.module.scss';
import { useTableData } from '../../utils/useTableData';
import { TWO_TABLES_COLUMNS } from './columns';
import { Table } from './components/Table';

type Props = {
    data: object | null;
    leftRows: Array<ObjectKeyColumnRow<any>>;
    rightRows: Array<ObjectKeyColumnRow<any>>;
};

export const TwoTables = ({ data, leftRows: leftColumns, rightRows: rightColumns }: Props) => {
    const leftTableTransformedData = useTableData(data, leftColumns);
    const rightTableTransformedData = useTableData(data, rightColumns);

    return (
        <div className={styles.tablesWrapper}>
            <Table data={leftTableTransformedData} />
            <Table data={rightTableTransformedData} />
        </div>
    );
};
