import { Box } from '@mantine/core';
import { type FC } from 'react';
import { type RiskMetric } from '@modules/Investorpro/modules/PortfolioPage/entities';
import { ViewLoader } from '@modules/Investorpro/modules/PortfolioPage/shared/Layout';

import { Table } from '../../../../shared/Table';
import { useRiskMetricsColumns } from './useRiskMetricsColumns';

type Props = {
    data: RiskMetric[];
    isPending?: boolean;
};

export const RiskMetricsTable: FC<Props> = ({ data, isPending }) => {
    const columns = useRiskMetricsColumns();

    if (isPending) {
        return <ViewLoader />;
    }

    return (
        <Box w="100%">
            <Table
columns={columns} data={data} striped={true}
highlightOnHover={true} />
        </Box>
    );
};
