import html2canvas from 'html2canvas';
import { useEffect, useState } from 'react';

export const useHtmlToCanvas = () => {
    const [element, setElement] = useState<HTMLDivElement>();
    const [screen, setScreen] = useState<string>();

    useEffect(() => {
        if (element) {
            html2canvas(element, {
                width: element.scrollWidth,
                height: element.scrollHeight,
                scale: 3,
            }).then((canvas) => {
                setScreen(canvas.toDataURL('image/png'));
            }).catch((e) => {
                console.error(e);
            });
        }
    }, [element]);

    return { screen, setElement };
};
