import { Box, Group, Text } from '@mantine/core';
import { type FC, Fragment } from 'react';

type Props = {
    data: Array<{
        name: string;
        value: number;
        color: string;
    }>;
};

export const ChartLegend: FC<Props> = ({ data }) => (
    <Fragment>
        {data.map((item) => (
            <Group key={item.name} justify="space-between">
                <Group>
                    <Box
                        key={`box-${item.name}`}
                        style={{
                            borderRadius: 2,
                            width: 8,
                            height: 8,
                            backgroundColor: item.color,
                        }}
                    />
                    <Text fz={14} fw={100} c="#14171F">
                        {item.name}
                    </Text>
                </Group>
                <Text fz={15} fw={400} c="#14171F">
                    {item.value.toLocaleString('ru', {
                        maximumFractionDigits: 1,
                        minimumFractionDigits: 1,
                    })}
                    {'%'}
                </Text>
            </Group>
        ))}
    </Fragment>
);
