import { Center, Group, Text } from '@mantine/core';
import { type FC } from 'react';

type Props = {
    label: string;
    percent: number;
    IconComponent?: FC;
};

export const PercentRowCard: FC<Props> = ({ label, percent, IconComponent }) => (
    <Group
style={{ borderRadius: '10px', border: '1px solid #D8E1EB' }} gap={0} p={1}
wrap="nowrap" miw={330} w="31%">
        <Center style={{ borderRadius: '8px 0px 0px 8px', background: '#D8E1EB33' }} px={7} py={6.5}>
            {IconComponent ? <IconComponent /> : null}
        </Center>
        <Group
            style={{
                background: `linear-gradient(to left, #2F9CEB33 ${percent}%, transparent ${percent}%)`,
                borderRadius: '0 10px 10px 0',
            }}
            justify="space-between"
            align="center"
            p={14}
            wrap="nowrap"
            w="100%"
        >
            <Text fz={13} lh="15px" fw={300}>
                {label}
            </Text>
            <Text
fw={500} fz={13} lh="15px"
ta="right">
                {percent.toFixed(2).toString().replace(/\./g, ',')}
                {'%'}
            </Text>
        </Group>
    </Group>
);
