import { Box, Image, Text } from '@mantine/core';
import { type FileRejection } from '@mantine/dropzone';
import { type FC } from 'react';

type Props = {
    rejectedFiles: FileRejection | null;
    logo: string;
};

export const DropZoneContent: FC<Props> = ({ rejectedFiles, logo }) => {
    if (logo) {
        return (<Image
mah={137} maw={479} fit="contain"
w="fit-content" key={logo || ''} src={logo || ''} />);
    }

    if (rejectedFiles?.errors[0]?.code === 'file-too-large') {
        return (
            <Box>
                <Text
ff="Inter" fz={14} fw={400}
lh="20px" c="#F2433D" style={{ whiteSpace: 'pre-wrap' }}>
                    {'Этот файл не подходит!\nВес изображения не должен превышать 300 Кб. Формат png, jpg'}
                </Text>
            </Box>
        );
    }

    if (rejectedFiles?.errors[0]?.code === 'file-invalid-type') {
        return (
            <Box>
                <Text
ff="Inter" fz={14} fw={400}
lh="20px" c="#F2433D" style={{ whiteSpace: 'pre-wrap' }}>
                    {'Файл данного формата не поддерживается'}
                </Text>
            </Box>
        );
    }

    return (
        <Box>
            <Text
ff="Inter" fz={14} fw={400}
lh="20px" c="#14171F">
                {'Перетащите логотип сюда или загрузите файл'}
            </Text>
            <Text
ff="Inter" fz={12} fw={400}
lh="16px" c="#8A96A6" mt={6}>
                {'Вес изображения не должен превышать 300 Кб. Формат png, jpg'}
            </Text>
        </Box>
    );
};
