type Matrix = Record<string, number>;

export enum TypologyAssetsStocks {
    blue = 'Голубые фишки',
    dividends = 'Дивиденды',
    growth = 'Рост',
    mixed = 'Смешанные',
    second = 'Второй эшелон',
    third = 'Третий эшелон',
}

export enum TypologyAssetsBonds {
    'long-term' = 'Долгосрочные',
    'medium-term' = 'Среднесрочные',
    'short-term' = 'Краткосрочные',
    corporate = 'Корпоративные',
    federal = 'Федеральные',
    regional = 'Региональные',
}

export enum TypologyAssetsFunds {
    active = 'Активные',
    bonds = 'Облигации',
    mixed = 'Смешанные',
    passive = 'Пассивные',
    stocks = 'Акции',
}

export type PortfolioTypologyAssetsMatrixData = {
    matrix: Matrix;
    j: string[];
    i: string[];
};

export type TopFiveByProfitability = {
    name: string;
    isin: string;
    profit: number;
};
