import { Modal } from '@mantine/core';
import { PDFViewer } from '@react-pdf/renderer';
import { type FC, type ReactElement } from 'react';

import { Close } from '../../../shared/Icon';

type Props = {
    opened: boolean;
    children: ReactElement;
    onClose: () => void;
};

export const PdfModal: FC<Props> = ({ opened, onClose, children }) => (
    <Modal.Root
opened={opened} onClose={onClose} centered={true}
size="1000px">
        <Modal.Overlay />
        <Modal.Content>
            <Modal.Header>
                <Modal.CloseButton
                    variant="transparent"
                    icon={<Close width={36} height={36} />}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '46px',
                        width: '46px',
                    }}
                />
            </Modal.Header>
            <Modal.Body>
                <PDFViewer
                    showToolbar={true}
                    style={{
                        width: '100%',
                        height: '700px',
                    }}
                >
                    {children}
                </PDFViewer>
            </Modal.Body>
        </Modal.Content>
    </Modal.Root>
);
