/* eslint-disable no-nested-ternary */
import { Text } from '@mantine/core';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { type RiskMetric } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type CellStylesProperties } from '../../../../shared/Table';

const columnHelper = createColumnHelper<RiskMetric>();

const coloredRows = [
    'Годовая доходность',
    'Максимальная историческая просадка',
    'Alpha',
    'Коэффициент Трейнора',
    'Результативность ребалансировок',
    'Положительно закрытых месяцев',
];

const getCellStyles = (props: CellContext<RiskMetric, number | null>): CellStylesProperties => ({
    textAlign: 'end',
    color:
        props.getValue() === null || !coloredRows.includes(props.row.original.name)
            ? '#14171F'
            : Number(props.getValue()) > 0
              ? '#288C14'
              : '#E42821',
});

export const useRiskMetricsColumns = () => useMemo(
        () => [
            columnHelper.accessor('name', {
                enableSorting: false,
                id: 'name',
                size: 1000000,
                header: 'Метрика',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'start' }}>
                        {props.getValue() ?? ''}
                    </Text>
                ),
            }),

            columnHelper.accessor('portfolio', {
                enableSorting: false,
                id: 'portfolio',
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                header: 'Портфель',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={() => getCellStyles(props)}>
                        {typeof props.getValue() === 'number' ? `${props.getValue()}%` : '–'}
                    </Text>
                ),
            }),

            columnHelper.accessor('benchmark', {
                enableSorting: false,
                id: 'benchmark',
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                header: 'Бенчмарк',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={() => getCellStyles(props)}>
                        {typeof props.getValue() === 'number' ? `${props.getValue()}%` : '–'}
                    </Text>
                ),
            }),
        ],
        [],
    );
