/* eslint-disable no-nested-ternary */
import { Stack, Text } from '@mantine/core';
import { type FC, Fragment } from 'react';

import { ViewEmptyStub, ViewError } from '../../../shared/Layout';
import { Table } from '../../../shared/Table';
import { useDiversificationColumns } from './useDiversificationColumns';
import { useGetLeastCorrelatingToolsQuery } from './useGetLeastCorrelatingToolsQuery';
import { useGetMostCorrelatingToolsQuery } from './useGetMostCorrelatingToolsQuery';

type Props = {
    id: string;
};

export const CorrelationToolsTables: FC<Props> = ({ id }) => {
    const {
        data: mostCorrelating,
        error: mostCorrelatingIsError,
        isPending: mostCorrelatingIsPending,
    } = useGetMostCorrelatingToolsQuery(id);

    const {
        data: leastCorrelating,
        error: leastCorrelatingIsError,
        isPending: leastCorrelatingIsPending,
    } = useGetLeastCorrelatingToolsQuery(id);

    const columns = useDiversificationColumns();

    return (
        <Fragment>
            <Stack gap={25}>
                <Text fz={19} fw={400} lh="18px">
                    {'Топ 5 наиболее коррелирующих инструментов'}
                </Text>
                {mostCorrelatingIsError && (
                    <ViewError
                        error={mostCorrelatingIsError}
                        subtitle="Ошибка получения данных по топ 5 наиболее коррелирующих инструментов"
                    />
                )}
                {!mostCorrelatingIsError && (
                    <Table
                        columns={columns}
                        data={mostCorrelating?.mostCorrelatingInstruments ?? []}
                        striped={true}
                        emptyStub={<ViewEmptyStub title={'Недостаточно данных'} />}
                        isDataLoading={mostCorrelatingIsPending}
                    />
                )}
            </Stack>
            <Stack gap={25} mt={16}>
                <Text fz={19} fw={400} lh="18px">
                    {'Топ 5 наименее коррелирующих инструментов'}
                </Text>
                {leastCorrelatingIsError && (
                    <ViewError
                        error={leastCorrelatingIsError}
                        subtitle="Ошибка получения данных по топ 5 наименее коррелирующих инструментов"
                    />
                )}
                {!leastCorrelatingIsError && (
                    <Table
                        columns={columns}
                        data={leastCorrelating?.leastCorrelatingInstruments ?? []}
                        striped={true}
                        emptyStub={<ViewEmptyStub title={'Недостаточно данных'} />}
                        isDataLoading={leastCorrelatingIsPending}
                    />
                )}
            </Stack>
        </Fragment>
    );
};
