import { useAppDispatch, useAppSelector } from '@store/store';
import { useState } from 'react';
import { type TSecurity } from '@libs/types';
import { createAlertThunk } from '@store/store/thunk/investorpro/alerts.thunk';

import { EditCreateAlertModalTemplate } from '../EditCreateAlertModalTemplate';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
};

export const CreateAlertModal = ({ isOpen, closeModal }: Props) => {
    const [security, setSecurity] = useState<TSecurity | null>(null);
    const [searchSecurityValue, setSearchSecurityValue] = useState('');
    const [price, setPrice] = useState<number | null>(null);
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector((state) => state.alerts);

    const handleCloseModal = () => {
        setSecurity(null);
        setSearchSecurityValue('');
        setPrice(null);
        closeModal();
    };

    const handleCreateAlert = async () => {
        if (security && price !== null) {
            const response = await dispatch(
                createAlertThunk({
                    ...security,
                    price,
                }),
            );

            if (response.type.endsWith('fulfilled')) {
                handleCloseModal();
                setSecurity(null);
                setSearchSecurityValue('');
                setPrice(null);
            }
        }
    };

    const cantCreateAlert = !security || price === null;

    return (
        <EditCreateAlertModalTemplate
            priceValue={price}
            searchSecuritiesValue={searchSecurityValue}
            setSecurity={setSecurity}
            setSearchValue={setSearchSecurityValue}
            setPriceValue={setPrice}
            isOpen={isOpen}
            closeModal={handleCloseModal}
            title="Создать уведомление"
            disableConfirmButton={isLoading || cantCreateAlert}
            disableDeclineButton={isLoading}
            handleAction={handleCreateAlert}
            actionButtonTitle="Создать"
        />
    );
};
