import {
    type Benchmark,
    type PageableResponse,
    type PaginationParams,
} from '../../../../PortfolioPage/entities';
import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const getDictionary: ApiFactory<
    ApiEndpoint<PageableResponse<Benchmark[]>, void, PaginationParams & { query?: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<PageableResponse<Benchmark[]>>(
        '/moex-datashop-investor-pro-portfolios-service/api/v1/benchmark/dictionary',
        { signal, params },
    );

    return data;
};
