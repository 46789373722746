import { type FC } from 'react';

export const InfoIcon: FC = () => (
    <svg
xmlns="http://www.w3.org/2000/svg" width="28" height="28"
viewBox="0 0 28 28" fill="none">
        <g clipPath="url(#clip0_56_27)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 25.6667C20.4433 25.6667 25.6667 20.4433 25.6667 14C25.6667 7.55668 20.4433
                2.33333 14 2.33333C7.55668 2.33333 2.33333 7.55668 2.33333 14C2.33333 20.4433
                7.55668 25.6667 14 25.6667ZM14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732
                0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM12.5999 11.4333L13.2998
                11.4333C14.3309 11.4332 15.1667 12.2689 15.1667 13.2999V19.8333H15.4C16.0444
                19.8333 16.5667 20.3557 16.5667 21C16.5667 21.6443 16.0444 22.1667 15.4
                22.1667H14.7C13.6691 22.1667 12.8334 21.3309 12.8334 20.3V13.7667L12.6002
                13.7667C11.9558 13.7667 11.4334 13.2445 11.4334 12.6001C11.4333 11.9558
                11.9556 11.4334 12.5999 11.4333ZM14 9.33333C14.9665 9.33333 15.75 8.54983
                15.75 7.58333C15.75 6.61683 14.9665 5.83333 14 5.83333C13.0335 5.83333
                12.25 6.61683 12.25 7.58333C12.25 8.54983 13.0335 9.33333 14 9.33333Z"
                fill="#14171F"
            />
        </g>
        <defs>
            <clipPath id="clip0_56_27">
                <rect width="28" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
