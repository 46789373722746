import { Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { type Expectations, type RiskMetric } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { ViewEmptyStub } from '../../../../shared/Layout';
import { Table } from '../../../../shared/Table';
import { useRiskMetricsColumns } from '../../RiskMetrics/Tables/useRiskMetricsColumns';
import { InfoIcon } from '../ReportIcons/InfoIcon';
import { ReportExpectations } from './ReportExpectations';

type Props = {
    riskMetrics: RiskMetric[];
    expectations: Expectations;
};

export const ReportRiskMetrics: FC<Props> = ({ riskMetrics, expectations }) => {
    const columns = useRiskMetricsColumns();

    return (
        <Stack gap={33} w={1350} p={5}>
            <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
                <Group align="center" justify="space-between">
                    <Text
fz="45px" fw={400} lh="47px"
style={{ whiteSpace: 'pre-wrap' }}>
                        {'Риск метрики'}
                    </Text>
                    <Group
                        gap={12}
                        miw={180}
                        mih={24}
                        px={16}
                        align="center"
                        py={8}
                        style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                    >
                        <InfoIcon />
                        <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                            {'Информация'}
                        </Text>
                    </Group>
                </Group>
                <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px" style={{ whiteSpace: 'pre-wrap' }}>
                    {
                        'Это классические количественные риск-метрики по портфелю.\n\nСамое главное, \
следите за тем, чтобы коэффициенты Шарпа и Сортино по портфелю были выше, \
чем по бенчмарку.\n\nЭто говорит о том, что инвестиции в ваш портфель \
выгоднее, чем простые инвестиции в индекс.'
                    }
                </Text>
            </Stack>
            <ReportExpectations expectations={expectations} />

            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'Риск метрики'}
                </Text>
                <Table
                    columns={columns}
                    data={riskMetrics}
                    striped={true}
                    highlightOnHover={true}
                    emptyStub={<ViewEmptyStub title="Данных по риск метрикам нет" />}
                />
            </Stack>
        </Stack>
    );
};
