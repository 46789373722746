import classNames from 'classnames';
import { PaginationController } from '@libs/components';

import styles from './styles.module.scss';

type Props = {
    page: number;
    handlePageChange: (page: number) => void;
    limit: number;
    total: number;
    className?: string;
    isLoading?: boolean;
    pageLinkSize?: number;
};

export const Paginator = ({ page, handlePageChange, limit, total, isLoading, className, pageLinkSize = 6 }: Props) => {
    if (total <= limit) {
        return null;
    }

    return (
        <div className={classNames(styles.paginatorWrapper, 'flex', 'justify-content-start', className)}>
            <PaginationController
                total={Math.ceil(total / limit)}
                currentPageCustomClass={styles.currentPage}
                current={page}
                disabled={isLoading}
                customClass={styles.customPagination}
                setAsyncCurrent={handlePageChange}
                pageLinkSize={pageLinkSize}
            />
        </div>
    );
};
