import { Text } from '@mantine/core';
import { type FC } from 'react';

type Props = {
    value?: number;
};

export const BodyCell: FC<Props> = ({ value }) => (
    <Text
style={{ textAlign: 'end' }} fz="14px" lh="16px"
fs="normal">
        {typeof value === 'number' ? `${value}%` : '-'}
    </Text>
);
