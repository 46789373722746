import { type FC } from 'react';

export const RealEstateIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path
                d="M14.8057 26.5H7.5V9.73366H14.8057V26.5ZM14.8057 26.5H11.717V21.9976H14.8057V26.5ZM14.8057
                26.5L30.1943 26.5M14.8057 26.5L14.8056 4.5H30.1943V26.5M30.1943 9.73361V26.5M30.1943
                26.5V21.9976M30.1943 16.7395V19.4829M30.1943 12.0344V14.7778M14.8057
                19.4828H11.717V16.7394H14.8057V19.4828ZM14.8057 14.7778H11.717V12.0344H14.8057V14.7778ZM20.2477
                21.9976H24.7521V26.5H20.2477V21.9976ZM18.1329 16.7395H21.2215V19.4829H18.1329V16.7395ZM23.7784
                16.7395H26.8671V19.4829H23.7784V16.7395ZM18.1329 12.0344H21.2215V14.7778H18.1329V12.0344ZM23.7784
                12.0344H26.8671V14.7778H23.7784V12.0344ZM18.1329 7.32937H21.2215V10.0728H18.1329V7.32937ZM23.7784
                7.32937H26.8671V10.0728H23.7784V7.32937Z"
                stroke="#636E80"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
