import { Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import { type CashFlowType } from '../../../../entities/portfolio';
import { CashFlowTable } from '../../CashFlow/CashFlowTable';
import { Chart } from '../../CashFlow/Chart/Chart';
import { InfoIcon } from '../ReportIcons/InfoIcon';

type Props = {
    data: CashFlowType[];
};

export const ReportCashFlowAverage: FC<Props> = ({ data }) => (
    <Stack gap={33} w={1350} p={5}>
        <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
            <Group align="center" justify="space-between">
                <Text
fz="45px" fw={400} lh="47px"
style={{ whiteSpace: 'pre-wrap' }}>
                    {'Денежный поток (среднегодовой)'}
                </Text>
                <Group
                    gap={12}
                    miw={180}
                    mih={24}
                    px={16}
                    align="center"
                    py={8}
                    style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                >
                    <InfoIcon />
                    <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                        {'Информация'}
                    </Text>
                </Group>
            </Group>
            <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px" style={{ whiteSpace: 'pre-wrap' }}>
                {
                    'Раздел Денежный поток - среднегодовой позволяет оценить среднегодовой размер \
пассивного дохода, который можно получать от вашего портфеля.\n\n\
Вы можете оценить размер пассивного дохода в абсолютном выражении или в процентах годовой доходности.'
                }
            </Text>
        </Stack>
        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={30}>
            <Text fz={36} fw={400}>
                {'Годовая доходность'}
            </Text>
            <Group h={500}>
                <Chart data={data ?? []} isInReport={true} cashFlowType="ANNUAL" />
            </Group>
        </Stack>

        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400}>
                {'Денежный поток'}
            </Text>
            <CashFlowTable data={data ?? []} />
        </Stack>
    </Stack>
);
