import { createEndpoint, createEndpointMap } from '../../../core/endpoint';
import { getAssetsBehavior } from './getAssetsBehavior';
import { getRiskProfile } from './getRiskProfile';
import { getScenarios } from './getScenarios';
import { getScenarioIndicator } from './getScenarioIndicator';
import { getSimulationResult } from './getSimulationResult';

export const createStressTestsEndpoints = createEndpointMap((client) => ({
    getScenarios: createEndpoint(getScenarios, client),
    getScenarioIndicator: createEndpoint(getScenarioIndicator, client),
    getRiskProfile: createEndpoint(getRiskProfile, client),
    getAssetsBehavior: createEndpoint(getAssetsBehavior, client),
    getSimulationResult: createEndpoint(getSimulationResult, client),
}));
