import { MIN_SEARCH_VALUE_LENGTH } from '@modules/Investorpro/shared/components/Header/components/Search/constants';
import { useEffect, useRef, useState } from 'react';
import { getSecurityDocumentByValue } from '@modules/Investorpro/shared/services';
import { type TSecurity } from '@libs/types';
import { SecurityType } from '@modules/Investorpro/modules/ScreenersPage/types';
import { AxiosError } from 'axios';

export const useSearchSecure = (searchValue: string) => {
    const [isLoading, setIsLoading] = useState(false);
    const [securityList, setSecurityList] = useState<TSecurity[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isServiceUnavailable, setIsServiceUnavailable] = useState(false);

    const abortController = useRef(new AbortController());

    const searchSecureByValue = async (value: string) => {
        try {
            if (isLoading) {
                abortController.current.abort();
            }

            if (value.length >= MIN_SEARCH_VALUE_LENGTH || !value) {
                setIsLoading(true);
                abortController.current = new AbortController();

                const typesByIndex: (SecurityType | undefined)[] = [undefined, SecurityType.STOCK, SecurityType.BOND];

                const response = await getSecurityDocumentByValue(
                    value ?? '',
                    typesByIndex[activeIndex],
                    abortController.current.signal,
                );
                setSecurityList(response.data.securities);
                setIsServiceUnavailable(false);
            }
        } catch (error) {
            const responseStatus: number | undefined = (error as AxiosError)?.response?.status;
            if (responseStatus && responseStatus !== 200) {
                setSecurityList([]);
                setIsServiceUnavailable(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        searchSecureByValue(searchValue);
    }, [activeIndex, searchValue]);

    return {
        searchSecureByValue,
        isLoading,
        setSecurityList,
        securityList,
        activeIndex,
        setActiveIndex,
        isServiceUnavailable,
    };
};
