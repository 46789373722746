import { type SectoralDiversificationResponse } from '../../../../../PortfolioPage/entities';
import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getSectoralDiversification: ApiFactory<
    ApiEndpoint<SectoralDiversificationResponse, void, { id: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<SectoralDiversificationResponse>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/diversification/sectoral/${params.id}`,
        { signal },
    );

    return data;
};
