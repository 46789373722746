import { SecurityMarketType } from '@libs/types';
import { useStompClient } from '@libs/utils';
import { SUBSCRIBE_TO_CANDLES_SOCKET_URL } from '@modules/Investorpro/constants';
import { SecurityBoards } from '@modules/Investorpro/modules/MarketPages/shared/constants';
import { getCandleSocketTicker } from '@modules/Investorpro/shared/utils/service.utils';
import { useRef, useEffect } from 'react';

type UseCurrentPriceUpdateArgs<T extends { secId: string }> = {
    securities: T[];
    getTicker?: (security: T) => string;
    disabled?: boolean;
    setSecurityPrice: (security: T, price: number) => void;
}

export const useGetCurrentPrice = <T extends { secId: string; board?: string; market?: SecurityMarketType }>({
    securities,
    setSecurityPrice,
    disabled,
    getTicker,
}: UseCurrentPriceUpdateArgs<T>) => {
    const { client, isConnected } = useStompClient()
    const unsubscribeCallbacks = useRef<Array<() => void>>([])

    useEffect(() => {
        if (isConnected && securities.length > 0 && !disabled) {
            unsubscribeCallbacks.current = securities.map((securityForUpdate) => {
                const { secId } = securityForUpdate
                const ticker = getTicker
                    ? getTicker(securityForUpdate)
                    : getCandleSocketTicker({
                        market: securityForUpdate.market ?? SecurityMarketType.STOCK,
                        secId,
                        board: securityForUpdate.board ?? SecurityBoards.TQBR,
                    })

                const subscription = client.subscribe(SUBSCRIBE_TO_CANDLES_SOCKET_URL + ticker, (message: any) => {
                    const body = JSON.parse(message.body)
                    const currentPrice = body?.data?.at(-1)?.[8]?.[0] ?? 0

                    setSecurityPrice(securityForUpdate, currentPrice)
                });

                return subscription.unsubscribe
            })

            return () => {
                unsubscribeCallbacks.current.forEach((unsubscribeCallback) => {
                    unsubscribeCallback()
                })
            }
        }
    }, [isConnected, client, securities.length, disabled]);
}
