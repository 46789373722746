import { type ReactNode, useCallback, useState } from 'react';
import classNames from 'classnames';
import { type TSecurity } from '@libs/types';
import { SearchSecurityDropDown } from '@modules/Investorpro/shared/components/Header/components/SearchSecurityDropDown';
import { SearchInput } from '@modules/Investorpro/shared/components/Search/SearchInput';
import { useClickAway } from '@uidotdev/usehooks';
import { ModalWindow } from '@libs/components';
import { Button } from 'primereact/button';
import { InputNumber, type InputNumberChangeEvent } from 'primereact/inputnumber';
import { debounce } from 'lodash';
import { getNumberWithSpacing } from '@libs/utils';
import { useSearchSecure } from '@libs/utils/hooks/useSearchSecure';

import styles from './styles.module.scss';

type Props = {
    searchSecuritiesValue: string;
    setSearchValue: (value: string) => void;
    priceValue: number | null;
    setPriceValue: (value: number | null) => void;
    setSecurity: (security: TSecurity | null) => void;
    isOpen: boolean;
    className?: string;
    closeModal: () => void;
    title: string;
    extraButton?: ReactNode;
    actionButtonTitle: string;
    handleAction: () => void;
    disableConfirmButton?: boolean;
    disableDeclineButton?: boolean;
};

export const EditCreateAlertModalTemplate = ({
    setPriceValue,
    priceValue,
    searchSecuritiesValue,
    setSearchValue,
    setSecurity,
    isOpen,
    className,
    closeModal,
    title,
    extraButton,
    actionButtonTitle,
    handleAction,
    disableConfirmButton,
    disableDeclineButton,
}: Props) => {
    const [searchDropdownOpened, setSearchDropdownOpened] = useState(false);
    const searchRef = useClickAway(() => setSearchDropdownOpened(false));

    const { searchSecureByValue, isLoading, securityList, activeIndex, setActiveIndex, isServiceUnavailable } =
        useSearchSecure(searchSecuritiesValue);

    const handlePriceChange = (e: InputNumberChangeEvent) => {
        const value = Number(e.value);

        if (value <= 999_999_999.99) {
            setPriceValue(Number(e.value));
        } else {
            // InputNumber behave like a uncontrolled component
            // price value is not changed, but value in input is continueing to be changed
            const numberParts = priceValue?.toString().split('.') ?? [];
            const numberBeforeDecimalPoint = +numberParts[0];
            const numberAfterDecimalPoint = numberParts[1]?.toString() ?? '';

            // @ts-expect-error need to change value directly
            e.originalEvent.currentTarget.value =
                getNumberWithSpacing(numberBeforeDecimalPoint) + ',' + numberAfterDecimalPoint.padEnd(2, '0');
        }
    };

    const selectSecurity = (security: TSecurity) => {
        setSecurity(security);
        setSearchValue(security.secId + ', ' + security.shortName);
        setSearchDropdownOpened(false);
    };

    const debouncedSearchByValue = useCallback(debounce(searchSecureByValue, 300), [isLoading]);

    const handleSearch = (value: string) => {
        setSearchValue(value);
        debouncedSearchByValue(value);
    };

    const handleClearClick = () => {
        setSearchValue('');
        setSecurity(null);
        setActiveIndex(0);
    };

    return (
        <ModalWindow isVisible={isOpen} className={styles.editCreateAlertModalWrapper} closeModal={closeModal}>
            <div className={classNames(styles.editCreateAlertModal, 'flex', 'flex-column', className)}>
                <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                    <h2>{title}</h2>
                    <div className={classNames(styles.modalContentForm, 'flex', 'flex-column')}>
                        <div className={classNames('flex', 'flex-column')}>
                            <h3>Выбрать инструмент</h3>
                            <div className="relative" ref={searchRef as any}>
                                <SearchInput
                                    handleClear={handleClearClick}
                                    handleChange={handleSearch}
                                    placeholder={'Поиск по тикеру или названию'}
                                    value={searchSecuritiesValue}
                                    className={styles.searchInput}
                                    onFocus={() => setSearchDropdownOpened(true)}
                                />
                                {searchDropdownOpened && (
                                    <SearchSecurityDropDown
                                        className={styles.searchSecurityDropDown}
                                        isLoading={isLoading}
                                        handleItemClick={selectSecurity}
                                        securityList={securityList}
                                        searchValue={searchSecuritiesValue}
                                        activeIndex={activeIndex}
                                        setActiveIndex={setActiveIndex}
                                        isServiceUnavailable={isServiceUnavailable}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classNames('flex', 'flex-column', 'relative')}>
                            <h3>Целевая цена</h3>
                            <InputNumber
                                value={priceValue}
                                onChange={handlePriceChange}
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                placeholder="0"
                                locale="ru-RU"
                                min={0}
                                className={styles.priceInput}
                            />
                            <span className={styles.currency}>RUB</span>
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-between">
                    {extraButton}
                    <div
                        className={classNames(
                            styles.btnWrapper,
                            'flex',
                            'justify-content-end',
                            extraButton && styles.withExtraButton,
                        )}
                    >
                        <Button
                            label={'Отменить'}
                            disabled={disableDeclineButton}
                            className={styles.cancelBtn}
                            onClick={closeModal}
                        />
                        <Button
                            label={actionButtonTitle}
                            disabled={disableConfirmButton}
                            className={styles.confirmBtn}
                            onClick={handleAction}
                        />
                    </div>
                </div>
            </div>
        </ModalWindow>
    );
};
