import { PeriodId } from '@libs/types/instrument.type';
import { isAfter, isBefore } from 'date-fns';

import {
    getPeriodIdByData,
    getPeriodIdByDateInterval,
    isPeriodLessThenAnother,
    printAxisDashboardDayTicks,
    printAxisDashboardMonthTicks,
    printAxisDashboardTimeTicks,
    printAxisDashboardYearTicks,
    printDayAxisTicks,
    printMonthAxisTicks,
    printTimeAxisTicks,
    printYearAxisTicks,
} from './chartTicks.utils';


type UseGetXAxisTicksCallbackByPeriodIdArgs = {
    data: object[];
    ticksCount?: number;
    period: PeriodId | null;
    disabledAutoSkip?: boolean;
}

export const getXAxisCallbackByPeriodId = ({
    data,
    ticksCount,
    disabledAutoSkip = false,
    period,
}: UseGetXAxisTicksCallbackByPeriodIdArgs) => {
    const countOfTicksForPrint = ticksCount ?? data.length
    const step = Math.round(data.length / countOfTicksForPrint)
    const realPeriod = getPeriodIdByData(data, period);

    const store: Partial<Record<PeriodId, typeof printDayAxisTicks>> = {
        [PeriodId.ONE_DAY]: printTimeAxisTicks,
        [PeriodId.FIVE_DAYS]: printTimeAxisTicks,
        [PeriodId.ONE_MONTH]: printDayAxisTicks,
        [PeriodId.THREE_MONTHS]: printDayAxisTicks,
        [PeriodId.SIX_MONTHS]: printDayAxisTicks,
        [PeriodId.ONE_YEAR]: printDayAxisTicks,
        [PeriodId.THREE_YEARS]: printMonthAxisTicks,
        [PeriodId.SNG]: printDayAxisTicks,
        [PeriodId.FIVE_YEARS]: printMonthAxisTicks,
        [PeriodId.TEN_YEARS]: printMonthAxisTicks,
        [PeriodId.ALL]: printMonthAxisTicks,
    }

    return store[realPeriod]?.(step, disabledAutoSkip) ??
        printMonthAxisTicks(step, disabledAutoSkip)
}

type GetXAxisTicksCallbackByPeriodIdArgsForDatasets = {
    datasets: Array<{ data: Array<{ x: string | number }> }>;
    ticksCount: number;
    period: PeriodId | null;
}

export const getXAxisCallbackByPeriodIdForDatasets = ({
    datasets,
    period,
    ticksCount,
}: GetXAxisTicksCallbackByPeriodIdArgsForDatasets) => {
    if (datasets.length === 0 || datasets.every((item) => item.data.length === 0)) {
        return () => '';
    };

    const countOfTicksForPrint = ticksCount ?? datasets[0].data.length ?? 0
    let minDate = new Date(datasets[0].data[0].x);
    let maxDate = new Date(datasets[0].data[datasets[0].data.length - 1].x);

    datasets.forEach((item) => {
        const firstDate = new Date(item.data[0].x);
        const lastDate = new Date(item.data[item.data.length - 1].x);
        minDate = isBefore(firstDate, minDate) ? minDate : firstDate;
        maxDate = isAfter(lastDate, maxDate) ? maxDate : lastDate;
    });

    const store: Partial<Record<PeriodId, typeof printDayAxisTicks>> = {
        [PeriodId.ONE_DAY]: printAxisDashboardTimeTicks,
        [PeriodId.FIVE_DAYS]: printAxisDashboardTimeTicks,
        [PeriodId.ONE_MONTH]: printAxisDashboardDayTicks,
        [PeriodId.THREE_MONTHS]: printAxisDashboardDayTicks,
        [PeriodId.SIX_MONTHS]: printAxisDashboardDayTicks,
        [PeriodId.ONE_YEAR]: printAxisDashboardDayTicks,
        [PeriodId.TWO_YEARS]: printAxisDashboardMonthTicks,
        [PeriodId.THREE_YEARS]: printAxisDashboardMonthTicks,
        [PeriodId.SNG]: printAxisDashboardMonthTicks,
        [PeriodId.FIVE_YEARS]: printAxisDashboardMonthTicks,
        [PeriodId.TEN_YEARS]: printAxisDashboardYearTicks,
    }

    const realPeriod = getPeriodIdByDateInterval(minDate, maxDate);
    const periodOfRender = period && isPeriodLessThenAnother(period, realPeriod) ? period : realPeriod;

    return store[periodOfRender]?.(countOfTicksForPrint, true) ??
        printAxisDashboardMonthTicks(countOfTicksForPrint, true)
}
