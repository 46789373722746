import { httpClient } from '@configs/httpclient.config';
import { Footer, Header, HelpLine } from '@libs/components';
import { Loader } from '@libs/components/loaders/Loader';
import { getScrollbarWidth, useAuthorize } from '@libs/utils';
import { type RootState, useAppDispatch } from '@store/store';
import { useSelector } from 'react-redux';
import { Outlet, ScrollRestoration, useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { YandexMetrica } from '@libs/ym/YandexMetrica';
import { LoginModal } from '@libs/components/overlay/LoginModal';
import { checkPermissionsThunk } from '@store/store/thunk/investorpro/permissions.thunk';
import { classNames } from 'primereact/utils';
import { useConfig } from '@dynamic-env/useConfig';
import { useOverlayPadding } from '@modules/Investorpro/shared/utils/useOverlayPadding';
import { SCROLLBAR_WIDTH_CSS_VARIABLE } from '@modules/Investorpro/shared/constants';
import { usePermission } from '@libs/utils/hooks/usePermission';
import { IS_INVESTORPRO_REDIRECT_PARAMETER } from '@libs/utils/hooks/useAuthorize';

import styles from './styles.module.scss';

export const Layout = () => {
    const dispatch = useAppDispatch();
    const { config } = useConfig();
    const { token, isAuthorized, loginHandler, loginInProgress } = useAuthorize();
    const { isPermissionsInitialized, isLoading: isPermissionsLoading } = usePermission();
    const isUserDataLoading = useSelector((state: RootState) => state.user.isLoading);

    const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!searchParams.get(IS_INVESTORPRO_REDIRECT_PARAMETER)) {
            setIsOpenLoginModal(!isAuthorized);
        } else {
            setIsOpenLoginModal(false);

            if (!isAuthorized) {
                loginHandler();
            }
        }

        if (isAuthorized && config.INVESTOR_PRO_PRODUCT_GUID) {
            dispatch(checkPermissionsThunk(config.INVESTOR_PRO_PRODUCT_GUID));
        }
    }, [isAuthorized, searchParams, config.INVESTOR_PRO_PRODUCT_GUID]);

    if (token && !loginInProgress) {
        httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    if (httpClient.defaults.headers.common.Authorization && loginInProgress) {
        httpClient.defaults.headers.common.Authorization = '';
    }

    const isOverlayOpen = useSelector((state: RootState) => state.technical.layout.openedOverlays.length > 0);

    const helplineRef = useRef(null);
    const headerRef = useRef(null);
    const footerRef = useRef(null);
    const contentRef = useRef(null);

    useOverlayPadding({
        active: isOverlayOpen,
        refsForAddingAttributes: [helplineRef],
        refsForAddingPaddings: {
            paddingRight: [headerRef, footerRef, contentRef],
        },
    });

    const location = useLocation();
    // TODO: maybe need to create layout.slice for this
    const noFooterMargin = location.pathname === '/analytics/market';

    useLayoutEffect(() => {
        document.documentElement.style.setProperty(SCROLLBAR_WIDTH_CSS_VARIABLE, `${getScrollbarWidth()}px`);
    }, []);

    const isLayoutLoading = loginInProgress || isUserDataLoading || isPermissionsLoading;

    return (
        <YandexMetrica>
            <div className={classNames(styles.layout, 'flex', 'flex-column', 'align-items-center')}>
                {(isPermissionsInitialized || isOpenLoginModal) && (
                    <>
                        <Header ref={headerRef} />
                        <div className={styles.layout_backgroundImage} />
                        <div
                            className={classNames('flex', 'justify-content-center', styles.layout_contentContainer)}
                            ref={contentRef}
                        >
                            <div
                                className={styles.layout_content}
                                style={{ minHeight: isOpenLoginModal ? '70vh' : undefined }}
                            >
                                <Outlet />
                            </div>
                        </div>
                        <ScrollRestoration />
                        <HelpLine ref={helplineRef} />
                        <LoginModal isVisible={isOpenLoginModal && !loginInProgress} />
                        <Footer className={classNames(!noFooterMargin && 'mt-5')} ref={footerRef} />
                    </>
                )}
            </div>
            <Loader isLoading={isLayoutLoading} withOverlay />
        </YandexMetrica>
    );
};
