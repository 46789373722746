import { PositiveNegativeValue } from '@modules/Investorpro/shared/components/PositiveNegativeValue';
import { type Column } from '@modules/Investorpro/shared/components/Table';
import { type TwoColumnsDataViewOption } from '@modules/Investorpro/modules/BondStockPage/shared/components/TwoColumnsDataView';
import { ValueWithMeasure } from '@modules/Investorpro/modules/BondStockPage/shared/components/ValueWithMeasure';
import { round } from 'lodash';

import { type ProfitDividendInfo, type FinanceAndAnalyticsInfo } from '../../../../types';
import styles from './styles.module.scss';

export const FINANCIAL_INFO_DATA_ROWS: Array<TwoColumnsDataViewOption<FinanceAndAnalyticsInfo>> = [
    {
        label: 'Выручка',
        value: 'revenue',
        valueComponent: (item) => <ValueWithMeasure value={item?.revenue} measure="руб" />,
    },
    {
        label: 'Чистая прибыль',
        value: 'netProfit',
        valueComponent: (item) => <ValueWithMeasure value={item?.netProfit} measure="руб" />,
    },
    { label: 'P/S', value: 'pS', type: 'price' },
    { label: 'P/E', value: 'pE', type: 'price' },
    { label: 'EV/EBITDA', value: 'evEbitda', type: 'number' },
];

function renderTableItem<T>(key: keyof T) {
    return (item: T) => (
        <PositiveNegativeValue className={styles.yield_value} measure="%" value={round(Number(item[key]), 2)} />
    );
}

export const INCOME_COLUMNS: Array<Column<ProfitDividendInfo & { bid: string }>> = [
    {
        id: 'bid',
        label: '',
        field: 'bid',
        align: 'left',
        width: '133px',
        className: styles.yield_title,
    },
    {
        id: '1d',
        label: '1 день',
        width: '133px',
        render: renderTableItem('y1d'),
    },
    {
        id: '5d',
        label: '5 дней',
        width: '133px',
        render: renderTableItem('y1w'),
    },
    {
        id: '1m',
        label: '1 месяц',
        width: '133px',
        render: renderTableItem('y1m'),
    },
    {
        id: '3m',
        label: '3 месяца',
        width: '133px',
        render: renderTableItem('y3m'),
    },
    {
        id: '6m',
        label: '6 месяцев',
        width: '133px',
        render: renderTableItem('y6m'),
    },
    {
        id: 'SNG',
        label: 'СНГ',
        width: '133px',
        render: renderTableItem('yytd'),
    },
    {
        id: '1y',
        label: '1 год',
        width: '133px',
        render: renderTableItem('y1y'),
    },
    {
        id: '5y',
        label: '5 лет',
        width: '133px',
        render: renderTableItem('y5y'),
    },
];
