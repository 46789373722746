import {
    type CouponPayment,
    type RedemptionPayment,
    type PaymentChartBarType,
    type AmmortizationPayment,
    type OfferPayment,
    PaymentType,
} from '@modules/Investorpro/modules/BondStockPage/BondPage/services/types';
import { classNames } from 'primereact/utils';
import { formatDate, formatPrice } from '@modules/Investorpro/shared/utils/format.util';

import styles from './styles.module.scss';

type Props = {
    data: PaymentChartBarType;
};

export const TooltipContent = ({ data }: Props) => {
    const { type } = data;

    const getContent = () => {
        const { rateDate, begOrder, paymentPerBond, operationType, endOfferDate, offerDate, endOrder, buyBackPrice } =
            data as OfferPayment;

        if (type === PaymentType.OFFER) {
            return (
                <>
                    <div>
                        <span>Тип выкупа</span>
                        <span>{operationType}</span>
                    </div>
                    <div>
                        <span>Объявление выкупа</span>
                        <span>{formatDate(rateDate)}</span>
                    </div>
                    <div>
                        <span>Начало предъявления к выкупу</span>
                        <span>{formatDate(begOrder)}</span>
                    </div>
                    <div>
                        <span>Окончание предъявления к выкупу</span>
                        <span>{formatDate(endOrder)}</span>
                    </div>
                    <div>
                        <span>Расчетная дата выкупа</span>
                        <span>{formatDate(offerDate)}</span>
                    </div>
                    <div>
                        <span>Дата выкупа</span>
                        <span>{formatDate(endOfferDate)}</span>
                    </div>
                    <div>
                        <span>Цена выкупа грязная</span>
                        <span>{formatPrice(buyBackPrice)}%</span>
                    </div>
                    <div>
                        <span>Выплата на одну облигацию (руб.)</span>
                        <span>{formatPrice(paymentPerBond)}</span>
                    </div>
                </>
            );
        }

        if (data.type === PaymentType.REDEMPTION) {
            const { endmtyDate, paymentPerBond } = data as RedemptionPayment;

            return (
                <>
                    <div>
                        <span>Дата погашения</span>
                        <span>{formatDate(endmtyDate)}</span>
                    </div>
                    <div>
                        <span>Выплата на одну облигацию</span>
                        <span>{formatPrice(paymentPerBond)}</span>
                    </div>
                </>
            );
        }

        if (data.type === PaymentType.AMORTIZATION) {
            const { mtyDate, mtyType, fixDate, mtyPart, payPerBond } = data as AmmortizationPayment;

            return (
                <>
                    <div>
                        <span>Тип погашения</span>
                        <span>{mtyType}</span>
                    </div>
                    <div>
                        <span>Дата выплаты части номинала</span>
                        <span>{formatDate(mtyDate)}</span>
                    </div>
                    <div>
                        <span>Дата фиксации списка</span>
                        <span>{formatDate(fixDate)}</span>
                    </div>
                    <div>
                        <span>Процент погашения</span>
                        <span>{formatPrice(mtyPart)}</span>
                    </div>
                    <div>
                        <span>Выплата на одну облигацию (руб.)</span>
                        <span>{formatPrice(payPerBond)}</span>
                    </div>
                </>
            );
        }

        if (data.type === PaymentType.COUPON) {
            const { couponType, couponNumber, annualRate, paymentDate, paymentAmount, listFixingDate } =
                data as CouponPayment;

            return (
                <>
                    <div>
                        <span>Вид купона</span>
                        <span>{couponType}</span>
                    </div>
                    <div>
                        <span>№ купона</span>
                        <span>{couponNumber}</span>
                    </div>
                    <div>
                        <span>Дата выплаты по купону</span>
                        <span>{formatDate(paymentDate)}</span>
                    </div>
                    <div>
                        <span>Дата фиксации списка</span>
                        <span>{formatDate(listFixingDate)}</span>
                    </div>
                    <div>
                        <span>Ставка (% годовых)</span>
                        <span>{formatPrice(annualRate)}</span>
                    </div>
                    <div>
                        <span>Размер выплаты (руб.)</span>
                        <span>{formatPrice(paymentAmount)}</span>
                    </div>
                </>
            );
        }
    };

    const getTypeTitle = () => {
        const titles: Record<string, string> = {
            offer: 'Оферта',
            redemption: 'Погашение',
            amortization: 'Амортизация',
            coupon: 'Купонная выплата',
        };

        return titles[type];
    };

    return (
        <div className={classNames('flex', 'flex-column', styles.tooltipContent)}>
            <span className={classNames(styles.paymentType, styles[type])}>{getTypeTitle()}</span>
            <div className={styles.content}>{getContent()}</div>
        </div>
    );
};
