import {
    type BondMarketRedemptionData,
    type BondMarketAmortisationData,
    type BondMarketAuctionData,
    type BondMarketCouponData,
    type BondMarketOfferData,
} from '@libs/types';
import { type Column } from '@modules/Investorpro/shared/components/Table';
import { ValueWithMeasure } from '@modules/Investorpro/modules/BondStockPage/shared/components/ValueWithMeasure';

import styles from './styles.module.scss';

export const AUCTION_COLUMNS: Array<Column<BondMarketAuctionData>> = [
    {
        id: 'name',
        field: 'name',
        label: 'Выпуск',
        width: '284px',
        align: 'left',
        className: styles.tableNameColumn,
    },
    {
        id: 'begDistDate',
        field: 'begDistDate',
        label: 'Дата',
        width: '114px',
        className: styles.tableValueColumn,
        align: 'right',
        type: 'date',
    },
    {
        id: 'distVal',
        label: 'Объем',
        width: '174px',
        align: 'right',
        render: (obj) => (
            <ValueWithMeasure value={obj.distVal} measure="RUB" measureCLassName={styles.measureWrapper} />
        ),
        className: styles.tableValueColumn,
    },
];

export const COUPONS_COLUMNS: Array<Column<BondMarketCouponData>> = [
    {
        id: 'name',
        field: 'name',
        label: 'Выпуск',
        width: '284px',
        align: 'left',
        className: styles.tableNameColumn,
    },
    {
        id: 'begDistDate',
        field: 'payDate',
        label: 'Дата',
        width: '114px',
        type: 'date',
        align: 'right',
        className: styles.tableValueColumn,
    },
    {
        id: 'distVal',
        label: 'Купон',
        width: '174px',
        className: styles.tableValueColumn,
        align: 'right',
        render: (obj) => (
            <ValueWithMeasure value={obj.payPerBond} measure="RUB" measureCLassName={styles.measureWrapper} />
        ),
    },
];

export const OFFERS_COLUMNS: Array<Column<BondMarketOfferData>> = [
    {
        id: 'name',
        field: 'name',
        label: 'Выпуск',
        width: '284px',
        align: 'left',
        className: styles.tableNameColumn,
    },
    {
        id: 'begDistDate',
        field: 'offerDate',
        label: 'Дата',
        width: '114px',
        className: styles.tableValueColumn,
        align: 'right',
        type: 'date',
    },
    {
        id: 'distVal',
        label: 'Объем',
        width: '174px',
        className: styles.tableValueColumn,
        align: 'right',
        render: (obj) => (
            <ValueWithMeasure value={obj.sumIssueVal} measure="RUB" measureCLassName={styles.measureWrapper} />
        ),
    },
];

export const AMMORTISATION_COLUMNS: Array<Column<BondMarketAmortisationData>> = [
    {
        id: 'name',
        field: 'name',
        label: 'Название',
        width: '186px',
        align: 'left',
        className: styles.tableNameColumn,
    },
    {
        id: 'mtyDate',
        field: 'mtyDate',
        label: 'Дата',
        className: styles.tableValueColumn,
        width: '114px',
        align: 'right',
        type: 'date',
    },
    {
        id: 'mtyDate',
        field: 'payPerBond',
        label: 'Сумма',
        className: styles.tableValueColumn,
        width: '94px',
        align: 'right',
    },
    {
        id: 'payPerMarket',
        label: 'Объем',
        className: styles.tableValueColumn,
        width: '174px',
        align: 'right',
        render: (obj) => (
            <ValueWithMeasure value={obj.payPerMarket} measure="RUB" measureCLassName={styles.measureWrapper} />
        ),
    },
];

export const REDEMPTION_COLUMNS: Array<Column<BondMarketRedemptionData>> = [
    {
        id: 'name',
        field: 'name',
        label: 'Выпуск',
        width: '284px',
        align: 'left',
        className: styles.tableNameColumn,
    },
    {
        id: 'mtyDate',
        field: 'mtyDate',
        label: 'Дата',
        width: '114px',
        type: 'date',
        className: styles.tableValueColumn,
        align: 'right',
    },
    {
        id: 'payPerMarket',
        label: 'Объем',
        width: '174px',
        className: styles.tableValueColumn,
        align: 'right',
        render: (obj) => (
            <ValueWithMeasure value={obj.payPerMarket} measure="RUB" measureCLassName={styles.measureWrapper} />
        ),
    },
];
