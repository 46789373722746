import { type SecurityType } from '@modules/Investorpro/modules/ScreenersPage/types';

export enum ECountryFlags {
    RU = 'RU',
}

export type TSecurity = {
    isin: string;
    secId: string;
    shortName: string;
    exchange: string;
    exchangeCountry: ECountryFlags;
    securityType: SecurityType;
};

export type TSecurityResponse = {
    securities: TSecurity[];
};
