import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const Circle: FC<Props> = ({ width = 24, height = 24, color = 'currentColor' }) => (
    <svg
xmlns="http://www.w3.org/2000/svg" width={width} height={height}
viewBox="0 0 12 12" fill={color}>
        <circle
cx="4" cy="4" r="4.8"
transform="matrix(1 0 0 -1 2 10)" stroke={color} strokeWidth="1.6" />
    </svg>
);
