import React, { type ReactNode } from 'react';
import classNames from 'classnames';
import { NONE_SYMBOL } from '@modules/Investorpro/shared/constants';
import { formatNullableValue } from '@modules/Investorpro/shared/utils/format.util';
import { type ValueType } from '@modules/Investorpro/types/common.type';
import { formatValueByType } from '@modules/Investorpro/shared/utils/formatValueByType.util';

import styles from './styles.module.scss';

export type TwoColumnsDataViewOption<T extends object = any> = {
    label?: string;
    value?: keyof T;
    link?: string | null;
    fontWeight?: number;
    fontSize?: number;
    fontFamily?: string;
    numberDivider?: string;
    labelComponent?: ReactNode;
    valueComponent?: (obj: T) => ReactNode;
    measure?: string | number;
    type?: ValueType;
};

type Props = {
    data: object | null;
    options: TwoColumnsDataViewOption[];
    className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const TwoColumnsDataView = ({ options, data, className, ...props }: Props) => {
    const viewElements: ReactNode[] = [];

    options.forEach(
        (
            {
                value,
                label,
                link,
                labelComponent,
                numberDivider,
                valueComponent,
                type,
                measure,
                fontSize,
                fontWeight,
                fontFamily,
            },
            index,
        ) => {
            const optionValue = data ? data[value as keyof typeof data] : null;
            const displayedMeasure = measure ?? '';

            const renderValue = () => {
                if (valueComponent) {
                    return formatNullableValue(valueComponent(data));
                }

                return formatValueByType({
                    value: optionValue,
                    type,
                    measure: displayedMeasure,
                    emptyValue: NONE_SYMBOL,
                    numberDivider,
                });
            };

            const optionValueComponent = renderValue();

            viewElements.push(
                <span key={'label' + index} className={styles.label}>
                    {labelComponent ?? label}
                </span>,
            );
            viewElements.push(
                link
? (
                    <a
                        style={{ fontSize, fontWeight }}
                        href={link}
                        key={'link' + index}
                        target="_blank"
                        rel="noreferrer"
                        className={classNames(styles.value, styles[`value_${type}`])}
                    >
                        {optionValueComponent}
                    </a>
                )
: (
                    <span
                        style={{ fontSize, fontWeight, fontFamily }}
                        className={classNames(styles.value, styles[`value_${type}`])}
                        key={'value' + index}
                    >
                        {optionValueComponent}
                    </span>
                ),
            );
        },
    );

    return (
        <div {...props} className={classNames(styles.twoColumnsDataView, className)}>
            {viewElements}
        </div>
    );
};
