import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useState } from 'react';


export const useLastUpdate = (
    label = 'Последнее обновление:',
    dateFormat = 'd MMMM yyyy HH:mm',
) => {
    const [updateDate, setUpdateDate] = useState<Date | null>(null);

    const setLastUpdate = (date?: Date) => {
        setUpdateDate(date ?? new Date());
    }

    return {
        setLastUpdate,
        updateDate,
        updateDateLabel: updateDate
            ? ` ${label} ${updateDate && format(updateDate, dateFormat, { locale: ru })}`
            : '',
    }
}
