import { Anchor, Breadcrumbs as MantineBreadcrumbs } from '@mantine/core';
import { type FC } from 'react';

type Breadcrumb = {
    href: string;
    title: string;
};

type Props = {
    items: Breadcrumb[];
};

export const Breadcrumbs: FC<Props> = ({ items }) => (
    <MantineBreadcrumbs styles={{ separator: { color: '#A5B1C0' } }}>
        {items.map(({ href, title }, index) => (
            <Anchor
                href={href}
                key={href}
                fz={13}
                lh="16px"
                underline="never"
                c={index === items.length - 1 ? '#14171F' : '#A5B1C0'}
            >
                {title}
            </Anchor>
        ))}
    </MantineBreadcrumbs>
);
