import { useEffect } from 'react';
import { getStockBaseInfoThunk, type RootState } from '@store/store';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ValueWithMeasure } from '@modules/Investorpro/modules/BondStockPage/shared/components/ValueWithMeasure';
import { resetStockPageData } from '@store/store/slices/investorpro/stock.slice';
import { HelpLineWrapper } from '@libs/components';
import { round } from 'lodash';
import { getNumberWithSpacing, useAuthorize } from '@libs/utils';
import { NONE_SYMBOL } from '@modules/Investorpro/shared/constants';
import { useLoading } from '@libs/utils/hooks/useLoading';

import styles from './styles.module.scss';
import { StockBondHeaderWrapper } from '../../../shared/components/StockBondHeaderWrapper';
import { PriceRange } from './components/PriceRange';
import { GET_STOCK_BASE_INFO_LOADING_KEY } from '../../constants';

export const StockHeader = () => {
    const { dispatch, startLoading, stopLoading } = useLoading(GET_STOCK_BASE_INFO_LOADING_KEY);
    const { ticker } = useParams();
    const formattedTicker = ticker?.toUpperCase();

    const {
        info,
        baseInfo: { generalInfo },
    } = useSelector((state: RootState) => state.stock);
    const { isin } = generalInfo;

    const { isAuthorized } = useAuthorize();

    useEffect(() => {
        if (formattedTicker && isAuthorized) {
            startLoading();
            dispatch(getStockBaseInfoThunk(formattedTicker)).finally(() => {
                stopLoading();
            });
        }

        return () => {
            dispatch(resetStockPageData());
        };
    }, [formattedTicker, dispatch, isAuthorized]);

    return (
        <StockBondHeaderWrapper
            title={info?.name}
            logo={info?.logo}
            underTitle={
                <>
                    <span>{formattedTicker}</span>
                    <span className={styles.dotSeparator}>•</span>
                    <span>{generalInfo.stockExchange}</span>
                </>
            }
            currentValue={info?.currentPrice}
            currentValueMeasure={' RUB'}
            changedAt={info?.updatedAt}
            changedAbsolute={info?.change?.absolute}
            changedRelative={info?.change?.relative}
            showChangedRelativeMeasure
            addToFavoritesCode={isin}
            columns={[
                {
                    title: 'Объем торгов',
                    value: (
                        <ValueWithMeasure
                            value={info?.tradeVolume}
                            measure={'RUB'}
                            measureCLassName={styles.measure}
                            stringValue={
                                info && info.tradeVolume !== null
                                    ? getNumberWithSpacing(round(info.tradeVolume))
                                    : NONE_SYMBOL
                            }
                        />
                    ),
                },
                {
                    title: 'Средний объем торгов за 10д',
                    value: (
                        <ValueWithMeasure
                            value={info?.avgTradeVolume10d}
                            measureCLassName={styles.measure}
                            className={styles.value}
                            measure={'RUB'}
                        />
                    ),
                },
                {
                    title: 'Дневной диапазон, руб.',
                    value: (
                        <HelpLineWrapper message="helpline.concreteStock.dailyRange">
                            <PriceRange
                                min={info?.dailyRange?.min}
                                max={info?.dailyRange?.max}
                                current={info?.currentPrice}
                            />
                        </HelpLineWrapper>
                    ),
                },
                {
                    title: 'Годовой диапазон, руб.',
                    value: (
                        <HelpLineWrapper message="helpline.concreteStock.annualRange">
                            <PriceRange
                                min={info?.yearlyRange?.min}
                                max={info?.yearlyRange?.max}
                                current={info?.currentPrice}
                            />
                        </HelpLineWrapper>
                    ),
                },
            ]}
        />
    );
};
