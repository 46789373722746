/* eslint-disable react/prop-types */
import { AreaChart } from '@mantine/charts';
import { type FC, memo } from 'react';

import { type SparklinePoint } from '../../entities';

type Props = {
    points: SparklinePoint[];
};

export const Sparkline: FC<Props> = memo(({ points }) => (
    <AreaChart
        strokeWidth={1}
        h={24}
        w="100%"
        data={points.map((point) => ({ ...point, value: point.value + 100 }))}
        dataKey="date"
        series={[
            {
                name: 'value',
                color: (points.at(-1)?.value ?? 0) > 0 ? '#42A62E' : '#F2433D',
            },
        ]}
        withTooltip={false}
        yAxisProps={{ domain: [0, 200] }}
        curveType="natural"
        tickLine="none"
        gridAxis="none"
        withXAxis={false}
        withYAxis={false}
        withDots={false}
        fillOpacity={0.2}
    />
));
