import { NumberInput, TextInput } from '@mantine/core';
import { type UseFormReturnType } from '@mantine/form';
import { useInfiniteQuery } from '@tanstack/react-query';
import { type FC, Fragment, useCallback } from 'react';

import { SelectAsyncPaginate } from '../shared/SelectAsyncPaginate';
import { useApi } from '../api';
import { type PortfolioFormValues } from './PortfolioForm.schema';

type Props = {
    form: UseFormReturnType<PortfolioFormValues>;
    onPortfolioCurrencyChange: (currency: string) => void;
};

export const PortfolioMainSettings: FC<Props> = ({ form, onPortfolioCurrencyChange }) => {
    const api = useApi();
    const {
        data: benchmarkDictionaryData,
        fetchNextPage,
        isLoading,
        hasNextPage,
    } = useInfiniteQuery({
        queryKey: ['benchmark', 'dictionary'],
        queryFn: async ({ pageParam }) => await api.benchmark.getDictionary({ params: { page: pageParam, size: 20 } }),
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
            return lastPage.totalPages === lastPage.pageNumber ? null : lastPage.pageNumber + 1;
        },
        select: ({ pages }) => pages.flatMap(({ content }) => content.map(({ uuid, name, currency }) => ({ value: uuid, label: name, currency })),
            ),
    });

    const fetchNextPageBenchmarkDictionary = useCallback(() => {
        fetchNextPage().catch((e) => console.error(e));
    }, [fetchNextPage]);

    return (
        <Fragment>
            <SelectAsyncPaginate
                {...form.getInputProps('benchmarkUuid')}
                onChange={(uuid) => {
                    form.setFieldValue('benchmarkUuid', uuid ?? '');
                    onPortfolioCurrencyChange(
                        benchmarkDictionaryData?.find(({ value }) => value === uuid)?.currency ?? 'RUB',
                    );
                }}
                w={255}
                data={benchmarkDictionaryData}
                fetchNextPage={fetchNextPageBenchmarkDictionary}
                isLoading={isLoading}
                hasNextPage={hasNextPage}
                clearable={false}
                label="Бенчмарк"
                comboboxProps={{ withinPortal: false }}
                placeholder="Выберите бенчмарк"
                allowDeselect={false}
            />
            <TextInput
                w={102}
                label="Валюта"
                disabled={true}
                defaultValue={
                    benchmarkDictionaryData?.find(({ value }) => form.values.benchmarkUuid === value)?.currency
                }
            />
            <NumberInput
                {...form.getInputProps('value')}
                w={210}
                max={10000000000}
                hideControls={true}
                thousandSeparator=" "
                label="Стоимость портфеля"
                placeholder="Стоимость портфеля"
                clampBehavior="none"
            />
            {/* TODO: изменить это поле, когда нужно будет менять значение периода балансировок,
             добавить в пропсы стейт для него */}
            <TextInput
                w={214}
                disabled={true}
                label="Период ребалансировок"
                value="Без ребалансировок"
                placeholder="Выберите бенчмарк"
            />
        </Fragment>
    );
};
