import { Button, Group, Text } from '@mantine/core';
import { type FC } from 'react';

type Props = {
    resetAllTickers: () => void;
};

export const SelectedToolsHeader: FC<Props> = ({ resetAllTickers }) => (
    <Group
pl={48} justify="space-between" pb={1}
style={{ borderBottom: '1px solid #D8E1EB' }}>
        <Text fz={16} lh="16px">
            {'Выбранные инструменты'}
        </Text>
        <Button
            style={{
                textDecoration: 'underline dashed #2F9CEB',
                textUnderlineOffset: '3px',
                border: 'none',
                background: 'transparent',
                fontStyle: 'normal',
            }}
            onClick={resetAllTickers}
        >
            <Text c="#2F9CEB" fz={14} fw={400}>
                {'Сбросить всё'}
            </Text>
        </Button>
    </Group>
);
