import { Stack, Text, Title, Image } from '@mantine/core';
import { type FC } from 'react';
import { ValidationError } from 'yup';
import nodata from '@shared/images/investorPro/nodata.png';

type Props = {
    error?: unknown;
    subtitle?: string;
    title?: string;
    errors?: string[];
};

const ErrorStack = ({ subtitle, title, errors }: Props) => (
    <Stack
        align="center"
        justify="center"
        gap={8}
        p={16}
        bg="rgba(215, 230, 245, 0.20)"
        style={{ border: '1px solid #D8E1EB', borderRadius: '12px' }}
        h="100%"
    >
        <Image src={nodata} h={64} w={48} />
        <Title fz={16} fw={400} c="#A5B1C0">
            {title}
        </Title>
        <Text fz={14} c="#A5B1C0" style={{ textAlign: 'center' }}>
            {subtitle}
        </Text>
        {errors
            ? errors.map((err, i) => (
                  <Text fz={14} key={i} c="#A5B1C0">
                      {err}
                  </Text>
              ))
            : null}
    </Stack>
);

export const ViewError: FC<Props> = ({ error, subtitle }) => {
    if (error instanceof ValidationError) {
        return <ErrorStack subtitle={subtitle} title="Ошибка данных" errors={error.errors} />;
    }

    if (error instanceof Error) {
        return <ErrorStack subtitle={subtitle} title="Ошибка сервера" />;
    }

    return <ErrorStack subtitle={subtitle} />;
};
