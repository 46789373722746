import { type StockDividend } from '@libs/types/bondsmarket.type'
import { type Column } from '@modules/Investorpro/shared/components/Table'

import styles from './styles.module.scss'

const width = '212px'

export const TOP_STOCK_BY_DIVIDENDS_COLUMNS: Array<Column<StockDividend>> = [
    {
        id: 'ticker',
        field: 'secId',
        label: 'Тикер',
        type: 'string',
        width: '204px',
        align: 'left',
        className: styles.nameColumn,
    },
    {
        id: 'price',
        field: 'price',
        label: 'Цена',
        width,
        className: styles.valueColumn,
        align: 'right',
        type: 'price',
    },
    {
        id: 'paymentData',
        field: 'paymentData',
        label: 'Дата выплаты',
        width,
        className: styles.valueColumn,
        align: 'right',
        type: 'date',
    },
    {
        id: 'vol',
        field: 'paymentVol',
        label: 'Сумма выплаты',
        width,
        className: styles.valueColumn,
        align: 'right',
        type: 'price',
    },
    {
        id: 'currentProfit',
        field: 'currentProfit',
        label: 'Текущая доходность',
        width: '210px',
        className: styles.valueColumn,
        align: 'right',
        type: 'price',
        measure: '%',
    },
]
