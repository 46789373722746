import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { ModalWindow } from '@libs/components';
import { InputText } from 'primereact/inputtext';
import { useSelector } from 'react-redux';
import {
    createScreenerThunk,
    type RootState,
    store,
    updateScreenerThunk,
    useAppDispatch,
    useAppSelector,
} from '@store/store';
import { FilterTypes, ValueTypes } from '@modules/Investorpro/modules/ScreenersPage/types';
import { useFieldArray, useForm } from 'react-hook-form';
import { instrumentsCountGetRequest } from '@modules/Investorpro/shared/services';
import { LoaderFlashing } from '@libs/components/loaders/LoaderFlashing/LoaderFlashing';
import { MAX_INPUT_VALUE_LENGTH } from '@modules/Investorpro/modules/ScreenersPage/constants';
import { Tooltip } from 'primereact/tooltip';
import { type PageableType, type ResponseInfo } from '@libs/types';

import { ReactComponent as RenameIcon } from '../../../../shared/images/svg/Rename.svg';
import { FiltersList } from './components/FiltersList';
import { FiltersFormList } from './components/FiltersFormList';
import {
    type FiltersInstrumentType,
    type FilterType,
    type RequestScreenerType,
    type ScreenerFilterValueType,
    type ScreenerType,
} from '../../types';
import styles from './styles.module.scss';
import { declension, getFiltersPayload, getScreenerFilterValue, prepareCompliance } from '../Screener/utils';

export type IFiltersFormData = ScreenerFilterValueType & { filterId: FilterType['id'] };

type NewScreenerModalProps = {
    closeModal: () => void;
    onUpdateCurrentScreener?: (
        screenerId: ScreenerType['id'],
        body: { instrumentTypeId: ScreenerType['instrumentTypeId']; pageable?: Partial<PageableType> },
    ) => void;
    onCreateScreener?: (screenerId?: ScreenerType['id']) => void;
    isVisible: boolean;
    screenerId?: ScreenerType['id'];
};

const DEFAULT_SCREENER_NAME = 'Новый скринер';

export const NewScreenerModal = ({
    closeModal,
    onUpdateCurrentScreener,
    onCreateScreener,
    isVisible,
    screenerId,
}: NewScreenerModalProps) => {
    const { filters, instrumentTypes } = useSelector((state: RootState) => state.screeners.filters);
    const screener: Pick<ScreenerType, 'name' | 'filters' | 'columns'> = useSelector(
        (state: RootState) => state.screeners.screeners.find(({ id }) => id === screenerId) ?? {
                name: DEFAULT_SCREENER_NAME,
                filters: [],
                columns: [],
            },
    );

    const isLoading: boolean = useAppSelector((state) => state.screeners.isLoading);

    // region Заголовок и переименование скринера
    const [screenerName, setScreenerName] = useState<{ locked: boolean; value: string }>({
        locked: true,
        value: DEFAULT_SCREENER_NAME,
    });
    // endregion Заголовок и переименование скринера

    // region Работа с инструментами
    const [activeInstrumentType, setActiveInstrumentType] = useState<FiltersInstrumentType | null>(null);
    const [isLoadingFlashing, setIsLoadingFlashing] = useState<boolean>(false);

    useEffect(() => {
        const defaultInstrumentType =
            instrumentTypes?.find(({ isAvailable }: FiltersInstrumentType) => isAvailable) ?? null;

        if (isVisible && defaultInstrumentType) {
            setActiveInstrumentType(defaultInstrumentType);
        } else {
            setActiveInstrumentType(null);
        }
    }, [instrumentTypes, isVisible]);
    // endregion Работа с инструментами

    // region Форма
    const {
        control,
        reset,
        formState: { isValid },
        getValues,
        setValue,
        watch,
        register,
    } = useForm<{ name: string; filters?: IFiltersFormData[] }>({
        mode: 'all',
        defaultValues: { name: '', filters: [] },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'filters',
    });
    // endregion Форма

    // region Работа с количеством инструментов
    const [instrumentsCount, setInstrumentsCount] = useState<number>(0);

    const handleRequestInstrumentsCount = () => {
        if (!isValid || !screenerName.value.trim().length) {
            return;
        }

        if (activeInstrumentType?.id) {
            const checkedFiltersValues = getValues().filters;

            if (
                !isValid ||
                !checkedFiltersValues ||
                !checkedFiltersValues.every(
                    ({ value, values, startValue, endValue, dates }) => value || values?.length || startValue || endValue || dates?.length,
                )
            ) {
                return;
            }

            setIsLoadingFlashing(true);
            instrumentsCountGetRequest({
                instrumentTypeId: activeInstrumentType?.id,
                filters: getFiltersPayload(preparedFilters, getValues().filters),
            })
                .then(({ data }) => {
                    setInstrumentsCount(data.count ?? 0);
                })
                .catch(() => setInstrumentsCount(0))
                .finally(() => {
                    setIsLoadingFlashing(false);
                });
        }
    };

    useEffect(() => {
        handleRequestInstrumentsCount();
    }, [watch('filters')]);
    // endregion Работа с количеством инструментов

    // region Работа с фильтрами
    const dispatch = useAppDispatch();

    const [preparedFilters, setPreparedFilters] = useState<Record<FilterType['id'], FilterType>>({});

    useEffect(() => {
        const defaultPreparedFilters: Record<FilterType['id'], FilterType> = prepareCompliance(filters);
        setPreparedFilters(defaultPreparedFilters);

        const screener: Partial<ScreenerType> =
            store.getState().screeners.screeners.find(({ id }) => id === screenerId) ??
            ({ name: DEFAULT_SCREENER_NAME, filters: [], columns: [] } as Partial<ScreenerType>);

        if (filters?.length && isVisible) {
            const updatedScreenerFilters: IFiltersFormData[] | undefined = screener.filters?.map(
                ({ id, values, value, startValue, endValue }) => {
                    const updatedScreenerFilter: IFiltersFormData = { id, filterId: id };

                    switch (defaultPreparedFilters[id].type) {
                        case FilterTypes.INPUT:
                            updatedScreenerFilter.value = value ?? '';
                            break;
                        case FilterTypes.SINGLE_SELECT:
                        case FilterTypes.MULTIPLE_SELECT:
                            updatedScreenerFilter.values = values ?? [];
                            break;
                        case FilterTypes.RANGE:
                        default:
                            if (
                                [ValueTypes.DATE, ValueTypes.DATE_TIME, ValueTypes.TIME].includes(
                                    defaultPreparedFilters[id].valueType,
                                )
                            ) {
                                updatedScreenerFilter.dates = [
                                    startValue ? new Date(startValue) : null,
                                    endValue ? new Date(endValue) : null,
                                ];
                            } else {
                                updatedScreenerFilter.startValue = startValue;
                                updatedScreenerFilter.endValue = endValue;
                            }
                    }

                    return updatedScreenerFilter;
                },
            );
            reset({ name: getValues('name'), filters: updatedScreenerFilters });
        }
    }, [filters, screenerId, isVisible]);

    useEffect(() => {
        setScreenerName({
            locked: true,
            value: screener.name || DEFAULT_SCREENER_NAME,
        });
    }, [screener.name]);

    const handleSwitchFilter = (filterId: FilterType['id'], value?: boolean) => {
        if (value) {
            append({
                ...getScreenerFilterValue(preparedFilters[filterId], { id: filterId }),
                filterId,
            });
        } else {
            remove(fields.findIndex((el) => el.filterId === filterId));
        }
    };

    const handleUpdateFilterValue = (filterId: FilterType['id'], value: Omit<ScreenerFilterValueType, 'id'> = {}) => {
        const updatedScreenerFilters: IFiltersFormData[] = fields.map((el) => {
            if (el.filterId !== filterId) {
                return el;
            }

            if (preparedFilters[filterId]?.type === FilterTypes.RANGE) {
                const rangeValue = {
                    startValue: value.startValue ?? '',
                    endValue: value.endValue ?? '',
                };

                if (Object.values(rangeValue).some((val) => !!val)) {
                    return { id: el.id, ...rangeValue, filterId } as IFiltersFormData;
                }

                if (value.dates?.[0] || value.dates?.[0]) {
                    value.dates[0] = value.dates[0] ?? null;
                    value.dates[1] = value.dates[1] ?? null;
                }
            }

            return { id: el.id, ...value, filterId };
        });
        reset({ name: getValues('name'), filters: updatedScreenerFilters });
    };

    const handleClearFilters = () => {
        setValue('filters', []);
    };
    // endregion Работа с фильтрами

    const handleStartEditScreenerName = () => {
        setScreenerName({ ...screenerName, locked: false });
        setValue('name', screenerName.value);
    };

    const handleSubmit = () => {
        if (!isValid) {
            return;
        }

        const screener: ScreenerType | undefined = store
            .getState()
            .screeners.screeners.find(({ id }) => id === screenerId);

        const formScreener: Partial<RequestScreenerType> = {
            id: screenerId,
            name: screenerName.value,
            instrumentTypeId: activeInstrumentType?.id,
            filters: getFiltersPayload(preparedFilters, getValues().filters),
            columns: screener?.columns,
        };

        if (screenerId) {
            dispatch(updateScreenerThunk(formScreener)).then(({ payload }) => {
                // @ts-expect-error При отсутствии ошибки
                const { errorMessage } = payload?.status ?? {};

                if (errorMessage) {
                    console.error(errorMessage);
                } else {
                    onUpdateCurrentScreener?.(screenerId, { instrumentTypeId: screener!.instrumentTypeId });
                    closeModal();
                }
            });
        } else {
            dispatch(createScreenerThunk(formScreener)).then(({ payload }) => {
                // @ts-expect-error При отсутствии ошибки
                const { errorMessage } = payload?.status ?? {};

                if (errorMessage) {
                    console.error(errorMessage);
                } else {
                    onCreateScreener?.(screenerId ?? (payload as ResponseInfo<ScreenerType>).data.id ?? 0);
                    closeModal();
                }
            });
        }
    };

    return (
        <ModalWindow closeModal={closeModal} isVisible={isVisible} className={styles.createScreenerWindow}>
            <section className={classNames(styles.newScreenerModal)}>
                <div className={classNames('flex align-items-stretch', styles.headerWrapper)}>
                    <h1 className={classNames(styles.screenerName_title)}>
                        {screenerId ? 'Изменить скринер:' : 'Создать скринер:'}
                    </h1>
                    {screenerName.locked
? (
                        <>
                            <Tooltip
                                position="top"
                                mouseTrack
                                pt={{
                                    text: { className: styles.tooltip_text },
                                    arrow: { className: styles.tooltip_arrow },
                                }}
                                mouseTrackTop={0}
                                target={`.${styles.screenerName_input__disabled}`}
                                content={screenerName.value}
                            />
                            <span className={styles.screenerName_input__disabled} onClick={handleStartEditScreenerName}>
                                {screenerName.value}
                            </span>
                        </>
                    )
: (
                        <InputText
                            disabled={screenerName.locked}
                            className={classNames(styles.screenerName_input)}
                            maxLength={MAX_INPUT_VALUE_LENGTH}
                            {...register('name')}
                        />
                    )}
                    <div className={classNames('flex', styles.screenerName_actions)}>
                        {screenerName.locked
? (
                            <Button
                                onClick={handleStartEditScreenerName}
                                className={classNames(styles.screenerName_editButton)}
                            >
                                <span className="flex">
                                    <RenameIcon className="mr-3" />
                                    Переименовать
                                </span>
                            </Button>
                        )
: (
                            <>
                                <Button
                                    icon="pi pi-times"
                                    severity="secondary"
                                    outlined
                                    className={classNames(styles.screenerName_actionButton)}
                                    onClick={() => setScreenerName((prev) => ({ value: prev.value, locked: true }))}
                                />
                                <Button
                                    icon="pi pi-check"
                                    severity="info"
                                    outlined
                                    className={classNames(
                                        styles.screenerName_actionButton,
                                        styles.screenerName_actionButton__save,
                                    )}
                                    disabled={!watch('name').trim().length}
                                    onClick={() => {
                                        if (getValues('name').trim().length) {
                                            setScreenerName({ value: getValues('name'), locked: true });
                                        }
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>

                <SelectButton
                    value={activeInstrumentType}
                    onChange={(e) => setActiveInstrumentType(e.value as FiltersInstrumentType)}
                    optionLabel="name"
                    options={instrumentTypes}
                    allowEmpty={false}
                    optionDisabled={(option: FiltersInstrumentType) => !option.isAvailable}
                    dataKey="id"
                    className={classNames(styles.toolsTypeButton)}
                />

                <div className={classNames(styles.constructorWrapper)}>
                    <FiltersList
                        activeInstrumentTypeId={activeInstrumentType?.id}
                        fields={fields}
                        preparedFilters={preparedFilters}
                        handleSwitchFilter={handleSwitchFilter}
                    />
                    <FiltersFormList
                        preparedFilters={preparedFilters}
                        handleUpdateFilterValue={handleUpdateFilterValue}
                        handleSwitchFilter={handleSwitchFilter}
                        handleClearFilters={handleClearFilters}
                        fields={fields}
                        control={control}
                    />
                </div>

                <div className={classNames(styles.footer)}>
                    <span className={classNames('flex justify-content-center gap-2', styles.footerCount)}>
                        Отобрано{' '}
                        {isLoadingFlashing || !isValid
? (
                            <LoaderFlashing />
                        )
: (
                            <span className={classNames(styles.toolsLength)}>{instrumentsCount}</span>
                        )}
                        {declension(instrumentsCount, 'инструмент')}
                    </span>
                    <div className={classNames('flex justify-content-end', styles.actionsWrapper)}>
                        <Button
                            onClick={() => {
                                closeModal();
                                handleClearFilters();
                            }}
                            label="Отменить"
                            className={classNames(styles.cancelButton)}
                        />
                        <Button
                            onClick={handleSubmit}
                            label={screenerId ? 'Применить' : 'Создать'}
                            disabled={!screenerName.value || !isValid || isLoading}
                            className={classNames(styles.createButton)}
                        />
                    </div>
                </div>
            </section>
        </ModalWindow>
    );
};
