import { Divider, Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { Link } from 'react-router-dom';

import { ProgressOff, ProgressOn, Sparkline } from '../../../../shared/Icon';
import { ViewError, ViewLoader } from '../../../../shared/Layout';
import styles from './Profit.module.css';
import { useGetExpectationsQuery } from './useGetExpectationsQuery';
import { useGetMaxRiskQuery } from './useGetMaxRiskQuery';

type Props = {
    portfolioId: string;
};

export const Profit: FC<Props> = ({ portfolioId }) => {
    const {
        data: expectations,
        isError: isExpectationsError,
        isPending: isExpectationsPending,
        error: expectationsError,
    } = useGetExpectationsQuery(portfolioId);

    const {
        data: maxRisk,
        isError: isMaxRiskError,
        isPending: isMaxRiskPending,
        error: maxRiskError,
    } = useGetMaxRiskQuery(portfolioId);

    if (isExpectationsError || isMaxRiskError) {
        const subtitle = expectationsError
            ? 'Ошибка получения данных по ожиданию'
            : 'Ошибка получения данных по максимальной';

        return <ViewError error={expectationsError ?? maxRiskError} subtitle={subtitle} />;
    }

    if (isExpectationsPending || isMaxRiskPending) {
        return <ViewLoader />;
    }

    return (
        <Stack>
            <Stack
className={styles.metricBox} px={24} py={24}
gap={16}>
                <Text fz={19} fw={400} lh="18px">
                    {'Ожидания'}
                </Text>
                <Group wrap="nowrap" justify="space-between">
                    <Group wrap="nowrap" gap={6}>
                        <ProgressOn width={24} height={24} color="#42A62E" />
                        <Text fz={14}>{'Ожидаемая прибыль (5 лет)'}</Text>
                    </Group>
                    <Text fz={15} fw={600} c="#288C14">
                        {expectations?.expectedProfit.toLocaleString('ru', { maximumFractionDigits: 2 })}
                        {'%'}
                    </Text>
                </Group>
                <Divider />
                <Group wrap="nowrap" justify="space-between">
                    <Group wrap="nowrap" gap={6}>
                        <ProgressOff width={24} height={24} color="#F2433D" />
                        <Text fz={14}>{'Потенциальная просадка'}</Text>
                    </Group>
                    <Text fz={15} fw={600} c="#E42821">
                        {expectations?.riskPortfolio.toLocaleString('ru', { maximumFractionDigits: 2 })}
                        {'%'}
                    </Text>
                </Group>
            </Stack>

            <Stack className={styles.metricBox} px={24} py={20}>
                <Text fz={19} fw={400}>
                    {'Максимальная'}
                </Text>
                <Group justify="space-between" wrap="nowrap">
                    <Sparkline width={18} height={18} color="#F2433D" />
                    <Text style={{ whiteSpace: 'nowrap' }}>
                        {maxRisk?.dateFromMaxRisk ? new Date(maxRisk.dateFromMaxRisk).toLocaleDateString('ru') : '-'}
                        {'–'}
                        {maxRisk?.dateTillMaxRisk ? new Date(maxRisk.dateTillMaxRisk).toLocaleDateString('ru') : '-'}
                    </Text>
                    <Text fz={15} fw={600} c="#E42821">
                        {maxRisk?.maxRisk?.toLocaleString('ru', { maximumFractionDigits: 2 })}
                        {'%'}
                    </Text>
                </Group>
                <Text
                    component={Link}
                    to={`/portfolio/${portfolioId}/risk-metrics`}
                    fz={14}
                    fw={400}
                    c="#2F9CEB"
                    td="underline"
                >
                    {'Все просадки'}
                </Text>
            </Stack>
        </Stack>
    );
};
