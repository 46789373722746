import { Checkbox, Divider, Stack, Text } from '@mantine/core';
import { type UseFormReturnType } from '@mantine/form';
import { useQuery } from '@tanstack/react-query';
import { type FC } from 'react';

import { useApi } from '../../../api';
import { ViewError, ViewLoader } from '../../../shared/Layout';
import { type DynamicsFilterValues } from './DynamicsFilter.schema';

type Props = {
    form: UseFormReturnType<DynamicsFilterValues>;
};

export const DynamicsFilter: FC<Props> = ({ form }) => {
    const api = useApi();

    const { data, error, isError, isLoading, isPending, isRefetching } = useQuery({
        queryKey: ['portfolio', 'dynamics', 'check-marks'],
        queryFn: async ({ signal }) => await api.portfolio.dynamics.getCheckMarks({ signal }),
        select: (eventsData) => ({
            events: Object.entries(eventsData.events).map(([key, value]) => ({
                label: value,
                value: key,
            })),
            comparingInstruments: Object.entries(eventsData.comparingInstruments).map(([key, value]) => ({
                label: value,
                value: key,
            })),
        }),
    });

    if (isError) {
        return <ViewError error={error} />;
    }

    if (isLoading || isRefetching || isPending) {
        return <ViewLoader />;
    }

    return (
        <Stack
            w="100%"
            gap={27}
            px={36}
            py={30}
            style={{
                border: '1px solid #D8E1EB',
                borderRadius: '12px',
            }}
        >
            <Text
fw={400} fz={19} lh="18px"
c="#14171F">
                {'Показать события'}
            </Text>
            <Checkbox.Group mb={10} {...form.getInputProps('dynamicRequestDto.events')}>
                {data.events.map(({ value, label }) => (
                    <Checkbox
size="md" key={value} value={value}
label={label} />
                ))}
            </Checkbox.Group>
            <Divider />
            <Text
fw={400} fz={19} lh="18px"
c="#14171F">
                {'Сравнение доходностей'}
            </Text>
            <Checkbox.Group {...form.getInputProps('dynamicRequestDto.comparingInstruments')}>
                {data.comparingInstruments.map(({ value, label }) => (
                    <Checkbox
key={value} size="md" value={value}
label={label} />
                ))}
            </Checkbox.Group>
        </Stack>
    );
};
