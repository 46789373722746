import { Center } from '@mantine/core';
import { type FC, type ReactNode } from 'react';

import { MatrixDescriptionText } from './MatrixDescriptionText';

type Props = {
    children: ReactNode;
};

export const MatrixRowDescription: FC<Props> = ({ children }) => (
    <Center h={128} w={15}>
        <MatrixDescriptionText
            style={{
                transform: 'rotate(270deg)',
                whiteSpace: 'nowrap',
            }}
        >
            {children}
        </MatrixDescriptionText>
    </Center>
);
