import { type Column } from '@modules/Investorpro/shared/components/Table';
import { DataTable, type DataTableProps, type DataTableValueArray } from 'primereact/datatable';
import { Column as ColumnComponent } from 'primereact/column';
import classNames from 'classnames';
import { getNumberWithSpacing } from '@libs/utils';
import { type SecurityMarketType, type SelectedSecurity } from '@libs/types';
import { ColoredCheckbox } from '@libs/components/form/ColoredCheckbox';
import { Tooltip } from 'primereact/tooltip';
import { over } from 'lodash';
import { formatValueByType } from '@modules/Investorpro/shared/utils/formatValueByType.util';

import styles from './styles.module.scss';

export type TableWithCheckBoxColumn<T extends object> = Omit<Column<T>, 'emptyValue' | 'measure' | 'sortable'>;

type Props = {
    data: DataTableValueArray;
    selected: SelectedSecurity[];
    handleSelect: (security: SelectedSecurity) => void;
    isMaxSelected: boolean;
    columns: Array<TableWithCheckBoxColumn<any>>;
    withoutHeader?: boolean;
    colorOffsetIndex?: number;
    navigate?: (security: SelectedSecurity) => void;
    disabled?: boolean;
} & Omit<DataTableProps<any>, 'data' | 'columns'>;

export const TableWithCheckBox = ({
    data,
    columns,
    selected,
    handleSelect,
    colorOffsetIndex = 0,
    withoutHeader = false,
    navigate,
    isMaxSelected,
    disabled = false,
}: Props) => {
    const checkboxTemplate = (rowData: SelectedSecurity) => {
        const selectedRow = selected.find(
            (item) => item.secId === rowData.secId && item.market === rowData.market && item.board && rowData.board,
        );

        const checkboxClass = `checkbox${rowData.secId}`;

        const handleSelectChange = () => {
            if (!isMaxSelected || selectedRow) {
                handleSelect({
                    ...rowData,
                });
            }
        };

        return (
            <>
                {!selectedRow && isMaxSelected && (
                    <Tooltip
                        target={`.${checkboxClass}`}
                        position="top"
                        mouseTrack
                        mouseTrackTop={20}
                        content="К сожалению, на график нельзя добавить более 7-ми инструментов."
                        pt={{
                            text: { className: styles.tooltipCustom_text },
                            arrow: { className: styles.tooltipCustom_arrow },
                        }}
                    />
                )}
                <ColoredCheckbox
                    handleClick={handleSelectChange}
                    checkedColor={selectedRow?.color.table ?? 'black'}
                    checked={!!selectedRow}
                    disabled={disabled}
                    className={classNames(styles.checkbox, checkboxClass)}
                />
            </>
        );
    };

    return (
        <DataTable
            selection={selected}
            selectionMode="multiple"
            emptyMessage="Нет данных"
            className={styles.tableWithCheckbox}
            showHeaders={!withoutHeader}
            pt={{
                thead: {
                    className: classNames(styles.tableHeader, withoutHeader && styles.withoutHeader),
                },
                tbody: {
                    className: classNames(
                        styles.content,
                        withoutHeader && styles.withoutHeader,
                        navigate && styles.withNavigate,
                    ),
                },
            }}
            style={{ width: 'auto' }}
            value={data}
        >
            <ColumnComponent body={checkboxTemplate} />
            {columns.map(
                (
                    {
                        id,
                        label,
                        className,
                        type = 'string',
                        field,
                        render,
                        align = 'right',
                        width,
                        fontSize,
                        numberDivider,
                        fontWeight,
                    },
                    index,
                ) => {
                    const getRenderValue = () => {
                        if (render) return render;

                        return (option: any) => {
                            return formatValueByType({
                                type,
                                value: option[field!],
                                numberDivider,
                            });
                        };
                    };

                    return (
                        <ColumnComponent
                            key={id}
                            field={field as string}
                            body={(option, options) => (
                                <span
                                    // style={{
                                    //     width:
                                    //         typeof width === 'number' || (width && width.includes('px'))
                                    //             ? width
                                    //             : undefined,
                                    // }}
                                    onClick={index === 0 && navigate ? () => navigate(option) : undefined}
                                    className={styles[align]}
                                >
                                    {getRenderValue()(option, options)}
                                </span>
                            )}
                            header={label}
                            style={{ width, fontSize, fontWeight, maxWidth: width, padding: 0, overflow: 'hidden' }}
                            className={classNames(styles.tableColumn, className)}
                            align={align}
                        />
                    );
                },
            )}
        </DataTable>
    );
};
