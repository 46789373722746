import { RefObject, useEffect, useRef } from 'react';

type ListenerEvent = MouseEvent & {
    target: Element;
};

export const useClickOutside = (
    ref: RefObject<HTMLElement>,
    callback: (event: MouseEvent) => void,
    eventType = 'mousedown',
): void => {
    const handlerRef = useRef(callback);

    /**
     * Update callback if it changes
     */
    useEffect(() => {
        handlerRef.current = callback;
    });

    /**
     * Add and remove event listeners
     */
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                handlerRef.current(event as ListenerEvent);
            }
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handlerRef]);
};
