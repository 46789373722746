import {
    SecurityMarketType,
    type StockChangeLeader,
    type TopStockByVol,
    type StockMarketData as TStockMarketData,
} from '@libs/types';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as ArrowFilled } from '@modules/Investorpro/shared/images/svg/arrowFilled.svg';
import { round } from 'lodash';
import { CandleDataColumnType, InstrumentEngineType } from '@modules/Investorpro/types/quote.type';
import { HelpLineWrapper } from '@libs/components';
import { formatDateToQuery, transformArrayToObject } from '@libs/utils';
import { IntervalType, PeriodId } from '@libs/types/instrument.type';
import { subDays } from 'date-fns';
import { getCandlesData } from '@shared/services/quote.service';
import { calculateRelativeChange } from '@modules/Investorpro/shared/utils/slice.utils';
import { CustomTable } from '@modules/Investorpro/shared/components/CustomTable';
import { WithLoader } from '@libs/components/loaders/ViewLoader';
import { useLoading } from '@libs/utils/hooks/useLoading';

import { useSelectSecurity } from '../../../shared/utils/useSelectSecurity';
import styles from './styles.module.scss';
import { SecurityIndexes } from '../../../shared/components/SecurityIndexes';
import { SecurityPriceChangesChart } from '../../../shared/components/ChangesChart';
import { TableWithCheckBox } from '../../../shared/components/TableWithCheckBox';
import {
    GET_PRICE_FOR_PERIOD_LOADING_KEY,
    GROWTH_LEADER_COLUMNS,
    LOADING_KEY,
    STOCK_EMPTY_NULL_PRICE_VALUES,
    STOCK_EMPTY_ZERO_PRICE_VALUES,
    STOCKS_INDEXES,
    TOP_STOCK_BY_VOL_COLUMNS,
    TOP_STOCK_COLUMNS,
} from './constants';
import { Dividends } from './components/Dividends';
import { Events } from './components/Events';
import { type SelectedPeriod } from './type';
import { getStocksDataFacade } from './utils/facades';
import { type ChartDataPoint } from '../../../BondsMarketPage/components/BondMarketChartBlock/types';
import { getArrowPosByPeriodId } from '../../../shared/utils/fetch.utils';
import { calculatePriceChanges } from './utils';
import { useGetCurrentPrice } from './utils/useGetCurrentPrice';
import { compareSecurities } from '../../../shared/utils/securities.utils';

const CHART_LOADING_KEY = 'CHART_LOADER_KEY';
const DEFAULT_PERIOD = PeriodId.ONE_DAY;

export const StockMarketDashboard = () => {
    const [chartLoadingKeys, setChartLoadingKeys] = useState<string[]>([]);

    const setLoadingStatus = (key: string) => (status: boolean) => {
        status
            ? setChartLoadingKeys((prev) => [...prev, key])
            : setChartLoadingKeys((prev) => prev.filter((loadingKey) => key !== loadingKey));
    };

    const setChartLoadingStatus = setLoadingStatus(CHART_LOADING_KEY);
    const setPeriodsLoadingStatus = setLoadingStatus(GET_PRICE_FOR_PERIOD_LOADING_KEY);

    const isChartBlockLoading = chartLoadingKeys.includes(CHART_LOADING_KEY);
    const isLeadersTablesLoading = chartLoadingKeys.includes(GET_PRICE_FOR_PERIOD_LOADING_KEY);

    const { selectSecurity, selectedSecurities, isMaxSelected } = useSelectSecurity();
    const [loadedPeriods, setLoadedPeriods] = useState<PeriodId[]>([DEFAULT_PERIOD]);
    const [currentPeriod, setCurrentPeriod] = useState<SelectedPeriod>({
        id: DEFAULT_PERIOD,
        date: subDays(new Date(), 1),
    });
    const [leadersPeriod, setLeadersPeriod] = useState({
        id: DEFAULT_PERIOD,
        date: subDays(new Date(), 1),
    });
    const [allStocks, setAllStocks] = useState<TStockMarketData[]>([]);
    const [topStocks, setTopStocks] = useState<string[]>([]);
    const growthLeaders: StockChangeLeader[] = useMemo(() => {
        return [...allStocks]
            .filter(({ prevTradeDayPrice }) => prevTradeDayPrice[currentPeriod.id] !== null)
            .sort((a, b) => b.changeRelative[currentPeriod.id] - a.changeRelative[currentPeriod.id])
            .map(({ name, price, changeRelative }) => ({
                name,
                price,
                changeRelative: changeRelative[currentPeriod.id] ?? 0,
            }))
            .slice(0, 5);
    }, [allStocks, leadersPeriod.id]);
    const fallLeaders: StockChangeLeader[] = useMemo(() => {
        return [...allStocks]
            .filter(({ prevTradeDayPrice }) => prevTradeDayPrice[currentPeriod.id] !== null)
            .sort((a, b) => a.changeRelative[currentPeriod.id] - b.changeRelative[currentPeriod.id])
            .map(({ name, price, changeRelative }) => ({
                name,
                price,
                changeRelative: changeRelative[currentPeriod.id] ?? 0,
            }))
            .slice(0, 5);
    }, [allStocks, leadersPeriod.id]);
    const topStocksByVol: TopStockByVol[] = useMemo(() => {
        return [...allStocks]
            .filter(({ prevTradeDayPrice }) => prevTradeDayPrice[PeriodId.ONE_DAY] !== null)
            .sort((a, b) => b.vol - a.vol)
            .map(({ name, price, changeRelative, vol }) => ({
                name,
                price,
                changeRelative: changeRelative[PeriodId.ONE_DAY] ?? 0,
                vol,
            }))
            .slice(0, 5);
    }, [allStocks]);

    const changeCurrentPeriod = async (currentPeriod: SelectedPeriod) => {
        setCurrentPeriod(currentPeriod);

        if (allStocks.length > 0 && !loadedPeriods.includes(currentPeriod.id)) {
            try {
                setPeriodsLoadingStatus(true);
                const stocksData = await getStocksDataFacade(currentPeriod.date);
                const stocksDataObject = transformArrayToObject(stocksData, 'secId');
                const selectedStocksObject = transformArrayToObject(selectedSecurities, 'secId');
                const missedStocks = allStocks.filter(
                    ({ secId }) => (!stocksDataObject[secId] || stocksDataObject[secId]?.last === null) &&
                        !selectedStocksObject[secId],
                );

                const missedPrices = await Promise.allSettled(
                    missedStocks.map(async ({ secId, engine, board, market }) => {
                        const stockPrices = await getCandlesData({
                            secId,
                            engine,
                            board,
                            columns: [CandleDataColumnType.CLOSE],
                            from: formatDateToQuery(currentPeriod.date),
                            market,
                            till: new Date(),
                            interval: IntervalType.ONE_DAY,
                            arrayPos: getArrowPosByPeriodId(currentPeriod.id, IntervalType.ONE_DAY),
                        });

                        return (stockPrices.data.candles.data[0]?.[0]?.[0] as number) ?? null;
                    }),
                );

                const missedPricesObject = missedPrices.reduce<Record<string, number | null>>(
                    (acc, response, index) => {
                        acc[missedStocks[index].secId] = response.status === 'fulfilled' ? response.value : null;

                        return acc;
                    },
                    {},
                );

                setAllStocks((prev) => prev.map((stock) => {
                        const foundPrice = stocksDataObject[stock.secId]?.last ?? missedPricesObject[stock.secId];

                        return calculatePriceChanges(stock, currentPeriod.id, foundPrice ? Number(foundPrice) : null);
                    }),
                );
                setLoadedPeriods((prev) => [...prev, currentPeriod.id]);
            } catch (e) {
                console.error('e', e);
            } finally {
                setPeriodsLoadingStatus(false);
                console.log('render');
                setLeadersPeriod(currentPeriod);
            }
        } else {
            setLeadersPeriod(currentPeriod);
        }
    };

    const { startLoading, stopLoading } = useLoading(LOADING_KEY);
    useEffect(() => {
        const getStocksData = async () => {
            try {
                startLoading();
                const topStockData = await getStocksDataFacade(new Date());

                const result: TStockMarketData[] = topStockData.map(
                    ({ last, secId, issueSize, boardId, shortName, volume }) => {
                        return {
                            secId,
                            name: shortName,
                            board: boardId,
                            price: last,
                            capitalization: round(issueSize * (last ?? 0), 2),
                            vol: volume ?? 0,
                            issuesize: issueSize,
                            engine: InstrumentEngineType.STOCK,
                            market: SecurityMarketType.STOCK,
                            prevTradeDayPrice: {
                                ...STOCK_EMPTY_NULL_PRICE_VALUES,
                                [PeriodId.ONE_DAY]: last,
                            },
                            changeAbsolute: {
                                ...STOCK_EMPTY_ZERO_PRICE_VALUES,
                                [PeriodId.ONE_DAY]: 0,
                            },
                            changeRelative: {
                                ...STOCK_EMPTY_ZERO_PRICE_VALUES,
                                [PeriodId.ONE_DAY]: 0,
                            },
                        } as TStockMarketData;
                    },
                );
                setAllStocks(result);
                setTopStocks(
                    [...result]
                        .sort((a, b) => b.capitalization - a.capitalization)
                        .slice(0, 10)
                        .map(({ secId }) => secId),
                );
            } catch (e) {
                console.error('e', e);
            } finally {
                stopLoading();
            }
        };

        getStocksData();
    }, []);

    useGetCurrentPrice({
        securities: allStocks,
        setSecurityPrice: (stock, currentPrice) => {
            setAllStocks((prev) => {
                const security = prev.find((security) => compareSecurities(stock, security));
                const priceWasChanged = security?.price !== currentPrice;

                return priceWasChanged
                    ? prev.map((security) => {
                          if (compareSecurities(stock, security)) {
                              const relativeChanges: Partial<Record<PeriodId, number>> = {};
                              const absoluteChanges: Partial<Record<PeriodId, number>> = {};

                              Object.keys(security.prevTradeDayPrice)
                                  .filter((periodId) => security.prevTradeDayPrice[periodId as PeriodId] !== null)
                                  .forEach((periodId) => {
                                      const prevPrice = security.prevTradeDayPrice[periodId as PeriodId];
                                      const currentPrice = security.price;
                                      const relativeChange =
                                          prevPrice !== null ? calculateRelativeChange(currentPrice, prevPrice)! : 0;
                                      const absoluteChange = prevPrice ? currentPrice - prevPrice : 0;
                                      relativeChanges[periodId as PeriodId] = relativeChange;
                                      absoluteChanges[periodId as PeriodId] = absoluteChange;
                                  });

                              return {
                                  ...security,
                                  price: currentPrice,
                                  changeRelative: {
                                      ...security.changeRelative,
                                      ...relativeChanges,
                                  },
                                  changeAbsolute: {
                                      ...security.changeAbsolute,
                                      ...absoluteChanges,
                                  },
                                  capitalization: currentPrice * stock.issuesize,
                              };
                          }

                          return security;
                      })
                    : prev;
            });
        },
    });

    const updatePrevDayPriceByChartData = (data: Record<string, ChartDataPoint['data']>, period: PeriodId) => {
        setAllStocks((prev) => prev.map((stock) => {
                if (data[stock.secId] && data[stock.secId].length > 0 && data[stock.secId][0].price) {
                    return calculatePriceChanges(stock, period, data[stock.secId][0].price ?? null);
                }

                return stock;
            }),
        );
    };

    return (
        <div className={styles.stocksMarketWrapper}>
            <h1>Рынок акций</h1>
            <div className={styles.dataContainer}>
                <div className={classNames(styles.chartWrapper, 'flex', 'flex-column relative')}>
                    <SecurityPriceChangesChart
                        selectSecurity={selectSecurity}
                        width={980}
                        withPrice
                        getChartData={updatePrevDayPriceByChartData}
                        setCurrentPeriod={changeCurrentPeriod}
                        selectedSecurities={selectedSecurities}
                        isLoading={isChartBlockLoading}
                        periodsControlsDisabled={isLeadersTablesLoading}
                        setIsLoading={setChartLoadingStatus}
                    />
                </div>
                <div className={classNames(styles.stockIndexesWrapper, 'flex', 'flex-column')}>
                    <SecurityIndexes
                        title="Индексы акций"
                        selectedIndexes={selectedSecurities}
                        indexes={STOCKS_INDEXES}
                        defaultActiveIndex={0}
                        columnsWidth={['329px', '144px', '112px', '112px']}
                        className={styles.stocksIndexes}
                        handleSelectIndexes={selectSecurity}
                        isMaxSelected={isMaxSelected}
                        disabled={isChartBlockLoading}
                    />
                    <div className={styles.topStocksIndexes}>
                        <h2>Топ акций по капитализации</h2>
                        <TableWithCheckBox
                            data={topStocks.map((secId) => allStocks.find((stock) => stock.secId === secId) ?? {})}
                            colorOffsetIndex={3}
                            columns={TOP_STOCK_COLUMNS}
                            selected={selectedSecurities}
                            handleSelect={selectSecurity}
                            isMaxSelected={isMaxSelected}
                            disabled={isChartBlockLoading}
                        />
                    </div>
                </div>
            </div>
            <div className={classNames(styles.cashFlow, 'flex', 'flex-column')}>
                <div className={classNames(styles.firstRow, 'flex')}>
                    <HelpLineWrapper message="helpline.marketDashboards.stocks.growthFallLeaders">
                        <div className={classNames(styles.tableDataView, 'flex', 'flex-column', 'relative')}>
                            <WithLoader isLoading={isLeadersTablesLoading}>
                                <div className="flex align-items-center">
                                    <h2>Лидеры роста</h2>
                                    <ArrowFilled />
                                </div>
                                <CustomTable
                                    className={styles.fallLeader_table}
                                    data={growthLeaders}
                                    columns={GROWTH_LEADER_COLUMNS}
                                />
                            </WithLoader>
                        </div>
                    </HelpLineWrapper>
                    <HelpLineWrapper message="helpline.marketDashboards.stocks.growthFallLeaders">
                        <div className={classNames(styles.tableDataView, 'flex', 'flex-column', 'relative')}>
                            <WithLoader isLoading={isLeadersTablesLoading}>
                                <div className="flex align-items-center">
                                    <h2>Лидеры падения</h2>
                                    <ArrowFilled className={styles.fallLeader_arrow} />
                                </div>
                                <CustomTable
                                    data={fallLeaders}
                                    columns={GROWTH_LEADER_COLUMNS}
                                    className={styles.fallLeader_table}
                                />
                            </WithLoader>
                        </div>
                    </HelpLineWrapper>

                    <div className={styles.topByVolWrapper}>
                        <HelpLineWrapper message="helpline.marketDashboards.stocks.topByVol">
                            <div className={classNames(styles.tableDataView, 'flex', 'flex-column')}>
                                <h2>Топ акций по объему торгов</h2>
                                <CustomTable data={topStocksByVol} columns={TOP_STOCK_BY_VOL_COLUMNS} />
                            </div>
                        </HelpLineWrapper>
                    </div>
                </div>
                <div className={classNames(styles.secondRow, 'flex')}>
                    <Dividends />
                    <Events />
                </div>
            </div>
        </div>
    );
};
