/* eslint-disable @typescript-eslint/no-floating-promises */
import {
    type SelectedSecurity,
    type SecurityIndex,
    type SecurityMarketIndexesData,
    SecurityMarketType,
} from '@libs/types';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { getPrevTradeDayPrice } from '@modules/Investorpro/shared/facades';
import classNames from 'classnames';
import { InstrumentEngineType } from '@modules/Investorpro/types/quote.type';
import { changeColumnsWidth } from '@modules/Investorpro/shared/utils/table.utils';
import { useLoading } from '@libs/utils/hooks/useLoading';

import { BONDS_INDEXES_COLUMNS } from './columns';
import { TableWithCheckBox } from '../TableWithCheckBox';
import { useCurrentPriceUpdate } from '../../utils/useCurrentPriceUpdate';
import styles from './styles.module.scss';
import { SecurityBoards } from '../../constants';
import { type SecurityForSelect } from '../../utils/useSelectSecurity';

type Props = {
    selectedIndexes: SelectedSecurity[];
    handleSelectIndexes: (sec: SecurityForSelect) => void;
    title: string | ReactNode;
    indexes: SecurityIndex[];
    withoutTableHeader?: boolean;
    className?: string;
    columnsWidth?: string[];
    defaultActiveIndex?: number;
    handleLoadData?: () => void;
    isMaxSelected: boolean;
    loadingKey?: string;
    navigate?: (data: SecurityIndex) => void;
    priceDivider?: string;
    disabled?: boolean;
};

export const SecurityIndexes = ({
    selectedIndexes,
    handleSelectIndexes,
    title,
    indexes,
    withoutTableHeader,
    navigate,
    className,
    defaultActiveIndex,
    columnsWidth,
    handleLoadData,
    priceDivider,
    loadingKey,
    isMaxSelected,
    disabled,
}: Props) => {
    const [securityIndexes, setSecurityIndexes] = useState<SecurityMarketIndexesData[]>(() => {
        const indexesWithCandlesData = indexes.map(({ board, market, engine, interval, ...sec }) => ({
            ...sec,
            price: 0,
            prevTradeDayPrice: null,
            board: board ?? SecurityBoards.SNDX,
            market: market ?? SecurityMarketType.INDEX,
            engine: engine ?? InstrumentEngineType.STOCK,
            changeRelative: 0,
            interval,
            changeAbsolute: 0,
        }));

        if (defaultActiveIndex !== undefined && indexesWithCandlesData[defaultActiveIndex]) {
            handleSelectIndexes(indexesWithCandlesData[defaultActiveIndex]);
        }

        return indexesWithCandlesData;
    });
    const [prevDayPriceLoaded, setPrevDayPriceLoaded] = useState(false);
    const { startLoading, stopLoading } = useLoading(loadingKey ?? 'bond-market-indexes-prices');

    useEffect(() => {
        startLoading();

        Promise.allSettled(
            securityIndexes.map(
                async ({ secId, board, engine, market, interval }) => await getPrevTradeDayPrice({
                        secId,
                        board,
                        market,
                        interval,
                        engine,
                    }),
            ),
        ).then((resp) => {
            const updatedSecurityIndexes = resp.map((item, index) => {
                if (item.status === 'fulfilled') {
                    const [price, tradeDate] = item.value;

                    return {
                        ...securityIndexes[index],
                        price,
                        tradeDate,
                        prevTradeDayPrice: price,
                    } as SecurityMarketIndexesData;
                } else {
                    return securityIndexes[index];
                }
            });
            setSecurityIndexes(updatedSecurityIndexes);
            stopLoading();
            setPrevDayPriceLoaded(true);
            handleLoadData?.();
        });
    }, [indexes, loadingKey]);

    const columns = useMemo(() => {
        return (columnsWidth ? changeColumnsWidth(BONDS_INDEXES_COLUMNS, columnsWidth) : BONDS_INDEXES_COLUMNS).map(
            (col, index) => index === 0
                    ? {
                          ...col,
                          render: (obj: SecurityIndex) => (
                              <span
                                  style={{
                                      width:
                                          col.width &&
                                          ((typeof col.width === 'string' && col.width.includes('px')) ||
                                              typeof col.width === 'number')
                                              ? col.width
                                              : '100%',
                                  }}
                                  className={styles.noTextOverflow}
                              >
                                  {obj.name}
                              </span>
                          ),
                      }
                    : col.field === 'price'
                      ? {
                            ...col,
                            numberDivider: priceDivider,
                        }
                      : col,
        );
    }, [columnsWidth, priceDivider]);

    useCurrentPriceUpdate({
        securities: securityIndexes,
        hasPrevTradePrice: prevDayPriceLoaded,
        updateSecurities: setSecurityIndexes,
    });

    const renderTitle = () => {
        if (typeof title === 'string') {
            return <h2>{title}</h2>;
        }

        return title;
    };

    return (
        <div className={classNames(styles.indexesWrapper, className)}>
            {renderTitle()}
            <TableWithCheckBox
                withoutHeader={withoutTableHeader}
                data={securityIndexes}
                columns={columns}
                navigate={navigate}
                selected={selectedIndexes}
                handleSelect={handleSelectIndexes}
                isMaxSelected={isMaxSelected}
                disabled={disabled}
            />
        </div>
    );
};
