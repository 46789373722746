import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { PlusIcon } from 'primereact/icons/plus';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useSelector } from 'react-redux';
import {
    getInstrumentsThunk,
    getScreenerColumnsThunk,
    getScreenerFiltersThunk,
    getScreenersThunk,
} from '@store/store/thunk/investorpro/screeners.thunk';
import { type RootState, store, useAppDispatch, useAppSelector } from '@store/store';
import { HelpLineWrapper } from '@libs/components';
import { NewScreenerModal } from '@modules/Investorpro/modules/ScreenersPage/components/NewScreenerModal';
import { useHelplineDefaultMessage } from '@libs/utils/hooks/useDefaultMessage';
import { useAuthorize } from '@libs/utils';
import { changeCurrentPage, resetInstruments } from '@store/store/slices/investorpro/screeners.slice';
import { type PageableType } from '@libs/types';

import { ScreenerPage } from './components/Screener';
import styles from './styles.module.scss';
import { Empty } from './components/Empty';
import { type FilterType, type ScreenerType } from './types';

export const ScreenersPage = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);

    const { isAuthorized } = useAuthorize();

    const defaultMessage =
        'Скринер позволяет искать инструменты из списка доступных по набору различных параметров. По выбранным инструментам можно вывести нужную информацию в колонках.';

    useHelplineDefaultMessage(defaultMessage);
    const screeners: ScreenerType[] = useSelector((state: RootState) => state.screeners.screeners);

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (isAuthorized) {
            dispatch(getScreenersThunk()).then(() => {
                dispatch(getScreenerColumnsThunk());
            });
        }
    }, [isAuthorized, dispatch]);

    useEffect(() => {
        dispatch(changeCurrentPage(1));
        dispatch(resetInstruments());
    }, [activeIndex, dispatch]);

    const filters: FilterType[] = useAppSelector((state: RootState) => state.screeners.filters.filters);
    useEffect(() => {
        if (isAuthorized && !filters.length) {
            dispatch(getScreenerFiltersThunk());
        }
    }, [isAuthorized, dispatch]);

    const handleAddScreener: () => void = () => setIsOpenCreateModal((prev) => !prev);

    const handleCreateScreener = (screenerId?: ScreenerType['id']) => {
        const currentActiveIndex: number = store
            .getState()
            .screeners.screeners.findIndex(({ id }) => id === screenerId);

        if (currentActiveIndex > -1) {
            setActiveIndex(currentActiveIndex);
        }
        setIsOpenCreateModal(false);
    };

    const handleUpdateCurrentScreener = (
        screenerId: ScreenerType['id'],
        body: { instrumentTypeId: ScreenerType['instrumentTypeId']; pageable?: Partial<PageableType> },
    ) => {
        if (screenerId && body.instrumentTypeId) {
            if (store.getState().screeners.instruments.pageNumber > 1) {
                dispatch(changeCurrentPage(1));
            } else {
                dispatch(getInstrumentsThunk({ screenerId, body }));
            }
        }
    };

    const items = [
        { label: 'Главная', url: '/', disabled: true },
        { label: 'Скринеры', url: '/screeners' },
    ];

    return (
        <div className="main-wrapper">
            <div>
                <div className={classNames('flex')}>
                    <div className={classNames(styles.pageTitle)}>
                        <BreadCrumb
                            model={items}
                            separatorIcon={<span className={styles.breadcrumbSeparator}>/</span>}
                        />
                        <div>Скринеры</div>
                    </div>
                </div>

                {screeners.length
? (
                    <div className={classNames('flex flex-column', styles.wrapper)}>
                        <TabView
                            pt={{
                                nav: { className: styles.tabView_nav },
                                navContainer: { className: styles.tabView_navContainer },
                                panelContainer: { className: styles.tabView_panelContainer },
                            }}
                            className={styles.tabView}
                            activeIndex={activeIndex}
                            onTabChange={({ index }) => setActiveIndex(index)}
                            scrollable
                        >
                            {screeners.map((screener) => {
                                return (
                                    <TabPanel
                                        key={`TabPanel-${screener.id}`}
                                        header={
                                            screener.name.length > 30
                                                ? screener.name.slice(0, 30) + '...'
                                                : screener.name
                                        }
                                        className={styles.tabPanel}
                                    >
                                        <ScreenerPage
                                            screenerId={screener.id}
                                            key={screener.id}
                                            onDeleteScreener={() => setActiveIndex(0)}
                                            onUpdateCurrentScreener={handleUpdateCurrentScreener}
                                            onCreateScreener={handleCreateScreener}
                                        />
                                    </TabPanel>
                                );
                            })}
                        </TabView>
                        <HelpLineWrapper message="helpline.screeners.addScreener" style={{ position: 'absolute' }}>
                            <Button onClick={() => handleAddScreener()} className={styles.addScreenerButton}>
                                <PlusIcon />
                            </Button>
                        </HelpLineWrapper>
                    </div>
                )
: (
                    <Empty onNewScreener={handleAddScreener} />
                )}
                {isOpenCreateModal && (
                    <NewScreenerModal
                        isVisible={isOpenCreateModal}
                        closeModal={() => setIsOpenCreateModal(false)}
                        onCreateScreener={handleCreateScreener}
                    />
                )}
            </div>
        </div>
    );
};
