/* eslint-disable no-nested-ternary */
import { Box, Button, Group, Stack, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { type SortingState } from '@tanstack/react-table';
import { useState, type FC } from 'react';
import { Link } from 'react-router-dom';

import { useApi } from '../api/index';
import { Breadcrumbs } from '../shared/Breadcrumbs';
import { Plus } from '../shared/Icon';
import { ViewError } from '../shared/Layout';
import { PortfolioListEmptyStub } from './PortfolioTable/PortfolioListEmptyStub';
import { PortfolioTable } from './PortfolioTable/PortfolioTable';

export const PortfolioList: FC = () => {
    const api = useApi();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [page, setPage] = useState(1);

    const breadcrumbs = [
        { title: 'Главная', href: '/portfolio' },
        { title: 'Портфели', href: '/portfolio' },
    ];

    const portfolioListQuery = useQuery({
        queryKey: ['portfolio', 'list', sorting, page],
        queryFn: async () => await api.portfolio.getPortfolioList({
                params: {
                    page,
                    size: 20,
                    sort: sorting.map((sortItem) => `${sortItem.id},${sortItem.desc ? 'desc' : 'asc'}`)[0],
                },
            }),
    });

    return (
        <Stack align="center" gap={66}>
            <Group justify="space-between" w="100%" align="center">
                <Stack gap={30}>
                    <Breadcrumbs items={breadcrumbs} />
                    <Text fz="24px" lh="24px" fw="400">
                        {'Список портфелей'}
                    </Text>
                </Stack>

                {portfolioListQuery.data?.totalElements
? (
                    <Button
                        component={Link}
                        to="create"
                        variant="outline"
                        styles={{ section: { color: '#2F9CEB' } }}
                        leftSection={<Plus width={24} height={24} />}
                    >
                        {'Добавить портфель'}
                    </Button>
                )
: null}
            </Group>
            {portfolioListQuery.data?.content.length ||
            portfolioListQuery.isLoading ||
            portfolioListQuery.isRefetching
? (
                <PortfolioTable
                    data={portfolioListQuery.data?.content ?? []}
                    page={page}
                    onPageChange={setPage}
                    totalPages={portfolioListQuery.data?.totalPages ?? 0}
                    sorting={sorting}
                    setSorting={setSorting}
                    isLoading={portfolioListQuery.isLoading || portfolioListQuery.isRefetching}
                />
            )
: portfolioListQuery.isError
? (
                <Box w="100%" h="60vh">
                    <ViewError error={portfolioListQuery.error} subtitle="Ошибка получения списка портфелей" />
                </Box>
            )
: (
                <PortfolioListEmptyStub />
            )}
        </Stack>
    );
};
