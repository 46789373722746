import { format, differenceInHours, differenceInDays, differenceInMonths, differenceInYears, isSameDay } from 'date-fns';
import { PeriodId } from '@libs/types/instrument.type';
import { formatTime } from './format.util';


const SHORT_MONTHS = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

export const createPrintTicksCallback = (
    cb: (currentDate: Date, previousDate: Date, index: number, values: Array<{ value: number }>) => string,
) => (step: number, disabledAutoSkip: boolean) =>
    (value: number, index: number, values: Array<{ value: number }>) => {
    const hasCurrentDate = !disabledAutoSkip ||
        index === 0 ||
        index % step === 0;

    const currentDate = hasCurrentDate ? new Date(value) : null
    const previousDate = values[index - step]?.value ? new Date(values[index - step].value) : new Date(value);

    if (!currentDate) {
        return ''
    }

    return cb(currentDate, previousDate, index, values);
}

export const printTimeAxisTicksCallback = (currentDate: Date, previousDate: Date) => {
    const isDayChanged = currentDate.getDate() !== previousDate.getDate();

    if (isDayChanged) {
        return format(currentDate, 'dd.MM.yyyy');
    }

    return format(currentDate, 'HH:mm');
}

export const printDayAxisTicksCallback = (currentDate: Date, previousDate: Date) => {
    const isMonthChanged = currentDate.getMonth() !== previousDate.getMonth();
    const isYearChanged = currentDate.getFullYear() !== previousDate.getFullYear();

    if (isYearChanged) {
        return `${currentDate.getFullYear()}`;
    }

    if (isMonthChanged) {
        return SHORT_MONTHS[currentDate.getMonth()] ?? '';
    }

    return currentDate.getDate().toString();
}

export const printMonthAxisTicksCallback = (currentDate: Date, previousDate: Date) => {
    const isYearChanged = currentDate.getFullYear() !== previousDate.getFullYear();

    if (isYearChanged) {
        return `${currentDate.getFullYear()}`;
    }

    return SHORT_MONTHS[currentDate.getMonth()] ?? '';
}

export const printYearAxisTicksCallback = (currentDate: Date, previousDate: Date, index: number) => {
    const isYearChanged = currentDate.getFullYear() !== previousDate.getFullYear();

    if (isYearChanged || index === 0) {
        return currentDate.getFullYear().toString();
    }

    return '';
}

export const printDayAxisTicks = createPrintTicksCallback(printDayAxisTicksCallback)

export const printMonthAxisTicks = createPrintTicksCallback(printMonthAxisTicksCallback)

export const printYearAxisTicks = createPrintTicksCallback(printYearAxisTicksCallback)

export const printTimeAxisTicks = createPrintTicksCallback(printTimeAxisTicksCallback)

export const getPeriodIdByDateInterval = (
    startDate: Date,
    endDate: Date,
) => {
    const deltaInHours = Math.abs(differenceInHours(endDate, startDate));

    if (deltaInHours <= 12) {
        return PeriodId.ONE_DAY;
    }

    const deltaInDays = Math.abs(differenceInDays(endDate, startDate));

    if (deltaInDays <= 5) {
        return PeriodId.FIVE_DAYS;
    }

    if (deltaInDays <= 31) {
        return PeriodId.ONE_MONTH;
    }

    const deltaInMonths = Math.abs(differenceInMonths(endDate, startDate));

    if (deltaInMonths <= 3) {
        return PeriodId.THREE_MONTHS;
    }

    if (deltaInMonths <= 6) {
        return PeriodId.SIX_MONTHS;
    }

    if (deltaInMonths <= 12) {
        return PeriodId.ONE_YEAR;
    }

    const deltaInYears = Math.abs(differenceInYears(endDate, startDate));

    if (deltaInYears <= 1.5) {
        return PeriodId.ONE_YEAR;
    }

    if (deltaInYears <= 2) {
        return PeriodId.TWO_YEARS;
    }

    if (deltaInYears <= 3) {
        return PeriodId.THREE_YEARS;
    }

    if (deltaInYears <= 5) {
        return PeriodId.FIVE_YEARS;
    }

    if (deltaInYears <= 10) {
        return PeriodId.TEN_YEARS;
    }

    return PeriodId.ALL;
}

export const getPeriodIdByData = <T extends object>(
    data: T[],
    defaultPeriod: PeriodId | null,
    dateKey: keyof T = 'x' as keyof T,
) => {
    try {
        const startDate = new Date(data[0][dateKey] as string);
        const endDate = new Date(data[data.length - 1][dateKey] as string);

        return getPeriodIdByDateInterval(startDate, endDate);
    } catch (e) {
        return defaultPeriod ?? PeriodId.ALL;
    }
}

export const createPrintAxisDashboardTicksCallback = (
    cb: (currentDate: Date, previousDate: Date, index: number, values: Array<{ value: number }>) => string,
) => (countOfTicks = 1, showLastTick = false) =>
    (value: number, index: number, values: Array<{ value: number }>) => {
    const step = Math.ceil(values.length / countOfTicks);

    const currentDate = new Date(value);
    const previousDate = values[index - step]?.value ? new Date(values[index - step].value) : new Date(value);

    return cb(currentDate, previousDate, index, values);
}

export const printAxisDashboardTimeTicks = createPrintAxisDashboardTicksCallback(printTimeAxisTicksCallback)

export const printAxisDashboardDayTicks = createPrintAxisDashboardTicksCallback((currentDate, previousDate) => {
    const isMonthChanged = currentDate.getMonth() !== previousDate.getMonth();
    const isYearChanged = currentDate.getFullYear() !== previousDate.getFullYear();

    if (isYearChanged) {
        return `${currentDate.getFullYear()}`;
    }

    if (isMonthChanged) {
        return SHORT_MONTHS[currentDate.getMonth()] ?? '';
    }

    return currentDate.getDate().toString();
})

export const printAxisDashboardMonthTicks = createPrintAxisDashboardTicksCallback(printMonthAxisTicksCallback)

export const printAxisDashboardYearTicks = createPrintAxisDashboardTicksCallback(printYearAxisTicksCallback)

export const isPeriodLessThenAnother = (firstPeriod: PeriodId, secondPeriod: PeriodId) => {
    switch (firstPeriod) {
        case PeriodId.ONE_DAY:
            return true;
        case PeriodId.FIVE_DAYS:
            return secondPeriod !== PeriodId.ONE_DAY;
        case PeriodId.ONE_MONTH:
            return ![PeriodId.ONE_DAY, PeriodId.FIVE_DAYS].includes(secondPeriod);
        case PeriodId.THREE_MONTHS:
            return ![PeriodId.ONE_DAY, PeriodId.FIVE_DAYS, PeriodId.ONE_MONTH].includes(secondPeriod);
        case PeriodId.SIX_MONTHS:
            return ![
                PeriodId.ONE_DAY,
                PeriodId.FIVE_DAYS,
                PeriodId.ONE_MONTH,
                PeriodId.THREE_MONTHS,
            ].includes(secondPeriod);
        case PeriodId.SNG:
            return ![
                PeriodId.ONE_DAY,
                PeriodId.FIVE_DAYS,
                PeriodId.ONE_MONTH,
                PeriodId.THREE_MONTHS,
                PeriodId.SIX_MONTHS,
            ].includes(secondPeriod);
        case PeriodId.ONE_YEAR:
            return [
                PeriodId.TWO_YEARS,
                PeriodId.THREE_YEARS,
                PeriodId.FIVE_YEARS,
                PeriodId.TEN_YEARS,
                PeriodId.ALL,
            ].includes(secondPeriod);
        case PeriodId.THREE_YEARS:
            return [
                PeriodId.FIVE_YEARS,
                PeriodId.TEN_YEARS,
                PeriodId.ALL,
            ].includes(secondPeriod);
        case PeriodId.FIVE_YEARS:
            return [PeriodId.ALL, PeriodId.TEN_YEARS].includes(secondPeriod);
        case PeriodId.TEN_YEARS:
            return secondPeriod === PeriodId.ALL;
        case PeriodId.ALL:
            return false
        default:
            return false;
    }
}
