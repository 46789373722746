import { ReactComponent as CheckMark } from '@shared/images/svg/checkMarkWhite.svg';
import { type ButtonHTMLAttributes } from 'react';
import { classNames } from 'primereact/utils';

import styles from './styles.module.scss';

type Props = {
    checked: boolean;
    handleClick: () => void;
    checkedColor: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ColoredCheckbox = ({ checked, handleClick, className, checkedColor, ...props }: Props) => {
    return (
        <button
            {...props}
            className={classNames(styles.checkbox, className)}
            tabIndex={0}
            onClick={handleClick}
            style={{
                backgroundColor: checked ? checkedColor : undefined,
                borderColor: checked ? checkedColor : undefined,
            }}
        >
            {checked && <CheckMark width={12} height={12} />}
        </button>
    );
};
