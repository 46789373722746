import { type Alert } from '@modules/Investorpro/shared/components/AlertsSidebar/services/types';
import { createSlice } from '@reduxjs/toolkit';
import {
    createAlertThunk,
    deleteAlertThunk,
    getAlertsListThunk,
    readAlertThunk,
    updateAlertThunk,
} from '@store/store/thunk/investorpro/alerts.thunk';

export type State = {
    fulfilledAlerts: Alert[];
    pendingAlerts: Alert[];
    isLoading: boolean;
    error: string | null;
}

export const initialState: State = {
    fulfilledAlerts: [],
    pendingAlerts: [],
    isLoading: false,
    error: null,
}

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAlertsListThunk.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAlertsListThunk.fulfilled, (state, action) => {
            state.pendingAlerts = action.payload.pending;
            state.fulfilledAlerts = action.payload.fulfilled;
            state.isLoading = false;
        });
        builder.addCase(getAlertsListThunk.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message ?? 'thunk error';
        });
        builder.addCase(createAlertThunk.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(createAlertThunk.fulfilled, (state, action) => {
            state.pendingAlerts = [...state.pendingAlerts, action.payload];
            state.isLoading = false;
        });
        builder.addCase(createAlertThunk.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message ?? 'thunk error';
        });
        builder.addCase(deleteAlertThunk.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(deleteAlertThunk.fulfilled, (state, action) => {
            state.pendingAlerts = state.pendingAlerts.filter((alert) => alert.id !== action.payload)
            state.fulfilledAlerts = state.fulfilledAlerts.filter((alert) => alert.id !== action.payload)
            state.isLoading = false;
        });
        builder.addCase(deleteAlertThunk.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message ?? 'thunk error';
        });
        builder.addCase(updateAlertThunk.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(updateAlertThunk.fulfilled, (state, action) => {
            state.pendingAlerts = state.pendingAlerts.map((alert) => {
                if (alert.id === action.payload.id) {
                    return {
                        ...alert,
                        ...action.payload,
                    };
                }

                return alert;
            });
            state.isLoading = false;
        });
        builder.addCase(updateAlertThunk.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message ?? 'thunk error';
        });
        builder.addCase(readAlertThunk.pending, (state, action) => {
            state.fulfilledAlerts = state.fulfilledAlerts.map((alert) => {
                if (alert.id === action.meta.arg.id) {
                    return {
                        ...alert,
                        isRead: true,
                    };
                }

                return alert;
            });
            state.isLoading = true;
        });
        builder.addCase(readAlertThunk.fulfilled, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(readAlertThunk.rejected, (state, action) => {
            state.fulfilledAlerts = state.fulfilledAlerts.map((alert) => {
                if (alert.id === action.meta.arg.id) {
                    return {
                        ...alert,
                        isRead: false,
                    };
                }

                return alert;
            });
            state.isLoading = false;
            state.error = action.error.message ?? 'thunk error';
        });
    },
});

export const alertsReducer = alertsSlice.reducer;
