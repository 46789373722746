import { Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { type InstrumentLiquidity } from '@modules/Investorpro/modules/PortfolioPage/entities';

const columnHelper = createColumnHelper<InstrumentLiquidity>();

export const useInstrumentsLiquidityColumns = () => useMemo(
        () => [
            columnHelper.accessor('name', {
                enableSorting: false,
                id: 'name',
                header: 'Инструмент',
                cell: (props) => (
                    <Text
fz="14px" lh="16px" fs="normal"
style={{ textAlign: 'start' }}>
                        {props.getValue() ?? ''}
                    </Text>
                ),
            }),
            columnHelper.accessor('liquidity', {
                enableSorting: false,
                id: 'liquidity',
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                header: 'Ликвидность',
                cell: (props) => (
                    <Text
style={{ textAlign: 'end' }} fz="14px" lh="16px"
fs="normal">
                        {props.getValue() ?? ''}
                    </Text>
                ),
            }),
            columnHelper.accessor('amount', {
                enableSorting: false,
                id: 'amount',
                meta: { headerCellStyles: { justifyContent: 'flex-end' } },
                header: 'Объём средств',
                cell: (props) => (
                    <Text
style={{ textAlign: 'end' }} fz="14px" lh="16px"
fs="normal">
                        {props.getValue().toLocaleString('ru') ?? ''}
                        {' ('}
                        {props.row.original.percent.toLocaleString('ru')}
                        {'%)'}
                    </Text>
                ),
            }),
        ],
        [],
    );
