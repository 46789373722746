import { type GeoDiversificationResponse } from '../../../../../PortfolioPage/entities';
import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getGeoDiversification: ApiFactory<
    ApiEndpoint<GeoDiversificationResponse, void, { id: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<GeoDiversificationResponse>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/diversification/geo/${params.id}`,
        { signal },
    );

    return data;
};
