import { Fragment, useEffect, useState } from 'react';
import { useAppDispatch } from '@store/store';
import classNames from 'classnames';
import { SecurityMarketType, type StockEvent } from '@libs/types';
import { getStockEvents } from '@modules/Investorpro/modules/BondStockPage/StockPage/services/stock.service';
import { addDays } from 'date-fns';
import { getIndexForInstrument } from '@libs/services/instrument.service';
import { useLoading } from '@libs/utils/hooks/useLoading';

import styles from './styles.module.scss';
import { LOADING_KEY, MONTHS } from './constants';

export const Events = () => {
    const [hasError, setHasError] = useState(false);
    const [events, setEvents] = useState<StockEvent[]>([]);
    const dispatch = useAppDispatch();
    const { startLoading, stopLoading } = useLoading(LOADING_KEY);

    useEffect(() => {
        const getStockEventsRequest = async () => {
            try {
                startLoading();
                const dateTo = new Date();
                const response = await getStockEvents({
                    filterDecl: ['Финансовые результаты'],
                    filter: ['МСФО', 'РСБУ'],
                    dateFrom: addDays(dateTo, -180),
                    dateTo,
                });

                const events = response.data
                    .sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime())
                    .slice(0, 10);

                const uniqueFininstIds = Array.from(new Set(events.map(({ fininstId }) => fininstId)));

                const indexesResponse = await Promise.all(
                    uniqueFininstIds.map(
                        async (fininstId) => await getIndexForInstrument({
                                fininstId,
                                toolSelection: SecurityMarketType.STOCK,
                            }),
                    ),
                );

                const eventData: StockEvent[] = events.map(({ fininstId, eventDate }) => {
                    const indexResponse = indexesResponse.find(
                        (response) => response.data[0]?.fininstId === +fininstId,
                    )!.data;

                    return {
                        date: new Date(eventDate),
                        name: indexResponse[0].shortTitle,
                        fininstId,
                        tickers: indexResponse.map(({ secId }) => secId),
                    };
                });
                setEvents(eventData);
            } catch (e) {
                console.error('e', e);
                setHasError(true);
            } finally {
                stopLoading();
            }
        };
        getStockEventsRequest();
    }, [dispatch]);

    if (hasError) {
        return null;
    }

    return (
        <div className={classNames(styles.eventsWrapper, 'flex', 'flex-column')}>
            <h2>Корпоративные события. Данные отчетность</h2>
            <div className={styles.stockEvents}>
                {events.map(({ date, name, tickers, fininstId }, index) => {
                    const day = date.getUTCDate();
                    const month = MONTHS[date.getUTCMonth()];

                    return (
                        <Fragment key={`${fininstId}_${index}`}>
                            <div className={classNames('flex', 'flex-column', 'align-items-center', styles.item_date)}>
                                <span className={styles.date_day}>{day}</span>
                                <span className={styles.date_month}>{month}</span>
                            </div>
                            <div className={classNames('flex', 'flex-column', styles.item_info)}>
                                <h4>{name}</h4>
                                <div className={classNames('flex')}>
                                    {tickers.map((ticker) => (
                                        <span key={ticker}>{ticker}</span>
                                    ))}
                                </div>
                            </div>
                            {index % 2 === 0 && <div />}
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};
