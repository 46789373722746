import { type FC } from 'react';

import {
    CommunicationsIcon,
    ConsumerResourcesIcon,
    EnergyResourcesIcon,
    EnergySectorIcon,
    FinancialServicesIcon,
    HealthcareIcon,
    IndustryIcon,
    OtherIcon,
    PublicUtilitiesIcon,
    RealEstateIcon,
    ResourcesAndMaterialsIcon,
    TechnologiesIcon,
    TelevisionRadioIcon,
    TransportIcon,
} from '../../shared/Icon';

export enum Continents {
    EUROPE = 'europe',
    ASIA = 'asia',
    MIDDLE_EAST_AND_AFRICA = 'middle_east_and_africa',
    NORTH_AMERICA = 'north_america',
    LATIN_AMERICA = 'south_america',
}

export const CONTINENTS = {
    [Continents.EUROPE]: { name: 'Европа', color: '#2F9CEB' },
    [Continents.ASIA]: { name: 'Азия', color: '#BB8DF7' },
    [Continents.MIDDLE_EAST_AND_AFRICA]: { name: 'Ближний Восток и Африка', color: '#F2433D' },
    [Continents.NORTH_AMERICA]: { name: 'Северная Америка', color: '#42A62E' },
    [Continents.LATIN_AMERICA]: { name: 'Латинская Америка', color: '#A5B1C0' },
};

export type CorrelationTools = {
    name: string;
    correlation: number;
    correlationPair: string;
};

export enum Sectors {
    COMMUNICATIONS = 'COMMUNICATIONS',
    CONSUMER_RESOURCES = 'CONSUMER_RESOURCES',
    ENERGY_RESOURCES = 'ENERGY_RESOURCES',
    ENERGY_SECTOR = 'ENERGY_SECTOR',
    FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
    HEALTHCARE = 'HEALTHCARE',
    INDUSTRY = 'INDUSTRY',
    OTHER = 'OTHER',
    PUBLIC_UTILITIES = 'PUBLIC_UTILITIES',
    REAL_ESTATE = 'REAL_ESTATE',
    RESOURCES_AND_MATERIALS = 'RESOURCES_AND_MATERIALS',
    TECHNOLOGIES = 'TECHNOLOGIES',
    TELEVISION_RADIO = 'TELEVISION_RADIO',
    TRANSPORT = 'TRANSPORT',
}

export const sectors: Record<Sectors, { label: string; Component?: FC }> = {
    [Sectors.ENERGY_RESOURCES]: {
        label: 'Энергоресурсы',
        Component: EnergyResourcesIcon,

    },
    [Sectors.REAL_ESTATE]: {
        label: 'Недвижимость',
        Component: RealEstateIcon,
    },
    [Sectors.INDUSTRY]: {
        label: 'Промышленность',
        Component: IndustryIcon,
    },
    [Sectors.RESOURCES_AND_MATERIALS]: {
        label: 'Ресурсы и материалы',
        Component: ResourcesAndMaterialsIcon,
    },
    [Sectors.HEALTHCARE]: {
        label: 'Здравоохранение',
        Component: HealthcareIcon,
    },
    [Sectors.TECHNOLOGIES]: {
        label: 'Технологии',
        Component: TechnologiesIcon,
    },
    [Sectors.CONSUMER_RESOURCES]: {
        label: 'Потребительские товары',
        Component: ConsumerResourcesIcon,
    },
    [Sectors.PUBLIC_UTILITIES]: {
        label: 'Коммунальные услуги',
        Component: PublicUtilitiesIcon,
    },
    [Sectors.TRANSPORT]: {
        label: 'Транспорт',
        Component: TransportIcon,
    },
    [Sectors.FINANCIAL_SERVICES]: {
        label: 'Финансовые услуги',
        Component: FinancialServicesIcon,
    },
    [Sectors.COMMUNICATIONS]: {
        label: 'Коммуникации',
        Component: CommunicationsIcon,
    },
    [Sectors.OTHER]: {
        label: 'Прочее',
        Component: OtherIcon,
    },
    [Sectors.TELEVISION_RADIO]: {
        label: 'Телерадиовещание',
        Component: TelevisionRadioIcon,
    },
    [Sectors.ENERGY_SECTOR]: {
        label: 'Энергетика',
        Component: EnergySectorIcon,
    },
};

export type GeoDiversificationResponse = {
    geoDiversification: {
        [key in Continents]: number;
    };
};

export type IntraPortfolioCorrelation = {
    correlation: number;
};

export type SectoralDiversificationResponse = {
    sectoralDiversification: {
        [key in Sectors]: number;
    };
};

export type MostCorrelationToolsResponse = {
    mostCorrelatingInstruments: CorrelationTools[];
};

export type LeastCorrelationToolsResponse = {
    leastCorrelatingInstruments: CorrelationTools[];
};

export type Sector = {
    iconName: string;
    percent: number;
    label: string;
};
