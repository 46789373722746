import { Box, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import { ViewError, ViewLoader } from '../../../../shared/Layout';
import { Table } from '../../../../shared/Table';
import { useConfigureKeyStatisticsColumns } from './useConfigureKeyStatisticsColumns';
import { useGetKeyStatisticDataQuery } from './useGetKeyStatisticDataQuery';

type Props = {
    portfolioId: string;
};

export const KeyStatisticsTable: FC<Props> = ({ portfolioId }) => {
    const { data, isError, error, isPending } = useGetKeyStatisticDataQuery(portfolioId);
    const columns = useConfigureKeyStatisticsColumns();

    if (isError) {
        return (
            <Stack>
                <Text fz={19} fw={400} lh="18px">
                    {'Ключевые статистические данные'}
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по ключевым статистическим данным" />
            </Stack>
        );
    }

    if (isPending) {
        return (
            <Box miw={500} mih={200}>
                <Text fz={19} fw={400}>
                    {'Ключевые статистические данные'}
                </Text>
                <ViewLoader />
            </Box>
        );
    }

    return (
        <Stack gap={20}>
            <Text fz={19} fw={400} lh="18px">
                {'Ключевые статистические данные'}
            </Text>
            <Table columns={columns} data={data ?? []} striped={true} />
        </Stack>
    );
};
