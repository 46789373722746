import { type StressScenarios } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getScenarios: ApiFactory<
    ApiEndpoint<StressScenarios, void, { id: string }>
> = (client) => async ({ params, signal }) => {
    const { data } = await client.get<StressScenarios>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/stress-test/${params.id}/scenario`,
        { signal },
    );

    return data;
};
