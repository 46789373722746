import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const Download: FC<Props> = ({ width = 20, height = 20, color = 'currentColor' }) => (
    <svg
xmlns="http://www.w3.org/2000/svg" width={width} height={height}
viewBox="0 0 24 22" fill="none">
        <path
            d="M4 14L4 15C4 16.6569 5.34315 18 7 18L17 18C18.6569 18 20 16.6569
            20 15L20 14M16 10L12 14M12 14L8 10M12 14L12 2"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
