import {
    type StockChangeLeader,
    type TopStockByVol,
    type SecurityIndex,
    type PeriodPriceValues,
    type StockMarketData,
} from '@libs/types';
import { type Column } from '@modules/Investorpro/shared/components/Table';
import { PositiveNegativeValue } from '@modules/Investorpro/shared/components/PositiveNegativeValue';
import { round } from 'lodash';
import { getNumberWithSpacing } from '@libs/utils';
import { PeriodId } from '@libs/types/instrument.type';
import { roundInstrumentPrice } from '@modules/Investorpro/modules/BondStockPage/shared/utils';
import { formatPrice } from '@modules/Investorpro/shared/utils/format.util';

import styles from './styles.module.scss';
import { SECURITY_DATASET_COLORS, STOCK_INDEXES } from '../../../shared/constants';

export const TOP_STOCK_COLUMNS: Array<Column<StockMarketData>> = [
    {
        id: 'shortName',
        field: 'name',
        label: 'Название',
        width: '170px',
        align: 'left',
        className: styles.tableNameColumn,
        render: (obj) => (
            <span style={{ width: 202 }} className={styles.noTextOverflow}>
                {obj.name}
            </span>
        ),
    },
    {
        id: 'capitalization',
        field: 'capitalization',
        label: 'Капитализация',
        width: '157px',
        className: styles.tableValueColumn,
        align: 'right',
        render: (stock) => getNumberWithSpacing(round(stock.capitalization)),
    },
    {
        id: 'price',
        field: 'price',
        label: 'Цена',
        width: '144px',
        className: styles.tableValueColumn,
        align: 'right',
        type: 'price',
    },
    {
        id: 'changeAbsolute',
        field: 'changeAbsolute',
        label: 'Изменение',
        width: '112px',
        className: styles.tableValueColumn,
        align: 'right',
        type: 'number',
        render: (stock) => (
            <PositiveNegativeValue
                positivePrefix="+"
                value={roundInstrumentPrice(stock.changeAbsolute[PeriodId.ONE_DAY] ?? 0)}
            />
        ),
    },
    {
        id: 'changeRelative',
        field: 'changeRelative',
        label: 'Изм., %',
        width: '112px',
        className: styles.tableValueColumn,
        align: 'right',
        type: 'number',
        render: (stock) => (
            <PositiveNegativeValue
                positivePrefix="+"
                measure="%"
                value={stock.changeRelative[PeriodId.ONE_DAY] ?? 0}
                formatter={formatPrice}
            />
        ),
    },
];

export const STOCKS_INDEXES = [
    {
        ...STOCK_INDEXES[0],
        color: SECURITY_DATASET_COLORS[0],
    },
    {
        ...STOCK_INDEXES[1],
        color: SECURITY_DATASET_COLORS[1],
    },
    {
        ...STOCK_INDEXES[2],
        color: SECURITY_DATASET_COLORS[2],
    },
] as SecurityIndex[];

export const GROWTH_LEADER_COLUMNS: Array<Column<StockChangeLeader>> = [
    {
        id: 'name',
        field: 'name',
        label: 'Название',
        type: 'string',
        align: 'left',
        className: styles.tableNameColumn,
    },
    {
        id: 'price',
        field: 'price',
        label: 'Цена',
        className: styles.tableValueColumn,
        align: 'right',
        type: 'price',
    },
    {
        id: 'changeRelative',
        field: 'changeRelative',
        label: 'Изм., %',
        className: styles.tableValueColumn,
        align: 'right',
        type: 'number',
        render: (stock) => (
            <PositiveNegativeValue
                measure="%"
                className={styles.fallLeader_change}
                value={stock.changeRelative}
                formatter={formatPrice}
            />
        ),
    },
];

export const TOP_STOCK_BY_VOL_COLUMNS: Array<Column<TopStockByVol>> = [
    {
        id: 'name',
        field: 'name',
        label: 'Название',
        width: '291px',
        align: 'left',
        className: styles.tableNameColumn,
    },
    {
        id: 'price',
        field: 'price',
        label: 'Цена',
        width: '158px',
        className: styles.tableValueColumn,
        align: 'right',
        type: 'price',
    },
    {
        id: 'changeRelative',
        field: 'changeRelative',
        label: 'Изм., %',
        width: '113px',
        className: styles.tableValueColumn,
        align: 'right',
        type: 'number',
        render: (stock) => <PositiveNegativeValue measure="%" value={stock.changeRelative} formatter={formatPrice} />,
    },
    {
        id: 'vol',
        field: 'vol',
        label: 'Объем торгов (RUB)',
        width: '173px',
        className: styles.tableValueColumn,
        align: 'right',
        type: 'price',
    },
];

export const LOADING_KEY = 'bond-market-top-bonds-prices';

export const GET_PRICE_FOR_PERIOD_LOADING_KEY = 'bond-market-top-bonds-prices';

export const STOCK_EMPTY_ZERO_PRICE_VALUES: PeriodPriceValues = {
    [PeriodId.ONE_DAY]: 0,
    [PeriodId.FIVE_DAYS]: 0,
    [PeriodId.ONE_MONTH]: 0,
    [PeriodId.THREE_MONTHS]: 0,
    [PeriodId.SIX_MONTHS]: 0,
    [PeriodId.ONE_YEAR]: 0,
    [PeriodId.THREE_YEARS]: 0,
    [PeriodId.FIVE_YEARS]: 0,
    [PeriodId.TEN_YEARS]: 0,
    [PeriodId.ALL]: 0,
    [PeriodId.SNG]: 0,
    [PeriodId.TWO_YEARS]: 0,
};

export const STOCK_EMPTY_NULL_PRICE_VALUES: PeriodPriceValues = {
    [PeriodId.ONE_DAY]: null,
    [PeriodId.FIVE_DAYS]: null,
    [PeriodId.ONE_MONTH]: null,
    [PeriodId.THREE_MONTHS]: null,
    [PeriodId.SIX_MONTHS]: null,
    [PeriodId.ONE_YEAR]: null,
    [PeriodId.THREE_YEARS]: null,
    [PeriodId.FIVE_YEARS]: null,
    [PeriodId.TEN_YEARS]: null,
    [PeriodId.ALL]: null,
    [PeriodId.SNG]: null,
    [PeriodId.TWO_YEARS]: null,
};
