import classNames from 'classnames';
import { useRef, useState } from 'react';
import { type TSecurity } from '@libs/types';
import { debounce } from 'lodash';
import { useClickOutside } from '@libs/utils';
import { useNavigate } from 'react-router-dom';
import { SecurityType } from '@modules/Investorpro/modules/ScreenersPage/types';
import { HelpLineWrapper } from '@libs/components';
import { useAppDispatch } from '@store/store';
import { setHelplineMessage } from '@store/store/slices';
import { useOverlay } from '@libs/utils/hooks/useOverlay';
import { useSearchSecure } from '@libs/utils/hooks/useSearchSecure';

import styles from './styles.module.scss';
import { SearchSecurityDropDown } from '../SearchSecurityDropDown';
import { SearchInput } from '../../../Search/SearchInput';

type Props = {
    isModalSearch?: boolean;
    placeholder?: string;
    selectedWatchlistId?: number;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
};

export const Search = ({
    isModalSearch = false,
    placeholder = 'Поиск',
    disabled,
    selectedWatchlistId,
    className: customClassName,
    onClick,
}: Props) => {
    const [searchValue, setSearchValue] = useState('');
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const dispatch = useAppDispatch();

    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    useOverlay('search', isOpenDropdown && !isModalSearch);

    const { searchSecureByValue, isLoading, securityList, activeIndex, setActiveIndex, isServiceUnavailable } =
        useSearchSecure(searchValue);

    const debouncedSearchByValue = debounce(searchSecureByValue, 300);

    const handleSearch = async (value: string) => {
        setSearchValue(value);
        await debouncedSearchByValue(value);

        if (!isOpenDropdown) {
            setIsOpenDropdown(true);
        }
    };

    const handleClear = () => {
        setSearchValue('');
        setIsOpenDropdown(false);
        setActiveIndex(0);
    };

    const handleInputClick = () => {
        !isOpenDropdown && setIsOpenDropdown(true);
        onClick?.();
    };

    const searchContainerRef = useRef(null);
    useClickOutside(searchContainerRef, () => setIsOpenDropdown(false));
    const navigate = useNavigate();

    const navigateToInstrumentPage = (instrument: TSecurity) => {
        switch (instrument.securityType) {
            case SecurityType.STOCK:
                navigate(`/analytics/stocks/${instrument.secId}`);
                break;
            case SecurityType.BOND:
                navigate(`/analytics/bonds/${instrument.isin}`);
                break;
        }

        handleClear();
    };

    const onSearchFocus = () => {
        setIsSearchFocused(true);
        dispatch(setHelplineMessage('helpline.search.tickerNames'));
    };

    const onSearchBlur = () => {
        setIsSearchFocused(false);
    };

    return (
        <div
            className={classNames(styles.wrapper, isModalSearch && styles.isModal, customClassName)}
            ref={searchContainerRef}
        >
            <HelpLineWrapper
                message={isSearchFocused ? 'helpline.search.tickerNames' : 'helpline.search.searchByInstruments'}
            >
                <SearchInput
                    onClick={handleInputClick}
                    handleClear={handleClear}
                    disabled={disabled}
                    handleChange={handleSearch}
                    isModalSearch={isModalSearch}
                    placeholder={placeholder}
                    onFocus={onSearchFocus}
                    onBlur={onSearchBlur}
                    value={searchValue}
                />
            </HelpLineWrapper>

            {(isModalSearch || isOpenDropdown) && (
                <SearchSecurityDropDown
                    isLoading={isLoading}
                    withFavouriteDropdown
                    isModalSearch={isModalSearch}
                    handleItemClick={navigateToInstrumentPage}
                    selectedWatchlistId={selectedWatchlistId}
                    securityList={securityList}
                    searchValue={searchValue}
                    activeIndex={activeIndex}
                    isServiceUnavailable={isServiceUnavailable}
                    setActiveIndex={setActiveIndex}
                />
            )}
        </div>
    );
};
