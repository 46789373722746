import { type FC } from 'react';

export const ConsumerResourcesIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7155 20.8468C13.3617 20.8468 13.0748 21.1336 13.0748 21.4874C13.0748 21.8413 13.3617 22.1281
                13.7155 22.1281H25.9836C26.2949 22.1281 26.5993 22.2202 26.8584 22.3929L28.0885 23.213C28.3602
                23.3942 28.4337 23.7614 28.2525 24.0331C28.0713 24.3049 27.7042 24.3783 27.4324
                24.1971L26.2023 23.377C26.1375 23.3339 26.0614 23.3108 25.9836 23.3108H13.7155C12.7084
                23.3108 11.8921 22.4945 11.8921 21.4874C11.8921 20.4804 12.7084 19.6641 13.7155 19.6641H25.4066C25.7332
                19.6641 25.998 19.9288 25.998 20.2554C25.998 20.582 25.7332 20.8468 25.4066 20.8468H13.7155Z"
                fill="#636E80"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.4834 24.3951C23.9526 24.3951 23.5223 24.8254 23.5223 25.3562C23.5223 25.887 23.9526 26.3173
                24.4834 26.3173C25.0142 26.3173 25.4445 25.887 25.4445 25.3562C25.4445 24.8254 25.0142 24.3951 24.4834
                24.3951ZM24.4834 23.2124C23.2994 23.2124 22.3396 24.1722 22.3396 25.3562C22.3396 26.5402
                23.2994 27.5 24.4834 27.5C25.6674 27.5 26.6272 26.5402 26.6272 25.3562C26.6272 24.1722 25.6674
                23.2124 24.4834 23.2124Z"
                fill="#636E80"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.613 24.3951C15.0822 24.3951 14.6519 24.8254 14.6519 25.3562C14.6519 25.887 15.0822
                26.3173 15.613 26.3173C16.1438 26.3173 16.5741 25.887 16.5741 25.3562C16.5741 24.8254 16.1438
                24.3951 15.613 24.3951ZM15.613 23.2124C14.429 23.2124 13.4692 24.1722 13.4692 25.3562C13.4692
                26.5402 14.429 27.5 15.613 27.5C16.797 27.5 17.7568 26.5402 17.7568 25.3562C17.7568 24.1722
                16.797 23.2124 15.613 23.2124Z"
                fill="#636E80"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.5 6.67999C5.5 6.35339 5.76476 6.08862 6.09137 6.08862H8.46944C9.13257 6.08862 9.72485
                6.50348 9.95147 7.12668L10.9282 9.81281C10.9534 9.80955 10.979 9.80786 11.005
                9.80786H28.8174C29.4324 9.80786 29.8608 10.4185 29.6515 10.9968L26.2986 20.2615C26.1715
                20.6128 25.838 20.8467 25.4645 20.8467H14.7707C14.1067 20.8467 13.5138 20.4308 13.2878
                19.8064L9.95647 10.6013L8.83995 7.53087C8.78329 7.37507 8.63522 7.27136 8.46944
                7.27136H6.09137C5.76476 7.27136 5.5 7.00659 5.5 6.67999ZM11.3552 10.9906L14.4
                19.4039C14.4565 19.56 14.6047 19.664 14.7707 19.664H25.257L28.396 10.9906H11.3552Z"
                fill="#636E80"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.757 13.0065C16.0973 12.9093 16.4518 13.1063 16.549 13.4465L17.5347 16.8961C17.6319
                17.2364 17.4349 17.5909 17.0947 17.6881C16.7545 17.7853 16.3999 17.5884 16.3027
                17.2481L15.3171 13.7985C15.2198 13.4583 15.4168 13.1037 15.757 13.0065Z"
                fill="#636E80"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.8755 12.9819C20.2293 12.9819 20.5162 13.2688 20.5162 13.6226V17.0722C20.5162
                17.426 20.2293 17.7129 19.8755 17.7129C19.5217 17.7129 19.2349 17.426 19.2349
                17.0722V13.6226C19.2349 13.2688 19.5217 12.9819 19.8755 12.9819Z"
                fill="#636E80"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9939 13.0065C23.6537 12.9093 23.2991 13.1063 23.2019 13.4465L22.2163 16.8961C22.1191
                17.2364 22.3161 17.5909 22.6563 17.6881C22.9965 17.7853 23.3511 17.5884
                23.4483 17.2481L24.4339 13.7985C24.5311 13.4583 24.3341 13.1037 23.9939 13.0065Z"
                fill="#636E80"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.9043 8.03381C16.2369 8.03381 15.046 9.17511 15.046 10.3993H13.8633C13.8633
                8.35742 15.7613 6.85107 17.9043 6.85107C20.0473 6.85107 21.9453 8.35742 21.9453
                10.3993H20.7626C20.7626 9.17511 19.5717 8.03381 17.9043 8.03381Z"
                fill="#636E80"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7915 6.45686C18.7915 5.58592 19.4975 4.87988 20.3685 4.87988H24.3109C25.1819
                4.87988 25.8879 5.58592 25.8879 6.45686V10.3993H24.7052V6.45686C24.7052 6.23913 24.5287
                6.06262 24.3109 6.06262H20.3685C20.1507 6.06262 19.9742 6.23913 19.9742
                6.45686V7.93528H18.7915V6.45686Z"
                fill="#636E80"
            />
            <path
                d="M21.354 3.79568V5.17554C21.354 5.33884 21.4864 5.47122 21.6497
                5.47122H23.0295C23.1928 5.47122 23.3252 5.33884 23.3252 5.17554V3.79568C23.3252
                3.63238 23.1928 3.5 23.0295 3.5H21.6497C21.4864 3.5 21.354 3.63238 21.354 3.79568Z"
                fill="#636E80"
            />
        </g>
    </svg>
);
