import React, { memo } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import {
    type FilterType,
    FilterTypes,
    type FilterValueType,
    type ScreenerFilterValueType,
    ValueTypes,
} from '@modules/Investorpro/modules/ScreenersPage/types';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Controller, type FieldArrayWithId } from 'react-hook-form';
import { ControlledInput } from '@libs/components';
import { Calendar } from 'primereact/calendar';
import { type Nullable } from 'primereact/ts-helpers';
import { INPUT_DATE_FORMAT } from '@libs/utils';

import styles from '../../styles.module.scss';

type FiltersFormListProps = {
    preparedFilters: Record<FilterType['id'], FilterType>;
    handleSwitchFilter: (filterId: FilterType['id'], value?: boolean) => void;
    handleClearFilters: () => void;
    handleUpdateFilterValue: (filterId: FilterType['id'], value: Omit<ScreenerFilterValueType, 'id'>) => void;
    fields: Array<
        FieldArrayWithId<{
            filters: Array<ScreenerFilterValueType & { filterId: FilterType['id'] }>;
        }>
    >;
    control: any;
};

export const FiltersFormList = memo(
    ({
        preparedFilters,
        handleSwitchFilter,
        handleClearFilters,
        handleUpdateFilterValue,
        fields,
        control,
    }: FiltersFormListProps) => {
        const handleUpdateInput = (filterId: FilterType['id'], rawValue: ScreenerFilterValueType['value']) => {
            handleUpdateFilterValue(filterId, { value: rawValue });
        };

        const handleUpdateSingleSelect = (filterId: FilterType['id'], rawValue: FilterValueType['id']) => {
            handleUpdateFilterValue(filterId, { values: rawValue ? [rawValue] : undefined });
        };

        const handleUpdateMultiSelect = (filterId: FilterType['id'], rawValue: ScreenerFilterValueType['values']) => {
            handleUpdateFilterValue(filterId, { values: rawValue });
        };

        const handleUpdateRange = (
            filterId: FilterType['id'],
            rawValue: Pick<ScreenerFilterValueType, 'startValue' | 'endValue'>,
        ) => {
            handleUpdateFilterValue(filterId, rawValue);
        };

        const handleUpdateDateTimeRange = (filterId: FilterType['id'], rawValue: Nullable<Array<Date | null>>) => {
            const [startValue, endValue] = rawValue ?? [];
            handleUpdateFilterValue(filterId, { dates: [startValue, endValue] });
        };

        const handleUpdateTimeRange = (filterId: FilterType['id'], rawValue: Nullable<Array<Date | null>>) => {
            const [startValue, endValue] = rawValue ?? [];
            handleUpdateFilterValue(filterId, { dates: [startValue ?? null, endValue ?? null] });
        };

        const renderItem = (filterValue: ScreenerFilterValueType & { filterId: FilterType['id'] }, index: number) => {
            const { type, values, valueType, minValue, maxValue } = preparedFilters[filterValue.filterId] ?? {};

            switch (type) {
                case FilterTypes.INPUT:
                    return (
                        <ControlledInput
                            className={classNames(styles.toolInput)}
                            onChange={(e: any) => handleUpdateInput(filterValue.filterId, e.currentTarget.value)}
                            name={`filters.${index}.value`}
                            control={control}
                            rules={{ required: true, min: minValue, max: maxValue }}
                        />
                    );
                case FilterTypes.SINGLE_SELECT:
                    return (
                        <Controller
                            control={control}
                            name={`filters.${index}.values`}
                            render={() => (
                                <Dropdown
                                    value={filterValue.values?.[0]}
                                    onChange={(e) => handleUpdateSingleSelect(filterValue.filterId, e.value)}
                                    options={values}
                                    optionValue="id"
                                    optionLabel="name"
                                    placeholder="Выберите значение"
                                    pt={{
                                        root: { className: styles.singleSelect },
                                        itemLabel: { className: styles.singleSelect_item },
                                        emptyMessage: { className: styles.singleSelect_placeholder },
                                    }}
                                />
                            )}
                            rules={{ required: true }}
                        />
                    );
                case FilterTypes.MULTIPLE_SELECT:
                    return (
                        <Controller
                            control={control}
                            name={`filters.${index}.values`}
                            render={() => (
                                <MultiSelect
                                    value={filterValue.values}
                                    onChange={(e) => handleUpdateMultiSelect(filterValue.filterId, e.value)}
                                    options={values}
                                    optionValue="id"
                                    optionLabel="name"
                                    filter
                                    placeholder="Выберите значения"
                                    maxSelectedLabels={3}
                                    pt={MULTI_PT}
                                />
                            )}
                            rules={{ required: true }}
                        />
                    );
                case FilterTypes.RANGE:
                    switch (valueType) {
                        case ValueTypes.DATE:
                            return (
                                <>
                                    <Controller
                                        control={control}
                                        name={`filters.${index}.dates[0]`}
                                        render={() => (
                                            <>
                                                <Calendar
                                                    className={classNames(styles.calendarInput)}
                                                    value={filterValue.dates?.[0]}
                                                    onChange={(e) => {
                                                        filterValue.dates![0] = e.value ?? null;
                                                        filterValue.dates![1] = filterValue.dates?.[1] ?? null;
                                                    }}
                                                    onHide={() => handleUpdateDateTimeRange(
                                                            filterValue.filterId,
                                                            filterValue.dates,
                                                        )}
                                                    readOnlyInput
                                                    showButtonBar
                                                    hideOnRangeSelection
                                                    minDate={minValue ? new Date(minValue) : undefined}
                                                    maxDate={maxValue ? new Date(maxValue) : undefined}
                                                    dateFormat={INPUT_DATE_FORMAT}
                                                    pt={{
                                                        buttonbar: { className: styles.calendar_buttonBar },
                                                    }}
                                                />
                                            </>
                                        )}
                                        rules={{ required: !fields[index].dates?.[1] }}
                                    />
                                    <span>-</span>
                                    <Controller
                                        control={control}
                                        name={`filters.${index}.dates[1]`}
                                        render={() => (
                                            <>
                                                <Calendar
                                                    className={classNames(styles.calendarInput)}
                                                    value={filterValue.dates?.[1]}
                                                    onChange={(e) => {
                                                        filterValue.dates![0] = filterValue.dates?.[0] ?? null;
                                                        filterValue.dates![1] = e.value ?? null;
                                                    }}
                                                    onHide={() => handleUpdateDateTimeRange(
                                                            filterValue.filterId,
                                                            filterValue.dates,
                                                        )}
                                                    readOnlyInput
                                                    showButtonBar
                                                    hideOnRangeSelection
                                                    minDate={minValue ? new Date(minValue) : undefined}
                                                    maxDate={maxValue ? new Date(maxValue) : undefined}
                                                    dateFormat={INPUT_DATE_FORMAT}
                                                    pt={{
                                                        buttonbar: { className: styles.calendar_buttonBar },
                                                    }}
                                                />
                                            </>
                                        )}
                                        rules={{ required: !fields[index].dates?.[0] }}
                                    />
                                </>
                            );
                        case ValueTypes.DATE_TIME:
                            return (
                                <Controller
                                    control={control}
                                    name={`filters.${index}.dates`}
                                    render={() => (
                                        <Calendar
                                            className={classNames(styles.calendarInput)}
                                            value={filterValue.dates}
                                            onChange={(e) => {
                                                filterValue.dates![0] = e.value![0];
                                                filterValue.dates![1] = e.value![1];
                                            }}
                                            onHide={() => handleUpdateDateTimeRange(filterValue.filterId, filterValue.dates)}
                                            selectionMode="range"
                                            readOnlyInput
                                            hideOnRangeSelection
                                            showIcon
                                            showTime={valueType === ValueTypes.DATE_TIME}
                                            minDate={minValue ? new Date(minValue) : undefined}
                                            maxDate={maxValue ? new Date(maxValue) : undefined}
                                        />
                                    )}
                                />
                            );
                        case ValueTypes.TIME:
                            return (
                                <>
                                    <Controller
                                        control={control}
                                        name={`filters.${index}.dates.0`}
                                        render={() => (
                                            <Calendar
                                                className={classNames(styles.calendarInput)}
                                                value={filterValue.dates?.[0] as Nullable<Date>}
                                                onChange={(e) => handleUpdateTimeRange(filterValue.filterId, [e.value ?? null])}
                                                readOnlyInput
                                                hideOnRangeSelection
                                                timeOnly
                                                minDate={minValue ? new Date(minValue) : undefined}
                                                maxDate={maxValue ? new Date(maxValue) : undefined}
                                            />
                                        )}
                                        rules={{ required: !fields[index].dates?.[1] }}
                                    />
                                    <span>-</span>
                                    <Controller
                                        control={control}
                                        name={`filters.${index}.dates.1`}
                                        render={() => (
                                            <Calendar
                                                className={classNames(styles.calendarInput)}
                                                value={filterValue.dates?.[1] as Nullable<Date>}
                                                onChange={(e) => handleUpdateTimeRange(filterValue.filterId, [null, e.value ?? null])}
                                                readOnlyInput
                                                hideOnRangeSelection
                                                timeOnly
                                                minDate={minValue ? new Date(minValue) : undefined}
                                                maxDate={maxValue ? new Date(maxValue) : undefined}
                                            />
                                        )}
                                        rules={{ required: !fields[index].dates?.[0] }}
                                    />
                                </>
                            );
                        case ValueTypes.STRING:
                        case ValueTypes.NUMBER:
                        default:
                            return (
                                <>
                                    <ControlledInput
                                        className={classNames(styles.toolInput)}
                                        name={`filters.${index}.startValue`}
                                        control={control}
                                        rules={{ required: !fields[index].endValue, min: minValue, max: maxValue }}
                                        type={valueType === ValueTypes.NUMBER ? 'number' : 'string'}
                                        onChange={(e: any) => handleUpdateRange(filterValue.filterId, {
                                                startValue: e.currentTarget.value,
                                                endValue: fields[index].endValue,
                                            })}
                                        placeholder={minValue}
                                    />
                                    <span>-</span>
                                    <ControlledInput
                                        className={classNames(styles.toolInput)}
                                        name={`filters.${index}.endValue`}
                                        control={control}
                                        rules={{ required: !fields[index].startValue, min: minValue, max: maxValue }}
                                        type={valueType === ValueTypes.NUMBER ? 'number' : 'string'}
                                        onChange={(e: any) => handleUpdateRange(filterValue.filterId, {
                                                startValue: fields[index].startValue,
                                                endValue: e.currentTarget.value,
                                            })}
                                        placeholder={maxValue}
                                    />
                                </>
                            );
                    }
                default:
                    return null;
            }
        };

        return (
            <div className={styles.selectedWrapper}>
                <div className={classNames(styles.criteriaHeaderWrapper)}>
                    <span className={classNames(styles.criteriaTitle)}>Выбранные критерии</span>
                    <Button className={classNames(styles.criteriaResetButton)} onClick={handleClearFilters}>
                        <span>Сбросить все</span>
                    </Button>
                </div>

                <form className={classNames(styles.toolsSelectedWrapper)}>
                    <ul className={classNames(styles.toolsSelectedList, styles.customScroll)}>
                        {fields.map((field, index) => {
                            const { filterId } = field;
                            const { name } = preparedFilters[filterId] ?? {};

                            return (
                                <li key={`checkedFilter-${filterId}`}>
                                    <div className={classNames(styles.toolHeaderWrapper)}>{name}</div>

                                    <div className={classNames(styles.toolValueWrapper)}>
                                        {renderItem(field, index)}
                                    </div>
                                    <button
                                        className={classNames(styles.trashButton)}
                                        onClick={() => handleSwitchFilter(filterId)}
                                    >
                                        <i className={classNames(styles.trashIcon, 'pi pi-trash ml-1')} />
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </form>
            </div>
        );
    },
);

const MULTI_PT = {
    trigger: () => ({
        className: styles.msTrigger,
    }),
    root: () => ({
        className: styles.msRoot,
    }),
    label: () => ({
        className: styles.msLabel,
    }),
    header: () => ({
        className: styles.msHeader,
    }),
    panel: () => ({
        className: styles.msPanel,
    }),
    list: () => ({
        className: styles.msList,
    }),
    item: () => ({
        className: styles.msItem,
    }),
    filterContainer: () => ({
        className: styles.msFilterContainer,
    }),
    filterIcon: () => ({
        className: styles.msFilterIcon,
    }),
    checkbox: () => ({
        className: styles.msHeaderCheckboxIcon,
    }),
};
