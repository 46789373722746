import classNames from 'classnames';
import { compactNumber, isValueExist } from '@libs/utils';
import { NONE_SYMBOL } from '@modules/Investorpro/shared/constants';
import { formatPrice } from '@modules/Investorpro/shared/utils/format.util';

import styles from './styles.module.scss';

type Props = {
    className?: string;
    value: number | null | undefined;
    measure?: string;
    roundStart?: 'thousands' | 'millions' | 'billions' | 'none';
    isPercent?: boolean;
    measureCLassName?: string;
    stringValue?: string;
    hideDecimals?: boolean;
};

export const ValueWithMeasure = ({
    value: num,
    className,
    stringValue,
    measure,
    roundStart = 'thousands',
    isPercent,
    measureCLassName,
    hideDecimals,
}: Props) => {
    if (!isValueExist(num)) {
        return <>{NONE_SYMBOL}</>;
    }

    const getValueWithDecimals = () => {
        if (stringValue) {
            return [''];
        }

        if (typeof num !== 'number') {
            num = Number(num);

            if (isNaN(num)) {
                return ['—'];
            }
        }

        if (isPercent) {
            return [formatPrice(num)];
        }

        return compactNumber(num, roundStart);
    };

    const [value, decimals] = getValueWithDecimals();

    const displayedValue = hideDecimals && value.endsWith(',00') ? value.slice(0, -3) : value;

    return (
        <span className={classNames(styles.valueWithMeasureWrapper, className)}>
            {stringValue ?? displayedValue}
            <span className={classNames(!isPercent && styles.measure, measureCLassName)}>
                {!isPercent && <>&nbsp;</>}
                {decimals && (
                    <>
                        {decimals}
                        &nbsp;
                    </>
                )}
                {isPercent ? '%' : measure}
            </span>
        </span>
    );
};
