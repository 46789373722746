import { Box, Group, Stack, Text } from '@mantine/core';
import { type FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ViewError } from '../../../shared/Layout';
import { SegmentController } from '../../../shared/SegmentController';
import { type InferParams } from '../../../shared/router';
import { Chart } from './Chart/Chart';
import { InstrumentsLiquidityTable } from './InstrumentsLiquidityTable';
import { PortfolioLiquidity } from './PortfolioLiquidity';
import { useGetInstrumentsLiquidityQuery } from './useGetInstrumentsLiquidityQuery';

export const Liquidity: FC = () => {
    const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;
    const [instrumentsType, setInstrumentsType] = useState<string>('CONSOLIDATED');
    const portfolioInstrumentsLiquidityQuery = useGetInstrumentsLiquidityQuery(portfolioId);

    const data = useMemo(
        () => instrumentsType !== 'CONSOLIDATED'
                ? portfolioInstrumentsLiquidityQuery.data?.filter((el) => el.instrumentType === instrumentsType) ?? []
                : portfolioInstrumentsLiquidityQuery.data ?? [],
        [instrumentsType, portfolioInstrumentsLiquidityQuery.data],
    );

    if (portfolioInstrumentsLiquidityQuery.isError) {
        return (
            <Box>
                <Text fz={19} fw={400}>
                    {'Ликвидность портфеля'}
                </Text>
                <ViewError
                    error={portfolioInstrumentsLiquidityQuery.error}
                    subtitle="Ошибка получения данных по ликвидности портфеля"
                />
            </Box>
        );
    }

    return (
        <Group
gap={60} w="100%" wrap="nowrap"
align="flex-start">
            <Stack w="60%" gap={20} h="500px">
                <Group justify="space-between" wrap="nowrap">
                    <Text fz={19} lh="18px" fw={400}>
                        {'Карта ликвидности портфеля'}
                    </Text>
                    <SegmentController
                        value={instrumentsType}
                        onValueChange={setInstrumentsType}
                        buttons={[
                            { label: 'Консолидировано', value: 'CONSOLIDATED' },
                            { label: 'Акции', value: 'share' },
                            { label: 'Облигации', value: 'bond' },
                            { label: 'Фонды', value: 'fund' },
                        ]}
                    />
                </Group>
                <Chart data={data} isPending={portfolioInstrumentsLiquidityQuery.isPending} />
            </Stack>
            <Stack w="40%" gap={36} pt={5}>
                <PortfolioLiquidity portfolioId={portfolioId} />
                <InstrumentsLiquidityTable
                    data={portfolioInstrumentsLiquidityQuery.data ?? []}
                    isPending={portfolioInstrumentsLiquidityQuery.isPending}
                />
            </Stack>
        </Group>
    );
};
