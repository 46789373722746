import { classNames } from 'primereact/utils';

import { type ObjectRowData } from '../../../../utils/types';
import styles from './styles.module.scss';

type Props = {
    data: ObjectRowData[];
    className?: string;
};

export const Table = ({ data, className }: Props) => {
    return (
        <div className={classNames(styles.table, 'flex', 'flex-column', className)}>
            {data.map(({ label, value }) => {
                return (
                    <div key={label} className={classNames(styles.table_row, 'flex', 'flex-row')}>
                        <div className="flex">
                            <span>{label}</span>
                            <span>{value ?? '–'}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
