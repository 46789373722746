import { getTradeDayTime } from '@modules/Investorpro/modules/MarketPages/shared/utils/date.utils';
import { useLastUpdate } from '@modules/Investorpro/shared/utils/useLastUpdate';
import { useState, useEffect } from 'react';
import { type ProfitPeriod } from '@libs/types/instrument.type';

import { BONDS_CATEGORY_OPTIONS, INSTRUMENT_TYPE_OPTIONS, STOCK_CATEGORY_OPTIONS } from '../constants';
import { InstrumentType, type InstrumentCategory, type Option, type HeatMapType } from '../types';

type UseInstrumentSelectArgs = {
    loadData: (
        type: InstrumentType,
        category: InstrumentCategory,
        profitPeriod: ProfitPeriod[],
        key: HeatMapType,
    ) => Promise<unknown>;
    key: HeatMapType;
    profitPeriod: ProfitPeriod;
    tradeDate: Date | null;
};

export const useInstrumentSelect = ({ loadData, key, tradeDate, profitPeriod }: UseInstrumentSelectArgs) => {
    const [instrumentType, setInstrumentType] = useState<Option<InstrumentType>>(INSTRUMENT_TYPE_OPTIONS[0]);
    const [instrumentCategory, setInstrumentCategory] = useState<Option<InstrumentCategory> | null>(
        STOCK_CATEGORY_OPTIONS[0],
    );
    const { updateDateLabel, setLastUpdate } = useLastUpdate();

    const handleInstrumentTypeChange = async (type: Option<InstrumentType>) => {
        const wasChanged = type.code !== instrumentType.code;
        setInstrumentType(type);

        if (!wasChanged) {
            return;
        }

        const defaultCategory =
            type.code === InstrumentType.STOCKS ? STOCK_CATEGORY_OPTIONS[0] : BONDS_CATEGORY_OPTIONS[0];

        setInstrumentCategory(defaultCategory);
        await loadData(type.code, defaultCategory.code, [profitPeriod], key);
    };

    const handleInstrumentCategoryChange = async (category: Option<InstrumentCategory> | null) => {
        setInstrumentCategory(category);

        if (!category) return;

        await loadData(instrumentType.code, category.code, [profitPeriod], key);
        setLastUpdate(getTradeDayTime(tradeDate ?? new Date()));
    };

    useEffect(() => {
        if (tradeDate) {
            setLastUpdate(getTradeDayTime(tradeDate));
        }
    }, [tradeDate]);

    return {
        instrumentType,
        updateDateLabel,
        instrumentCategory,
        setInstrumentCategory,
        handleInstrumentTypeChange,
        handleInstrumentCategoryChange,
    };
};
