import { Box, Group, Stack, Text } from '@mantine/core';
import { type UseQueryResult } from '@tanstack/react-query';
import { type FC } from 'react';
import {
    type CashFlowType,
    type Expectations,
    type MaxRisk,
    type PortFolioCost,
    type PortfolioDynamicsResponseData,
} from '@modules/Investorpro/modules/PortfolioPage/entities';

import { Chart as CashFlowChart } from '../../CashFlow/Chart/Chart';
import { Chart as DynamicsChart } from '../../Dynamics/Chart/Chart';
import { CoreMetricsChart } from '../../GeneralInfo/CoreMetricsChart';
import { InfoIcon } from '../ReportIcons/InfoIcon';
import { ReportExpectations } from './ReportExpectations';
import { ReportMaxRisk } from './ReportMaxRisk';

type Props = {
    portfolioCostQuery: UseQueryResult<PortFolioCost, Error>;
    coreMetricsQuery: UseQueryResult<
        Array<{
            name: string;
            value: number;
            color: string;
        }>,
        Error
    >;
    cashFlowType: CashFlowType[];
    expectations: Expectations;
    dynamics: PortfolioDynamicsResponseData;
    maxRisk: MaxRisk;
};

export const ReportGeneralInfoFirstPage: FC<Props> = ({
    portfolioCostQuery,
    coreMetricsQuery,
    cashFlowType,
    expectations,
    dynamics,
    maxRisk,
}) => (
    <Stack gap={20} w={1350} p={5}>
        <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
            <Group align="center" justify="space-between">
                <Text fz="45px" fw={400}>
                    {'Сводная аналитика'}
                </Text>
                <Group
                    gap={12}
                    miw={180}
                    mih={24}
                    px={16}
                    align="center"
                    py={8}
                    style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                >
                    <InfoIcon />
                    <Text fz={18} fw={400} ff="FavoritPro">
                        {'Информация'}
                    </Text>
                </Group>
            </Group>
            <Text
fz={18} fw={400} ff="FavoritPro"
style={{ whiteSpace: 'pre-wrap' }}>
                {'В этом разделе вы найдете самые важные показатели по портфелю.'}
            </Text>
        </Stack>
        <Group justify="space-between">
            <Stack gap={12}>
                <Text fz={36} fw={400}>
                    {'Основные метрики'}
                </Text>
                <Group gap={40}>
                    <CoreMetricsChart
                        isInReport={true}
                        portfolioCostQuery={portfolioCostQuery}
                        coreMetricsQuery={coreMetricsQuery}
                    />
                </Group>
            </Stack>
            <Stack gap={0} w="50%">
                <ReportExpectations expectations={expectations} />
                <ReportMaxRisk maxRisk={maxRisk} />
            </Stack>
        </Group>

        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
            <Text fz={36} fw={400}>
                {'Перформанс портфеля'}
            </Text>
            <Box h={500} w="100%">
                <DynamicsChart data={dynamics ?? { performances: [], events: [] }} isInReport={true} />
            </Box>
        </Stack>
        <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={30}>
            <Text fz={36} fw={400}>
                {'Годовая доходность'}
            </Text>
            <Group h={500}>
                <CashFlowChart data={cashFlowType} isInReport={true} cashFlowType="ANNUAL" />
            </Group>
        </Stack>
    </Stack>
);
