import {
    ProfitPeriod,
    type CapitalizationInstrumentData,
    type GetInstrumentLiquidityResponse,
} from '@libs/types/instrument.type';

import {
    HeatMapType,
    type InstrumentStoreType,
    type InstrumentCategory,
    type InstrumentType,
    type InstrumentIndustryData,
    type LoadingState,
} from '../types';
import { DATA_LOADING_KEY, LIQUIDITY_LOADING_KEY, PROFIT_LOADING_KEY } from '../constants';

export const getInstrumentStoreCode = (instrumentType: InstrumentType, instrumentCategory: InstrumentCategory) => {
    return `${instrumentType}-${instrumentCategory}`;
};

export const formatProfitLoadingKey = (storeKey: string, period: ProfitPeriod) => `${storeKey}-${period}-${PROFIT_LOADING_KEY}`;

export const formatDataLoadingKey = (storeKey: string) => `${storeKey}-${DATA_LOADING_KEY}`;

export const formatLiquidityLoadingKey = (storeKey: string) => `${storeKey}-${LIQUIDITY_LOADING_KEY}`;

export const getLoadingStatus = (
    store: InstrumentStoreType,
    key: HeatMapType,
    profitPeriod: ProfitPeriod,
    instrumentType: InstrumentType,
    instrumentCategory: InstrumentCategory | null | undefined,
) => {
    if (!instrumentCategory) {
        return false;
    }

    const storeKey = getInstrumentStoreCode(instrumentType, instrumentCategory);
    const storeItem = store[storeKey];

    if (!storeItem) {
        return false;
    }

    const anotherKey = key === HeatMapType.MARKET ? HeatMapType.LIQUIDITY : HeatMapType.MARKET;

    return (
        storeItem.loadings[key].length > 0 ||
        storeItem.loadings[anotherKey].includes(formatProfitLoadingKey(storeKey, profitPeriod)) ||
        storeItem.loadings[anotherKey].includes(DATA_LOADING_KEY)
    );
};

export const getStoreKeyWithLoadingByHeatMapType = (instrumentsStore: InstrumentStoreType, heatMapKey: HeatMapType) => {
    let keyWithLoading = null;

    for (const key in instrumentsStore) {
        if ((instrumentsStore[key]?.loadings[heatMapKey]?.length ?? 0) > 0) {
            keyWithLoading = key;
            break;
        }
    }

    return keyWithLoading;
};

export const parseLiquidity = (liquidityItem: GetInstrumentLiquidityResponse['data'][number] | undefined) => {
    return {
        sell_liquidit100000: liquidityItem?.sell?.liq100k ?? 0,
        sell_liquidit1000000: liquidityItem?.sell?.liq1000k ?? 0,
        sell_liquidit5000000: liquidityItem?.sell?.liq5000k ?? 0,
        sell_liquidit10000000: liquidityItem?.sell?.liq10000k ?? 0,
        sell_liquidit50000000: liquidityItem?.sell?.liq50000k ?? 0,
        sell_liquidit100000000: liquidityItem?.sell?.liq100000k ?? 0,
        buy_liquidit100000: liquidityItem?.buy?.liq100k ?? 0,
        buy_liquidit1000000: liquidityItem?.buy?.liq1000k ?? 0,
        buy_liquidit5000000: liquidityItem?.buy?.liq5000k ?? 0,
        buy_liquidit10000000: liquidityItem?.buy?.liq10000k ?? 0,
        buy_liquidit50000000: liquidityItem?.buy?.liq50000k ?? 0,
        buy_liquidit100000000: liquidityItem?.buy?.liq100000k ?? 0,
    };
};

export const getUniqueInstruments = (data: CapitalizationInstrumentData[]) => {
    const uniqueDataSecIds: Record<string, boolean> = {};

    const uniqueInstruments: InstrumentIndustryData[] = [];

    data.forEach((data) => {
        const { secId } = data;

        if (!uniqueDataSecIds[secId]) {
            uniqueDataSecIds[secId] = true;
            uniqueInstruments.push({
                ...data,
                ...parseLiquidity(undefined),
                [ProfitPeriod.DAY]: 0,
                [ProfitPeriod.WEEK]: 0,
                [ProfitPeriod.MONTH]: 0,
                [ProfitPeriod.YEAR]: 0,
                [ProfitPeriod.YTD]: 0,
                close: data.close ?? 0,
            });
        }
    });

    return {
        uniqueInstruments,
    };
};

export const removeLoadingKeyFromAllHeatMaps = (loadingState: LoadingState, loadingKey: string) => {
    return Object.keys(loadingState).reduce<LoadingState>(
        (acc, key) => {
            acc[key as keyof LoadingState] = loadingState[key as keyof LoadingState].filter(
                (item) => item !== loadingKey,
            );

            return acc;
        },
        { ...loadingState },
    );
};
