import { Box } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { type FC, Fragment } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { ViewError, ViewLoader } from '../shared/Layout';
import { type InferParams } from '../shared/router';
import { useApi } from '../api';
import { PortfolioNav } from './PortfolioNav';

export const PortfolioLayout: FC = () => {
    const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;
    const api = useApi();

    const { data, isError, isPending, error } = useQuery({
        queryKey: ['portfolio', portfolioId],
        queryFn: async () => await api.portfolio.getPortfolioById({ params: { id: portfolioId } }),
        enabled: Boolean(portfolioId),
    });

    if (isError) {
        return (
            <Box p="md">
                <ViewError error={error} />
            </Box>
        );
    }

    if (isPending) {
        return <ViewLoader />;
    }

    return (
        <Fragment>
            <PortfolioNav portfolioName={data.name || ''} />
            <Outlet />
        </Fragment>
    );
};
