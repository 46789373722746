import { type TwoColumnsDataViewOption } from '@modules/Investorpro/modules/BondStockPage/shared/components/TwoColumnsDataView';
import { ServerBondIndustry, ServerBondTypeCode } from '@libs/types';

import { type BondGeneralInfo } from '../../../../services/types';
import styles from './styles.module.scss';

const TYPE_OF_ISSUES_MAPPER: Record<ServerBondTypeCode, string> = {
    [ServerBondTypeCode.EXCHANGE_BOND]: 'Биржевые',
    [ServerBondTypeCode.IFI_BOND]: 'Облигации иностранного эмитента',
    [ServerBondTypeCode.CORPORATE_BOND]: 'Корпоративные',
    [ServerBondTypeCode.MUNICIPAL_BOND]: 'Муниципальные',
    [ServerBondTypeCode.OFZ_BOND]: 'Облигации федерального знайма',
    [ServerBondTypeCode.SUBFEDERAL_BOND]: 'Субфедеральные',
};

const INDUSTRY_MAPPER: Record<ServerBondIndustry, string> = {
    [ServerBondIndustry.CORP]: 'Корпоративные облигации',
    [ServerBondIndustry.GOV]: 'Государственные облигации',
    [ServerBondIndustry.MONI]: 'Муниципальные облигации',
};

export const BOND_GENERAL_INFO_DATA_ROWS: Array<TwoColumnsDataViewOption<BondGeneralInfo>> = [
    {
        label: 'Полное наименование заемщика',
        valueComponent: (obj: BondGeneralInfo) => obj?.fullNameOfBorrower ? <span className={styles.borrower}>{obj?.fullNameOfBorrower}</span> : null,
    },
    { label: 'Краткое наименование заемщика', value: 'shortNameOfBorrower' },
    // @ts-expect-error в данном случае не получается обработать тип выпуска как string
    { label: 'Сектор', valueComponent: (obj: BondGeneralInfo) => INDUSTRY_MAPPER[obj?.industry ?? ''] ?? null },
    { label: 'Отрасль', value: 'sector' },
    {
        label: 'Вид выпуска',
        // @ts-expect-error в данном случае не получается обработать тип выпуска как string
        valueComponent: (obj: BondGeneralInfo) => TYPE_OF_ISSUES_MAPPER[obj?.typeOfIssue ?? ''] ?? null,
    },
    { label: 'Регистрационный номер', value: 'registrationNumber', fontWeight: 400, fontFamily: 'Inter' },
    { label: 'Уровень листинга', value: 'listingLevel', type: 'number' },
    { label: 'Ломбардный список', value: 'lombardList' },
    { label: 'Для квалифицированных инвесторов', value: 'forQualifiedInvestors', type: 'boolean' },
    { label: 'Субординированная облигация', value: 'subordinatedBond', type: 'boolean' },
];
