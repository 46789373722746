import { generatePath } from 'react-router-dom';

type UrlParts = {
    path: string;
    params?: Record<string, string>;
    search?: string;
    hash?: string;
};

export const composeUrl = ({ path, params, search, hash }: UrlParts): string => {
    const urlPath = generatePath(path, params);
    const urlSearch = search ? `?${search}` : '';
    const urlHash = hash ? `#${hash}` : '';
    const url = `${urlPath}${urlSearch}${urlHash}`;

    return url;
};
