import { type FC } from 'react';

export const ResourcesAndMaterialsIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path
                d="M16.0811 14.829L13.1081 28.2073C13.0942 28.2698 13.1417 28.329 13.2057
                28.329H21.9564C22.0204 28.329 22.0679 28.2698 22.054 28.2073L19.0811 14.829"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
                d="M15.7298 14.6804C14.7273 13.6779 14.7273 12.0526 15.7298 11.0501C16.7323
                10.0477 18.3576 10.0477 19.36 11.0501C20.3625 12.0526 20.3625 13.6779 19.36
                14.6804C18.3576 15.6828 16.7323 15.6828 15.7298 14.6804Z"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <circle
                cx="9.15706"
                cy="16.8762"
                r="1.26494"
                transform="rotate(15.4042 9.15706 16.8762)"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
                d="M9.3186 15.6133L15.281 12.8229M25.6215 7.98341L19.5228 10.8377M26.5386
                9.94305L20.3849 12.823M10.2357 17.573L16.1016 14.8277"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
                d="M25.11 7.10605L24.813 4.55574L26.7468 3.65078L28.5146 5.51282L28.9497
                5.09971L28.5146 5.51282C28.5442 5.544 28.5686 5.57975 28.5868 5.61869L30.1612 8.98313C30.1751
                9.01276 30.1853 9.04397 30.1915 9.07607L30.7804 8.96115L30.1915 9.07607L30.8679 12.5422L28.9993
                13.4167L26.7714 10.6766C26.7507 10.6512 26.7333 10.6234 26.7194 10.5938L25.145 7.22931C25.1268
                7.19037 25.115 7.14875 25.11 7.10605Z"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
d="M9.29053 18.2399L9.29023 26.2399" stroke="#636E80" strokeWidth="1.2"
strokeLinecap="round" />
            <path
                d="M13.0811 28.329L20.0811 20.329M22.0811 28.329L15.0811 20.329"
                stroke="#636E80"
                strokeWidth="1.2"
                strokeLinecap="round"
            />
        </g>
    </svg>
);
