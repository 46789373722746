export const parseLink = (link: string | null | undefined) => {
    if (!link) {
        return null;
    }

    if (link.includes('@')) {
        return `mailto:${link}`;
    }

    if (link.includes('http')) {
        return link;
    }

    return `https://${link}`;
};

export const parsLinkLabel = (link: string | null | undefined) => {
    if (!link) {
        return null;
    }

    if (link.includes('http')) {
        return link.replace('https://', '').replace('http://', '').split('/')[0].split('?')[0];
    }

    return link;
};
