import { type Column } from '@modules/Investorpro/shared/components/Table';
import {
    type BondCouponsInfo,
    type BondIssueInfo,
    type BondPriceInfo,
    type BondRedemptionInfo,
    type TradeStat,
} from '@modules/Investorpro/modules/BondStockPage/BondPage/services/types';
import { getNumberWithSpacing as addSpacesToNumber } from '@libs/utils';
import { round } from 'lodash';
import { NONE_SYMBOL } from '@modules/Investorpro/shared/constants';
import { formatDate, formatTextBoolean } from '@modules/Investorpro/shared/utils/format.util';

import styles from '../styles.module.scss';
import { type ObjectKeyColumnRow } from './types';

const getNumberWithSpacing = (num: number) => addSpacesToNumber(round(num, 2));

const renderTextRowValue = (
    value: string | number | undefined | null | boolean,
    weight?: number,
    fontSize?: number,
) => {
    if (value === null || value === undefined) {
        return NONE_SYMBOL;
    }

    const renderValue = () => {
        if (typeof value === 'boolean') {
            return formatTextBoolean(value);
        }

        return value;
    };

    return (
        <span style={{ fontWeight: weight ?? 350, fontSize: fontSize ?? 16 }} className={styles.textRowValue}>
            {renderValue()}
        </span>
    );
};

export const LEFT_COUPONS_TABLE_ROWS: Array<ObjectKeyColumnRow<BondCouponsInfo>> = [
    { label: 'Купонов по облигации всего', key: 'totalCoupons', type: 'number' },
    { label: 'Количество купонов в год', key: 'couponsPerYear', type: 'number' },
    { label: 'Вид купона', render: (data) => renderTextRowValue(data?.couponType), type: 'string' },
    { label: 'Фиксированная ставка купона, %', key: 'fixedCouponRate', type: 'price' },
    { label: 'Плавающая ставка купона', key: 'floatingCouponRate', type: 'price' },
    { label: 'Спред к плавающей ставке, %', key: 'spreadToFloatingRate', type: 'price' },
    { label: 'Пересмотр плавающей ставки (раз в год)', key: 'floatingRateReview', type: 'number' },
];

function renderTwoValuesPerSlash<T extends Record<string, string | number | null | boolean>>(
    data: T | null,
    firstValueKey: keyof T,
    secondValueKey: keyof T,
    firstValueType: 'date' | 'number' | 'string' = 'number',
    secondValueType: 'date' | 'number' | 'string' = 'number',
) {
    try {
        if (!data || (data[firstValueKey] === null && !data[secondValueKey] === null)) {
            return NONE_SYMBOL;
        }
        let leftValue: string | T[keyof T] = data[firstValueKey];
        leftValue =
            firstValueType === 'number' && typeof leftValue === 'number' ? getNumberWithSpacing(leftValue) : leftValue;
        leftValue = firstValueType === 'date' && typeof leftValue === 'string' ? formatDate(leftValue) : leftValue;
        leftValue = leftValue === null ? NONE_SYMBOL : leftValue;
        let rightValue: string | T[keyof T] = data[secondValueKey];
        rightValue =
            secondValueType === 'number' && typeof rightValue === 'number' && rightValue !== null
                ? getNumberWithSpacing(rightValue)
                : rightValue;
        rightValue = secondValueType === 'date' && typeof rightValue === 'string' ? formatDate(rightValue) : rightValue;
        rightValue = rightValue === null ? NONE_SYMBOL : rightValue;

        return `${leftValue} / ${rightValue}`;
    } catch (e) {
        return NONE_SYMBOL;
    }
}

export const RIGHT_COUPONS_TABLE_ROWS: Array<ObjectKeyColumnRow<BondCouponsInfo>> = [
    { label: 'Номер текущей купонной выплаты', key: 'currentCouponNumber', type: 'number' },
    { label: 'Ставка текущего купона, %', key: 'currentCouponRate', type: 'price' },
    {
        label: 'Дата выплаты купона',
        key: 'couponPaymentDate',
        type: 'date',
    },
    {
        label: 'Дата фиксации списка',
        key: 'recordDate',
        type: 'date',
    },
    { label: 'Сумма купонов до погашения', key: 'couponsUntilMaturity' },
    { label: 'Сумма купонов до ближ. оферты', key: 'couponsUntilNextOffer' },
];

export const LEFT_ISSUE_TABLE_ROWS: Array<ObjectKeyColumnRow<BondIssueInfo>> = [
    {
        label: 'Дата регистрации',
        key: 'registrationDate',
        type: 'date',
    },
    {
        label: 'Дата начала / окончания размещения',
        render: (data) => renderTwoValuesPerSlash(data, 'placementStartDate', 'placementEndDate', 'date', 'date'),
    },
    {
        label: 'Номинал / Валюта',
        render: (data) => renderTwoValuesPerSlash(data, 'nominal', 'currency', 'number', 'string'),
    },
    {
        label: 'Объем выпуска (шт. / валюта)',
        render: (data) => renderTwoValuesPerSlash(data, 'issueVolumeUnits', 'issueVolumeCurrency'),
    },
    {
        label: 'Объем в обращении (шт. / валюта)',
        render: (data) => renderTwoValuesPerSlash(data, 'circulationVolumeUnits', 'circulationVolumeCurrency'),
    },
    { label: 'Индексируемый номинал', render: (data) => renderTextRowValue(data?.nominalIndexed) },
    { label: 'Амортизация номинала', render: (data) => renderTextRowValue(data?.nominalAmortization) },
    { label: 'Досрочный выкуп / погашение', render: (data) => renderTextRowValue(data?.earlyRepurchaseRedemption) },
    { label: 'Вид гарантии', render: (data) => renderTextRowValue(data?.guaranteeType, 400, 15) },
    { label: 'Полнота гарантии', render: (data) => renderTextRowValue(data?.guaranteeCompleteness) },
    { label: 'Гарантированная сумма', key: 'guaranteedAmount', type: 'number' },
    { label: 'Количество гарантов / получателей', key: 'numberOfGuarantors', type: 'number' },
    { label: 'Ковенанты в проспекте эмиссии', render: (data) => renderTextRowValue(data?.covenantsInProspectus) },
];

export const RIGHT_ISSUE_TABLE_ROWS: Array<ObjectKeyColumnRow<BondIssueInfo>> = [
    {
        label: 'Конвертация в другой инструмент',
        render: (data) => renderTextRowValue(data?.conversionToAnotherInstrument),
    },
    { label: 'Площадка размещения', render: (data) => renderTextRowValue(data?.placementPlatform) },
    { label: 'Тип подписки', render: (data) => renderTextRowValue(data?.subscriptionType) },
    {
        label: 'Способ размещения',
        render: (data) => renderTextRowValue(
                data?.placementMethod
                    ? data?.placementMethod[0].toUpperCase() + data.placementMethod.split('').slice(1).join('')
                    : undefined,
            ),
    },
    {
        label: 'Предложение к размещению (шт. / валюта)',
        render: (data) => renderTwoValuesPerSlash(data, 'placementOfferUnits', 'placementOfferCurrency'),
    },
    {
        label: 'Объем спроса (шт. / валюта)',
        render: (data) => renderTwoValuesPerSlash(data, 'demandVolumeUnits', 'demandVolumeCurrency'),
    },
    {
        label: 'Размещенный объем (шт. / валюта)',
        render: (data) => renderTwoValuesPerSlash(data, 'placedVolumeUnits', 'placedVolumeCurrency'),
    },
    { label: 'Количество сделок при размещении (шт.)', key: 'numberOfDealsAtPlacement', type: 'number' },
    { label: 'Минимальная цена в заявках при размещении, %', key: 'minPriceInPlacementBidsPercentage', type: 'price' },
    {
        label: 'Максимальная цена в заявках при размещении, %',
        key: 'maxPriceInPlacementBidsPercentage',
        type: 'price',
    },
    {
        label: 'Ср. взв. цена по итогам размещения, %',
        key: 'weightedAvgPriceAtPlacementPercentage',
        type: 'price',
    },
    {
        label: 'Доходность при размещении по ср. взв. цене, %',
        key: 'yieldAtPlacementWeightedAvgPricePercentage',
        type: 'price',
    },
];

export const LEFT_PRICE_TABLE_ROWS: Array<ObjectKeyColumnRow<BondPriceInfo>> = [
    { label: 'Остаточный номинал облигации (в валюте номинала)', key: 'remainingNominal', type: 'number' },
    {
        label: 'Чистая цена (в % от номинала / в валюте номинала)',
        render: (data) => renderTwoValuesPerSlash(data, 'cleanPricePercentage', 'cleanPriceCurrency'),
    },
    {
        label: 'НКД (в % от номинала / в валюте номинала)',
        render: (data) => renderTwoValuesPerSlash(data, 'accruedInterestPercentage', 'accruedInterestCurrency'),
    },
    {
        label: 'Полная цена (в % от номинала / в валюте номинала)',
        render: (data) => renderTwoValuesPerSlash(data, 'fullPricePercentage', 'fullPriceCurrency'),
    },
    { label: 'Доходность к погашению по последней сделке, %', key: 'yieldToMaturity', type: 'price' },
    { label: 'Доходность к оферте по последней сделке, %', key: 'yieldToOffer', type: 'price' },
    { label: 'Номер оферты, для которой рассчитана доходность', key: 'offerNumberForYield', type: 'number' },
];

export const RIGHT_PRICE_TABLE_ROWS: Array<ObjectKeyColumnRow<BondPriceInfo>> = [
    { label: 'Текущая доходность по последней сделке, %', key: 'lastDealCurrentYield', type: 'price' },
    { label: 'Простая доходность по последней сделке, %', key: 'lastDealSimpleYield', type: 'price' },
    { label: 'Дюрация Маколея (лет)', key: 'macauleyDuration', type: 'price' },
    { label: 'Дюрация модифицированная', key: 'modifiedDuration', type: 'price' },
    { label: 'Выпуклость', key: 'convexity', type: 'price' },
    { label: 'Стоимостная оценка базисного пункта PVBP', key: 'pvbp', type: 'price' },
];

export const LEFT_REDEMPTION_TABLE_ROWS: Array<ObjectKeyColumnRow<BondRedemptionInfo>> = [
    {
        label: 'Дата погашения выпуска',
        key: 'maturityDate',
        type: 'date',
    },
    { label: 'Погашений частей номинала всего', key: 'totalRedemptions', type: 'number' },
    { label: 'Текущий номинал', key: 'currentNominal', type: 'number' },
    { label: 'Номер текущего погашения номинала', key: 'currentRedemptionNumber', type: 'number' },
    {
        label: 'Дата погашения части номинала',
        key: 'partialRedemptionDate',
        type: 'date',
    },
    {
        label: 'Дата фиксации списка',
        key: 'recordDate',
        type: 'date',
    },
    { label: 'Процент тек. погашения номинала', key: 'currentRedemptionPercentage', type: 'price' },
];

export const RIGHT_REDEMPTION_TABLE_ROWS: Array<ObjectKeyColumnRow<BondRedemptionInfo>> = [
    { label: 'Досрочных выкупов (оферт) всего', key: 'totalEarlyRepurchases', type: 'number' },
    { label: 'Дата ближайшего выкупа (оферты)', key: 'nextRepurchaseDate', type: 'date' },
    { label: 'Номер досрочного выкупа (оферты)', key: 'earlyRepurchaseNumber', type: 'number' },
    { label: 'Тип ближайшего выкупа (оферты)', render: (data) => renderTextRowValue(data?.nextRepurchaseType) },
    { label: 'Окончание предъявления к выкупу', key: 'endOfRepurchaseSubmission', type: 'date' },
    { label: 'Цена выкупа', key: 'repurchasePrice', type: 'price' },
    { label: 'Предельный объем тек. выкупа, шт.', key: 'maxRepurchaseVolume', type: 'number' },
];

export const TRADE_RESULTS_TABLE_COLUMNS: Array<Column<TradeStat>> = [
    {
        id: 'date',
        label: 'Дата торгов',
        field: 'tradeDate',
        align: 'center',
        width: '119px',
        type: 'date',
    },
    {
        id: 'first',
        field: 'open',
        width: '126px',
        type: 'price',
        label: 'Первая, %',
    },
    {
        id: 'minPrice',
        field: 'min',
        width: '136px',
        type: 'price',
        label: 'Мин. цена, %',
    },
    {
        id: 'maxPrice',
        field: 'max',
        width: '136px',
        type: 'price',
        label: 'Макс. цена, %',
    },
    {
        id: 'last',
        width: '131px',
        field: 'close',
        type: 'price',
        label: 'Последняя, %',
    },
    {
        id: 'avgPrice',
        field: 'waprice',
        width: '144px',
        type: 'price',
        label: 'Ср. взв. цена, %',
    },
    {
        id: 'profitabilityByAvgPrice',
        field: 'yieldAtWap',
        width: '185px',
        type: 'price',
        label: 'Доходность по ср. взв.',
    },
    {
        id: 'transactionCount',
        field: 'numtrades',
        width: '134px',
        label: 'Cделок, шт',
        type: 'number',
    },
    {
        id: 'volumeCount',
        field: 'volume',
        width: '134px',
        label: 'Объем, шт',
        type: 'number',
    },
    {
        id: 'volumeVol',
        field: 'value',
        width: '208px',
        label: 'Объем, вал',
        type: 'price',
    },
    {
        id: 'rpcCount',
        field: 'ndmNumtrades',
        width: '165px',
        type: 'number',
        label: 'Cделок РПС, шт',
    },
    {
        id: 'rpcVol',
        width: '208px',
        type: 'number',
        label: 'Объем РПС, вал',
        field: 'ndmValue',
    },
];
