import { type AppDispatch, useAppDispatch, useAppSelector } from '@store/store';
import { addKeyToLoading, removeKeyFromLoading, showLoader } from '@store/store/slices/loading.slice';

type UseLoadingResult<T extends string | undefined> = {
    startLoading: T extends string ? (key?: T | undefined | string) => void : (key: string) => void;
    stopLoading: T extends string ? (key?: T | undefined | string) => void : (key: string) => void;
    dispatch: AppDispatch;
    isLoading: boolean;
    isTotalLoading: boolean;
    loadingKeys: string[];
    startTotalLoading: () => void;
    stopTotalLoading: () => void;
}

export const useLoading = <T extends string | undefined>(key?: T): UseLoadingResult<T> => {
    const dispatch = useAppDispatch()
    const { loadingKeys, isLoading } = useAppSelector((state) => state.loading)

    const startLoadingByKey = (key: string) => {
        if (key) {
            dispatch(addKeyToLoading(key))
        }
    }

    const stopLoadingByKey = (key: string) => {
        if (key) {
            dispatch(removeKeyFromLoading(key))
        }
    }

    const startLoadingByDefaultKey = (customKey = key) => {
        if (customKey) {
            dispatch(addKeyToLoading(customKey))
        }
    }

    const stopLoadingByDefaultKey = (customKey = key) => {
        if (customKey) {
            dispatch(removeKeyFromLoading(customKey))
        }

    }

    const startTotalLoading = () => {
        dispatch(showLoader())
    }

    const stopTotalLoading = () => {
        dispatch(showLoader())
    }

    return {
        startLoading: key ? startLoadingByDefaultKey : startLoadingByKey as any,
        stopLoading: key ? stopLoadingByDefaultKey : stopLoadingByKey as any,
        dispatch,
        startTotalLoading,
        stopTotalLoading,
        isLoading: isLoading ?? loadingKeys.length > 0,
        isTotalLoading: isLoading,
        loadingKeys,
    }
}
