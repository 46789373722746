import { Group, Stack } from '@mantine/core';
import { type FC } from 'react';
import { useParams } from 'react-router-dom';

import { type InferParams } from '../../../shared/router';
import { ReturnOnValueTable } from './Tables/ReturnOnValueTable';
import { TopFiveDividends } from './Tables/TopFiveDividends';
import { TopFiveTotalReturn } from './Tables/TopFiveTotalReturn';
import { TypologyAssetsBondsMatrix } from './TypologyAssetsBondsMatrix';
import { TypologyAssetsFundsMatrix } from './TypologyAssetsFundsMatrix';
import { TypologyAssetsStocksMatrix } from './TypologyAssetsStocksMatrix';
import { useGetTypologyAssetsBondsQuery } from './useGetTypologyAssetsBondsQuery';
import { useGetTypologyAssetsFundsQuery } from './useGetTypologyAssetsFundsQuery';
import { useGetTypologyAssetsStocksQuery } from './useGetTypologyAssetsStocksQuery';

export const TypologyAssets: FC = () => {
    const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;
    const typologyAssetsStocksQuery = useGetTypologyAssetsStocksQuery(portfolioId);
    const typologyAssetsBondsQuery = useGetTypologyAssetsBondsQuery(portfolioId);
    const typologyAssetsFundsQuery = useGetTypologyAssetsFundsQuery(portfolioId);

    return (
        <Stack gap={60}>
            <Group justify="space-between" align="start" gap={60}>
                <Stack miw={548} flex={1}>
                    <TypologyAssetsStocksMatrix typologyAssetsStocksQuery={typologyAssetsStocksQuery} />
                </Stack>
                <Stack miw={548} flex={1}>
                    <TypologyAssetsBondsMatrix typologyAssetsBondsQuery={typologyAssetsBondsQuery} />
                </Stack>
                <Stack miw={548} flex={1}>
                    <TypologyAssetsFundsMatrix typologyAssetsFundsQuery={typologyAssetsFundsQuery} />
                </Stack>
            </Group>

            <Group justify="space-between" align="start" gap={60}>
                <Stack miw={548} flex={1} gap={25}>
                    <ReturnOnValueTable portfolioId={portfolioId} />
                </Stack>

                <Stack miw={548} flex={1} gap={25}>
                    <TopFiveDividends portfolioId={portfolioId} />
                </Stack>

                <Stack miw={548} flex={1} gap={25}>
                    <TopFiveTotalReturn portfolioId={portfolioId} />
                </Stack>
            </Group>
        </Stack>
    );
};
