import {
    getBonds,
    getBondsLiquidity,
    getBondsProfit,
    getShares,
    getSharesIndexes,
    getStocksLiquidity,
    getStocksProfit,
} from '@libs/services/instrument.service';
import {
    type ProfitPeriod,
    type CapitalizationInstrumentData,
} from '@libs/types/instrument.type';
import { formatDateToQuery } from '@libs/utils';
import { refetchDataUntilFindNotEmpty } from '@modules/Investorpro/modules/MarketPages/shared/utils/fetch.utils';
import { SecurityBoards } from '@modules/Investorpro/modules/MarketPages/shared/constants';

import { type InstrumentCategory, InstrumentType, StockCategoryOptionValue } from '../types';

export const loadLiquidityDataFacade = async (
    instruments: CapitalizationInstrumentData[],
    instrumentType: InstrumentType,
    signal?: AbortSignal,
) => {
    const isins = instruments.map((item) => item.isin);

    try {
        if (instrumentType === InstrumentType.BONDS) {
            const response = await getBondsLiquidity({ isin: isins, signal });

            return response.data;
        }

        const response = await getStocksLiquidity({ isin: isins, signal });

        return response.data;
    } catch (e) {
        console.error('e', e);

        if ((e as Error).name === 'CanceledError') {
            throw e
        }

        return [];
    }
};

export const getProfitFacade = async (
    uniqueInstruments: CapitalizationInstrumentData[],
    instrumentType: InstrumentType,
    period: ProfitPeriod,
    signal?: AbortSignal,
) => {
    const isin = uniqueInstruments.map((item) => item.isin);
    const loadProfitService = instrumentType === InstrumentType.BONDS ? getBondsProfit : getStocksProfit;

    return await loadProfitService({ isin, period, signal })
};

export const getInstrumentsByTypeFacade = async (
    instrumentType: InstrumentType,
    instrumentCategory: InstrumentCategory,
    tradeDate: Date,
    signal?: AbortSignal,
) => {
    let capitalizationData: CapitalizationInstrumentData[] = [];

    if (instrumentType === InstrumentType.BONDS) {
        const [response] = await refetchDataUntilFindNotEmpty({
            fetchData: async (date) => await getBonds(
                date,
                instrumentCategory,
                [SecurityBoards.TQCB, SecurityBoards.TQOB],
                signal,
            ),
            start: tradeDate,
            step: 1,
            format: formatDateToQuery,
            responseDataAccessor: (resp) => resp.data.bonds,
        });

        capitalizationData = response.filter(({ group }) => group === 'stock_bonds');
    }

    if (instrumentType === InstrumentType.STOCKS) {
        if (instrumentCategory === StockCategoryOptionValue.ALL_STOCKS) {

            const [response] = await refetchDataUntilFindNotEmpty({
                fetchData: async (date) => await getShares(date, [SecurityBoards.TQBR], signal),
                start: tradeDate,
                step: 1,
                format: formatDateToQuery,
                responseDataAccessor: (resp) => resp.data.shares,
            });

            capitalizationData = response.filter(({ group }) => group === 'stock_shares');
        }

        if (
            [StockCategoryOptionValue.TOP_100, StockCategoryOptionValue.MOEX_INDEX].includes(
                instrumentCategory as StockCategoryOptionValue,
            )
        ) {
            const [response] = await refetchDataUntilFindNotEmpty({
                fetchData: async (date) => (
                    await getSharesIndexes(tradeDate, instrumentCategory, signal)
                ),
                start: tradeDate,
                step: 1,
                format: formatDateToQuery,
                responseDataAccessor: (resp) => resp.data.shares,
            });

            capitalizationData = response;
        }
    }

    return capitalizationData;
};
