import React from 'react';

type Props = {
    filter?: string;
    str: string;
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export const HighlightText = ({ filter, str, style }: Props & React.HTMLAttributes<HTMLSpanElement>) => {
    if (!filter) return <>{str}</>;

    const regexp = new RegExp(filter, 'ig');
    const matchValue = str.match(regexp);

    if (matchValue) {
        return (
            <>
                {str.split(regexp).map((s, index, array) => {
                    if (index < array.length - 1) {
                        const c = matchValue.shift();

                        return (
                            <>
                                {s}
                                <span style={style}>{c}</span>
                            </>
                        );
                    }

                    return <>{s}</>;
                })}
            </>
        );
    }

    return <>{str}</>;
};
