import { Group, Text } from '@mantine/core';
import { type FC } from 'react';

type Props = {
    percent: number;
    portfolioCurrency: string;
};

export const FreeMoneyHeader: FC<Props> = ({ percent, portfolioCurrency }) => (
    <Group justify="end" gap={8}>
        <Text
style={{ color: '#8A96A6', textAlign: 'end' }} span={true} fz={13}
fw={400} lh="15px">
            {'Доля в портфеле'}
            <Text
fz={13} fw={400} lh="15px"
style={{ color: percent >= 0 ? '#42A62E' : '#F2433D' }}>
                {`Распределено ${(100 - percent).toFixed(2)}%`}
            </Text>
        </Text>
        <Text
w={156} fz={13} fw={400}
lh="15px" style={{ whiteSpace: 'pre-wrap', color: '#8A96A6', textAlign: 'end' }}>
            {`Сумма по \n инструменту, ${portfolioCurrency}.`}
        </Text>
    </Group>
);
