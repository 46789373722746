import { createAsyncThunk } from "@reduxjs/toolkit";
import { removeAvatar } from "@libs/services";

const avatarRemoveThunk = createAsyncThunk('user/removeAvatar', async (_, { rejectWithValue }) => {
  try {
    await removeAvatar();
    // return {};
  } catch (e: any) {
    console.error('e', e);

    return rejectWithValue(e);
  }
});

export { avatarRemoveThunk };
