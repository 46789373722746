import { useEffect } from 'react';
import { Table } from '@modules/Investorpro/shared/components/Table';
import { type AppDispatch, type RootState } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { getBondTradesResultsThunk } from '@store/store/thunk/investorpro/bond.thunk';
import { Paginator } from '@modules/Investorpro/shared/components/Paginator';
import { setBondTradesResultsPage } from '@store/store/slices/investorpro/bond.slice';
import { WithLoader } from '@libs/components/loaders/ViewLoader';

import { TRADE_RESULTS_TABLE_COLUMNS } from '../../utils/columnsAndRows';
import styles from './styles.module.scss';

export const TradeResults = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { total, page, data, loaded } = useSelector((state: RootState) => state.bond.descriptionInfo.tradesResults);
    const isLoading = useSelector((state: RootState) => state.bond.loadings.commonLoading);
    const { secId } = useSelector((state: RootState) => state.bond);

    useEffect(() => {
        if (secId && !loaded && !isLoading) {
            dispatch(
                getBondTradesResultsThunk({
                    secId,
                    page,
                }),
            );
        }
    }, [page, dispatch, secId, loaded, isLoading]);

    const handlePageChange = (page: number) => {
        dispatch(setBondTradesResultsPage(page));
    };

    return (
        <>
            <WithLoader isLoading={isLoading}>
                <Table data={data} columns={TRADE_RESULTS_TABLE_COLUMNS} />
            </WithLoader>
            <Paginator
                page={page}
                className={styles.paginator}
                total={total}
                isLoading={isLoading}
                handlePageChange={handlePageChange}
                limit={14}
            />
        </>
    );
};
