import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const Sparkline: FC<Props> = ({ width = 18, height = 18, color = 'currentColor' }) => (
    <svg
width={width} height={height} viewBox="0 0 18 18"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.2"
            d="M17.5 15.5V2.8C17.5 2.35817 17.1418 2 16.7 2H0.762469C0.617511 2 0.5 2.11751 0.5
            2.26247C0.5 2.39731 0.602178 2.51022 0.736353 2.52364L4.15104 2.8651C5.00304 2.9503
            5.76922 3.42066 6.23079 4.14186L12.32 13.6562C13.0552 14.805 14.3251 15.5 15.6891 15.5H17.5Z"
            fill={color}
        />
        <path
            d="M0.5 2.5H2.76482C4.15386 2.5 5.44346 3.22062 6.17145 4.40361L11.8285
            13.5964C12.5565 14.7794 13.8461 15.5 15.2352 15.5H17.5"
            stroke={color}
            strokeLinecap="round"
        />
    </svg>
);
