import classNames from 'classnames';
import { formatInstrumentPrice } from '@modules/Investorpro/modules/BondStockPage/shared/utils';
import { isValueExist } from '@libs/utils';

import styles from './styles.module.scss';

type Props = {
    min: number | null;
    max: number | null;
    current: number | null;
    className?: string;
};

export const PriceRange = ({ min, max, current, className }: Props) => {
    const hasMax = isValueExist(max);
    const hasCurrent = isValueExist(current);
    const hasMin = isValueExist(min);
    const maxValue = hasMax && hasCurrent ? Math.max(max, current) : null;
    const minValue = hasMin && hasCurrent ? Math.min(min, current) : null;
    const hasMinValue = minValue !== null;
    const hasMaxValue = maxValue !== null;

    const pointOffset =
        hasCurrent && hasMinValue && hasMaxValue ? ((current - minValue) / (maxValue - minValue)) * 100 : 0;
    const delta = hasMaxValue && hasMinValue ? maxValue - minValue : 0;
    const inGrowthArea = hasCurrent && hasMinValue ? current > minValue + delta * 0.66 : false;
    const inFallArea = hasCurrent && hasMinValue ? current < minValue + delta * 0.33 : false;

    return (
        <div
            className={classNames(
                styles.priceRange,
                inGrowthArea && styles.priceRange__growth,
                inFallArea && styles.priceRange__fall,
                className,
            )}
        >
            {isValueExist(minValue) && formatInstrumentPrice(minValue)}
            <div className={styles.priceRange_track}>
                <span className={styles.priceRange_point} style={{ left: pointOffset + '%' }} />
            </div>
            {isValueExist(maxValue) && formatInstrumentPrice(maxValue)}
        </div>
    );
};
