import { TwoColumnsDataView } from '@modules/Investorpro/modules/BondStockPage/shared/components/TwoColumnsDataView';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';
import { HelpLineWrapper } from '@libs/components';

import styles from './styles.module.scss';
import { ChartsBlock } from './components/ChartsBlock';
import { BOND_GENERAL_INFO_DATA_ROWS } from './constants';

export const BaseInformationTab = () => {
    const baseInfo = useSelector((state: RootState) => state.bond.generalInfo);

    return (
        <div className={styles.baseInformationTabWrapper}>
            <div className={styles.generalInfo}>
                <HelpLineWrapper message="helpline.specificBond.toolInformation">
                    <h2>Общая информация</h2>
                    <TwoColumnsDataView
                        data={baseInfo}
                        options={BOND_GENERAL_INFO_DATA_ROWS}
                        className={styles.aboutInfo}
                    />
                </HelpLineWrapper>
            </div>

            <HelpLineWrapper message="helpline.specificBond.allBonds">
                <ChartsBlock />
            </HelpLineWrapper>
        </div>
    );
};
