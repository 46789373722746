import { type Security } from '@modules/Investorpro/modules/ScreenersPage/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSecurities } from '@modules/Investorpro/shared/services';
import { Watchlist } from '@modules/Investorpro/types/watchlist.type';

export const getFavouriteSecuritiesAndWatchlistsThunk = createAsyncThunk(
    'investorpro/getSecurities',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getSecurities();
            const { securities, watchlists } = response.data;
            const defaultSecurities = securities.reduce((acc: Record<Security['isin'], Security>, security) => {
                acc[security.isin.toString()] = security;

                return acc;
            }, {});
            const defaultWatchlists = watchlists.map((watchlist: Watchlist) => ({
                ...watchlist,
                securities: securities.reduce((acc: Security[], security: Security) => {
                    if (security.watchlistIds.includes(watchlist.id)) {
                        acc.push({
                            ...security,
                        });
                    }
                    return acc;
                }, []),
            }));

            return {
                securities: defaultSecurities,
                watchlists: defaultWatchlists,
            };
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
