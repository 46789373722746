import { type FC } from 'react';

export const TransportIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path
                d="M7.5 27.5L9.20903 26.529C10.332 25.8909 11.708 25.8909 12.831 26.529V26.529C13.8984 27.1355
                15.1987 27.1673 16.2946 26.6139L16.5792 26.4701C17.7871 25.8601 19.2129 25.8601 20.4208
                26.4701L20.7054 26.6139C21.8013 27.1673 23.1016 27.1355 24.169 26.529V26.529C25.292
                25.8909 26.668 25.8909 27.791 26.529L29.5 27.5"
                stroke="#636E80"
                strokeWidth="1.2"
                strokeLinecap="round"
            />
            <path
                d="M12.8863 26.5L9.94446 19.7959C9.74417 19.3395 9.9106 18.8052 10.3347 18.5433L17.9745
                13.8246C18.2966 13.6256 18.7034 13.6256 19.0255 13.8246L26.6653 18.5433C27.0894 18.8052
                27.2558 19.3395 27.0555 19.7959L24.1137 26.5"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
                d="M10.6428 21.5L18.4994 16.5003C18.4998 16.5001 18.5002 16.5001 18.5005 16.5003L26.3571 21.5"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path d="M18.5 16.5V26" stroke="#636E80" strokeWidth="1.2" />
            <path
d="M18.5 12L18.5 13.5" stroke="#636E80" strokeWidth="1.2"
strokeLinecap="round" />
            <path
                d="M21.7191 13C21.7191 12.6686 21.4505 12.4 21.1191 12.4C20.7878 12.4 20.5191 12.6686
                20.5191 13L21.7191 13ZM20.5191 14.5V15.1L21.7191 15.1V14.5H20.5191ZM20.5191
                13V14.5H21.7191V13L20.5191 13Z"
                fill="#636E80"
            />
            <path
                d="M16.4809 13C16.4809 12.6686 16.2122 12.4 15.8809 12.4C15.5495 12.4 15.2809
                12.6686 15.2809 13L16.4809 13ZM15.2809 14.5V15.1L16.4809 15.1V14.5H15.2809ZM15.2809
                13V14.5H16.4809V13L15.2809 13Z"
                fill="#636E80"
            />
            <path
                d="M12.738 17V10.5C12.738 9.94772 13.1858 9.5 13.738 9.5H23.2618C23.8141 9.5 24.2618
                9.94772 24.2618 10.5V17"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
                d="M15.8809 9.5V7C15.8809 6.72386 16.1047 6.5 16.3809 6.5H20.619C20.8951
                6.5 21.119 6.72386 21.119 7V9.5"
                stroke="#636E80"
                strokeWidth="1.2"
            />
            <path
                d="M19.1 3.5C19.1 3.16863 18.8314 2.9 18.5 2.9C18.1686 2.9 17.9 3.16863 17.9 3.5L19.1
                3.5ZM18.5 4.5H17.9H18.5ZM19.1 6.5V4.5H17.9V6.5H19.1ZM19.1 4.5V3.5L17.9 3.5V4.5H19.1Z"
                fill="#636E80"
            />
        </g>
    </svg>
);
