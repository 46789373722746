import { createAsyncThunk } from "@reduxjs/toolkit";
import { uploadAvatarRequest, uploadTicket } from "@libs/services";
import { readAsDataURL } from "@libs/utils";

const avatarUploadThunk = createAsyncThunk('user/uploadAvatar', async (file: File, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
        const ticketResponse = await uploadTicket();
        const { bucket, ticketId } = ticketResponse.data.data;

        const { data: uploadData } = await uploadAvatarRequest(formData as any, ticketId);

        const { data } = await readAsDataURL(file);

        return { uploadData: { bucket: uploadData.data.bucket, fileId: uploadData.data.ticketId }, imageStr: data };
    } catch (e: any) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export { avatarUploadThunk };
