import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { EditModal } from '@modules/Investorpro/shared/components/WatchlistSidebar/components/NotEmptyFavorites/WatchlistDropdown/EditModal';
import { CopyModal } from '@modules/Investorpro/shared/components/WatchlistSidebar/components/NotEmptyFavorites/WatchlistDropdown/CopyModal';
import { DeleteModal } from '@modules/Investorpro/shared/components/WatchlistSidebar/components/NotEmptyFavorites/WatchlistDropdown/DeleteModal';
import { useModal } from '@libs/utils';
import { useState } from 'react';
import { type Watchlist } from '@modules/Investorpro/types/watchlist.type';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '@store/store';
import { setSelectedWatchlist } from '@store/store/slices/investorpro/investorprofile.slice';

import { ReactComponent as TrashIcon } from '../../../../../images/svg/Bin.svg';
import { ReactComponent as CopyIcon } from '../../../../../images/svg/Copy.svg';
import { ReactComponent as PencilIcon } from '../../../../../images/svg/Pencil.svg';
import styles from './styles.module.scss';

export const WatchlistDropdown = () => {
    const dispatch = useDispatch<AppDispatch>();

    const { isOpen: isOpenEditModal, openModal: openEditModal, closeModal: closeEditModal } = useModal();
    const { isOpen: isOpenCopyModal, openModal: openCopyModal, closeModal: closeCopyModal } = useModal();
    const { isOpen: isOpenDeleteModal, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();

    const { watchlists, selectedWatchlist } = useSelector((state: RootState) => state.investorProfile);

    const [editableItem, setEditableItem] = useState<Watchlist>();

    return (
        <>
            <Dropdown
                options={watchlists}
                optionLabel="name"
                optionValue="id"
                highlightOnSelect={false}
                value={selectedWatchlist?.id}
                pt={{
                    panel: { className: styles.customPanel },
                    wrapper: { className: styles.customItemGroup },
                    item: { className: styles.customItem },
                    itemLabel: { className: styles.customItemLabel },
                }}
                onChange={(e) => {
                    dispatch(
                        setSelectedWatchlist(watchlists.find((item: Watchlist) => item.id === e.target.value) || null),
                    );
                }}
                itemTemplate={(option) => (
                    <div className={classNames(styles.watchlistItemWrapper, 'flex', 'justify-content-between')}>
                        <div className={classNames(styles.watchlistItemWrapper_content, 'flex')}>
                            <RadioButton
                                inputId={option.id}
                                value={option.id}
                                checked={option.id === selectedWatchlist?.id}
                                pt={{
                                    root: { className: styles.radioButtonWrapper },
                                    box: { className: styles.radioButtonWrapper_box },
                                    icon: { className: styles.radioButtonWrapper_icon },
                                }}
                            />
                            <div className={classNames(styles.titleWrapper, 'flex', 'flex-column')}>
                                <span className={styles.titleWrapper_title}>{option.name}</span>
                                <span className={styles.titleWrapper_count}>{option.count} инструментов</span>
                            </div>
                        </div>
                        <div className={classNames(styles.watchlistItemWrapper_tools, 'flex')}>
                            <Button
                                icon={<PencilIcon />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setEditableItem(option);
                                    openEditModal();
                                }}
                            />
                            <Button
                                icon={<CopyIcon />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setEditableItem(option);
                                    openCopyModal();
                                }}
                            />
                            <Button
                                icon={<TrashIcon />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setEditableItem(option);
                                    openDeleteModal();
                                }}
                            />
                        </div>
                    </div>
                )}
            />
            <EditModal
                isOpen={isOpenEditModal}
                closeModal={() => {
                    closeEditModal();
                    setEditableItem(undefined);
                }}
                item={editableItem}
            />
            <CopyModal
                isOpen={isOpenCopyModal}
                closeModal={() => {
                    closeCopyModal();
                    setEditableItem(undefined);
                }}
                item={editableItem}
            />
            <DeleteModal
                isOpen={isOpenDeleteModal}
                closeModal={() => {
                    closeDeleteModal();
                    setEditableItem(undefined);
                }}
                item={editableItem}
            />
        </>
    );
};
