import { createSlice, isFulfilled, isRejected } from '@reduxjs/toolkit';
import {
    type ColumnsResponseType,
    type FiltersResponseType,
    type ScreenerType,
} from '@modules/Investorpro/modules/ScreenersPage/types';
import { type DataTableValue } from 'primereact/datatable';
import { type PageableResponse } from '@libs/types';

import {
    copyScreenerThunk,
    createScreenerThunk,
    deleteScreenerThunk,
    getInstrumentsThunk,
    getScreenerColumnsThunk,
    getScreenerFiltersThunk,
    getScreenersThunk,
    updateScreenerThunk,
} from '../../thunk';

export type IScreenersState = {
    screeners: ScreenerType[];
    instruments: PageableResponse<DataTableValue>;
    columns: ColumnsResponseType;
    isInstrumentsLoading: boolean;
    isLoading: boolean;
    error: string | null;
    filters: FiltersResponseType;
};

export const initialScreenersState: IScreenersState = {
    screeners: [],
    instruments: {
        totalRows: 0,
        totalPages: 0,
        size: 12,
        rows: [],
        pageNumber: 1,
    },
    columns: {
        columns: [],
        groups: [],
    },
    isLoading: false,
    isInstrumentsLoading: false,
    error: null,
    filters: {
        filters: [],
        groups: [],
        instrumentTypes: [],
    },
};

export const screenersSlice = createSlice({
    name: 'screeners',
    initialState: initialScreenersState,
    reducers: {
        resetScreeners: (state) => {
            state.isLoading = false;
            state.error = null;
            state.screeners = initialScreenersState.screeners;
        },
        changeCurrentPage: (state, action) => {
            state.instruments.pageNumber = action.payload;
        },
        resetInstruments: (state) => {
            state.instruments = { ...initialScreenersState.instruments };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getScreenersThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getScreenersThunk.fulfilled, (state, action) => {
            state.screeners = action.payload.screeners ?? [];
        });
        builder.addCase(createScreenerThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createScreenerThunk.fulfilled, (state, action) => {
            const updatedScreenersState: ScreenerType[] = [...state.screeners];

            if (action.payload?.data) {
                updatedScreenersState.push(action.payload.data);
            }
            state.screeners = updatedScreenersState;
        });
        builder.addCase(updateScreenerThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateScreenerThunk.fulfilled, (state, action) => {
            const updatedScreenersState: ScreenerType[] = [...state.screeners].map((screener) =>
                screener.id === action.payload?.data?.id ? { ...action.payload.data } : screener,
            );
            state.screeners = updatedScreenersState;
        });
        builder.addCase(copyScreenerThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(copyScreenerThunk.fulfilled, (state, action) => {
            const updatedScreenersState: ScreenerType[] = [...state.screeners];

            if (action.payload) {
                updatedScreenersState.push(action.payload);
            }
            state.screeners = updatedScreenersState;
        });
        builder.addCase(deleteScreenerThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteScreenerThunk.fulfilled, (state, action) => {
            const updatedScreenersState: ScreenerType[] = [...state.screeners].filter(
                (screener) => screener.id !== action.payload,
            );
            state.screeners = updatedScreenersState;
        });
        builder.addCase(getInstrumentsThunk.pending, (state) => {
            state.isInstrumentsLoading = true;
        });
        builder.addCase(getInstrumentsThunk.fulfilled, (state, action) => {
            if (action.payload) {
                state.instruments.rows = action.payload.rows;
                state.instruments.totalRows = action.payload.totalRows;
                state.instruments.totalPages = action.payload.totalPages;
                state.instruments.size = action.payload.size;
            } else {
                state.instruments = { ...initialScreenersState.instruments };
            }
            state.isInstrumentsLoading = false;
        });
        builder.addCase(getInstrumentsThunk.rejected, (state) => {
            state.isInstrumentsLoading = false;
        });
        builder.addCase(getScreenerColumnsThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getScreenerColumnsThunk.fulfilled, (state, action) => {
            state.columns = action.payload ?? { ...initialScreenersState.columns };
        });
        builder.addCase(getScreenerFiltersThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getScreenerFiltersThunk.fulfilled, (state, action) => {
            state.filters = action.payload ?? { ...initialScreenersState.filters };
        });
        builder.addMatcher(isFulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addMatcher(isRejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message ?? 'thunk error';
        });
    },
});

export const screenersReducer = screenersSlice.reducer;

export const { resetScreeners, changeCurrentPage, resetInstruments } = screenersSlice.actions;
