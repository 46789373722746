import { ActionIcon, Group, Stack } from '@mantine/core';
import { Dropzone, type FileRejection } from '@mantine/dropzone';
import { type FC, Fragment, useState } from 'react';

import { Trash } from '../../../shared/Icon';
import { DropZoneContent } from './DropZoneContent';

type Props = {
    logo: string;
    onLogoChange: (value: string) => void;
};

export const DropZone: FC<Props> = ({ logo, onLogoChange }) => {
    const [rejectedFiles, setRejectedFiles] = useState<FileRejection | null>(null);
    const reader = new FileReader();

    return (
        <Fragment>
            {logo
? (
                <ActionIcon
                    style={{ zIndex: 2 }}
                    pos="absolute"
                    top={18}
                    right={19}
                    variant="gray"
                    size="24px"
                    onClick={() => {
                        onLogoChange('');
                        setRejectedFiles(null);
                    }}
                >
                    <Trash width={26} height={26} />
                </ActionIcon>
            )
: null}
            <Dropzone
                dragEventsBubbling={false}
                multiple={false}
                maxFiles={1}
                onDrop={(files) => {
                    const readFile = new Blob(files, { type: files[0].type });

                    reader.readAsDataURL(readFile);
                    reader.onload = () => {
                        onLogoChange(reader.result as string);
                    };
                }}
                onReject={(files) => setRejectedFiles(files[0])}
                maxSize={300 * 1024}
                accept={['image/png', 'image/jpeg']}
                styles={{
                    inner: {
                        paddingLeft: '43px',
                    },
                    root: {
                        padding: '0',
                        border: 'none',
                    },
                }}
            >
                <Stack mih={137} justify="center">
                    <Group justify="start" gap="xl" style={{ pointerEvents: 'none' }}>
                        <Dropzone.Reject>{'Файл данного формата не поддерживается'}</Dropzone.Reject>

                        <Dropzone.Accept>{'Загрузить файл'}</Dropzone.Accept>

                        <DropZoneContent logo={logo} rejectedFiles={rejectedFiles} />
                    </Group>
                </Stack>
            </Dropzone>
        </Fragment>
    );
};
