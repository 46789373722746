import { Box, Group, Stack, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';
import { readLocalStorageValue, useDebouncedValue } from '@mantine/hooks';
import dayjs from 'dayjs';
import { type FC } from 'react';
import { useParams } from 'react-router-dom';

import { PortfolioFilterKeys } from '../../../entities';
import { Calendar } from '../../../shared/Icon';
import { ViewError } from '../../../shared/Layout';
import { type InferParams } from '../../../shared/router';
import { Chart } from './Chart/Chart';
import { DynamicsFilter } from './DynamicsFilter';
import { type DynamicsFilterValues, dynamicsFilterSchema } from './DynamicsFilter.schema';
import { useGetPortfolioDynamicsQuery } from './useGetPortfolioDynamicsQuery';

export const Dynamics: FC = () => {
    const filters = readLocalStorageValue<DynamicsFilterValues>({
        key: PortfolioFilterKeys.DYNAMICS_FILTER,
        defaultValue: {
            ...dynamicsFilterSchema.getDefault(),
            dates: [dayjs().subtract(10, 'years').toDate(), dayjs().toDate()],
        },
    });

    const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;
    const dynamicsFilterForm = useForm<DynamicsFilterValues>({
        initialValues: {
            ...filters,
            dates: [dayjs(filters.dates?.[0]).toDate(), dayjs(filters.dates?.[1]).toDate()],
        },
        validate: yupResolver(dynamicsFilterSchema),
    });
    const [debounced] = useDebouncedValue(dynamicsFilterForm.values, 500);

    const { data, error, isError, isPending } = useGetPortfolioDynamicsQuery(portfolioId, debounced);

    if (isError) {
        return (
            <Stack>
                <Text fz={19} lh="18px">
                    {'Перформанс портфеля'}
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по динамике" />
            </Stack>
        );
    }

    return (
        <form>
            <Group wrap="nowrap" align="flex-start">
                <Box w={417}>
                    <DynamicsFilter form={dynamicsFilterForm} />
                </Box>
                <Stack flex={1}>
                    <Group pl={48} justify="space-between">
                        <Text fz={19} lh="18px">
                            {'Перформанс портфеля'}
                        </Text>
                        <DatePickerInput
                            w={220}
                            radius={6}
                            maxDate={new Date()}
                            rightSection={<Calendar color="#A5B1C0" width={24} height={24} />}
                            valueFormat="DD.MM.YYYY"
                            type="range"
                            placeholder="Выберите период"
                            {...dynamicsFilterForm.getInputProps('dates')}
                            styles={{
                                input: { padding: '6px 18px 6px 12px' },
                            }}
                        />
                    </Group>
                    <Box h={500} w="100%">
                        <Chart data={data ?? { performances: [], events: [] }} isPending={isPending} />
                    </Box>
                </Stack>
            </Group>
        </form>
    );
};
