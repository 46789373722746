import { Button, Group, Stack, Tabs, Text } from '@mantine/core';
import { type FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumbs } from '../shared/Breadcrumbs';
import { List, Pencil } from '../shared/Icon';
import { type InferParams } from '../shared/router';
import { usePortfolios } from './PortfoliosContext';

const tabsData = [
    { label: 'Общие сведения', to: 'general-info' },
    { label: 'Динамика', to: 'dynamics' },
    { label: 'Историческая доходность', to: 'historic-returns' },
    { label: 'Эффективность', to: 'performance' },
    { label: 'Риск метрики', to: 'risk-metrics' },
    { label: 'Стресс-тесты', to: 'stress-tests' },
    { label: 'Диверсификация', to: 'diversification' },
    { label: 'Типология активов', to: 'typology-assets' },
    { label: 'Ликвидность', to: 'liquidity' },
    { label: 'Денежный поток', to: 'cash-flow' },
    { label: 'Отчет', to: 'report' },
];

type Props = {
    portfolioName: string;
};

export const PortfolioNav: FC<Props> = ({ portfolioName }) => {
    const { onPortfolioIdForEditChange } = usePortfolios();
    const params = useParams() as InferParams<':portfolioId/*'>;
    const navigate = useNavigate();

    const handleTabChange = (value: string | null) => {
        if (value) {
            navigate(value);
        }
    };

    const handleAllPortfoliosClick = () => {
        navigate('/portfolio');
    };

    const breadcrumbs = [
        { title: 'Главная', href: '/portfolio' },
        { title: 'Портфели', href: '/portfolio' },
        {
            title: `${portfolioName}`,
            href: `/${params.portfolioId}`,
        },
    ];

    return (
        <Stack gap={26} pb={28}>
            <Breadcrumbs items={breadcrumbs} />
            <Group justify="space-between">
                <Stack gap={0}>
                    <Text
fz={14} fw={400} c="#576273"
lh="16px">
                        {'Мой портфель'}
                    </Text>
                    <Text
fz={24} fw={400} c="#14171F"
lh="28px">
                        {portfolioName}
                    </Text>
                </Stack>
                <Group>
                    <Button
                        variant="outline"
                        leftSection={<List color="#2F9CEB" width={24} height={24} />}
                        onClick={handleAllPortfoliosClick}
                    >
                        {'Все портфели'}
                    </Button>
                    <Button
                        variant="outline"
                        styles={{ section: { color: '#2F9CEB' } }}
                        leftSection={<Pencil width={24} height={24} />}
                        onClick={() => onPortfolioIdForEditChange(params.portfolioId)}
                    >
                        {'Редактировать портфель'}
                    </Button>
                </Group>
            </Group>

            <Tabs value={params['*']} onChange={handleTabChange}>
                <Tabs.List>
                    {tabsData.map((tab) => (
                        <Tabs.Tab
key={tab.to} value={tab.to} px={0}
pb={15}>
                            {tab.label}
                        </Tabs.Tab>
                    ))}
                </Tabs.List>
            </Tabs>
        </Stack>
    );
};
