import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { type FC } from 'react';

import { type ReportFormTitleValues } from './ReportForm.schema';

const styles = StyleSheet.create({
    subTitle: {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: 400,
    },
});

type Props = {
    titleValues: ReportFormTitleValues;
};

export const ReportTitlePage: FC<Props> = ({ titleValues }) => (
    <Page wrap={false} size="A4" style={{ backgroundColor: titleValues.background }}>
        <View
            style={{
                color: titleValues.textColor,
                margin: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                height: '100vh',
                justifyContent: 'space-between',
            }}
        >
            {titleValues?.logo ? <Image style={{ width: 180 }} src={titleValues.logo} /> : <View />}
            <View style={{ gap: '12px' }}>
                <Text
                    style={{
                        fontSize: '40px',
                        textAlign: 'left',
                        fontFamily: 'Inter',
                        fontWeight: 700,
                    }}
                >
                    {titleValues.name}
                </Text>
                <Text style={styles.subTitle}>{titleValues.description}</Text>
            </View>
            <Text
                style={{
                    fontSize: '12px',
                    textAlign: 'left',
                    fontFamily: 'Inter',
                }}
            >
                {titleValues.footer}
            </Text>
        </View>
    </Page>
);
