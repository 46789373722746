import { Text } from '@mantine/core';
import { type UseQueryResult } from '@tanstack/react-query';
import { type FC, Fragment } from 'react';
import {
    type PortfolioTypologyAssetsMatrixData,
    TypologyAssetsStocks,
} from '@modules/Investorpro/modules/PortfolioPage/entities';

import { ViewError, ViewLoader } from '../../../shared/Layout';
import { Matrix } from './Matrix/Matrix';

// TODO: Не передавать весь квэри
type Props = {
    typologyAssetsStocksQuery: UseQueryResult<PortfolioTypologyAssetsMatrixData, Error>;
    isInReport?: boolean;
};

export const TypologyAssetsStocksMatrix: FC<Props> = ({ typologyAssetsStocksQuery, isInReport }) => {
    if (typologyAssetsStocksQuery.isError) {
        return (
            <Fragment>
                <Text fz={19} fw={400}>
                    {'Акции'}
                </Text>
                <ViewError error={typologyAssetsStocksQuery.error} subtitle="Ошибка получения данных по акциям" />
            </Fragment>
        );
    }

    if (typologyAssetsStocksQuery.isPending) {
        return (
            <Fragment>
                <Text fz={19} fw={400}>
                    {'Акции'}
                </Text>
                <ViewLoader />
            </Fragment>
        );
    }

    return (
        <Fragment>
            {!isInReport
? (
                <Text fz={19} fw={400}>
                    {'Акции'}
                </Text>
            )
: null}
            <Matrix matrixData={typologyAssetsStocksQuery.data} keysDecryption={TypologyAssetsStocks} />
        </Fragment>
    );
};
