import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { type Watchlist, type WatchlistRow } from '@modules/Investorpro/types/watchlist.type';
import {
    addSecurityToWatchListThunk,
    copyWatchlistThunk,
    createWatchlistThunk,
    deleteSecurityFromWatchlistThunk,
    deleteWatchlistThunk,
    getFavouriteSecuritiesAndWatchlistsThunk,
    getWatchlistDataThunk,
    getWatchlistsThunk,
    updateWatchlistThunk,
} from '@store/store';
import { type Security } from '@modules/Investorpro/modules/ScreenersPage/types';

export type IState = {
    watchlists: Watchlist[];
    securities: Record<Security['isin'], Security>;
    selectedWatchlist: Watchlist | null;
    watchlistData: WatchlistRow[];
    isLoading: boolean;
    error: string | null;
};

export const initialWatchlistState: IState = {
    watchlists: [],
    securities: {},
    selectedWatchlist: null,
    watchlistData: [],
    isLoading: false,
    error: null,
};

export const investorProfileSlice = createSlice({
    name: 'investorprofile',
    initialState: initialWatchlistState,
    reducers: {
        setSelectedWatchlist(state, action) {
            state.selectedWatchlist = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getWatchlistsThunk.fulfilled, (state, action) => {
            state.watchlists = action.payload.data;

            if (state.selectedWatchlist && action.payload.data.length) {
                state.selectedWatchlist =
                    action.payload?.data.find((item) => item.id === state.selectedWatchlist!.id) ||
                    action.payload.data?.[0];
            } else {
                state.selectedWatchlist = action.payload?.data.length ? action.payload.data[0] : null;
            }
        });
        builder.addCase(getWatchlistsThunk.rejected, (state, action) => {
            state.watchlists = [];
            state.selectedWatchlist = null;
        });
        builder.addCase(createWatchlistThunk.fulfilled, (state, action) => {
            state.selectedWatchlist = action.payload.data;
            state.watchlists = [...state.watchlists, action.payload.data];
        });
        builder.addCase(createWatchlistThunk.rejected, (state, action) => {
            state.selectedWatchlist = null;
            state.watchlists = [];
        });
        builder.addCase(getWatchlistDataThunk.fulfilled, (state, action) => {
            state.watchlistData = action.payload ?? [...initialWatchlistState.watchlistData];
        });
        builder.addCase(getWatchlistDataThunk.rejected, (state, action) => {
            state.watchlistData = [];
        });
        builder.addCase(updateWatchlistThunk.fulfilled, (state, action) => {
            state.watchlists = [...state.watchlists].map((watchlist) => {
                if (watchlist.id === action.payload.data?.id) {
                    return action.payload.data;
                }

                return watchlist;
            });

            state.selectedWatchlist = action.payload?.data ?? null;
        });
        builder.addCase(copyWatchlistThunk.fulfilled, (state, action) => {
            state.watchlists = [...state.watchlists, action.payload.data];
            state.selectedWatchlist = action.payload.data;
        });
        builder.addCase(deleteWatchlistThunk.fulfilled, (state, action) => {
            state.selectedWatchlist = null;

            const updatedWatchlists = [...state.watchlists].filter(({ id }) => id !== action.payload);
            state.watchlists = updatedWatchlists;
            state.watchlistData = [...initialWatchlistState.watchlistData];
        });
        builder.addCase(addSecurityToWatchListThunk.fulfilled, (state, action) => {
            if (action.payload) {
                const { data } = action.payload;
                const updatedWatchlistsState: Watchlist[] = [...state.watchlists].map((watchlist) => {
                    if (watchlist.id !== data?.id) {
                        return watchlist;
                    }

                    return { ...data };
                });
                state.watchlists = updatedWatchlistsState;
            }
        });
        builder.addCase(deleteSecurityFromWatchlistThunk.fulfilled, (state, action) => {
            if (action.payload) {
                const { data } = action.payload;
                const updatedWatchlistsState: Watchlist[] = [...state.watchlists].map((watchlist) => {
                    if (watchlist.id !== data?.id) {
                        return watchlist;
                    }

                    return { ...data };
                });
                state.watchlists = updatedWatchlistsState;
            }
        });
        builder.addCase(getFavouriteSecuritiesAndWatchlistsThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getFavouriteSecuritiesAndWatchlistsThunk.fulfilled, (state, action) => {
            state.securities = action.payload.securities;
            state.watchlists = action.payload.watchlists;
            state.isLoading = false;
        });
        builder.addCase(getFavouriteSecuritiesAndWatchlistsThunk.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message ?? 'thunk error';
        });
        builder.addMatcher(isPending, (state) => {
            state.isLoading = true;
        });
        builder.addMatcher(isFulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addMatcher(isRejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message ?? 'thunk error';
        });
    },
});

export const investorProfileReducer = investorProfileSlice.reducer;

export const { setSelectedWatchlist } = investorProfileSlice.actions;
