import { useState } from 'react';
import { type CapitalizationInstrumentData, type ProfitPeriod } from '@libs/types/instrument.type';

import {
    formatDataLoadingKey,
    formatLiquidityLoadingKey,
    formatProfitLoadingKey,
    getInstrumentStoreCode,
    getStoreKeyWithLoadingByHeatMapType,
    getUniqueInstruments,
    parseLiquidity,
    removeLoadingKeyFromAllHeatMaps,
} from '.';
import {
    type InstrumentStoreType,
    type HeatMapState,
    HeatMapType,
    type InstrumentType,
    type InstrumentCategory,
    type LoadingState,
    type InstrumentIndustryData,
} from '../types';
import { getInstrumentsByTypeFacade, getProfitFacade, loadLiquidityDataFacade } from './facades';

export const useInstrumentStore = () => {
    const [instrumentsStore, setInstrumentsStore] = useState<InstrumentStoreType>({});
    const setInstrumentStoreDataByKey = (
        storeKey: string,
        data: Partial<HeatMapState> | ((prev: HeatMapState) => Partial<HeatMapState>),
    ) => {
        const defaultHeatMapState: HeatMapState = {
            liquidityLoaded: false,
            profitLoaded: [],
            data: [],
            loadings: {
                [HeatMapType.MARKET]: [],
                [HeatMapType.LIQUIDITY]: [],
            },
        };
        setInstrumentsStore((prev) => ({
            ...prev,
            [storeKey]: {
                ...(prev[storeKey] ?? defaultHeatMapState),
                ...(typeof data === 'function' ? data(prev[storeKey] ?? defaultHeatMapState) : data),
            },
        }));
    };

    const setInstrumentLoading = (
        instrumentType: InstrumentType,
        instrumentCategory: InstrumentCategory,
        heatMapKey: HeatMapType,
        key: string,
        loading: boolean,
    ) => {
        const storeKey = getInstrumentStoreCode(instrumentType, instrumentCategory);
        setInstrumentStoreDataByKey(storeKey, (prev) => ({
            loadings: {
                ...prev.loadings,
                [heatMapKey]: loading
                    ? [...prev.loadings[heatMapKey], key]
                    : prev.loadings[heatMapKey].filter((item) => item !== key) ?? [],
            },
        }));
    };

    const addInstrumentLoadingKeys = (storeKey: string, heatMapKey: HeatMapType, keys: string[]) => {
        setInstrumentStoreDataByKey(storeKey, (prev) => ({
            loadings: {
                ...prev.loadings,
                [heatMapKey]: [...prev.loadings[heatMapKey], ...keys],
            },
        }));
    };

    const addInstrumentLoadingKeysInBothHeatMaps = (storeKey: string, keys: string[]) => {
        setInstrumentStoreDataByKey(storeKey, (prev) => ({
            loadings: {
                ...prev.loadings,
                [HeatMapType.LIQUIDITY]: [...prev.loadings[HeatMapType.LIQUIDITY], ...keys],
                [HeatMapType.MARKET]: [...prev.loadings[HeatMapType.MARKET], ...keys],
            },
        }));
    };

    const removeInstrumentLoadingKeys = (storeKey: string, heatMapKey: HeatMapType, keys: string[]) => {
        setInstrumentStoreDataByKey(storeKey, (prev) => ({
            loadings: {
                ...prev.loadings,
                [heatMapKey]: prev.loadings[heatMapKey].filter((item) => !keys.includes(item)),
            },
        }));
    };

    const removeInstrumentLoadingKeysInBothHeatMaps = (storeKey: string, keys: string[]) => {
        setInstrumentStoreDataByKey(storeKey, (prev) => ({
            loadings: {
                ...prev.loadings,
                [HeatMapType.LIQUIDITY]: prev.loadings[HeatMapType.LIQUIDITY].filter((item) => !keys.includes(item)),
                [HeatMapType.MARKET]: prev.loadings[HeatMapType.MARKET].filter((item) => !keys.includes(item)),
            },
        }));
    };

    const getUniqueLoadingKeysForHeatMap = (heatMapKey: HeatMapType, currentStoreKey: string) => {
        const keyWithLoading = getStoreKeyWithLoadingByHeatMapType(instrumentsStore, heatMapKey);
        let keys: string[] = [];

        if (keyWithLoading) {
            const storeElement = instrumentsStore[keyWithLoading];
            const anotherHeatmapLoadingForFoundData = Object.keys(storeElement?.loadings ?? {})
                .filter((key) => key !== heatMapKey)
                .reduce<string[]>((acc, key) => {
                    return acc.concat(storeElement?.loadings[key as keyof LoadingState] ?? []);
                }, []);

            let currentHeatmapLoadingKeys = (instrumentsStore[keyWithLoading]?.loadings[heatMapKey] ?? []).filter(
                (item) => !anotherHeatmapLoadingForFoundData.includes(item),
            );

            if (currentStoreKey === keyWithLoading) {
                const liquidityLoadingKey = formatLiquidityLoadingKey(currentStoreKey);
                const dataLoadingKey = formatDataLoadingKey(currentStoreKey);

                currentHeatmapLoadingKeys = currentHeatmapLoadingKeys.filter(
                    (item) => item !== liquidityLoadingKey && item !== dataLoadingKey,
                );
            }

            keys = currentHeatmapLoadingKeys;
        }

        return {
            storeKeyWithLoading: keyWithLoading,
            keys,
        };
    };

    const resetLoadingsForHeatMap = (storeKey: string, heatMapKey: HeatMapType) => {
        setInstrumentStoreDataByKey(storeKey, (prev) => ({
            loadings: {
                ...prev.loadings,
                [heatMapKey]: [],
            },
        }));
    };

    const getHeatMapWithCertainLoadingKey = (storeKey: string, key: string) => {
        let keyWithLoading: string | null = null;
        const storeElementLoadings = instrumentsStore[storeKey]?.loadings ?? ({} as LoadingState);

        for (const heatMapKey in storeElementLoadings) {
            if (storeElementLoadings[heatMapKey as keyof LoadingState]?.includes(key)) {
                keyWithLoading = heatMapKey;
                break;
            }
        }

        return keyWithLoading;
    };

    const addProfitData =
        (
            instrumentType: InstrumentType,
            instrumentCategory: InstrumentCategory,
            heatMapKey: HeatMapType,
            period: ProfitPeriod,
            addRequest?: (key: string, filter?: string) => AbortSignal,
        ) => async (data: InstrumentIndustryData[]) => {
            if (data.length === 0) {
                return;
            }

            const storeKey = getInstrumentStoreCode(instrumentType, instrumentCategory);

            const setLoading = (loading: boolean, key: string) => setInstrumentLoading(instrumentType, instrumentCategory, heatMapKey, key, loading);

            const periodLoadingKey = formatProfitLoadingKey(storeKey, period);

            try {
                setLoading(true, periodLoadingKey);
                const signal = addRequest
                    ? addRequest(heatMapKey, formatProfitLoadingKey(storeKey, period))
                    : undefined;
                const response = await getProfitFacade(data, instrumentType, period, signal);

                setInstrumentStoreDataByKey(storeKey, (prev) => {
                    const instruments = prev.data.length > 0 ? prev.data : data;
                    const payload = instruments.map((item) => {
                        const profit = response.find(({ isin }) => isin === item.isin);

                        return {
                            ...item,
                            [period]: profit?.profit ?? 0,
                        } as InstrumentIndustryData;
                    });

                    return {
                        data: payload,
                        profitLoaded: [...prev.profitLoaded, period],
                        loadings: removeLoadingKeyFromAllHeatMaps(prev.loadings, periodLoadingKey),
                    };
                });
            } catch (e) {
                setLoading(false, periodLoadingKey);
                console.error('e', e);
            }
        };

    const addLiquidityData =
        (
            instrumentType: InstrumentType,
            instrumentCategory: InstrumentCategory,
            heatMapKey: HeatMapType,
            addRequest?: (key: string, filter?: string) => AbortSignal,
        ) => async (data: InstrumentIndustryData[]) => {
            const storeKey = getInstrumentStoreCode(instrumentType, instrumentCategory);
            const liquidityLoadingKey = formatLiquidityLoadingKey(storeKey);

            const setLoading = (loading: boolean, key: string) => setInstrumentLoading(instrumentType, instrumentCategory, heatMapKey, key, loading);

            try {
                if (data.length === 0) {
                    return;
                }

                setLoading(true, liquidityLoadingKey);
                const response = await loadLiquidityDataFacade(
                    data,
                    instrumentType,
                    addRequest?.(heatMapKey, liquidityLoadingKey),
                );

                setInstrumentStoreDataByKey(storeKey, (prev) => {
                    const instruments = prev.data.length > 0 ? prev.data : data;
                    const payload = instruments.map((item) => {
                        const liquidityItem = response.find(({ isin }) => isin === item.isin);

                        return {
                            ...item,
                            ...parseLiquidity(liquidityItem),
                        } as InstrumentIndustryData;
                    });

                    return {
                        liquidityLoaded: true,
                        data: payload,
                        loadings: removeLoadingKeyFromAllHeatMaps(prev.loadings, liquidityLoadingKey),
                    };
                });
            } catch (e) {
                setLoading(false, liquidityLoadingKey);
                console.error('e', e);
            }
        };

    const addInstrumentsData = async (
        instrumentType: InstrumentType,
        instrumentCategory: InstrumentCategory,
        heatMapKey: HeatMapType,
        tradeDate: Date,
        addRequest?: (key: string, filter?: string) => AbortSignal,
    ) => {
        let aborted = false;
        const storeKey = getInstrumentStoreCode(instrumentType, instrumentCategory);
        const dataLoadingKey = formatDataLoadingKey(storeKey);

        const setLoading = (loading: boolean, key: string) => setInstrumentLoading(instrumentType, instrumentCategory, heatMapKey, key, loading);

        let capitalizationData: CapitalizationInstrumentData[] = [];

        try {
            capitalizationData = await getInstrumentsByTypeFacade(
                instrumentType,
                instrumentCategory,
                tradeDate,
                addRequest?.(heatMapKey, dataLoadingKey),
            );
        } catch (e) {
            console.error('e', e);
            setLoading(false, dataLoadingKey);
            setInstrumentStoreDataByKey(storeKey, (prev) => ({
                data: [],
                loadings: {
                    ...prev.loadings,
                    [heatMapKey]: [],
                },
            }));
            aborted = true;
        }

        if (aborted) return [];

        capitalizationData = capitalizationData.filter(({ issuersector }) => issuersector);
        const { uniqueInstruments } = getUniqueInstruments(capitalizationData);

        setInstrumentStoreDataByKey(storeKey, (prev) => ({
            data: uniqueInstruments,
            loadings: removeLoadingKeyFromAllHeatMaps(prev.loadings, dataLoadingKey),
        }));

        return uniqueInstruments;
    };

    const getNoLoadedPeriods = (storeKey: string, profitPeriod: ProfitPeriod[]) => {
        const instrumentStoreElement = instrumentsStore[storeKey];

        return profitPeriod
            .filter((item) => !instrumentStoreElement?.profitLoaded.includes(item))
            .filter(
                (item) => !Object.keys(instrumentStoreElement?.loadings ?? {}).some((key) => {
                        return instrumentStoreElement?.loadings[key as keyof LoadingState]?.includes(
                            formatProfitLoadingKey(storeKey, item),
                        );
                    }),
            );
    };

    return {
        instrumentsStore,
        setInstrumentStoreDataByKey,
        setInstrumentLoading,
        addProfitData,
        addLiquidityData,
        resetLoadingsForHeatMap,
        addInstrumentLoadingKeys,
        getNoLoadedPeriods,
        addInstrumentsData,
        getHeatMapWithCertainLoadingKey,
        getUniqueLoadingKeysForHeatMap,
        addInstrumentLoadingKeysInBothHeatMaps,
        removeInstrumentLoadingKeys,
        removeInstrumentLoadingKeysInBothHeatMaps,
    };
};
