import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import { type FormEvent } from 'primereact/ts-helpers';
import { HelpLineWrapper } from '@libs/components/layout/HelpLineWrapper';
import { IntervalType, PeriodId } from '@libs/types/instrument.type';

import styles from './styles.module.scss';
import { type Period } from '../../../utils/hooks/useChoosePeriod';

export type PeriodOption = {
    id: string;
    label: {
        value: number | null;
        measure: string;
    };
};
type Props = {
    options: PeriodOption[];
    currentOption: string | null;
    handlePeriodOptionChange: (period: string | null) => void;
    period?: Period;
    handlePeriodChange?: (args: Period) => void;
    showCalendar?: boolean;
    disabled?: boolean;
};

export const DatePeriodSelector = ({
    currentOption,
    handlePeriodChange,
    options,
    period,
    handlePeriodOptionChange,
    showCalendar,
    disabled,
}: Props) => {
    const definetlyShowCalendar = showCalendar && period && handlePeriodChange;
    const isAnyOptionNotActive = currentOption === null;
    const calendarDates = period ? [period?.from, period?.to] : [];

    const handleCalendarDateChange = (e: FormEvent<Array<Date | null>>) => {
        if (e?.value && handlePeriodChange) {
            handlePeriodOptionChange(null);

            handlePeriodChange({
                from: e.value[0] ?? new Date(),
                to: e.value[1] ?? new Date(),
                interval: IntervalType.ONE_MINUTE,
                arrowPos: ['0'],
            });
        }
    };

    return (
        <HelpLineWrapper message="helpline.marketDashboards.changeDataPeriod">
            <div className={styles.datePeriodSelector}>
                {definetlyShowCalendar && (
                    <Calendar
                        value={calendarDates}
                        onChange={handleCalendarDateChange}
                        selectionMode="range"
                        className={classNames(styles.calendar, isAnyOptionNotActive && styles.active)}
                        readOnlyInput
                        dateFormat="dd.mm.yy"
                        locale="ru"
                        showIcon
                        inputClassName={styles.calendarInput}
                    />
                )}
                {options.map(({ id, label: { value, measure } }, index) => (
                    <button
                        key={id}
                        tabIndex={0}
                        onClick={() => handlePeriodOptionChange(id)}
                        className={classNames(
                            styles.datePeriodItem,
                            currentOption === id && styles.active,
                            'flex',
                            'justify-content-center',
                            'align-items-center',
                        )}
                        disabled={disabled}
                    >
                        <div className="flex justify-content-center align-items-baseline">
                            <span className={styles.value}>{value}</span>
                            <span className={classNames(styles.measure)}>{measure}</span>
                        </div>
                    </button>
                ))}
            </div>
        </HelpLineWrapper>
    );
};
