import {
    useResize,
    getCurrentVerticalScrollbarWidth,
    hasDocumentHorizontalScroll,
    getScrollbarWidthFromCSSVariable,
    getObjectKeys,
    hasDocumentVerticalScroll,
    isMobileDevice,
} from '@libs/utils';
import { useEffect } from 'react';

import { type RefsForAddingPaddings, type SetScrollAttributesForElementArgs } from './types';
import { setBodyOverflow, setRootOverflow, setScrollAttributesForElement, updatePaddingOfElements } from './utils';

type UseOverlayPaddingArgs = {
    active: boolean;
    refsForAddingAttributes: Array<React.RefObject<HTMLElement>>;
    refsForAddingPaddings?: RefsForAddingPaddings;
}

export const useOverlayPadding = ({
    active,
    refsForAddingAttributes: refsForAttributes = [],
    refsForAddingPaddings: paddingsRefs = {},
}: UseOverlayPaddingArgs) => {
    const { width } = useResize()
    const isMobile = isMobileDevice()

    const needToAddOffset = width >= 1920 - getCurrentVerticalScrollbarWidth();

    const setScrollAttributesForElements = (args: Omit<SetScrollAttributesForElementArgs, 'ref'>) => {
        refsForAttributes.forEach((ref) => setScrollAttributesForElement({
            ref,
            ...args,
        }))
    }

    const updatePaddingsOfElements = (cb: (padding: number, scrollbarWidth: number) => number) => {
        const scrollbarWidth = getScrollbarWidthFromCSSVariable()
        const currentVerticalScrollBarWidth = Math.min(getCurrentVerticalScrollbarWidth(), scrollbarWidth);

        const verticalScrollBarWidth = width < 1920 && width >= 1920 - currentVerticalScrollBarWidth
            ? Math.abs((1920 - currentVerticalScrollBarWidth) - width + 0.1)
            : currentVerticalScrollBarWidth

        const horizontalScrollBarWidth = hasDocumentHorizontalScroll()
            ? scrollbarWidth
            : 0;

        getObjectKeys(paddingsRefs).forEach((paddingKey) => {
            updatePaddingOfElements(
                paddingsRefs[paddingKey]!,
                paddingKey,
                prevPadding => cb(prevPadding, ['paddingTop', 'paddingBottom'].includes(paddingKey)
                    ? horizontalScrollBarWidth
                    : verticalScrollBarWidth,
                ),
            )
        })
    }

    useEffect(() => {
        if (active) {
            if (isMobile) {
                setRootOverflow('hidden')
            } else {
                setScrollAttributesForElements({
                    hasOverlay: true,
                    horizontalScroll: hasDocumentHorizontalScroll(),
                    verticalScroll: hasDocumentVerticalScroll(),
                });

                if (needToAddOffset) {
                    updatePaddingsOfElements((padding, scrollbarWidth) => padding + scrollbarWidth);
                }

                setBodyOverflow('hidden');
            }


            return () => {
                if (isMobile) {
                    setRootOverflow('auto')
                } else {
                    setBodyOverflow('auto')

                    setScrollAttributesForElements({
                        hasOverlay: false,
                        horizontalScroll: false,
                        verticalScroll: false,
                    })

                    if (needToAddOffset) {
                        updatePaddingsOfElements((padding, scrollbarWidth) => padding - scrollbarWidth);
                    }
                }
            }
        }
    }, [active, needToAddOffset, isMobile]);
}
