import { Text, Tooltip } from '@mantine/core';

export const CustomizedContent = (props: {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    name?: string;
    color?: string;
}) => {
    const { x, y, width, height, name, color } = props;

    return (
        <g>
            <rect
x={x} y={y} width={width}
height={height} style={{ fill: color ?? '#ffffff00' }} />
            <foreignObject
                width={width}
                height={height}
                x={x}
                y={y}
                style={{
                    color: '#fff',
                    paddingTop: Number(height) / 2 - 7,
                }}
            >
                <Tooltip label={name} position="top">
                    <Text
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            textAnchor: 'middle',
                            fontSize: Number(width) > 180 ? 24 : Number(width) / 10,
                            lineHeight: '20px',
                            wordBreak: 'break-word',
                        }}
                    >
                        {name}
                    </Text>
                </Tooltip>
            </foreignObject>
        </g>
    );
};
