import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';
import { type DataTableValue } from 'primereact/datatable';
import { type PageableResponse, type PageableType, type ResponseInfo, SortOrderTypes } from '@libs/types';

import {
    type CopyScreenersReqType,
    type InstrumentsCountRequestType,
    type InstrumentsCountResponseType,
    type RequestScreenerType,
    type ScreenerType,
} from '../../../modules/ScreenersPage/types';

export const SCREENERS_SERVICE_URL = '/moex-datashop-screenerservice/api/screeners/v1/';

export const screenersListGetRequest = async (): Promise<AxiosResponse<{ screeners: ScreenerType[] }>> =>
    await httpClient
        .get(SCREENERS_SERVICE_URL)
        .then(({ data }) => data as AxiosResponse<{ screeners: ScreenerType[] }>);

export const screenerUpdateRequest = async (
    screener: Partial<RequestScreenerType>,
): Promise<ResponseInfo<ScreenerType>> =>
    await httpClient.post(`${SCREENERS_SERVICE_URL}${screener.id}`, screener).then(({ data }) => data);

export const screenerCreateRequest = async (
    screener: Partial<RequestScreenerType>,
): Promise<ResponseInfo<ScreenerType>> => {
    return await httpClient.post(SCREENERS_SERVICE_URL, screener).then(({ data }) => data);
};

export const screenerDeleteRequest = async (
    screenerId: ScreenerType['id'],
): Promise<AxiosResponse<ScreenerType['id']>> => {
    return await httpClient.delete(`${SCREENERS_SERVICE_URL}${screenerId}`);
};

export const DEFAULT_PAGEABLE: PageableType = {
    pageSize: 12,
    pageNumber: 0,
    sortField: 'isin',
    sortOrder: SortOrderTypes.ASC,
};

export const instrumentsGetRequest = (
    screenerId: ScreenerType['id'],
    body: { instrumentTypeId: ScreenerType['instrumentTypeId']; pageable?: Partial<PageableType> },
): Promise<ResponseInfo<PageableResponse<DataTableValue>>> =>
    httpClient
        .post(`${SCREENERS_SERVICE_URL}${screenerId}/instruments`, {
            instrumentTypeId: body.instrumentTypeId,
            pageable: body.pageable
                ? {
                      ...DEFAULT_PAGEABLE,
                      ...body.pageable,
                  }
                : {
                      ...DEFAULT_PAGEABLE,
                  },
        })
        .then(({ data }) => data);

export const instrumentsCountGetRequest = async (
    requestBody: InstrumentsCountRequestType,
): Promise<AxiosResponse<InstrumentsCountResponseType>> =>
    await httpClient.post(`${SCREENERS_SERVICE_URL}instruments_count`, requestBody).then(({ data }) => data);

export const screenerCopyPostRequest = async ({
    screenerId,
    name,
}: CopyScreenersReqType): Promise<AxiosResponse<ScreenerType>> =>
    await httpClient.post(`${SCREENERS_SERVICE_URL}copy`, { screenerId, name }).then(({ data }) => data);
