
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

type IState = {
    layout: {
        openedOverlays: string[];
    };
}

const initialState: IState = {
    layout: {
        openedOverlays: [],
    },
};

export const technicalSlice = createSlice({
    name: 'technical',
    initialState,
    reducers: {
        openOverlay: (state, action: PayloadAction<string>) => {
            state.layout.openedOverlays.push(action.payload);
        },
        closeOverlay: (state, action: PayloadAction<string>) => {
            state.layout.openedOverlays = state.layout.openedOverlays.filter((key) => key !== action.payload);
        },
    },
});

export const technicalReducer = technicalSlice.reducer;

export const { openOverlay, closeOverlay } = technicalSlice.actions;
