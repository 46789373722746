import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';

import { ReactComponent as TelegramIcon } from '../../images/svg/Telegram.svg';
import { ReactComponent as VkIcon } from '../../images/svg/Vk.svg';
import { ReactComponent as YouTubeIcon } from '../../images/svg/YouTube.svg';
import styles from './styles.module.scss';

type Props = {
    className?: string;
};

export const Footer = ({ className }: Props) => {
    return (
        <section className={classNames(styles.footerWrapper, className)}>
            <div className={styles.footerHeader}>
                <div className={styles.headerInfo}>
                    <a href="/" className={styles.disabled}>
                        <p>Раскрытие информации</p>
                    </a>
                    <a href="/" className={styles.disabled}>
                        <p>Пользовательское соглашение</p>
                    </a>
                    <a href="/" className={styles.disabled}>
                        <p>Политика обработки персональных данных</p>
                    </a>
                    <a href="/" className={styles.disabled}>
                        <p>Справочный центр</p>
                    </a>
                </div>

                <div className={styles.socialWrapper}>
                    <a href={'/'} className={styles.disabled}>
                        <Button rounded text style={{ padding: '0' }}>
                            <TelegramIcon />
                        </Button>
                    </a>

                    <a href={'/'} className={styles.disabled}>
                        <Button rounded text style={{ padding: '0' }}>
                            <VkIcon />
                        </Button>
                    </a>

                    <a href={'/'} className={styles.disabled}>
                        <Button rounded text style={{ padding: '0' }}>
                            <YouTubeIcon />
                        </Button>
                    </a>
                </div>
            </div>

            <div className={styles.infoWrapper}>
                <p className="m-0">© DataShop, 2023 г. — Работает в рамках ПАО Московская биржа.</p>
                <p className="m-0">
                    Все права на информацию и аналитические материалы, размещенные на настоящем сайте Биржи, защищены в
                    соответствии с российским законодательством. Прежде чем приступить к использованию сайта предлагаем
                    ознакомиться с Пользовательским соглашением. Воспроизведение, распространение и иное использование
                    информации, размещенной на сайте Биржи, или ее части допускается только с предварительного
                    письменного согласия Биржи.
                </p>
            </div>
        </section>
    );
};
