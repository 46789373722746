import { type FC, type ReactNode, createContext, useContext, useMemo, useState } from 'react';

export type PortfoliosContextType = {
    onPortfolioIdForEditChange: (id: string | null) => void;
    portfolioIdForEdit: string | null;
};

export const PortfoliosContext = createContext<PortfoliosContextType | null>(null);

export const usePortfolios = (): PortfoliosContextType => {
    const context = useContext(PortfoliosContext);

    if (!context) {
        throw new Error('PortfoliosContext: usePortfolios should only be used inside an PortfoliosProvider');
    }

    return context;
};

type Props = {
    children: ReactNode;
};

export const PortfoliosProvider: FC<Props> = ({ children }) => {
    const [portfolioIdForEdit, setPortfolioIdForEdit] = useState<string | null>(null);

    const value = useMemo<PortfoliosContextType>(
        () => ({
            onPortfolioIdForEditChange: setPortfolioIdForEdit,
            portfolioIdForEdit,
        }),
        [portfolioIdForEdit],
    );

    return <PortfoliosContext.Provider value={value}>{children}</PortfoliosContext.Provider>;
};
