import { ReactComponent as Arrow } from '@shared/images/svg/ArrowLeft.svg';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
    handleShiftLeft: () => void;
    handleShiftRight: () => void;
    isLeftDisabled?: boolean;
    isRightDisabled?: boolean;
};

export const DateNavigationButtons = ({
    handleShiftLeft,
    handleShiftRight,
    isLeftDisabled,
    isRightDisabled,
}: Props) => {
    return (
        <div className={classNames('flex', styles.dateNavigation)}>
            <button onClick={handleShiftLeft} disabled={isLeftDisabled}>
                <Arrow />
            </button>
            <button onClick={handleShiftRight} disabled={isRightDisabled}>
                <Arrow />
            </button>
        </div>
    );
};
