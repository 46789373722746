import { type Client } from '@stomp/stompjs';
import { createContext } from 'react';

export type StompContextType = {
    isConnected: boolean;
    isActive: boolean;
    client: Client | null;
};

export const StompContext = createContext<StompContextType>({
    isConnected: false,
    isActive: false,
    client: null,
});
