import { Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import { type PortfolioPerformanceResponse } from '../../../../entities/portfolio';
import { ViewEmptyStub } from '../../../../shared/Layout';
import { Table } from '../../../../shared/Table/Table';
import { PerformanceChart } from '../../Performance/Chart/PerformanceChart';
import { useFindMinDeltaIndex } from '../../Performance/Table/useFindMinDeltaIndex';
import { usePerformanceColumns } from '../../Performance/Table/usePerformanceColumns';
import { InfoIcon } from '../ReportIcons/InfoIcon';

type Props = {
    data: PortfolioPerformanceResponse;
};

export const ReportPerformanceRisk: FC<Props> = ({ data }) => {
    const minDeltaIndex = useFindMinDeltaIndex(data?.allocations ?? []);
    const columns = usePerformanceColumns('VOLATILITY_MODEL', minDeltaIndex);

    return (
        <Stack gap={33} w={1350} p={5}>
            <Stack
w="100%" bg="#2F9CEB1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
                <Group align="center" justify="space-between">
                    <Text
fz="45px" fw={400} lh="47px"
style={{ whiteSpace: 'pre-wrap' }}>
                        {'Эффективность портфеля \n(за период расчета)'}
                    </Text>
                    <Group
                        gap={12}
                        miw={180}
                        mih={24}
                        px={16}
                        align="center"
                        py={8}
                        style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                    >
                        <InfoIcon />
                        <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                            {'Информация'}
                        </Text>
                    </Group>
                </Group>
                <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px" style={{ whiteSpace: 'pre-wrap' }}>
                    {
                        'Раздел Эффективность в графическом виде поможет понять, как в вашем портфеле сочетаются \
риск и доходность.\n\nЭффективный портфель будет находиться выше или на кривой \
эффективности. Если портфель лежит ниже кривой, значит он неэффективен и его \
можно улучшить, нарастив доходность и/или снизив риск.'
                    }
                </Text>
            </Stack>
            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'Эффективность'}
                </Text>
                <PerformanceChart data={data.allocations} performanceType="VOLATILITY_MODEL" isInReport={true} />
            </Stack>
            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'Перформанс портфеля'}
                </Text>
                <Table
                    columns={columns}
                    data={data.allocations}
                    striped={true}
                    emptyStub={<ViewEmptyStub title="Данных по параметрам эффективности нет" />}
                />
            </Stack>
        </Stack>
    );
};
