import { type PortFolioCost } from '../../../../../PortfolioPage/entities';
import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getPortfolioCost: ApiFactory<
    ApiEndpoint<PortFolioCost, void, { id: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<PortFolioCost>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/common-data/portfolio-cost/${params.id}`,
        { signal },
    );

    return data;
};
