import { SelectedButtonTabs } from '@modules/Investorpro/shared/components/SelectedButtonTabs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { use } from 'i18next';

import { TradesResultsChart } from './components/TradesResultsChart';
import { PaymentsChart } from './components/PaymentsChart';
import styles from './styles.module.scss';

export const ChartsBlock = () => {
    const options = [
        {
            label: 'График платежей',
            value: 'payments',
            tab: <PaymentsChart />,
        },
        {
            label: 'Итоги торгов',
            value: 'trades',
            tab: <TradesResultsChart />,
        },
    ];
    const { secId } = useParams();
    const [currentTableDataType, setCurrentTableDataType] = useState(options[0].value);
    const currentOption = options.find((option) => option.value === currentTableDataType);

    useEffect(() => {
        setCurrentTableDataType(options[0].value);
    }, [secId]);

    return (
        <SelectedButtonTabs
            options={options}
            value={currentTableDataType}
            className={styles.chartsBlock}
            handleChange={setCurrentTableDataType}
            title="График"
        >
            {currentOption?.tab}
        </SelectedButtonTabs>
    );
};
