import {
    type BondPaymentAmortisationData,
    type BondPaymentEarlyRedemptionData,
    type BondPaymentCouponData,
} from '@modules/Investorpro/modules/BondStockPage/BondPage/services/types';
import { type Column } from '@modules/Investorpro/shared/components/Table';
import { transformColumnsFromFixedWidthToPercentage } from '@modules/Investorpro/shared/utils/table.utils';

import styles from './styles.module.scss';

export const COUPONS_COLUMNS: Array<Column<BondPaymentCouponData>> = transformColumnsFromFixedWidthToPercentage([
    {
        id: 'couponNumber',
        field: 'couponNumber',
        label: '№ купона',
        type: 'number',
        className: styles.textRowValue,
        width: '118px',
        align: 'left',
    },
    {
        id: 'couponStartDate',
        field: 'couponStartDate',
        label: 'Начало купонного периода',
        width: '242px',
        align: 'right',
        type: 'date',
    },
    {
        id: 'couponEndDate',
        field: 'couponEndDate',
        label: 'Окончание купонного периода',
        width: '242px',
        align: 'right',
        type: 'date',
    },
    {
        id: 'paymentDate',
        field: 'paymentDate',
        label: 'Дата выплаты по купону',
        width: '242px',
        align: 'right',
        type: 'date',
    },
    {
        id: 'rateDetermination',
        field: 'rateDetermination',
        label: 'Определение купонной ставки',
        width: '242px',
        type: 'string',
        className: styles.textRowValue,
        align: 'right',
        alignEmptyCell: 'left',
    },
    {
        id: 'listFixationDate',
        field: 'listFixationDate',
        label: 'Дата фиксации списка',
        width: '202px',
        type: 'date',
    },
    {
        id: 'couponPeriod',
        field: 'couponPeriod',
        label: 'Купонный период',
        width: '162px',
        type: 'number',
    },
    {
        id: 'interestRate',
        field: 'interestRate',
        label: 'Ставка (% годовых)',
        width: '172px',
        type: 'price',
    },
    {
        id: 'paymentAmount',
        field: 'paymentAmount',
        label: 'Размер выплаты (RUB)',
        width: '210px',
        type: 'price',
    },
]);

export const EARLY_REDEMPTION_COLUMNS: Array<Column<BondPaymentEarlyRedemptionData>> =
    transformColumnsFromFixedWidthToPercentage([
        {
            id: 'offerNumber',
            field: 'offerNumber',
            label: '№ выкупа оферты',
            width: '102px',
            align: 'left',
            className: styles.textRowValue,
            type: 'number',
        },
        {
            id: 'offerStartDate',
            field: 'offerStartDate',
            type: 'date',
            label: 'Начало предъявления к выкупу',
            width: '183px',
        },
        {
            id: 'offerEndDate',
            field: 'offerEndDate',
            type: 'date',
            label: 'Окончание предъявления к выкупу',
            width: '207px',
        },
        {
            id: 'offerAnnouncementDate',
            field: 'offerAnnouncementDate',
            type: 'date',
            label: 'Объявление выкупа',
            width: '121px',
        },
        {
            id: 'settlementDate',
            field: 'settlementDate',
            type: 'date',
            label: 'Расчетная дата выкупа',
            width: '117px',
        },
        {
            id: 'repurchaseDate',
            field: 'repurchaseDate',
            label: 'Дата выкупа',
            type: 'date',
            width: '108px',
        },
        {
            id: 'daysSincePlacement',
            field: 'daysSincePlacement',
            label: 'Дней с начала размещения',
            width: '131px',
            type: 'number',
        },
        {
            id: 'repurchaseType',
            field: 'repurchaseType',
            label: 'Тип выкупа',
            width: '136px',
            align: 'left',
            type: 'string',
            className: styles.textRowValue,
            trimText: true,
        },
        {
            id: 'dirtyPrice',
            field: 'dirtyPrice',
            label: 'Цена выкупа грязная, %',
            width: '121px',
            type: 'price',
        },
        {
            id: 'maximumRepurchaseVolume',
            field: 'maximumRepurchaseVolume',
            label: 'Предельный объем выкупа, шт.',
            width: '157px',
            type: 'number',
        },
        {
            id: 'offeredRepurchaseVolume',
            field: 'offeredRepurchaseVolume',
            label: 'Предъявленный к выкупу объем, шт.',
            width: '157px',
            type: 'number',
        },
        {
            id: 'repurchasedVolume',
            field: 'repurchasedVolume',
            label: 'Выкупленный объем, шт.',
            width: '133px',
            type: 'number',
        },
        {
            id: 'satisfactionRatio',
            field: 'satisfactionRatio',
            label: 'Коэф. удовл. заявок',
            width: '150px',
            type: 'price',
        },
    ]);

export const AMORTISATION_COLUMNS: Array<Column<BondPaymentAmortisationData>> =
    transformColumnsFromFixedWidthToPercentage([
        {
            id: 'redemptionNumber',
            field: 'redemptionNumber',
            label: '№ погашения',
            width: '118px',
            align: 'left',
            className: styles.textRowValue,
            type: 'number',
        },
        {
            id: 'announcementDate',
            field: 'announcementDate',
            type: 'date',
            label: 'Дата объявления погашения',
            width: '192px',
            align: 'right',
        },
        {
            id: 'partialRedemptionDate',
            field: 'partialRedemptionDate',
            type: 'date',
            label: 'Дата погашения части номинала',
            width: '192px',
            align: 'right',
        },
        {
            id: 'paymentDate',
            field: 'paymentDate',
            type: 'date',
            label: 'Дата выплаты части номинала',
            width: '192px',
            align: 'right',
        },
        {
            id: 'listFixationDate',
            field: 'listFixationDate',
            type: 'date',
            label: 'Дата фиксации списка',
            width: '192px',
            align: 'right',
        },
        {
            id: 'redemptionType',
            field: 'redemptionType',
            className: styles.textRowValue,
            label: 'Тип погашения',
            width: '384px',
            type: 'string',
            align: 'left',
        },
        {
            id: 'daysFromIssue',
            field: 'daysFromIssue',
            label: 'Дней с начала размещения',
            width: '200px',
            type: 'number',
            align: 'right',
        },
        {
            id: 'redemptionPercentage',
            field: 'redemptionPercentage',
            label: 'Процент погашения',
            width: '162px',
            type: 'price',
            align: 'right',
        },
        {
            id: 'paymentPerBond',
            field: 'paymentPerBond',
            label: 'Выплата на одну облигацию',
            width: '200px',
            type: 'price',
            align: 'right',
        },
    ]);
