import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const Plus: FC<Props> = ({ width = 24, height = 24, color = 'currentColor' }) => (
    <svg
width={width} height={height} fill={color}
viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.2505 5.75C11.2505 5.33579 11.5863 5 12.0005 5C12.4147 5 12.7505 5.33579
        12.75055.75V18.25C12.7505 18.6642 12.4147 19 12.0005 19C11.5863 19 11.2505
        18.6642 11.2505 18.25V5.75Z"
        />
        <path
            d="M18.25 11.25C18.6642 11.25 19 11.5858 19 12C19 12.4142 18.6642 12.75 18.25
        12.75L5.75 12.75C5.33579 12.75 5 12.4142 5 12C5 11.5858 5.33579 11.25 5.75 11.25L18.25 11.25Z"
        />
    </svg>
);
