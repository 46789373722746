export type Diversification = {
    diversification: number;
};

export type InvestmentProfile = {
    investmentProfile: number;
};

export type Expectations = {
    expectedProfit: number;
    riskPortfolio: number;
};

export type MaxRisk = {
    dateFromMaxRisk: string;
    dateTillMaxRisk: string;
    maxRisk: number;
};

export type PortFolioCost = {
    costPortfolio: number;
    currency: string;
};

export type KeyTools = {
    name: string;
    ticker: string;
    isin: string;
    portfolioShare: number;
    profitability: number;
    instrumentType: string;
};

export type KeyStatistics = {
    name: string;
    quarterToDate: number;
    yearToDate: number;
    oneYear: number;
    fiveYears: number;
    tenYears: number;
};

export enum CoreMetrics {
    STOCKS = 'stocks',
    BONDS = 'bonds',
    FUNDS = 'funds',
    CASH = 'cash',
    ALTERNATIVE = 'alternative'
}

export const coreMetrics = {
    [CoreMetrics.STOCKS]: { name: 'Акции', color: '#2F9CEB' },
    [CoreMetrics.BONDS]: { name: 'Облигации', color: '#F7D762' },
    [CoreMetrics.FUNDS]: { name: 'Фонды', color: '#F2433D' },
    [CoreMetrics.CASH]: { name: 'Денежные средства', color: '#42A62E' },
    [CoreMetrics.ALTERNATIVE]: { name: 'Альтернативные', color: '#A5B1C0' },
};

export type CoreMetricsResponseData = {
    stocks: number;
    bonds: number;
    funds: number;
    cash: number;
    alternative: number;
};
