import { type HeaderGroup } from '@tanstack/react-table';
import { Table } from '@mantine/core';

import { TableFooterCell } from './TableFooterCell';

type Props<T extends Record<string, unknown>> = {
    footerGroup: HeaderGroup<T>;
};

export const TableFooterRow = <TableData extends Record<string, unknown>>({ footerGroup }: Props<TableData>) => (
    <Table.Tr key={footerGroup.id} style={{ borderTop: '1px solid #636E80' }}>
        {footerGroup.headers.map((footer) => (
            <TableFooterCell key={footer.id} footer={footer} />
        ))}
    </Table.Tr>
);
