import { Charts, type PortfolioDynamicsPerformance } from '@modules/Investorpro/modules/PortfolioPage/entities';

const defaultChartColors = {
     [Charts.Портфель]: '#f2433d',
     [Charts.GLDRUB_TOM]: '#2F9CEB',
     [Charts.BRF4]: '#009933',
     [Charts.RUABITR]: '#330099',
     [Charts.MOEX]: '#6333c4',
     [Charts.MOEXBC]: '#FF8000',
};

export const chartColorGenerator = (performances: PortfolioDynamicsPerformance[]): Record<string, string> => (
    performances.reduce((object, value, index) => ({
        ...object,
        [value.name]: index < 6
            ? defaultChartColors[value.name]
            : `#${(parseInt(`0x${defaultChartColors[value.name]
                .slice(1).toUpperCase()}`, 16) + 21248).toString(16)}`,
    }), {})
);
