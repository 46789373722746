import {
    TwoColumnsDataView,
    type TwoColumnsDataViewOption,
} from '@modules/Investorpro/modules/BondStockPage/shared/components/TwoColumnsDataView';
import { LabelWithNavigation } from '@modules/Investorpro/modules/BondStockPage/shared/components/NavigateToTab';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';
import { Table } from '@modules/Investorpro/shared/components/Table';
import { ValueWithMeasure } from '@modules/Investorpro/modules/BondStockPage/shared/components/ValueWithMeasure';
import { HelpLineWrapper } from '@libs/components';

import styles from './styles.module.scss';
import { StockPriceChart } from './components/Chart';
import { News } from './components/News';
import { type StockGeneralInfo } from '../../../../types';
import { FINANCIAL_INFO_DATA_ROWS, INCOME_COLUMNS } from './columns';
import { parsLinkLabel, parseLink } from '../../../../utils/link.utils';
import { StockDescriptionText } from './components/StockDescriptionText';
import { GET_STOCK_DESCRIPTION_INFO_LOADING_KEY } from '../../../../constants';

type Props = {
    handleChangeTab: (tab: number) => void;
};

export const BaseInformationTab = ({ handleChangeTab }: Props) => {
    const navigateToDividents = () => handleChangeTab(1);
    const navigateToNews = () => handleChangeTab(4);
    const navigateToFinancialReport = () => handleChangeTab(2);
    const { generalInfo, financials, datePeriodsProfit } = useSelector((state: RootState) => state.stock.baseInfo);
    const isDescriptionLoading = useSelector((state: RootState) => state.loading.loadingKeys.includes(GET_STOCK_DESCRIPTION_INFO_LOADING_KEY),
    );

    const ABOUT_COMPANY_DATA_ROWS: Array<TwoColumnsDataViewOption<StockGeneralInfo>> = [
        {
            label: 'Капитализация',
            value: 'capitalization',
            valueComponent: (item) => <ValueWithMeasure value={item?.capitalization} measure="RUB" />,
        },
        {
            labelComponent: (
                <LabelWithNavigation handleNavigate={navigateToDividents}>Дивидендная доходность</LabelWithNavigation>
            ),
            type: 'price',
            value: 'dividendYield',
            measure: '%',
        },
        { label: 'Сектор', value: 'sector' },
        { label: 'Год основания', value: 'founded', type: 'date' },
        {
            label: 'Сайт',
            valueComponent: (obj) => parsLinkLabel(generalInfo?.website),
            link: parseLink(generalInfo?.website),
        },
        { label: 'Страна', value: 'country' },
        { label: 'Биржа', valueComponent: () => (isDescriptionLoading ? null : 'Московская биржа') },
        { label: 'ISIN', value: 'isin', fontWeight: 400 },
        { label: 'Ближайшая дата выхода отчета', type: 'date', value: 'earningsReportDate' },
    ];

    return (
        <div className={styles.baseInformationTabWrapper}>
            <HelpLineWrapper message="helpline.concreteStock.aboutTheCompany">
                <div className={styles.infoColumns}>
                    <div className={styles.aboutCompany}>
                        <StockDescriptionText text={generalInfo?.about} />
                        <TwoColumnsDataView
                            className={styles.aboutInfo}
                            data={generalInfo}
                            options={ABOUT_COMPANY_DATA_ROWS}
                        />
                    </div>
                    <div className={styles.financials}>
                        <LabelWithNavigation handleNavigate={navigateToFinancialReport}>
                            <h2>Финансы и аналитика</h2>
                        </LabelWithNavigation>
                        <TwoColumnsDataView
                            data={financials}
                            options={FINANCIAL_INFO_DATA_ROWS}
                            className={styles.aboutInfo}
                        />
                    </div>
                </div>
            </HelpLineWrapper>
            <div className={styles.infoColumns}>
                <div className={styles.chart}>
                    <HelpLineWrapper message="helpline.concreteStock.instrumentChart">
                        <h2>График</h2>
                        <div className={styles.chart_wrapper}>
                            <StockPriceChart />
                        </div>
                    </HelpLineWrapper>
                </div>

                <div className={styles.income}>
                    <HelpLineWrapper message="helpline.concreteStock.dividendsExcludingDividends">
                        <h2>Доходность</h2>
                        <Table columns={INCOME_COLUMNS} data={datePeriodsProfit} />
                    </HelpLineWrapper>
                </div>
                <div className={styles.news}>
                    <LabelWithNavigation handleNavigate={navigateToNews}>
                        <h2>Новости</h2>
                    </LabelWithNavigation>
                    <News />
                </div>
            </div>
        </div>
    );
};
