import { IntervalType } from '@libs/types/instrument.type';
import { addMinutes, endOfDay, format, isToday, subDays } from 'date-fns';

export const getPrevTradeDayDate = () => {
    return subDays(endOfDay(new Date()), 1);
}

export const getTradeDayTime = (date: Date) => {
    const getEndTradeTime = (date: Date) => {
        date.setHours(23, 50, 0, 0)
        const resultDate = addMinutes(date, -(240 + date.getTimezoneOffset()))

        return resultDate
    }

    return isToday(date) ? date : getEndTradeTime(date)
}

export const transformDateFromMoscowToLocal = (date: string | Date) => {
    const moscowDate = new Date(date);

    return addMinutes(moscowDate, -moscowDate.getTimezoneOffset() - 180);
}

export const formatInstrumentTradeDate = (
    date: string | null | undefined,
    interval: IntervalType = IntervalType.ONE_DAY,
) => {
    if (!date) {
        return new Date().toLocaleDateString()
    }

    return [IntervalType.ONE_MINUTE, IntervalType.ONE_HOUR]
                .includes(interval)
                        ? format(new Date(date), 'dd.MM.yyyy HH:mm')
                        : new Date(date).toLocaleDateString();
}
