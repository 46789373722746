import { Tabs as TabsComponent } from '@modules/Investorpro/shared/components/Tabs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WithLoader } from '@libs/components/loaders/ViewLoader';
import { type RootState } from '@store/store';
import { useSelector } from 'react-redux';

import { BaseInformationTab } from './components/BaseInformationTab';
import { DescriptionInformationTab } from './components/DescriptionInformationTab';
import { PaymentsTab } from './components/Payments';
import styles from './styles.module.scss';

export const Tabs = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const [paymentTab, setPaymentTab] = useState('coupons');
    const [descriptionInfoTab, setDescriptionInfoTab] = useState('issiance');
    const { secId } = useParams();

    const { getBondDescriptionInfo } = useSelector((state: RootState) => state.bond.loadings);

    useEffect(() => {
        setCurrentTab(0);
        setDescriptionInfoTab('issiance');
        setPaymentTab('coupons');
    }, [secId]);

    const tabs = {
        'Базовая информация': <BaseInformationTab />,
        'Описательная информация': (
            <WithLoader isLoading={getBondDescriptionInfo} notRenderChildrenOnLoading>
                <DescriptionInformationTab currentTab={descriptionInfoTab} handleTabChange={setDescriptionInfoTab} />
            </WithLoader>
        ),
        Выплаты: <PaymentsTab currentTab={paymentTab} handleTabChange={setPaymentTab} />,
    };

    return (
        <TabsComponent
            tabs={tabs}
            navContentClassName={styles.tabsWrapper}
            current={currentTab}
            handleTabChange={setCurrentTab}
        />
    );
};
