import { createAsyncThunk } from "@reduxjs/toolkit";
import { downloadAvatarRequest } from "@libs/services";
import { readAsDataURL } from "@libs/utils";

const avatarDownloadThunk = createAsyncThunk(
    'user/downloadAvatar',
    async ({ bucket, ticket }: { bucket: string; ticket: number }, { rejectWithValue }) => {
        try {
            const { data } = await downloadAvatarRequest(bucket, ticket);
            const image = await readAsDataURL(new Blob([data]));

            return image.data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export { avatarDownloadThunk };
