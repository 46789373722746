import { ReactComponent as TelegramIcon } from '@shared/images/svg/Telegram.svg';
import { ReactComponent as VkIcon } from '@shared/images/svg/Vk.svg';
import { ReactComponent as Support } from '@shared/images/svg/Support.svg';
import { ReactComponent as FooterLogoIcon } from '@shared/images/svg/FooterLogo.svg';
import classNames from 'classnames';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

type Props = {
    className?: string;
};

export const Footer = forwardRef<HTMLElement, Props>(({ className }, ref) => {
    return (
        <section ref={ref} className={classNames(styles.footerWrapper, 'flex', 'justify-content-center', className)}>
            <div className={styles.footerContent}>
                <div className={styles.footerHeader}>
                    <div className={styles.headerInfo}>
                        <a target={'_blank'} rel="noreferrer" href="https://www.moex.com/s249">
                            <p>Раскрытие информации</p>
                        </a>
                        <a target={'_blank'} rel="noreferrer" href="https://fs.moex.com/f/3499/agreement.pdf">
                            <p>Пользовательское соглашение</p>
                        </a>
                        <a
                            target={'_blank'}
                            rel="noreferrer"
                            href="https://assets.finuslugi.ru/mp-assets/privacy-policy.pdf"
                        >
                            <p>Политика обработки персональных данных</p>
                        </a>
                    </div>
                </div>

                <div className={styles.logoWrapper}>
                    <FooterLogoIcon />
                </div>

                <div className={styles.infoWrapper}>
                    <p>Datashop, 2024 г. — сервис ПАО Московская биржа</p>
                    <p>
                        Все права на информацию и аналитические материалы, размещенные на настоящем сайте Биржи,
                        защищены в соответствии с российским законодательством. Прежде чем приступить к использованию
                        сайта предлагаем ознакомиться с Пользовательским соглашением. Воспроизведение, распространение и
                        иное использование информации, размещенной на сайте Биржи, или ее части допускается только с
                        предварительного письменного согласия Биржи.
                    </p>
                </div>
                <div className={styles.socialWrapper}>
                    <a
                        href="https://t.me/MOEX_DATASHOP"
                        target="_blank"
                        className={classNames(styles.socialWrapper_icon, styles.socialWrapper_icon__centered)}
                        rel="noreferrer"
                    >
                        <TelegramIcon />
                    </a>
                    <a
                        className={styles.socialWrapper_icon}
                        target="_blank"
                        href="https://vk.com/club226048516"
                        rel="noreferrer"
                    >
                        <VkIcon />
                    </a>
                </div>
                <div className={styles.supportWrapper}>
                    <div className={styles.support_icon}>
                        <Support />
                    </div>
                    <div>
                        <p className={styles.support_call}>Поддержка: +7 495 786-38-90</p>
                        <p className={styles.support_schedule}>Будни: с 7:00 до 23:00 мск</p>
                    </div>
                </div>
            </div>
        </section>
    );
});
