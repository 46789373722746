import { ReactComponent as NotActiveIcon } from '@modules/Investorpro/shared/images/svg/Star.svg';
import { ReactComponent as ActiveIcon } from '@modules/Investorpro/shared/images/svg/StarFilled.svg';
import { ReactComponent as HoverNotActiveIcon } from '@modules/Investorpro/shared/images/svg/HoveredStart.svg';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';

type Props = {
    active: boolean;
    parentRef?: React.RefObject<HTMLElement>;
    activeClassName?: string;
    notActiveClassName?: string;
    disabled?: boolean;
    className?: string;
    onClick?: () => void;
    hoverNotActiveClassName?: string;
};

export const FavoriteStar = ({
    active,
    parentRef,
    notActiveClassName,
    activeClassName,
    className,
    disabled,
    onClick,
    hoverNotActiveClassName,
}: Props) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const ref = useRef<HTMLElement>(null);

    const commonIconProps = {
        onClick,
    };
    const clickable = Boolean(onClick);

    useEffect(() => {
        const refForHover = parentRef ?? ref;

        if (refForHover?.current && !disabled) {
            const onMouseEnter = () => setHovered(true);
            const onMouseLeave = () => setHovered(false);

            refForHover.current.addEventListener('mouseenter', onMouseEnter);
            refForHover.current.addEventListener('mouseleave', onMouseLeave);

            return () => {
                refForHover.current?.removeEventListener('mouseenter', onMouseEnter);
                refForHover.current?.removeEventListener('mouseleave', onMouseLeave);
            };
        }
    }, [parentRef, ref, disabled]);

    const renderStar = () => {
        if (active) {
            return <ActiveIcon className={classNames(styles.star__active, activeClassName)} {...commonIconProps} />;
        }

        if (hovered) {
            return (
                <HoverNotActiveIcon
                    className={classNames(styles.star__hovered, hoverNotActiveClassName)}
                    {...commonIconProps}
                />
            );
        }

        return (
            <NotActiveIcon className={classNames(styles.star__notActive, notActiveClassName)} {...commonIconProps} />
        );
    };

    return (
        <span
            ref={ref}
            className={classNames(
                className,
                'flex',
                'align-items-center',
                'justify-content-center',
                clickable && 'cursor-pointer',
            )}
        >
            {renderStar()}
        </span>
    );
};
