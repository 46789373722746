import { Table } from '@mantine/core';
import { type Header, flexRender } from '@tanstack/react-table';

type Props<T extends Record<string, unknown>> = {
    footer: Header<T, unknown>;
};

export const TableFooterCell = <TableData extends Record<string, unknown>>({ footer }: Props<TableData>) => (
    <Table.Td key={footer.id}>{flexRender(footer.column.columnDef.footer, footer.getContext())}</Table.Td>
);
