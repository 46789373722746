import { useRef } from 'react';
import { type ProfitPeriod } from '@libs/types/instrument.type';

import {
    type InstrumentCategory,
    type InstrumentType,
    type Option,
    type InstrumentStoreType,
    type HeatMapType,
} from '../types';
import { getInstrumentStoreCode, getLoadingStatus } from '.';

export const useGetDataFromStore = (
    key: HeatMapType,
    store: InstrumentStoreType,
    instrumentType: Option<InstrumentType>,
    instrumentCategory: Option<InstrumentCategory> | null,
    profitPeriod: ProfitPeriod,
) => {
    const prevCodeRef = useRef<string | null>(null);
    const loading = getLoadingStatus(store, key, profitPeriod, instrumentType.code, instrumentCategory?.code);

    const getData = () => {
        if (instrumentCategory) {
            const code = getInstrumentStoreCode(instrumentType.code, instrumentCategory.code);
            const data = store[code]?.data ?? [];
            const isProfitLoaded = store[code]?.profitLoaded.includes(profitPeriod);

            if (data.length > 0 && isProfitLoaded) {
                prevCodeRef.current = code;

                return data;
            }

            if (prevCodeRef.current && loading) {
                return store[prevCodeRef.current]?.data ?? [];
            }

            return [];
        }

        if (prevCodeRef.current && loading) {
            return store[prevCodeRef.current]?.data ?? [];
        }

        return [];
    };

    return {
        data: getData(),
        loading,
    };
};
