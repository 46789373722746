import { Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import { DatePickerInput, type DateValue } from '@mantine/dates';
import { useLocalStorage } from '@mantine/hooks';
import { type FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PortfolioFilterKeys } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { Calendar } from '../../../shared/Icon';
import { ViewEmptyStub, ViewError, ViewLoader } from '../../../shared/Layout';
import { type InferParams } from '../../../shared/router';
import { AssetsBehavior } from './AssetBehavior/AssetsBehavior';
import { useGetAssetsBehaviorQuery } from './AssetBehavior/useGetAssetsBehaviorQuery';
import { RiskProfileScale } from './RiskProfileScale/RiskProfileScale';
import { ScenarioIndicatorTable } from './ScenarioIndicatorTable/ScenarioIndicatorTable';
import { SimulationChart } from './SimulationChart/SimulationChart';
import { StressScenarioList } from './StressScripts/StressScenarioList';
import { useGetRiskProfileQuery } from './useGetRiskProfileQuery';
import { useGetScenarioIndicatorQuery } from './useGetScenarioIndicatorQuery';
import { useGetScenariosQuery } from './useGetScenariosQuery';
import { useGetSimulationResultQuery } from './useGetSimulationResultQuery';

export const StressTests: FC = () => {
    const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;
    const [dates, setDates] = useState<[DateValue, DateValue]>([null, null]);
    const { data, isSuccess, isPending, isError: scenarioError } = useGetScenariosQuery(portfolioId);
    const [scenarioUuid, setScenarioUuid] = useLocalStorage<string>({
        key: PortfolioFilterKeys.STRESS_TESTS_FILTER,
        defaultValue: data?.content[0]?.uuid,
    });
    const { data: simulationResult, error: simulationResultError } = useGetSimulationResultQuery(
        portfolioId,
        scenarioUuid,
    );
    const { data: assetsBehavior, error: assetsBehaviorError } = useGetAssetsBehaviorQuery(portfolioId, scenarioUuid);
    const { data: riskProfile, error: riskProfileError } = useGetRiskProfileQuery(portfolioId, scenarioUuid);

    useEffect(() => {
        if (isSuccess && data.content[0]) {
            setScenarioUuid(data.content[0].uuid);
            setDates([new Date(data.content[0].dateFrom), new Date(data.content[0].dateTill)]);
        }
    }, [data, isSuccess, setScenarioUuid]);

    const handleCardClick = (uuid: string, dateFrom: string, dateTill: string) => {
        setScenarioUuid(uuid);
        setDates([new Date(dateFrom || ''), new Date(dateTill || '')]);
    };

    const {
        data: scenarioIndicator,
        isError,
        error,
        isRefetching,
        isLoading,
    } = useGetScenarioIndicatorQuery(portfolioId, scenarioUuid);

    if (isLoading || isRefetching) {
        return (
            <Group grow={true}>
                <Stack>
                    <Text fz={19} fw={400}>
                        {'Сценарии тестирования'}
                    </Text>
                    <ViewLoader />
                </Stack>
                <Stack>
                    <Text fz={19} fw={400} c="#C1CCD8">
                        {'Результат симуляции сценария'}
                    </Text>
                    <ViewLoader />
                </Stack>
                <Stack>
                    <Text fz={19} fw={400} c="#C1CCD8">
                        {'Показатели сценария'}
                    </Text>
                    <ViewLoader />
                </Stack>
            </Group>
        );
    }

    if (scenarioError) {
        return (
            <Stack>
                <Text fz={19} fw={400}>
                    {'Сценарии стресс-тестирования'}
                </Text>
                <ViewError error={error} subtitle="Ошибка получения данных по сценариям тестирования" />
            </Stack>
        );
    }

    if (data && data.content.length === 0) {
        return (
            <Group grow={true}>
                <Stack>
                    <Text fz={19} fw={400}>
                        {'Сценарии стресс-тестирования'}
                    </Text>
                    <ViewEmptyStub title={'Недостаточно данных'} />
                </Stack>
                <Stack>
                    <Text fz={19} fw={400} c="#C1CCD8">
                        {'Результат симуляции сценария'}
                    </Text>
                    <ViewEmptyStub title={'Недостаточно данных'} />
                </Stack>
                <Stack>
                    <Text fz={19} fw={400} c="#C1CCD8">
                        {'Показатели сценария'}
                    </Text>
                    <ViewEmptyStub title={'Недостаточно данных'} />
                </Stack>
            </Group>
        );
    }

    return (
        <Group
wrap="nowrap" gap={60} align="start"
pos="relative">
            <LoadingOverlay visible={isPending} zIndex={1} />
            <StressScenarioList
                handleCardClick={handleCardClick}
                scenarioUuid={scenarioUuid}
                scenarios={data?.content ?? []}
            />
            <Stack>
                <Group justify="space-between">
                    <Text fz={19} fw={400}>
                        {'Результат симуляции сценария'}
                    </Text>
                    <DatePickerInput
                        disabled={true}
                        locale="ru"
                        w={220}
                        radius={6}
                        rightSection={<Calendar color="#A5B1C0" width={24} height={24} />}
                        valueFormat="DD.MM.YYYY"
                        type="range"
                        placeholder="Выберите период"
                        value={dates}
                        styles={{
                            input: { padding: '6px 18px 6px 12px' },
                        }}
                        readOnly={true}
                    />
                </Group>
                <Stack>
                    <SimulationChart data={simulationResult?.performance ?? []} error={simulationResultError} />
                    <Group wrap="nowrap" align="start" gap={48}>
                        <AssetsBehavior assetsBehavior={assetsBehavior ?? []} error={assetsBehaviorError} />
                        <RiskProfileScale riskProfile={riskProfile?.value ?? 0} error={riskProfileError} />
                    </Group>
                </Stack>
            </Stack>
            <ScenarioIndicatorTable
                dates={dates}
                scenarioIndicator={scenarioIndicator ?? []}
                error={error}
                isError={isError}
                isRefetching={isRefetching}
                isLoading={isLoading}
            />
        </Group>
    );
};
