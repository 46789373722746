import { Stack, Text } from '@mantine/core';
import { type FC } from 'react';

import { type InstrumentLiquidity } from '../../../entities';
import { Table } from '../../../shared/Table';
import { useInstrumentsLiquidityColumns } from './useInstrumentsLiquidityColumns';
import { ViewLoader } from '../../../shared/Layout';

type Props = {
    data: InstrumentLiquidity[];
    isPending?: boolean;
    isInReport?: boolean;
};

export const InstrumentsLiquidityTable: FC<Props> = ({ data, isInReport, isPending }) => {
    const columns = useInstrumentsLiquidityColumns();

    if (isPending) {
        return (
            <Stack gap={25} miw={500} mih={200}>
                <Text fz={19} fw={400}>
                    {'Ликвидность инструментов в портфеле'}
                </Text>
                <ViewLoader />
            </Stack>
        );
    }

    return (
        <Stack gap={25}>
            {!isInReport
? (
                <Text fz={19} lh="22px" fw={400}>
                    {'Ликвидность инструментов в портфеле'}
                </Text>
            )
: null}
            <Table
columns={columns} data={data} striped={true}
highlightOnHover={true} />
        </Stack>
    );
};
