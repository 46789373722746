import { type PortfolioByIdResponseData } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const getPortfolioById: ApiFactory<
    ApiEndpoint<PortfolioByIdResponseData, void, { id?: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<PortfolioByIdResponseData>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/portfolio/${params.id}`,
        { signal },
    );

    return data;
};
