import { type KeyTools, type PageableResponse } from '../../../../../PortfolioPage/entities';
import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getPortfolioInstruments: ApiFactory<
    ApiEndpoint<PageableResponse<KeyTools[]>, void, { sort: string } & { id: string }>
> = (client) => async ({ signal, params }) => {
    const { data } = await client.get<PageableResponse<KeyTools[]>>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/common-data/portfolio-instruments/${params.id}`,
        { signal, params },
    );

    return data;
};
