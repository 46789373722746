import { IntervalType, PeriodId } from '@libs/types/instrument.type';
import { getStartOfMOEXDate } from '@libs/utils';
import { addDays, differenceInDays } from 'date-fns';


type RefetchSecurityDataToFindTradeDayArgs<T, R> = {
    responseDataAccessor: (resp: R) => T[];
    fetchData: (start: string, end: string, tryNumber: number) => Promise<R>;
    start: Date;
    end?: Date;
    data?: T[];
    condition?: (data: T[]) => boolean;
    format?: (date: Date) => string;
    step?: number | number[] | ((date: Date) => Date);
    maxTries?: number;
    extraEffect?: (start: Date, end: Date) => void;
}

export const refetchDataUntilFindNotEmpty = async<T, R>({
    responseDataAccessor,
    fetchData,
    start,
    step = 1,
    data = [],
    end = new Date(),
    condition,
    format,
    maxTries = 7,
    extraEffect,
}: RefetchSecurityDataToFindTradeDayArgs<T, R>): Promise<[T[], Date, Date, R | null]> => {
    let startDate = start;
    let endDate = end
    let fetchedData = data
    let response: R | null = null

    if (fetchedData.length > 0) {
        return [fetchedData, startDate, endDate, response];
    } else {
        const totalTries = Array.isArray(step) ? step.length : maxTries;
        let tries = totalTries

        while ((condition ? !condition(fetchedData) : fetchedData.length === 0) && tries > 0) {
            const res = await fetchData(
                format ? format(startDate) : startDate.toJSON(),
                format ? format(endDate) : endDate.toJSON(),
                totalTries - tries,
            );
            response = res;
            fetchedData = responseDataAccessor(res);

            if (condition ? !condition(fetchedData) : fetchedData.length === 0) {
                const currentStep = Array.isArray(step) ? step[totalTries - tries] : step;
                startDate = typeof currentStep === 'function'
                    ? currentStep(startDate)
                    : addDays(startDate, -currentStep);

                endDate = typeof currentStep === 'function' ? currentStep(endDate) : addDays(endDate, -currentStep)
            }

            tries--;
        }

        if (tries === 0) {
            console.warn('No nearest trade day');
        }

        extraEffect?.(startDate, endDate);

        return [fetchedData, startDate, endDate, response];
    }
}

export const getArrowPos = (countOfDays: number, intervalType: IntervalType) => {
    const maxCount: Record<IntervalType, number> = {
        [IntervalType.ONE_DAY]: countOfDays,
        [IntervalType.ONE_HOUR]: countOfDays * 24,
        [IntervalType.ONE_MINUTE]: countOfDays * 24 * 60,
    }
    const countOfBunches = Math.floor(maxCount[intervalType] / 500);

    return [
        '0',
        ...[...new Array(countOfBunches).fill(500)]
            .map((bunch, index) => bunch * (index + 1))
            .map(value => value.toString()),
    ]
}

export const getArrowPosByPeriodId = (period: PeriodId, interval: IntervalType) => {
    const countOfDaysForPeriod: Partial<Record<PeriodId, number>> = {
        [PeriodId.ONE_DAY]: 1,
        [PeriodId.FIVE_DAYS]: 5,
        [PeriodId.ONE_MONTH]: 30,
        [PeriodId.THREE_MONTHS]: 90,
        [PeriodId.SIX_MONTHS]: 180,
        [PeriodId.ONE_YEAR]: 360,
        [PeriodId.SNG]: 360,
        [PeriodId.FIVE_YEARS]: 1800,
        [PeriodId.THREE_YEARS]: 1080,
        [PeriodId.TEN_YEARS]: 3600,
        [PeriodId.ALL]: differenceInDays(new Date(), getStartOfMOEXDate()),
    };

    return getArrowPos(countOfDaysForPeriod[period] ?? 1, interval);
}

export const getArrowPosByDatePeriod = (interval: IntervalType, from: string | Date, till?: string | Date) => {
    return getArrowPos(
        Math.abs(differenceInDays(new Date(from), till ? new Date(till) : new Date())),
        interval,
    );
}
