import { Text } from '@mantine/core';
import { type FC } from 'react';
import {
    Bar,
    type BarProps,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';

import { type CashFlowType } from '../../../../entities';
import { ViewLoader } from '../../../../shared/Layout';
import { CustomBar } from './CustomBar';
import { CustomLegendContent } from './CustomLegendContent';

type Props = {
    data: CashFlowType[];
    isInReport?: boolean;
    isPending?: boolean;
    cashFlowType: string;
};

export const Chart: FC<Props> = ({ data, isInReport, isPending, cashFlowType }) => {
    if (isPending) {
        return <ViewLoader />;
    }

    return (
        <ResponsiveContainer height="100%" width="100%">
            <ComposedChart data={data} margin={{ top: 0, bottom: 0 }}>
                <CartesianGrid
horizontal={true} vertical={false} stroke="#D8E1EB"
opacity={0.6} />
                <XAxis
                    dataKey="year"
                    interval="preserveEnd"
                    reversed={true}
                    tickMargin={10}
                    tickSize={0}
                    style={{
                        fontSize: 11,
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fill: '#8A96A6',
                        lineHeight: '20px',
                    }}
                />
                <YAxis
                    yAxisId="right"
                    type="number"
                    allowDataOverflow={true}
                    dataKey="cashFlow"
                    tickMargin={12}
                    tickSize={0}
                    axisLine={false}
                    orientation="right"
                    style={{
                        fontSize: 11,
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        textAnchor: 'start',
                        fill: '#8A96A6',
                    }}
                />
                <YAxis
                    unit="%"
                    yAxisId="left"
                    dataKey="annualYield"
                    tickMargin={12}
                    tickSize={0}
                    axisLine={false}
                    type="number"
                    orientation="left"
                    style={{
                        fontSize: 11,
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        textAnchor: 'end',
                        fill: '#8A96A6',
                    }}
                />

                <Legend
                    verticalAlign="top"
                    content={(props) => <CustomLegendContent props={props} />}
                    wrapperStyle={{ marginTop: '-20px' }}
                    payload={[
                        {
                            value: 'Годовая доходность',
                            id: 'annualYield',
                            color: '#F2433D',
                        },
                        {
                            value: cashFlowType === 'ANNUAL' ? 'Денежный поток' : 'Накопленный денежный поток',
                            id: 'cashFlow',
                            color: '#BFE4FF',
                        },
                    ]}
                    formatter={(value) => (
                        <Text
display="inline-block" mr={30} lh="16px"
fw={400} fz={14} style={{ color: '#8A96A6' }}>
                            {value}
                        </Text>
                    )}
                />
                <Bar
                    shape={(props: BarProps) => <CustomBar props={props} />}
                    yAxisId="right"
                    dataKey="cashFlow"
                    barSize={48}
                    isAnimationActive={!isInReport}
                />
                <Line
                    yAxisId="left"
                    dot={false}
                    type="linear"
                    dataKey="annualYield"
                    stroke="#F2433D"
                    isAnimationActive={!isInReport}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
};
