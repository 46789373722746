import { useRef, useState } from 'react';
import { type Column, Table } from '@modules/Investorpro/shared/components/Table';
import classNames from 'classnames';
import { useModal } from '@libs/utils';
import { useAppDispatch } from '@store/store';
import { deleteAlertThunk } from '@store/store/thunk/investorpro/alerts.thunk';
import { type DataTableRowClickEvent } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { SearchInput } from '@modules/Investorpro/shared/components/Search/SearchInput';
import { PlusIcon } from 'primereact/icons/plus';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ReactComponent as CheckIcon } from '@modules/Investorpro/shared/images/svg/CheckedGrey.svg';

import { ReactComponent as PencilIcon } from '../../../../../images/svg/Pencil.svg';
import { ReactComponent as TrashIcon } from '../../../../../images/svg/Bin.svg';
import { ReactComponent as KebabIcon } from '../../../../../images/svg/favoritesSidebar/kebab.svg';
import { type Alert } from '../../../services/types';
import styles from './styles.module.scss';
import { CreateAlertModal } from '../../../components/overlay/CreateAlertModal';
import { EditAlertModal } from '../../../components/overlay/EditAlertModal';

type Props = {
    columns: Array<Column<Alert>>;
    alerts: Alert[];
    className?: string;
    withAddAlertButton?: boolean;
    readAlert?: (alert: Alert) => void;
};

export const TabWithSearch = ({ columns, alerts, readAlert, className, withAddAlertButton }: Props) => {
    const [searchValue, setSearchValue] = useState('');
    const searchValueRegex = new RegExp(searchValue, 'i');
    const [editedAlert, setEditedAlert] = useState<Alert | null>(null);
    const dispatch = useAppDispatch();

    const visibleAlerts = alerts.filter(({ shortName, secId }) => {
        return searchValueRegex.test(shortName) || searchValueRegex.test(secId);
    });
    const {
        isOpen: isCreateAlertModalOpen,
        openModal: openCreateAlertModal,
        closeModal: closeCreateAlertModal,
    } = useModal();

    const closeEditAlertModal = () => setEditedAlert(null);
    const isEditAlertModalOpen = editedAlert !== null;

    const deleteAlert = async (alert: Alert) => {
        dispatch(showLoader());
        await dispatch(deleteAlertThunk(alert.id));
        dispatch(hideLoader());
    };

    const refs = useRef<any>({});
    const toggleOverlay = (event: any, rowIndex: number) => {
        if (refs.current[rowIndex]) {
            refs.current[rowIndex].toggle(event);
        }
    };

    const handleReadAlertByRowClick = (e: DataTableRowClickEvent) => {
        if (e?.data && readAlert) {
            readAlert(e.data as Alert);
        }
    };

    const lastColumnWithMenu: Column<Alert> = {
        id: 'menu',
        label: '',
        width: '',
        render: (alert, { rowIndex }) => (
            <>
                <Button
                    icon={<KebabIcon />}
                    className={styles.actionWrapper}
                    onClick={(e) => toggleOverlay(e, rowIndex)}
                />
                <OverlayPanel
                    onClick={() => {
                        refs.current[rowIndex].hide();
                    }}
                    dismissable={true}
                    ref={(el) => (refs.current[rowIndex] = el)}
                    className={styles.overlayPanelWrapper}
                >
                    {withAddAlertButton && (
                        <Button
                            onClick={() => {
                                setEditedAlert(alert);
                            }}
                            text
                            className={styles.overlayPanelWrapper_menuItem}
                            label="Редактировать"
                            icon={<PencilIcon />}
                        />
                    )}
                    {readAlert && !alert.isRead && (
                        <Button
                            onClick={() => {
                                readAlert(alert);
                            }}
                            text
                            className={styles.overlayPanelWrapper_menuItem}
                            label="Прочитать"
                            icon={<CheckIcon className={styles.readAlertIcon} />}
                        />
                    )}
                    <Button
                        onClick={() => {
                            deleteAlert(alert);
                        }}
                        text
                        className={styles.overlayPanelWrapper_menuItem}
                        label="Удалить"
                        icon={<TrashIcon />}
                    />
                </OverlayPanel>
            </>
        ),
    };

    const handleClear = () => setSearchValue('');

    return (
        <div className={classNames(styles.tabWrapper, 'flex', 'flex-column', className)}>
            <div className={classNames(styles.searchBlock, 'flex')}>
                <SearchInput
                    value={searchValue}
                    handleClear={handleClear}
                    handleChange={setSearchValue}
                    placeholder="Поиск по названию или тикеру"
                />
                {withAddAlertButton && (
                    <Button className={styles.addAlertButton} icon={<PlusIcon />} onClick={openCreateAlertModal} />
                )}
            </div>
            <Table
                className={styles.alertsTable}
                data={visibleAlerts}
                columns={[...columns, lastColumnWithMenu]}
                onRowClick={handleReadAlertByRowClick}
                scrollable
            />
            <CreateAlertModal isOpen={isCreateAlertModalOpen} closeModal={closeCreateAlertModal} />
            {editedAlert && (
                <EditAlertModal isOpen={isEditAlertModalOpen} closeModal={closeEditAlertModal} alert={editedAlert} />
            )}
        </div>
    );
};
