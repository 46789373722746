import { format } from 'date-fns';
import { getCountOfZerosAfterDotAndBeforeNumber, isValueExist } from '@libs/utils';

import { NONE_SYMBOL, DEFAULT_NUMBER_DIVIDER } from '../constants';

export const formatPrice = (value: string | number | null | undefined, divider = DEFAULT_NUMBER_DIVIDER): string => {
    if (!isValueExist(value)) {
        return NONE_SYMBOL;
    }

    const cost = Number(value);

    if (Number.isNaN(cost)) {
        return NONE_SYMBOL;
    }

    const precision = getCountOfZerosAfterDotAndBeforeNumber(cost);

    const formattedNumber = cost.toLocaleString(
        'ru-RU',
        { minimumFractionDigits: 2, maximumFractionDigits: precision >= 2 ? precision + 2 : 2 },
    )

    return divider !== ',' ? formattedNumber.replace(',', divider) : formattedNumber;
}

export const formatTextBoolean = (value: boolean | null | undefined): string => {
    if (value === null || value === undefined) {
        return NONE_SYMBOL;
    }

    if (typeof value === 'boolean') {
        return value ? 'Да' : 'Нет';
    }

    return value
}


export const formatDate = (date: Date | string | null | undefined): string => {
    try {
        if (date === null || date === undefined) {
            return NONE_SYMBOL;
        }

        return format(new Date(date), 'dd.MM.yyyy')
    } catch (e) {
        return NONE_SYMBOL;
    }
}

export const formatTime = (date: Date | string | null | undefined): string => {
    try {
        if (date === null || date === undefined) {
            return NONE_SYMBOL;
        }

        return format(new Date(date), 'HH:mm')
    } catch (e) {
        return NONE_SYMBOL;
    }
}

export const formatNullableValue = <T>(value: T, emptyValue = NONE_SYMBOL as T): T => {
    if (value === null || value === undefined) {
        return emptyValue;
    }

    return value
}
