import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const Eye: FC<Props> = ({ width = 20, height = 20, color = 'currentColor' }) => (
    <svg
xmlns="http://www.w3.org/2000/svg" width={width} height={height}
viewBox="0 0 24 24" fill="none">
        <g>
            <circle
cx="12" cy="12" r="3.25"
stroke={color} strokeWidth="1.5" />
            <path
                d="M21.145 12C20.7664 12.6575 19.8258 14.1425 18.3662 15.502C16.7853 16.9746 14.6519 18.25 12
                18.25C9.34809 18.25 7.21467 16.9746 5.63376 15.502C4.17421 14.1425 3.23357 12.6575 2.855
                12C3.23357 11.3425 4.17421 9.85751 5.63376 8.49799C7.21467 7.02543 9.34809 5.75 12
                5.75C14.6519 5.75 16.7853 7.02543 18.3662 8.49799C19.8258 9.85751 20.7664 11.3425 21.145 12Z"
                stroke={color}
                strokeWidth="1.5"
            />
        </g>
    </svg>
);
