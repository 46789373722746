/* eslint-disable no-useless-escape */
import { decode, encode } from 'js-base64';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';
import { store } from '@store/store';
import _ from 'lodash';
import { addMinutes, format } from 'date-fns';
import { color as colorFn } from 'chart.js/helpers'
import { type TPrimitiveRecord } from 'react-oauth2-code-pkce/dist/Types';
import { SCROLLBAR_WIDTH_CSS_VARIABLE, DEFAULT_NUMBER_DIVIDER } from '@modules/Investorpro/shared/constants';
import { formatPrice } from '@modules/Investorpro/shared/utils/format.util';

import { type ChartFillGradient, type TooltipContext } from '../types';

export const DATE_FORMAT = 'yyyy-MM-dd';

export const INPUT_DATE_FORMAT = 'dd.mm.yy';

export const getNumberWithSpacing = (num: number | undefined | null): string => {
    if (!isValueExist(num)) {
        return '';
    };

    const [before, after] = num.toString().split('.');
    const afterSymbols = after ? `${DEFAULT_NUMBER_DIVIDER}${after}` : ''

    return before.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + afterSymbols;
};

export const objectToBase64String = (obj: unknown) => encode(JSON.stringify(obj));

export const base64StringToObject = (base64str: string) => JSON.parse(decode(base64str));

export const readAsDataURL = async (file: File | Blob): Promise<{ data: string | ArrayBuffer | null }> => {
    return await new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.onload = function () {
            resolve({ data: fileReader.result });
        };
        fileReader.readAsDataURL(file);
    });
};

export const withLoading = async (serviceRequest: () => Promise<any>) => {
    store.dispatch(showLoader());
    try {
        const res = await serviceRequest();
        store.dispatch(hideLoader());

        return res;
    } catch (e) {
        console.error(e);
        store.dispatch(hideLoader());
    }
};

export const generateRandomString = (length: number) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()=+-';
    const charactersLength = characters.length;
    let result = '';

    const randomValues = new Uint32Array(length);

    // Generate random values
    crypto.getRandomValues(randomValues);
    randomValues.forEach((value) => {
        result += characters.charAt(value % charactersLength);
    });

    return result;
};

export const getUuid = () => Date.now().toString(36) + Math.random().toString(36).substring(2, 10);

export const generateState = (additionalParameters: TPrimitiveRecord = {}): string => {
    const stateObj = JSON.stringify({
        simple: 'true',
        authState: getUuid(),
        browserId: localStorage?.getItem('browserId') || window.Cookies?.get('browserId') || window.browserIdValue,
        ...additionalParameters,
    });

    return encode(stateObj);
};

export const isMobileDevice = () => {
    const isMobile =
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            navigator.userAgent,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            navigator.userAgent.substr(0, 4),
        );

    return isMobile;
};

export const validateEmail = (email: string): boolean => {
    const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    return re.test(String(email).toLowerCase());
};

export const scrollElementByRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth',
        });
    }
};

export const roundArrToTenths = (arr: string[] | number[]) => {
    return arr.map((item: string | number) => _.round(Number(item), 2));
};

export const transformDateToServerFormat = (date: Date | null) => {
    if (!date) return date;

    const today = new Date();
    const isEndCurrentDate = date.toDateString() === today.toDateString();
    const dateWithOffset = isEndCurrentDate ? addMinutes(today, today.getTimezoneOffset() + 240) : date;

    return format(dateWithOffset, DATE_FORMAT);
};

export const isNumberValue = (value: any): boolean => {
    return typeof value === 'number';
}


export const getGradient = ({
    reversed = false,
    color,
    colorsSteps,
    opacitySteps: customOpacitySteps,
}: ChartFillGradient) => (context: Pick<TooltipContext<'line'>, 'chart'>) => {
    if (!context) return;

    const { chart } = context;

    if (!chart) return;

    const { ctx, chartArea } = chart;

    if (!chartArea || !color || !ctx) {
        return;
    }

    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

    if (colorsSteps) {
        for (const step in colorsSteps) {
            gradient.addColorStop(+step, colorsSteps[step]);
        }

        return gradient
    }

    const opacitySteps = customOpacitySteps ?? { 1: 0.4, 0.5: 0.2, 0: 0 }
    const gradientSteps = Object.keys(opacitySteps).map(key => +key).sort((a, b) => a - b)
    const isReversed = reversed && !customOpacitySteps

    for (let i = 0; i < gradientSteps.length; i++) {
        const step = isReversed ? gradientSteps[gradientSteps.length - 1 - i] : gradientSteps[i]
        const stepColor = colorFn(color).alpha(opacitySteps[gradientSteps[i]]).rgbString();
        gradient.addColorStop(step, stepColor);
    }

    return gradient;
};

export const compactNumber = (
    num: number | undefined | null,
    roundStart: 'thousands' | 'millions' | 'billions' | 'none' = 'thousands',
): [string, string] => {
    if (!isValueExist(num)) {
        return ['', ''];
    }

    if (num >= 1_000_000_000 && roundStart !== 'none') {
        return [formatPrice(num / 1_000_000_000), 'млрд'];
    }

    if (num >= 1_000_000 && roundStart !== 'billions' && roundStart !== 'none') {
        return [formatPrice((num / 1_000_000)), 'млн'];
    }

    if (num >= 1_000 && roundStart !== 'millions' && roundStart !== 'billions' && roundStart !== 'none') {
        return [formatPrice(num / 1_000), 'тыс'];
    }

    return [formatPrice(num), ''];
}

export const deepClone = (value: any): any => {
    if (Array.isArray(value)) {
        return value.map(deepClone);
    }

    if (![null, undefined].includes(value) && typeof value === 'object') {
        return Object.keys(value).reduce((acc: Record<string, any>, key: string) => {
            acc[key] = deepClone(value[key]);

            return acc;
        }, {});
    }

    return value;
}

export const getCurrentDateUTC = (date: Date) => {
    return format(date, 'yyyy-MM-dd')
}

export const formatDateToQuery = (date: Date | string) => {
    return date instanceof Date ? format(date, 'yyyy-MM-dd') : date
}

export const isValueExist = (num: number | undefined | null | string | boolean):
num is number | string | boolean => {
    return num !== undefined && num !== null;
}

export const capitalize = <T extends string | null | undefined>(str: T) => {
    try {
        if (!str) return str;

        return str.charAt(0).toUpperCase() + str.slice(1);
    } catch (e) {
        return str;
    }
}

export const transformArrayToObject = <T>(data: T[], key: keyof T): Record<string, T> => {
    return data.reduce<Record<string, T>>((acc, item) => {
        acc[item[key] as string] = item;

        return acc;
    }, {});
}

export const getCountOfZerosAfterDotAndBeforeNumber = (num: number): number => {
    if (Math.abs(num) >= 1 || num === 0) return 0;

    let precision = 0;
    let priceNum = Math.abs(num);

    while (priceNum < 1) {
        priceNum *= 10;
        precision++;
    }


    return Math.max(precision - 1, 0);
}

export const getCurrentVerticalScrollbarWidth = () => window.innerWidth - document.body.clientWidth;

export const getScrollbarWidth = () => {
    const element = document.createElement('div');
    element.style.visibility = 'hidden';
    element.style.height = `${window.innerHeight * 1.02}px`;
    document.body.appendChild(element);

    const scrollbarWidth = getCurrentVerticalScrollbarWidth();
    document.body.removeChild(element);

    return scrollbarWidth;
}

export const getObjectKeys = <T extends object>(obj: T): Array<keyof T> => Object.keys(obj) as Array<keyof T>;

export const hasDocumentHorizontalScroll = () => document.documentElement.scrollWidth > window.innerWidth - getCurrentVerticalScrollbarWidth()

export const hasDocumentVerticalScroll = () => document.documentElement.scrollHeight > window.innerHeight

export const getScrollbarWidthFromCSSVariable = () => {
    const systemScrollbarWidth = Number(document.documentElement.style
        .getPropertyValue(SCROLLBAR_WIDTH_CSS_VARIABLE)
        .split('px')[0])

    return Number.isNaN(systemScrollbarWidth) ? 0 : systemScrollbarWidth
}
