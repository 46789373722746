import classNames from 'classnames';
import { type ReactNode } from 'react';
import { type Nullable } from 'primereact/ts-helpers';
import { getNumberWithSpacing, isValueExist } from '@libs/utils';
import { transformDateFromMoscowToLocal } from '@modules/Investorpro/modules/MarketPages/shared/utils/date.utils';
import { NONE_SYMBOL } from '@modules/Investorpro/shared/constants';
import { formatDate, formatTime } from '@modules/Investorpro/shared/utils/format.util';

import styles from './styles.module.scss';
import { PositiveNegativeValue } from '../../../../../shared/components/PositiveNegativeValue';
import { formatInstrumentPrice, roundInstrumentPrice } from '../../utils';
import { AddFavouriteButton } from '../AddFavouriteButton';

type Column = {
    title: string;
    value: number | string | ReactNode;
    className?: string;
};

type Props = {
    title: Nullable<string>;
    logo: Nullable<string>;
    underTitle: ReactNode;
    currentValue: Nullable<number>;
    currentValueMeasure: string;
    changedAt: Nullable<string>;
    changedAbsolute: Nullable<number>;
    changedRelative: Nullable<number>;
    showChangedRelativeMeasure?: boolean;
    columns: Column[];
    addToFavoritesCode?: string | null;
};

export const StockBondHeaderWrapper = ({
    title,
    logo,
    addToFavoritesCode,
    underTitle,
    currentValue,
    currentValueMeasure,
    changedAt,
    changedAbsolute,
    changedRelative,
    showChangedRelativeMeasure = false,
    columns,
}: Props) => {
    const date = changedAt ? transformDateFromMoscowToLocal(changedAt) : null;

    const formattedChangedDate = date ? formatDate(date) : null;
    const formattedChangedTime = date ? formatTime(date) : null;

    return (
        <div className={classNames(styles.headerWrapper)}>
            {logo
? (
                <img src={logo} />
            )
: (
                <div className={classNames('flex', 'align-items-center', 'justify-content-center', styles.imgStub)}>
                    ?
                </div>
            )}
            <div className={classNames('flex', 'flex-column', styles.titleBlock)}>
                <h1>{title}</h1>
                <div className={classNames('flex', 'align-items-center')}>{underTitle}</div>
            </div>
            <div className={styles.columns}>
                <div className={classNames('flex', styles.currentValueBlock)}>
                    <div className={styles.currentValue}>
                        {formatInstrumentPrice(currentValue)}
                        {currentValue && currentValueMeasure && <span>{currentValueMeasure}</span>}
                    </div>
                    <div className={classNames('flex', styles.changesBlock, 'flex-column')}>
                        <span>
                            Обновлено&nbsp;{formattedChangedDate}&nbsp;в&nbsp;{formattedChangedTime}
                        </span>
                        <div className={classNames(styles.changedRelativeBlock, 'flex')}>
                            <PositiveNegativeValue
                                value={
                                    isValueExist(changedAbsolute) ? roundInstrumentPrice(changedAbsolute ?? 0) : null
                                }
                                className={classNames(styles.changedRelative)}
                                positivePrefix={'+'}
                                formatter={getNumberWithSpacing}
                                measureClassName={styles.measure}
                                measure={showChangedRelativeMeasure ? currentValueMeasure : undefined}
                            />
                            <PositiveNegativeValue
                                value={changedRelative}
                                positivePrefix={'+'}
                                className={styles.changedRelative}
                                measure={' %'}
                                measureClassName={styles.measure}
                            />
                        </div>
                    </div>
                </div>
                {columns.map(({ title, value, className }) => (
                    <div key={title} className={classNames(styles.column, 'flex', 'flex-column', className)}>
                        <h4 className={styles.columnTitle}>{title}</h4>
                        <div className={styles.columnValue}>
                            {value !== null && value !== undefined ? value : NONE_SYMBOL}
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.addToFavorites}>
                <AddFavouriteButton isin={addToFavoritesCode} />
            </div>
        </div>
    );
};
