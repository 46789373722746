import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const SortDesc: FC<Props> = ({ width = 16, height = 16, color = 'currentColor' }) => (
    <svg
width={width} height={height} viewBox="0 0 16 16"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.5 6L8 3L11.5 6"
            stroke={color}
            color="#A5B1C0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.5 10L8 13L11.5 10"
            stroke="#2F9CEB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
