import { PageableType, PageableResponse as ServerPageableResponse } from '@libs/types';
import { SortingDirections } from '@libs/types/instrument.type';
import { type AxiosResponse } from 'axios';

export type GetBondDataResponse = AxiosResponse<{
    data: any;
}>

export type BondCouponsInfo = {
    totalCoupons: number | null;
    couponsPerYear: number | null;
    couponType: string | null;
    fixedCouponRate: number | null;
    floatingCouponRate: number | null;
    spreadToFloatingRate: number | null;
    floatingRateReview: number | null;
    currentCouponNumber: number | null;
    currentCouponRate: number | null;
    couponPaymentDate: string | null;
    recordDate: string | null;
    couponsUntilMaturity: number | null;
    couponsUntilNextOffer: number | null;
}

export type BondPriceInfo = {
    remainingNominal: number | null;
    cleanPricePercentage: number | null;
    cleanPriceCurrency: number | null;
    accruedInterestPercentage: number | null;
    accruedInterestCurrency: number | null;
    fullPricePercentage: number | null;
    fullPriceCurrency: number | null;
    yieldToMaturity: number | null;
    yieldToOffer: number | null;
    offerNumberForYield: number | null;
    lastDealCurrentYield: number | null;
    lastDealSimpleYield: number | null;
    macauleyDuration: number | null;
    modifiedDuration: number | null;
    convexity: number | null;
    pvbp: number | null;
}

export type BondIssueInfo = {
    registrationDate: string | null;
    placementStartDate: string | null;
    placementEndDate: string | null;
    nominal: number | null;
    currency: string | null;
    issueVolumeUnits: number | null;
    issueVolumeCurrency: number | null;
    circulationVolumeUnits: number | null;
    circulationVolumeCurrency: number | null;
    nominalIndexed: boolean | null;
    nominalAmortization: boolean | null;
    earlyRepurchaseRedemption: boolean | null;
    guaranteeType: string | null;
    guaranteeCompleteness: string | null;
    guaranteedAmount: number | null;
    numberOfGuarantors: number | null;
    covenantsInProspectus: boolean | null;
    conversionToAnotherInstrument: boolean | null;
    placementPlatform: string | null;
    subscriptionType: string | null;
    placementMethod: string | null;
    placementOfferUnits: number | null;
    placementOfferCurrency: number | null;
    demandVolumeUnits: number | null;
    demandVolumeCurrency: number | null;
    placedVolumeUnits: number | null;
    placedVolumeCurrency: number | null;
    numberOfDealsAtPlacement: number | null;
    minPriceInPlacementBidsPercentage: number | null;
    maxPriceInPlacementBidsPercentage: number | null;
    weightedAvgPriceAtPlacementPercentage: number | null;
    yieldAtPlacementWeightedAvgPricePercentage: number | null;
}

export type BondRedemptionInfo = {
    maturityDate: string | null;
    totalRedemptions: number | null;
    currentNominal: number | null;
    currentRedemptionNumber: number | null;
    partialRedemptionDate: string | null;
    recordDate: string | null;
    currentRedemptionPercentage: number | null;
    totalEarlyRepurchases: number | null;
    nextRepurchaseDate: string | null;
    earlyRepurchaseNumber: number | null;
    nextRepurchaseType: string | null;
    endOfRepurchaseSubmission: string | null;
    repurchasePrice: number | null;
    maxRepurchaseVolume: number | null;
}

export type BondRatingInfo = {
    raitingAgency: string | null;
    borrowerRaiting: string | null;
}

export type BondPaymentCouponData = {
    couponNumber: number;
    couponStartDate: string;
    couponEndDate: string;
    paymentDate: string | null;
    rateDetermination: string | null;
    listFixationDate: string | null;
    couponPeriod: number;
    interestRate: number | null;
    paymentAmount: number | null;
}

export type BondPaymentAmortisationData = {
    redemptionNumber: number;
    announcementDate: string;
    partialRedemptionDate: string;
    paymentDate: string;
    listFixationDate: string;
    redemptionType: string;
    daysFromIssue: number;
    redemptionPercentage: number;
    paymentPerBond: number;
};

export type BondPaymentEarlyRedemptionData = {
    offerNumber: number;
    offerStartDate: string;
    offerEndDate: string;
    offerAnnouncementDate: string;
    settlementDate: string;
    repurchaseDate: string;
    daysSincePlacement: number;
    repurchaseType: string;
    dirtyPrice: number;
    maximumRepurchaseVolume: number;
    offeredRepurchaseVolume: number;
    repurchasedVolume: number;
    satisfactionRatio: number | null;
};

export type BondGeneralInfo = {
    fullNameOfBorrower: string | null;
    shortNameOfBorrower: string | null;
    sector: string | null;
    industry: string | null;
    typeOfIssue: string | null;
    registrationNumber: string | null;
    listingLevel: number | null;
    lombardList: boolean | null;
    forQualifiedInvestors: boolean | null;
    subordinatedBond: boolean | null;
}

export type BondInfo = {
    name: string | null;
    companyName: string | null;
    currentValue: number | null;
    updatedAt: string | null;
    isin: string | null;
    logo: string | null;
    nominalValue: number | null;
    status: string | null;
    duration: number | null;
    dailyTradingVolume: number | null;
    lastDealProfitability: number | null;
    change: {
        absolute: number | null;
        relative: number | null;
    };
}


type CommonPaymentProps = {
    type: PaymentType;
    date: string;
}

export type RedemptionPayment = {
    paymentPerBond: number;
    endmtyDate: string;
} & CommonPaymentProps;


export type AmmortizationPayment = {
    mtyType: string;
    mtyDate: string;
    fixDate: string;
    mtyPart: number;
    payPerBond: number;
} & CommonPaymentProps;


export type ServerCouponPayment = {
    id_coupon: number;
}

export type CouponPayment = {
    couponType: string;
    couponNumber: number;
    paymentDate: string;
    listFixingDate: string;
    annualRate: number;
    paymentAmount: number;
} & CommonPaymentProps;

export type ServerOfferPayment = {
    operation_type: string;
    rate_date: string;
    beg_order: string;
    end_order: string;
    offer_date: string;
    end_offer_date: string;
    buy_back_price: number;
};

export type OfferPayment = {
    operationType: string;
    rateDate: string;
    begOrder: string;
    endOrder: string;
    offerDate: string;
    endOfferDate: string;
    buyBackPrice: number;
    paymentPerBond: number;
} & CommonPaymentProps;

export enum PaymentType {
    REDEMPTION = 'redemption',
    AMORTIZATION = 'amortization',
    COUPON = 'coupon',
    OFFER = 'offer',
}

export type PaymentChartBarType = RedemptionPayment | AmmortizationPayment | CouponPayment | OfferPayment;


export type GetBondDataRequestResponse = AxiosResponse<{
    bonds: Array<{
        boardId: string;
        buyBackDate: string | null;
        capitalization: number;
        close: number;
        couponRate: number;
        duration: number;
        fininstId: string;
        fintoolId: string;
        waprice?: number;
        group: string;
        initialFaceValue: number;
        isQualifiedInvestors: boolean;
        isin: string;
        issuerSector: string;
        listLevel: number;
        matDate: string;
        tradeDate: string;
        secId: string;
        securityType: string;
        shortName: string;
        title: string;
        type: string;
        yieldClose: number;
        status?: string;
        shortTitle?: string;
        isSubordinated?: boolean;
        regCode?: string;
    }>;
}>

export type ServerBondReferenceData = Array<{
    islombardcbr_nrd: number;
    coupontype: string;
    guaranteetype: string;
    guaranteeamount: string;
    guarantval: number;
    numguarantors: number;
    privatedist: string;
    currentfacevalue_nrd: number;
}>

export type GetBondReferenceDataRequestResponse = Array<{
    islombardcbrNrd: number;
    coupontype: string;
    guaranteetype: string;
    guaranteeamount: string;
    guarantval: number;
    numguarantors: number;
    privatedist: string;
    currentfacevalueNrd: number;
}>

export type GetBondRedemptionDataRequestResponse = AxiosResponse<Array<{
    isin: string;
    endmtyDate: string;
    regDate: string;
    begdist_date: string;
    enddist_date: string;
    facevalueСurrency: string;
    issueVol: number;
    issueVal: number;
    marketVol: number;
    marketVal: number;
    haveindexedfv: number;
    amortisedMty: number;
    haveOffer: number;
    hasCovenants: number;
    convertible: number;
    numCoupons: number;
    floatratename: number;
    raterevisionsperyear: number;
    couponsPerYear?: number;
}>>

export type ServerAmmortizationData = {
    mty_type: string;
    mty_date: string;
    fix_date: string;
    mty_part: number;
    pay_per_bond: string;
    id_tranche: number;
    rate_date: string;
    pay_date: string;
    days_from_dist: number;
}

type PageableResponse<T> = {
    data: T[];
    total: number;
}

export type GetBondAmmortisationsDataRequestResponse = PageableResponse<{
    mtyType: string;
    mtyDate: string;
    fixDate: string;
    mtyPart: number;
    payPerBond: number;
    idTranche: number;
    rateDate: string;
    payDate: string;
    daysFromDist: number;
}>

export type PageableBodyWithFilter<T> = {
    fintoolId?: string | number;
    pageable?: PageableType<keyof T, SortingDirections>;
    filter?: { key: string; value: string | number };
}

export type ServerOfferData = {
    operation_type: string;
    rate_date: string;
    beg_order: string;
    end_order: string;
    offer_date: string;
    end_offer_date: string;
    buy_back_price: number;
    id_offer: number;
    buy_back_limit_vol: number;
    buy_back_bid_vol: number;
    buy_back_vol: number;
    buy_back_bid_ratio: number;
    days_from_dist: number;
}

export type GetBondOffersDataRequestResponse = PageableResponse<{
    operationType: string;
    rateDate: string;
    begOrder: string;
    endOrder: string;
    offerDate: string;
    endOfferDate: string;
    buyBackPrice: number;
    idOffer: number;
    buyBackLimitVol: number;
    buyBackBidVol: number;
    buyBackVol: number;
    buyBackBidRatio: number;
    daysFromDist: number;
}>

export type ServerCouponData = {
    id_coupon: number;
    rate_spread_pct: number;
    coupon_period: number;
    pay_date?: string;
    begin_period?: string;
    end_period?: string;
    fix_date: string;
    base_type_name: string;
    coupon_rate?: number;
    pay_per_bond?: number;
}

export type GetBondCouponsDataRequestResponse = PageableResponse<{
    idCoupon: number;
    rateSpreadPct: number;
    couponPeriod: number;
    payDate?: string;
    beginPeriod?: string;
    endPeriod?: string;
    couponRate?: number;
    baseTypeName: string;
    fixDate: string;
    payPerBond?: number;
}>

export type ServerAuctionData = {
    exch_name: string;
    method: string;
    ask_vol: number;
    ask_val: number;
    bid_vol: number;
    bid_val: number;
    dist_vol: number;
    dist_val: number;
    waprice: number;
    num_trades: number;
    min_bid: number;
    max_bid: number;
    yield_waprice: number;
}

export type GetBondAuctionDataRequestResponse = PageableResponse<{
    exchName: string;
    method: string;
    askVol: number;
    askVal: number;
    bidVol: number;
    bidVal: number;
    distVol: number;
    distVal: number;
    numTrades: number;
    waprice: number;
    minBid: number;
    maxBid: number;
    yieldWaprice: number;
}>

export type GetBondCouponsSumRequestResponse = AxiosResponse<Array<{
    sumCouponsMty: number;
    sumCouponsOffer: number;
}>>

export type GetBondCalculatedDataRequestResponse = AxiosResponse<{
    coupon_payment: number;
}>

export enum BondCalculationValueType {
    CLEAR_PRICE = 0,
    FULL_PRICE = 1,
    YIELD_FOR_REDEMPTION = 2,
    YIELD_FOR_OFFER = 3,
}

export type CalculateBondRequestBody = {
    id: string;
    date: string;
    value: number;
    valueType: BondCalculationValueType;
    rateNew: number;
    periods: number;
    comission: number;
}

export type ServerBondCalculationData = {
    is_offer: boolean;
    offer_id: number;
    price_pct: number;
    fullprice_pct: number;
    price: number;
    accrint: number;
    accrint_pct?: number;
    fullprice: number;
    yield: number;
    convx: number;
    pvbp: number;
    cyield: number;
    syield: number;
    mD?: number | null;
    faceval_at_date: number;
}

export type CalculateBondReqeuestResponse = Array<{
    isOffer: boolean;
    offerId: number;
    pricePct: number;
    mD?: number | null;
    fullpricePct: number;
    price: number;
    accrint: number;
    accrintPct?: number;
    fullprice: number;
    yield: number;
    convx: number;
    pvbp: number;
    cyield: number;
    syield: number;
    facevalAtDate: number;
}>

export type GetSecurityRatingData = {
    code_name: string;
    id_value: string;
}

export type GetSecurityRatingRequestResponse = Array<{
    codeName: string;
    idValue: string;
}>

export type GetSecurityRatingRequestBody = {
    isin: string;
    date: string;
    count: number;
}

export type GetBondTradeStateRequestBody = {
    secId: string;
    pageable: PageableType<'tradeDate', SortingDirections>;
}

export type TradeStat = {
    tradeDate: string;
    open: number;
    min: number;
    max: number;
    close: number;
    waprice: number;
    yieldAtWap: number;
    numtrades: number;
    volume: number;
    value: number;
    ndmNumtrades: number;
    ndmValue: number;
}

export type GetBondTradeStateRequestResponse = AxiosResponse<ServerPageableResponse<TradeStat>>;
