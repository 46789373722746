import {
    type PortfolioResponseData,
    type PortfolioUpdateRequestData,
} from '@modules/Investorpro/modules/PortfolioPage/entities';

import { type ApiEndpoint, type ApiFactory } from '../../core/endpoint';

export const updatePortfolio: ApiFactory<ApiEndpoint<
    PortfolioResponseData,
    PortfolioUpdateRequestData,
    { id?: string }
>
> = (client) => async ({ signal, data, params }) => {
    const res = await client.put<PortfolioResponseData>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/portfolio/${params.id}`,
        data,
        { signal },
    );

    return res.data;
};
