import { type FC } from 'react';
import { Cell, PieChart as Chart, Pie } from 'recharts';

type Props = {
    data: Array<{
        name?: string;
        value?: number;
        color?: string;
    }>;
    price: {
        value?: number;
        currency?: string;
    };
    isInReport?: boolean;
};

export const PieChart: FC<Props> = ({ data, price, isInReport }) => {
    return (
        <Chart width={240} height={240}>
            <Pie
                isAnimationActive={!isInReport}
                data={data}
                dataKey="value"
                innerRadius={92}
                outerRadius={112}
                cx="50%"
                cy="50%"
            >
                {data.map((entry) => (
                    <Cell
                        key={`cell-${entry.name}`}
                        fill={entry.color}
                        fillOpacity={0.2}
                        style={{ pointerEvents: 'none' }}
                    />
                ))}
            </Pie>
            <Pie
isAnimationActive={!isInReport} data={data} dataKey="value"
innerRadius={112} outerRadius={119}>
                {data.map((entry) => (
                    <Cell key={`cell-${entry.name}`} fill={entry.color} style={{ pointerEvents: 'none' }} />
                ))}
            </Pie>
            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                style={{ fontSize: 16, fontWeight: 600 }}
            >
                {price.value?.toLocaleString('ru')}
            </text>
            <text
x="50%" y="60%" textAnchor="middle"
dominantBaseline="middle" style={{ fontSize: 13 }}>
                {price.currency}
            </text>
        </Chart>
    );
};
