import { useLocation } from 'react-router-dom';
import { type PropsWithChildren, useCallback, useEffect } from 'react';
import ym, { YMInitializer } from 'react-yandex-metrika';

const useLocationChange = (action: any) => {
    const location = useLocation();
    useEffect(() => {
        action(location);
    }, [location]);
};

//Check, is it production mode
export const enableYM = process.env.NODE_ENV === 'production' && process.env.REACT_APP_YM_ID;

export const YandexMetrica = ({ children }: PropsWithChildren) => {
    const hit = useCallback((url: string) => {
        if (enableYM) {
            ym('hit', url);
        } else {
            console.log('%c[YandexMetrika](HIT)', 'color: orange', url);
        }
    }, []);

    useLocationChange((location: any) => {
        hit(window.location.pathname + window.location.search);
        console.log('handle route change here', location);
    });

    return (
        <>
            {enableYM && (
                <YMInitializer accounts={[Number(process.env.REACT_APP_YM_ID)]} options={{ defer: true }} version="2" />
            )}
            {children}
        </>
    );
};
