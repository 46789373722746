import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { type Continents, CONTINENTS } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { useApi } from '../../../api';

export const useGetGeoDiversificationQuery = (
    id: string,
    enabled = true,
    onStatusChange?: (status: 'error' | 'success' | 'pending') => void,
) => {
    const api = useApi();

    const query = useQuery({
        queryKey: ['portfolio', id, 'diversification', 'geo-diversification'],
        queryFn: async () => await api.portfolio.diversification.getGeoDiversification({ params: { id } }),
        enabled: Boolean(id) && enabled,
        select: ({ geoDiversification }) => (
            Object.entries(CONTINENTS).map(([key, value]) => (
                {
                    name: value.name,
                    value: geoDiversification[key as Continents] || 0,
                    color: value.color,
                }
            ))
        ),
    });

    useEffect(() => {
        if (query.isLoading || query.isFetching) {
            onStatusChange?.('pending');
        }

        if (query.isError) {
            onStatusChange?.('error');
        }

        if (query.isSuccess && !query.isLoading && !query.isFetching) {
            onStatusChange?.('success');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isLoading, query.isError, query.isSuccess, query.isFetching, query.isPending]);

    return query;
};
