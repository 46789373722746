import { type ReactNode, forwardRef } from 'react';
import classNames from 'classnames';

import styles from './style.module.scss';

export type TooltipAlignment = 'left' | 'right' | 'top' | 'bottom';

type Props = {
    children: ReactNode;
    className?: string;
    withArrow?: boolean;
    visible?: boolean;
    arrowAlignment?: TooltipAlignment;
    transparent?: boolean;
    alignment?: TooltipAlignment;
} & React.HTMLAttributes<HTMLDivElement>;

export const TooltipWrapper = forwardRef<HTMLDivElement, Props>(
    (
        {
            children,
            className,
            transparent = true,
            withArrow = true,
            visible = true,
            alignment = 'top',
            arrowAlignment: extraArrowAlignment,
            ...props
        },
        ref,
    ) => {
        const arrowAlignment = extraArrowAlignment ?? alignment;

        return (
            <div
                ref={ref}
                {...props}
                className={classNames(styles.tooltipWrapper, !visible && styles.tooltipWrapper__hidden)}
            >
                <div
                    className={classNames(
                        styles.tooltip,
                        transparent && styles.tooltip__transparent,
                        alignment && styles[`tooltip__${alignment}`],
                        className,
                    )}
                >
                    {children}
                    {withArrow && (
                        <svg
                            width="28"
                            className={classNames(
                                styles.tooltip_arrow,
                                arrowAlignment && styles[`tooltip_arrow__${arrowAlignment}`],
                            )}
                            height="8"
                            viewBox="0 0 28 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.51472 0.5H0.5V-2H27.5V0.5H24.4853C22.7614 0.5 21.1081 1.18482 19.8891 2.4038L15.3435 6.94939C14.6015 7.69139 13.3985 7.69139 12.6565 6.94939L8.11091 2.40381C6.89193 1.18482 5.23863 0.5 3.51472 0.5Z"
                                fill="white"
                                stroke="#D8DFE6"
                            />
                        </svg>
                    )}
                </div>
            </div>
        );
    },
);
