import { Box } from '@mantine/core';
import { type FC } from 'react';

type Props = {
    value: number;
};

export const StressProgress: FC<Props> = ({ value }) => {
    const width = `${Math.abs(value / 2)}%`;

    return (
        <Box
            style={{
                width: '100%',
                backgroundColor: '#D8E1EB',
                height: '4px',
                position: 'relative',
                borderRadius: '2px',
                overflow: 'hidden',
            }}
        >
            <Box
                style={{
                    width,
                    backgroundColor: `${value >= 0 ? '#42A62E' : '#F2433D'}`,
                    height: '100%',
                    position: 'absolute',
                    borderRadius: '2px',
                    left: value > 0 ? '50%' : `${50 - Math.abs(value / 2)}%`,
                }}
            />
        </Box>
    );
};
