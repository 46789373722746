import { ControlledInput, ModalWindow } from '@libs/components';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { type AppDispatch, copyScreenerThunk, useAppSelector } from '@store/store';
import { MAX_INPUT_VALUE_LENGTH } from '@modules/Investorpro/modules/ScreenersPage/constants';
import { type ScreenerType } from '@modules/Investorpro/modules/ScreenersPage/types';

import styles from '../../styles.module.scss';

type IProps = {
    isOpen: boolean;
    onCloseModal: () => void;
    screenerId: number;
    screenerName: string;
    onCreateScreener?: (screenerId?: ScreenerType['id']) => void;
};
type TFormData = {
    name: string;
};

export const CopyScreenerModal = ({ isOpen, onCloseModal, onCreateScreener, screenerId, screenerName }: IProps) => {
    const isLoading: boolean = useAppSelector((state) => state.screeners.isLoading);

    const initialFormState = useMemo(
        () => ({
            name: `${screenerName} Копия`,
        }),
        [screenerName],
    );

    const {
        handleSubmit,
        reset,
        control,
        setValue,
        watch,
        formState: { isValid },
    } = useForm<TFormData>({ defaultValues: initialFormState, mode: 'onChange' });

    const dispatch = useDispatch<AppDispatch>();

    const handleModalClose = () => {
        onCloseModal();
        reset(initialFormState);
    };

    useEffect(() => {
        reset(initialFormState);
    }, [initialFormState, reset]);

    const onSubmit = async (data: TFormData) => {
        try {
            if (isValid) {
                const { payload } = await dispatch(copyScreenerThunk({ screenerId, name: data.name.trim() }));
                // @ts-expect-error При отсутствии ошибки
                const { errorMessage } = payload?.status ?? {};

                if (errorMessage) {
                    console.error(errorMessage);
                } else {
                    onCreateScreener?.((payload as ScreenerType).id ?? 0);
                    onCloseModal();
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const trimValue = watch('name', initialFormState.name).trim();

    return (
        <ModalWindow closeModal={handleModalClose} isVisible={isOpen}>
            <form className={styles.dialog} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.dialogWrapper}>
                    <div className={styles.dialogHeader}>Копировать скринер</div>
                    <ControlledInput
                        className={styles.dialogInput}
                        name="name"
                        control={control}
                        onChange={(e) => setValue('name', e.target.value)}
                        rules={{ required: 'Поля обязательное для заполнения', maxLength: 60 }}
                        maxLength={MAX_INPUT_VALUE_LENGTH}
                    />
                    <div className={classNames('flex')}>
                        <Button
                            className={styles.dialogActionButton}
                            type="button"
                            severity="secondary"
                            onClick={handleModalClose}
                        >
                            Отменить
                        </Button>
                        <Button
                            className={classNames(styles.dialogActionButton, styles.dialogActionButtonSuccess)}
                            type="submit"
                            disabled={!trimValue.length || isLoading}
                        >
                            Сохранить
                        </Button>
                    </div>
                </div>
            </form>
        </ModalWindow>
    );
};
