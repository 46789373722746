import { httpClient } from '@configs/httpclient.config';
import { type PageableResponse } from '@libs/types';

import {
    type CalculateBondReqeuestResponse,
    type CalculateBondRequestBody,
    type GetBondAmmortisationsDataRequestResponse,
    type GetBondAuctionDataRequestResponse,
    type GetBondCouponsDataRequestResponse,
    type GetBondCouponsSumRequestResponse,
    type GetBondDataRequestResponse,
    type GetBondOffersDataRequestResponse,
    type GetBondRedemptionDataRequestResponse,
    type GetBondReferenceDataRequestResponse,
    type GetSecurityRatingRequestBody,
    type GetSecurityRatingRequestResponse,
    type PageableBodyWithFilter,
    type ServerAmmortizationData,
    type ServerBondReferenceData,
    type ServerOfferData,
    type ServerCouponData,
    type ServerAuctionData,
    type ServerBondCalculationData,
    GetBondTradeStateRequestBody,
    GetBondTradeStateRequestResponse,
} from './types';
import { parseServerNumberValue } from '@modules/Investorpro/shared/utils/service.utils';


export const BOND_API_URL = '/moex-datashop-instrument-service/api/v1/';

const transformtPageableBodyToServerFormat = <T>(body: PageableBodyWithFilter<T>) => {
    return {
        fintoolId: body.fintoolId,
        pageable: {
            pageSize: body.pageable?.pageSize,
            pageNumber: body.pageable?.pageNumber,
            sortField: body.pageable?.sortField,
            sortOrder: body.pageable?.sortOrder,
        },
        filter: body.filter ? `${body.filter.key} = '${body.filter.value}'` : undefined,
    }
}

export const getBondData = async (isin: string, tradeDate?: string): Promise<GetBondDataRequestResponse> => {
    return await httpClient.get(BOND_API_URL + 'bonds/bond_data', {
        params: {
            isin,
            tradeDate,
        },
    })
}

export const getBondReferenceData = async (isin: string): Promise<GetBondReferenceDataRequestResponse> => {
    const response = await httpClient.post<ServerBondReferenceData>(BOND_API_URL + 'info/fintollReferenceData', { isin })

    return response.data.map(({ islombardcbr_nrd, currentfacevalue_nrd, ...item }) => ({
        ...item,
        currentfacevalueNrd: currentfacevalue_nrd,
        islombardcbrNrd: islombardcbr_nrd,
    }))
}

export const getBondCommonData = async (isin: string): Promise<GetBondRedemptionDataRequestResponse> => {
    return await httpClient.post(BOND_API_URL + 'bond/commonData', { isin })
}

export const getBondAmmortizationsData = async (body: PageableBodyWithFilter<ServerAmmortizationData>):
Promise<GetBondAmmortisationsDataRequestResponse> => {
    const response = await httpClient.post<PageableResponse<ServerAmmortizationData>>(
        BOND_API_URL + 'bond/amortizations', transformtPageableBodyToServerFormat(body),
    )

    return {
        data: response.data.rows.map((item) => ({
            mtyType: item.mty_type,
            mtyDate: item.mty_date,
            fixDate: item.fix_date,
            mtyPart: item.mty_part,
            payPerBond: parseServerNumberValue(item.pay_per_bond)!,
            idTranche: item.id_tranche,
            rateDate: item.rate_date,
            payDate: item.pay_date,
            daysFromDist: item.days_from_dist,
        })),
        total: response.data.totalRows,
    }
}

export const getBondOffersData = async (body: PageableBodyWithFilter<ServerOfferData>):
Promise<GetBondOffersDataRequestResponse> => {
    const response = await httpClient.post<PageableResponse<ServerOfferData>>(
        BOND_API_URL + 'bond/offers', transformtPageableBodyToServerFormat(body),
    )

    return {
        data: response.data.rows.map((item) => ({
            operationType: item.operation_type,
            rateDate: item.rate_date,
            begOrder: item.beg_order,
            endOrder: item.end_order,
            offerDate: item.offer_date,
            endOfferDate: item.end_offer_date,
            buyBackPrice: item.buy_back_price,
            daysFromDist: item.days_from_dist,
            idOffer: item.id_offer,
            buyBackLimitVol: item.buy_back_limit_vol,
            buyBackBidVol: item.buy_back_bid_vol,
            buyBackVol: item.buy_back_vol,
            buyBackBidRatio: item.buy_back_bid_ratio,
        })),
        total: response.data.totalRows,
    }
}

export const getBondCouponsData = async (body: PageableBodyWithFilter<ServerCouponData>):
Promise<GetBondCouponsDataRequestResponse> => {
    const response = await httpClient.post<PageableResponse<ServerCouponData>>(
        BOND_API_URL + 'bond/coupons', transformtPageableBodyToServerFormat(body),
    )

    return {
        data: response.data.rows.map((item) => ({
            idCoupon: item.id_coupon,
            rateSpreadPct: item.rate_spread_pct,
            couponPeriod: item.coupon_period,
            payDate: item.pay_date,
            beginPeriod: item.begin_period,
            endPeriod: item.end_period,
            fixDate: item.fix_date,
            baseTypeName: item.base_type_name,
            couponRate: item.coupon_rate,
            payPerBond: item.pay_per_bond,
        })),
        total: response.data.totalRows,
    }
}

export const getBondAuctionData = async (body: PageableBodyWithFilter<ServerAuctionData>):
Promise<GetBondAuctionDataRequestResponse> => {
    const response = await httpClient.post<PageableResponse<ServerAuctionData>>(
        BOND_API_URL + 'bond/auctiondata', transformtPageableBodyToServerFormat(body),
    )

    return {
        data: response.data.rows.map((item) => ({
            exchName: item.exch_name,
            method: item.method,
            askVol: item.ask_vol,
            askVal: item.ask_val,
            bidVol: item.bid_vol,
            bidVal: item.bid_val,
            distVol: item.dist_vol,
            distVal: item.dist_val,
            numTrades: item.num_trades,
            waprice: item.waprice,
            minBid: item.min_bid,
            maxBid: item.max_bid,
            yieldWaprice: item.yield_waprice,
        })),
        total: response.data.totalRows,
    }
}

export const getBondCouponsSum = async (isin: string): Promise<GetBondCouponsSumRequestResponse> => {
    return await httpClient.post(BOND_API_URL + 'bond/dateOptions', { isin })
}

export const calculateBondRequest = async (calculateBond: CalculateBondRequestBody):
Promise<CalculateBondReqeuestResponse> => {
    const response = await httpClient.post<ServerBondCalculationData[]>(
        BOND_API_URL + 'bond/calculateBond', calculateBond,
    )

    return response.data.map((item) => ({
        isOffer: item.is_offer,
        offerId: item.offer_id,
        pricePct: item.price_pct,
        fullpricePct: item.fullprice_pct,
        price: item.price,
        accrint: item.accrint,
        accrintPct: item.accrint_pct,
        fullprice: item.fullprice,
        yield: item.yield,
        convx: item.convx,
        pvbp: item.pvbp,
        cyield: item.cyield,
        syield: item.syield,
        mD: item.mD,
        facevalAtDate: item.faceval_at_date,
    }))

}

export const getSecurityRating = async (body: GetSecurityRatingRequestBody):
Promise<GetSecurityRatingRequestResponse> => {
    // return await httpClient.post(BOND_API_URL + 'rating/securityRating', body)

    return [
        {
            codeName: 'AKPA',
            idValue: 'AAA',
        },
    ]
}


export const getBondTradeStats = (body: GetBondTradeStateRequestBody): Promise<GetBondTradeStateRequestResponse> => {
    return httpClient.post(BOND_API_URL + 'bonds/trade-stats', body)
}
