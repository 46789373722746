import { SecurityMarketType } from '@libs/types';
import { SecurityBoards } from '@modules/Investorpro/modules/MarketPages/shared/constants';

type GetCandleSocketTickerArgs = {
    market: SecurityMarketType;
    board?: string;
    secId: string;
}

export const getCandleSocketTicker = ({
    market,
    board,
    secId,
}: GetCandleSocketTickerArgs) => {
    const codeByMarket: Record<SecurityMarketType, string> = {
        [SecurityMarketType.CURRENCY]: 'MXCX',
        [SecurityMarketType.STOCK]: 'MXSE',
        [SecurityMarketType.BOND]: 'MXSE',
        [SecurityMarketType.INDEX]: 'INDICES',
        [SecurityMarketType.GOODS]: 'FORTS',
    }

    return `${codeByMarket[market]}.${board ?? SecurityBoards.SNDX}.${secId}`
}


export const parseCandleDate = (date: string) => {
    return date.split(' ')[0]
}

export const parseServerNumberValue = <T extends string | null>(value: T) => {
    if (value === null) {
        return null
    }

    const numericValue = Number(value)

    if (Number.isNaN(numericValue)) {
        return null
    }

    return numericValue
}
