import { getCompanyLogo } from '@libs/services/instrument.service';
import { SecurityMarketType } from '@libs/types';
import { IntervalType, PeriodId, SortingDirections } from '@libs/types/instrument.type';
import { formatDateToQuery } from '@libs/utils';
import {
    getBondAmmortizationsData,
    getBondAuctionData,
    getBondCouponsData,
    getBondCouponsSum,
    getBondData,
    getBondOffersData,
    getBondCommonData,
    getBondReferenceData,
    calculateBondRequest,
    getSecurityRating,
    getBondTradeStats,
} from '@modules/Investorpro/modules/BondStockPage/BondPage/services/bond.services';
import {
    BondCalculationValueType,
    type BondPaymentAmortisationData,
    type BondPaymentCouponData,
    type BondPaymentEarlyRedemptionData,
    PaymentType,
    type PaymentChartBarType,
    type RedemptionPayment,
    type AmmortizationPayment,
    type CouponPayment,
    type OfferPayment,
} from '@modules/Investorpro/modules/BondStockPage/BondPage/services/types';
import {
    getArrowPosByDatePeriod,
    getArrowPosByPeriodId,
    refetchDataUntilFindNotEmpty,
} from '@modules/Investorpro/modules/MarketPages/shared/utils/fetch.utils';
import {
    CandleDataColumnType,
    GetCandlesDataResponse,
    InstrumentEngineType,
} from '@modules/Investorpro/types/quote.type';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCandlesData } from '@shared/services/quote.service';
import { type RootState } from '@store/store';
import { addDays, isBefore, subDays, subYears } from 'date-fns';

const PAYMENTS_PAGE_SIZE = 14;

export const getBondDataThunk = createAsyncThunk('investpro/getBondInfo', async (isin: string, { rejectWithValue }) => {
    try {
        const bondDataResponse = await getBondData(isin);
        const zeroBond = bondDataResponse.data.bonds[0];
        const tradeDate = new Date(zeroBond?.tradeDate);
        const boards = bondDataResponse.data.bonds.map(({ boardId }) => boardId);
        const secId = zeroBond?.secId ?? null;

        const parsePrice = (data: any[]) => data?.[0]?.[0] ?? 0;
        const parseVolume = (data: any[]) => data?.[0]?.[1] ?? 0;
        let candles: GetCandlesDataResponse['data']['candles']['data'] = [];
        let boardWithData = boards[0];

        if (secId && boards.length > 0) {
            const getTillDate = () => {
                if (zeroBond?.matDate) {
                    const matDate = new Date(zeroBond.matDate);
                    if (isBefore(matDate, tradeDate)) {
                        return matDate;
                    }
                }

                return tradeDate;
            };
            const tillDate = getTillDate();

            let activeBoard = boards[0];

            const fetchCandles = async (date: string, board: string) => {
                return await getCandlesData({
                    engine: InstrumentEngineType.STOCK,
                    market: SecurityMarketType.BOND,
                    board,
                    arrayPos: getArrowPosByDatePeriod(IntervalType.ONE_DAY, date, tillDate),
                    secId,
                    columns: [CandleDataColumnType.CLOSE, CandleDataColumnType.VALUE, CandleDataColumnType.END],
                    interval: IntervalType.ONE_DAY,
                    from: date,
                    till: formatDateToQuery(tillDate),
                });
            };

            const [priceResponse] = await refetchDataUntilFindNotEmpty({
                fetchData: async (from, till, i) => {
                    activeBoard = boards[i];

                    return await fetchCandles(from, activeBoard);
                },
                start: subDays(tillDate, 360),
                format: formatDateToQuery,
                step: 0,
                maxTries: boards.length,
                responseDataAccessor: (resp) => resp.data.candles.data,
            });

            candles = priceResponse as any[];
            boardWithData = activeBoard;

            if (priceResponse.length < 2) {
                const [finalPriceResponse] = await refetchDataUntilFindNotEmpty({
                    fetchData: async (from, _, i) => await fetchCandles(from, activeBoard),
                    start: subYears(tillDate, 2),
                    format: formatDateToQuery,
                    condition: (data) => data.length > 1,
                    step: [360, 360, 360],
                    responseDataAccessor: (resp) => resp.data.candles.data,
                });

                candles = finalPriceResponse as any[];
            }
        }

        const lastPriceElement = candles[candles.length - 1];
        const currentQuote =
            bondDataResponse.data.bonds[0]?.close ?? lastPriceElement ? (parsePrice(lastPriceElement) as number) : null;
        const prevQuote = candles[candles.length - 2] ? (parsePrice(candles[candles.length - 2]) as number) : null;
        const dayVolume = lastPriceElement ? (parseVolume(lastPriceElement) as number) : null;
        const lastTradeDate = candles[candles.length - 1]?.[0]?.[2]
            ? new Date(candles[candles.length - 1]?.[0]?.[2] as string)
            : tradeDate;

        return {
            ...(zeroBond ?? {}),
            isin,
            boards,
            currentQuote,
            prevQuote,
            boardId: boardWithData,
            dayVolume,
            hasZeroBond: Boolean(zeroBond),
            updateDate: lastTradeDate.toJSON(),
        };
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const getBondReferenceDataThunk = createAsyncThunk(
    'investpro/getLombardListStatus',
    async (isin: string, { rejectWithValue }) => {
        try {
            const response = await getBondReferenceData(isin);

            return response;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export type GetBondPaymentsArgs = {
    isin: string;
    fintoolId: string;
    type: PaymentType;
    page: number;
};

export const getComponyLogoThunk = createAsyncThunk(
    'investpro/getCompanyLogo',
    async (fininstId: string, { rejectWithValue }) => {
        try {
            const response = await getCompanyLogo([fininstId]);

            return response.data[0]?.link ?? null;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const getBondPaymentsThunk = createAsyncThunk(
    'investpro/getBondPaymens',
    async ({ isin, fintoolId, type, page }: GetBondPaymentsArgs, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        const info = state.bond.descriptionInfo;
        let count = 0;

        const pageableBody = {
            fintoolId,
            pageable: {
                pageSize: PAYMENTS_PAGE_SIZE,
                pageNumber: page,
            },
        };

        try {
            const data: any[] = [];

            if (type === PaymentType.COUPON) {
                const response = await getBondCouponsData(pageableBody);
                count = response.total;

                response.data.forEach((item) => {
                    const couponItem: BondPaymentCouponData = {
                        couponNumber: item.idCoupon,
                        couponStartDate: item.beginPeriod ?? '',
                        couponEndDate: item.endPeriod ?? '',
                        paymentDate: item.payDate ?? null,
                        rateDetermination: info.coupons.spreadToFloatingRate?.toString() ?? null,
                        listFixationDate: info.coupons.recordDate ?? null,
                        couponPeriod: item.couponPeriod,
                        interestRate: item.couponRate ?? null,
                        paymentAmount: item.payPerBond ?? state.bond.payPerBond,
                    };

                    data.push(couponItem);
                });
            }

            if (type === PaymentType.AMORTIZATION) {
                const response = await getBondAmmortizationsData(pageableBody);
                count = response.total;

                response.data.forEach((item) => {
                    const ammortiazationItem: BondPaymentAmortisationData = {
                        redemptionNumber: item.idTranche,
                        announcementDate: item.rateDate,
                        partialRedemptionDate: item.mtyDate,
                        paymentDate: item.payDate,
                        listFixationDate: item.fixDate,
                        redemptionType: item.mtyType,
                        daysFromIssue: item.daysFromDist,
                        redemptionPercentage: item.mtyPart,
                        paymentPerBond: item.payPerBond,
                    };

                    data.push(ammortiazationItem);
                });
            }

            if (type === PaymentType.REDEMPTION) {
                const response = await getBondOffersData(pageableBody);
                count = response.total;

                response.data.forEach((item) => {
                    const redemptionItem: BondPaymentEarlyRedemptionData = {
                        offerNumber: item.idOffer,
                        offerStartDate: item.begOrder,
                        offerEndDate: item.endOrder,
                        offerAnnouncementDate: item.rateDate,
                        settlementDate: item.offerDate,
                        repurchaseDate: item.endOfferDate,
                        daysSincePlacement: item.daysFromDist,
                        repurchaseType: item.operationType,
                        dirtyPrice: item.buyBackPrice,
                        maximumRepurchaseVolume: item.buyBackLimitVol,
                        offeredRepurchaseVolume: item.buyBackBidVol,
                        repurchasedVolume: item.buyBackVol,
                        satisfactionRatio: item.buyBackBidRatio,
                    };

                    data.push(redemptionItem);
                });
            }

            return {
                count,
                type,
                data,
            };
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export type GetBondTradesArgs = {
    secId: string;
    page: number;
};

export const getBondTradesResultsThunk = createAsyncThunk(
    'investpro/getBondTradesResults',
    async ({ secId, page }: GetBondTradesArgs, { rejectWithValue, getState }) => {
        try {
            const PAGE_SIZE = 14;

            const response = await getBondTradeStats({
                pageable: {
                    pageNumber: page,
                    pageSize: PAGE_SIZE,
                    sortOrder: SortingDirections.DESC,
                    sortField: 'tradeDate',
                },
                secId,
            });

            return {
                count: response.data.totalRows,
                data: response.data.rows,
            };
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export type GetBondDescriptionDataThunkArgs = {
    isin: string;
    fintoolId: string;
    tradeDate: string;
    lastValue: number;
};

export const getBondDescriptionDataThunk = createAsyncThunk(
    'investpro/getBondDescriptionData',
    async ({ isin, fintoolId, lastValue, tradeDate }: GetBondDescriptionDataThunkArgs, { rejectWithValue }) => {
        try {
            const response = await Promise.all([
                getBondAuctionData({ fintoolId }),
                getBondCouponsSum(isin),
                calculateBondRequest({
                    id: isin,
                    date: formatDateToQuery(new Date(tradeDate)),
                    value: lastValue,
                    valueType: BondCalculationValueType.YIELD_FOR_REDEMPTION,
                    rateNew: 0,
                    periods: 3,
                    comission: 0,
                }),
                getSecurityRating({
                    isin,
                    date: new Date().toJSON(),
                    count: 0,
                }),
                calculateBondRequest({
                    id: isin,
                    date: formatDateToQuery(new Date(tradeDate)),
                    value: lastValue,
                    valueType: BondCalculationValueType.YIELD_FOR_OFFER,
                    rateNew: 0,
                    periods: 3,
                    comission: 0,
                }),
            ]);

            return {
                auctions: response[0].data,
                couponsSum: response[1].data,
                calcaulations: response[2],
                ratings: response[3],
                offerYield: response[4][0]?.yield ?? null,
                redemptionYield: response[2][0]?.yield ?? null,
            };
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export type getBondPaymentsBarsDataThunkArgs = {
    fintoolId: string;
    isin: string;
};

export const getBondPaymentsBarsDataThunk = createAsyncThunk(
    'investpro/getBondPaymentsCurvesThunkData',
    async ({ fintoolId, isin }: getBondPaymentsBarsDataThunkArgs, { rejectWithValue }) => {
        try {
            let payments: PaymentChartBarType[] = [];

            const [offersResponse, couponsResponse, redemptionsResponse, ammortizationsResponse] = await Promise.all([
                getBondOffersData({ fintoolId }),
                getBondCouponsData({ fintoolId }),
                getBondCommonData(isin),
                getBondAmmortizationsData({ fintoolId }),
            ]);

            offersResponse.data.forEach((item) => {
                const offerPayment: OfferPayment = {
                    type: PaymentType.OFFER,
                    operationType: item.operationType,
                    rateDate: item.rateDate,
                    begOrder: item.begOrder,
                    endOrder: item.endOrder,
                    offerDate: item.offerDate,
                    date: item.endOfferDate,
                    endOfferDate: item.endOfferDate,
                    buyBackPrice: item.buyBackPrice,
                    paymentPerBond: couponsResponse.data[0]?.payPerBond ?? 0,
                };
                payments.push(offerPayment);
            });

            let couponsResponseStartDate = new Date();
            const payPerBond = ammortizationsResponse.data[0]?.payPerBond ?? couponsResponse.data[0]?.payPerBond ?? 0;

            couponsResponse.data.forEach((item) => {
                couponsResponseStartDate = addDays(couponsResponseStartDate, -1);
                const couponPayment: CouponPayment = {
                    type: PaymentType.COUPON,
                    couponType: item.baseTypeName,
                    couponNumber: item.idCoupon,
                    paymentDate: item.payDate ?? couponsResponseStartDate.toJSON(),
                    listFixingDate: item.fixDate,
                    annualRate: item.couponRate ?? 0,
                    paymentAmount: item.payPerBond ?? 0,
                    date: item.payDate ?? couponsResponseStartDate.toJSON(),
                };
                payments.push(couponPayment);
            });

            redemptionsResponse.data.forEach((item) => {
                const redemptionPayment: RedemptionPayment = {
                    type: PaymentType.REDEMPTION,
                    endmtyDate: item.endmtyDate,
                    paymentPerBond: payPerBond,
                    date: item.endmtyDate,
                };
                payments.push(redemptionPayment);
            });

            ammortizationsResponse.data.forEach((item) => {
                const ammortizationPayment: AmmortizationPayment = {
                    ...item,
                    type: PaymentType.AMORTIZATION,
                    date: item.mtyDate,
                };
                payments.push(ammortizationPayment);
            });

            payments = payments.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

            return {
                payments,
                offers: offersResponse.data,
                coupons: couponsResponse.data,
                redemptions: redemptionsResponse.data,
                ammortizations: ammortizationsResponse.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
