import { DataTable, type DataTableSortEvent, type DataTableValue } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { memo, useEffect, useState, useRef } from 'react';
import { Loader, PaginationController } from '@libs/components';
import { useSelector } from 'react-redux';
import { getInstrumentsThunk, type RootState, updateScreenerThunk, useAppDispatch, useAppSelector } from '@store/store';
import { isNumberValue } from '@libs/utils';
import classNames from 'classnames';
import { getFavouriteSecuritiesAndWatchlistsThunk } from '@store/store/thunk/investorpro/investorprofile/securities.thunk';
import { ScreenerFooter } from '@modules/Investorpro/modules/ScreenersPage/components/ScreenerFooter';
import {
    type FilterType,
    FilterTypes,
    type ScreenerColumnType,
} from '@modules/Investorpro/modules/ScreenersPage/types';
import { DEFAULT_PAGEABLE } from '@modules/Investorpro/shared/services';
import { type PageableType, SortOrderTypes } from '@libs/types';
import { changeCurrentPage } from '@store/store/slices/investorpro/screeners.slice';
import { SortFields } from '@modules/Investorpro/types/watchlist.type';
import { useNavigate } from 'react-router-dom';
import { usePermission } from '@libs/utils/hooks/usePermission';

import styles from './styles.module.scss';
import { ReactComponent as SortIcon } from '../../../../shared/images/svg/Sort.svg';
import { formatPrice } from '../../../../shared/utils/format.util';
import { IsFavourite } from './components/IsFavourite';
import { ColumnsSettings } from './components/ColumnsSettings';
import { type ColumnType, type ScreenerType } from '../../types';
import { getDefaultColumn } from './utils';

type ScreenerPagePropsType = {
    screenerId: ScreenerType['id'];
    onDeleteScreener: () => void;
    onUpdateCurrentScreener: (
        screenerId: ScreenerType['id'],
        body: { instrumentTypeId: ScreenerType['instrumentTypeId']; pageable?: Partial<PageableType> },
    ) => void;
    onCreateScreener?: (screenerId?: ScreenerType['id']) => void;
};

export const ScreenerPage = memo(
    ({ screenerId, onDeleteScreener, onUpdateCurrentScreener, onCreateScreener }: ScreenerPagePropsType) => {
        const dispatch = useAppDispatch();

        const screener = useSelector(
            (state: RootState) => state.screeners.screeners.find(({ id }) => id === screenerId) ?? ({} as ScreenerType),
        );
        const { hasPermissionWatchlist } = usePermission();

        const isScreenerLoading: boolean = useAppSelector((state) => state.screeners.isInstrumentsLoading);

        const screenerColumns: ColumnType[] | null = useSelector((state: RootState) => {
            const allColumns: ColumnType[] = state.screeners.columns.columns;

            if (!allColumns.length) {
                return null;
            }

            return (
                screener.columns?.map(
                    (column: ScreenerColumnType) => allColumns.find(({ id }) => id === column.id) ?? getDefaultColumn(column.id ?? 0),
                ) ?? null
            );
        });

        const {
            rows: instruments,
            totalPages,
            pageNumber,
        } = useSelector((state: RootState) => state.screeners.instruments);
        const { securities } = useAppSelector((state) => state.investorProfile);

        const tableRef = useRef<DataTable<DataTableValue[]>>(null);

        useEffect(() => {
            dispatch(getFavouriteSecuritiesAndWatchlistsThunk());
        }, []);

        const handleChangeCurrentPage = (pageNumber: number) => {
            dispatch(changeCurrentPage(pageNumber + 1));
        };

        const [sort, setSort] = useState<Pick<PageableType, 'sortField' | 'sortOrder'>>({
            sortField: '',
            sortOrder: DEFAULT_PAGEABLE.sortOrder,
        });

        useEffect(() => {
            if (!sort.sortField) {
                const defaultScreenerColumnName: string =
                    screenerColumns?.find(({ fieldName }) => Object.values(SortFields).includes(fieldName as SortFields),
                    )?.fieldName ?? '';
                const newSort = {
                    sortOrder: sort.sortOrder,
                    sortField: defaultScreenerColumnName,
                };
                setSort(newSort);
            }
        }, [screenerColumns, sort.sortField]);

        useEffect(() => {
            if (sort.sortField) {
                dispatch(
                    getInstrumentsThunk({
                        screenerId: screener.id,
                        body: {
                            instrumentTypeId: screener.instrumentTypeId,
                            pageable: {
                                pageNumber: pageNumber - 1,
                                ...sort,
                            },
                        },
                    }),
                );
            }
        }, [sort, pageNumber, dispatch]);

        useEffect(() => {
            tableRef?.current?.resetResizeColumnsWidth?.();
        }, [screenerColumns]);

        const handleSort = ({ sortField, sortOrder }: DataTableSortEvent) => {
            const newSort = {
                sortField,
                sortOrder: !sortOrder || sortOrder > 0 ? SortOrderTypes.ASC : SortOrderTypes.DESC,
            };
            setSort(newSort);
        };

        const filters: FilterType[] = useAppSelector((state: RootState) => state.screeners.filters.filters);

        // region isFavourite template
        const isFavoriteBodyTemplate = (instrument: DataTableValue) => (
            <IsFavourite
                isin={instrument.isin}
                key={instrument.isin + securities[instrument.isin]?.id}
                security={securities[instrument.isin]}
                isDisabled={!hasPermissionWatchlist}
            />
        );
        // endregion isFavourite template

        // region Column template
        const rootUrl = '';
        const navigate = useNavigate();

        const openSecurity = (row: DataTableValue) => {
            const securityId = row.seqId;
            const type = 'stocks';
            navigate(`${rootUrl}/analytics/${type}/${securityId}`);
        };

        const instrumentBodyTemplate = (instrument: DataTableValue, { field }: { field: string }) => {
            const column: ColumnType | undefined = screenerColumns?.find(({ fieldName }) => fieldName === field);
            const {
                colored: isColored,
                percentaged: isPercentaged,
                showCurrency,
                reducedToMil: isReducedToMil,
                primary,
            } = column ?? {};

            if ([null, undefined].includes(instrument[field])) {
                return (
                    <div
                        className={classNames('flex justify-content-end', primary && styles.primary)}
                        onClick={() => (primary ? openSecurity(instrument) : undefined)}
                    >
                        —
                    </div>
                );
            }

            if (instrument[field] === 0) {
                return (
                    <div
                        className={classNames('flex justify-content-end', primary && styles.primary)}
                        onClick={() => (primary ? openSecurity(instrument) : undefined)}
                    >
                        0
                    </div>
                );
            }

            const filter: FilterType | undefined = filters.find(({ fieldName }) => fieldName === field);

            if (filter?.type && [FilterTypes.SINGLE_SELECT, FilterTypes.MULTIPLE_SELECT].includes(filter.type)) {
                return (
                    <div
                        className={classNames('flex justify-content-end', primary && styles.primary)}
                        onClick={() => (primary ? openSecurity(instrument) : undefined)}
                    >
                        {filter.values?.find(({ value }) => value === instrument[field])?.name}
                    </div>
                );
            }

            let reducedToMil = instrument[field];

            const coloredClass: string[] = [];

            if (isNumberValue(reducedToMil)) {
                coloredClass.push(styles.number);

                if (isColored) {
                    coloredClass.push(reducedToMil < 0 ? styles.number__negative : styles.number__positive);
                } else {
                    coloredClass.push(styles.number__neitral);
                }
            }
            const percentage: string | null = isPercentaged ? '%' : null;
            const currency: JSX.Element | null = showCurrency ? <span className={styles.currency}>RUB</span> : null;

            if (isNumberValue(reducedToMil)) {
                if (isReducedToMil) {
                    reducedToMil = parseFloat((reducedToMil / 1000000).toFixed(2));
                    reducedToMil = `${reducedToMil <= 0.01 ? '< 0.01' : formatPrice(reducedToMil, ',')} млн`;
                } else {
                    reducedToMil = formatPrice(reducedToMil, ',');
                }
            }

            return (
                <div
                    className={classNames(
                        'flex justify-content-end align-items-end',
                        ...coloredClass,
                        primary && styles.primary,
                    )}
                    onClick={() => (primary ? openSecurity(instrument) : undefined)}
                >
                    {reducedToMil}
                    {percentage}
                    {currency}
                </div>
            );
        };
        // endregion Column template

        const handleColumnReorder = async (reorderedColumns: Column[]) => {
            const columnsOrder: ScreenerColumnType[] = reorderedColumns.reduce(
                (acc: ScreenerColumnType[], { props }) => {
                    const column: ColumnType | null =
                        screenerColumns?.find((column) => column.fieldName === props.field) ?? null;

                    if (column) {
                        acc.push({
                            id: column.id,
                        });
                    }

                    return acc;
                },
                [],
            );

            const updatedScreener: ScreenerType = {
                ...screener,
                columns: columnsOrder,
            };

            try {
                await dispatch(updateScreenerThunk(updatedScreener));
            } catch (error) {
                console.error(error);
            }
        };
        const isScreenerTableVisible = !instruments.length || isScreenerLoading ? 'hidden' : 'visible';

        return (
            <>
                <ScreenerFooter
                    screenerId={screenerId}
                    onDeleteScreener={onDeleteScreener}
                    onUpdateCurrentScreener={onUpdateCurrentScreener}
                    onCreateScreener={onCreateScreener}
                />
                {isScreenerLoading && <Loader isLoading={isScreenerLoading} />}
                <DataTable
                    ref={tableRef}
                    value={instruments}
                    stripedRows
                    reorderableColumns
                    onColReorder={async (event) => await handleColumnReorder(event.columns)}
                    sortIcon={<SortIcon className={styles.sortIcon} />}
                    onSort={handleSort}
                    lazy
                    sortField={sort.sortField}
                    sortOrder={sort.sortOrder === SortOrderTypes.ASC ? 1 : -1}
                    scrollable
                    emptyMessage="Нет доступных инструментов"
                    loading={isScreenerLoading}
                    style={{ visibility: isScreenerTableVisible }}
                    pt={{
                        root: { className: styles.table },
                        loadingOverlay: { className: styles.table_loadingOverlay },
                    }}
                >
                    <Column
                        body={isFavoriteBodyTemplate}
                        header={<ColumnsSettings screener={screener} />}
                        className={classNames(
                            styles.isFavouriteColumn,
                            !hasPermissionWatchlist && styles.isFavouriteColumn__disabled,
                        )}
                        frozen
                    />

                    {screenerColumns?.map(({ id, fieldName, name, primary }: ColumnType) => (
                        <Column
                            key={id}
                            field={fieldName}
                            header={name}
                            body={instrumentBodyTemplate}
                            sortable
                            frozen={primary}
                        />
                    ))}
                </DataTable>

                <div className="mt-3">
                    <PaginationController
                        total={totalPages}
                        currentPageCustomClass={styles.currentPage}
                        current={pageNumber - 1}
                        disabled={isScreenerLoading}
                        customClass={styles.customPagination}
                        setAsyncCurrent={handleChangeCurrentPage}
                        pageLinkSize={6}
                    />
                </div>
            </>
        );
    },
);
