import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const Calendar: FC<Props> = ({ width = 24, height = 24, color = 'currentColor' }) => (
    <svg
width={width} height={height} viewBox="0 0 24 24"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 10V17C4 18.1046 4.89543 19 6 19H18C19.1046 19 20 18.1046 20 17V10M4 10V7C4
            5.89543 4.89543 5 6 5H9M4 10H20M20 10V7C20 5.89543 19.1046 5 18 5H17M9 6.5V5M9
            3.5V5M15 3.5V5M15 6.5V5M11 5H15"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
        />
    </svg>
);
