import { useContext } from 'react';
import { AuthContext, type IAuthContext } from 'react-oauth2-code-pkce';
import { httpClient } from '@configs/httpclient.config';
import { type TPrimitiveRecord } from 'react-oauth2-code-pkce/dist/Types';

import { generateState } from '../utils';

export const IS_INVESTORPRO_REDIRECT_PARAMETER = 'isInvestorproRedirect';

export const getInvestorProLoginParams = (isAuthorized: boolean): string => {
    const isInvestorProRedirectParameter: string = `${IS_INVESTORPRO_REDIRECT_PARAMETER}=true`;
    const isInvestorProAuthorizedParameter: string = `isInvestorProAuthorized=${isAuthorized}`;

    return `${isInvestorProRedirectParameter}&${isInvestorProAuthorizedParameter}`;
};

export const useAuthorize = () => {
    const { token, tokenData, logIn, logOut, loginInProgress } = useContext<IAuthContext>(AuthContext);

    const loginHandler = (additionalParameters?: TPrimitiveRecord) => {
        if (additionalParameters && additionalParameters.isRegistration) {
            logIn(generateState({ isRegistration: true }));
        } else {
            logIn();
        }
    };

    const logoutHandler = () => {
        httpClient.defaults.headers.common['Authorization'] = '';
        logOut();
    };

    return {
        loginHandler,
        logoutHandler,
        isAuthorized: !loginInProgress && !!token,
        token: loginInProgress ? null : token,
        tokenData,
        loginInProgress,
    };
};
