import { Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { type KeyTools } from '@modules/Investorpro/modules/PortfolioPage/entities';

const columnHelper = createColumnHelper<KeyTools>();

export const useToolsTableColumns = (isInReport?: boolean) => useMemo(
        () => [
            columnHelper.accessor('name', {
                id: 'name',
                header: 'Показатель',
                enableSorting: false,
                cell: (props) => (
                    <Text
style={{ textAlign: 'start', whiteSpace: 'nowrap' }} fz="14px" lh="16px"
fs="normal">
                        {props?.getValue() ?? '–'}
                    </Text>
                ),
            }),
            columnHelper.accessor('ticker', {
                id: 'ticker',
                header: 'Тикер',
                enableSorting: false,
                cell: (props) => (
                    <Text style={{ textAlign: 'start' }} fz="14px">
                        {props?.getValue() ?? '–'}
                    </Text>
                ),
            }),
            columnHelper.accessor('isin', {
                id: 'isin',
                header: 'ISIN',
                enableSorting: false,
                cell: (props) => <Text fz="14px">{props?.getValue() ? props?.getValue() : '–'}</Text>,
            }),
            columnHelper.accessor('portfolioShare', {
                id: 'portfolioShare',
                header: 'Доля в портфеле',
                enableSorting: !isInReport,
                meta: { headerCellStyles: { justifyContent: 'end' } },
                cell: (props) => (
                    <Text style={{ textAlign: 'end' }} fz="14px">
                        {props
                            ?.getValue()
                            .toLocaleString('ru', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? '–'}
                        {'%'}
                    </Text>
                ),
            }),
            columnHelper.accessor('profitability', {
                id: 'profitability',
                header: 'Доходность',
                enableSorting: !isInReport,
                meta: { headerCellStyles: { justifyContent: 'end' } },
                cell: (props) => (
                    <Text
                        style={{ textAlign: 'end', color: Number(props?.getValue()) < 0 ? '#E42821' : '#26A65B' }}
                        fz="14px"
                    >
                        {props?.getValue() ? `${props?.getValue()}%` : '–'}
                    </Text>
                ),
            }),
            columnHelper.accessor('instrumentType', {
                id: 'instrumentType',
                header: 'Инструмент',
                meta: { headerCellStyles: { justifyContent: 'end' } },
                enableSorting: false,
                cell: (props) => (
                    <Text
                        fz="14px"
                        style={{
                            fontSize: '15px',
                            textAlign: 'start',
                        }}
                    >
                        {props?.getValue() ? props?.getValue() : '–'}
                    </Text>
                ),
            }),
        ],
        [isInReport],
    );
