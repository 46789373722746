import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { type CoreMetrics, coreMetrics } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { useApi } from '../../../api';

export const useGetCoreMetricsQuery = (
    portfolioId: string,
    enabled = true,
    onStatusChange?: (status: 'error' | 'success' | 'pending') => void,
) => {
    const api = useApi();

    const query = useQuery({
        queryKey: ['portfolio', portfolioId, 'general-info', 'core-metrics'],
        queryFn: async () => await api.portfolio.generalInfo.getCoreMetrics({ params: { id: portfolioId } }),
        enabled: Boolean(portfolioId) && enabled,
        select: (data) => (
            Object.entries(data).map(([key, value]) => ({
                name: coreMetrics[key as CoreMetrics].name,
                value,
                color: coreMetrics[key as CoreMetrics].color,
            }))
        ),
    });

    useEffect(() => {
        if (query.isLoading || query.isFetching) {
            onStatusChange?.('pending');
        }

        if (query.isError) {
            onStatusChange?.('error');
        }

        if (query.isSuccess && !query.isLoading && !query.isFetching) {
            onStatusChange?.('success');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isLoading, query.isError, query.isSuccess, query.isFetching, query.isPending]);

    return query;
};
