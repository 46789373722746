import { type FC } from 'react';

export const EnergySectorIcon: FC = () => (
    <svg
width="37" height="31" viewBox="0 0 37 31"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
            <path
                d="M10.7857 26.5H26.2143M14.6428 18.2386L17.2143 4.5M14.6428 18.2386H22.3571M14.6428
                18.2386L18.5 22.3465M14.6428 18.2386L12.0714 26.5H24.9286L22.3571 18.2386M17.2143
                4.5H19.7857M17.2143 4.5L15.6429 4.5M19.7857 4.5L22.3571 18.2386M19.7857 4.5L21.3571
                4.5M22.3571 18.2386L18.5 22.3465M18.5 22.3465L22.3571 26.5M18.5 22.3465L14.6429
                26.5M9.5 12.7614L14.6429 15.5H22.3571L27.5 12.7614M9.5 12.7614H27.5M9.5
                12.7614V14.5M27.5 12.7614L27.5 14.5M9.5 7.28423L15.9286 10.0228H21.0714L27.5 7.28423M9.5
                7.28423H27.5M9.5 7.28423V9.02282M27.5 7.28423L27.5 9.02282"
                stroke="#636E80"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
