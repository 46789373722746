import { getOneDayPeriod, getOneWeekPeriod, getOneYearPeriod, getStartYearDate } from '@libs/utils'
import { PeriodId, ProfitPeriod } from '@libs/types/instrument.type'
import {
    type GetActualQuotesDataResponse,
    type GetProfitForPeriodRequestResponse,
} from '@modules/Investorpro/types/quote.type'
import { ServerBondIndustry } from '@libs/types'

import {
    InstrumentType,
    StockCategoryOptionValue,
    type Option,
    LiquidityKeyType,
} from '../types'

export const BONDS_CATEGORY_OPTIONS: Array<Option<ServerBondIndustry>> = [
    { name: 'Государственные', code: ServerBondIndustry.GOV },
    { name: 'Корпоративные', code: ServerBondIndustry.CORP },
    { name: 'Муниципальные', code: ServerBondIndustry.MONI },
]

export const STOCK_CATEGORY_OPTIONS: Array<Option<StockCategoryOptionValue>> = [
    { name: 'Все акции', code: StockCategoryOptionValue.ALL_STOCKS },
    { name: 'Топ 100', code: StockCategoryOptionValue.TOP_100 },
    { name: 'Индекс Мос.Биржи', code: StockCategoryOptionValue.MOEX_INDEX },
]

export const INSTRUMENT_TYPE_OPTIONS: Array<Option<InstrumentType>> = [
    { name: 'Акции', code: InstrumentType.STOCKS },
    { name: 'Облигации', code: InstrumentType.BONDS },
]

export const LIQUIDITY_OPTIONS: Array<Option<LiquidityKeyType>> = [
    { name: '100 000 RUB', code: LiquidityKeyType.l100k },
    { name: '1 000 000 RUB', code: LiquidityKeyType.l1m },
    { name: '5 000 000 RUB', code: LiquidityKeyType.l5m },
    { name: '10 000 000 RUB', code: LiquidityKeyType.l10m },
    { name: '50 000 000 RUB', code: LiquidityKeyType.l50m },
    { name: '100 000 000 RUB', code: LiquidityKeyType.l100m },
]

export const QUOTE_OPTIONS = [
    { label: 'Купить', value: 'buy' },
    { label: 'Продать', value: 'sell' },
]

export const DEFAULT_MARKET_MAP_PERIODS_OPTIONS: Array<{ label: string; value: ProfitPeriod }> = [
    {
        value: ProfitPeriod.DAY,
        label: '1 день',
    },
    {
        label: '7 дней',
        value: ProfitPeriod.WEEK,
    },
    {
        label: '30 дней',
        value: ProfitPeriod.MONTH,
    },
    {
        label: '365 дней',
        value: ProfitPeriod.YEAR,
    },
    {
        label: 'YTD',
        value: ProfitPeriod.YTD,
    },
];


export const EMPTY_PRICE: GetProfitForPeriodRequestResponse['data'] = {
        y1d: 0,
        y1w: 0,
        y1m: 0,
        y3m: 0,
        y6m: 0,
        y1y: 0,
        y5y: 0,
        yytd: 0,
}

export const EMPTY_LIQUIDITY: GetActualQuotesDataResponse['data'] = {
    orderbook: {
        columns: [],
        data: [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ],
    },
}

export const PROFIT_LOADING_KEY = 'profit-loading';

export const LIQUIDITY_LOADING_KEY = 'liquidity-loading';

export const DATA_LOADING_KEY = 'data-loading';

export const NOT_FULL_LOADED_CALLBACK_KEY = 'no-full';

export const FULL_LOADED_CALLBACK_KEY = 'full-loaded';

export const TOTAL_LOADING_KEY = 'total-analytics-loading';
