import { type ApiEndpoint, type ApiFactory } from '../../../core/endpoint';

export const getLiquidity: ApiFactory<
    ApiEndpoint<{ value: number }, void, { id: string }>
> = (client) => async ({ signal, params: { id } }) => {
    const { data } = await client.get<{ value: number }>(
        `/moex-datashop-investor-pro-portfolios-service/api/v1/liquidity/${id}`,
        { signal },
    );

    return data;
};
