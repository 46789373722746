/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, LoadingOverlay, Table as MantineTable, ScrollArea, Stack } from '@mantine/core';
import {
    type CellContext,
    type ColumnDef,
    type HeaderContext,
    type OnChangeFn,
    type RowSelectionState,
    type SortingState,
    type TableState,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { type ReactNode, useCallback, useMemo, useState } from 'react';

import { TableFooterRow } from './TableFooterRow';
import { TableHeaderRow } from './TableHeaderRow';
import { TableRow } from './TableRow';

enum TableInternalColumnsIds {
    Selection = 'selection',
}

enum TableSortDirection {
    Asc = 'ASC',
    Desc = 'DESC',
    Default = '',
}

export type TableSort = {
    direction: TableSortDirection;
    field: string;
};

export type HoveredIds = {
    columnId: string | null;
    rowId: string | null;
};

type Props<TData extends Record<string, unknown>> = {
    columns: Array<ColumnDef<TData, any>>;
    data: TData[];
    onRowSelectionChange?: OnChangeFn<RowSelectionState>;
    onSortingChange?: OnChangeFn<SortingState>;
    rowSelection?: RowSelectionState;
    sorting?: SortingState;
    isDataLoading?: boolean;
    header?: ReactNode;
    footer?: ReactNode;
    striped?: boolean;
    highlightOnHover?: boolean;
    highlightColumnOnHover?: boolean;
    className?: string;
    emptyStub?: ReactNode;
    isFooterVisible?: boolean;
};

export const Table = <TData extends Record<string, unknown>>({
    columns,
    data,
    onRowSelectionChange,
    onSortingChange,
    rowSelection,
    sorting,
    isDataLoading,
    header,
    footer,
    striped,
    highlightColumnOnHover,
    className,
    emptyStub,
    isFooterVisible,
}: Props<TData>) => {
    const configureColumns = useCallback(() => {
        if (rowSelection && onRowSelectionChange) {
            return [
                {
                    id: TableInternalColumnsIds.Selection,
                    header: ({
                        table: { getIsAllRowsSelected, getIsSomeRowsSelected, getToggleAllRowsSelectedHandler },
                    }: HeaderContext<any, any>) => (
                        <Checkbox
                            checked={getIsAllRowsSelected()}
                            indeterminate={getIsSomeRowsSelected()}
                            onChange={getToggleAllRowsSelectedHandler()}
                        />
                    ),
                    size: 40,
                    cell: (cell: CellContext<any, any>) => (
                        <Checkbox checked={cell.row.getIsSelected()} onChange={cell.row.getToggleSelectedHandler()} />
                    ),
                },
                ...columns,
            ];
        }

        return columns;
    }, [rowSelection, onRowSelectionChange, columns]);

    const state = useMemo<Partial<TableState>>(
        () => ({
            sorting,
            rowSelection,
        }),
        [sorting, rowSelection],
    );

    const { getHeaderGroups, getRowModel, getFooterGroups } = useReactTable({
        data,
        columns: configureColumns(),
        manualSorting: true,
        manualPagination: true,
        enableMultiRowSelection: true,
        manualFiltering: true,
        getRowId: ({ id }, index) => String(id || index),
        state,
        onSortingChange,
        onRowSelectionChange,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        sortDescFirst: false,
    });

    const [hoveredIds, setHoveredIds] = useState<HoveredIds>({ columnId: null, rowId: null });

    return (
        <Stack className={className} gap={0}>
            {header}

            <ScrollArea style={{ overflow: 'auto', position: 'relative', minHeight: isDataLoading ? 200 : undefined }}>
                <LoadingOverlay visible={Boolean(isDataLoading)} zIndex={99} />
                <MantineTable
striped={striped} verticalSpacing="9px" withRowBorders={true}
withColumnBorders={true}>
                    {!isDataLoading && getRowModel().rows.length === 0
? null
: (
                        <MantineTable.Thead>
                            {getHeaderGroups().map((headerGroup) => (
                                <TableHeaderRow headerGroup={headerGroup} key={headerGroup.id} />
                            ))}
                        </MantineTable.Thead>
                    )}
                    <MantineTable.Tbody>
                        {getRowModel().rows.map((row) => (
                            <TableRow
                                key={row.id}
                                row={row}
                                selected={Object.keys(rowSelection ?? {}).includes(row.id)}
                                onCellMouseEnter={setHoveredIds}
                                onCellMouseLeave={() => setHoveredIds({ columnId: null, rowId: null })}
                                hoveredIds={hoveredIds}
                                highlightColumnOnHover={highlightColumnOnHover}
                            />
                        ))}
                    </MantineTable.Tbody>
                    {isFooterVisible
? (
                        <MantineTable.Tfoot>
                            {getFooterGroups().map((footerGroup) => (
                                <TableFooterRow footerGroup={footerGroup} key={footerGroup.id} />
                            ))}
                        </MantineTable.Tfoot>
                    )
: null}
                </MantineTable>
            </ScrollArea>

            {!isDataLoading && getRowModel().rows.length === 0 ? emptyStub : null}

            {footer}
        </Stack>
    );
};
