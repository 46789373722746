import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { type AssetsName, assetsNames } from '@modules/Investorpro/modules/PortfolioPage/entities';

import { useApi } from '../../../../api';

export const useGetAssetsBehaviorQuery = (
    portfolioId: string,
    scenarioUuid: string,
    enabled = true,
    onStatusChange?: (status: 'error' | 'success' | 'pending') => void,
) => {
    const api = useApi();

    const query = useQuery({
        queryKey: ['portfolio', portfolioId, 'stress-tests', 'assets-behavior', scenarioUuid],
        queryFn: async () => await api.portfolio.stressTests.getAssetsBehavior({
            params: {
                portfolioId,
                scenarioUuid,
            },
        }),
        select: (data) => Object.entries(data).map(([key, value]) => ({
            name: assetsNames[key as AssetsName].name,
            value,
        })),
        enabled: Boolean(portfolioId) && Boolean(scenarioUuid) && enabled,
    });

    useEffect(() => {
        if (query.isLoading || query.isFetching) {
            onStatusChange?.('pending');
        }

        if (query.isError) {
            onStatusChange?.('error');
        }

        if (query.isSuccess && !query.isLoading && !query.isFetching) {
            onStatusChange?.('success');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isLoading, query.isError, query.isSuccess, query.isFetching, query.isPending]);

    return query;
};
