import { StockBondHeaderWrapper } from '@modules/Investorpro/modules/BondStockPage/shared/components/StockBondHeaderWrapper';
import { ValueWithMeasure } from '@modules/Investorpro/modules/BondStockPage/shared/components/ValueWithMeasure';
import { type AppDispatch, type RootState } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
    getBondDataThunk,
    getBondReferenceDataThunk,
    getComponyLogoThunk,
} from '@store/store/thunk/investorpro/bond.thunk';
import { resetBondPageData } from '@store/store/slices/investorpro/bond.slice';
import { useAuthorize } from '@libs/utils';
import { formatPrice } from '@modules/Investorpro/shared/utils/format.util';
import { useLoading } from '@libs/utils/hooks/useLoading';

import styles from './styles.module.scss';
import { GET_BOND_DATA_LOADING_KEY, GET_BOND_REFERENCE_DATA_LOADING_KEY } from '../../shared/constants';

export const BondHeader = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { secId } = useParams();
    const formattedSecId = secId?.toUpperCase();
    const { startLoading, stopLoading } = useLoading();

    const { isAuthorized } = useAuthorize();

    useEffect(() => {
        if (formattedSecId && isAuthorized) {
            startLoading(GET_BOND_DATA_LOADING_KEY);
            startLoading(GET_BOND_REFERENCE_DATA_LOADING_KEY);
            dispatch(getBondDataThunk(formattedSecId)).finally(() => {
                stopLoading(GET_BOND_DATA_LOADING_KEY);
            });
            dispatch(getBondReferenceDataThunk(formattedSecId)).finally(() => {
                stopLoading(GET_BOND_REFERENCE_DATA_LOADING_KEY);
            });
        }

        return () => {
            dispatch(resetBondPageData());
        };
    }, [formattedSecId, isAuthorized]);

    const { info, fininstId } = useSelector((state: RootState) => state.bond);

    useEffect(() => {
        if (fininstId) {
            dispatch(getComponyLogoThunk(fininstId));
        }
    }, [fininstId, dispatch]);

    return (
        <StockBondHeaderWrapper
            title={info?.name}
            logo={info?.logo}
            underTitle={<span>{info?.companyName}</span>}
            currentValue={info?.currentValue}
            currentValueMeasure={'%'}
            changedAt={info?.updatedAt}
            changedAbsolute={info?.change?.absolute}
            changedRelative={info?.change?.relative}
            addToFavoritesCode={secId}
            columns={[
                { title: 'ISIN', value: formattedSecId, className: styles.isin },
                {
                    title: 'Номинал',
                    value: (
                        <ValueWithMeasure
                            value={info?.nominalValue}
                            measureCLassName={styles.measure}
                            measure={'RUB'}
                            roundStart="none"
                            hideDecimals={true}
                        />
                    ),
                },
                { title: 'Статус', value: info?.status },
                {
                    title: 'Дюрация',
                    value: info?.duration ? formatPrice(info?.duration) : null,
                    className: styles.duration,
                },
                {
                    title: 'Объем торгов за день',
                    value: (
                        <ValueWithMeasure
                            value={info?.dailyTradingVolume}
                            measureCLassName={styles.measure}
                            measure={'RUB'}
                        />
                    ),
                },
                {
                    title: 'Доходность по последней сделке',
                    value: <ValueWithMeasure value={info?.lastDealProfitability} isPercent />,
                },
            ]}
        />
    );
};
