import { ReactComponent as HeaderLogoIcon } from '@shared/images/svg/HeaderLogo.svg';
// import { ReactComponent as BurgerMenuIcon } from '@shared/images/svg/BurgerMenu.svg';
import { ReactComponent as CrossIcon } from '@shared/images/svg/CrossIcon.svg';
import { ReactComponent as UserAvatar } from '@shared/images/svg/UserAvatar.svg';
import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { useAuthorize, useResize } from '@libs/utils';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, avatarDownloadThunk, getUserThunk, type RootState } from '@store/store';

import { DropdownUserPanel } from './DropdownUserPanel';
import { AvatarButton } from './AvatarButton';
import { BurgerMenu } from './BurgerMenu';
import { Search } from './Search';
import styles from './styles.module.scss';

const navigations = [
    {
        text: 'Продукты',
        link: '/products',
    },
    // {
    //     text: 'Статьи',
    //     link: '/articles',
    // },
    // {
    //     text: 'Партнерам',
    //     link: '',
    // },
    // {
    //     text: 'Справочный центр',
    //     link: '/help-center',
    // },
];

type NavigationProps = {
    children?: ReactNode;
    className?: string;
    isHideIconsAlways?: boolean;
    isDesktopOnly?: boolean;
};

export const Navigation = ({ children, className, isHideIconsAlways, isDesktopOnly }: NavigationProps) => {
    const { loginHandler, isAuthorized, logoutHandler } = useAuthorize();
    const imageAvatarSelector = useSelector((state: RootState) => state.user.userAvatar);
    const userSelector = useSelector((state: RootState) => state.user.user);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        (async () => {
            if (isAuthorized) {
                try {
                    await dispatch(getUserThunk());
                } catch (e) {
                    console.error(e);
                }
            }
        })();
    }, [isAuthorized]);

    useEffect(() => {
        (async () => {
            if (userSelector?.photoUrl && isAuthorized) {
                try {
                    await dispatch(
                        avatarDownloadThunk({
                            bucket: userSelector.photoUrl.bucket,
                            ticket: userSelector.photoUrl.fileId,
                        }),
                    );
                } catch (e) {
                    console.error(e);
                }
            }
        })();
    }, [userSelector, isAuthorized]);

    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const [isHideIcons, setIsHideIcons] = useState<boolean>(false);
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

    const { isScreenXl, isScreenSm, width } = useResize();
    const op = useRef(null);

    // if screen wider than isScreenXl should close burger menu
    useEffect(() => {
        if (isScreenXl) {
            setMenuOpen(false);
        }
    }, [isScreenXl]);

    useEffect(() => {
        const res = isSearchOpen && isScreenSm;
        setIsHideIcons(res);
        setScroll(!res);
    }, [isSearchOpen, isScreenSm]);

    const toggleMenuCallback = useCallback(() => {
        setScroll(isMenuOpen);
        setMenuOpen((prev) => !prev);
    }, [isMenuOpen]);

    const setScroll = (showScroll: boolean) => {
        if (showScroll) {
            document.body.style.overflowY = 'auto';
        } else {
            document.body.style.overflowY = 'hidden';
        }
    };

    return (
        <div className={classNames(styles.navigationWrapper, className)}>
            <div className={styles.navigationPanelWrapper}>
                {children || (
                    <div className={styles.navigationLInksBlock}>
                        {!isHideIcons && (
                            <Link to={'/'}>
                                <HeaderLogoIcon />
                            </Link>
                        )}
                        <nav className={classNames(isSearchOpen ? styles.hideNavigationList : undefined)}>
                            <ul>
                                {navigations.map(({ link, text }, index) => link
? (
                                        <Link key={link + text} to={link} className={classNames(styles.navigationItem)}>
                                            {text}
                                        </Link>
                                    )
: (
                                        <li key={index} className={classNames(styles.navigationItem)}>
                                            {text}
                                        </li>
                                    ),
                                )}
                            </ul>
                        </nav>
                    </div>
                )}

                <div className={styles.navigationIcons}>
                    <div style={{ display: 'none' }}>
                        {' '}
                        {/* todo: removed from april release */}
                        <Search isOpen={isSearchOpen} setIsOpen={(value: boolean) => setIsSearchOpen(value)} />
                    </div>

                    {/* <RingIcon cursor={'pointer'} /> */}
                    {!isHideIcons && !isDesktopOnly && (
                        <>
                            {/* todo: removed from april release */}
                            {/*                            {isAuthorized && (
                                <>
                                    {width > 1359 && (
                                        <Link to={'/cart'}>{cartCount ? <ShoppingCartRed /> : <ShoppingCart />}</Link>
                                    )}
                                </>
                            )} */}

                            {isMenuOpen
? (
                                <CrossIcon className={styles.burgerMenuButton} onClick={toggleMenuCallback} />
                            )
: (
                                <UserAvatar className={styles.burgerMenuButton} onClick={toggleMenuCallback} />
                            )}
                        </>
                    )}
                </div>

                {!isHideIconsAlways && (
                    <>
                        <div className={styles.divider} />
                        <div className={styles.navigationButtons}>
                            {!isAuthorized
? (
                                <>
                                    <Button
                                        className={styles.whiteBtn}
                                        label={'Регистрация'}
                                        onClick={() => loginHandler({ isRegistration: true })}
                                    />
                                    <Button className={styles.redBtn} label={'Вход'} onClick={() => loginHandler()} />
                                </>
                            )
: (
                                <>
                                    <AvatarButton
                                        img={imageAvatarSelector}
                                        avatarSize={36}
                                        onClick={(e: any) => (op.current as any).toggle(e)}
                                    />
                                    <OverlayPanel className={styles.overlayUserPanel} ref={op}>
                                        <DropdownUserPanel />
                                    </OverlayPanel>
                                </>
                            )}
                        </div>
                        <hr />
                        {isMenuOpen && (
                            <BurgerMenu
                                loginHandler={loginHandler}
                                logoutHandler={logoutHandler}
                                isAuthorized={isAuthorized}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
