import { Navigation } from '@libs/components';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
// import { Header } from '@modules/Investorpro/shared/components/Header';
import { Box } from '@mantine/core';

// import { Footer } from '../../shared/components/Footer';
import { Portfolios } from './components';
import { Providers } from './providers';
import styles from './styles.module.scss';

export const PortfolioPage = () => {
    return (
        <Providers>
            {/* <Navigation className={styles.navigation}> */}
            {/* <Header /> */}
            {/* </Navigation> */}
            <Box className={styles.pageWrapper}>
                <Portfolios />
            </Box>
            {/* <Footer /> */}
        </Providers>
    );
};
