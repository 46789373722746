import { type FC } from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

export const ExclamationCircle: FC<Props> = ({ width = 24, height = 24, color = 'white' }) => (
    <svg
xmlns="http://www.w3.org/2000/svg" width={width} height={height}
viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228
            2 22 6.47715 22 12ZM12 7C11.4477 7 11 7.40148 11 7.89673V13.0815C11 13.5767 11.4477 13.9782 12
            13.9782C12.5523 13.9782 13 13.5767 13 13.0815V7.89673C13 7.40148 12.5523 7 12 7ZM12 15.1738C11.4477
            15.1738 11 15.5753 11 16.0706V16.1033C11 16.5985 11.4477 17 12 17C12.5523 17 13 16.5985 13
            16.1033V16.0706C13 15.5753 12.5523 15.1738 12 15.1738Z"
            fill={color}
        />
    </svg>
);
