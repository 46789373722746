import {
    type ColumnType,
    type FilterType,
    FilterTypes,
    type ScreenerColumnType,
    type ScreenerFilterValueType,
    ScreenerType,
    ValueTypes,
} from '../../types';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@libs/utils';
import { IFiltersFormData } from '@modules/Investorpro/modules/ScreenersPage/components/NewScreenerModal';

const defaultColumn: Omit<ColumnType, 'id' | 'fieldName'> = {
    name: '—',
    groupId: [],
    colored: false,
    percentaged: false,
    showCurrency: false,
    primary: false,
    reducedToMil: false,
};

export const getDefaultColumn = (columnId: ScreenerColumnType['id']) => {
    return {
        ...defaultColumn,
        id: columnId,
        fieldName: columnId.toString(),
    };
};

export const declension = (count: number, word = 'инструмент') => {
    const stringCount: string = count?.toString() ?? '';
    const lastDigit: number = parseInt(stringCount[stringCount.length - 1]);

    if (lastDigit === 1) {
        return word;
    }

    if ([2, 3, 4].includes(lastDigit)) {
        return `${word}а`;
    }

    return `${word}ов`;
};

export const filterIds = <T extends Record<string, unknown> & { id: number; order?: number }, K>(
    array: T[],
    compareValue?: K,
    fieldName: keyof T = 'id',
): number[] => {
    if (!compareValue) {
        return [];
    }

    const filteredArray = array.filter((item) => {
        const preparedFieldValue: K[] = Array.isArray(item[fieldName])
            ? (item[fieldName] as K[])
            : [item[fieldName] as K];

        return preparedFieldValue.includes(compareValue);
    });

    if (filteredArray.some(({ order }) => order)) {
        filteredArray.sort(({ order: orderA }, { order: orderB }) => (orderA ?? 0) - (orderB ?? 0));
    }

    return filteredArray.map(({ id }) => id);
};

export function prepareCompliance<T extends { id: number }>(array: T[]): Record<number, T> {
    return array.reduce((acc: Record<number, T>, item) => {
        acc[item.id] = item;

        return acc;
    }, {});
}

export const getScreenerFilterValue = (
    { id, type, valueType, minValue, maxValue }: FilterType,
    { id: screenerId, value, values, startValue, endValue }: ScreenerFilterValueType,
): ScreenerFilterValueType => {
    if (id !== screenerId) {
        return { id: screenerId };
    }

    switch (type) {
        case FilterTypes.RANGE:
            switch (valueType) {
                case ValueTypes.DATE:
                case ValueTypes.TIME:
                case ValueTypes.DATE_TIME:
                    // eslint-disable-next-line no-case-declarations
                    const startScreenerValue: Date | null =
                        startValue || minValue ? new Date(startValue ?? minValue ?? '') : null;
                    // eslint-disable-next-line no-case-declarations
                    const endScreenerValue: Date | null =
                        startValue || minValue ? new Date(endValue ?? maxValue ?? '') : null;
                    return {
                        id,
                        dates: [startScreenerValue, endScreenerValue],
                    };
                case ValueTypes.STRING:
                case ValueTypes.NUMBER:
                default:
                    return {
                        id,
                        startValue: startValue ?? '',
                        endValue: endValue ?? '',
                    };
            }
        case FilterTypes.SINGLE_SELECT:
        case FilterTypes.MULTIPLE_SELECT:
            return {
                id,
                values: values ? [...values] : [],
            };
        case FilterTypes.INPUT:
        default:
            return {
                id,
                value: value ?? '',
            };
    }
};

export const getFiltersPayload = (
    preparedFilters: Record<FilterType['id'], FilterType>,
    checkedFiltersValues?: IFiltersFormData[],
): ScreenerType['filters'] =>
    checkedFiltersValues!.reduce(
        (acc: ScreenerFilterValueType[], { filterId, value, values, startValue, endValue, dates }) => {
            const isDateType: boolean = [ValueTypes.DATE].includes(preparedFilters[filterId].valueType);
            const isTimeType: boolean = [ValueTypes.DATE_TIME, ValueTypes.TIME].includes(
                preparedFilters[filterId].valueType,
            );
            const isDateTimeType: boolean = isDateType || isTimeType;

            const updatedDates: Array<string | null> | undefined = dates?.map((date) => {
                if (isDateType && date) {
                    return format(date, DATE_FORMAT);
                }

                if (isTimeType && date) {
                    return date.toISOString();
                }

                return null;
            });

            const updatedStartValue: string | null | undefined = isDateTimeType ? updatedDates?.[0] : startValue;
            const updatedEndValue: string | null | undefined = isDateTimeType ? updatedDates?.[1] : endValue;

            if (value || values?.length || updatedStartValue || updatedEndValue) {
                acc.push({
                    id: filterId,
                    values: values?.length ? values : undefined,
                    value,
                    startValue: updatedStartValue,
                    endValue: updatedEndValue,
                });
            }

            return acc;
        },
        [],
    );
