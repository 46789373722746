import { Group, Stack, Text } from '@mantine/core';
import { type FC } from 'react';
import { useParams } from 'react-router-dom';

import { type InferParams } from '../../../shared/router';
import { CoreMetricsChart } from './CoreMetricsChart';
import { KeyStatisticsTable } from './KeyStatisticsTable/KeyStatisticsTable';
import { Profit } from './Profit/Profit';
import { DiversificationScale } from './Scales/DiversificationScale';
import { InvestmentScale } from './Scales/InvestmentScale';
import { useGetDiversificationQuery } from './Scales/useGetDiversificationQuery';
import { useGetInvestmentProfileQuery } from './Scales/useGetInvestmentProfileQuery';
import { ToolsTable } from './ToolsTable/ToolsTable';
import { useGetCoreMetricsQuery } from './useGetCoreMetricsQuery';
import { useGetPortfolioCostQuery } from './useGetPortfolioCostQuery';

export const GeneralInfo: FC = () => {
    const { portfolioId } = useParams() as InferParams<':portfolioId/*'>;
    const portfolioCostQuery = useGetPortfolioCostQuery(portfolioId);
    const coreMetricsQuery = useGetCoreMetricsQuery(portfolioId);
    const investmentProfile = useGetInvestmentProfileQuery(portfolioId);
    const diversification = useGetDiversificationQuery(portfolioId);

    return (
        <Group wrap="nowrap" align="start" justify="space-between">
            <Stack style={{ maxWidth: '50%' }}>
                <Text fz={19} fw={400}>
                    {'Основные метрики по портфелю'}
                </Text>
                <Group gap={40}>
                    <CoreMetricsChart portfolioCostQuery={portfolioCostQuery} coreMetricsQuery={coreMetricsQuery} />
                    <Profit portfolioId={portfolioId} />
                </Group>
                <DiversificationScale diversification={diversification} />
                <InvestmentScale investmentProfile={investmentProfile} />
            </Stack>
            <Stack gap={64} style={{ maxWidth: '50%' }}>
                <KeyStatisticsTable portfolioId={portfolioId} />
                <ToolsTable portfolioId={portfolioId} />
            </Stack>
        </Group>
    );
};
