import { Fragment, type FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
    CashFlow,
    Diversification,
    Dynamics,
    GeneralInfo,
    HistoricReturns,
    Liquidity,
    Performance,
    ReportLayout,
    RiskMetrics,
    StressTests,
    TypologyAssets,
} from '../components/PortfolioTabs';
import { RouterNavigate } from '../shared/router';
import { CreatePortfolio } from './CreatePortfolio';
import { PatchModal } from './PatchModal';
import { PortfolioLayout } from './PortfolioLayout';
import { PortfolioList } from './PortfolioList';

export const Portfolios: FC = () => (
    <Fragment>
        <Routes>
            <Route path="/" Component={PortfolioList} />
            <Route path="create" Component={CreatePortfolio} />
            <Route path=":portfolioId/*" Component={PortfolioLayout}>
                <Route path="general-info" Component={GeneralInfo} />
                <Route path="dynamics" Component={Dynamics} />
                <Route path="historic-returns" Component={HistoricReturns} />
                <Route path="performance" Component={Performance} />
                <Route path="risk-metrics" Component={RiskMetrics} />
                <Route path="stress-tests" Component={StressTests} />
                <Route path="diversification" Component={Diversification} />
                <Route path="typology-assets" Component={TypologyAssets} />
                <Route path="liquidity" Component={Liquidity} />
                <Route path="cash-flow" Component={CashFlow} />
                <Route path="report" Component={ReportLayout} />
                <Route path="*" element={<RouterNavigate to="general-info" replace={true} />} />
            </Route>
        </Routes>
        <PatchModal />
    </Fragment>
);
