import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ReactComponent as Arrow } from '@shared/images/svg/ArrowDownGrey.svg';
import { type AppDispatch, type RootState } from '@store/store';
import { useLocalStorage } from '@uidotdev/usehooks';
import { forwardRef, useEffect } from 'react';
import { setHelplineMessage, setHelplineOpened } from '@store/store/slices/investorpro';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const HelpLine = forwardRef<HTMLDivElement, unknown>((_, ref) => {
    const dispatch = useDispatch<AppDispatch>();
    const { message, defaultMessage } = useSelector((state: RootState) => state.helpline);
    const [isOpened, setIsOpened] = useLocalStorage('helpline', false);

    useEffect(() => {
        dispatch(setHelplineOpened(isOpened));

        if (isOpened) {
            dispatch(setHelplineMessage(defaultMessage));
        }
    }, [dispatch, isOpened, defaultMessage]);

    const toggleHelplineOpened = () => setIsOpened(!isOpened);

    const { t } = useTranslation();

    return (
        <div
            onClick={toggleHelplineOpened}
            ref={ref}
            className={classNames(
                styles.helpline,
                'flex',
                'align-items-center',
                'justify-content-center',
                isOpened && styles.helpline__active,
            )}
        >
            <div className={classNames('flex', 'align-items-center', 'justify-content-between', styles.contentWrapper)}>
                <div className={classNames('flex', 'align-items-center', styles.content)}>
                    <span
                        className={classNames(
                            'flex, justify-content-center align-item-center, text-center',
                            styles.infoIcon,
                        )}
                    >
                        ?
                    </span>
                    <p>{message ? t(message) : t(defaultMessage)}</p>
                </div>
                <Arrow className={styles.openIcon} />
            </div>
        </div>
    );
});
