import { Group, Stack, Text } from '@mantine/core';
import { type DateValue } from '@mantine/dates';
import dayjs from 'dayjs';
import { type FC, useEffect, useState } from 'react';

import {
    type RiskProfile,
    type ScenarioIndicatorType,
    type SimulationData,
    type StressScenarios,
} from '../../../../entities/portfolio';
import { AssetsBehavior } from '../../StressTests/AssetBehavior/AssetsBehavior';
import { RiskProfileScale } from '../../StressTests/RiskProfileScale/RiskProfileScale';
import { ScenarioIndicatorTable } from '../../StressTests/ScenarioIndicatorTable/ScenarioIndicatorTable';
import { SimulationChart } from '../../StressTests/SimulationChart/SimulationChart';
import { CalendarIcon } from '../ReportIcons/CalendarIcon';
import { InfoIcon } from '../ReportIcons/InfoIcon';

type Props = {
    assetsBehavior: Array<{
        name: string;
        value: number;
    }>;
    scenarioIndicator: ScenarioIndicatorType[];
    simulationData: SimulationData;
    riskProfile: RiskProfile;
    scenarios: StressScenarios;
};

export const ReportStressTestsScenarios: FC<Props> = ({
    assetsBehavior,
    scenarios,
    scenarioIndicator,
    simulationData,
    riskProfile,
}) => {
    const [dates, setDates] = useState<[DateValue, DateValue]>([null, null]);

    useEffect(() => {
        setDates([new Date(scenarios.content[0]?.dateFrom), new Date(scenarios.content[0]?.dateTill)]);
    }, [scenarios]);

    return (
        <Stack gap={20} w={1350} p={5}>
            <Stack
w="100%" bg="#EB2F2F1A" h="fit-content"
p={30} pt={20} gap={30}
style={{ borderRadius: '12px' }}>
                <Group align="center" justify="space-between">
                    <Text
fz="45px" fw={400} lh="47px"
style={{ whiteSpace: 'pre-wrap' }}>
                        {'Сценарии стресс-тестирования '}
                    </Text>
                    <Group
                        gap={12}
                        miw={180}
                        mih={24}
                        px={16}
                        align="center"
                        py={8}
                        style={{ border: '1px solid #14171F', borderRadius: '100px' }}
                    >
                        <InfoIcon />
                        <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px">
                            {'Информация'}
                        </Text>
                    </Group>
                </Group>
                <Text
fz={18} fw={400} ff="FavoritPro"
lh="20px" style={{ whiteSpace: 'pre-wrap' }}>
                    {
                        'Стресс-тесты — это набор заранее подготовленных нами “Что если…” сценариев, \
которые помогут оценить потенциальные убытки по портфелю, если похожие сценарии \
повторятся в будущем. Зная результаты стресс-тестов, вы понимаете, к чему нужно \
быть готовым.\n\nЕсли результаты стресс-тестов вас не устраивают, то вы можете \
изменить набор инструментов в своем портфеле, чтобы \
сделать его более консервативным или агрессивным.'
                    }
                </Text>
            </Stack>
            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Group align="center" justify="space-between">
                    <Text fz={36} fw={400} lh="38px">
                        {'Результат симуляции сценария'}
                    </Text>
                    <Group gap={14} align="center">
                        <Text fz={20} fw={400} lh="22px">
                            {dayjs(dates[0]).format('DD.MM.YYYY')}
                            {'-'}
                            {dayjs(dates[1]).format('DD.MM.YYYY')}
                        </Text>
                        <CalendarIcon />
                    </Group>
                </Group>
                <SimulationChart isInReport={true} data={simulationData.performance || []} />
            </Stack>

            <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                <Text fz={36} fw={400} lh="38px">
                    {'Показатели сценария'}
                </Text>
                <ScenarioIndicatorTable isFromReport={true} dates={dates} scenarioIndicator={scenarioIndicator ?? []} />
            </Stack>
            <Group
w="100%" wrap="nowrap" align="start"
justify="space-between">
                <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                    <Text fz={36} fw={400} lh="38px">
                        {'Поведение активов в портфеле в выбранном сценарии'}
                    </Text>
                    <AssetsBehavior assetsBehavior={assetsBehavior ?? []} isInReport={true} />
                </Stack>
                <Stack
w="100%" h="fit-content" p={30}
pt={20} gap={25}>
                    <Text fz={36} fw={400} lh="38px">
                        {'Риск профиль портфеля в заданном сценарии'}
                    </Text>
                    <RiskProfileScale riskProfile={riskProfile?.value ?? 0} isInReport={true} />
                </Stack>
            </Group>
        </Stack>
    );
};
