import classNames from 'classnames';
import { type TSecurity } from '@libs/types';
import { MIN_SEARCH_VALUE_LENGTH } from '@modules/Investorpro/shared/components/Header/components/Search/constants';
import { HelpLineWrapper } from '@libs/components';
import { EmptyContent } from '@modules/Investorpro/shared/components/Header/components/EmptyContent';

import styles from './styles.module.scss';
import { SearchSecurityItem } from '../SearchItem';
import { Tabs } from '../../../Tabs';

type Props = {
    isLoading: boolean;
    isModalSearch?: boolean;
    selectedWatchlistId?: number;
    securityList: TSecurity[];
    handleItemClick?: (item: TSecurity) => void;
    className?: string;
    withFavouriteDropdown?: boolean;
    searchValue: string;
    activeIndex: number;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    isServiceUnavailable?: boolean;
};

export const SearchSecurityDropDown = ({
    isLoading,
    isModalSearch = false,
    selectedWatchlistId,
    securityList,
    handleItemClick,
    withFavouriteDropdown = false,
    className,
    searchValue,
    activeIndex,
    setActiveIndex,
    isServiceUnavailable = false,
}: Props) => {
    const mapSecuirityList = (list: TSecurity[]) => {
        if (list.length) {
            return list.map((item, index) => (
                <SearchSecurityItem
                    key={`${item.isin}-${index}`}
                    withFavouriteDropdown={withFavouriteDropdown}
                    handleClick={() => handleItemClick?.(item)}
                    item={item}
                    isModalSearch={isModalSearch}
                    selectedWatchlistId={selectedWatchlistId}
                />
            ));
        }

        if (isServiceUnavailable) {
            return (
                <EmptyContent isModalSearch={isModalSearch} isLoading={isLoading}>
                    <>
                        <p className={classNames(styles.empty_text)}>Проблемы доступности сервиса.</p>
                        <p className={classNames(styles.empty_text)}>Попробуйте выполнить запрос снова</p>
                        <p className={classNames(styles.empty_text)}>или обратитесь в тех.поддержку</p>
                    </>
                </EmptyContent>
            );
        }

        if (searchValue?.length > MIN_SEARCH_VALUE_LENGTH) {
            return (
                <EmptyContent isModalSearch={isModalSearch} isLoading={isLoading}>
                    <>
                        <p className={classNames(styles.empty_text)}>Поиск не дал результатов.</p>
                        <p className={classNames(styles.empty_text)}>Попробуйте изменить свой запрос.</p>
                    </>
                </EmptyContent>
            );
        }

        return (
            <EmptyContent isModalSearch={isModalSearch} isLoading={isLoading} icon="pi pi-search">
                <p className={classNames(styles.empty_text)}>Поиск по тикеру или названию</p>
            </EmptyContent>
        );
    };

    const tabs = {
        Все: mapSecuirityList(securityList),
        Акции: mapSecuirityList(securityList),
        Облигации: mapSecuirityList(securityList),
    };

    return (
        <div className={classNames(styles.menuWrapper, isModalSearch && styles.isModal, className)}>
            <div className={classNames(styles.menuWrapper_content)}>
                <HelpLineWrapper message="helpline.search.requiredAsset">
                    <Tabs
                        tabs={tabs}
                        handleTabChange={setActiveIndex}
                        current={activeIndex}
                        className={styles.tabView}
                    />
                </HelpLineWrapper>
            </div>
        </div>
    );
};
