import { SecurityMarketType, type StockDividend } from '@libs/types';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '@store/store';
import { getStockDividends } from '@modules/Investorpro/modules/BondStockPage/StockPage/services/stock.service';
import { CandleDataColumnType, InstrumentEngineType } from '@modules/Investorpro/types/quote.type';
import { getCandlesData } from '@shared/services/quote.service';
import { IntervalType, type NotNullable, SortingDirections } from '@libs/types/instrument.type';
import { round } from 'lodash';
import { getIndexForInstrument } from '@libs/services/instrument.service';
import { HelpLineWrapper } from '@libs/components';
import { classNames } from 'primereact/utils';
import { isAfter } from 'date-fns';
import { SecurityBoards } from '@modules/Investorpro/modules/MarketPages/shared/constants';
import {
    getArrowPosByDatePeriod,
    refetchDataUntilFindNotEmpty,
} from '@modules/Investorpro/modules/MarketPages/shared/utils/fetch.utils';
import { CustomTable } from '@modules/Investorpro/shared/components/CustomTable';
import { useLoading } from '@libs/utils/hooks/useLoading';

import styles from './styles.module.scss';
import { TOP_STOCK_BY_DIVIDENDS_COLUMNS } from './columns';
import { useGetCurrentPrice } from '../../utils/useGetCurrentPrice';

export const Dividends = () => {
    const [dividends, setDividends] = useState<StockDividend[]>([]);
    const dispatch = useAppDispatch();
    const { startLoading, stopLoading } = useLoading('stock-market-get-dividends-data');

    useEffect(() => {
        const getDividendsRequest = async () => {
            try {
                startLoading();
                const dividendsResponse = await getStockDividends({
                    sortFields: 'declaredPayDate',
                    sortingDirection: SortingDirections.ASC,
                    pageNumber: 0,
                    pageSize: 8,
                });

                const uniqueFintoolIds = Array.from(
                    new Set(dividendsResponse.data.rows.map(({ fintoolId }) => fintoolId)),
                );

                const tickersResponse = await Promise.allSettled(
                    uniqueFintoolIds.map(
                        async (fintoolId) => await getIndexForInstrument({
                                fintoolId,
                                toolSelection: SecurityMarketType.STOCK,
                            }),
                    ),
                );

                const pricesResponse = await Promise.allSettled(
                    dividendsResponse.data.rows.map(async ({ fintoolId, declaredPayDate }) => {
                        const ticker = tickersResponse.find((response) => {
                            if (response.status === 'fulfilled') {
                                return response.value.data[0]?.fintoolId === fintoolId;
                            }

                            return false;
                        });

                        if (!ticker || !declaredPayDate) return null;

                        if (isAfter(new Date(declaredPayDate), new Date())) {
                            const [candlse] = await refetchDataUntilFindNotEmpty({
                                fetchData: async (date) => await getCandlesData({
                                        engine: InstrumentEngineType.STOCK,
                                        market: SecurityMarketType.STOCK,
                                        board: SecurityBoards.TQBR,
                                        arrayPos: getArrowPosByDatePeriod(IntervalType.ONE_DAY, date),
                                        columns: [CandleDataColumnType.CLOSE],
                                        secId: ticker.status === 'fulfilled' ? ticker.value.data[0].secId : '',
                                        from: date,
                                        till: new Date().toJSON(),
                                        interval: IntervalType.ONE_DAY,
                                    }),
                                start: new Date(),
                                step: [7, 30, 90, 180, 360],
                                responseDataAccessor: (resp) => resp.data.candles.data,
                            });

                            return [candlse];
                        }

                        const response = await getCandlesData({
                            engine: InstrumentEngineType.STOCK,
                            market: SecurityMarketType.STOCK,
                            board: SecurityBoards.TQBR,
                            arrayPos: ['0'],
                            columns: [CandleDataColumnType.CLOSE],
                            secId: ticker.status === 'fulfilled' ? ticker.value.data[0]?.secId ?? '' : '',
                            from: new Date(declaredPayDate).toJSON(),
                            till: new Date(declaredPayDate).toJSON(),
                            interval: IntervalType.ONE_DAY,
                        });

                        return response.data.candles.data;
                    }),
                );

                const dividendsData: StockDividend[] = dividendsResponse.data.rows.map(
                    ({ declaredPayDate, payStock, fintoolId }, index) => {
                        const priceResponse = pricesResponse[index];
                        const price =
                            priceResponse.status === 'fulfilled'
                                ? (priceResponse.value?.at(-1)?.[0]?.[0] as number) ?? 0
                                : 0;
                        const ticker = tickersResponse.find((response) => {
                            if (response.status === 'fulfilled') {
                                return response.value.data[0]?.fintoolId === fintoolId;
                            }

                            return false;
                        });
                        const secId = ticker?.status === 'fulfilled' ? ticker.value.data[0]?.secId ?? null : null;

                        return {
                            secId,
                            price,
                            paymentData: new Date(declaredPayDate).toJSON(),
                            paymentVol: payStock,
                            currentProfit: price > 0 ? round((payStock / price) * 100, 2) : 0,
                        };
                    },
                );

                setDividends(dividendsData);
            } catch (e) {
                console.error('e', e);
            } finally {
                stopLoading();
            }
        };
        getDividendsRequest();
    }, [dispatch]);

    useGetCurrentPrice({
        securities: dividends.filter(({ secId }) => secId) as Array<NotNullable<StockDividend>>,
        setSecurityPrice: ({ secId, paymentVol }, currentPrice) => {
            setDividends((prev) => {
                const priceWasChanged = prev.find(({ secId: ticker }) => secId === ticker)?.price !== currentPrice;

                const dividends = priceWasChanged
                    ? prev.map((dividend) => secId === dividend.secId
                              ? {
                                    ...dividend,
                                    price: currentPrice,
                                    currentProfit: round((paymentVol / currentPrice) * 100, 2),
                                }
                              : dividend,
                      )
                    : prev;

                return dividends;
            });
        },
    });

    return (
        <HelpLineWrapper message="helpline.marketDashboards.stocks.dividends">
            <div className={classNames(styles.tableDataView, 'flex', 'flex-column')}>
                <h2>Дивиденды</h2>
                <CustomTable data={dividends} columns={TOP_STOCK_BY_DIVIDENDS_COLUMNS} />
            </div>
        </HelpLineWrapper>
    );
};
