import classNames from 'classnames';

import styles from './styles.module.scss';
import { ReactComponent as EmptyListIcon } from '../../../../images/svg/favoritesSidebar/emptyList.svg';

type Props = {
    title: string;
    description: string;
};

export const EmptyData = ({ title, description }: Props) => {
    return (
        <div
            className={classNames(
                styles.emptyDataWrapper,
                'flex',
                'flex-column',
                'align-items-center',
                'justify-content-center',
            )}
        >
            <EmptyListIcon />
            <div className={classNames('flex', 'flex-column', 'align-items-center')}>
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
};
