/* eslint-disable @typescript-eslint/no-floating-promises */
import { DatePeriodSelector, HelpLineWrapper, LinearChart } from '@libs/components';
import { round } from 'lodash';
import classNames from 'classnames';
import { formatDateToQuery, getNumberWithSpacing, useChoosePeriod } from '@libs/utils';
import { useEffect, useMemo, useState } from 'react';
import { type SecurityData, type SelectedSecurity } from '@libs/types';
import { getCandlesData } from '@shared/services/quote.service';
import { color as colorFn } from 'chart.js/helpers';
import { formatPrice } from '@modules/Investorpro/shared/utils/format.util';
import { TooltipContent } from '@modules/Investorpro/shared/components/TooltipContent';
import { getXAxisCallbackByPeriodIdForDatasets } from '@modules/Investorpro/shared/utils/chart.utils';
import { subDays } from 'date-fns';
import { PeriodId } from '@libs/types/instrument.type';
import { CandleDataColumnType } from '@modules/Investorpro/types/quote.type';

import { type ChartDataPoint } from '../../../BondsMarketPage/components/BondMarketChartBlock/types';
import { getArrowPosByPeriodId, refetchDataUntilFindNotEmpty } from '../../utils/fetch.utils';
import { Mark } from './components/Mark';
import styles from './styles.module.scss';
import './chart.styles.scss';
import { PERIOD_OPTIONS } from './constants';
import { type SecurityForSelect } from '../../utils/useSelectSecurity';
import { compareSecurities } from '../../utils/securities.utils';
import { formatInstrumentTradeDate, transformDateFromMoscowToLocal } from '../../utils/date.utils';
import { type SelectedPeriod } from '../../../StockMarketPage/components/StockMarketDashboard/type';

type Props = {
    selectedSecurities: SelectedSecurity[];
    selectSecurity?: (security: SecurityForSelect) => void;
    setCurrentPeriod?: (selectedPeriod: SelectedPeriod) => void;
    getChartData?: (data: Record<string, ChartDataPoint['data']>, currentPeriod: PeriodId) => void;
    width?: number;
    withPrice?: boolean;
    height?: number;
    isLoading?: boolean;
    setIsLoading?: (status: boolean) => void;
    periodsControlsDisabled?: boolean;
};

export const SecurityPriceChangesChart = ({
    selectedSecurities,
    selectSecurity,
    width,
    setCurrentPeriod,
    getChartData,
    withPrice = false,
    height,
    periodsControlsDisabled,
    isLoading = false,
    setIsLoading,
}: Props) => {
    const {
        options: periodOptions,
        currentOption: currentPeriodOption,
        period: currentPeriod,
        getStartDateByEndDate,
        handleOptionChange: handlePeriodOptionChange,
    } = useChoosePeriod({
        defaultValue: PeriodId.ONE_DAY,
        options: PERIOD_OPTIONS,
    });

    const [chartData, setChartData] = useState<ChartDataPoint[]>([]);
    const [changedPeriodsDates, setChangedPeriodsDates] = useState<Record<string, string>>({});
    const [prevSelectedSecurities, setPrevSelectedSecurities] = useState<SelectedSecurity[]>(selectedSecurities);
    const startLoading = () => setIsLoading?.(true);
    const stopLoading = () => setIsLoading?.(false);
    const hasLabelSelection = selectSecurity !== undefined;
    const [hoveredSecurity, setHoveredSecurity] = useState<SecurityData | null>(null);
    const [initLoading, setInitLoading] = useState(true);

    const fetchSecurityPriceData = async (selectedSecurity: SelectedSecurity) => {
        const { secId, color, board, market, engine, name, interval, tradeDate } = selectedSecurity;

        try {
            const getData = async (start: string, end: string) => await getCandlesData({
                    secId,
                    engine,
                    board,
                    columns: [
                        ...(withPrice ? [CandleDataColumnType.CLOSE] : []),
                        CandleDataColumnType.DELTA_PROC,
                        CandleDataColumnType.END,
                    ],
                    from: start,
                    market,
                    till: end,
                    interval: interval ?? currentPeriod.interval,
                    arrayPos: getArrowPosByPeriodId(currentPeriodOption!, interval ?? currentPeriod.interval),
                });

            const resp = await getData(
                changedPeriodsDates[`${currentPeriodOption}_${secId}`] ?? formatDateToQuery(currentPeriod.from),
                formatDateToQuery(currentPeriod.to),
            );

            const [data] = await refetchDataUntilFindNotEmpty({
                data: resp.data.candles.data,
                fetchData: getData,
                start: tradeDate ? getStartDateByEndDate(tradeDate) : subDays(currentPeriod.from, 1),
                end: tradeDate ?? subDays(currentPeriod.to, 1),
                step: 1,
                maxTries: 14,
                responseDataAccessor: (resp) => resp.data.candles.data,
                extraEffect: (date) => setChangedPeriodsDates((prev) => ({
                        ...prev,
                        [`${currentPeriodOption}_${secId}`]: date.toJSON(),
                    })),
            });

            if (data.length === 0) return;

            const dataPoints = data
                .filter((item) => item[0].length === (withPrice ? 3 : 2))
                .map((item) => ({
                    x: transformDateFromMoscowToLocal((withPrice ? item[0][2] : item[0][1]) as string).toJSON(),
                    y: round(Number(withPrice ? item[0][1] : item[0][0]), 2),
                    price: withPrice ? Number(item[0][0]) : undefined,
                }));

            const pointProps =
                dataPoints.length === 1
                    ? {
                          pointRadius: 4,
                          pointColor: typeof color === 'string' ? color : color?.chart ?? '#2F9CEB',
                      }
                    : {};

            getChartData?.({ [selectedSecurity.secId]: dataPoints }, currentPeriodOption!);

            setChartData((prev) => {
                return [
                    ...prev,
                    {
                        engine: selectedSecurity.engine,
                        board: selectedSecurity.board,
                        market: selectedSecurity.market,
                        secId: selectedSecurity.secId,
                        data: dataPoints,
                        label: name,
                        color: typeof color === 'string' ? color : color?.chart ?? '#2F9CEB',
                        ...pointProps,
                    },
                ];
            });
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (selectedSecurities.length > prevSelectedSecurities.length) {
            const newSelectedSecurity = selectedSecurities.find(
                (newSelected) => !prevSelectedSecurities.some((prevSelected) => compareSecurities(newSelected, prevSelected)),
            )!;
            startLoading();
            fetchSecurityPriceData(newSelectedSecurity)
                .then(stopLoading)
                .then(() => setInitLoading(false));
        } else if (selectedSecurities.length < prevSelectedSecurities.length) {
            const unSelectedSecurity = prevSelectedSecurities.find(
                (prevSelected) => !selectedSecurities.some((newSelected) => compareSecurities(prevSelected, newSelected)),
            )!;

            setChartData((prev) => prev.filter((chartSecurity) => !compareSecurities(chartSecurity, unSelectedSecurity)),
            );
        }
        setPrevSelectedSecurities(selectedSecurities);
    }, [selectedSecurities]);

    useEffect(() => {
        startLoading();
        setChartData([]);

        if (currentPeriodOption && setCurrentPeriod) {
            setCurrentPeriod({
                id: currentPeriodOption,
                date: currentPeriod.from,
            });
        }

        Promise.allSettled(selectedSecurities.map(async (bond) => await fetchSecurityPriceData(bond))).then(() => {
            stopLoading();
        });
    }, [currentPeriodOption]);

    const onSecurityMarkHover = (dataset: SecurityData) => {
        if (hasLabelSelection) {
            setChartData((prev) => prev.map((item) => compareSecurities(item, dataset)
                        ? item
                        : {
                              ...item,
                              color: colorFn(item.color).alpha(0.4).rgbString(),
                          },
                ),
            );

            setHoveredSecurity(dataset);
        }
    };

    const onSecurityMarkMouseLeave = (dataset: SecurityData) => {
        if (hasLabelSelection) {
            setChartData((prev) => prev.map((item) => compareSecurities(item, dataset)
                        ? item
                        : {
                              ...item,
                              color: colorFn(item.color).alpha(1).rgbString(),
                          },
                ),
            );

            setHoveredSecurity(null);
        }
    };

    const handleUnselectSecurity = (dataset: SecurityData) => {
        if (hasLabelSelection && hoveredSecurity && compareSecurities(hoveredSecurity, dataset)) {
            return () => {
                selectSecurity(dataset as unknown as SecurityForSelect);
                onSecurityMarkMouseLeave(dataset);
            };
        } else {
            return undefined;
        }
    };

    const maxSecIdLength = selectedSecurities.reduce((max, security) => {
        return security.secId.length > max ? security.secId.length : max;
    }, 0);
    const chartWidthOffset = Math.max(0, (maxSecIdLength - 6) * 7);
    const isMarkFullWidth = selectedSecurities.some((security) => security.secId.length > 6);

    const options = useMemo(() => {
        const ticksCountForPeriodId: Partial<Record<PeriodId, number>> = {
            [PeriodId.ONE_DAY]: 20,
            [PeriodId.FIVE_DAYS]: 18,
            [PeriodId.ONE_MONTH]: 21,
            [PeriodId.THREE_MONTHS]: 20,
            [PeriodId.SIX_MONTHS]: 20,
            [PeriodId.ONE_YEAR]: 20,
            [PeriodId.THREE_YEARS]: 18,
            [PeriodId.SNG]: 20,
            [PeriodId.FIVE_YEARS]: 18,
            [PeriodId.TEN_YEARS]: 10,
        };

        const ticksCount = currentPeriodOption ? ticksCountForPeriodId[currentPeriodOption] ?? 12 : 12;

        return {
            animation: false,
            responsive: true,
            maintainAspectRatio: true,
            scales: {
                y: {
                    type: 'linear',
                    border: {
                        dash: (context: any) => {
                            if (context.tick.value === 0) {
                                return [5, 5];
                            }

                            return [];
                        },
                    },
                    ticks: {
                        padding: 15,
                        callback: (value: string) => {
                            return `${getNumberWithSpacing(round(Number(value), 2))}%`;
                        },
                    },
                    grid: {
                        color: (context: any) => {
                            if (context.tick.value === 0) {
                                return '#A5B1C0';
                            }

                            return '#D8E1EB';
                        },
                    },
                },
                x: {
                    type: 'timeseries',
                    border: {
                        color: 'transparent',
                    },
                    ticks: {
                        maxTicksLimit: ticksCount,
                        maxRotation: 0,
                        minRotation: 0,
                        callback: getXAxisCallbackByPeriodIdForDatasets({
                            datasets: chartData,
                            ticksCount,
                            period: currentPeriodOption,
                        }),
                    },
                },
            },
        } as any;
    }, [chartData, currentPeriodOption]);

    return (
        <HelpLineWrapper message="helpline.marketDashboards.selectedTools">
            <div className={classNames('flex ', 'flex-column', 'relative')}>
                <div className={classNames('flex', 'align-items-center', styles.legend)}>
                    {selectedSecurities.map(({ secId, name, color }) => (
                        <div key={secId} className={classNames('flex', 'align-items-center', styles.legend_item)}>
                            <span style={{ background: color.chart }} />
                            <span>{name}</span>
                        </div>
                    ))}
                </div>
                <LinearChart
                    style={{ opacity: initLoading ? 0 : 1 }}
                    className={classNames(styles.priceChart, 'securityPriceChangesChart')}
                    data={chartData as any}
                    height={height ?? 475}
                    width={(width ?? 1000) - chartWidthOffset}
                    withVerticalAnnotationLine
                    options={options}
                    loading={isLoading}
                    initLoading={initLoading}
                    yAxisAnnotationMarks={{
                        xOffset: 45,
                        allocateMarks: true,
                        renderMark: (mark, index) => {
                            const { value, color, pos, dataset } = mark;

                            if (value === null) return null;

                            return (
                                <Mark
                                    key={(dataset as ChartDataPoint)?.secId}
                                    className={classNames(styles.mark, isMarkFullWidth && 'w-auto')}
                                    color={color ?? 'black'}
                                    onMouseEnter={() => onSecurityMarkHover(dataset as unknown as SecurityData)}
                                    onMouseLeave={() => onSecurityMarkMouseLeave(dataset as unknown as SecurityData)}
                                    positionType="relative"
                                    handleDelete={handleUnselectSecurity(dataset as unknown as SecurityData)}
                                    y={pos - 10 - 20 * index}
                                >
                                    <h3> {(dataset as unknown as { secId: string })?.secId}</h3>
                                    <p className={styles.markChangeValue}>{formatPrice(round(+value ?? 0, 2))}%</p>
                                </Mark>
                            );
                        },
                    }}
                    tooltip={{
                        mode: 'nearest',
                        renderTooltipData: (data) => {
                            if (!data.length) return null;
                            const dataset = data[0];

                            const date = formatInstrumentTradeDate(dataset?.parsed?.x ?? '', currentPeriod.interval);

                            return (
                                <TooltipContent
                                    title={
                                        <>
                                            <span
                                                className={styles.tooltipColorLine}
                                                style={{ backgroundColor: dataset?.dataset?.borderColor }}
                                            />
                                            {dataset?.dataset?.secId}
                                        </>
                                    }
                                    content={[
                                        {
                                            title: 'Дата',
                                            content: date,
                                        },
                                        {
                                            title: 'Изменение,%',
                                            content: formatPrice(round(dataset?.parsed?.y ?? 0, 2)) + '%',
                                        },
                                    ]}
                                />
                            );
                        },
                    }}
                />
                <DatePeriodSelector
                    options={periodOptions}
                    currentOption={currentPeriodOption}
                    handlePeriodOptionChange={handlePeriodOptionChange}
                    disabled={isLoading || periodsControlsDisabled}
                />
            </div>
        </HelpLineWrapper>
    );
};
