import { type PolymorphicComponentProps, Text, type TextProps } from '@mantine/core';
import { type FC } from 'react';

type Props = PolymorphicComponentProps<'p', TextProps>;

export const MatrixDescriptionText: FC<Props> = (props) => (
    <Text
        {...props}
        ta="center"
        fz={14}
        lh="22px"
        c="#8A96A6"
        flex={1}
        style={{
            ...props.style,
            wordBreak: 'break-word',
        }}
    />
);
