import { Button, Grid, Group, Modal, Stack, Tabs, Textarea, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useEffect, type FC } from 'react';

import { Close } from '../../../shared/Icon';
import { CustomInputColor } from './CustomInputColor';
import { DropZone } from './DropZone';
import { FinalPreview } from './FinalPreview';
import {
    reportFormFinalSchema,
    reportFormTitleSchema,
    type ReportFormFinalValues,
    type ReportFormTitleValues,
} from './ReportForm.schema';
import { ReportTitlePreview } from './ReportTitlePreview';

type Props = {
    finalValues: ReportFormFinalValues;
    onClose: () => void;
    onFinalValuesChange: (values: ReportFormFinalValues) => void;
    onTitleValuesChange: (values: ReportFormTitleValues) => void;
    opened: boolean;
    titleValues: ReportFormTitleValues;
};

export const ReportSettingsModal: FC<Props> = ({
    finalValues,
    onClose,
    onFinalValuesChange,
    onTitleValuesChange,
    opened,
    titleValues,
}) => {
    const formTitle = useForm<ReportFormTitleValues>({
        initialValues: titleValues,
        validate: yupResolver(reportFormTitleSchema),
    });
    const formFinal = useForm<ReportFormFinalValues>({
        initialValues: finalValues,
        validate: yupResolver(reportFormFinalSchema),
    });

    const handleSubmitTitle = (values: ReportFormTitleValues) => {
        onTitleValuesChange(values);
        onClose();
    };

    const handleSubmitFinal = (values: ReportFormFinalValues) => {
        onFinalValuesChange(values);
        onClose();
    };

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (opened) {
            formTitle.setValues(titleValues);
            formFinal.setValues(finalValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opened, titleValues, finalValues]);

    const handleTitleLogoChange = (value: string) => formTitle.setFieldValue('logo', value);
    const handleFinalLogoChange = (value: string) => formFinal.setFieldValue('logo', value);

    return (
        <Modal.Root
centered={true} size="837px" opened={opened}
radius={6} onClose={handleClose}>
            <Modal.Overlay />
            <Modal.Content>
                <Modal.Header p={0} style={{ alignItems: 'flex-start' }}>
                    <Modal.Title
fz={24} fw={400} lh="16px"
p="48px 48px 0 42px">
                        {'Настройки отчета'}
                    </Modal.Title>
                    <Modal.CloseButton
                        variant="transparent"
                        icon={<Close width={36} height={36} />}
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            height: '46px',
                            width: '46px',
                        }}
                    />
                </Modal.Header>
                <Modal.Body p="35px 0 50px 0">
                    <Tabs color="#F2433D" h="100%" defaultValue="TITLE">
                        <Tabs.List pl={42}>
                            <Tabs.Tab value="TITLE">{'Титульный слайд'}</Tabs.Tab>
                            <Tabs.Tab value="FINAL">{'Финальный слайд'}</Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panel value="TITLE">
                            <form onSubmit={formTitle.onSubmit(handleSubmitTitle)}>
                                <Stack gap={50}>
                                    <Grid gutter={0}>
                                        <Grid.Col
                                            style={{
                                                borderRight: '1px solid #D8E1EB',
                                                borderBottom: '1px solid #D8E1EB',
                                            }}
                                            span={7.5}
                                        >
                                            <Stack gap={0} p="38px 43px 43px 42px">
                                                <TextInput
                                                    {...formTitle.getInputProps('name')}
                                                    label="Название слайда"
                                                    styles={{
                                                        input: { minHeight: 50, paddingLeft: '24px' },
                                                        root: { minHeight: 94 },
                                                        label: { fontFamily: 'Inter' },
                                                    }}
                                                />
                                                <Textarea
                                                    {...formTitle.getInputProps('description')}
                                                    label="Описание (не более 200 символов)"
                                                    styles={{
                                                        input: { minHeight: 90, paddingLeft: '24px' },
                                                        root: { minHeight: 134 },
                                                        label: { fontFamily: 'Inter' },
                                                    }}
                                                />
                                                <TextInput
                                                    {...formTitle.getInputProps('footer')}
                                                    label="Нижний колонтитул"
                                                    styles={{
                                                        input: { minHeight: 50, paddingLeft: '24px' },
                                                        root: { minHeight: 94 },
                                                        label: { fontFamily: 'Inter' },
                                                    }}
                                                />
                                            </Stack>
                                        </Grid.Col>
                                        <Grid.Col span={4.5} style={{ borderBottom: '1px solid #D8E1EB' }}>
                                            <ReportTitlePreview form={formTitle} />
                                        </Grid.Col>
                                        <Grid.Col
                                            style={{
                                                borderRight: '1px solid #D8E1EB',
                                                borderBottom: '1px solid #D8E1EB',
                                            }}
                                            pos="relative"
                                            span={7.5}
                                        >
                                            <DropZone
                                                logo={formTitle.values.logo}
                                                onLogoChange={handleTitleLogoChange}
                                            />
                                        </Grid.Col>
                                        <Grid.Col
                                            span={4.5}
                                            display="flex"
                                            style={{
                                                borderBottom: '1px solid #D8E1EB',
                                                padding: '26px 30px 46px',
                                                gap: 25,
                                            }}
                                        >
                                            <CustomInputColor
                                                {...formTitle.getInputProps('background')}
                                                label="Цвет фона"
                                            />
                                            <CustomInputColor
                                                {...formTitle.getInputProps('textColor')}
                                                label="Цвет текста"
                                            />
                                        </Grid.Col>
                                    </Grid>
                                    <Group
gap={26} wrap="nowrap" justify="center"
align="center" mx={180}>
                                        <Button
w="100%" variant="cancel" size="large"
onClick={handleClose}>
                                            {'Отменить'}
                                        </Button>
                                        <Button
w="100%" type="submit" size="large"
variant="primary">
                                            {'Сохранить'}
                                        </Button>
                                    </Group>
                                </Stack>
                            </form>
                        </Tabs.Panel>

                        <Tabs.Panel value="FINAL">
                            <form onSubmit={formFinal.onSubmit(handleSubmitFinal)}>
                                <Stack gap={50}>
                                    <Grid gutter={0}>
                                        <Grid.Col
                                            style={{
                                                borderRight: '1px solid #D8E1EB',
                                                borderBottom: '1px solid #D8E1EB',
                                            }}
                                            span={7.5}
                                        >
                                            <Stack gap={0} p="38px 43px 43px 42px">
                                                <TextInput
                                                    {...formFinal.getInputProps('name')}
                                                    label="Название слайда"
                                                    styles={{
                                                        input: { minHeight: 50, paddingLeft: '24px' },
                                                        root: { minHeight: 94 },
                                                        label: { fontFamily: 'Inter' },
                                                    }}
                                                />
                                                <Textarea
                                                    {...formFinal.getInputProps('descriptionFinal')}
                                                    label="Краткое описание (не более 50 символов)"
                                                    styles={{
                                                        input: { minHeight: 90, paddingLeft: '24px' },
                                                        root: { minHeight: 134 },
                                                        label: { fontFamily: 'Inter' },
                                                    }}
                                                />
                                            </Stack>
                                        </Grid.Col>
                                        <Grid.Col span={4.5} style={{ borderBottom: '1px solid #D8E1EB' }}>
                                            <FinalPreview form={formFinal} />
                                        </Grid.Col>
                                        <Grid.Col
                                            style={{
                                                borderRight: '1px solid #D8E1EB',
                                                borderBottom: '1px solid #D8E1EB',
                                            }}
                                            pos="relative"
                                            span={7.5}
                                        >
                                            <DropZone
                                                logo={formFinal.values.logo}
                                                onLogoChange={handleFinalLogoChange}
                                            />
                                        </Grid.Col>
                                        <Grid.Col
                                            span={4.5}
                                            display="flex"
                                            style={{
                                                borderBottom: '1px solid #D8E1EB',
                                                padding: '26px 30px 46px',
                                                gap: 25,
                                            }}
                                        >
                                            <CustomInputColor
                                                {...formFinal.getInputProps('background')}
                                                label="Цвет фона"
                                            />
                                            <CustomInputColor
                                                {...formFinal.getInputProps('textColor')}
                                                label="Цвет текста"
                                            />
                                        </Grid.Col>
                                    </Grid>
                                    <Group
gap={26} wrap="nowrap" justify="center"
align="center" mx={180}>
                                        <Button
w="100%" variant="cancel" size="large"
onClick={handleClose}>
                                            {'Отменить'}
                                        </Button>
                                        <Button
w="100%" type="submit" size="large"
variant="primary">
                                            {'Сохранить'}
                                        </Button>
                                    </Group>
                                </Stack>
                            </form>
                        </Tabs.Panel>
                    </Tabs>
                </Modal.Body>
            </Modal.Content>
        </Modal.Root>
    );
};
