import { useAppDispatch, useAppSelector } from '@store/store';
import { useState } from 'react';
import { deleteAlertThunk, updateAlertThunk } from '@store/store/thunk/investorpro/alerts.thunk';
import { Button } from 'primereact/button';
import { type TSecurity } from '@libs/types';

import styles from './styles.module.scss';
import { type Alert } from '../../../services/types';
import { ReactComponent as TrashIcon } from '../../../../../images/svg/BinRed.svg';
import { EditCreateAlertModalTemplate } from '../EditCreateAlertModalTemplate';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    alert: Alert;
};

export const EditAlertModal = ({ isOpen, closeModal, alert }: Props) => {
    const [security, setSecurity] = useState<TSecurity | null>({
        isin: alert.isin,
        exchange: alert.exchange,
        exchangeCountry: alert.exchangeCountry,
        securityType: alert.securityType,
        secId: alert.secId,
        shortName: alert.shortName,
    });
    const [searchSecurityValue, setSearchSecurityValue] = useState(`${alert.secId}, ${alert.shortName}`);
    const [price, setPrice] = useState<number | null>(alert.price);
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector((state) => state.alerts);

    const handleEditAlert = async () => {
        if (security && price !== null) {
            const response = await dispatch(
                updateAlertThunk({
                    ...alert,
                    ...security,
                    price,
                }),
            );

            if (response.type.endsWith('fulfilled')) {
                closeModal();
            }
        }
    };

    const handleDeleteAlert = async () => {
        const response = await dispatch(deleteAlertThunk(alert.id));

        if (response.type.endsWith('fulfilled')) {
            closeModal();
        }
    };

    const securityWasChanges = security && security.secId !== alert.secId && security.isin !== alert.isin;
    const priceWasChanges = price !== null && price !== alert.price;
    const hasChanges = securityWasChanges || priceWasChanges;

    return (
        <EditCreateAlertModalTemplate
            priceValue={price}
            searchSecuritiesValue={searchSecurityValue}
            setSecurity={setSecurity}
            setSearchValue={setSearchSecurityValue}
            setPriceValue={setPrice}
            isOpen={isOpen}
            closeModal={closeModal}
            title="Изменить уведомление"
            disableConfirmButton={isLoading || !hasChanges}
            disableDeclineButton={isLoading}
            handleAction={handleEditAlert}
            actionButtonTitle="Сохранить"
            extraButton={
                <span className={styles.deleteBtnWrapper} onClick={handleDeleteAlert}>
                    <TrashIcon />
                </span>
            }
        />
    );
};
