import { type Column } from '@modules/Investorpro/shared/components/Table';
import { getNumberWithSpacing } from '@libs/utils';
import { formatInstrumentPrice } from '@modules/Investorpro/modules/BondStockPage/shared/utils';
import { formatPrice } from '@modules/Investorpro/shared/utils/format.util';

import styles from './styles.module.scss';

export type QuoteDataLine = {
    bid: {
        price: number | null;
        quantity: number | null;
    };
    ask: {
        price: number | null;
        quantity: number | null;
    };
    maxBuyVolume: number;
    maxSellVolume: number;
};

export const QUOTE_COLUMNS: Array<Column<QuoteDataLine>> = [
    {
        id: 'bid',
        label: (
            <div className={styles.header}>
                <span>Bid, RUB</span>
                <span>Объем</span>
            </div>
        ),
        type: 'number',
        align: 'right',
        render: ({ bid: { price, quantity }, maxBuyVolume }) => (
            <>
                <div
                    style={{
                        width: quantity !== null && maxBuyVolume ? (quantity / maxBuyVolume) * 100 + '%' : '0%',
                    }}
                    className={styles.bid}
                />
                <div className={styles.quote}>
                    <span>{price ? formatPrice(price) : '–'}</span>
                    <span>{quantity ? getNumberWithSpacing(quantity) : '–'}</span>
                </div>
            </>
        ),
    },
    {
        id: 'ask',
        label: (
            <div className={styles.header}>
                <span>Объем</span>
                <span>Ask, RUB</span>
            </div>
        ),
        align: 'right',
        render: ({ maxSellVolume, ask: { price, quantity } }) => (
            <>
                <div
                    style={{
                        width: quantity !== null && maxSellVolume ? (quantity / maxSellVolume) * 100 + '%' : '0%',
                    }}
                    className={styles.ask}
                />
                <div className={styles.quote}>
                    <span>{quantity ? getNumberWithSpacing(quantity) : '–'}</span>
                    <span>{price ? formatPrice(price) : '–'}</span>
                </div>
            </>
        ),
    },
];
