export const commonChartOptions = {
    scales: {
        x: {
            grid: {
                display: false,
            },
            border: {
                z: 1,
                color: '#A5B1C0',
            },

        },
        y: {
            type: 'linear',
            position: 'right',
            color: '#825c34',
            drawTicks: true,
            border: {
                display: false,
            },
            grid: {
                color: '#D8E1EB',
                lineWidth: 1,
                offset: false,
                tickLength: 0,
                zeroLineWidth: 2,
            },
            ticks: {
                padding: 18,
                color: '#8A96A6',
                font: {
                    size: 11,
                    weight: 400,
                    color: '#8A96A6',
                    family: 'Inter',
                },
            },
        },
    },
}
