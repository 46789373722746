import { Permission } from '@libs/utils/hooks/usePermission';
import { type MenuItemType } from '@modules/Investorpro/shared/components/Header/header.model';

export const MENU_ITEMS: MenuItemType[] = [
    { label: 'Портфель', url: 'portfolio', permission: Permission.PORTFOLIO },
    { label: 'Скринер', url: 'screeners', permission: Permission.SCREENER },
    {
        label: 'Аналитика',
        url: 'analytics',
        permission: Permission.ANALYTIC,
        items: [
            {
                label: 'Дашборд рынка',
                url: 'analytics/market',
            },
            {
                label: 'Дашборд акций',
                url: 'analytics/stocks-market',
            },
            {
                label: 'Дашборд облигаций',
                url: 'analytics/bonds-market',
            },
        ],
    },
];
